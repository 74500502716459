import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MonitoringConfiguration } from '@app/models/MonitoringConfiguration';
import { Scoring } from '@app/models/scoring-tools/scoring';
import { Screening } from '@app/models/scoring-tools/screening';
import { MonitoringConfigurationService } from '@app/services/monitoring-configuration.service';
import { ScoringToolsService } from '@app/services/scoring-tools.service';
import { forkJoin } from 'rxjs';
import { DialogMonitoringComponent } from '@app/modules/screening-hub/components/dialog-monitoring/dialog-monitoring.component';
import { DialogConfirmRefreshScreeningComponent } from '@app/modules/screening-hub/components/dialog-confirm-refresh-screening/dialog-confirm-refresh-screening.component';
import { DialogConfirmUnlinkScreeningComponent } from '@app/modules/screening-hub/components/dialog-confirm-unlink-screening/dialog-confirm-unlink-screening.component';
import { DialogSearchLimitReachedComponent } from '@app/modules/screening-hub/components/dialog-search-limit-reached/dialog-search-limit-reached.component';
import { AMLUpdateLink } from '../../models/AMLUpdateLink';
import { ScreeningProvider, mapProviderName } from '../../models/ScreeningProvider';
import { AmlUpdateScoringService } from '../../services/aml-update-scoring.service';
import { AMLUpdateConfirmRefreshScreeningComponent } from '../aml-update-confirm-refresh-screening/aml-update-confirm-refresh-screening.component';
import { EquifaxWSScoringService } from '../../services/equifax-ws-scoring.service';
import { EquifaxWSLink } from '../../models/EquifaxWS/EquifaxWSLink';
import { EquifaxWSConfirmRefreshScreeningComponent } from '../equifax-ws-confirm-refresh-screening/equifax-ws-confirm-refresh-screening.component';
import { MemberCheckLink } from '../../models/member-check/member-check-link';
import { MemberCheckScoringService } from '../../services/member-check-scoring.service';
import { MemberCheckConfirmRefreshScreeningComponent } from '../member-check-confirm-refresh-screening/member-check-confirm-refresh-screening.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-screening-scorings',
  templateUrl: './screening-scorings.component.html',
  styleUrls: ['./screening-scorings.component.scss'],
})
export class ThirdPartyScreeningScoringsComponent implements OnInit {
  @Input() scoring: Scoring | AMLUpdateLink | EquifaxWSLink | MemberCheckLink;
  @Input() thirdPartyId: number;
  @Input() software: ScreeningProvider;
  @Output() refreshScoring = new EventEmitter<Scoring | AMLUpdateLink | EquifaxWSLink | MemberCheckLink>();
  monitoringConfiguration: MonitoringConfiguration;

  screenings: Screening[];

  ScreeningProvider = ScreeningProvider;

  providerNameTranslations: { [key:string]: string };

  constructor(
    private scoringToolsService: ScoringToolsService,
    private amlUpdateScoringService: AmlUpdateScoringService,
    private equifaxWSScoringService: EquifaxWSScoringService,
    private memberCheckScoringService: MemberCheckScoringService,
    private monitoringConfigurationService: MonitoringConfigurationService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.translate.get('providerNames')
      .subscribe(translation => {
        this.providerNameTranslations = translation;
      });

    switch (this.software) {
      case ScreeningProvider.Refinitiv:
        this.populateScreeningsAndMonitoring();
        break;
      case ScreeningProvider.AMLUpdate:
        this.populateAMLUpdateScreenings();
        break;
      case ScreeningProvider.EquifaxWS:
        this.populateEquifaxWSScreenings();
        break;
      case ScreeningProvider.MemberCheck:
        this.populateMemberCheckScreenings();
        break;
      default:
        break;
    }

  }

  openDialogUnlinkScreening() {
    const dialogRef = this.dialog.open(DialogConfirmUnlinkScreeningComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        switch (this.software) {
          case ScreeningProvider.Refinitiv:
            this.scoringToolsService
              .unlinkScoring(this.scoring.id)
              .subscribe((res) => {
                if (res) {
                  this.refreshScoring.emit(this.scoring);
                }
              });
            break;
          case ScreeningProvider.AMLUpdate:
            this.amlUpdateScoringService.deleteLink(this.scoring.id)
              .subscribe((res) => {
                if (res) {
                  this.refreshScoring.emit(this.scoring);
                }
              });
            break;
          case ScreeningProvider.EquifaxWS:
            this.equifaxWSScoringService.deleteLink(this.scoring.id)
              .subscribe((res) => {
                if (res) {
                  this.refreshScoring.emit(this.scoring);
                }
              });
            break;
          case ScreeningProvider.MemberCheck:
            this.memberCheckScoringService.deleteLink(this.scoring.id)
            .subscribe((res) => {
              if (res) {
                this.refreshScoring.emit(this.scoring);
              }
            });
          default:
            break;
        }
      }
    });
  }

  openMonitoringDialog(event: MouseEvent) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(DialogMonitoringComponent, {
      data: {
        monitoringConfiguration: this.monitoringConfiguration,
        thirdPartyRefinitivId: this.scoring.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.populateScreeningsAndMonitoring();
      }
    });
  }

  openDialogRefreshScreening(event: MouseEvent) {
    switch (this.software) {
      case ScreeningProvider.AMLUpdate:
        this.openDialogRefreshScreeningAMLUpdate(event);
        break;
      case ScreeningProvider.Refinitiv:
        this.openDialogRefreshScreeningRefinitiv(event);
        break;
      case ScreeningProvider.EquifaxWS:
        this.openDialogRefreshScreeningEquifaxWS(event);
        break;
      case ScreeningProvider.MemberCheck:
        this.openDialogRefreshScreeningMemberCheck(event);
        break;
      default:
        break;
    }
  }

  openDialogRefreshScreeningRefinitiv(event: MouseEvent) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(DialogConfirmRefreshScreeningComponent, {
      data: this.scoring,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.scoringToolsService
          .refreshScreening((<Scoring>this.scoring).worldCheckId, this.thirdPartyId)
          .subscribe((res) => {
            if (res === 'OK') {
              this.populateScreeningsAndMonitoring();
            } else if (res === 'REACHED_QUOTA') {
              this.dialog.open(DialogSearchLimitReachedComponent);
            }
          });
      }
    });
  }

  openDialogRefreshScreeningAMLUpdate(event: MouseEvent) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(AMLUpdateConfirmRefreshScreeningComponent, {
      data: <AMLUpdateLink>this.scoring,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.amlUpdateScoringService
          .refresh((<AMLUpdateLink>this.scoring).rut, this.thirdPartyId)
          .subscribe((res) => {
            if (res === 'OK') {
              this.populateAMLUpdateScreenings();
            } else if (res === 'REACHED_QUOTA') {
              this.dialog.open(DialogSearchLimitReachedComponent);
            }
          });
      }
    });
  }

  openDialogRefreshScreeningEquifaxWS(event: MouseEvent) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(EquifaxWSConfirmRefreshScreeningComponent, {
      data: <EquifaxWSLink>this.scoring,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.equifaxWSScoringService
          .refresh((<EquifaxWSLink>this.scoring).cedula, this.thirdPartyId)
          .subscribe((res) => {
            if (res === 'OK') {
              this.populateEquifaxWSScreenings();
            } else if (res === 'REACHED_QUOTA') {
              this.dialog.open(DialogSearchLimitReachedComponent);
            }
          });
      }
    });
  }

  openDialogRefreshScreeningMemberCheck(event: MouseEvent) {
    event.stopPropagation();

    const dialogRef = this.dialog.open(MemberCheckConfirmRefreshScreeningComponent, {
      data: <MemberCheckLink>this.scoring,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberCheckScoringService
          .refresh((<MemberCheckLink>this.scoring).memberCheckId, this.thirdPartyId)
          .subscribe((res) => {
            if (res === 'OK') {
              this.populateMemberCheckScreenings();
            } else if (res === 'REACHED_QUOTA') {
              this.dialog.open(DialogSearchLimitReachedComponent);
            }
          });
      }
    });
  }

  private populateScreeningsAndMonitoring(): void {
    forkJoin({
      screenings: this.scoringToolsService.getScreeningsForThirdParty(
        this.scoring.id,
        (<Scoring>this.scoring).worldCheckId
      ),
      monitoringConfiguration:
        this.monitoringConfigurationService.getMonitoring(this.scoring.id),
    }).subscribe(({ screenings, monitoringConfiguration }) => {
      this.screenings = screenings;
      this.monitoringConfiguration = monitoringConfiguration;
    });
  }

  private populateAMLUpdateScreenings(): void {
    this.amlUpdateScoringService.getScreenings(this.scoring.id).subscribe(r => this.screenings = r);
  }

  private populateEquifaxWSScreenings(): void {
    this.equifaxWSScoringService.getScreenings(this.scoring.id).subscribe(r => this.screenings = r);
  }

  private populateMemberCheckScreenings(): void {
    this.memberCheckScoringService.getScreenings(this.scoring.id).subscribe(r => this.screenings = r);
  }

  getProviderName(provider: ScreeningProvider) {
    return mapProviderName(provider, this.providerNameTranslations);
  }
}
