import { Country, CountryDTO } from '@app/models/Country';
import { CountryDto } from 'src/proxy/model/countryDto';
import { mapDescriptionToCurrentLanguage } from './description-translation.mapping';

export function mapCountry(dto: CountryDto, currentLanguage: string): Country {
  if (!dto) return null;

  return <Country>{
    id: dto.Id,
    code: dto.Code,
    codeAlpha3: dto.CodeAlpha3,
    description: mapDescriptionToCurrentLanguage(dto, currentLanguage),
  };
}

export function mapCountryToCountryDto(dto: Country): CountryDto {
  if (!dto) return null;

  return <CountryDto>{
    Id: dto.id,
    Code: dto.code,
    CodeAlpha3: dto.codeAlpha3,
    Description: dto.description,
    DescriptionEN: dto.description,
    DescriptionPT: dto.description,
    DescriptionFR: dto.description
  };
}

export function mapCountryDTO(dto: CountryDto, currentLanguage: string): CountryDTO {
  return <CountryDTO>{
    Id: dto.Id,
    Code: dto?.Code,
    Description: mapDescriptionToCurrentLanguage(dto, currentLanguage)
  }
}
