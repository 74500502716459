<div class="o-wrapper">
  <main id="content">
    <div class="c-head-section c-head-section--tab c-head-section--bg-cream bg-cream-dark">
      <!--parte superior-->
      <div class="c-head-section--tab__top">
        <div class="c-head-section__left">
          <div class="c-head-section--tab__image" aria-hidden="true">
            <img src="assets/placeholder-avatar.png" alt="" loading="lazy" />
          </div>
          <div class="c-head-section--tab__info">
            <h2 class="c-head-section--tab__title">{{'MiCuenta.intro.valor'|translate}} - {{userdata?userdata.Name:''}}
              {{userdata?userdata.SurName:''}}</h2>
            <div class="c-head-section--tab-data">
              <div class="c-head-section--tab-data__item">
                <div class="c-head-section--tab-data__ico" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      d="M15.833,3.333h-.917A4.174,4.174,0,0,0,10.833,0H9.167A4.174,4.174,0,0,0,5.083,3.333H4.167A4.172,4.172,0,0,0,0,7.5v8.333A4.172,4.172,0,0,0,4.167,20H15.833A4.172,4.172,0,0,0,20,15.833V7.5a4.172,4.172,0,0,0-4.167-4.167ZM9.167,1.667h1.667A2.5,2.5,0,0,1,13.18,3.333H6.82A2.5,2.5,0,0,1,9.167,1.667ZM4.167,5H15.833a2.5,2.5,0,0,1,2.5,2.5V10H1.667V7.5A2.5,2.5,0,0,1,4.167,5ZM15.833,18.333H4.167a2.5,2.5,0,0,1-2.5-2.5V11.667h7.5V12.5a.833.833,0,0,0,1.667,0v-.833h7.5v4.167A2.5,2.5,0,0,1,15.833,18.333Z" />
                  </svg>
                </div>
                <div>
                  <span class="u-bold">{{(userdata && userdata?.Position >= 0 ?
                    getPositionTranslationKey(userdata.Position) : '') | translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-head-section__right">
          <button type="button" class="c-button" (click)="update()"
            [disabled]="!form.valid">{{'MiCuenta.save.valor'|translate}} </button>
        </div>
      </div>
      <!--parte inferior-->
      <div class="c-head-section--tab__bottom">
        <!--tabs-->
        <mat-tab-group class="c-tabs">
          <!--información cuenta-->
          <mat-tab label="{{'MiCuenta.accountinfo.valor'|translate}}">
            <div class="o-scroll o-scroll--small">
              <div class="c-form c-form--space">
                <form [formGroup]="form" id="form-account" autocomplete="off" class="c-form__form">
                  <!--datos perfil-->
                  <fieldset class="u-mb-1medium">
                    <legend class="c-form__legend">{{'MiCuenta.profiledata.valor'|translate}}</legend>
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-2">
                        <div class="c-form__block">
                          <!--nombre-->
                          <mat-form-field>
                            <input type="text" matInput name="Name" formControlName="Name" id="Name"
                              class="c-form__input" placeholder="{{'MiCuenta.name.valor'|translate}}*" />
                            <mat-error>
                              *{{'MiCuenta.required.valor'|translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6 col-xl-3">
                        <div class="c-form__block">
                          <!--apellidos-->
                          <mat-form-field>
                            <input type="text" matInput name="Surname" formControlName="SurName" id="Surname"
                              class="c-form__input" placeholder="{{'MiCuenta.surname.valor'|translate}}*" />
                            <mat-error>
                              *{{'MiCuenta.required.valor'|translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6 col-xl-4">
                        <div class="c-form__block">
                          <!--email-->
                          <mat-form-field>
                            <input type="email" matInput name="Email" formControlName="Email" id="Email"
                              class="c-form__input" placeholder="{{'MiCuenta.email.valor'|translate}}*" />
                            <mat-error>
                              *{{'MiCuenta.required.valor'|translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6 col-xl-3">
                        <div class="c-form__block">
                          <!--cargo-->
                          <div class="c-select">
                            <mat-form-field>
                              <mat-select formControlName="Position"
                                placeholder="{{'MiCuenta.position.valor' | translate }}">
                                <mat-option *ngFor="let position of positions" [value]="position">
                                  {{ getPositionTranslationKey(position) | translate }}</mat-option>
                              </mat-select>
                              <mat-error>
                                *{{'MiCuenta.required.valor'|translate}}
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="c-form-upload c-form-upload--image" aria-labelledby="uploadTitle">
                          <div class="c-form-upload--image__left">
                            <img [src]="selectedIconFileUri ? loadAvatarImage() : '/assets/placeholder-avatar.png'" />
                          </div>
                          <div class="c-form-upload--image__right">
                            <p id="uploadTitle" class="c-form-upload--image__title">
                              {{'MiCuenta.profilePicture'|translate}}</p>
                            <app-file-upload formControlName="Foto" [buttonText]="uploadButtonText" [qid]="null"
                              (fileSelected)="selectDocument($event)"></app-file-upload>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <!--cambio de contraseña-->
                  <fieldset class="u-mb-1medium">
                    <legend class="c-form__legend">{{'MiCuenta.passchange.valor'|translate}}</legend>
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-4">
                        <div class="c-form__block">
                          <button class="c-button"
                            (click)="changePassword()">{{'MiCuenta.passchange.valor'|translate}}</button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <!--preferencias de la cuenta-->
                  <fieldset class="u-mb-1medium">
                    <legend class="c-form__legend">{{'MiCuenta.preferences.valor'|translate}}</legend>
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-3">
                        <div class="c-form__block">
                          <div class="c-select">
                            <mat-form-field>
                              <mat-select formControlName="Language" id="Language"
                                placeholder="{{'MiCuenta.language'|translate}}*">
                                <mat-option *ngFor="let language of languages" [value]="language">
                                  {{ getLanguageTranslationKey(language) | translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset *appAuthRole="['ThirdParty']">
                    <legend class="c-form__legend">{{'MiCuenta.security'|translate}}</legend>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="c-switches c-switches--cols">
                          <p class="c-select__label--mb">
                            {{'MiCuenta.twoFactorDisclaimer'|translate}}
                          </p>
                          <div class="c-switches--cols__group" aria-labelledby="attachedDocs">
                            <div class="c-switches__block">
                              <div class="c-switches__items">
                                <mat-label class="mat-slide-toggle-content"
                                  style="vertical-align: top;
                                                                line-height: 24px;
                                                                margin-right:0.5rem;
                                                                margin-bottom:0.5rem;">{{'MiCuenta.twoFactorOff'|translate}}</mat-label>
                                <mat-slide-toggle formControlName="TwoFactorEnabled" class="c-switch">
                                  {{'MiCuenta.twoFactorOn'|translate}}
                                </mat-slide-toggle>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </main>
</div>
