import { ThirdPartyFile } from '@app/models/ThirdPartyFile';
import { ThirdPartyFileDto } from 'src/proxy/model/thirdPartyFileDto';

export function mapThirdPartyFile(dto: ThirdPartyFileDto): ThirdPartyFile {
  if (!dto) return null;

  return <ThirdPartyFile>{
    name: dto.Name,
    fileUri: dto.FileUri,
    documentType: dto.DocumentType,
    campaignName: dto.CampaignName,
    uploadDate: dto.UploadDate,
  };
}
