<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'DeleteThirdPartyDialog.title' | translate}}</h1>
        <button mat-button (click)="onNoClick()" class="c-dialog__close" aria-label="Cerrar ventana modal">
            <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path
                        d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
                        transform="translate(-309.86-406.47)" />
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content">
        <h2 class="c-dialog__subtitle">{{'DeleteThirdPartyDialog.message' | translate}}</h2>
        <div class="c-dialog-info">
            <div class="c-dialog-info__right">
                <h3 class="c-dialog__thirdtitle">{{ data.Name }}</h3>
                <div class="c-dialog-data">
                    <div class="c-dialog-data__item">
                        <div class="c-dialog-data__ico" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                <path
                                    d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z" />
                            </svg>
                        </div>
                        <div>
                            <span>{{ data.Type | translatedescription }}</span>
                        </div>
                    </div>
                    <div class="c-dialog-data__item">
                        <div class="c-dialog-data__ico" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                <path
                                    d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm6.667,8a6.625,6.625,0,0,1-1.108,3.672l-.795-.8a.333.333,0,0,1-.1-.235V10a2,2,0,0,0-2-2h-2A.667.667,0,0,1,8,7.333V7a.333.333,0,0,1,.333-.333A1.667,1.667,0,0,0,10,5V4.333A.333.333,0,0,1,10.333,4h.919a1.677,1.677,0,0,0,1.178-.488l.251-.251A6.646,6.646,0,0,1,14.667,8Zm-13.3.642,2.105,2.105a1.985,1.985,0,0,0,1.415.586H6.667A.667.667,0,0,1,7.333,12v2.633A6.677,6.677,0,0,1,1.366,8.642Zm7.3,5.991V12a2,2,0,0,0-2-2H4.886a.667.667,0,0,1-.472-.2L1.442,6.833a6.652,6.652,0,0,1,10.2-4.413l-.149.149a.338.338,0,0,1-.235.1h-.919A1.667,1.667,0,0,0,8.667,4.333V5a.333.333,0,0,1-.333.333A1.667,1.667,0,0,0,6.667,7v.333a2,2,0,0,0,2,2h2a.667.667,0,0,1,.667.667v.641a1.677,1.677,0,0,0,.488,1.178l.891.891A6.647,6.647,0,0,1,8.667,14.633Z" />
                            </svg>
                        </div>
                        <div>
                            <span>{{ data.Country | translatedescription }}</span>
                        </div>
                    </div>
                    <div class="c-dialog-data__item">
                        <div class="c-dialog-data__ico" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                <path
                                    d="M12.667,2.667h-.733A3.339,3.339,0,0,0,8.667,0H7.333A3.339,3.339,0,0,0,4.067,2.667H3.333A3.337,3.337,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6a3.337,3.337,0,0,0-3.333-3.333ZM7.333,1.333H8.667a2,2,0,0,1,1.877,1.333H5.456A2,2,0,0,1,7.333,1.333ZM3.333,4h9.333a2,2,0,0,1,2,2V8H1.333V6a2,2,0,0,1,2-2Zm9.333,10.667H3.333a2,2,0,0,1-2-2V9.333h6V10a.667.667,0,0,0,1.333,0V9.333h6v3.333A2,2,0,0,1,12.667,14.667Z" />
                            </svg>
                        </div>
                        <div>
                            <span>{{ data.Sector | translatedescription }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="c-dialog__footer">
        <button type="button" [mat-dialog-close]="false" class="c-button c-button--light">{{'DeleteThirdPartyDialog.cancel' | translate}}</button>
        <button type="submit" [mat-dialog-close]="true" routerLinkActive="is-active" class="c-button c-button--remove">{{'DeleteThirdPartyDialog.confirm' | translate}}</button>
    </footer>
</div>