import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Country } from '@app/models/Country';
import { Gender, getGenderList } from '@app/models/scoring-tools/gender';
import { IndividualFilter } from '@app/models/scoring-tools/individual-filter';

@Component({
  selector: 'app-scoring-candidates-individual-search-gesintel',
  templateUrl: './scoring-candidates-individual-search-gesintel.component.html',
  styleUrls: ['./scoring-candidates-individual-search-gesintel.component.scss'],
})
export class ScoringCandidatesIndividualSearchGesintelComponent {
  @Input() countryList: Country[];
  @Output() onSearchClick = new EventEmitter<IndividualFilter>();

  model: IndividualFilter;
  genderList: Gender[];

  constructor() {
    this.genderList = getGenderList();
    this.model = new IndividualFilter();
  }

  search() {
    this.onSearchClick.emit(this.model);
  }
}
