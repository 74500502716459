export enum ThirdPartyStatus {
    pending = 0,
    inProgress = 1,
    approved = 2,
    rejected = 3
}

export const ThirdPartyStatusValues = Object.values(ThirdPartyStatus).filter(value => !isNaN(+value)) as ThirdPartyStatus[];

export function mapTPStatusToTranslationKey(tpStatus: ThirdPartyStatus): string {
  switch (tpStatus) {
    case ThirdPartyStatus.pending:
      return 'Riskboard.pending';
    case ThirdPartyStatus.inProgress:
      return 'Riskboard.inProgress';
    case ThirdPartyStatus.approved:
      return 'Riskboard.approved';
    case ThirdPartyStatus.rejected:
      return 'Riskboard.rejected';
    default:
      return 'Riskboard.pending';
  }
}
