import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog3pp-exists',
  templateUrl: './dialog3pp-exists.component.html',
  styleUrls: ['./dialog3pp-exists.component.scss']
})
export class Dialog3ppExistsComponent implements OnInit {

  introText='';

  constructor(
    public dialogRef: MatDialogRef<Dialog3ppExistsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.get('DialogExisting3pp.introText').subscribe((text:string) => {
      this.introText=text['valor'];
      this.introText=this.introText.replace('##1',this.data.name);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }  
}
