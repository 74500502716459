<div class="o-wrapper">
  <main id="content">
    <div class="c-head-portal">
      <div class="c-head-portal__top">
        <div class="c-head-portal__image" aria-hidden="true">
          <img [src]="headPortalImage" [alt]="" />
        </div>
        <div class="c-head-portal__logo">
          <img [src]="headPortalLogo" [alt]="" />
        </div>
      </div>
      <div class="c-head-portal__medium">
        <h2 class="o-title">{{ username }}</h2>
        <p class="o-text o-text--bold">{{'PageId.title.welcome.valor' | translate }}</p>
      </div>
      <mat-divider></mat-divider>
      <div class="c-head-section--tab__bottom">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-9">
              <div class="row">
                <div class="container-fluid pending-container">
                  <div class="row">
                    <div class="col-lg-10">
                      <span class="pending-title-icon" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18.345">
                          <path
                            d="M19.913,9.693A10,10,0,0,0,0,11a9.9,9.9,0,0,0,3.132,7.259,4.033,4.033,0,0,0,2.795,1.073h8.138a4.161,4.161,0,0,0,2.924-1.191,9.952,9.952,0,0,0,2.925-8.444Zm-4.09,7.249a2.5,2.5,0,0,1-1.759.719H5.926a2.385,2.385,0,0,1-1.651-.617A8.249,8.249,0,0,1,1.666,11,8.344,8.344,0,0,1,4.448,4.782,8.233,8.233,0,0,1,9.987,2.663a8.792,8.792,0,0,1,.978.055,8.327,8.327,0,0,1,4.858,14.227ZM6.5,14.566a.834.834,0,0,1-1.166,1.191A6.666,6.666,0,0,1,10.951,4.4a.833.833,0,0,1,.706.944.824.824,0,0,1-.944.707A5,5,0,0,0,6.5,14.566ZM16.6,10.043a6.726,6.726,0,0,1,.067.952,6.608,6.608,0,0,1-2.006,4.762.834.834,0,1,1-1.166-1.193,4.982,4.982,0,0,0,1.453-4.283.834.834,0,0,1,1.651-.238Zm-4.993.526a1.694,1.694,0,1,1-1.178-1.178l3.15-3.15a.833.833,0,1,1,1.178,1.178Z"
                            transform="translate(0-.982)" />
                        </svg>
                      </span>
                      <h1 class="pending-title">{{'PageId.card.title.valor' | translate }}</h1>
                    </div>
                    <div class="col-lg-2">
                      <a [routerLink]="['/mis-riesgos']" routerLinkActive="is-active" style="min-width: auto;"
                        class="c-button c-button--gradient">{{'PageId.button.all.valor' | translate }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="container-fluid" *ngIf="!hasPendingConfigRequest">
                  <table mat-table [dataSource]="pendingList" class="mat-elevation-z8">
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef style="width: 28%;"> {{'PageId.table.name.valor' |
                        translate}} </th>
                      <td mat-cell style="width: 28%;" *matCellDef="let element">
                        <div class="table-cell">
                          <span class="assesment-name">{{element.name}}</span>
                          <span class="company-name">{{element.client.name}}</span>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="assessed">
                      <th mat-header-cell *matHeaderCellDef style="width: 28%;"> {{'PageId.table.assessed' | translate}} </th>
                      <td mat-cell style="width: 28%;" *matCellDef="let element">
                        <div class="table-cell">
                          <span class="assesment-name">{{element.thirdParties[0].CompanyName}}</span>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="dates">
                      <th mat-header-cell *matHeaderCellDef style="width: 14%;"> {{'PageId.table.dates.valor' |
                        translate}} </th>
                      <td mat-cell style="width: 14%;" *matCellDef="let element">
                        <div class="table-cell">
                          <span class="start-date">{{element.startDate | date:'dd/MM/yyyy - HH:mm'}}</span>
                          <span [ngClass]="{'expiring': checkIfExpiring(element.endDate)}"
                            class="end-date">{{element.endDate | date:'dd/MM/yyyy - HH:mm'}}</span>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef style="width: 25%;"> {{'PageId.table.status.valor' |
                        translate}} </th>
                      <td mat-cell style="width: 25%;" *matCellDef="let element">
                        <div class="c-progress-bar" style="width: 90%; float: left;">
                          <mat-progress-bar aria-labelledby="progressBar" class="c-progress-bar__bar" [color]="color"
                            [value]="element.thirdParties[0].progress">
                          </mat-progress-bar>
                          <p id="progressBar" class="c-progress-bar__percent">{{element.thirdParties[0].progress}}%</p>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef style="width: 15%;"> {{'PageId.table.actions.valor' |
                        translate}} </th>
                      <td mat-cell *matCellDef="let element" style="width: 15%;">
                        <app-risklist-action [element]="element"></app-risklist-action>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="9">{{'PageId.empty.valor' | translate }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <article class="c-box-action u-mt-1medium">
                <h1 class="c-box-action__title">{{'PageId.box.title.valor' | translate }}</h1>
                <h2 class="c-box-action__subtitle">{{'PageId.box.subtitle.valor' | translate }}</h2>
                <p class="o-text">{{'PageId.box.text.valor' | translate }}</p>
                <div class="banner-container">
                  <button class="c-button banner-button"
                    onclick="window.open('https://www.riskallay.com/demo','_blank');"
                    type="button">{{'PageId.box.askDemo.valor' | translate }}</button>
                </div>
                <div class="banner-container">
                  <button class="c-button banner-button inverted-colors" (click)="goToVideo()"
                    type="button">{{'PageId.box.goToVideo.valor' | translate }}</button>
                </div>
                <div class="banner-container">
                  <img src="/assets/thirdPartyHomeDiscover.svg" alt="Discover banner" class="banner-image" />
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
