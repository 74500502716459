import { Injectable } from '@angular/core';
import { ThirdPartyType } from '@app/models/ThirdPartyType';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonsProxyClient } from 'src/proxy/apiCommons/commons.proxy';
import { mapThirdPartyType } from './mappings/third-party-type.mappings';

@Injectable({
    providedIn: 'root'
})
export class ThirdPartyTypesService {

    constructor(private commonsClient: CommonsProxyClient) { }

    public getThirdPartyTypes(): Observable<ThirdPartyType[]> {
        return this.commonsClient
            .commonsThirdPartyTypeGet()
            .pipe(map((thirdPartyTypes) => thirdPartyTypes.map(tpt => mapThirdPartyType(tpt))));
    }
}
