import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScoringCandidateDetails } from '@app/models/scoring-tools/scoringCandidateDetails';
import { ScoringRisk } from '@app/models/scoring-tools/scoringRisk';
import { ScoringToolsService } from '@app/services/scoring-tools.service';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-scoring-candidate-details',
  templateUrl: './scoring-candidate-details.component.html',
  styleUrls: ['./scoring-candidate-details.component.scss'],
})
export class ThirdPartyScoringCandidateDetailsComponent implements OnInit {
  thirdPartyId: number;
  caseId: string;
  referenceId: string;
  matchStrength: string;
  scoringDetails: ScoringCandidateDetails;

  returnTabIndex: number = 3;

  private screeningSucceededMessage: string;
  private screeningFailedMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private scoringToolsService: ScoringToolsService,
    private router: Router,
    private translate: TranslateService,
    private toaster: Toaster
  ) {}

  ngOnInit() {
    this.translate
      .get('ScoringDetails.screeningSucceeded')
      .subscribe((translation) => {
        this.screeningSucceededMessage = translation;
      });

    this.translate
      .get('ScoringDetails.screeningFailed')
      .subscribe((translation) => {
        this.screeningFailedMessage = translation;
      });

    this.activatedRoute.params.subscribe((params) => {
      (this.thirdPartyId = params.id),
        (this.caseId = params.caseId),
        (this.referenceId = params.referenceId);
      this.matchStrength = params.matchStrength;
    });

    this.scoringToolsService
      .getScoringCandidateDetails(this.referenceId)
      .subscribe((res) => {
        this.scoringDetails = res;
        this.scoringDetails.matchStrength = this.matchStrength;
      });
  }

  save(): void {
    this.scoringToolsService
      .saveScreening(this.referenceId, this.matchStrength, this.thirdPartyId)
      .subscribe(
        async (res) => {
          if (res) {
            this.toaster.open({
              text: this.screeningSucceededMessage,
              duration: 6000,
              type: 'success',
            });
            if (this.thirdPartyId) {
              this.router.navigate([
                'perfil-tercero',
                this.thirdPartyId,
                this.returnTabIndex,
              ]);
            } else {
              this.router.navigate(['screening-tools']);
            }
          } else {
            this.toaster.open({
              text: this.screeningFailedMessage,
              duration: 6000,
              type: 'danger',
            });
          }
        },
        (err) =>
          this.toaster.open({
            text: this.screeningFailedMessage,
            duration: 6000,
            type: 'danger',
          })
      );
  }

  getRisks(risks: ScoringRisk[]): string[] {
    return (
      risks?.reduce(function (dr: string[], a) {
        const index = dr.findIndex((r) => r == a.type);
        if (index === -1) {
          dr.push(a.type);
        }
        return dr;
      }, []) || []
    );
  }

  back() {
    if (this.thirdPartyId) {
      this.router.navigate([
        'scoring-candidates-result',
        this.caseId,
        this.thirdPartyId,
      ]);
    } else {
      this.router.navigate(['scoring-candidates-result', this.caseId]);
    }
  }
}
