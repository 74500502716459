import { ThirdPartyType } from "@app/models/ThirdPartyType";
import { ThirdPartyTypeDto } from "src/proxy/model/thirdPartyTypeDto";

export function mapThirdPartyType(dto: ThirdPartyTypeDto): ThirdPartyType {
  if (!dto) return null;

  return <ThirdPartyType>{
    id: dto.Id,
    code: dto.Code,
    description: dto.Description,
    descriptionEN: dto.DescriptionEN,
    descriptionPT: dto.DescriptionPT,
    descriptionFR: dto.DescriptionFR
  };
}

export function mapThirdPartyTypeToThirdPartyTypeDto(dto: ThirdPartyType): ThirdPartyTypeDto {
  if (!dto) return null;

  return <ThirdPartyTypeDto>{
    Id: dto.id,
    Code: dto.code,
    Description: dto.description,
    DescriptionEN: dto.descriptionEN,
    DescriptionPT: dto.descriptionPT,
    DescriptionFR: dto.descriptionFR
  };
}
