<div class="o-wrapper">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section c-head-section--tab bg-cream">
            <!--parte superior-->
            <div class="c-head-section--tab__top">
                <div class="c-head-section__left">
                    <div class="c-head-section--tab__info">
                        <h2 class="c-head-section--tab__title">{{'CustomFieldDetail.header.title' | translate}}</h2>
                    </div>
                </div>
                <div class="c-head-section__right">
                    <button [disabled]="!isValid" (click)="updateCustomField()" type="submit" class="c-button">
                        {{'CustomFieldDetail.header.saveChanges' | translate}}
                    </button>
                </div>
            </div>
            <!--volver a listado-->
            <div class="c-go-back">
                <a [routerLink]="['/ajustes-generales/custom-fields']" class="c-go-back__button">
                    <span class="c-go-back__arrow" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                            <path
                                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                                transform="translate(-5-6.01)" />
                        </svg>
                    </span> {{'CustomFieldDetail.header.backToList' | translate}}
                </a>
            </div>
            <!--parte inferior-->
            <div class="c-head-section--tab__bottom">
                <div class="c-tabs">
                    <div class="mat-tab-body-content">
                        <app-custom-field-form
                            *ngIf="!loading"
                            (changeValidity)="isFormValid($event)"
                            (changeCustomField)="customFieldUpdated($event)"
                            [customField]="customField"
                        ></app-custom-field-form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>