import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { QueryResult } from '@app/models/common/queryResult';
import { Screening } from '@app/models/scoring-tools/screening';
import { MemberCheckLink } from '@app/modules/screening-hub/models/member-check/member-check-link';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'member-check-screening-list',
  templateUrl: './member-check-screening-list.component.html',
  styleUrls: ['./member-check-screening-list.component.scss'],
})
export class MemberCheckScreeningListComponent implements OnChanges {
  @Input() screenings: Screening[];
  @Input() thirdPartyId?: number;
  @Input() scoring: MemberCheckLink;

  displayedColumns: string[] = [
    'matchedAlias',
    'worldCheckId',
    'screeningDate',
    'createdBy',
    'actions',
  ];
  generatingReportMessage: string;
  reportGeneratedMessage: string;

  queryResult: QueryResult<Screening> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: 'matchedAlias',
    IsSortAscending: false,
    Page: 1,
  };

  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
    this.translate
      .get('ThirdPartyScreeningTable.generatingReport')
      .subscribe((translation) => {
        this.generatingReportMessage = translation;
      });

    this.translate
      .get('ThirdPartyScreeningTable.reportGenerated')
      .subscribe((translation) => {
        this.reportGeneratedMessage = translation;
      });
  }

  ngOnChanges(): void {
    this.getScreenings();
  }

  sortingFunction(a: Screening, b: Screening): number {
    if (a[this.query.SortBy] > b[this.query.SortBy]) {
      return this.query.IsSortAscending ? 1 : -1;
    }

    if (a[this.query.SortBy] < b[this.query.SortBy]) {
      return this.query.IsSortAscending ? -1 : 1;
    }

    return 0;
  }

  getScreenings() {
    this.queryResult.items = this.screenings
      .sort((a, b) => this.sortingFunction(a, b))
      .slice(
        (this.query.Page - 1) * this.query.PageSize,
        this.query.Page * this.query.PageSize
      );
    this.queryResult.totalItems = this.screenings.length;
  }

  onFilterChange(event: Event) {
    this.query.Page = 1;
    this.getScreenings();
  }

  resetFilter() {
    this.query = {
      PageSize: 5,
      SortBy: '',
      IsSortAscending: false,
      Page: 1,
    };

    this.getScreenings();
  }

  sortBy(columnName) {
    this.query.Page = 1;
    this.query.SortBy = columnName.active;
    this.query.IsSortAscending = columnName.direction === 'asc';
    this.getScreenings();
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getScreenings();
  }

  viewDetails(screening: Screening) {
    const screeningId = screening.id;
    if (this.thirdPartyId) {
      this.router.navigate(['member-check/screening-details', screeningId, this.scoring.name, this.thirdPartyId], { state: { screening } });
    } else {
      this.router.navigate(['member-check/screening-details', screeningId, this.scoring.name], { state: { screening } });
    }
  }
}
