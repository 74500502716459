import { Component } from '@angular/core';

@Component({
  selector: 'app-onboarding-add-user',
  templateUrl: './onboarding-add-user.component.html',
  styles: [
  ]
})
export class OnboardingAddUserComponent {

  constructor() { }
}
