import { Component } from '@angular/core';
@Component({
  selector: 'app-riskboard',
  templateUrl: './riskboard.component.html',
  styleUrls:['./riskboard.component.scss']
})
export class RiskboardComponent {

  constructor() {}
  
}
