import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MonitoringConfigurationProxyClient } from 'src/proxy/apiMonitoringConfiguration/monitoring-configuration.proxy';
import { MonitoringConfiguration } from '@app/models/MonitoringConfiguration';
import { mapMonitoringConfiguration, mapMonitoringConfigurationDto } from './mappings/monitoring-configuration.mappings';

@Injectable({
  providedIn: 'root',
})
export class MonitoringConfigurationService {
  constructor(private monitoringConfigurationClient: MonitoringConfigurationProxyClient) {}

  public getMonitoring(refinitivThirdPartyId: number): Observable<MonitoringConfiguration> {
    return this.monitoringConfigurationClient
      .monitoringConfigurationGet(refinitivThirdPartyId)
      .pipe(
        map(monitoringConfigurationDto => mapMonitoringConfiguration(monitoringConfigurationDto))
      );
  }

  public createMonitoring(monitoringConfiguration: MonitoringConfiguration) {
    return this.monitoringConfigurationClient
      .monitoringConfigurationPost(mapMonitoringConfigurationDto(monitoringConfiguration));
  }
  
  public updateMonitoring(monitoringConfiguration: MonitoringConfiguration) {
    return this.monitoringConfigurationClient
      .monitoringConfigurationUpdatePost(mapMonitoringConfigurationDto(monitoringConfiguration));
  }

  public deleteMonitoring(refinitivThirdPartyId: number) {
    return this.monitoringConfigurationClient
      .monitoringConfigurationDeleteGet(refinitivThirdPartyId);
  }
}
