import { Component, Input } from "@angular/core";
import { ThirdPartyStatus } from "@app/models/ThirdPartyStatus";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-third-party-status',
    templateUrl: './third-party-status.component.html',
    styleUrls: ['third-party-status.component.scss']
})
export class ThirdPartyStatusComponent {

    @Input() status: number;

    pendingStatus: string;
    inProgressStatus: string;
    approvedStatus: string;
    rejectedStatus: string;
    thirdPartyStatus = ThirdPartyStatus;

    constructor(private translate: TranslateService) {
        
        this.translate.get('ThirdPartyStatus.pending').subscribe(translation => {
            this.pendingStatus = translation;
        });

        this.translate.get('ThirdPartyStatus.inProgress').subscribe(translation => {
            this.inProgressStatus = translation;
        });

        this.translate.get('ThirdPartyStatus.approved').subscribe(translation => {
            this.approvedStatus = translation;
        });
    
        this.translate.get('ThirdPartyStatus.rejected').subscribe(translation => {
            this.rejectedStatus = translation;
        });
    }

    translateStatusDescription(status: number): string {
        switch(status) {
            case ThirdPartyStatus.approved:
                return this.approvedStatus;
            case ThirdPartyStatus.rejected:
                return this.rejectedStatus;
            case ThirdPartyStatus.inProgress:
                return this.inProgressStatus;
            case ThirdPartyStatus.pending:
                return this.pendingStatus; 
            default:
                return this.pendingStatus; ;
        }
    }
}
