import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { MemberCheckScoringService } from '@app/modules/screening-hub/services/member-check-scoring.service';
import { mapMemberCheckCorpReport, mapMemberCheckIndividualReport } from '../member-check-report.mapper';
import { Screening } from '@app/models/scoring-tools/screening';

@Component({
  selector: 'member-check-scoring-detail',
  templateUrl: './member-check-scoring-detail.component.html',
  styleUrls: ['./member-check-scoring-detail.component.scss'],
})
export class MemberCheckScoringDetailComponent implements OnInit {

  screeningId: number;
  name: string;
  report: string;
  screening: Screening;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private memberCheckScoringService: MemberCheckScoringService,
    private location: Location,
    private sanitizer: DomSanitizer
  ) {
    this.screening = this.router.getCurrentNavigation().extras.state.screening;
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params) => {
      this.screeningId = params.screeningId;
      this.name = params.name;

      this.memberCheckScoringService
        .screeningDetails(this.screeningId)
        .subscribe(async (record) => {
          const userName = `${this.screening.createdBy.name} ${this.screening.createdBy.surName}`;
          const screeningDate = new Date(this.screening.screeningDate);
          let modifiedReport = record;
          if (this.screening.entityType === 1) {
            modifiedReport = await mapMemberCheckIndividualReport(record, userName, screeningDate);
          } else if (this.screening.entityType === 2) {
            modifiedReport = await mapMemberCheckCorpReport(record, userName, screeningDate);
          }
          this.report = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(modifiedReport) as any).changingThisBreaksApplicationSecurity;

          top.document.getElementById('ifrm').setAttribute("src", this.report);
        });
    });
  }

  back() {
    this.location.back();
  }
}
