<ng-container [formGroup]="form">
    <div class="container-fluid tab-content">
        <div class="row">
            <div class="col-12">
                <h3 class="o-title">{{'ListadoPreguntas.weights.title' | translate}}</h3>
                <p class="o-text">{{'ListadoPreguntas.weights.text' | translate}}</p>
            </div>
            <div *ngIf="blocks.length > 0" class="col-lg-6 col-xl-4">
                <div class="c-switches c-switches--cols">
                    <div class="c-switches--cols__group" aria-labelledby="risk-weights">
                        <div class="c-switches__items">
                            <mat-label class="mat-slide-toggle-content" style="vertical-align: top;
                                    line-height: 24px; 
                                    margin-right:0.5rem;
                                    margin-bottom:0.5rem;">
                                {{'ListadoPreguntas.weights.deactivate' | translate}}
                            </mat-label>
                            <mat-slide-toggle class="c-switch" name="weights-enabled" (change)="modifyUseWeights()"
                                [(ngModel)]="useWeights" [ngModelOptions]="{standalone: true}" [disabled]="formDisabled">
                                {{'ListadoPreguntas.weights.activate' | translate}}
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="useWeights">
            <div class="col-12">
                <ng-container formArrayName="bloques">
                    <div *ngFor="let formBlock of blocks.controls; let i = index" class="weight-container">
                        <ng-container [formGroup]="formBlock">
                            <div class="c-form__block">
                                <p class="o-title">
                                    {{formBlock.get("Risk") && formBlock.get("Risk").value?
                                    (formBlock.get("Risk").value | translatedescription) :''}}
                                </p>
                                <mat-form-field
                                    [ngClass]="{'invalid': formBlock.get('Weight').value === 0 && useWeights}"
                                    class="block-weight" appearance="outline">
                                    <input matInput type="number" formControlName="Weight" class="c-form" />
                                    <span matSuffix class="weight-suffix">%</span>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <div *ngIf="blocks.length > 0" class="weight-total">
                    <h2 class="o-title weight-total__title" [ngClass]="{'invalid': form.get('TotalWeight').value !== '100.0'}">Total</h2>
                    <mat-form-field [ngClass]="{'invalid': form.get('TotalWeight').value !== '100.0' && useWeights}"
                        class="weight-total__result" appearance="outline">
                        <input matInput formControlName="TotalWeight" class="weight-total__input" />
                        <span matSuffix class="weight-suffix">%</span>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</ng-container>