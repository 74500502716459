import { Component, Input } from "@angular/core";
import { EquifaxWSRecord } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSRecord";

@Component({
  selector: 'equifax-ws-individual',
  templateUrl: './individual-data.component.html',
  styleUrls: ['./individual-data.component.scss'],
})
export class EquifaxWSIndividualDataComponent {
  @Input() scoringDetails: EquifaxWSRecord;

  constructor() { }
}
