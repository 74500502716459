export function toQueryString(obj) {
  var parts = [];
  for (var property in obj) {
    var value = obj[property];
    if (value != null && value != undefined)
      parts.push(
        encodeURIComponent(property) + '=' + encodeURIComponent(value)
      );
  }

  return parts.join('&');
}

export function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function parsearErroresAPI(response: any): string[] {
  const resultado: string[] = [];

  if (response.error) {
    if (typeof response.error === 'string') {
      resultado.push(response.error);
    } else if (Array.isArray(response.error)) {
      response.error.forEach((valor) => resultado.push(valor.description));
    } else {
      const mapaErrores = response.error.errors;
      const entradas = Object.entries(mapaErrores);
      entradas.forEach((arreglo: any[]) => {
        const campo = arreglo[0];
        arreglo[1].forEach((mensajeError) => {
          resultado.push(`${campo}: ${mensajeError}`);
        });
      });
    }
  }

  return resultado;
}

export function groupByKey(data, key) {
  return data.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function formatearFecha(date: Date) {
  const formato = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minute },
  ] = formato.formatToParts(date);

  return `${year}-${month}-${day} ${hour}:${minute}`;
}

import { ThirdPartyDTO } from '../models/ThirdParty';
export interface Table3PPConfiguration {
  columnas: Array<string>;
  acciones: Array<string>;
  tipo?: string;
  filterByTypes: boolean;
  tableTarget?: string;
  campaign?: number;
  importedItems?: Array<ThirdPartyDTO>;
}

export interface TableTemplateConfiguration {
  columnas: Array<string>;
  filterByTypes: boolean;
  acciones: Array<string>;
}

export interface TableMyrisksConfiguration {
  columnas: Array<string>;
  columnasExpanded: Array<string>;
  acciones: Array<string>;
}

export interface TableSectoresConfiguration {
  columnas: Array<string>;
  acciones: Array<string>;
  data: any;
}

export function sort(
  a: any,
  b: any,
  sortingField: string,
  isAscending: boolean
): number {
  if (a[sortingField] > b[sortingField]) {
    return isAscending ? 1 : -1;
  }

  if (a[sortingField] < b[sortingField]) {
    return isAscending ? -1 : 1;
  }

  return 0;
}
