import { Injectable } from '@angular/core';
import { CompanyData } from '@app/models/CompanyData';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyProxyClient } from 'src/proxy/apiCompany/company.proxy';
import { mapCompanyData } from './mappings/company.mappings';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    
    constructor(private companyClient: CompanyProxyClient){}

    getCompanyData(): Observable<CompanyData> {
        return this.companyClient.companyCompanyDataGet().pipe(map(s => mapCompanyData(s)));
    }
}
