<!--formulario-->

<div class="c-form c-form--space">
  <form [formGroup]="form" (submit)="saveChanges()" id="form-config" autocomplete="off" class="c-form__form">
    <fieldset class="u-mb-1medium">
      <legend class="c-form__legend">{{'FormularioThird.form.section.cat.valor' | translate}}</legend>
      <div class="row">
        <!--tipo de tercero-->
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select [compareWith]="objectComparisonFunction" formControlName="ThirdPartyType"
                  placeholder="{{'FormularioThird.form.third.type.valor' | translate}}">
                  <mat-option *ngFor="let type of thirdPartyTypeNoSelected" [value]="type">
                    {{type | translatedescription}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!--sector-->
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select [compareWith]="objectComparisonFunction" formControlName="Sector"
                  placeholder="{{'FormularioThird.form.third.sector.valor' | translate}}" floatlLabel="never">
                  <mat-option *ngFor="let sector of sectorsNoSelected" [value]="sector">
                    {{sector | translatedescription}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{'FormularioThird.form.company.nif_error.valor' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!--asociar responsable-->
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select [compareWith]="objectComparisonFunction" formControlName="UserResponsible"
                  placeholder="{{'FormularioThird.form.third.responsible.valor' | translate}}" floatlLabel="never"
                  required>
                  <mat-option *ngFor="let responsible of responsiblesNoSelected" [value]="responsible">
                    {{responsible.Name}} {{responsible.SurName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-error role="alert" *ngIf="isSubmitted && UserResponsible.errors?.required" class="c-form-error">
              <p class="c-form-error__text">
                {{'FormularioThird.form.third.responsible_error.valor' | translate}}
              </p>
            </mat-error>
          </div>
        </div>
        <!--Status-->
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select formControlName="ThirdPartyStatusSelect"
                  placeholder="{{'FormularioThird.form.third.status' | translate}}">
                  <mat-option *ngFor="let status of possibleStatus" [value]="status.code">
                    {{status.description}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select formControlName="ThirdPartyInherentRiskSelect"
                  placeholder="{{'FormularioThird.form.third.inherentRisk' | translate}}">
                  <mat-option *ngFor="let inherentRisk of inherentRisks" [value]="inherentRisk">
                    {{getInherentRiskName(inherentRisk) | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="u-mb-1">
      <legend class="c-form__legend">{{'FormularioThird.form.section.thirdPartyDetails.valor' | translate}}</legend>
      <div class="row">
        <!--cif-->
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="CompanyCIF" value="" id="config-CompanyCIF" class="c-form__input"
                formControlName="CompanyCIF" placeholder="{{'FormularioThird.form.company.nif.valor' | translate}}" />
              <mat-error>
                {{'FormularioThird.form.company.nif_error.valor' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!--nombre empresa-->
        <div class="col-12 col-lg-6 col-xl-5">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="CompanyName" value="" id="config-CompanyName" class="c-form__input"
                formControlName="CompanyName" placeholder="{{'FormularioThird.form.company.name.valor' | translate}}"
                required />
              <mat-error>
                {{'FormularioThird.form.company.name_error.valor' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="Alias" value="" id="config-Alias" class="c-form__input"
                formControlName="Alias" placeholder="{{'FormularioThird.form.company.alias' | translate}}" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <!--naturaleza de la actividad-->
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select [compareWith]="objectComparisonFunction" formControlName="NatureOfActivity"
                  placeholder="{{'FormularioThird.form.company.nature.valor' | translate}}">
                  <mat-option *ngFor="let natureOfActivity of natureOfActivityNoSelected" [value]="natureOfActivity">
                    {{natureOfActivity | translatedescription}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!--Tip de actividad-->
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select [compareWith]="objectComparisonFunction" formControlName="TypeOfActivity"
                  placeholder="{{'FormularioThird.form.company.activity.valor' | translate}}">
                  <mat-option *ngFor="let typeOfActivity of typeOfActivityNoSelected"
                    [value]="typeOfActivity">{{typeOfActivity | translatedescription}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="Website" value="" id="config-Website" class="c-form__input"
                formControlName="Website" placeholder="{{'FormularioThird.form.company.website' | translate}}" />
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="Fax" value="" id="config-Fax" class="c-form__input"
                formControlName="Fax" placeholder="{{'FormularioThird.form.company.fax' | translate}}" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <!--país-->
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select [compareWith]="objectComparisonFunction" formControlName="Country"
                  placeholder="{{'FormularioThird.form.third.country.valor' | translate}} *">
                  <mat-option *ngFor="let country of countriesNoSelected" [value]="country">
                    {{country | translatedescription}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{'FormularioThird.form.company.nif_error.valor' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select [compareWith]="objectComparisonFunction" formControlName="Country"
                  placeholder="{{'FormularioThird.form.third.countryCode' | translate}}">
                  <mat-option *ngFor="let country of countriesNoSelected" [value]="country">
                    {{country.Code}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!--provincia-->
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="c-form__block">
            <mat-form-field>

              <input type="text" matInput name="CompanyProvince" value="" id="config-CompanyProvince"
                class="c-form__input" formControlName="ThirdProvince"
                placeholder="{{'FormularioThird.form.company.province.valor' | translate}}" />
              <mat-error>
                {{'FormularioThird.form.company.province_error.valor' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <!--dirección-->
        <div class="col-12 col-lg-6 col-xl-9">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="CompanyAddress" value="" id="config-CompanyAddress"
                class="c-form__input" formControlName="CompanyAddress"
                placeholder="{{'FormularioThird.form.company.address.valor' | translate}}" />
              <mat-error>
                {{'FormularioThird.form.company.address_error.valor' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!--código postal-->
        <div class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="CompanyCP" value="" id="config-CompanyCP" class="c-form__input"
                formControlName="CompanyCP" placeholder="{{'FormularioThird.form.company.pc.valor' | translate}}" />
              <mat-error>
                {{'FormularioThird.form.company.pc_error.valor' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend class="c-form__legend">{{'FormularioThird.form.section.contact.valor' | translate}}</legend>
      <div class="row">
        <!--nombre contacto-->
        <div *ngIf="this.model && this.model.Contact.Name!==null && !this.isSaeta" class="col-12 col-lg-6 col-xl-4">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="ContactName" value="" id="config-ContactName" class="c-form__input"
                formControlName="ContactName" placeholder="{{'FormularioThird.form.contact.name.valor' | translate}}" />
              <mat-error>
                {{'FormularioThird.form.contact.name_error.valor' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!--apellidos contacto-->
        <div *ngIf="this.model && this.model.Contact.Surnames!==null && !this.isSaeta" class="col-12 col-lg-6 col-xl-4">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="ContactSurnames" value="" id="config-ContactSurnames"
                class="c-form__input" formControlName="ContactSurnames"
                placeholder="{{'FormularioThird.form.contact.surname.valor' | translate}}" />
              <mat-error>
                {{'FormularioThird.form.contact.surname_error.valor' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!--email contacto-->
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="c-form__block">
            <mat-form-field>
              <input type="email" matInput name="ContactEmail" value="" id="config-ContactEmail" class="c-form__input"
                formControlName="ContactEmail" placeholder="{{'FormularioThird.form.contact.email.valor' | translate}}"
                required />
            </mat-form-field>
            <div *ngIf="mailContactNoValid" role="alert" aria-labelledby="config-mailContact" class="c-form-error">
              <p class="c-form-error__text">
                {{'FormularioThird.form.contact.email_error.valor' | translate}}
              </p>
            </div>
            <div *ngIf="mailContactExist" role="alert" aria-labelledby="config-mailContact" class="c-form-error">
              <p class="c-form-error__text">
                Usuario ya existente.
              </p>
            </div>
          </div>
        </div>
        <!--puesto laboral-->
        <div *ngIf="this.model && this.model.Contact.Position!==null && !this.isSaeta" class="col-12 col-lg-6 col-xl-3">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="ContactPosition" value="" id="config-ContactPosition"
                class="c-form__input" formControlName="ContactPosition"
                placeholder="{{'FormularioThird.form.contact.role.valor' | translate}}" />
              <mat-error>
                {{'FormularioThird.form.contact.role_error.valor' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="c-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select formControlName="ContactLanguage" id="ContactLanguage"
                  placeholder="{{'MiCuenta.language'|translate}}">
                  <mat-option *ngFor="let language of languagesNoSelected" [value]="language">
                    {{ getLanguageTranslationKey(language) | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="c-form__block">
            <mat-form-field>
              <input type="text" matInput name="ContactPhoneNumber" value="" id="" class="c-form__input"
                formControlName="ContactPhoneNumber"
                placeholder="{{'FormularioThird.form.contact.phone' | translate}}" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset *ngIf="customFieldsControl.length > 0">
      <legend class="c-form__legend">{{'FormularioThird.form.section.customFields' | translate}}</legend>
      <div class="row">
        <!--nombre contacto-->
        <ng-container *ngFor="let customField of customFieldsControl.controls; let i = index">
          <div [ngClass]="{ 'col-lg-6' : customField.get('type').value.id == 0 || customField.get('type').value.id == 1, 'col-lg-12': customField.get('type').value.id == 2 }">
            <div class="c-form__block" [formGroup]="customField">
              <!-- Text field -->
              <mat-form-field *ngIf="customField.get('type').value.id == 0">
                <input type="text" matInput name="ContactName" [id]="'custom-field-'+customField.get('id').value"
                  class="c-form__input" formControlName="userValue"
                  placeholder="{{customField.value | translatedescription}}{{ customField.get('mandatory').value ? ' *' : '' }}" />
                <mat-error *ngIf="customField.get('mandatory').value">
                  {{'FormularioThird.form.company.nif_error.valor' | translate}}
                </mat-error>
              </mat-form-field>
              <!-- Multiline text field -->
              <mat-form-field *ngIf="customField.get('type').value.id == 2">
                <mat-label>{{customField.value | translatedescription}}</mat-label>
                <textarea matInput rows="3" class="c-form__textarea c-formulario-third__textarea" name="ContactName"
                  [id]="'custom-field-'+customField.get('id').value" formControlName="userValue">
                    {{customField.value | translatedescription}}{{ customField.get('mandatory').value ? ' *' : '' }}
                </textarea>
                <mat-error *ngIf="customField.get('mandatory').value">
                  {{'FormularioThird.form.company.nif_error.valor' | translate}}
                </mat-error>
              </mat-form-field>
              <!-- Selection field type -->
              <div class="c-select" *ngIf="customField.get('type').value.id == 1">
                <mat-form-field *ngIf="!customField.get('hasTranslations').value">
                  <mat-select formControlName="userValue" [compareWith]="objectComparisonByIdFunction"
                    [id]="'custom-field-'+customField.get('id').value"
                    placeholder="{{customField.value | translatedescription}}{{ customField.get('mandatory').value ? ' *' : '' }}">
                    <mat-option *ngFor="let option of customField.get('options').value" [value]="option.id">
                      {{option.description}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="customField.get('mandatory').value">
                    {{'FormularioThird.form.company.nif_error.valor' | translate}}
                  </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="customField.get('hasTranslations').value">
                  <mat-select formControlName="userValue" [compareWith]="objectComparisonByIdFunction"
                    [id]="'custom-field-'+customField.get('id').value"
                    placeholder="{{customField.value | translatedescription}}{{ customField.get('mandatory').value ? ' *' : '' }}">
                    <mat-option *ngFor="let option of customField.get('options').value" [value]="option.id">
                      {{option | translatedescription}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="customField.get('mandatory').value">
                    {{'FormularioThird.form.company.nif_error.valor' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </fieldset>
    <fieldset *ngIf="showBusinessCentral()">
      <legend class="c-form__legend">Business central</legend>
      <div class="row">
        <div class="col-3 c-form__block">
          <button type="button" class="c-button c-button--gradient" (click)="shareTPWithBC()">
            {{'FormularioThird.businessCentral.button' | translate}}
          </button>
        </div>
        <div class="col-4 c-form__block" *ngIf="model?.ThirdPartyConfig?.LastUpdateUser">
          {{'FormularioThird.businessCentral.lastModification' | translate}}
          <app-responsible-user [responsibleUserId]="model?.ThirdPartyConfig?.LastUpdateUser"></app-responsible-user>
        </div>
      </div>
    </fieldset>
    <div *ngIf="this.model != undefined" class="row">
      <div class="col-12 d-flex justify-content-end align-center">
        <ng-container *appAuthRole="['Owner','Manager']">
          <button *ngIf="this.model.CanRemove" (click)="openDialogDeleteThirparty()" type="button"
            class="c-button c-button--remove u-mr-1">
            {{'FormularioThird.form.button.delete.valor' | translate}}
          </button>
        </ng-container>
        <ng-container *appAuthRole="['Owner','Manager','Assistant','Collaborator']">
          <button type="submit" class="c-button" form="form-config" [disabled]="!form.valid || !form.dirty">
            {{'FormularioThird.form.button.save.valor' | translate}}
          </button>
        </ng-container>
      </div>
    </div>
  </form>
</div>
