<div class="o-wrapper o-wrapper--no-padding">
    <main id="content">
        <div *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
        </div>
        <div *ngIf="!isLoading" [formGroup]="form">
            <section class="c-risklist">
                <div class="c-head-section">
                    <div class="c-head-section__left">
                        <h2 class="o-title o-title--small" lang="en">risklist</h2>
                        <h3 class="o-title">{{'RisklistFill.title1.valor'|translate}}</h3>
                    </div>
                    <div class="c-head-section__right">
                        <div *ngIf="form.valid && !form.dirty && !errorWhenSaving">
                            <button *ngIf="!submitted" type="button" (click)="continue()"
                                class="c-button">{{'RisklistFill.sign.valor'|translate}}</button>
                        </div>
                        <div>
                          <button *ngIf="!submitted" type="button" [disabled]="!form.dirty || submittingAnswers" (click)="generateOutput()"
                              class="c-button">
                              {{'RisklistFill.save.valor'|translate}}
                          </button>
                          <mat-progress-bar *ngIf="submittingAnswers" mode="indeterminate" class="c-spinner"></mat-progress-bar>
                        </div>
                    </div>
                </div>
                <!--tabs-->
                <mat-tab-group #tabs class="c-tabs" animationDuration="0ms">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div formArrayName="bloques">
                            <div *ngFor="let bloqueForm of bloques.controls; let bloqueIndex = index">
                                <div [formGroupName]="bloqueIndex">
                                    <mat-tab label="{{getBlockName(bloqueForm)}}">
                                        <!--bloque progreso-->
                                        <div class="c-risklist-progress">
                                            <!--parte izquierda progress-->
                                            <div class="c-risklist-progress__left">
                                                <!--izquierda progress flecha-->
                                                <button type="button" class="c-risklist-progress__button prev"
                                                    aria-label="Anterior" (click)="previousTab()">
                                                    <span aria-hidden="true">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                                                            <path
                                                                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                                                                transform="translate(-5-6.01)" />
                                                        </svg>
                                                    </span>
                                                </button>
                                                <!--izquierda progress textos-->
                                                <div class="c-risklist-progress-title">
                                                    <figure class="c-risklist-progress-title__ico" aria-hidden="true">
                                                        <img src="assets/hourglass.svg" alt="Reloj de arena" />
                                                    </figure>
                                                    <div class="c-risklist-progress-title__text">
                                                        <h4 class="o-title o-title--small dark">
                                                            {{'RisklistFill.progreso.valor'|translate}}:</h4>
                                                        <h5 class="o-title o-title--small">{{getBlockName(bloqueForm)}}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <!--izquierda progress barra progreso-->
                                                <div class="c-progress-bar c-progress-bar--row">
                                                    <mat-progress-bar aria-labelledby="progressBar"
                                                        class="c-progress-bar__bar"
                                                        value="{{getvalidQuestionsRateNum(bloqueForm)}}">
                                                    </mat-progress-bar>
                                                    <p id="progressBar" class="c-progress-bar__percent">
                                                        <span
                                                            *ngIf="showRateCompleted">{{getvalidQuestionsRate(bloqueForm)}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <!--parte derecha progress-->
                                            <div class="c-risklist-progress__right">
                                                <button type="button" class="c-risklist-progress__button next"
                                                    (click)="nextTab()" aria-label="Siguiente">
                                                    <span aria-hidden="true">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                                                            <path
                                                                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                                                                transform="translate(-5-6.01)" />
                                                        </svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="o-scroll">
                                            <!--bloque preguntas-->
                                            <div formArrayName="Questions">
                                                <div *ngFor="let q of bloqueQuestions(bloqueIndex).controls; let questionIndex = index"
                                                    class="c-risklist-question-wrapper">
                                                    <div [formGroupName]="questionIndex" class="u-pl-2 u-mt-1">
                                                        <!--cada ítem-->
                                                        <article class="c-risklist-question">
                                                            <div [ngClass]="[bloqueQuestions(bloqueIndex).at(questionIndex).invalid ? 'question-error' : 'question-ok']">
                                                                <h1 class="c-risklist-question__title">
                                                                    {{getBlockName(bloqueForm)}} - {{questionIndex+1}}
                                                                </h1>
                                                            </div>
                                                            <div class="c-risklist-question__top">
                                                                <div class="c-text-toggle">
                                                                    <div class="c-text-toggle__item">
                                                                        <p id="answerSelectionGroup"
                                                                            class="o-text o-text--bold u-pl-0" style="white-space: break-spaces;">
                                                                            {{q.get('Description').value}}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="c-risklist-question__bottom">
                                                                <div class="c-extra-info">
                                                                    <label>{{'RisklistFill.answer'|translate}}</label>
                                                                </div>
                                                                <mat-radio-group *ngIf="!q.get('IsMultipleChoice')?.value" aria-label="Select an option" formControlName="Answer">
                                                                    <mat-radio-button value="true">{{'RisklistFill.yes'|translate}}</mat-radio-button>
                                                                    <mat-radio-button value="false">{{'RisklistFill.no'|translate}}</mat-radio-button>
                                                                </mat-radio-group>
                                                                <app-multiple-choice
                                                                  *ngIf="q.get('IsMultipleChoice')?.value"
                                                                  [rawOptions]="q.get('Options').value"
                                                                  [blockIndex]="bloqueIndex"
                                                                  [questionIndex]="questionIndex"
                                                                ></app-multiple-choice>
                                                                <div class="c-extra-info">
                                                                    <div [ngClass]="[bloqueQuestions(bloqueIndex).at(questionIndex).hasError('requiredComment') ? 'input-error' : '']">
                                                                        <label
                                                                            *ngIf="bloqueQuestions(bloqueIndex).at(questionIndex).get('Comment')"
                                                                        >{{'RisklistFill.addRemarks'|translate}}
                                                                        </label>
                                                                    </div>
                                                                    <div class="c-extra-info__content">
                                                                        <textarea matInput *ngIf="hasComment(q)"
                                                                            formControlName="Comment" id="infoExtra"
                                                                            [placeholder]="submitted ? '' : 'Añade comentarios'"
                                                                            class="c-extra-info__textarea"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="c-extra-info-add">
                                                                    <div [ngClass]="[bloqueQuestions(bloqueIndex).at(questionIndex).hasError('requiredDocument') ? 'input-error' : '']">
                                                                        <label
                                                                            *ngIf="bloqueQuestions(bloqueIndex).at(questionIndex).get('Doc')"
                                                                            for="infoExtraAdd"
                                                                            [ngClass]="['c-extra-info__content']"
                                                                        >{{'RisklistFill.attachDocument'|translate}}
                                                                        </label>
                                                                    </div>

                                                                    <app-file-upload *ngIf="hasDoc(q) && !submitted"
                                                                        [buttonText]="getButtonText(bloqueIndex,questionIndex)"
                                                                        formControlName="Doc" [qid]="q.get('Id').value"
                                                                        (fileSelected)="selectDocument($event)"
                                                                        id="infoExtraAdd"
                                                                        class="c-extra-info-add__file">
                                                                    </app-file-upload>
                                                                    <a *ngIf="hasDoc(q) && submitted && q.get('Doc').value"
                                                                        (click)="download(q.get('Doc').value.FileUri, q.get('Doc').value.FileName)"
                                                                        class="c-ico-link__link"
                                                                        [title]="q.get('Doc').value.FileName"
                                                                        style="cursor: pointer;">
                                                                        {{ q.get('Doc').value.FileName }}</a>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-tab-group>
            </section>
        </div>
    </main>
</div>
