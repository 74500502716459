import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Country } from '@app/models/Country';
import { GesintelBothFilter } from '@app/modules/screening-hub/models/GesintelScreening/GesintelBothFilter';

@Component({
  selector: 'app-scoring-candidates-both-search-gesintel',
  templateUrl: './scoring-candidates-both-search-gesintel.component.html',
  styleUrls: ['./scoring-candidates-both-search-gesintel.component.scss'],
})
export class ScoringCandidatesBothSearchGesintelComponent {
  @Input() countryList: Country[];
  @Output() onSearchClick = new EventEmitter<GesintelBothFilter>();

  model: GesintelBothFilter;

  constructor() {
    this.model = new GesintelBothFilter();
  }

  search() {
    this.onSearchClick.emit(this.model);
  }
}
