import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { DialogSendRisklistComponent } from '@app/modules/myrisks/dialog-send-risklist/dialog-send-risklist.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { QuestionaryEvaluationDto } from '@app/models/Questionary';
import { QuestionariesService } from '@app/services/questionaries-service';
import { CampaignService } from '@app/services/campaign-service';
import { Campaign } from '@app/models/Campaign';

@Component({
  selector: 'app-risklist-sign',
  templateUrl: './risklist-sign.component.html',
  styleUrls: ['./risklist-sign.component.scss']
})
export class RisklistSignComponent implements OnInit {

  form: any;
  evaluation: number;
  thirdPartyId: number;
  campaign: Campaign;
  isLoading: boolean = true;
  questionary: QuestionaryEvaluationDto;

  constructor(

    public dialog: MatDialog,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toaster: Toaster,
    private translate: TranslateService,
    private campaignService: CampaignService,
    private questionaryService: QuestionariesService
  ) { }

  async ngOnInit(): Promise<void> {
    this.evaluation = this.activatedRoute.snapshot.queryParams.ca;
    this.thirdPartyId = this.activatedRoute.snapshot.queryParams.third;

    this.form = this.fb.group({
      Sign: new FormControl(false, [Validators.requiredTrue]),
      Sign2: new FormControl(false, [Validators.requiredTrue])
    })

    this.campaignService.getCampaignForPageId(this.evaluation).subscribe(r => {
      this.campaign = r;
      this.isLoading = false;
    })

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('o-risklist', 'js-noHeader');
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('o-risklist', 'js-noHeader');
  }

  canSign(): boolean {
    return (this.form.get('Sign').valid && this.form.get('Sign2').valid)
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogSendRisklistComponent, {
      data: {
        campaign: this.campaign.name,
        client: this.campaign.client.name,
        date: this.campaign.endDate
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        this.router.navigate(['/rellena-risklist'], { queryParams: { ca: this.campaign.id.toString(), third: this.thirdPartyId.toString() } });
      } else {
        this.questionaryService.submitQuestionary(this.evaluation, this.thirdPartyId)
          .subscribe(
            r => {
              switch (r) {
                case "OK":
                  this.router.navigate(['/risklist-enviado']);
                  break;
                case "MISSING_ANSWER":
                  this.translate.get('Firma.missingAnswers.valor').subscribe((text: string) => {
                    this.toaster.open({ text: text, duration: 3000, type: 'danger' });
                  });
                  this.router.navigate(['/rellena-risklist'], { queryParams: { ca: this.campaign.id.toString(), third: this.thirdPartyId.toString() } });
                  break;
                default:
                  break;
              }
            },
            err => {
              this.translate.get('Firma.error.valor').subscribe((text: string) => {
                this.toaster.open({ text: text, duration: 3000, type: 'danger' });
              });
              this.router.navigate(['/rellena-risklist'], { queryParams: { ca: this.campaign.id.toString(), third: this.thirdPartyId.toString() } });
            })
      }
    })
  }
}
