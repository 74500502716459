<fieldset class="u-mb-1medium">
  <legend class="c-form__legend">
    {{ "ScoringCandidatesSearchGesintel.query" | translate }}
  </legend>
  <div class="row">
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>
            {{ "ScoringCandidatesSearchGesintel.filter.nameOrRut" | translate }}
          </mat-label>
          <input
            type="text"
            matInput
            name="name"
            value=""
            id="name"
            [(ngModel)]="model.name"
            class="c-form__input"
            placeholder="{{'ScoringCandidatesSearchGesintel.filter.nameOrRut' | translate}}"
            required
          />
          <mat-error>
            {{ "ScoringCandidatesSearchGesintel.required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-12">
      <legend class="c-form__legend">
        {{ "ScoringCandidatesSearchGesintel.individual.personalData" | translate }}
      </legend>
      <div class="row">
        <div class="c-form__block col-12 col-md-4">
          <div class="c-select">
            <mat-form-field>
              <mat-select
                placeholder="{{
                  'ScoringCandidatesSearchGesintel.filter.residenceCountry'
                    | translate
                }}"
                [(ngModel)]="model.country"
              >
                <mat-option *ngFor="let country of countryList"
                  [value]="country.codeAlpha3"
                >
                  {{ country.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="c-form__block col-12 col-md-4">
          <div class="c-select">
            <mat-form-field>
              <mat-select
                placeholder="{{'ScoringCandidatesSearchGesintel.filter.nationality' | translate}}"
                [(ngModel)]="model.nationality"
              >
                <mat-option *ngFor="let country of countryList"
                  [value]="country.codeAlpha3"
                >
                  {{ country.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="c-form__block col-12 col-md-4">
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="{{
                'ScoringCandidatesSearchGesintel.filter.birthDate' | translate
              }}"
              [(ngModel)]="model.birthDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div> -->
</fieldset>
<fieldset class="u-mb-1medium">
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-3">
      <div class="c-form__block">
        <button
          (click)="search()"
          type="button"
          [disabled]="!model.name?.length"
          class="c-button u-mr-1"
        >
          {{ "ScoringCandidatesSearchGesintel.search" | translate }}
        </button>
      </div>
    </div>
  </div>
</fieldset>
