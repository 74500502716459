import { Component, Input } from "@angular/core";
import { EquifaxWSIndividualData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSIndividualData";

@Component({
  selector: 'equifax-ws-credit-data',
  templateUrl: './credit-data.component.html',
  styleUrls: ['./credit-data.component.scss'],
})
export class EquifaxWSCreditDataComponent {
  @Input() individualDetails: EquifaxWSIndividualData;

  constructor() { }
}
