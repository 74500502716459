<ng-container [formGroup]="form">
  <div cdkDropList (cdkDropListDropped)="dropBlock($event)">
    <ng-container formArrayName="bloques">
      <mat-accordion class="c-accordion c-accordion--actions" multi>
        <ng-container *ngFor="let bloqueForm of bloques.controls; let i = index">
          <div [formGroup]="bloqueForm" cdkDrag [cdkDragDisabled]="!this.canEdit">
            <mat-expansion-panel class="c-accordion__panel">
              <mat-expansion-panel-header>
                <mat-panel-title class="c-accordion__title">
                  <ul *appAuthRole="['Owner', 'Manager']" class="c-action-icons">
                    <li class="c-action-icons__item" *ngIf="canEdit">
                      <div class="example-handle" cdkDragHandle>
                        <button type="button" matTooltip="Mover" class="c-action-icons__button" aria-label="Mover">
                          <span class="c-action-icons__ico" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19.979">
                              <path
                                d="M19.836,9.886,17.445,7.495a.577.577,0,0,0-.8,0l-.3.3a.557.557,0,0,0,0,.791l.889.884H10.78V3.03l.887.9a.569.569,0,0,0,.79,0l.3-.3a.564.564,0,0,0,0-.8L10.359.43a.567.567,0,0,0-.4-.17H9.944a.565.565,0,0,0-.4.17L7.2,2.779a.567.567,0,0,0,0,.8l.3.3a.59.59,0,0,0,.806,0l.909-.9V9.472H2.756l.9-.895a.559.559,0,0,0,0-.792l-.3-.3a.578.578,0,0,0-.8,0L.164,9.878a.585.585,0,0,0,0,.812l2.345,2.345a.578.578,0,0,0,.8,0l.3-.3a.566.566,0,0,0,0-.8l-.9-.9H9.219v6.505L8.27,16.6a.583.583,0,0,0-.8,0l-.3.3a.564.564,0,0,0,0,.8l2.376,2.376a.562.562,0,0,0,.4.164h.011a.559.559,0,0,0,.4-.164l2.391-2.392a.564.564,0,0,0,0-.8l-.3-.3a.561.561,0,0,0-.783,0l-.876.889v-6.44H17.3l-.939.94a.565.565,0,0,0,0,.8l.3.3a.577.577,0,0,0,.8,0L19.836,10.7a.585.585,0,0,0,0-.812Z"
                                transform="translate(0-.26)" />
                            </svg>
                          </span>
                        </button>
                      </div>
                    </li>
                    <li class="c-action-icons__item" *ngIf="canEdit">
                      <button type="button" matTooltip="Borrar" class="c-action-icons__button" aria-label="Eliminar"
                        (click)="deleteBloque(i)">
                        <span class="c-action-icons__ico" aria-hidden="true">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.667 20"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path
                              d="M20.833,3.333H18.25A4.174,4.174,0,0,0,14.167,0H9.5A4.174,4.174,0,0,0,5.417,3.333H2.833A.833.833,0,0,0,2.833,5h.833V15.833A4.172,4.172,0,0,0,7.833,20h8A4.172,4.172,0,0,0,20,15.833V5h.833a.833.833,0,0,0,0-1.667ZM9.5,1.667h4.667a2.5,2.5,0,0,1,2.358,1.667H7.142A2.5,2.5,0,0,1,9.5,1.667Zm8.833,14.167a2.5,2.5,0,0,1-2.5,2.5h-8a2.5,2.5,0,0,1-2.5-2.5V5h13Z"
                              transform="translate(-2)" />
                            <path
                              d="M9.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,9.833,16.667Z"
                              transform="translate(-3.333-1.666)" />
                            <path
                              d="M13.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,13.833,16.667Z"
                              transform="translate(-4-1.666)" id="0" />
                            <use transform="translate(3.333)" xlink:href="#0" />
                          </svg>
                        </span>
                      </button>
                    </li>
                  </ul>
                  {{
                  bloqueForm.get("Risk") && bloqueForm.get("Risk").value
                  ? (bloqueForm.get("Risk").value | translatedescription)
                  : ""
                  }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div #xx="cdkDropList" cdkDropList (cdkDropListDropped)="dropQuestion(bloqueForm, $event)">
                <mat-panel-description>
                  <div *ngIf="!questionary?.isMaster" class="c-question c-question--full c-question--head">
                    <div class="row">
                      <div class="col-lg-6 col-xl-3">
                        <div class="c-select">
                          <mat-select formControlName="Risk" [compareWith]="objectComparisonFunction" placeholder="{{
                              'ListadoPreguntas.form.riskBlock.valor'
                                | translate
                            }}" id="risk" (selectionChange)="blockNameChange($event)">
                            <mat-option *ngFor="
                                let tipo of unusedRiskTypesWithRisk(
                                  bloqueForm.get('Risk').value
                                )
                              " [value]="tipo">
                              {{ tipo | translatedescription }}
                            </mat-option>
                          </mat-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngFor="
                      let questionForm of bloqueForm.controls['questions']
                        .controls;
                      let j = index
                    ">
                    <div [formGroup]="questionForm" cdkDrag [cdkDragDisabled]="!this.canEdit">
                      <div class="c-question c-question--full col-2">
                        <!-- Iconos de las preguntas -->
                        <div class="c-question--full__left">
                          <ul *appAuthRole="['Owner', 'Manager']" class="c-action-icons">
                            <li class="c-action-icons__item" *ngIf="canEdit">
                              <button type="button" matTooltip="Mover" class="c-action-icons__button" aria-label="Mover"
                                cdkDragHandle>
                                <span class="c-action-icons__ico" aria-hidden="true">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19.979">
                                    <path
                                      d="M19.836,9.886,17.445,7.495a.577.577,0,0,0-.8,0l-.3.3a.557.557,0,0,0,0,.791l.889.884H10.78V3.03l.887.9a.569.569,0,0,0,.79,0l.3-.3a.564.564,0,0,0,0-.8L10.359.43a.567.567,0,0,0-.4-.17H9.944a.565.565,0,0,0-.4.17L7.2,2.779a.567.567,0,0,0,0,.8l.3.3a.59.59,0,0,0,.806,0l.909-.9V9.472H2.756l.9-.895a.559.559,0,0,0,0-.792l-.3-.3a.578.578,0,0,0-.8,0L.164,9.878a.585.585,0,0,0,0,.812l2.345,2.345a.578.578,0,0,0,.8,0l.3-.3a.566.566,0,0,0,0-.8l-.9-.9H9.219v6.505L8.27,16.6a.583.583,0,0,0-.8,0l-.3.3a.564.564,0,0,0,0,.8l2.376,2.376a.562.562,0,0,0,.4.164h.011a.559.559,0,0,0,.4-.164l2.391-2.392a.564.564,0,0,0,0-.8l-.3-.3a.561.561,0,0,0-.783,0l-.876.889v-6.44H17.3l-.939.94a.565.565,0,0,0,0,.8l.3.3a.577.577,0,0,0,.8,0L19.836,10.7a.585.585,0,0,0,0-.812Z"
                                      transform="translate(0-.26)" />
                                  </svg>
                                </span>
                              </button>
                            </li>
                            <li class="c-action-icons__item" *ngIf="canEdit">
                              <button type="button" matTooltip="Borrar" class="c-action-icons__button"
                                aria-label="Eliminar" (click)="deleteQuestion(i, j)">
                                <span class="c-action-icons__ico" aria-hidden="true">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.667 20"
                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <path
                                      d="M20.833,3.333H18.25A4.174,4.174,0,0,0,14.167,0H9.5A4.174,4.174,0,0,0,5.417,3.333H2.833A.833.833,0,0,0,2.833,5h.833V15.833A4.172,4.172,0,0,0,7.833,20h8A4.172,4.172,0,0,0,20,15.833V5h.833a.833.833,0,0,0,0-1.667ZM9.5,1.667h4.667a2.5,2.5,0,0,1,2.358,1.667H7.142A2.5,2.5,0,0,1,9.5,1.667Zm8.833,14.167a2.5,2.5,0,0,1-2.5,2.5h-8a2.5,2.5,0,0,1-2.5-2.5V5h13Z"
                                      transform="translate(-2)" />
                                    <path
                                      d="M9.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,9.833,16.667Z"
                                      transform="translate(-3.333-1.666)" />
                                    <path
                                      d="M13.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,13.833,16.667Z"
                                      transform="translate(-4-1.666)" id="0" />
                                    <use transform="translate(3.333)" xlink:href="#0" />
                                  </svg>
                                </span>
                              </button>
                            </li>
                          </ul>
                        </div>
                        <!-- contenido de la pregunta -->
                        <div class="c-question--full__right">
                          <!-- texto pregunta -->
                          <div class="row u-mb-editBlock">
                            <div class="col-12">
                              <div class="c-form__block">
                                <mat-form-field>
                                  <textarea matInput type="text" formControlName="Description"
                                    class="c-form"></textarea>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="row u-mb-editBlock">
                            <div class="col-lg-12">
                              <div class="c-select c-select--cols">
                                <label for="typeQuestion" class="c-select__label c-select__label--mb">
                                  {{ "ListadoPreguntas.form.questionType.valor" | translate }}
                                </label>
                                <div id="typeQuestion" class="c-select--cols__selects">
                                  <mat-form-field class="col-2">
                                    <mat-select formControlName="TipoPregunta"
                                      placeholder="{{ 'ListadoPreguntas.form.questionType.valor' | translate }}">
                                      <mat-option *ngFor="let tp of tipoPreguntasValues" [value]="tp.value">
                                        {{ tp.text }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <mat-form-field class="col-3">
                                    <mat-select formControlName="TipoScore"
                                      placeholder="{{ 'ListadoPreguntas.form.riskBasedOn' | translate }}">
                                      <mat-option *ngFor="let tp of tipoScoreValues" [value]="tp.value">
                                        {{ tp.text }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <div class="c-select--cols__selects col-6" *ngIf="
                                      questionForm.get('TipoPregunta').value === 1 &&
                                      questionForm.get('TipoScore').value === 0;
                                      else MultipleAnswer
                                    ">
                                    <mat-form-field>
                                      <mat-select formControlName="WeightYes"
                                        placeholder="{{ 'ListadoPreguntas.form.riesgoSi.valor' | translate }}">
                                        <mat-option *ngFor="let pv of pesoValues" [value]="pv.value">
                                          {{ pv.text }}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                    <mat-form-field>
                                      <mat-select formControlName="WeightNo" placeholder="{{
                                          'ListadoPreguntas.form.riesgoNo.valor'
                                            | translate
                                        }}">
                                        <mat-option *ngFor="let pv of pesoValues" [value]="pv.value">
                                          {{ pv.text }}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <ng-template #MultipleAnswer>
                                    <div class="col-6" *ngIf="questionForm.get('TipoPregunta').value === 2">
                                      <app-multiple-answer [weightValues]="pesoValues" [canEdit]="canEdit"
                                        [scoreType]="questionForm.get('TipoScore').value"></app-multiple-answer>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <!-- comentarios -->
                            <div class="col-lg-6">
                              <div class="c-switches c-switches--cols">
                                <p id="comments" class="c-select__label c-select__label--mb">
                                  {{ "ListadoPreguntas.form.comments.valor" | translate }}
                                </p>
                                <div class="c-switches--cols__group d-flex flex-wrap" aria-labelledby="comments">
                                  <div class="c-switches__block">
                                    <div class="c-switches__items">
                                      <mat-slide-toggle formControlName="CanAddComments" class="c-switch">
                                        {{ "ListadoPreguntas.form.addComments.valor" | translate }}
                                      </mat-slide-toggle>
                                    </div>
                                  </div>
                                  <div class="c-switches__block">
                                    <div class="c-switches__items">
                                      <mat-slide-toggle *ngIf="questionForm.controls['CanAddComments'].value" (change)="mustAddCommentChanged(questionForm)"
                                        formControlName="MustAddComments" class="c-switch">
                                        {{ "ListadoPreguntas.form.commentOblig.valor" | translate}}
                                      </mat-slide-toggle>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- documentación y certificado -->
                            <div class="col-lg-6">
                              <div class="c-switches c-switches--cols">
                                <p id="attachedDocs" class="c-select__label c-select__label--mb">
                                  {{ "ListadoPreguntas.form.attachedDocs.valor" | translate }}
                                </p>
                                <div class="c-switches--cols__group d-flex flex-wrap" aria-labelledby="attachedDocs">
                                  <div class="c-switches__block">
                                    <div class="c-switches__items">
                                      <mat-slide-toggle formControlName="CanAddDoc" class="c-switch">
                                        {{ "ListadoPreguntas.form.addDoc.valor" | translate }}
                                      </mat-slide-toggle>
                                    </div>
                                  </div>
                                  <div class="c-switches__block">
                                    <div *ngIf="
                                        questionForm.controls['CanAddDoc']
                                          .value &&
                                        questionForm.get('TipoPregunta')
                                          .value === 1 &&
                                        questionForm.get('TipoScore').value === 0
                                      " class="c-switches__items">
                                      <mat-slide-toggle formControlName="MustAddDoc" class="c-switch">
                                        {{ "ListadoPreguntas.form.docOblig.valor" | translate }}
                                      </mat-slide-toggle>
                                    </div>
                                    <div *ngIf="
                                        questionForm.controls['CanAddDoc']
                                          .value &&
                                        questionForm.get('TipoPregunta')
                                          .value === 2
                                        || questionForm.get('TipoScore').value === 1
                                      " class="c-switches__items">
                                      <mat-slide-toggle formControlName="MustAddDoc" class="c-switch" (change)="mustAddDocChanged(questionForm)">
                                        {{ "ListadoPreguntas.form.docObligMultiple" | translate }}
                                      </mat-slide-toggle>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6" *ngIf="questionForm.get('TipoScore').value===1">
                              <div class="c-switches--cols__group d-flex flex-wrap" aria-labelledby="attachedDocs">
                                <div class="c-select--cols__selects col-lg-3" style="margin-top: 1.25rem;">
                                  <mat-form-field>
                                    <mat-select formControlName="WeightComment"
                                      placeholder="{{ 'ListadoPreguntas.form.weightWithComment' | translate}}">
                                      <mat-option *ngFor="let pv of pesoValues" [value]="pv.value">
                                        {{ pv.text }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="c-select--cols__selects col-lg-3" style="margin-top: 1.25rem;">
                                  <mat-form-field>
                                    <mat-select formControlName="WeightNoComment"
                                      placeholder="{{ 'ListadoPreguntas.form.weightNoComment' | translate}}">
                                      <mat-option *ngFor="let pv of pesoValues" [value]="pv.value">
                                        {{ pv.text }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6" *ngIf="questionForm.get('TipoScore').value===1">
                              <div class="c-switches--cols__group d-flex flex-wrap" aria-labelledby="attachedDocs">
                                <div class="c-select--cols__selects col-lg-3" style="margin-top: 1.25rem;">
                                  <mat-form-field>
                                    <mat-select formControlName="WeightFile"
                                      placeholder="{{ 'ListadoPreguntas.form.weightWithFile' | translate}}">
                                      <mat-option *ngFor="let pv of pesoValues" [value]="pv.value">
                                        {{ pv.text }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="c-select--cols__selects col-lg-3" style="margin-top: 1.25rem;">
                                  <mat-form-field>
                                    <mat-select formControlName="WeightNoFile"
                                      placeholder="{{ 'ListadoPreguntas.form.weightNoFile' | translate}}">
                                      <mat-option *ngFor="let pv of pesoValues" [value]="pv.value">
                                        {{ pv.text }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 offset-6"
                              *ngIf="!questionary?.isMaster || (questionary?.isMaster && canAddDocFlag(i, j))">
                              <div class="c-form__block u-mt-1">
                                <mat-form-field>
                                  <input matInput type="text" formControlName="ButtonText"
                                    placeholder="{{ 'ListadoPreguntas.form.docbuttontext.valor' | translate }}"
                                    class="c-form__input" />
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="row u-mt-1">
                    <div class="col-12">
                      <button *appAuthRole="['Owner', 'Manager']" type="button" class="c-button c-button--add"
                        (click)="addQuestion(i)" [disabled]="
                          (!questionary || questionary.isMaster
                            ? true
                            : false) || !canEdit
                        ">
                        <span class="c-button--add__ico" aria-hidden="true">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path data-name=" ↳Color" d="M14,8H8v6H6V8H0V6H6V0H8V6h6Z" transform="translate(5 5)" />
                          </svg>
                        </span>
                        {{ "ListadoPreguntas.button.addQuestion.valor" | translate }}
                      </button>
                    </div>
                  </div>
                </mat-panel-description>
              </div>
            </mat-expansion-panel>
          </div>
        </ng-container>
        <!-- añadir bloque -->
        <div class="row">
          <div class="col-12">
            <button *appAuthRole="['Owner', 'Manager']" [disabled]="isAddBlockButtonDisabled()" type="button"
              class="c-button c-button--add c-button--add-bg" (click)="addBlock()">
              <span class="c-button--add__ico" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path data-name=" ↳Color" d="M14,8H8v6H6V8H0V6H6V0H8V6h6Z" transform="translate(5 5)" />
                </svg>
              </span>
              {{ "ListadoPreguntas.button.addBlock.valor" | translate }}
            </button>
          </div>
        </div>
      </mat-accordion>
    </ng-container>
  </div>
</ng-container>
