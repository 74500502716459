import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  loggedIn = false;

  constructor(
    public auth: AuthenticationService,
    public router: Router) {

    auth.account.subscribe(e => {
      this.loggedIn = e;
    });
  }

  canActivate(): boolean {
    if (!this.loggedIn) {
        this.auth.logout();
        this.router.navigate(['/login']);
        return false;
    } else {
      return true;
    }
  }
}
