import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThirdParty } from '@app/models/ThirdParty';
import { ThirdPartyStatus } from '@app/models/ThirdPartyStatus';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';

@Component({
    selector: 'app-dialog-bulk-status-change',
    templateUrl: './dialog-bulk-status-change.component.html',
    styleUrls: ['./dialog-bulk-status-change.component.scss']
})
export class DialogBulkStatusChangeComponent implements OnInit {

    readonly ThirdPartyStatus = ThirdPartyStatus;

    nonPendingThirdParties: { status: number, thirdParties: number }[] = [];
    pendingThirdParties: number;
    selectedStatus: number;

    updateErrorMsg: string;

    constructor(
        private thirdPartiesService: ThirdPartiesService,
        private toast: Toaster,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<DialogBulkStatusChangeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ThirdParty[]
    ) { }

    ngOnInit(): void {
        this.translate.get('bulkStatusUpdateDialog.updateError')
            .subscribe(translation => {
                this.updateErrorMsg = translation;
            });

        const tempCurrentStatus: { [status: number]: number } = {};

        for (const thirdParty of this.data) {
            const status = thirdParty.status;
            if (!tempCurrentStatus[status]) {
                tempCurrentStatus[status] = 0;
            }
            tempCurrentStatus[status] += 1;
        }

        const tempResult: { status: number, thirdParties: number }[] = [];
        for (const status in tempCurrentStatus) {
            tempResult.push({ status: Number(status), thirdParties: tempCurrentStatus[status] });
        }

        this.nonPendingThirdParties = tempResult.filter(s => s.status !== ThirdPartyStatus.pending);
        this.pendingThirdParties = tempResult.find(s => s.status === ThirdPartyStatus.pending)?.thirdParties || 0;
    }

    updateBulkStatus(): void {
        const thirdPartyIds = this.data.filter(tp => tp.status !== ThirdPartyStatus.pending).map(tp => tp.id);
        this.thirdPartiesService
            .bulkUpdateStatus(thirdPartyIds, this.selectedStatus)
            .subscribe(updated => {
                if (updated) {
                    this.dialogRef.close(true);
                    return;
                } else {
                    this.dialogRef.close(false);
                    this.toast.open({ text: this.updateErrorMsg, duration: 6000, type: 'danger' });
                }
            },
            _ => {
                this.dialogRef.close(false);
                this.toast.open({ text: this.updateErrorMsg, duration: 6000, type: 'danger' });
            });
    }

    onClose(): void {
        this.dialogRef.close(false);
    }
}
