import { Injectable } from '@angular/core';
import { FreshdeskProxyClient } from 'src/proxy/apiFreshdesk/freshdesk.proxy';

function getWindow(): any {
  return window;
}

@Injectable()
export class FreshdeskWidgetService {

  constructor(private freshdeskClient: FreshdeskProxyClient) { }

  get FreshworksWidget() {
    const window = getWindow();
    return window.FreshworksWidget;
  }

  authenticateWidget() {
    const window = getWindow();
    this.freshdeskClient.freshdeskGet().subscribe(r => {
      window.FreshworksWidget('authenticate', {
        token: r,
        callback: function() {
          this.freshdeskClient.freshdeskGet().subscribe(data => 
            window.FreshworksWidget('authenticate', {
              token: data
            })
          );  
        }
      });
    });
  }

  CreateWidget(locale: string, widget_id: number) {
    const window = getWindow();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://euc-widget.freshworks.com/widgets/${widget_id}.js`;

    window.fwSettings = {
      'widget_id': widget_id,
      'locale': `${locale}`
    };

    window.FreshworksWidget || function() {
      if ("function" != typeof window.FreshworksWidget) {
        let n = function() {
          n['q'].push(arguments)
        };
        n['q'] = [], window.FreshworksWidget = n
      }
    }();

    script.onload = function (event) {
        try {

            window.FreshworksWidget("setLabels", {                
                'en': {
                    banner: "Welcome to support",
                    launcher: "Help",
                    contact_form: {
                        title: "Contact us",
                        submit: "Submit",
                        confirmation: "We'll contact you soon!"
                    },   
                },
                'pt-PT': {
                  banner: "Bem-vindo ao suporte",
                  launcher: "Ajuda",
                  contact_form: {
                      title: "Contate-nos",
                      submit: "Enviar",
                      confirmation: "Entraremos em contato com vocé em breve!"
                  },
                }
            });
            window.FreshworksWidget('show');
        } catch (error) {
            console.log("error.: ", error)
        }
    };

    script.onerror = function (event) {
      console.log("error Onerror.: ", event)
    };

    document.body.append(script);
  }
}