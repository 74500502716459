<div class="o-wrapper">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section c-head-section--tab bg-cream">
            <!--parte superior-->
            <div class="c-head-section--tab__top">
                <div class="c-head-section__left">
                    <div class="c-head-section--tab__ico">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.5 20.484">
                            <g transform="translate(-320.13-411.06)">
                                <path
                                    d="m340.34 419.49l-3.23-2.826v-4.762a.836 .836 0 0 0 -.833 -.836c-.478-.006-1.734-.009-3.731-.009-2.847 0-6.446.007-7.628.009h-.441a.837 .837 0 0 0 -.836 .836v4.762l-3.23 2.826a.837 .837 0 0 0 -.285 .629v9.414a2.011 2.011 0 0 0 2.008 2.008h16.484a2.011 2.011 0 0 0 2.008 -2.008v-9.414a.835 .835 0 0 0 -.286 -.629m-18.11.629l1.41-1.234v2.468m13.469 0v-2.468l1.411 1.234m.433 1.842v6.9l-3.942-3.451m-4.858-1.643a.339 .339 0 0 1 .442 0l6.969 6.1h-14.38m5.869-7.358l-2.044 1.791-1.7-1.484v-10.08h10.125v10.08l-1.7 1.484-2.045-1.791a2.014 2.014 0 0 0 -2.645 0m-3.304 2.9l-3.944 3.453v-6.9" />
                                <path
                                    d="M329.556,420.117a2.309,2.309,0,0,1-1.768-.866L326.5,417.9a1.117,1.117,0,0,1-.067-1.393.747.747,0,0,1,1.1-.151.822.822,0,0,1,.074.071l1.321,1.394a.789.789,0,0,0,.6.325.775.775,0,0,0,.614-.29l3-3.382a.758.758,0,0,1,1.16.062,1.118,1.118,0,0,1-.009,1.37l-2.991,3.365A2.291,2.291,0,0,1,329.556,420.117Z" />
                            </g>
                        </svg>
                    </div>
                    <div class="c-head-section--tab__info">
                        <h2 class="c-head-section--tab__title">{{ model?model.Name:'' }}</h2>
                        <div class="c-head-section--tab-data">
                            <div class="c-head-section--tab-data__item">
                                <div class="c-head-section--tab-data__ico" aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                        <path
                                            d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z" />
                                    </svg>
                                </div>
                                <div>
                                    <span>{{ model?model.ThirdParties.length:'' }}</span> {{'assesmentDetail.3pp.valor' | translate}}
                                </div>
                            </div>
                            <div class="c-head-section--tab-data__item">
                                <div class="c-head-section--tab-data__ico" aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path class="shape"
                                            d="M15.833,1.667H15V.833a.833.833,0,0,0-1.667,0v.833H6.667V.833A.833.833,0,1,0,5,.833v.833H4.167A4.172,4.172,0,0,0,0,5.833v10A4.172,4.172,0,0,0,4.167,20H15.833A4.172,4.172,0,0,0,20,15.833v-10A4.172,4.172,0,0,0,15.833,1.667ZM1.667,5.833a2.5,2.5,0,0,1,2.5-2.5H15.833a2.5,2.5,0,0,1,2.5,2.5v.833H1.667Zm14.167,12.5H4.167a2.5,2.5,0,0,1-2.5-2.5v-7.5H18.333v7.5A2.5,2.5,0,0,1,15.833,18.333Z" />
                                        <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 11.25)" />
                                        <circle cx="1.25" cy="1.25" r="1.25" transform="translate(4.583 11.25)" />
                                        <circle cx="1.25" cy="1.25" r="1.25" transform="translate(12.917 11.25)" />
                                    </svg>
                                </div>
                                <div>
                                    <span>{{ model?model.StartDate.toLocaleDateString():'' }}</span> - <span>{{ model
                                        ? model.StartDate.toLocaleTimeString():'' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-head-section__right">
                    <button *appAuthRole="['Owner','Manager']" [disabled]="!changes" type="submit" class="c-button c-button--gray" (click)="saveChanges()">{{'assesmentDetail.save.valor' | translate}}</button>
                </div>
            </div>
            <!--volver a listado-->
            <div class="c-go-back">
                <a [routerLink]="['/evaluaciones']" class="c-go-back__button">
                    <span class="c-go-back__arrow" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                            <path
                                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                                transform="translate(-5-6.01)" />
                        </svg>
                    </span> {{'assesmentDetail.back.valor' | translate}}
                </a>
            </div>
            <!--parte inferior-->
            <div class="c-head-section--tab__bottom">
                <!--tabs-->
                <mat-tab-group class="c-tabs">
                    <!--listado de terceros-->
                    <mat-tab label="{{'assesmentDetail.3pplist.valor' | translate}}">
                        <app-campaign-third-party-table [campaignId]="id" [canAddThirdParties]="canAddTP"></app-campaign-third-party-table>
                    </mat-tab>
                    <!--detalles de la evaluación-->
                    <mat-tab label="{{'assesmentDetail.campaignDetails.valor' | translate}}">
                        <div *ngIf="model" class="o-content o-scroll">
                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <div class="c-data-campaign__block">
                                        <p id="templateData" class="c-data-campaign__title">
                                            {{'assesmentDetail.template.valor' | translate}}</p>
                                        <div class="c-data-campaign__input-modal">
                                            <input [value]="model? model.Questionary.Code : ''" type="text" class="c-form__input" aria-labelledby="templateData" disabled="disabled" />
                                            <button type="button" aria-label="Mostrar ventana modal">
                                                <span aria-hidden="true">
                                                    <img src="assets/ico-eye-open.svg" alt="" loading="lazy" />
                                                </span>
                                                {{'assesmentDetail.viewTemplate.valor' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-end">
                                <div class="col-6 col-lg-4">
                                    <div class="c-data-campaign__block">
                                        <p id="basicData" class="c-data-campaign__title">
                                            {{'assesmentDetail.basicData.valor' | translate}}</p>
                                        <input [value]="model? model.Name : ''" type="text" class="c-form__input" aria-labelledby="basicData" disabled="disabled" />
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4 col-xl-3">
                                    <div class="c-data-campaign__block">
                                        <input type="text" class="c-form__input" aria-labelledby="basicData" disabled="disabled" [value]="model? model.Amount : ''" />
                                    </div>
                                </div>
                            </div>

                            <form [formGroup]="form">
                                <div class="row">
                                    <div class="col-12 col-lg-6 col-xl-4">
                                        <div class="c-data-campaign__block">
                                            <div class="c-select">
                                                <mat-form-field *ngIf="form.controls['StartDate']">
                                                    <input matInput [ngxMatDatetimePicker]="picker" placeholder="{{'NuevaCampañaConfig.startph.valor' | translate }}" formControlName="StartDate">
                                                    <button mat-icon-button matSuffix (click)="picker.open(); $event.stopPropagation()">
                                                        <mat-icon>today</mat-icon>
                                                    </button>
                                                    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
                                                        <ng-template>
                                                            <span>OK</span>
                                                        </ng-template>
                                                    </ngx-mat-datetime-picker>
                                                    <mat-error>
                                                        {{'NuevaCampañaConfig.startError.valor' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="form.errors" role="alert" aria-labelledby="config-startDate" class="c-form-error">
                                                <p class="c-form-error__text"> {{'NuevaCampañaConfig.startError2.valor' | translate }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-xl-4">
                                        <div class="c-data-campaign__block">
                                            <div class="c-select">
                                                <mat-form-field *ngIf="form.controls['EndDate']">
                                                    <input matInput [ngxMatDatetimePicker]="pickerf" placeholder="{{'NuevaCampañaConfig.endph.valor' | translate }}" formControlName="EndDate" [min]="minDateEnd">
                                                    <button mat-icon-button matSuffix (click)="pickerf.open(); $event.stopPropagation()">
                                                        <mat-icon>today</mat-icon>
                                                    </button>
                                                    <ngx-mat-datetime-picker #pickerf [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
                                                        <ng-template>
                                                            <span>OK</span>
                                                        </ng-template>
                                                    </ngx-mat-datetime-picker>
                                                    <mat-error>
                                                        {{'NuevaCampañaConfig.startError.valor' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="form.errors" role="alert" aria-labelledby="config-startDate" class="c-form-error">
                                                <p class="c-form-error__text"> {{'NuevaCampañaConfig.startError2.valor' | translate }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row">
                                <div class="col-12">
                                    <div class="c-data-campaign__block">
                                        <p id="introData" class="c-data-campaign__title">{{'NuevaCampañaConfig.automatization' | translate}}</p>
                                        <div class="c-switches c-switches--cols">
                                            <div class="c-switches--cols__group" aria-labelledby="">
                                                <mat-slide-toggle class="c-switch" name="automatization-enabled" [disabled]="true"
                                                    [(ngModel)]="enableAutomatizationApprove" [ngModelOptions]="{standalone: true}">
                                                </mat-slide-toggle>
                                                <app-third-party-status [status]="2"></app-third-party-status>
                                                <p class="automatize-paragraph-right">{{'NuevaCampañaConfig.automatizationApproveParagraph' | translate}}</p>
                                                <input type="number" name="ApproveThreshold" min="1" max="5" [value]="model?.ApproveThreshold" id="ApproveThreshold" class="c-form__input" placeholder="" style="width: auto;" disabled="disabled"/>
                                            </div>
                                        </div>
                                        <div class="c-switches c-switches--cols">
                                            <div class="c-switches--cols__group" aria-labelledby="">
                                                <mat-slide-toggle class="c-switch" name="automatization-enabled" [disabled]="true"
                                                    [(ngModel)]="enableAutomatizationDeny" [ngModelOptions]="{standalone: true}">
                                                </mat-slide-toggle>
                                                <app-third-party-status [status]="3"></app-third-party-status>
                                                <p class="automatize-paragraph-right">{{'NuevaCampañaConfig.automatizationDenyParagraph' | translate}}</p>
                                                <input type="number" name="DiscardThreshold" min="1" max="5" [value]="model?.DiscardThreshold" id="DiscardThreshold" class="c-form__input" placeholder="" style="width: auto;" disabled="disabled"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="c-data-campaign__block">
                                        <p id="introData" class="c-data-campaign__title">
                                            {{'NuevaCampañaEnviada.intro.valor' | translate}}</p>
                                        <div class="c-form__input c-form__input--large" aria-labelledby="introData" disabled="disabled">
                                            <p>{{ model ? model.Introduction:'' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="c-ico-link u-mb-2">
                                        <div class="c-ico-link__left">
                                            <span class="c-ico-link__ico" aria-hidden="true">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20">
                                                    <path
                                                        d="M16.779,2.6,15.4,1.22A4.14,4.14,0,0,0,12.452,0H7.167A4.172,4.172,0,0,0,3,4.167V15.833A4.172,4.172,0,0,0,7.167,20h6.667A4.172,4.172,0,0,0,18,15.833V5.547A4.14,4.14,0,0,0,16.779,2.6ZM15.6,3.78a2.443,2.443,0,0,1,.316.387H13.833V2.083a2.583,2.583,0,0,1,.388.317Zm.733,12.053a2.5,2.5,0,0,1-2.5,2.5H7.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5h5v2.5a1.667,1.667,0,0,0,1.667,1.667h2.5ZM13.833,7.5a.833.833,0,1,1,0,1.667H7.167a.833.833,0,1,1,0-1.667Zm.833,4.167a.833.833,0,0,1-.833.833H7.167a.833.833,0,1,1,0-1.667h6.667A.833.833,0,0,1,14.667,11.667Zm-.16,2.844a.833.833,0,0,1-.181,1.162,5.387,5.387,0,0,1-2.88.994,2.71,2.71,0,0,1-1.667-.583c-.273-.188-.377-.25-.583-.25a3.293,3.293,0,0,0-1.527.662.833.833,0,0,1-1.012-1.323A4.884,4.884,0,0,1,9.2,14.167a2.521,2.521,0,0,1,1.527.546,1.122,1.122,0,0,0,.72.287,3.822,3.822,0,0,0,1.9-.674A.833.833,0,0,1,14.507,14.511Z"
                                                        transform="translate(-3)" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="c-ico-link__right">
                                            <p class="c-ico-link__title">{{'NuevaCampañaEnviada.conditions.valor' | translate}}</p>
                                            <a [routerLink]="" (click)="download(model.FileUri)" class="c-ico-link__link" title="{{'NuevaCampañaEnviada.fileMessage.valor' | translate}}" download>{{ model? model.FileName: '' }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-tab>
                    <!--Estadísticas-->
                    <mat-tab label="{{'assesmentDetail.stats.valor' | translate}}">
                        <app-campaign-detail-statistics [campaignId]="id"></app-campaign-detail-statistics>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </main>
</div>
