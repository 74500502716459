import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryResult } from '@app/models/common/queryResult';
import { ScoringCandidate } from '@app/models/scoring-tools/scoringCandidate';
import { ScreeningProvider } from '@app/modules/screening-hub/models/ScreeningProvider';
import { CreateResolutionComponent } from '@app/modules/screening-hub/resolutions/create-resolution/create-resolution.component';
import { ScoringToolsService } from '@app/services/scoring-tools.service';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-scoring-candidates-list',
  templateUrl: './scoring-candidates-list.component.html',
  styleUrls: ['./scoring-candidates-list.component.scss'],
})
export class ThirdPartyScoringCandidatesListComponent implements OnInit {
  displayedColumns: string[] = [
    'matchedAlias',
    'matchStrength',
    'worldCheckId',
    'category',
    'software',
    'thirdPartyRisks',
    'actions',
  ];
  queryResult: QueryResult<ScoringCandidate> = { items: [], totalItems: 0 };

  thirdPartyId: number;
  caseId: string;
  scoringCandidates: ScoringCandidate[] = [];
  name: string;
  searchType: number;
  translations: any;

  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private toaster: Toaster,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private scoringToolsService: ScoringToolsService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.thirdPartyId = params.id,
      this.caseId = params.caseId
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.name = params.name;
      this.searchType = params.searchType;
    });

    this.translateService.get("ThirdPartyCandidateTable").subscribe(t => this.translations = t);
  }

  ngOnInit() {
    this.scoringToolsService.getCaseResults(this.caseId).subscribe((res) => {
      this.scoringCandidates = res
        .map((s) => {
          s.thirdPartyRisks = this.getRisks(s.thirdPartyRisks);
          return s;
        })
        .sort((a, b) => this.sortingFunction(a, b));
      this.queryResult.items = res.slice(
        (this.query.Page - 1) * this.query.PageSize,
        this.query.Page * this.query.PageSize
      );
      this.queryResult.totalItems = this.scoringCandidates.length;
    });
  }

  getCandidates() {
    this.queryResult.items = this.scoringCandidates
      .sort((a, b) => this.sortingFunction(a, b))
      .slice(
        (this.query.Page - 1) * this.query.PageSize,
        this.query.Page * this.query.PageSize
      );
    this.queryResult.totalItems = this.scoringCandidates.length;
  }

  sortBy(columnName) {
    this.query.Page = 1;
    this.query.SortBy = columnName.active;
    this.query.IsSortAscending = columnName.direction === 'asc';
    this.getCandidates();
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.queryResult.items = this.scoringCandidates.slice(
      (this.query.Page - 1) * this.query.PageSize,
      this.query.Page * this.query.PageSize
    );
  }

  sortingFunction(a: ScoringCandidate, b: ScoringCandidate): number {
    if (a[this.query.SortBy] > b[this.query.SortBy]) {
      return this.query.IsSortAscending ? 1 : -1;
    }

    if (a[this.query.SortBy] < b[this.query.SortBy]) {
      return this.query.IsSortAscending ? -1 : 1;
    }

    return 0;
  }

  getRisks(risks: string[]): string[] {
    return risks.reduce(function (dr: string[], a) {
      const index = dr.findIndex((r) => r == a);
      if (index === -1) {
        dr.push(a);
      }
      return dr;
    }, []);
  }

  back() {
    if (this.thirdPartyId) {
      this.router.navigate(['scoring-candidates', this.thirdPartyId]);
    } else {
      this.router.navigate(['scoring-candidates']);
    }
  }

  viewDetails(referenceId: string, matchStrength: string) {
    if (this.thirdPartyId) {
      this.router.navigate([
        'scoring-candidate-details',
        referenceId,
        this.caseId,
        matchStrength,
        this.thirdPartyId,
      ]);
    } else {
      this.router.navigate([
        'scoring-candidate-details',
        referenceId,
        this.caseId,
        matchStrength,
      ]);
    }
  }

  createResolution() {
    const dialogRef = this.dialog.open(CreateResolutionComponent, {
      data: {
        name: this.name,
        software: ScreeningProvider.Refinitiv,
        data: JSON.stringify(this.scoringCandidates),
        totalResults: this.scoringCandidates.length,
        searchType: this.searchType,
        thirdPartyId: this.thirdPartyId
      }
    });
    dialogRef.afterClosed().subscribe((r: boolean) => {
      if (r != null || r != undefined) {
        if (r) {
          this.toaster.open({ text: this.translations.resolutionCreated, duration: 6000, type: 'success' });
        } else {
          this.toaster.open({ text: this.translations.resolutionCreationError, duration: 6000, type: 'danger' });
        }
      }
    })
  }
}
