import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Campaign } from '@app/models/Campaign';
import { CampaignStatus } from '@app/models/CampaignStatus';
import { AuthenticationService } from '@app/services/authentication.service';
import { CampaignService } from '@app/services/campaign-service';
import { CampaignThirdPartyService } from '@app/services/campaign-third-party.service';
import { GlobalService } from '@app/services/global.service';
import { RemoteService } from '@app/services/remote.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-risklist-intro',
  templateUrl: './risklist-intro.component.html',
  styles: []
})
export class RisklistIntroComponent implements OnInit {
  campaignId: number;
  thirdPartyId: number;
  campaign: Campaign;
  locale: string;
  submitted: boolean = false;
  pendingSubmission: boolean = false;

  constructor(
    public router: Router,
    public auth: AuthenticationService,
    private campaignService: CampaignService,
    private campaignThirdPartySerivce: CampaignThirdPartyService,
    private activatedRoute: ActivatedRoute,
    private remoteService: RemoteService,
    private globalService: GlobalService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.locale = this.translate.currentLang.split("-")[0];
    const currentDate = new Date();

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('o-risklist', 'js-noHeader');

    this.campaignId = this.activatedRoute.snapshot.params.ca;
    this.thirdPartyId = this.activatedRoute.snapshot.params.third;

    this.campaignService.getCampaignForPageId(this.campaignId).subscribe(r => {
      this.campaign = r;
      this.campaignThirdPartySerivce.getCampaignThirdPartiesByThirdPartyAndCampaign(this.thirdPartyId, this.campaignId).subscribe(r => {

        const campaignEndDate = new Date(r.endDate);
        const isCampaignEnded = currentDate > campaignEndDate;
        if (isCampaignEnded ||
          r.status == CampaignStatus.discarded ||
          r.status == CampaignStatus.pendingReview ||
          r.status == CampaignStatus.reviewed) {
          this.submitted = true;
          this.globalService.setDisableSignRiskList();
        } else {
          this.pendingSubmission = true;
        }
      });
    });
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('o-risklist', 'js-noHeader');
  }

  download(idfile: string) {
    this.remoteService.downloadMyFile(idfile, this.campaign.fileName);
  }

  gotoFillRisklist(idCampaign: number, idThirdParty: number) {
    this.router.navigate(['/rellena-risklist/'], { queryParams: { ca: idCampaign, third: idThirdParty } });
  }

  gotoViewRisklist(idCampaign: number, idThirdParty: number) {
    this.router.navigate(['/evaluation-answers', idThirdParty, idCampaign, 0]);
  }
}
