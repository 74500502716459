<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{ 'ConsumptionUsage.expandQuotas.title' | translate }}</h1>
        <button mat-button (click)="onNoClick()" class="c-dialog__close" attr.aria-label="{{'ConsumptionUsage.expandQuotas.closeModalWindow' | translate}}">
            <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path
                        d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
                        transform="translate(-309.86-406.47)" />
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content">
        <h2 class="c-dialog__subtitle">{{ 'ConsumptionUsage.expandQuotas.subtitle' | translate }}</h2>
        <p class="o-text">{{ 'ConsumptionUsage.expandQuotas.text' | translate }}</p>
        <div class="row u-mt-1medium c-select">
            <mat-form-field class="col">
                <mat-select placeholder="{{ 'ConsumptionUsage.expandQuotas.quotaTypePlaceholder' | translate }}" [(ngModel)]="quotaTypeToIncrease">
                    <mat-option value="0">{{ 'ConsumptionUsage.expandQuotas.scoringTools' | translate }}</mat-option>
                    <mat-option value="1">{{ 'ConsumptionUsage.expandQuotas.thirdParties' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row u-mt-1medium c-form">
            <mat-form-field class="c-form__block">
                <input
                    type="text"
                    matInput
                    [(ngModel)]="remarks"
                    placeholder="{{ 'ConsumptionUsage.expandQuotas.remarksPlaceholder' | translate }}"
                    required
                    class="c-form__input"
                />
                <mat-hint>
                    {{ 'ConsumptionUsage.expandQuotas.remarksHint' | translate }}
                </mat-hint>
            </mat-form-field>
        </div>
        <footer class="c-dialog__footer">
            <button type="button" [mat-dialog-close]="false" class="c-button c-button--light">
                {{ 'ConsumptionUsage.expandQuotas.cancel' | translate }}
            </button>
            <button type="submit" (click)="sendExpandQuotaRequest()" class="c-button c-button--green" [disabled]="!quotaTypeToIncrease || !remarks">
                {{ 'ConsumptionUsage.expandQuotas.send' | translate }}
            </button>
        </footer>
    </div>
</div>