<div class="o-wrapper">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section c-head-section--bg">
            <div class="c-head-section__left">
                <h2 class="o-title"><strong>{{'NuevaCampañaEnviada.title1.valor' | translate}}</strong></h2>
                <p class="o-text" *ngIf="model">{{'NuevaCampañaEnviada.title2.valor' | translate}} <a
                        [routerLink]="['/detalle-evaluacion/' + model.id]">{{'NuevaCampañaEnviada.title3.valor' |
                        translate}}</a>.</p>
            </div>
            <div class="c-head-section__right" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.993 56.004">
                    <path
                        d="M731.078,547.534a28,28,0,1,1,0-56v8.2h-.006a19.816,19.816,0,1,0,19.813,19.819v-.013h8.2a27.98,27.98,0,0,1-27.958,28Z"
                        transform="translate(-703.09-491.53)" />
                    <path d="m1190.47 513.31l-.151-21.783h-21.783v8.2h8.03l-16.942 16.942 5.8 5.8 16.86-17.02v7.868"
                        transform="translate(-1134.48-491.53)" />
                    <circle cx="8.453" cy="8.453" r="8.453" transform="translate(20.07 20.08)" />
                </svg>
            </div>
        </div>
        <!--volver atrás-->
        <div class="c-go-back c-go-back--steps">
            <a [routerLink]="['/evaluaciones']" class="c-go-back__button" (click)="backToCampaigns()">
                <span class="c-go-back__arrow" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                        <path
                            d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                            transform="translate(-5-6.01)" />
                    </svg>
                </span> {{'NuevaCampañaEnviada.back.valor' | translate}}
            </a>
        </div>
        <!--datos evaluación-->
        <article class="c-data-campaign c-data-campaign--space-left">
            <div class="row">
                <div class="col-12">
                    <div class="c-data-campaign__block">
                        <p id="templateData" class="c-data-campaign__title">{{'NuevaCampañaEnviada.template.valor' |
                            translate}}</p>
                        <div class="c-data-campaign__input-modal">
                            <input type="text" class="c-form__input" [value]="model ? model.questionary.Code : ''"
                                aria-labelledby="templateData" disabled="disabled" />
                            <button type="button" aria-label="Mostrar ventana modal">
                                <span aria-hidden="true">
                                    <img src="assets/ico-eye-open-color.svg" alt="" loading="lazy" />
                                </span>
                                {{'NuevaCampañaEnviada.viewTemplate.valor' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-end">
                <div class="col-6 col-lg-4">
                    <div class="c-data-campaign__block">
                        <p id="basicData" class="c-data-campaign__title">{{'NuevaCampañaEnviada.basicData.valor' |
                            translate}}</p>
                        <input type="text" class="c-form__input" aria-labelledby="basicData" disabled="disabled"
                            [value]="model?model.name:''" />
                    </div>
                </div>
                <div class="col-6 col-lg-4 col-xl-3">
                    <div class="c-data-campaign__block">
                        <input type="text" class="c-form__input" aria-labelledby="basicData" disabled="disabled"
                            [value]="model?model.amount:''" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="c-data-campaign__block">
                        <p id="sendDataDate" class="c-data-campaign__title">{{'NuevaCampañaEnviada.start.valor' |
                            translate}}</p>
                        <div class="c-data-campaign__cols" aria-labelledby="sendDataDate">
                            <div class="c-data-campaign__date">
                                <input type="text" class="c-form__input date" disabled="disabled"
                                    value="{{ model?.startDate | date:'d MMM y, H:mm':undefined:locale}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="c-data-campaign__block">
                        <p id="endDataDate" class="c-data-campaign__title">{{'NuevaCampañaEnviada.end.valor' |
                            translate}}</p>
                        <div class="c-data-campaign__cols" aria-labelledby="endDataDate">
                            <div class="c-data-campaign__date">
                                <input type="text" class="c-form__input date" disabled="disabled"
                                    value="{{ model?.endDate | date:'d MMM y, H:mm':undefined:locale}}" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="c-data-campaign__block">
                        <p id="introData" class="c-data-campaign__title">{{'NuevaCampañaConfig.automatization' | translate}}</p>
                        <div class="c-switches c-switches--cols">
                            <div class="c-switches--cols__group" aria-labelledby="">                                            
                                <mat-slide-toggle class="c-switch" name="automatization-enabled" [disabled]="true"
                                    [(ngModel)]="enableAutomatizationApprove" [ngModelOptions]="{standalone: true}">
                                </mat-slide-toggle>
                                <app-third-party-status [status]="2"></app-third-party-status>
                                <p class="automatize-paragraph-right">{{'NuevaCampañaConfig.automatizationApproveParagraph' | translate}}</p>
                                <input type="number" name="ApproveThreshold" min="1" max="5" [value]="model?.approveThreshold" id="ApproveThreshold" class="c-form__input" placeholder="" style="width: auto;" disabled="disabled"/>
                            </div>
                        </div>
                        <div class="c-switches c-switches--cols">
                            <div class="c-switches--cols__group" aria-labelledby="">                                            
                                <mat-slide-toggle class="c-switch" name="automatization-enabled" [disabled]="true"
                                    [(ngModel)]="enableAutomatizationDeny" [ngModelOptions]="{standalone: true}">
                                </mat-slide-toggle>
                                <app-third-party-status [status]="3"></app-third-party-status>
                                <p class="automatize-paragraph-right">{{'NuevaCampañaConfig.automatizationDenyParagraph' | translate}}</p>
                                <input type="number" name="DiscardThreshold" min="1" max="5" [value]="model?.discardThreshold" id="DiscardThreshold" class="c-form__input" placeholder="" style="width: auto;" disabled="disabled"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="c-data-campaign__block">
                        <p id="introData" class="c-data-campaign__title">{{'NuevaCampañaEnviada.intro.valor' |
                            translate}}</p>
                        <div class="c-form__input c-form__input--large" aria-labelledby="introData" disabled="disabled">
                            <p>{{ model ? model.introduction:'' }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="c-ico-link u-mb-2">
                        <div class="c-ico-link__left">
                            <span class="c-ico-link__ico" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20">
                                    <path
                                        d="M16.779,2.6,15.4,1.22A4.14,4.14,0,0,0,12.452,0H7.167A4.172,4.172,0,0,0,3,4.167V15.833A4.172,4.172,0,0,0,7.167,20h6.667A4.172,4.172,0,0,0,18,15.833V5.547A4.14,4.14,0,0,0,16.779,2.6ZM15.6,3.78a2.443,2.443,0,0,1,.316.387H13.833V2.083a2.583,2.583,0,0,1,.388.317Zm.733,12.053a2.5,2.5,0,0,1-2.5,2.5H7.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5h5v2.5a1.667,1.667,0,0,0,1.667,1.667h2.5ZM13.833,7.5a.833.833,0,1,1,0,1.667H7.167a.833.833,0,1,1,0-1.667Zm.833,4.167a.833.833,0,0,1-.833.833H7.167a.833.833,0,1,1,0-1.667h6.667A.833.833,0,0,1,14.667,11.667Zm-.16,2.844a.833.833,0,0,1-.181,1.162,5.387,5.387,0,0,1-2.88.994,2.71,2.71,0,0,1-1.667-.583c-.273-.188-.377-.25-.583-.25a3.293,3.293,0,0,0-1.527.662.833.833,0,0,1-1.012-1.323A4.884,4.884,0,0,1,9.2,14.167a2.521,2.521,0,0,1,1.527.546,1.122,1.122,0,0,0,.72.287,3.822,3.822,0,0,0,1.9-.674A.833.833,0,0,1,14.507,14.511Z"
                                        transform="translate(-3)" />
                                </svg>
                            </span>
                        </div>
                        <div class="c-ico-link__right">
                            <p class="c-ico-link__title">{{'NuevaCampañaEnviada.conditions.valor' | translate}}</p>
                            <a (click)="download(model.fileUri)" class="c-ico-link__link"
                                title="{{'NuevaCampañaEnviada.fileMessage.valor' | translate}}" download>{{ model?.fileName }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </article>
        <!--tabla-->
        <app-third-party-table-extended [config]="TercerosCampaignConfig"></app-third-party-table-extended>
    </main>
</div>
