<section class="c-login">
    <div class="c-login__wrapper">
        <!--parte izquierda - imagen-->
        <div class="c-login__left" aria-hidden="true">
            <div class="c-login-header">
                <div class="c-login-header__image" aria-hidden="true">
                    <img src="./assets/image-logo.svg" alt="" loading="lazy" />
                </div>                
            </div>
            <div class="c-login__opacity"></div>
        </div>
        <!--parte derecha - form-->
        <div class="c-login__right">
            <div class="c-login__right-top">
                <h2 class="c-login__subtitle">{{'confirmEmail.waitingMessage' | translate}}</h2>                
            </div>
        </div>
    </div>
</section>

