import { CustomField } from "@app/models/CustomField";
import { CustomFieldCreateDto } from "src/proxy/model/customFieldCreateDto";
import { CustomFieldDto } from "src/proxy/model/customFieldDto";
import { CustomFieldUpdateDto } from "src/proxy/model/customFieldUpdateDto";
import { CustomFieldTypesService } from "../custom-fields/custom-field-types.service";
import { mapCustomFieldOptionsToOptionsString, mapOptionsStringToCustomFieldOptions, mapOptionsStringToMaxOptionId } from "./custom-field-options.mappings";

export function mapCustomField(dto: CustomFieldDto, customFieldTypesService: CustomFieldTypesService): CustomField {
  if (!dto) return null;

  return {
    id: dto.Id,
    description: dto.Description,
    descriptionEN: dto.DescriptionEN,
    descriptionPT: dto.DescriptionPT,
    descriptionFR: dto.DescriptionFR,
    hasTranslations: dto.HasTranslations,
    type: customFieldTypesService.getById(dto.CustomFieldTypeId),
    maxOptionsId: mapOptionsStringToMaxOptionId(dto.Options),
    options: mapOptionsStringToCustomFieldOptions(dto.Options),
    mandatory: dto.Mandatory ?? false
  } as CustomField;
}

export function mapCustomFieldToCreateDto(dto: CustomField): CustomFieldCreateDto {
  if (!dto) return null;

  return {
    Id: dto.id,
    Description: dto.description,
    DescriptionEN: dto.descriptionEN,
    DescriptionPT: dto.descriptionPT,
    DescriptionFR: dto.descriptionFR,
    HasTranslations: dto.hasTranslations,
    CustomFieldTypeId: dto.type?.id,
    Options: mapCustomFieldOptionsToOptionsString(dto.options, dto.type, dto.maxOptionsId),
    Mandatory: dto.mandatory ?? false
  } as CustomFieldCreateDto;
}

export function mapCustomFieldToUpdateDto(dto: CustomField): CustomFieldUpdateDto {
  if (!dto) return null;

  return {
    Id: dto.id,
    Description: dto.description,
    DescriptionEN: dto.descriptionEN,
    DescriptionPT: dto.descriptionPT,
    DescriptionFR: dto.descriptionFR,
    HasTranslations: dto.hasTranslations,
    CustomFieldTypeId: dto.type?.id,
    Options: mapCustomFieldOptionsToOptionsString(dto.options, dto.type, dto.maxOptionsId),
    Mandatory: dto.mandatory ?? false
  } as CustomFieldUpdateDto;
}
