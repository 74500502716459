<!--modal confirmación-->
<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'MassiveLoad.confirmDialog.title' | translate }}</h1>
        <button mat-button (click)="onNoClick()" class="c-dialog__close" attr.aria-label="{{'MassiveLoad.confirmDialog.closeModalWindow' | translate}}">
            <span aria-hidden="true"> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243" transform="translate(-309.86-406.47)"/>
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content">
        <h2 class="c-dialog__subtitle">{{('MassiveLoad.confirmDialog.subtitle' | translate).replace('${totalLoaded}', data.totalLoaded) }}</h2>
        <footer class="c-dialog__footer no-paddingH u-mt-1medium u-pb-0">
            <mat-dialog-actions>
                <button class="c-button c-button--light" mat-button mat-dialog-close>{{'MassiveLoad.confirmDialog.cancel' | translate }}</button>
                <button class="c-button c-button--green" mat-button [mat-dialog-close]="true">{{'MassiveLoad.confirmDialog.acceptAndLoad' | translate }}</button>
            </mat-dialog-actions>
        </footer>
    </div>    
</div>
