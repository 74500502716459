<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'RequestThirdParties.header' | translate }}</h1>
        <button mat-button (click)="closeModal()" class="c-dialog__close" attr.aria-label="{{'RequestThirdParties.close' | translate}}">
            <span aria-hidden="true"> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243" transform="translate(-309.86-406.47)"/>
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content">
        <h2 class="c-dialog__subtitle">{{('RequestThirdParties.title' | translate).replace('${totalLoaded}', data.totalLoaded) }}</h2>
        <p class="info-text">{{'RequestThirdParties.subtitle' | translate }}</p>
        <form class="c-form">
        <div class="row u-mt-1medium" aria-labelledby="requestedThirdParties">
             <div class="col-lg-12 col-xl-12">
                 <mat-form-field>
                     <input type="number" matInput name="num-third-parties" value="" min="1" step="1" id="num-third-parties" class="c-form__input" placeholder="{{'RequestThirdParties.numThirdParties' | translate}}" [(ngModel)]="numberOfThirdPartiesRequested" required pattern="^[1-9][0-9]*$" />
                 </mat-form-field>
             </div>
         </div>
         <div class="row u-mt-1medium" aria-labelledby="requestRemarks">
             <div class="col-lg-12 col-xl-12">
                 <mat-form-field>
                     <input type="text" matInput name="requestRemarks" id="requestRemarks" class="c-form__input" placeholder="{{'RequestThirdParties.remarks' | translate}}" [(ngModel)]="requestRemarks" />
                 </mat-form-field>
             </div>
         </div>
        </form>
        <footer class="c-dialog__footer no-paddingH u-mt-1medium u-pb-0">
            <mat-dialog-actions>
                <button class="c-button c-button--light" mat-button (click)="closeModal()">{{'RequestThirdParties.close' | translate }}</button>
                <button class="c-button c-button--green" mat-button (click)="submitForm()">{{'RequestThirdParties.send' | translate }}</button>
            </mat-dialog-actions>
        </footer>
    </div>    
</div>
