interface IDtoWithDescription {
  Description: string;
  DescriptionEN: string;
  DescriptionPT: string;
  DescriptionFR: string;
}

export function mapDescriptionToCurrentLanguage(
  dto: IDtoWithDescription,
  currentLanguage: string
): string {
  let translatedDescription = '';

  switch (currentLanguage) {
    case 'es-ES':
      translatedDescription = dto?.Description ?? '';
      break;
    case 'pt-PT':
      translatedDescription = dto?.DescriptionPT ?? '';
      break;
    case 'en-US':
      translatedDescription = dto?.DescriptionEN ?? '';
      break;
    case 'fr-FR':
      translatedDescription = dto?.DescriptionFR ?? '';
      break;
    default:
      translatedDescription = dto?.DescriptionEN ?? '';
      break;
  }

  return translatedDescription;
}
