<div class="parental-mesh">
  <p id="parentalMeshLegend" class="parental-mesh__legend u-mt-1medium">
    {{ "ParentalMesh.title" | translate }}
  </p>
  <section class="c-table">
    <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "ParentalMesh.rut" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.rut }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "ParentalMesh.name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.fullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="kinship">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "ParentalMesh.kinship" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.relationType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "ParentalMesh.category" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.category }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">{{'ParentalMesh.noResults' | translate}} </td>
      </tr>
    </table>
    <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems" class="c-pagination">
    </mat-paginator>
  </section>
</div>

