import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from "@angular/router";
import { CountryDTO } from '@app/models/Country';
import { SectorDTO } from '@app/models/Sector';
import { ThirdPartyTypeDTO } from '@app/models/ThirdPartyType';
import { formatearFecha } from '@app/utilities/utilities'

@Component({
  selector: 'app-dialog-delete-thirdparty',
  templateUrl: './dialog-delete-thirdparty.component.html'
})
export class DialogDeleteThirdpartyComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteThirdpartyComponent>,
    @Inject(MAT_DIALOG_DATA)public data: {
      Name: string,
      Country: CountryDTO,
      Type: ThirdPartyTypeDTO,
      Sector: SectorDTO 
      
    }
  ) { }

  ngOnInit(): void {
  }

  //cancelar
  onNoClick(): void {
    this.dialogRef.close();
  }

  //aceptar

  finishCreate():void{
    this.dialogRef.close(); 
  }

}
