<section class="c-table">
  <div class="c-table__top">
    <div class="row">
      <div class="col-5">
        <app-text-table-filter (filter)="updateSearchWord($event)"></app-text-table-filter>
      </div>
      <div class="col-12 col-lg-6 col-xl-3">
        <div class="c-select">
          <mat-form-field>
            <mat-select (selectionChange)="statusChange($event)"
              placeholder="{{'Tabla3PP.header.status.valor' | translate}}" multiple>
              <mat-option *ngFor="let status of statusList" [value]="status">
                {{ getStatusName(status) | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-3">
        <div class="c-select">
          <mat-form-field>
            <mat-select (selectionChange)="inherentRiskChange($event)"
              placeholder="{{'Tabla3PP.header.inherentRisk' | translate}}" multiple>
              <mat-option *ngFor="let inherentRisk of inherentRiskList" [value]="inherentRisk">
                {{ getInherentRiskText(inherentRisk) | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="o-scroll o-scroll--small">
    <div class="c-table__bottom">
      <div *ngIf="dataSource.loading$ | async">
        <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
      </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let thirdParty">
            <mat-checkbox (change)="onThirdPartyToggled(thirdParty)" [checked]="selection.isSelected(thirdParty)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'Tabla3PP.header.name.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element" class="bold">
            <span>{{element.companyName}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'Tabla3PP.header.hq.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.country?.description || '-'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Sector">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'Tabla3PP.header.sector.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.thirdPartyConfig?.Sector | translatedescription}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            {{'Tabla3PP.header.status.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <app-third-party-status [status]="element.status"></app-third-party-status>
          </td>
        </ng-container>

        <ng-container matColumnDef="InherentRisk">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            {{'Tabla3PP.header.inherentRisk'| translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{ getInherentRiskText(element.thirdPartyConfig?.InherentRisk) | translate}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Responsible">
          <th mat-header-cell *matHeaderCellDef>
            {{'Tabla3PP.header.responsible.valor' |
            translate}} </th>
          <td mat-cell *matCellDef="let element">
            <app-responsible-user [responsibleUserId]="element.userOwnerId">
            </app-responsible-user>
          </td>
        </ng-container>

        <ng-container matColumnDef="Sync">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            Sync
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.thirdPartyConfig?.Synced">
              <img src="../../../../assets/fi-rr-check-circle-filled.svg" alt="">
            </ng-container>
            <ng-container *ngIf="!element.thirdPartyConfig?.Synced">
              <img src="../../../../assets/fi-rr-cross-circle-filled.svg" alt="">
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            {{'Tabla3PP.header.actions.valor'| translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/perfil-tercero',element.id]" class="c-table__watch">
              <span class="c-box-edit__button-ico" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12.747">
                  <path
                    d="m19.873 98.82c-.179-.244-4.436-5.985-9.873-5.985s-9.695 5.74-9.873 5.984a.66 .66 0 0 0 0 .778c.178.244 4.436 5.985 9.873 5.985s9.694-5.74 9.873-5.984a.659 .659 0 0 0 0 -.778m-9.873 5.444c-4 0-7.474-3.81-8.5-5.05 1.026-1.247 4.487-5.06 8.5-5.06s7.473 3.809 8.5 5.06c-1.025 1.247-4.486 5.05-8.5 5.05"
                    transform="translate(0-92.83)" />
                  <path
                    d="M158.678 154.725a3.956 3.956 0 1 0 3.956 3.956A3.961 3.961 0 0 0 158.678 154.725m0 6.593a2.637 2.637 0 1 1 2.637 -2.637A2.64 2.64 0 0 1 158.678 161.318"
                    transform="translate(-148.68-152.3)" />
                </svg>
              </span> {{'Tabla3PP.actions.view.valor' | translate}}
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">{{'Tabla3PP.noresults.valor' | translate}}</td>
        </tr>
      </table>

      <mat-paginator [length]="dataSource.totalItems$ | async" [pageSize]="initialPageSize"
        [pageSizeOptions]="[5, 10, 25, 100]" class="c-pagination">
      </mat-paginator>

    </div>
  </div>
</section>
