import { Component, Input, OnChanges } from "@angular/core";
import { AMLUpdateRecord } from "@app/modules/screening-hub/models/GesintelScreening/AMLUpdateRecord";

@Component({
  selector: 'aml-update-main-pep',
  templateUrl: './main-pep.component.html',
  styleUrls: ['./main-pep.component.scss'],
})
export class AMLUpdateMainPEPComponent {
  @Input() scoringDetails: AMLUpdateRecord;

  constructor() { }
}
