import { Component, Input, OnChanges } from '@angular/core';
import { QueryResult } from '@app/models/common/queryResult';
import { RelatedPerson } from '@app/modules/screening-hub/models/GesintelScreening/RelatedPerson';

@Component({
  selector: 'aml-update-parental-mesh',
  templateUrl: './parental-mesh.component.html',
  styleUrls: ['./parental-mesh.component.scss'],
})
export class ParentalMeshComponent implements OnChanges {
  @Input() parentalMesh: RelatedPerson[];

  displayedColumns: string[] = [
    'rut',
    'name',
    'kinship',
    'category'
  ];

  queryResult: QueryResult<RelatedPerson> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() { }

  ngOnChanges(): void {
    this.getScreenings();
  }

  getScreenings() {
    if (this.parentalMesh) {
      this.queryResult.items = this.parentalMesh
        .slice(
          (this.query.Page - 1) * this.query.PageSize,
          this.query.Page * this.query.PageSize
        );
      this.queryResult.totalItems = this.parentalMesh.length;
    }
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getScreenings();
  }
}
