import {  Component, Input, OnInit } from '@angular/core';
import { AsyncValidatorFn, FormArray, FormBuilder, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'app-multiple-answer',
  templateUrl: './multiple-answer.component.html',
  styleUrls: ['./multiple-answer.component.scss']
})
export class MultipleAnswerComponent implements OnInit {

  @Input() weightValues = [];
  @Input() canEdit: boolean;
  @Input() scoreType: boolean;

  form: FormGroup;

  constructor(
    public rootFormGroup: FormGroupDirective,
    public fb: FormBuilder,
  ) {}

  get options() {
    return this.form.controls["Options"] as FormArray;
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    if (this.options.length === 0) {
      this.addOption();
    }
  }

  ngAfterViewInit(): void {
    this.form.setAsyncValidators(this.minimumOptionsValidator);
  }

  addOption(): void {
    const answerForm = this.fb.group({
      Text: ['', Validators.required],
      Weight: [this.weightValues[0].value, Validators.required],
    })
    this.options.push(answerForm);
  }

  deleteOption(index: number) {
    this.options.removeAt(index);
    this.form.markAsDirty();
  }

  minimumOptionsValidator: AsyncValidatorFn = (): Promise<ValidationErrors> | null => {
    return this.options.length < 2 ? Promise.resolve({ minimumOptionsValidator : true}) : Promise.resolve(null);
  };
}
