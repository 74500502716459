<div class="o-wrapper">
    <main id="content">
        <div class="c-head-section">
            <div class="c-head-section__left">
                <h2 class="o-title">{{'MisRiesgos.title' | translate }}</h2>
                <p class="o-text">{{'MisRiesgos.text' | translate }}</p>
            </div>
        </div>
        <div class="c-head-section--tab__bottom">
            <app-myrisks-table></app-myrisks-table>
        </div>
    </main>
</div>
