import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScoringRisk } from '@app/models/scoring-tools/scoringRisk';
import { ScreeningDetails } from '@app/models/scoring-tools/screeningDetails';
import { ScoringToolsService } from '@app/services/scoring-tools.service';
import { ThirdPartyScreeningReportService } from '@app/services/reports/third-party-screening-report.service';
import { TranslateService } from '@ngx-translate/core';
import download from 'downloadjs';
import { Toaster } from 'ngx-toast-notifications';
import { Location } from '@angular/common';
import { ScreeningReportService } from '@app/services/reports/screening-report.service';

@Component({
  selector: 'app-screening-details',
  templateUrl: './screening-details.component.html',
  styleUrls: ['./screening-details.component.scss'],
})
export class ThirdPartyScreeningDetailsComponent implements OnInit {
  thirdPartyId?: number;
  screeningId: number;
  screeningDetails: ScreeningDetails;

  generatingReportMessage: string;
  reportGeneratedMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private scoringToolsService: ScoringToolsService,
    private tpScreeningReportService: ThirdPartyScreeningReportService,
    private screeningReportService: ScreeningReportService,
    private translate: TranslateService,
    private location: Location,
    private toaster: Toaster
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      (this.thirdPartyId = params.id), (this.screeningId = params.screeningId);
    });

    this.scoringToolsService
      .getScreeningDetails(this.screeningId)
      .subscribe((res) => {
        this.screeningDetails = res;
      });

    this.translate
      .get('ScreeningDetails.generatingReport')
      .subscribe((translation) => {
        this.generatingReportMessage = translation;
      });

    this.translate
      .get('ScreeningDetails.reportGenerated')
      .subscribe((translation) => {
        this.reportGeneratedMessage = translation;
      });
  }

  getRisks(risks: ScoringRisk[]): string[] {
    return (
      risks?.reduce(function (dr: string[], a) {
        const index = dr.findIndex((r) => r == a.type);
        if (index === -1) {
          dr.push(a.type);
        }
        return dr;
      }, []) || []
    );
  }

  back() {
    this.location.back();
  }

  async downloadReport() {
    this.toaster.open({
      text: this.generatingReportMessage,
      duration: 4000,
      type: 'info',
    });

    let a: [string, Uint8Array];
    if (this.thirdPartyId) {
      a = await this.tpScreeningReportService.getScreeningReport(
        this.thirdPartyId,
        this.screeningId
      );
    } else {
      a = await this.screeningReportService.getScreeningReport(
        this.screeningId
      );
    }

    this.toaster.open({
      text: this.reportGeneratedMessage,
      duration: 4000,
      type: 'success',
    });
    download(a[1], a[0], 'application/pdf');
  }
}
