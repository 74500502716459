import { MonitoringConfiguration } from "@app/models/MonitoringConfiguration";
import { MonitoringConfigurationDto } from "src/proxy/model/monitoringConfigurationDto";

export function mapMonitoringConfiguration(dto: MonitoringConfigurationDto): MonitoringConfiguration {
  if (dto == null) return null;

  return <MonitoringConfiguration>{
    id: dto.Id,
    preriodicity: dto.Preriodicity,
    repeatIn: dto.RepeatIn,
    refinitivThirdPartyId: dto.RefinitivThirdPartyId,
    risksToWatch: dto.RisksToWatch
  };
}

export function mapMonitoringConfigurationDto(dto: MonitoringConfiguration): MonitoringConfigurationDto {
  return <MonitoringConfigurationDto> {
    Id: dto.id,
    Preriodicity: dto.preriodicity,
    RepeatIn: dto.repeatIn,
    RefinitivThirdPartyId: dto.refinitivThirdPartyId,
    RisksToWatch: dto.risksToWatch
  }
}
