import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RemoteService } from '@app/services/remote.service';
import { parsearErroresAPI } from '@app/utilities/utilities';
import { Client} from '@app/models/Client';
import { ConfigurationService } from '@app/services/configuration.service'
import { Toaster } from 'ngx-toast-notifications';
import { AwsS3Service } from '@app/services/awsS3.service';
import { mapClient } from '@app/services/mappings/client.mappings';

@Component({
  selector: 'app-config-company',
  templateUrl: './config-company.component.html',
  styleUrls:['./config-company.component.scss']
})

export class ConfigCompanyComponent implements OnInit {
  errores: string[] = [];
  userData: Client;

  form: any;
  loaded: boolean = false;
  selectedIconFileUri = null
  selectedIconFileName = null
  selectedIconFile = null
  iconUrl: string;
  iconUri: string;
  uploadButtonText: string;
  updatedDataMessage: string;


  constructor(
    private fb: FormBuilder,
    private remoteService: RemoteService,
    private translate: TranslateService,
    private configService: ConfigurationService,
    private toaster: Toaster,
    private awsS3Service: AwsS3Service
  ) { }

  async ngOnInit(): Promise<void> {

    await this.loadInitialData();
    this.form = this.fb.group({
      Name: new FormControl(this.userData.name, [Validators.required]),
      Icon: new FormControl(null, [Validators.required])
    })

    this.form.get('Icon').setValue({ 'FileUri': this.selectedIconFileUri, 'FileName': this.selectedIconFileName, 'File': this.selectedIconFile });

    this.translate.get('ConfiguracionEmpresa.companydata.valor').subscribe(translation => {
      this.updatedDataMessage = translation;
    });

    this.translate.get('ConfiguracionEmpresa.customize.valor').subscribe(translation => {
      this.uploadButtonText = translation;
    })

    this.loaded = true;
  }

  updateProfileData() {
    let model = {
      name: this.form.get('Name').value,
      selectedIconFileUri: this.selectedIconFileUri,
      selectedIconFileName: this.selectedIconFileName
    }

    this.updateIconFile(model);
  }

  private async updateIconFile(model: any) {
    if(this.selectedIconFile) {
      return await this.configService.getFileStorageKeyClientPublicImage(this.selectedIconFileName).then(res => {
        model.selectedIconFileUri = res.FileId;
        this.awsS3Service.uploadFileToS3(res.FileUri, this.selectedIconFile).subscribe();
      }).then(() => this.updateVisibleInfo(model));
    } else {
      return await this.updateVisibleInfo(model);
    }
  }

  private async updateVisibleInfo(model: any) {
    await this.configService.updateVisibleInfo(model)
    .subscribe(response => {
      this.loadInitialData();
      this.toaster.open({ text: this.updatedDataMessage, duration: 6000, type: 'info' });
    }, errores => this.errores = parsearErroresAPI(errores));
  }


  loadIconImage() {
    if (this.selectedIconFileUri !== this.iconUri) {
      this.remoteService.getTokenFileUri(this.selectedIconFileUri).subscribe(res => this.iconUrl = res.url);
      this.iconUri = this.selectedIconFileUri;
    }

    return this.iconUrl;
  }

  selectIcon(resp) {
    this.selectedIconFile = resp.file;
    this.selectedIconFileName = resp.file?.name;
  }

  async loadInitialData() {
    let promiseUserData = this.getUserDataP();

    let promises = [promiseUserData];

    await Promise.all(promises).then(values => {
      this.getUserData(values[0]);
    });
  }

  getUserDataP() {
    return this.remoteService.getRequest("Users/GetClientData");
  }

  getUserData(r) {
    if (r !== null && r !== undefined) {
      this.userData = mapClient(r, this.translate.currentLang);

      if (this.userData.iconImageFileUri !== null || this.userData.iconImageFileName !== null) {
        this.selectedIconFileUri = this.userData.iconImageFileUri;
        this.selectedIconFileName = this.userData.iconImageFileName;
        this.selectedIconFile = null
      } else {
        this.selectedIconFileUri = null;
        this.selectedIconFileName = null;
        this.selectedIconFile = null;
      }
    }
  }
}
