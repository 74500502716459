import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';
import { GlobalService } from "@app/services/global.service";

@Component({
  selector: 'app-onboarding-intro',
  templateUrl: './onboarding-intro.component.html',
  styles: [
  ]
})
export class OnboardingIntroComponent implements OnInit {

  constructor(
    public auth: AuthenticationService,
    public global: GlobalService,
  ) { }

  //Se agrega clase a body para customizar header y nav 
  ngOnInit(): void {

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('js-noHeader');

    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
        this.global.navShowOnboarding = false;
    }, 0);
  }

  ngOnDestroy(): void {

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('js-noHeader');

    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
        this.global.navShowOnboarding = true;
    }, 0);
  }

}
