<div *ngIf="status == thirdPartyStatus.pending"
    class="c-state" aria-hidden="true">
    <img src="../../../../assets/thirdPartyPending.svg" alt="">
</div>
<div *ngIf="status == thirdPartyStatus.inProgress"
    class="c-state" aria-hidden="true">
    <img src="../../../../assets/thirdPartyInProgress.svg" alt="">
</div>
<div *ngIf="status == thirdPartyStatus.approved"
    class="c-state" aria-hidden="true">
    <img src="../../../../assets/thirdPartyApproved.svg" alt="">
</div>
<div *ngIf="status == thirdPartyStatus.rejected"
    class="c-state" aria-hidden="true">
    <img src="../../../../assets/thirdPartyRejected.svg" alt="">
</div>
<span class="status-description">{{translateStatusDescription(status)}}</span>