import { MemberCheckScanResult } from "@app/modules/screening-hub/models/member-check/member-check-scan-result";
import { MemberCheckScanResultDto } from "src/proxy/model/memberCheckScanResultDto";

export function mapMemberCheckScanResult(dto: MemberCheckScanResultDto): MemberCheckScanResult {
  if (!dto) return null;

  return <MemberCheckScanResult>{
    name: dto.Name,
    matchStrength: dto.MatchStrength,
    memberCheckId: dto.MemberCheckId,
    location: dto.Location,
    dob: dto.Dob,
    entityType: dto.EntityType
  };
}

export function mapMemberCheckScanResultDto(dto: MemberCheckScanResult): MemberCheckScanResultDto {
  if (!dto) return null;

  return <MemberCheckScanResultDto>{
    Name: dto.name,
    MatchStrength: dto.matchStrength,
    MemberCheckId: dto.memberCheckId,
    Location: dto.location,
    Dob: dto.dob,
    EntityType: dto.entityType
  };
}
