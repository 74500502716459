import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ScoringToolsService } from "@app/services/scoring-tools.service";

@Component({
    selector: 'app-create-resolution',
    templateUrl: './create-resolution.component.html',
    styleUrls: ['./create-resolution.component.scss']
})
export class CreateResolutionComponent {

    resolutionExplanation: string;
    selectedReason: number;

    constructor(public dialogRef: MatDialogRef<CreateResolutionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { name: string, software: number, data: string, totalResults: number, searchType: number, thirdPartyId?: number },
        private scoringToolsService: ScoringToolsService) { }

    closeModal(): void {
        this.dialogRef.close();
    }

    submitForm(): void {
        this.scoringToolsService
            .createResolution(this.data.name, this.data.software, this.data.data, this.data.totalResults,
                this.selectedReason, this.resolutionExplanation, this.data.searchType, this.data.thirdPartyId)
            .subscribe(r => {
                this.dialogRef.close(r);
            });
    }

}