import { PEPListInfoDto } from "src/proxy/model/pEPListInfoDto";
import { PEPListInfo } from "../../models/GesintelScreening/PEPListInfo";
import { mapPEPInfo } from "./pep-info.mapping";

export function mapPEPListInfo(dto: PEPListInfoDto): PEPListInfo {
  if (!dto) return null;

  return <PEPListInfo> {
    mainPEP: mapPEPInfo(dto.MainPEP),
    relationshipPEP: dto.RelationshipPEP?.map(i => mapPEPInfo(i)),
    partnershipOtherPEP: dto.PartnershipOtherPEP?.map(i => mapPEPInfo(i)),
    kinshipTiesWithPEP: dto.KinshipTiesWithPEP?.map(i => mapPEPInfo(i)),
    partnershipWithPEP: dto.PartnershipOtherPEP?.map(i => mapPEPInfo(i)),
  }
}
