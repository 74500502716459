import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-refinitiv-config-form',
  templateUrl: './refinitiv-config-form.component.html',
  styleUrls: ['./refinitiv-config-form.component.scss']
})
export class RefinitivConfigFormComponent {

  @Input() apiKey: string;
  @Input() apiSecret: string;

  @Output() apiKeyChange = new EventEmitter<string>();
  @Output() apiSecretChange = new EventEmitter<string>();

  constructor() { }

  onApiKeyChange() {
    this.apiKeyChange.emit(this.apiKey);
  }

  onApiSecretChange() {
    this.apiSecretChange.emit(this.apiSecret);
  }
}
