<div class="marital-status-data">
  <div class="marital-status-data__group" aria-labelledby="keyData">
    <div class="marital-status-data-item">
      <p id="eventDate" class="marital-status-data-item__title">
        {{ "EquifaxWSMaritalStaus.eventDate" | translate }}
      </p>
      <p class="marital-status-data-item__text" aria-labelledby="eventDate">
        {{ maritalStatus?.eventDate || "" }}
      </p>
    </div>
    <div class="marital-status-data-item">
      <p id="partnerName" class="marital-status-data-item__title">
        {{ "EquifaxWSMaritalStaus.partnerName" | translate }}
      </p>
      <p class="marital-status-data-item__text" aria-labelledby="partnerName">
        {{ maritalStatus?.partnerName || "" }}
      </p>
    </div>
    <div class="marital-status-data-item">
      <p id="maritalStatus" class="marital-status-data-item__title">
        {{ "EquifaxWSMaritalStaus.maritalStatus" | translate }}
      </p>
      <p class="marital-status-data-item__text" aria-labelledby="maritalStatus">
        {{ maritalStatus?.maritalStatus || "" }}
      </p>
    </div>
    <div class="marital-status-data-item">
      <p id="partnerCedula" class="marital-status-data-item__title">
        {{ "EquifaxWSMaritalStaus.partnerCedula" | translate }}
      </p>
      <p class="marital-status-data-item__text" aria-labelledby="partnerCedula">
        {{ maritalStatus?.partnerCedula || "" }}
      </p>
    </div>
  </div>
</div>
