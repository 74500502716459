import { Component, Input } from "@angular/core";
import { EquifaxWSMaritalStatusData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSMaritalStatusData";

@Component({
  selector: 'equifax-ws-individual-marital-status-data',
  templateUrl: './marital-status-data.component.html',
  styleUrls: ['./marital-status-data.component.scss'],
})
export class EquifaxWSIndividualMaritalStatusDataComponent {
  @Input() maritalStatus: EquifaxWSMaritalStatusData;

  constructor() { }

}
