<section class="c-table u-mt-1">
  <div class="c-table__top">
    <div class="row align-items-center">
      <div class="col-8">
        <app-text-table-filter (filter)="updateFilter($event)"></app-text-table-filter>
      </div>
      <div *appAuthRole="['Owner', 'Manager', 'Assistant']" class="col-4 d-flex justify-content-end">
        <button [disabled]="isLoading" class="c-button c-button--gradient" (click)="uploadFile()">
          <span class="c-button__ico" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.667 20">
              <path
                d="M13.667,13.333a.833.833,0,0,1-.833.833H11.167v1.667a.833.833,0,1,1-1.667,0V14.167H7.833a.833.833,0,1,1,0-1.667H9.5V10.833a.833.833,0,1,1,1.667,0V12.5h1.667A.833.833,0,0,1,13.667,13.333Zm5-4.6v7.1A4.172,4.172,0,0,1,14.5,20H6.167A4.172,4.172,0,0,1,2,15.833V4.167A4.172,4.172,0,0,1,6.167,0H9.929a5.8,5.8,0,0,1,4.125,1.708l2.9,2.9a5.793,5.793,0,0,1,1.709,4.124ZM12.876,2.887A4.175,4.175,0,0,0,12,2.237v3.6a.833.833,0,0,0,.833.833h3.6a4.153,4.153,0,0,0-.651-.875ZM17,8.737c0-.137-.027-.269-.039-.4H12.833a2.5,2.5,0,0,1-2.5-2.5V1.706c-.135-.012-.267-.039-.4-.039H6.167a2.5,2.5,0,0,0-2.5,2.5V15.833a2.5,2.5,0,0,0,2.5,2.5H14.5a2.5,2.5,0,0,0,2.5-2.5Z"
                transform="translate(-2)" />
            </svg>
          </span>
          <span class="c-button--gradient__text">
            {{ "ThirdPartyTable.attachDocument" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="o-scroll o-scroll--small">
    <div class="c-table__bottom">
      <div *ngIf="isLoading != undefined ? isLoading : false">
        <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
      </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Origin">
          <th mat-header-cell *matHeaderCellDef>
            {{ "ThirdPartyTable.origin" | translate }}
          </th>
          <td mat-cell *matCellDef="let data">
            <p>{{ getFileOrigin(data) }}</p>
          </td>
        </ng-container>
        <ng-container matColumnDef="FileName">
          <th mat-header-cell *matHeaderCellDef>
            {{ "ThirdPartyTable.attachedFile" | translate }}
          </th>
          <td mat-cell *matCellDef="let data">
            {{ data.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="UploadDate">
          <th mat-header-cell *matHeaderCellDef>
            {{ "ThirdPartyTable.uploadDate" | translate }}
          </th>
          <td mat-cell *matCellDef="let data">
            <p>{{ data.uploadDate | date }}</p>
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            {{ "ThirdPartyTable.action" | translate }}
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container *appAuthRole="['Owner', 'Manager', 'Assistant', 'Auditor']">
              <a *ngIf="!data.expandable" class="c-table__watch" (click)="download(data.fileUri, data.name)">
                <span class="c-box-edit__button-ico" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18"
                    height="18" viewBox="0 0 18 18">
                    <defs>
                      <style>
                        .a {
                          fill: url(#a);
                        }
                      </style>
                      <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#fdc130" />
                        <stop offset="1" stop-color="#faa868" />
                      </linearGradient>
                    </defs>
                    <path class="a"
                      d="M17.25,16h0a.724.724,0,0,0-.75.694v2.778a.724.724,0,0,1-.75.694H2.25a.724.724,0,0,1-.75-.694V16.694A.724.724,0,0,0,.75,16h0a.724.724,0,0,0-.75.694v2.778a2.171,2.171,0,0,0,2.25,2.083h13.5A2.171,2.171,0,0,0,18,19.472V16.694A.724.724,0,0,0,17.25,16Z"
                      transform="translate(0 -3.555)" />
                    <path class="a"
                      d="M5.231,9.4l3.622,3.185a2.611,2.611,0,0,0,3.35,0L15.824,9.4a.637.637,0,0,0,0-.982.868.868,0,0,0-1.117,0l-3.39,2.98V.694a.8.8,0,0,0-1.579,0V11.4L6.348,8.419a.868.868,0,0,0-1.117,0,.637.637,0,0,0,0,.982Z"
                      transform="translate(-1.528)" />
                  </svg> </span>{{ "ThirdPartyTable.download" | translate }}
              </a>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">
            {{ "Tabla3PP.noresults.valor" | translate }}
          </td>
        </tr>
      </table>

      <mat-paginator [length]="dataSource.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
        class="c-pagination">
      </mat-paginator>
    </div>
  </div>
</section>
