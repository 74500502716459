import { Component, Input } from "@angular/core";
import { AMLUpdateRecord } from "@app/modules/screening-hub/models/GesintelScreening/AMLUpdateRecord";

@Component({
  selector: 'aml-update-mandatory-lists',
  templateUrl: './mandatory-lists.component.html',
  styleUrls: ['./mandatory-lists.component.scss'],
})
export class AMLUpdateMandatoryListsComponent {
  @Input() scoringDetails: AMLUpdateRecord;

  constructor() { }
}
