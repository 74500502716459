<div class="o-wrapper">
  <main id="content">
    <!--encabezado de sección-->
    <div class="c-head-section c-head-section--tab bg-cream">
      <!--parte superior-->
      <div class="c-head-section--tab__top">
        <div class="c-head-section__left">
          <div class="c-head-section--tab__info">
            <h2 class="c-head-section--tab__title">
              {{ "ScoringCandidates.ScreeningSearchTitle" | translate }}
            </h2>
            <div class="c-head-section--tab-data">
              <div class="c-head-section--tab-data__item">
                <div>
                  {{ "ScoringCandidates.ScreeningSearchText" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--volver a listado-->
      <div class="c-go-back">
        <a [routerLink]="" (click)="back()" class="c-go-back__button">
          <span class="c-go-back__arrow" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
              <path
                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                transform="translate(-5-6.01)" />
            </svg>
          </span>
          {{ "ScoringCandidates.GoBack.valor" | translate }}
        </a>
      </div>
    </div>
    <div *ngIf="isLoading" class="overlay">
      <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
    </div>
    <div class="c-form c-form--space">
      <fieldset class="u-mb-1medium">
        <legend class="c-form__legend">
          {{ 'ScoringCandidates.ScreeningProvider'| translate }}
        </legend>
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-3">
            <div class="c-form__block">
              <div class="c-select">
                <mat-form-field>
                  <mat-select placeholder="{{ 'ScoringCandidates.ScreeningProvider' | translate }}"
                    (selectionChange)="providerChanged()" [(ngModel)]="selectedProvider">
                    <mat-option *ngFor="let provider of screeningProviders" [value]="provider">
                      {{ getProviderName(provider) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset *ngIf="searchType" class="u-mb-1medium">
        <legend class="c-form__legend">
          {{ "ScoringCandidates.typeOfSearch" | translate }}
        </legend>
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-3">
            <div class="c-form__block">
              <mat-button-toggle-group [(ngModel)]="searchType" aria-label="Search type">
                <mat-button-toggle *ngFor="let type of searchTypes" [value]="type">
                  {{ "ScoringCandidates." + type | translate }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
      </fieldset>
      <ng-container *ngIf="this.selectedProvider === ScreeningProvider.Refinitiv">
        <app-scoring-candidates-organization-search-refinitiv *ngIf="searchType === 'organization'"
          [countryList]="this.countries"
          (onSearchClick)="search($event)"></app-scoring-candidates-organization-search-refinitiv>
        <app-scoring-candidates-individual-search-refinitiv *ngIf="searchType === 'individual'"
          [countryList]="this.countries"
          (onSearchClick)="search($event)"></app-scoring-candidates-individual-search-refinitiv>
      </ng-container>

      <ng-container *ngIf="this.selectedProvider === ScreeningProvider.AMLUpdate">
        <app-scoring-candidates-organization-search-gesintel *ngIf="searchType === 'organization'"
          [countryList]="this.countries"
          (onSearchClick)="search($event)"></app-scoring-candidates-organization-search-gesintel>
        <app-scoring-candidates-individual-search-gesintel *ngIf="searchType === 'individual'"
          [countryList]="this.countries"
          (onSearchClick)="search($event)"></app-scoring-candidates-individual-search-gesintel>
        <app-scoring-candidates-both-search-gesintel *ngIf="searchType === 'both'" [countryList]="this.countries"
          (onSearchClick)="search($event)"></app-scoring-candidates-both-search-gesintel>
      </ng-container>

      <ng-container *ngIf="this.selectedProvider === ScreeningProvider.EquifaxWS">
        <equifax-ws-scoring-candidates-search [searchType] = "searchType"
          (onSearchClick)="search($event)">
        </equifax-ws-scoring-candidates-search>
      </ng-container>

      <ng-container *ngIf="this.selectedProvider === ScreeningProvider.MemberCheck">
        <member-check-scoring-member-search *ngIf="searchType === 'individual'"
          (onSearchClick)="search($event)">
        </member-check-scoring-member-search>
        <member-check-scoring-corp-search *ngIf="searchType === 'organization'"
          (onSearchClick)="search($event)">
        </member-check-scoring-corp-search>
      </ng-container>

    </div>
  </main>
</div>
