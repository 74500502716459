<div class="o-wrapper">
    <main id="content">

        <div class="c-head-section small-height">
            <div class="c-head-section__left">
                <h2 class="o-title">
                    {{'Busqueda.title' | translate}}
                </h2>
            </div>
            <div class="c-head-section__right">
                <div *appAuthRole="[Role.Owner, Role.Manager, Role.Collaborator, Role.Assistant]" class="c-dropdown-button u-mr-2">
                    <mat-menu #menuLoad="matMenu">
                        <a mat-menu-item [routerLink]="['/nuevo-tercero']" class="c-dropdown-button__link" data-test-id="load-individual">{{'Busqueda.button.single_load.valor' | translate}}</a>
                        <a mat-menu-item [routerLink]="['/importar-terceros-carga-masiva']" data-test-id="load-massive" (click)="global.activeImportThird()" class="c-dropdown-button__link">{{'Busqueda.button.masive_load.valor' | translate}}</a>
                    </mat-menu>
                    <button [matMenuTriggerFor]="menuLoad" class="c-button c-button--gradient" data-test-id="load-third-menu">
                        <span class="c-button__ico" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M21.833,10.5H20.167V8.833a.833.833,0,1,0-1.667,0V10.5H16.833a.833.833,0,0,0,0,1.667H18.5v1.667a.833.833,0,1,0,1.667,0V12.167h1.667a.833.833,0,1,0,0-1.667Z" transform="translate(-2.667-1.333)"/>
                                <path d="M8 10A5 5 0 1 0 3 5a5 5 0 0 0 5 5M8 1.667A3.333 3.333 0 1 1 4.667 5 3.333 3.333 0 0 1 8 1.667" transform="translate(-.5)"/>
                                <path d="M7.5,14A7.508,7.508,0,0,0,0,21.5a.833.833,0,1,0,1.667,0,5.833,5.833,0,1,1,11.667,0A.833.833,0,0,0,15,21.5,7.508,7.508,0,0,0,7.5,14Z" transform="translate(0-2.333)"/>
                            </svg>
                        </span>
                        <span class="c-button--gradient__text">{{'Busqueda.button.load3pp.valor' | translate}}</span>
                    </button>
                </div>
                <div *appAuthRole="[Role.Owner, Role.Manager, Role.Collaborator, Role.Assistant]" class="c-dropdown-button u-mr-0">
                    <mat-menu #moreActions="matMenu">
                        <button mat-menu-item class="c-dropdown-button__link" data-test-id="change-status" (click)="changeStates()">{{'Busqueda.changeState' | translate}}</button>
                    </mat-menu>
                    <button mat-button [matMenuTriggerFor]="moreActions" class="c-dropdown-button__button" data-test-id="more-actions">
                        <span class="c-button__ico" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <!-- TODO chevron here-->
                            </svg>
                        </span>
                        {{'Busqueda.moreActions' | translate}}
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="loading">
            <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
        </div>

        <mat-tab-group class="c-radiotab" animationDuration="0" dynamicHeight>
            <mat-tab *ngFor="let thirdPartyType of thirdPartyTypes" class="c-radiotab__item">
                <ng-template mat-tab-label>
                    <span>{{thirdPartyType | translatedescription}}</span>
                </ng-template>
                <ng-template matTabContent>
                    <app-third-parties-list-table [thirdPartyTypeId]="thirdPartyType.id"></app-third-parties-list-table>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

    </main>
</div>
