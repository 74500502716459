import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TwoFactorDto } from '@app/models/RiskallayUser';
import { Role } from '@app/models/Role';
import { AuthenticationService } from '@app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-app-two-step-verification',
  templateUrl: './app-two-step-verification.component.html'
})
export class AppTwoStepVerificationComponent implements OnInit {

  public twoStepForm: FormGroup;
  public showError: boolean;
  public errorMessage: string;
  private _provider: string;
  private _email: string;
  private incorrectCodeMessage: string;

  constructor(private _authService: AuthenticationService,
    private _route: ActivatedRoute,
    private translate: TranslateService,
    private toaster: Toaster,
    private _router: Router) { }

  ngOnInit(): void {
    this.twoStepForm = new FormGroup({
      twoFactorCode: new FormControl('', [Validators.required]),
    });

    this.translate.get('TwoStepVerification.incorrectCode')
      .subscribe(translation => {
        this.incorrectCodeMessage = translation;
      });

    this._provider = this._route.snapshot.queryParams['provider'];
    this._email = this._route.snapshot.queryParams['email'];
  }

  public validateControl = (controlName: string) => {
    return this.twoStepForm.controls[controlName].invalid && this.twoStepForm.controls[controlName].touched;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.twoStepForm.controls[controlName].hasError(errorName);
  }

  public loginUser = (twoStepFromValue) => {
    this.showError = false;

    const formValue = { ...twoStepFromValue };
    const twoFactorDto: TwoFactorDto = {
      Email: this._email,
      Provider: this._provider,
      Token: formValue.twoFactorCode
    };

    this._authService.twoStepLogin(twoFactorDto)
    .then(res => {
      if (res.IsAuthSuccessful) {
        const roles = this._authService.getRoles();
        if (roles.filter(r => r === Role.ThirdParty).length > 0) {
          this._router.navigateByUrl('/pagina-id');
        } else {
          this._router.navigateByUrl('/home');
        }
      } else {
          this.toaster.open({ text: this.incorrectCodeMessage, duration: 6000, type: 'danger' });
      }
    },
    error => {
      this.errorMessage = error;
      this.showError = true;
    });
  }
}
