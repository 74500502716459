import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-resolution-link',
  templateUrl: './confirm-delete-resolution-link.component.html'
})
export class ConfirmDeleteResolutionLinkComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteResolutionLinkComponent>,    
  ) { }

  close(): void {
    this.dialogRef.close();
  }
  
}
