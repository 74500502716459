import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { DomSanitizer } from '@angular/platform-browser';
import { MemberCheckScoringService } from '@app/modules/screening-hub/services/member-check-scoring.service';
import { MemberCheckScanResult } from '@app/modules/screening-hub/models/member-check/member-check-scan-result';
import { mapMemberCheckCorpReport, mapMemberCheckIndividualReport } from '../member-check-report.mapper';
import { RiskallayUserDto } from 'src/proxy/model/riskallayUserDto';

@Component({
  selector: 'member-check-candidate-detail',
  templateUrl: './member-check-candidate-detail.component.html',
  styleUrls: ['./member-check-candidate-detail.component.scss'],
})
export class MemberCheckCandidateDetailComponent implements OnInit {
  thirdPartyId: number;
  scanId: number;
  name: string;
  candidate: MemberCheckScanResult;

  currentUser: RiskallayUserDto;
  currentDate: Date;

  returnTabIndex: number = 3;
  report: string;

  private screeningSucceededMessage: string;
  private screeningFailedMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private memberCheckScoringService: MemberCheckScoringService,
    private router: Router,
    private translate: TranslateService,
    private toaster: Toaster,
    private sanitizer: DomSanitizer,
  ) {

    this.candidate =
      this.router.getCurrentNavigation().extras.state.scanResult;
    this.currentUser = this.router.getCurrentNavigation().extras.state.currentUser;
    this.currentDate = this.router.getCurrentNavigation().extras.state.currentDate;
  }

  ngOnInit() {
    this.translate
      .get('ScoringDetails.screeningSucceeded')
      .subscribe((translation) => {
        this.screeningSucceededMessage = translation;
      });

    this.translate
      .get('ScoringDetails.screeningFailed')
      .subscribe((translation) => {
        this.screeningFailedMessage = translation;
      });

    this.activatedRoute.params.subscribe((params) => {
      this.thirdPartyId = params.id;
      this.scanId = params.scanId;

      if (this.candidate.entityType == 1) {
        this.memberCheckScoringService
        .candidateMemberReport(this.candidate.memberCheckId)
        .subscribe(async (record) => {
          const userName = `${this.currentUser.Name} ${this.currentUser.SurName}`;
          const modifiedReport = await mapMemberCheckIndividualReport(record, userName, this.currentDate);
          this.report = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(modifiedReport) as any).changingThisBreaksApplicationSecurity;
          top.document.getElementById('ifrm').setAttribute("src", this.report);
        });
      } else {
        this.memberCheckScoringService
        .candidateCorpReport(this.candidate.memberCheckId)
        .subscribe(async (record) => {
          const userName = `${this.currentUser.Name} ${this.currentUser.SurName}`;
          const modifiedReport = await mapMemberCheckCorpReport(record, userName, this.currentDate);
          this.report = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(modifiedReport) as any).changingThisBreaksApplicationSecurity;
          top.document.getElementById('ifrm').setAttribute("src", this.report);
        });
      }
    });
  }

  save(): void {
    this.memberCheckScoringService
      .saveScreening(this.candidate, this.thirdPartyId)
      .subscribe(
        async (res) => {
          if (res) {
            this.toaster.open({
              text: this.screeningSucceededMessage,
              duration: 6000,
              type: 'success',
            });
            if (this.thirdPartyId) {
              this.router.navigate([
                'perfil-tercero',
                this.thirdPartyId,
                this.returnTabIndex,
              ]);
            } else {
              this.router.navigate(['screening-tools']);
            }
          } else {
            this.toaster.open({
              text: this.screeningFailedMessage,
              duration: 6000,
              type: 'danger',
            });
          }
        },
        (err) =>
          this.toaster.open({
            text: this.screeningFailedMessage,
            duration: 6000,
            type: 'danger',
          })
      );
  }

  back() {
    if (this.thirdPartyId) {
      this.router.navigate(['member-check/candidates', this.scanId, this.thirdPartyId], { queryParams: { name: this.candidate.name, searchType: this.candidate.entityType } });
    } else {
      this.router.navigate(['member-check/candidates', this.scanId], { queryParams: { name: this.candidate.name, searchType: this.candidate.entityType } });
    }
  }
}
