import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'riskallay';

  constructor(private route: Router) {
    this.routeEvent(this.route);
  }
  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        const loader = document.getElementsByClassName('load-screen')[0];
        if (!loader.classList.contains('load-screen-hidden')) {
          loader.classList.add('load-screen-hidden');
        }
      }
    });
  }

}
