import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClienOnBoarding, ClienOnBoardingUpdate, UpdateClientUsersData } from '@app/models/Client';

@Injectable({
    providedIn: 'root',
})

export class UserProfileService {
    constructor(
        private http: HttpClient,
        @Inject('API_URL') public serverUrl: string
    ) { }

    private apiURL = 'Users';

    public SaveOnboarding(client: ClienOnBoarding) {
        const formData = this.ConstruirFormData(client, false);
        return this.http.post(`${this.serverUrl}/${this.apiURL}/SaveOnboarding`, formData).toPromise();
    }

    public UpdateOnboarding(client: ClienOnBoardingUpdate) {
        const formData = this.ConstruirFormData(client, true);
        return this.http.put(`${this.serverUrl}/${this.apiURL}/UpdateClientData`, formData).toPromise();
    }

    public UpdateClientUsersData(data: UpdateClientUsersData){
        return this.http.put(`${this.serverUrl}/${this.apiURL}/UpdateClientUsersData`, data).toPromise();
    }

    async getFileStorageKey(filename: string): Promise<any>{
        const res = await this.http.get(`${this.serverUrl}/Users/UploadOnBoardingFile/${filename}`).toPromise();    
        return res;
      }

    private ConstruirFormData(client: any, editMode: boolean): FormData {
        const formData = new FormData();


        if (editMode) {
            formData.append('Id', client.Id.toString());
            formData.append('ClientInfo.Id', client.ClientInfo.Id.toString());
        }

        if (client.CompanyRegistrationFileUri)
            formData.append('CompanyRegistrationFileUri', client.CompanyRegistrationFileUri);

        if (client.ClientInfo.ExternalAuditFileUri)
            formData.append('ClientInfo.ExternalAuditFileUri', client.ClientInfo.ExternalAuditFileUri);
        
        if (client.ClientInfo.InternalAuditFileUri)
            formData.append('ClientInfo.InternalAuditFileUri', client.ClientInfo.InternalAuditFileUri);
        
        if (client.ClientInfo.AnnualAccountingFirstYearFileUri)
            formData.append('ClientInfo.AnnualAccountingFirstYearFileUri', client.ClientInfo.AnnualAccountingFirstYearFileUri);
        
        if (client.ClientInfo.AnnualAccountingSecondYearFileUri)
            formData.append('ClientInfo.AnnualAccountingSecondYearFileUri', client.ClientInfo.AnnualAccountingSecondYearFileUri);

        formData.append('Name', client.Name);

        if (client.OtherTypeOfActivity != null)
            formData.append('OtherTypeOfActivity', client.OtherTypeOfActivity);
        
        formData.append('TypeOfActivity', client.TypeOfActivity.toString());
        formData.append('CompanyCif', client.CompanyCif.toString());


        if (client.CompanyRegistrationFileName != null)
            formData.append('CompanyRegistrationFileName', client.CompanyRegistrationFileName);

        formData.append('ClientInfo.CompanyGroup', client.ClientInfo.CompanyGroup.toString());
        formData.append('ClientInfo.ComplianceProgram', client.ClientInfo.ComplianceProgram.toString());
        formData.append('CountryId', client.CountryId.toString());
        formData.append('ClientInfo.ExecutiveOrganId', client.ClientInfo.ExecutiveOrganId.toString());
        formData.append('ClientInfo.ExternalAudit', client.ClientInfo.ExternalAudit.toString());

        if (client.ClientInfo.ExternalAuditFileName != null)
            formData.append('ClientInfo.ExternalAuditFileName', client.ClientInfo.ExternalAuditFileName);

        client.ClientInfo.FinanceTypes.forEach((element, index) => {
            formData.append('ClientInfo.FinanceTypes[' + index + '].Id', element.Id.toString());
            formData.append('ClientInfo.FinanceTypes[' + index + '].Code', element.Code.toString());
            formData.append('ClientInfo.FinanceTypes[' + index + '].Description', element.Description.toString());
        });

        formData.append('ClientInfo.InternalAudit', client.ClientInfo.InternalAudit.toString());        

        if (client.ClientInfo.InternalAuditFileName != null)
            formData.append('ClientInfo.InternalAuditFileName', client.ClientInfo.InternalAuditFileName);

        formData.append('ClientInfo.InvesteeCompanies', client.ClientInfo.InvesteeCompanies.toString());

        if (client.ClientInfo.OtherExecutiveOrgan != null)
            formData.append('ClientInfo.OtherExecutiveOrgan', client.ClientInfo.OtherExecutiveOrgan);
        
        formData.append('ClientInfo.OutSourcedCompanies', client.ClientInfo.OutSourcedCompanies.toString());
        
        client.ClientInfo.IsoCertifications.forEach((element, index) => {
            formData.append('ClientInfo.IsoCertifications[' + index + '].IsoCertificationFileName', element.IsoCertificationFileName);
            formData.append('ClientInfo.IsoCertifications[' + index + '].IsoCertificationId', element.IsoCertificationId.toString());
            formData.append('ClientInfo.IsoCertifications[' + index + '].IsoCertificationFileUri', element.IsoCertificationFileUri);
        });

        formData.append('ClientInfo.NumWorkers', client.ClientInfo.NumWorkers.toString());


        if (client.ClientInfo.AnnualAccountingFirstYearFileName != null) {
            formData.append('ClientInfo.AnnualAccountingFirstYear', client.ClientInfo.AnnualAccountingFirstYear);
            formData.append('ClientInfo.AnnualAccountingFirstYearFileName', client.ClientInfo.AnnualAccountingFirstYearFileName);
        }

        if (client.ClientInfo.AnnualAccountingSecondYearFileName != null) {
            formData.append('ClientInfo.AnnualAccountingSecondYear', client.ClientInfo.AnnualAccountingSecondYear);
            formData.append('ClientInfo.AnnualAccountingSecondYearFileName', client.ClientInfo.AnnualAccountingSecondYearFileName);
        }

        return formData;
    }
}
