import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication.service';
import { RemoteService } from '@app/services/remote.service';
import { GlobalService } from '@app/services/global.service';
import { ActiveCampaignService } from '@app/services/active-campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import localeFr from '@angular/common/locales/fr';
import { Role } from '@app/models/Role';
import { canAccess } from '@app/security/riskallay.roles';
import { FreshdeskWidgetService } from '@app/services/helpdesk/freshdesk-widget.service';
import { mapPositionToTranslationKey } from '@app/models/Position';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  loggedIn = false;
  nPending = 0;
  points = 0;
  image: string;
  navShow = true;
  URLHome = '';

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private remote: RemoteService,
    private global: GlobalService,
    public activeCampaign: ActiveCampaignService,
    private translate: TranslateService,
    public widget: FreshdeskWidgetService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    auth.account.subscribe((e) => {
      this.loggedIn = e;
      if (this.loggedIn) {
        const imageProfile = this.auth.getFieldJWT('Image');
        if (!!imageProfile) {
          this.remote
            .getTokenFileUri(imageProfile)
            .subscribe((res) => (this.image = res.url));
        }
        const locale = this.global.chageLanguage(
          this.auth.getFieldJWT('Language')
        );
        const roles = this.auth.getRoles();

        this.loadFreshDesk(locale, this.auth.getFieldJWT('Name'), this.auth.getFieldJWT('Email'), roles);

        var isTP = canAccess(Role.ThirdParty, roles);
        if (!isTP && canAccess(Role.Owner, roles)) {
          // ACTIVAR CUANDO FUNCIONE ONBOARDING
          this.remote.getRequest('Users/ClientHaveConfig').then((r) => {
            if (!r) {
              this.router.navigateByUrl('/onboarding-introduccion');
            }
          });
          this.router.navigateByUrl('/home');
        } else if(isTP) {
          if(!window.location.pathname || window.location.pathname === "/home" || window.location.pathname === "/login"){
            this.router.navigateByUrl('/pagina-id');
            this.URLHome = '/pagina-id';
          }
        } else {
          if(!window.location.pathname || window.location.pathname === "/pagina-id" || window.location.pathname === "/login"){
            this.URLHome = '/home';
          }
        }
      }
    });

    this.translate.use(locale);
  }

  logout() {
    this.widget.FreshworksWidget('hide');

    this.auth.logout();
  }

  activeSublist() {
    const element = document.getElementById('sublistThirdParty');
    element?.classList?.add('is-active');
    this.inactiveSublistPortal();
  }

  toggleSublist() {
    const element = document.getElementById('sublistThirdParty');
    if (element?.classList?.contains('is-active')) {
      this.inactiveSublist();
    } else {
      this.activeSublist();
    }
  }

  inactiveSublist() {
    const element = document.getElementById('sublistThirdParty');
    element?.classList?.remove('is-active');
  }

  toggleSublistPortal() {
    const element = document.getElementById('sublistPortal');
    if (element?.classList?.contains('is-active')) {
      this.inactiveSublist();
    } else {
      this.activeSublist();
    }
  }

  loadFreshDesk(locale: string, name: string, email: string, userRoles: string[]): void {
    const widget_id = canAccess([Role.Manager, Role.Owner, Role.Collaborator, Role.Assistant, Role.Auditor], userRoles) ? 103000002531 : 103000003338;
    this.widget.CreateWidget(locale, widget_id);
    this.widget.FreshworksWidget('identify', 'ticketForm', {
      name: `${name}`,
      email: `${email}`,
    });
    this.widget.authenticateWidget();
    this.widget.FreshworksWidget('disable', 'ticketForm', ['name', 'email']);
  }

  activeSublistPortal() {
    const element = document.getElementById('sublistPortal');
    element?.classList?.add('is-active');
    this.inactiveSublist();
  }

  inactiveSublistPortal() {
    const element = document.getElementById('sublistPortal');
    element?.classList?.remove('is-active');
  }

  exitFillRiskList() {
    this.router.navigate(['/pagina-id']);
  }

  getPosition(): string {
    const position = this.auth.getFieldJWT('Position');

    if (!position) {
      return '';
    }
    if (!isNaN(+position)) {
      return mapPositionToTranslationKey(+position);
    }
  }
}
