import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserTemporalCreateDTO, UserTemporalUpdateDTO } from '@app/models/UserTemporal';
import { CommonsService } from '../../../services/commons-service';
import { CommonsProxyClient } from 'src/proxy/apiCommons/commons.proxy';
import { forkJoin } from 'rxjs';
import { UsersService } from '@app/services/users.service';
import { RiskallayUserDto } from 'src/proxy/model/riskallayUserDto';
import { Role } from '@app/models/Role';
import { mapPositionToTranslationKey, Position, PositionValues } from '@app/models/Position';

@Component({
  selector: 'app-dialog-add-user',
  templateUrl: './dialog-add-user.component.html',
  styleUrls: ['./dialog-add-user.component.scss']
})
export class DialogAddUserComponent implements OnInit {

  form: any;
  entry: RiskallayUserDto = null;
  currentUser: RiskallayUserDto;
  roles: Array<string>;
  positions: number[];

  constructor(
    public dialogRef: MatDialogRef<DialogAddUserComponent>,
    private fb: FormBuilder,
    private commons: CommonsService,
    private commonsClient: CommonsProxyClient,
    private userClient: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: RiskallayUserDto 
  ) { }

  ngOnInit(): void {
    this.positions = PositionValues;
    forkJoin({
      roles: this.commonsClient.commonsRolesGet(),
      userData: this.userClient.getCurrentUser()
    }).subscribe(({roles, userData}) => {
      if(this.data?.Roles.includes(Role.Owner)) {
        this.roles = roles;
      } else {
        this.roles = roles.filter(r => r !== Role.Owner);
      }
      this.currentUser = userData;
      this.fillFormValues();
    });
  }

  // cerrar modal
  onNoClick(): void {
    this.dialogRef.close();
  }

  async addUser() {    
    let user = (<UserTemporalCreateDTO>{});
    user.Name=this.form.get("Name").value;
    user.SurName=this.form.get("SurName").value;
    user.Email=this.form.get("Email").value;
    user.Position=this.form.get("Position").value;
    user.Role=this.form.get("Role").value;
    
    this.commons.postUser(user)
    .then(r=>{
      this.dialogRef.close(true);
    })
    .catch(err=>{
      this.dialogRef.close(false);
    });
  }

  async updateUser() {
    let Name = this.form.get("Name").value;
    let SurName = this.form.get("SurName").value;
    let Email = this.form.get("Email").value;
    let Position = this.form.get("Position").value;
    let Role = this.form.get("Role").value;
    

    let user = (<UserTemporalUpdateDTO>{});
    user.Id = this.data.Id;
    user.Name=Name;
    user.SurName=SurName;
    user.Email=Email;
    user.Position=Position;
    user.Role=Role;
    
    this.commons.putUser(user)
    .then(r=>{      
      this.dialogRef.close(true);
    })
    .catch(err=>{
      this.dialogRef.close(false);
    });
  }

  get mailContactNotValid() {
    return this.form.get('Email').invalid && this.form.get('Email').touched;
  }

  get dataInitialized(): boolean {
    return this.roles !== undefined && this.currentUser !== undefined;
  }

  public isRoleChangeEnabled(): boolean {
    // A user can not modify its own role
    return this.currentUser?.Email === this.data?.Email && this.data?.Email !== null;
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (option && value) {
      return option.Id === value.Id;
    }
    else {
      return false;
    }
  }

  private fillFormValues() {
    let entry=this.data;

    this.form = this.fb.group({
      Name: new FormControl(entry!==null?entry.Name:'', Validators.required),
      SurName: new FormControl(entry!==null?entry.SurName:'', Validators.required),
      Email: new FormControl(entry!==null?entry.Email:'', [Validators.required,Validators.email]),
      Position: new FormControl(entry!==null?entry.Position:'', Validators.required),
      Role: new FormControl(
        {
          value: entry!==null?entry.Roles.filter((r: string) => this.roles.includes(r))[0]:'',
          disabled: this.isRoleChangeEnabled() 
        }, 
        Validators.required
      ),
    });

    if (entry!==null){
      this.form.get('Email').disable();
    }
  }

  getPositionTranslationKey(position: Position) {
    return mapPositionToTranslationKey(position);
}
}
