import { AfterContentInit, Component, Input } from "@angular/core";
import { QueryResult } from "@app/models/common/queryResult";
import { ScoringCandidate } from "@app/models/scoring-tools/scoringCandidate";

@Component({
    selector: 'app-refinitiv-result-list',
    templateUrl: './refinitiv-result-list.component.html',
    styleUrls: ['./refinitiv-result-list.component.scss']
})
export class RefinitivResultListComponent implements AfterContentInit {
    @Input() data: string;

    displayedColumns: string[] = ['matchedAlias', 'matchStrength', 'worldCheckId', 'category', 'software', 'thirdPartyRisks'];
    queryResult: QueryResult<ScoringCandidate> = { items: [], totalItems: 0 };
    scoringCandidates: ScoringCandidate[] = [];

    query = {
        PageSize: 5,
        SortBy: '',
        IsSortAscending: false,
        Page: 1,
    };

    constructor() {

    }

    ngAfterContentInit(): void {
        this.scoringCandidates = JSON.parse(this.data);
        this.getCandidates();
    }

    getCandidates() {
        this.queryResult.items = this.scoringCandidates.sort((a, b) => this.sortingFunction(a, b)).slice((this.query.Page - 1) * this.query.PageSize, this.query.Page * this.query.PageSize);
        this.queryResult.totalItems = this.scoringCandidates.length;
    }

    sortBy(columnName) {
        this.query.Page = 1;
        this.query.SortBy = columnName.active;
        this.query.IsSortAscending = columnName.direction === 'asc'
        this.getCandidates();
    }

    onPageChange(page) {
        this.query.Page = page.pageIndex + 1;
        this.query.PageSize = page.pageSize;
        this.queryResult.items = this.scoringCandidates.slice((this.query.Page - 1) * this.query.PageSize, this.query.Page * this.query.PageSize);
    }

    sortingFunction(a: ScoringCandidate, b: ScoringCandidate): number {
        if (a[this.query.SortBy] > b[this.query.SortBy]) {
            return this.query.IsSortAscending ? 1 : -1;
        }

        if (a[this.query.SortBy] < b[this.query.SortBy]) {
            return this.query.IsSortAscending ? -1 : 1;
        }

        return 0;
    }

}