import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-aml-update-config-form',
  templateUrl: './aml-update-config-form.component.html',
  styleUrls: ['./aml-update-config-form.component.scss']
})
export class AMLUpdateConfigFormComponent {

  @Input() apiToken: string;

  @Output() apiTokenChange = new EventEmitter<string>();

  constructor() { }

  onApiTokenChange() {
    this.apiTokenChange.emit(this.apiToken);
  }
}
