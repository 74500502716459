<div class="o-wrapper">
  <main id="content">
    <div class="c-head-section c-head-section--tab bg-cream">
      <div class="c-head-section--tab__top">
        <div class="c-head-section__left">
          <div class="c-head-section--tab__info">
            <h2 class="c-head-section--tab__title">
              {{ "screeningToolsHome.title" | translate }}
            </h2>
            <div class="c-head-section--tab-data">
              <div class="c-head-section--tab-data__item">
                <div>
                  {{ "screeningToolsHome.intro" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--parte inferior-->
      <div class="c-head-section--tab__bottom">
        <mat-tab-group class="c-tabs">
          <mat-tab label="{{'screeningToolsHome.screeningList'|translate}}">
            <app-screening-dashboard></app-screening-dashboard>
          </mat-tab>
          <mat-tab label="{{'screeningToolsHome.resolutionList'|translate}}">
            <app-resolutions-dashboard></app-resolutions-dashboard>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </main>
</div>
