import { Component, Input } from "@angular/core";
import { QueryResult } from "@app/models/common/queryResult";
import { EquifaxWSImmovablePropertyData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSImmovablePropertyData";
import { EquifaxWSLien } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSLien";

@Component({
  selector: 'equifax-ws-immovable-property',
  templateUrl: './immovable-property.component.html',
  styleUrls: ['./immovable-property.component.scss'],
})
export class EquifaxWSImmovablePropertyComponent {
  @Input() property: EquifaxWSImmovablePropertyData;

  displayedColumns: string[] = [
    'type',
    'registrationDate',
    'startDate',
    'expDate',
    'amount',
    'currency',
    'partialCancels',
    'creditor'
  ];

  queryResult: QueryResult<EquifaxWSLien> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() { }

  ngOnChanges(): void {
    this.getLiens();
  }

  getLiens() {
    if (this.property.liens) {
      this.queryResult.items = this.property.liens
        .slice(
          (this.query.Page - 1) * this.query.PageSize,
          this.query.Page * this.query.PageSize
        );
      this.queryResult.totalItems = this.property.liens.length;
    }
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getLiens();
  }
}
