import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommercialProxyClient } from "src/proxy/apiCommercial/commercial.proxy";

@Injectable({
    providedIn: 'root'
})
export class CommercialService {

    constructor(private commercialService: CommercialProxyClient) { }

    public sendThirdPartyIncrementRequest(increment: number, remarks: string): Observable<boolean> {
        return this.commercialService.commercialRequestIncreaseThirdPartyLimitGet(increment, remarks);
    }

    public sendQuotaIncrementRequest(quotaToIncrease: number, remarks: string) {
        return this.commercialService.commercialRequestIncreaseQuotaGet(quotaToIncrease, remarks);
    }
}
