import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { formatearFecha } from '@app/utilities/utilities'

@Component({
  selector: 'app-dialog-send-campaign',
  templateUrl: './dialog-send-campaign.component.html'
})
export class DialogSendCampaignComponent implements OnInit {

  public dateTimeStart: string;

  constructor(
    public dialogRef: MatDialogRef<DialogSendCampaignComponent>,
    @Inject(MAT_DIALOG_DATA)public data: {
      CampaignName: string,
      CountThirdParties: number,
      StartDate: Date,
      EndDate:Date,
      QuestionaryName: string
      Introduction: string,
      Amount: number
    }
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  finishCreate():void{
    this.dialogRef.close(); 
  }

  ngOnInit(): void {
    this.dateTimeStart = formatearFecha(this.data.StartDate);
  }
}
