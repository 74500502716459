import { EquifaxWSLink } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSLink";
import { EquifaxWSLinkDto } from "src/proxy/apiEquifaxWS/model/equifaxWSLinkDto";

export function mapEquifaxWSLink(dto: EquifaxWSLinkDto): EquifaxWSLink {
  if (!dto) return null;

  return <EquifaxWSLink>{
    cedula: dto.Cedula,
    creationTime: dto.CreationTime,
    id: dto.Id,
    name: dto.Name
  };
}
