<div class="main-pep">
  <p id="keyData" class="main-pep__legend u-mt-1medium">{{ "mainPEP.title" | translate }}</p>
  <div class="main-pep__group" aria-labelledby="keyData">
    <div class="main-pep-item">
      <p id="category" class="main-pep-item__title">
        {{ "mainPEP.category" | translate }}
      </p>
      <p class="main-pep-item__text" aria-labelledby="thirdName">
        {{ scoringDetails?.pepListInfo?.mainPEP?.category || "" }}
      </p>
    </div>
    <div class="main-pep-item">
      <p id="institution" class="main-pep-item__title">
        {{ "mainPEP.institution" | translate }}
      </p>
      <p class="main-pep-item__text" aria-labelledby="thirdAlias">
        {{ scoringDetails?.pepListInfo?.mainPEP?.institution || "" }}
      </p>
    </div>
    <div class="main-pep-item">
      <p id="role" class="main-pep-item__title">
        {{ "mainPEP.role" | translate }}
      </p>
      <p class="main-pep-item__text" aria-labelledby="thirdAlias">
        {{ scoringDetails?.pepListInfo?.mainPEP?.role || "" }}
      </p>
    </div>
    <div class="main-pep-item">
      <p id="startDate" class="main-pep-item__title">
        {{ "mainPEP.startDate" | translate }}
      </p>
      <p class="main-pep-item__text" aria-labelledby="thirdCategory">
        {{ scoringDetails?.pepListInfo?.mainPEP?.fromDate || ""}}
      </p>
    </div>
    <div class="main-pep-item">
      <p id="endDate" class="main-pep-item__title">
        {{ "mainPEP.endDate" | translate }}
      </p>
      <p class="main-pep-item__text" aria-labelledby="thirdCategory">
        {{ scoringDetails?.pepListInfo?.mainPEP?.toDate || "" }}
      </p>
    </div>
  </div>
</div>
