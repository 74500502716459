<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
  <header class="c-dialog__header">
    <h1 id="titleDialog" class="c-dialog__title">
      {{ "uploadFileToThirdProfile.title" | translate }}
    </h1>
    <button
      mat-button
      (click)="close()"
      class="c-dialog__close"
      attr.aria-label="{{
        'uploadFileToThirdProfile.closeModalWindow' | translate
      }}"
    >
      <span aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
          <path
            d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
            transform="translate(-309.86-406.47)"
          />
        </svg>
      </span>
    </button>
  </header>
  <div class="c-dialog__content">
    <h2 class="c-dialog__subtitle">
      {{
        ("uploadFileToThirdProfile.subtitle" | translate).replace(
          "${thirdPartyName}",
          data.CompanyName
        )
      }}
    </h2>
    <p class="o-text">
      {{ "uploadFileToThirdProfile.text" | translate }}
    </p>
    <div class="file-upload-wrapper" [formGroup]="form">
      <app-file-upload
        formControlName="fileName"
        [buttonText]="fileSelectButtonLbl"
        [qid]="null"
        (fileSelected)="fileSelected($event)"
      ></app-file-upload>
    </div>
  </div>
  <footer class="c-dialog__footer d-flex justify-content-end">
    <button
    class="c-button"
    [disabled]="file === null"
    (click)="submitFile()"
    >
      {{ "uploadFileToThirdProfile.submitDocument" | translate }}
    </button>
  </footer>
</div>
