<mat-form-field class="c-search" floatLabel="never">
    <mat-label class="c-search__label">
        <span class="c-search__button" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                    d="M19.724,18.547,14.757,13.58a8.336,8.336,0,1,0-1.177,1.177l4.968,4.968a.832.832,0,1,0,1.177-1.177ZM8.317,14.975a6.658,6.658,0,1,1,6.658-6.658,6.658,6.658,0,0,1-6.658,6.658Z"
                    transform="translate(.032.032)" />
            </svg>
        </span> {{ 'TablaCampaña.search.valor' | translate}}
    </mat-label>
    <input matInput (keyup)="onFilterChange($event)" class="c-search__input" #input />
</mat-form-field>