import { Component, OnInit, AfterViewChecked, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { RemoteService } from '@app/services/remote.service';
import { Toaster } from 'ngx-toast-notifications';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionDTO } from '@app/models/Question';
import { QuestionaryDTO, QuestionaryEvaluationDto } from '@app/models/Questionary';
import { QuestionaryBlockCreateDto } from '@app/models/QuestionaryBlock';
import { RiskDTO } from '@app/models/Risk';
import { AnswerCreateDTO } from '@app/models/Answer';
import { EvaluationQuestionaryRiskCreateDTO, EvaluationQuestionaryRiskUpdateDTO } from '@app/models/EvaluationQuestionaryRisk';
import { EvaluationQuestionaryRiskAnswerCreateDTO, EvaluationQuestionaryRiskAnswerUpdateDTO } from '@app/models/EvaluationQuestionaryRiskAnswer';
import { EvaluationQuestionaryCreateDTO } from '@app/models/EvaluationQuestionary';
import { MatTabGroup } from '@angular/material/tabs';
import { QuestionariesService } from '@app/services/questionaries-service';
import { Observable, of, Subscription } from 'rxjs';
import { AwsS3Service } from '@app/services/awsS3.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogSignChecklistComponent } from './dialog-sign-checklist/dialog-sign-checklist.component';
import { DialogConfirmExitComponent } from '../dialog-confirm-exit/dialog-confirm-exit.component';
import { sort } from '@app/utilities/utilities';

@Component({
  selector: 'app-risklist-fill',
  templateUrl: './risklist-fill.component.html',
  styleUrls: ['./risklist-fill.component.scss']
})
export class RisklistFillComponent implements OnInit, AfterViewChecked {

  isLoading: boolean = true;
  showRateCompleted: boolean = false;

  questionary: QuestionaryEvaluationDto;
  campaignId: number;
  thirdPartyId: number;
  questionaryAnswers: Array<any>;
  form: any;

  tipoBloqueDefinitionObjs = [];

  submittingAnswers = false;
  submitted:boolean=true;

  rateCompletadas = {};

  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
  currentIndex: number = 0;

  locale: string;
  private subscription: Subscription;

  errorWhenSaving = false;

  correctlySavedMessage: string;
  errorSavingMessage: string;
  errorUploadingFileMessage: string;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private remoteService: RemoteService,
    private toaster: Toaster,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private questionariesService: QuestionariesService,
    private router: Router,
    private awsS3Service: AwsS3Service
  ) { }


  ngAfterViewChecked(): void {
    this.locale = this.translate.currentLang.split("-")[0];

    this.subscription=this.translate.onLangChange
        .subscribe((langChangeEvent: LangChangeEvent) => {
            this.locale = langChangeEvent.lang.split("-")[0];
        })

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('o-risklist');
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('o-risklist', 'js-noHeader');

    this.subscription.unsubscribe();
  }

  async ngOnInit(): Promise<void> {

    this.form = this.fb.group({
      Template: new FormControl({ value: '', disabled: true }, Validators.required),
      bloques: this.fb.array([])
    });

    this.campaignId = this.activatedRoute.snapshot.queryParams.ca;
    this.thirdPartyId = this.activatedRoute.snapshot.queryParams.third;

    this.translate.get('RisklistFill.correctlySaved')
      .subscribe(translation => {
        this.correctlySavedMessage = translation;
      });

    this.translate.get('RisklistFill.errorSaving')
      .subscribe(translation => {
        this.errorSavingMessage = translation;
      });

    this.translate.get('RisklistFill.errorUploadingFile')
      .subscribe(translation => {
        this.errorUploadingFileMessage = translation;
      });

    await this.loadInitialData(this.campaignId, this.thirdPartyId);
    this.isLoading = false;

    setTimeout(() => {
      this.showRateCompleted = true;
    }, 500);

  }

  async loadInitialData(idca: number, idthird: number) {
    let promiseQuestionary = this.getQuestionaryP(idca, idthird);
    let promiseCampaign = this.getCampaignP(idca);
    let promises = [
      promiseQuestionary,       // 0
      promiseCampaign,          // 1
    ];

    await Promise.all(promises).then(values => {
      this.getQuestionary(values[0]);
      this.checkCampaignDateSurpassed(values[1]);
      this.questionaryAnswers = this.questionary.EvaluationQuestionary;
    });
    this.buildFormulario(this.questionary);
  }

  getQuestionaryP(ca, third) {
    return this.remoteService.getRequest("Questionaries/GetByThirdPartyAndCampaign/"+ (third).toString()  + '/' + (ca).toString());
  }

  getCampaignP(ca) {
    return this.remoteService.getRequest("Campaign/" + (ca).toString());
  }

  checkCampaignDateSurpassed(r) {
    if (r) {
      const campaignEndDate = new Date(r.EndDate);
      const currentDate = new Date();
      if(campaignEndDate < currentDate) {
        this.submitted = true;
      }
    }
  }

  getQuestionary(r) {
    if (r) {
      this.questionary = (<any>r);
      this.questionary.UpdateTime = new Date(this.questionary.UpdateTime);
      this.questionary.CreationTime = new Date(this.questionary.CreationTime);
      if (this.questionary.EvaluationQuestionary &&
        this.questionary.EvaluationQuestionary.length>0 &&
        this.questionary.EvaluationQuestionary[0].Submit
        )
        {
          this.submitted=true;
        } else {
          this.submitted=false;
        }
    }
  }

  get bloques() {
    return this.form.get("bloques") as FormArray;
  }

  addBloque(bloque: any) {
    this.bloques.push(this.newBloque(bloque));
  }

  newBloque(bloque: any): FormGroup {
    return this.fb.group({
      Risk: new FormControl({ value: bloque.Risk, disabled: true }, Validators.required),
      Id: new FormControl({ value: bloque.Id, disabled: true }, Validators.required),
      Questions: this.fb.array([])
    })
  }

  getBlockName(bloque) {
    return bloque.get('Risk').value.Description;
  }

  bloqueQuestions(bloqueIndex: number): FormArray {
    return this.bloques.at(bloqueIndex).get("Questions") as FormArray
  }

  findAnsweredQuestion(bloqueId: number, preguntaId: number, respuesta: EvaluationQuestionaryCreateDTO) {
    let res: any = null;
    if (respuesta !== null) {
      for (let indexB = 0; indexB < respuesta.EvaluationQuestionaryRisk.length; indexB++) {
        const elementB = respuesta.EvaluationQuestionaryRisk[indexB];

        // Busco coincidencia entre bloque cuestionario y bloque respuesta
        let idBloqueCuestionario = this.questionary.QuestionaryBlocks[bloqueId].Risk.Id;
        if (elementB.RiskId == idBloqueCuestionario) {

          // Busco coincidencia pregunta cuestionario y pregunta respuesta
          for (let indexQ = 0; indexQ < elementB.EvaluationQuestionaryRiskAnswer.length; indexQ++) {
            const elementQ = elementB.EvaluationQuestionaryRiskAnswer[indexQ];
            if (elementQ.QuestionId == preguntaId) {
              res = elementQ;
              break;
            }
          }
          if (res != null)
            break;
        }
      }
    }
    return res;
  }

  requiredDocument(f: FormGroup) {
    return ((f.get('Answer').value.toString() == 'true'|| f.get('SelectedOption').value || f.get('IsAlternativeScoring').value === 1) && f.get('Q').value.MustAddDoc && !f.get('Doc').value) ? {'requiredDocument': true} : null;
  }

  requiredComments(f: FormGroup) {
    return ((f.get('Answer').value || f.get('SelectedOption').value) && f.get('Q').value.MustAddComments && (f.get('Comment').value == null || f.get('Comment').value == "")) ? {'requiredComment': true} : null;
  }

  newQuestion2(bloqueIndex: number, question: QuestionDTO, answeredQuestion: EvaluationQuestionaryCreateDTO): FormGroup {

    let f = this.fb.group({
      Q: new FormControl(question, Validators.required),
      Description: new FormControl({ value: question.Name, disabled: true }, Validators.required),
      Id: new FormControl({ value: question.Id, disabled: true }, Validators.required),
      WeightYes: new FormControl({ value: question.WeightYes, disabled: true }, Validators.required),
      WeightNo: new FormControl({ value: question.WeightNo, disabled: true }, Validators.required),
      Answer: new FormControl('', !question.IsMultipleChoice ? Validators.required: null),
      SelectedOption: new FormControl({ value: question.SelectedOption, disabled: true}, question.IsMultipleChoice ? Validators.required: null),
      IsMultipleChoice: new FormControl({ value: question.IsMultipleChoice, disabled: true}),
      Options: new FormControl({ value: question.Options, disabled: true}),
      IsAlternativeScoring: new FormControl({value: question.IsAlternativeScorinig, disabled: true})
    })

    let comment = null;
    let fileuri = null;
    let filepath = null;
    let validators: ValidatorFn[] = []

    let valorRespuestas = this.findAnsweredQuestion(bloqueIndex, question.Id, answeredQuestion)

    if (question.CanAddComments) {
      if (question.MustAddComments) {
        validators.push(this.requiredComments);
        f.addControl('Comment', new FormControl(comment, []))
      } else {
        f.addControl('Comment', new FormControl(comment, []))
      }
    }

    let doc = null;
    if (fileuri !== null) {
      doc = { 'FileUri': fileuri, 'FileName:': filepath }
    }
    if (question.CanAddDoc) {
      if (question.MustAddDoc) {
        f.addControl('Doc', new FormControl(doc, []));
        validators.push(this.requiredDocument);
      } else {
        f.addControl('Doc', new FormControl(doc, []))
      }
    }
    f.setValidators(validators);
    // Data from answered question
    if (valorRespuestas != null) {
      f.get('Answer').setValue(valorRespuestas.Value.toString());
      f.get('SelectedOption').setValue(valorRespuestas.SelectedOption);
      if (valorRespuestas.Comment !== null) {
        f.get("Comment").setValue(valorRespuestas.Comment);
      }

      if (valorRespuestas.FileName !== null) {
        f.get("Doc").setValue({ 'FileName': valorRespuestas.FileName, 'FileUri': valorRespuestas.FileUri, 'File': {} });
      }
    }

    return f;
  }

  addQuestion2(bloqueIndex: number, question: QuestionDTO, answeredQuestion: EvaluationQuestionaryCreateDTO) {
    this.bloqueQuestions(bloqueIndex).push(this.newQuestion2(bloqueIndex, question, answeredQuestion));
  }

  getButtonText(b, q) {
    let qs = this.bloqueQuestions(b);
    if (qs == null || qs == undefined || qs.at(q) == null || qs.at(q) == undefined)
      return '';

    let Q = qs.at(q).get('Q');
    if (Q == null || Q == undefined)
      return '';

    return Q.value['DocTitle'];
  }

  buildFormulario(data: QuestionaryDTO) {

    this.form = this.fb.group({
      Template: new FormControl({ value: data.Code, disabled: true }, Validators.required),
      Client: new FormControl(data.Client),
      bloques: this.fb.array([])
    });

    for (let indexB = 0; indexB < data.QuestionaryBlocks.length; indexB++) {
      const bloque = data.QuestionaryBlocks[indexB];
      this.addBloque(bloque);

      const preguntas = (<QuestionDTO[]>bloque.Questions).sort((a,b) => sort(a, b, 'Order', true));

      for (let indexQ = 0; indexQ < preguntas.length; indexQ++) {
        const pregunta = preguntas[indexQ];

        //Miramos si esta respuesta tiene ya respuesta para sobre escribirla
        let nuevaRespuesta = (this.questionaryAnswers.length == 0);
        let respuestaAnterior: EvaluationQuestionaryCreateDTO = null;
        if (!nuevaRespuesta) {
          respuestaAnterior = this.questionaryAnswers[0];
        }

        this.addQuestion2(indexB, pregunta, respuestaAnterior);
      }
    }

    if((this.questionary && this.questionaryAnswers && this.questionaryAnswers.length > 0 && this.questionaryAnswers[0].Submit) || this.submitted)
    {
      this.form.disable();
    }
  }

  getvalidQuestionsRate(bloque: FormGroup) {
    let total = (<FormArray>bloque.get('Questions')).length.toString();
    let totalOk = 0;
    for (let indexQ = 0; indexQ < (<FormArray>bloque.get("Questions")).length; indexQ++) {
      var qa = (<FormArray>bloque.get("Questions")).at(indexQ);
      if (qa.valid) {
        totalOk++;
      }
    }
    return totalOk.toString() + "/" + total;
  }

  getvalidQuestionsRateNum(bloque: FormGroup) {
    let total = (<FormArray>bloque.get('Questions')).length;
    let totalOk = 0;
    for (let indexQ = 0; indexQ < (<FormArray>bloque.get("Questions")).length; indexQ++) {
      var qa = (<FormArray>bloque.get("Questions")).at(indexQ);
      if (qa.valid) {
        totalOk++;
      }
    }

    return (totalOk / total * 100);
  }

  hasComment(questionForm) {
    let x = questionForm.get('Comment');
    if (x === null || x === undefined)
      return false;
    else
      return true;
  }

  hasDoc(questionForm) {
    let x = questionForm.get('Doc');
    if (x === null || x === undefined)
      return false;
    else
      return true;
  }

  continue() {
    this.signDocument();
  }

  signDocument() {
    this.router.navigate(['/firma-risklist/'], { queryParams: { ca: this.campaignId, third: this.thirdPartyId } });
  }

  async generateOutput() {
    try{
      this.submittingAnswers = true;
      this.errorWhenSaving = false;
      let nuevaRespuesta = (this.questionaryAnswers.length == 0);
      let evaluation = <EvaluationQuestionaryCreateDTO>{};

      evaluation.ClientId = this.form.get('Client').value ? this.form.get('Client').value.Id : null;
      evaluation.Submit = false;
      evaluation.ThirdPartyId = null;
      evaluation.CampaignId = null;

      evaluation.EvaluationQuestionaryRisk = (<EvaluationQuestionaryRiskCreateDTO[]>[]);
      for (let indexB = 0; indexB < this.bloques.length; indexB++) {

        const elementBloque = (<FormArray>this.form.get("bloques")).at(indexB);

        let riesgo = <RiskDTO>elementBloque.get('Risk').value;
        riesgo.Client = this.form.get('Client').value;

        let newRisk: any;
        if (nuevaRespuesta) {
          newRisk = (<EvaluationQuestionaryRiskCreateDTO>{});
        } else {
          newRisk = (<EvaluationQuestionaryRiskUpdateDTO>{});
        }

        newRisk.RiskId = riesgo.Id;
        newRisk.EvaluationQuestionaryRiskAnswer = (<EvaluationQuestionaryRiskAnswerCreateDTO[]>[]);

        // Insertar respuestas correctas
        for (let indexQ = 0; indexQ < (<FormArray>elementBloque.get("Questions")).length; indexQ++) {

          var qa = (<FormArray>elementBloque.get("Questions")).at(indexQ);

          if (qa.valid) {
            let riskAnswer: any;
            if (nuevaRespuesta) {
              riskAnswer = (<EvaluationQuestionaryRiskAnswerCreateDTO>{});
            } else {
              riskAnswer = (<EvaluationQuestionaryRiskAnswerUpdateDTO>{});
            }

            riskAnswer.QuestionId = qa.get('Q').value.Id;

            riskAnswer.Value = (qa.get('Answer').value == "true");
            riskAnswer.SelectedOption = qa.get('SelectedOption').value || null;

            if (qa.get('Comment') !== undefined && qa.get('Comment') !== null && qa.get('Comment').value !== null) {
              riskAnswer.Comment = qa.get('Comment').value;
            } else {
              riskAnswer.Comment = null;
            }

            if (qa.get('Doc') !== undefined && qa.get('Doc') !== null && qa.get('Doc').value !== null) {
              riskAnswer.FileName = qa.get('Doc').value.FileName;
              riskAnswer.FileUri = qa.get('Doc').value.FileUri;
              riskAnswer.File = qa.get('Doc').value.File;
            } else {
              riskAnswer.FileName = null;
              riskAnswer.FileUri = null;
              riskAnswer.File = null;
            }

            newRisk.EvaluationQuestionaryRiskAnswer.push(riskAnswer);

          }
        }

        //Insertamos el bloque de riesgo con sus preguntas
        evaluation.EvaluationQuestionaryRisk.push(newRisk);
      }

      await this.saveRiskQuestionAnswerFiles(evaluation, 0).then(res => this.saveQuestion(res));

      this.form.markAsPristine();
      this.submittingAnswers = false;

      if(this.form.valid && !this.errorWhenSaving) {
          const dialogRef = this.dialog.open(DialogSignChecklistComponent);
          dialogRef
              .afterClosed()
              .subscribe(signDocumentSelected => {
                  if(signDocumentSelected) {
                      this.signDocument();
                  }
              });
      }
    } finally{
      this.submittingAnswers = false;
    }
  }

  private async saveRiskQuestionAnswerFiles(evaluation: EvaluationQuestionaryCreateDTO, index: number) {
    if(index < evaluation.EvaluationQuestionaryRisk.length){
      return await this.saveQuestionAnswerFiles(evaluation, index, 0).then(() => this.saveRiskQuestionAnswerFiles(evaluation,index + 1))
    } else {
      return await new Promise((resolve) => {
        resolve(evaluation);
    });
    }
  }

  private async saveQuestionAnswerFiles(evaluation: EvaluationQuestionaryCreateDTO, evaluationQuestionaryRiskIndex: number, index: number) {
    if(index < evaluation.EvaluationQuestionaryRisk[evaluationQuestionaryRiskIndex].EvaluationQuestionaryRiskAnswer.length){
      const riskAnswer = evaluation.EvaluationQuestionaryRisk[evaluationQuestionaryRiskIndex].EvaluationQuestionaryRiskAnswer[index];
      const file = riskAnswer.File;
      riskAnswer.File = null;
      if(file && file.size > 0){
        return await this.questionariesService.getFileStorageKey(riskAnswer.FileName,this.campaignId, this.thirdPartyId).then(async (res) => {
          riskAnswer.FileUri = res.FileId;
          try {
            await this.awsS3Service.uploadFileToS3(res.FileUri, file).toPromise().then(_ => {
              const questionId = riskAnswer.QuestionId;
              this.resetLocalFile(questionId, riskAnswer.FileName, res.FileId);
            });
          } catch(_) {
            this.errorWhenSaving = true;
            this.toaster.open({ text: this.errorUploadingFileMessage.replace('${fileName}', riskAnswer.FileName), duration: 6000, type: 'danger' });
            console.error(`Error uploading file ${file.FileName}`);
            this.resetLocalFile(riskAnswer.QuestionId, undefined, undefined);
          }
        }).then(()=> this.saveQuestionAnswerFiles(evaluation, evaluationQuestionaryRiskIndex,index + 1));
      } else {
        return await this.saveQuestionAnswerFiles(evaluation, evaluationQuestionaryRiskIndex,index + 1)
      }
    } else {
      return await new Promise((resolve) => {
        resolve(evaluation);
    });
    }
  }

  private resetLocalFile(questionId: number, fileName: string, fileUri: string) {
    const blockElements = (<FormArray>this.form.get("bloques"));
    for(let blockElement of blockElements.controls) {
      for (let indexQ = 0; indexQ < (<FormArray>blockElement.get("Questions")).length; indexQ++) {
        var qa = (<FormArray>blockElement.get("Questions")).at(indexQ);
        if(qa.get('Q').value.Id === questionId) {
          if (fileName) {
            qa.get("Doc").setValue({ 'FileName': fileName, 'FileUri': fileUri, 'File': {} });
          } else {
            qa.get("Doc").setValue(null);
          }
          return;
        }
      }
    }
  }

  private async saveQuestion(evaluation: EvaluationQuestionaryCreateDTO) {
    await this.questionariesService.saveQuestionayAnswer(this.campaignId, this.thirdPartyId, evaluation)
    .then(response => {
      if (!this.errorWhenSaving) {
        this.toaster.open({ text: this.correctlySavedMessage, duration: 6000, type: 'info' });
      }
    })
    .catch(errores => {
      console.error('Received error while saving questionary answer:');
      console.error(errores);
      this.errorWhenSaving = true;
     });

    if (this.errorWhenSaving) {
      this.toaster.open({ text: this.errorSavingMessage, duration: 6000, type: 'danger' });
    }


  }

  inspect() {

    let answers: AnswerCreateDTO[] = [];
    for (let indexB = 0; indexB < this.bloques.length; indexB++) {
      //Obtenemos el bloque del form
      const elementB = (<FormArray>this.form.get("bloques")).at(indexB);
      let bloque = (<QuestionaryBlockCreateDto>{});
      bloque.Risk = elementB.get('Risk').value;
      for (let indexQ = 0; indexQ < (<FormArray>elementB.get("Questions")).length; indexQ++) {

        let answer = (<AnswerCreateDTO>{});

        var qa = (<FormArray>elementB.get("Questions")).at(indexQ);
        let pid = qa.get('Id').value;
        let pdesc = qa.get('Description').value;
        let pansw = qa.get('Answer').value;

        //Construimos DTO salida
        answer.Question = this.getQuestionObj(indexB, indexQ, pid);
        if (answer.Question === null) {
          return;
        }
        answer.Value = (pansw == "true");

        answer.SelectedOption = qa.get('SelectedOption').value;

        if (qa.get('Comment') !== undefined && qa.get('Comment') !== null) {
          answer.Comment = qa.get('Comment').value
        } else {
          answer.Comment = null;
        }

        if (qa.get('Doc') !== undefined && qa.get('Doc') !== null && qa.get('Doc').value !== null) {
          answer.FileName = qa.get('Doc').value.FileName;
          answer.FileUri = qa.get('Doc').value.FileUri;
          answer.File = qa.get('Doc').value.File;
        } else {
          answer.FileName = null;
          answer.FileUri = null;
          answer.File = null;
        }

        answers.push(answer);
      }

    }

    return answers;
  }


  getQuestionObj(indexB, indexQ, pid) {
    const pregunta = this.questionary.QuestionaryBlocks[indexB].Questions[indexQ];
    if (pregunta.Id == pid) {
      return pregunta;
    } else {
      return null;
    }
  }


  selectDocument(resp) {
  }


  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.form.value);
  }


  nextTab() {
    this.currentIndex = (this.currentIndex + 1) % this.bloques.length
    this.tabGroup.selectedIndex = this.currentIndex
  }

  previousTab() {
    this.currentIndex = (this.currentIndex -1)
    if (this.currentIndex < 0) {
      this.currentIndex = this.bloques.length - 1
    }
    this.tabGroup.selectedIndex = this.currentIndex
  }

  public download(idfile: string, fileName :string){
    this.remoteService.downloadMyFile(idfile, fileName);
  }

  confirmExitingIfDirty(): Observable<boolean> {
    if(this.form.dirty) {
      const dialogRef = this.dialog.open(DialogConfirmExitComponent);
      return dialogRef.afterClosed();
    } else {
      return of(true);
    }
  }
}
