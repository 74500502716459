<div *ngIf="!loading" class="o-scroll o-scroll--small consumption-usage">
  <h1 class="o-title">{{'ConsumptionUsage.title' | translate}}</h1>
  <div class="row consumption-card-group">
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.refinitivUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/cloud-download.svg" alt="Icono consumo API Refinitiv" />
        <div class="consumption-data">
          <p class="data refinitiv-data">{{ refinitivUsage.total - refinitivUsage.remaining }} / {{ refinitivUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.refinitivUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.thirdPartiesUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/users.svg" alt="Icono terceros cargados" />
        <div class="consumption-data">
          <p class="data third-parties-data">{{ thirdPartyUsage.total - thirdPartyUsage.remaining }} / {{ thirdPartyUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.thirdPartiesUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.assessmentsLaunchedTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/assesments.svg" alt="Icono evaluaciones lanzadas" />
        <div class="consumption-data">
          <p class="data assessments-data">{{ assessmentsLaunched }}</p>
          <p class="data-label">{{'ConsumptionUsage.assessmentsLaunchedLabel' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row consumption-card-group">
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.amlUpdateUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/cloud-download.svg" alt="Icono consumo API AML Update - Gesintel" />
        <div class="consumption-data">
          <p class="data refinitiv-data">{{ amlUpdateUsage.total - amlUpdateUsage.remaining }} / {{ amlUpdateUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.refinitivUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.equifaxUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/cloud-download.svg" alt="Icono consumo API Equifax" />
        <div class="consumption-data">
          <p class="data refinitiv-data">{{ equifaxUsage.total - equifaxUsage.remaining }} / {{ equifaxUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.refinitivUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.memberCheckUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/cloud-download.svg" alt="Icono consumo API MemberCheck" />
        <div class="consumption-data">
          <p class="data refinitiv-data">{{ memberCheckUsage.total - memberCheckUsage.remaining }} / {{ memberCheckUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.refinitivUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <h1 class="o-title-medium">{{'ConsumptionUsage.usageLimitsTitle' | translate}}</h1>
  <p>{{'ConsumptionUsage.usageLimitsLabel' | translate}}</p>
  <button class="c-button c-button-filled" (click)="openExpandQuotasDialog()">{{'ConsumptionUsage.usageLimitsButton' | translate}}</button>
</div>
