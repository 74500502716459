import { Component, Input } from '@angular/core';
import { CampaignStatus } from '@app/models/CampaignStatus';

@Component({
    selector: 'app-risklist-action',
    templateUrl: './risklist-action.component.html',
    styleUrls: ['./risklist-action.component.scss']
})
export class RisklistActionComponent {
    readonly CampaignStatus = CampaignStatus;

    @Input() element: any;

    editable() {
      const startDate = new Date(this.element.startDate);
      const endDate = new Date(this.element.endDate);
      const currentDate = new Date();
      return startDate <= currentDate && currentDate <= endDate
        && (this.element.campaignStatus === CampaignStatus.pendingSign
            || this.element.campaignStatus === CampaignStatus.inProgress
            || this.element.campaignStatus === CampaignStatus.pending);
    }
}
