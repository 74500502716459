import { Component } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styles: [
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: SlideToggleComponent
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: SlideToggleComponent
  }]
})
export class SlideToggleComponent implements ControlValueAccessor {

  value: number = 1;

  constructor() { }

  onChange = (value) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;

  writeValue(value: number) {
    this.value = value;
    
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const respuesta = control.value;
    if (respuesta == 1) {
      return {
        cannotBenull: {
          respuesta
        }
      };
    }
  }

  cambiarRespuesta(valor){
    this.value=valor;
    this.onChange(valor);
  }
}
