import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Country } from '@app/models/Country';
import { IndividualFilter } from '@app/models/scoring-tools/individual-filter';
import { OrganizationFilter } from '@app/models/scoring-tools/organization-filter';
import { ScoringCandidate } from '@app/models/scoring-tools/scoringCandidate';
import { CountriesService } from '@app/services/countries.service';
import { ScoringToolsService } from '@app/services/scoring-tools.service';
import { DialogSearchLimitReachedComponent } from '@app/modules/screening-hub/components/dialog-search-limit-reached/dialog-search-limit-reached.component';
import { ScreeningProviderService } from '@app/modules/screening-hub/services/screening-provider.service';
import { ScreeningProvider, mapProviderName } from '@app/modules/screening-hub/models/ScreeningProvider';
import { GesintelIndividualFilter } from '../../models/GesintelScreening/GesintelIndividualFilter';
import { GesintelOrganizationFilter } from '../../models/GesintelScreening/GesintelOrganizationFilter';
import { GesintelBothFilter } from '../../models/GesintelScreening/GesintelBothFilter';
import { AmlUpdateScoringService } from '../../services/aml-update-scoring.service';
import { EquifaxWSScoringService } from '../../services/equifax-ws-scoring.service';
import { EquifaxWSFilter } from '../../models/EquifaxWS/EquifaxWSFilter';
import { MemberCheckScoringService } from '../../services/member-check-scoring.service';
import { CorpFilter } from '../../models/member-check/corp-filter';
import { MemberFilter } from '../../models/member-check/member-filter';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-scoring-candidates',
  templateUrl: './scoring-candidates.component.html',
  styleUrls: ['./scoring-candidates.component.scss'],
})
export class ThirdPartyScoringCandidatesComponent implements OnInit {
  thirdPartyId?: number;
  caseId: string;
  searchType: string;
  searchTypes: string[];
  candidates: ScoringCandidate[];
  countries: Country[];

  screeningProviders: ScreeningProvider[];
  ScreeningProvider = ScreeningProvider;
  selectedProvider: ScreeningProvider;

  providerNameTranslations: { [key:string]: string };

  isLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private scoringToolsService: ScoringToolsService,
    private router: Router,
    private dialog: MatDialog,
    private countriesService: CountriesService,
    private screeningProviderService: ScreeningProviderService,
    private amlUpdateScoringService: AmlUpdateScoringService,
    private equifaxWSScoringService: EquifaxWSScoringService,
    private memberCheckScoringService: MemberCheckScoringService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      (this.thirdPartyId = params.id), (this.caseId = params.caseId);
    });

    this.countriesService.getCountries().subscribe((res) => {
      this.countries = res;
    });

    this.screeningProviders = this.screeningProviderService.clientProviders();

    this.translate.get('providerNames')
      .subscribe(translation => {
        this.providerNameTranslations = translation;
      });
  }

  search(
    filter: IndividualFilter
      | OrganizationFilter
      | GesintelIndividualFilter
      | GesintelOrganizationFilter
      | GesintelBothFilter
      | EquifaxWSFilter
      | MemberFilter
      | CorpFilter
  ) {
    this.isLoading = true;
    switch (this.selectedProvider) {
      case ScreeningProvider.Refinitiv:
        this.refinitivSearch(<IndividualFilter | OrganizationFilter>filter);
        break;
      case ScreeningProvider.AMLUpdate:
        this.gesintelSearch(<GesintelIndividualFilter | GesintelOrganizationFilter | GesintelBothFilter>filter);
        break;
      case ScreeningProvider.EquifaxWS:
        this.equifaxWSSearch(<EquifaxWSFilter>filter);
        break;
      case ScreeningProvider.MemberCheck:
        this.memberCheckSearch(<MemberFilter | CorpFilter>filter);
        break;
    }
  }

  private refinitivSearch(filter: IndividualFilter | OrganizationFilter) {
    if (filter instanceof IndividualFilter) {
      this.scoringToolsService.createIndividualCase(filter).subscribe((res) => {
        if (res === 'REACHED_QUOTA') {
          this.openLimitReachedDialog();
          this.isLoading = false;
        } else {
          if (this.thirdPartyId) {
            this.router.navigate(['scoring-candidates-result', res, this.thirdPartyId], { queryParams: { name: filter.name, searchType: 1 } });
          } else {
            this.router.navigate(['scoring-candidates-result', res], { queryParams: { name: filter.name, searchType: 1 } });
          }
        }
      });
    } else {
      this.scoringToolsService
        .createOrganizationCase(filter)
        .subscribe((res) => {
          if (res === 'REACHED_QUOTA') {
            this.openLimitReachedDialog();
            this.isLoading = false;
          } else {
            if (this.thirdPartyId) {
              this.router.navigate(['scoring-candidates-result', res, this.thirdPartyId], { queryParams: { name: filter.name, searchType: 2 } });
            } else {
              this.router.navigate(['scoring-candidates-result', res], { queryParams: { name: filter.name, searchType: 2 } });
            }
          }
        });
    }
  }

  private gesintelSearch(
    filter:
      | GesintelIndividualFilter
      | GesintelOrganizationFilter
      | GesintelBothFilter
  ) {
    const searchType =
      this.searchType === 'individual'
        ? 1
        : this.searchType === 'organization'
          ? 2
          : 3;

    this.amlUpdateScoringService
      .search(searchType, filter.name)
      .subscribe((searchResponse) => {
        if (searchResponse.response === 'REACHED_QUOTA') {
          this.openLimitReachedDialog();
          this.isLoading = false;
          return;
        }

        if (this.thirdPartyId) {
          this.router.navigate(
            ['scoring-candidates-result-gesintel', this.thirdPartyId],
            {
              state: { candidates: searchResponse.results },
              queryParams: { name: filter.name, searchType: searchType }
            }
          );
        } else {
          this.router.navigate(['scoring-candidates-result-gesintel'],
            {
              state: { candidates: searchResponse.results },
              queryParams: { name: filter.name, searchType: searchType }
            });
        }
      });
  }

  private equifaxWSSearch(filter: EquifaxWSFilter) {

    const searchType = this.searchType === 'individual' ? 1 : 2;

    this.equifaxWSScoringService
      .search(searchType, filter.cedula)
      .subscribe((searchResponse) => {
        if (searchResponse === 'REACHED_QUOTA') {
          this.openLimitReachedDialog();
          this.isLoading = false;
          return;
        }

        if (this.thirdPartyId) {
          this.router.navigate(
            ['equifaxws/scoring-candidates-results', this.thirdPartyId],
            {
              state: { result: searchResponse },
              queryParams: { cedula: filter.cedula, searchType: searchType }
            }
          );
        } else {
          this.router.navigate(
            ['equifaxws/scoring-candidates-results'],
            {
              state: { result: searchResponse },
              queryParams: { cedula: filter.cedula, searchType: searchType }
            }
          );
        }
      });
  }

  private memberCheckSearch(filter: MemberFilter | CorpFilter) {

    if (filter instanceof MemberFilter) {
      this.memberCheckScoringService.searchMember(filter).subscribe(([message, scanId]) => {
        if (!message && !scanId) {
          this.isLoading = false;
        } else if (message === 'REACHED_QUOTA') {
          this.openLimitReachedDialog();
          this.isLoading = false;
        } else {
          if (this.thirdPartyId) {
            this.router.navigate(['member-check/candidates', scanId, this.thirdPartyId], { queryParams: { name: `${filter.firstName} ${filter.lastname}`, searchType: 1 } });
          } else {
            this.router.navigate(['member-check/candidates', scanId], { queryParams: { name: `${filter.firstName} ${filter.lastname}`, searchType: 1 } });
          }
        }
      });
    } else {
      this.memberCheckScoringService.searchCorp(filter.name, filter.regNumber, filter.address).subscribe(([message, scanId]) => {
          if (!message && !scanId) {
            this.isLoading = false;
          } else if (message === 'REACHED_QUOTA') {
            this.openLimitReachedDialog();
            this.isLoading = false;
          } else {
            if (this.thirdPartyId) {
              this.router.navigate(['member-check/candidates', scanId, this.thirdPartyId], { queryParams: { name: filter.name, searchType: 2 } });
            } else {
              this.router.navigate(['member-check/candidates', scanId], { queryParams: { name: filter.name, searchType: 2 } });
            }
          }
        });
    }
  }

  openLimitReachedDialog() {
    const dialogRef = this.dialog.open(DialogSearchLimitReachedComponent);
    dialogRef.afterClosed().subscribe((r) => { });
  }

  back() {
    if (this.thirdPartyId) {
      this.router.navigate(['perfil-tercero', this.thirdPartyId, 3]);
    } else {
      this.router.navigate(['screening-tools']);
    }
  }

  getProviderName(provider: ScreeningProvider) {
    return mapProviderName(provider, this.providerNameTranslations);
  }

  providerChanged() {
    switch (this.selectedProvider) {
      case ScreeningProvider.MemberCheck:
      case ScreeningProvider.Refinitiv:
      case ScreeningProvider.EquifaxWS:
        this.searchTypes = ['organization', 'individual'];
        break;
      case ScreeningProvider.AMLUpdate:
        this.searchTypes = ['both', 'individual', 'organization'];
        break;
    }
    this.searchType = this.searchTypes[0];
  }
}
