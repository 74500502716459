import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AMLUpdateScoringProxyClient } from 'src/proxy/apiAMLUpdateScoring/amlUpdateScoring.proxy';
import { AMLUpdateSearchResponse } from '../models/AMLUpdateSearchReponse';
import { mapAMLUpdateSearchResponse } from './mappings/aml-update-search-response.mapping';
import { AMLUpdateRecord } from '../models/GesintelScreening/AMLUpdateRecord';
import { mapAMLUpdateRecord } from './mappings/aml-update-record.mapping';
import { mapAMLUpdateLink } from './mappings/aml-update-link.mapping';
import { mapAMLUpdateScreening } from './mappings/aml-update-screening.mapping';
import { Screening } from '@app/models/scoring-tools/screening';
import { AMLUpdateLink } from '../models/AMLUpdateLink';

@Injectable({
  providedIn: 'root',
})
export class AmlUpdateScoringService {

  constructor(private amlUpdateScoringClient: AMLUpdateScoringProxyClient) { }

  public search(
    searchType: number,
    name?: string,
    country?: string,
    residenceCountry?: string,
    date?: Date
  ): Observable<AMLUpdateSearchResponse> {
    return this.amlUpdateScoringClient
      .aMLUpdateScoringSearchGet(
        name,
        country,
        residenceCountry,
        date,
        searchType
      )
      .pipe(map((response) => mapAMLUpdateSearchResponse(response)));
  }

  public candidateDetails(rut: string): Observable<AMLUpdateRecord> {
    return this.amlUpdateScoringClient
      .aMLUpdateScoringCandidateDetailsRutGet(rut)
      .pipe(map((record) => mapAMLUpdateRecord(record)));
  }

  public getScoringLinks(thirdPartyId?: number): Observable<AMLUpdateLink[]> {
    return this.amlUpdateScoringClient.aMLUpdateScoringLinksGet(thirdPartyId).pipe(map((r) => r.map(record => mapAMLUpdateLink(record))));
  }

  public deleteLink(id: number): Observable<boolean> {
    return this.amlUpdateScoringClient.aMLUpdateScoringRemoveLinkLinkIdPost(id);
  }

  public getScreenings(id: number): Observable<Screening[]> {
    return this.amlUpdateScoringClient.aMLUpdateScoringScreeningsLinkIdGet(id).pipe(map(r => r.map(record => mapAMLUpdateScreening(record))));
  }

  public saveScreening(rut: string, name: string, thirdPartyId?: number): Observable<boolean> {
    return this.amlUpdateScoringClient.aMLUpdateScoringCreateLinkPostForm(rut, name, thirdPartyId);
  }

  public screeningDetails(id: number): Observable<AMLUpdateRecord> {
    return this.amlUpdateScoringClient.aMLUpdateScoringScreeningDetailsScreenIdGet(id).pipe(map(r => mapAMLUpdateRecord(r)));
  }

  public refresh(rut: string, thirdPartyId?: number): Observable<string> {
    return this.amlUpdateScoringClient.aMLUpdateScoringRefreshScreeinigRutPost(rut, thirdPartyId)
  }
}
