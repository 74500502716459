import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Campaign } from '@app/models/Campaign';
import { CampaignService } from '@app/services/campaign-service';
import { RemoteService } from '@app/services/remote.service';
import { ActivatedRoute } from '@angular/router';
import { Table3PPConfiguration } from '@app/utilities/utilities';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActiveCampaignService } from "@app/services/active-campaign.service";
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-sent-campaign',
  templateUrl: './sent-campaign.component.html',
  styleUrls: ['./sent-campaign.component.scss']
})
export class SentCampaignComponent implements OnInit, OnDestroy {

  locale: string;
  private subscription: Subscription;
  model: Campaign;

  TercerosCampaignConfig: Table3PPConfiguration = {
    'columnas':[  'id', 'name', 'type', 'headquarters', 'sector', 'responsible' , 'action']
    ,'acciones':['riskboard','cuestionario']
    ,'tipo':null
    ,filterByTypes:false
    ,'tableTarget':'SendCampaign'
    ,'campaign':-1
  }

  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDateEnd: Date;
  public maxDateEnd: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public color: ThemePalette = 'primary';
  public disableMinute = false;
  public hideTime = false;
  enableAutomatizationDeny: boolean = false;
  enableAutomatizationApprove: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private renderer: Renderer2,
              private campaignService: CampaignService,
              private translate: TranslateService,
              private activeCampaign: ActiveCampaignService,
              private remoteService: RemoteService
  ) {
    this.renderer.addClass(document.body, 'campaign-step-final');
    this.renderer.removeClass(document.body, 'campaign-step-one');
    this.renderer.removeClass(document.body, 'campaign-step-second');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.getData(params.id);
      this.TercerosCampaignConfig.campaign=params.id;
    });

    this.locale = this.translate.currentLang.split("-")[0];
        this.subscription=this.translate.onLangChange
            .subscribe((langChangeEvent: LangChangeEvent) => {
                this.locale = langChangeEvent.lang.split("-")[0];
            })
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  backToCampaigns(){
    this.activeCampaign.closeCampaignToCampaigns();
  }

  public getData(id: number){
    this.campaignService.getCampaign(id).subscribe(response => {
      this.enableAutomatizationDeny = !!response.discardThreshold;
      this.enableAutomatizationApprove = !!response.approveThreshold;
      this.model = response;
    });
  }

  public download(idfile: string){
    this.remoteService.downloadMyFile(idfile, this.model.fileName);
  }
}
