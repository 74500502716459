import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomField } from '@app/models/CustomField';
import { CustomFieldOption } from '@app/models/CustomFieldOption';
import { CustomFieldType } from '@app/models/CustomFieldType';
import { CustomFieldTypesService } from '@app/services/custom-fields/custom-field-types.service';

@Component({
  selector: 'app-custom-field-form',
  templateUrl: './custom-field-form.component.html',
  styleUrls: ['./custom-field-form.component.scss']
})
export class CustomFieldFormComponent implements OnInit {
  @Output() changeValidity = new EventEmitter<boolean>();
  @Output() changeCustomField = new EventEmitter<CustomField>();

  @Input() customField?: CustomField;

  customFieldTypes: CustomFieldType[];
  maxOptionsId = 0;
  isOptionDeletable: boolean;

  form: FormGroup;

  get hasOptions(): boolean {
    return this.customFieldType?.hasOptions || false;
  }

  get customFieldType(): CustomFieldType {
    return this.form.controls['type']?.value;
  }

  get options(): FormArray {
    return this.form.get('options') as FormArray;
  }

  constructor(private fb: FormBuilder, private customFieldTypesService: CustomFieldTypesService) { }

  ngOnInit(): void {
    this.maxOptionsId = this.customField?.maxOptionsId || 0;
    this.customFieldTypes = this.customFieldTypesService.getAll();

    this.form = this.fb.group({
      description: new FormControl(this.customField?.description, Validators.required),
      descriptionEN: new FormControl(this.customField?.descriptionEN, Validators.required),
      descriptionPT: new FormControl(this.customField?.descriptionPT, Validators.required),
      descriptionFR: new FormControl(this.customField?.descriptionFR, Validators.required),
      type: new FormControl(this.customField?.type, Validators.required),
      hasTranslations: new FormControl(
        { value: !!this.customField?.hasTranslations, disabled: !this.customField?.type?.hasOptions },
        Validators.required
      ),
      mandatory: new FormControl(!!this.customField?.mandatory, Validators.required),
      options: this.fb.array([])
    });

    if (this.customField?.options) {
      for (const option of this.customField?.options) {
        this.addOption(option);
      }
    }

    this.form.valueChanges
      .subscribe((customField: CustomField) => {
        this.checkIfOptionDeletable(customField);
        this.emitCustomField(customField);
        this.emitValidity();
      });

    this.form.get('type').valueChanges
      .subscribe((type: CustomFieldType) => {
        this.onTypeSelectChanges(type);
      }
      );

    this.form.get('hasTranslations').valueChanges
      .subscribe((hasTranslations: boolean) => {
        this.updateOptionValidators(hasTranslations);
      });

      this.form.get('mandatory').valueChanges
      .subscribe((mandatory: boolean) => {
        this.customField.mandatory = mandatory;
      });
  }

  addOption(customFieldOption?: CustomFieldOption): void {
    if (!customFieldOption) {
      this.maxOptionsId += 1;
    }

    const optionFormGroup = new FormGroup({
      id: new FormControl(customFieldOption?.id || this.maxOptionsId),
      description: new FormControl(customFieldOption?.description || '', Validators.required),
      descriptionEN: new FormControl(customFieldOption?.descriptionEN || ''),
      descriptionPT: new FormControl(customFieldOption?.descriptionPT || ''),
      descriptionFR: new FormControl(customFieldOption?.descriptionFR || ''),
    });

    this.options.push(optionFormGroup);
    this.isOptionDeletable = true;

    this.updateOptionValidators(this.form?.get('hasTranslations').value);
  }

  removeOption(index: number): void {
    if (!this.isOptionDeletable) {
      return;
    }

    this.options.removeAt(index);

    if (this.options.length === 0) {
      this.addOption();
      this.isOptionDeletable = false;
    }
  }

  emitCustomField(customField: CustomField) {
    if (this.customField?.id) {
      customField = { ...customField, id: this.customField.id };
    }

    customField.maxOptionsId = this.maxOptionsId;

    if (!customField?.type?.hasOptions) {
      customField.options = [];
    }

    if (!customField.hasTranslations) {
      for (const option of customField.options) {
        option.descriptionEN = null;
        option.descriptionPT = null;
        option.descriptionFR = null;
      }
    }

    this.changeCustomField.emit(customField);
  }

  emitValidity() {
    this.changeValidity.emit(this.form.valid);
  }

  onTypeSelectChanges(type: CustomFieldType) {
    const hasTranslationsControl = this.form.controls['hasTranslations'];
    if (type.hasOptions) {
      hasTranslationsControl.enable({ emitEvent: false });
      if ((this.form.controls['options'] as FormArray).length === 0) {
        this.addOption();
      }
    } else {
      hasTranslationsControl.setValue(false, { emitEvent: false });
      hasTranslationsControl.disable({ emitEvent: false });
    }

    this.updateOptionValidators(hasTranslationsControl.value);
  }

  updateOptionValidators(hasTranslations: boolean) {
    for (let option of this.options.controls) {
      if (this.hasOptions) {
        option.get('description').setValidators(Validators.required);
      } else {
        option.get('description').clearValidators();
      }

      if (hasTranslations) {
        option.get('descriptionEN').setValidators(Validators.required);
        option.get('descriptionPT').setValidators(Validators.required);
        option.get('descriptionFR').setValidators(Validators.required);
      } else {
        option.get('descriptionEN').clearValidators();
        option.get('descriptionPT').clearValidators();
        option.get('descriptionFR').clearValidators();
      }

      option.get('description').updateValueAndValidity();
      option.get('descriptionEN').updateValueAndValidity();
      option.get('descriptionPT').updateValueAndValidity();
      option.get('descriptionFR').updateValueAndValidity();
    }

    this.emitValidity();
  }

  checkIfOptionDeletable(customField: CustomField): void {
    if (!customField?.type?.hasOptions || this.options.length !== 1) {
      this.isOptionDeletable = true;
    }

    const optionFields = this.options.controls[0]?.value;
    if (optionFields && !optionFields.description && !optionFields.descriptionEN && !optionFields.descriptionPT && !optionFields.descriptionFR) {
      this.isOptionDeletable = false;
    } else {
      this.isOptionDeletable = true;
    }
  }
}
