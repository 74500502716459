import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterContentInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



import { CommonsService } from '../../../services/commons-service';
import { Toaster } from 'ngx-toast-notifications';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { NatureOfActivityDTO } from '@app/models/NatureOfActivity';
import { DialogAddActivityComponent } from '../dialog-add-activity/dialog-add-activity.component';
import { DialogDeleteActivityComponent } from '../dialog-delete-activity/dialog-delete-activity.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity-table',
  templateUrl: './activity-table.component.html',
  styles: []
})
export class ActivityTableComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  data: any;
  dataSource: any;
  displayedColumns: string[] = ['Description', 'DescriptionEN', 'DescriptionPT', 'DescriptionFR', 'Actions'];
  actions: string[] = ['delete', 'update'];
  ready: boolean = false;
  errorMessage: string;
  doneMessage: string;

  constructor(
    private commons: CommonsService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private toaster: Toaster,
  ) { }

  async ngAfterContentInit(): Promise<void> {

    await this.getData();
    this.updateTable();

  }


  ngOnInit(): void {
    this.translate.get('TablaActivities.error')
      .subscribe(translation => {
        this.errorMessage = translation;
      });
    this.translate.get('TablaActivities.done')
      .subscribe(translation => {
        this.doneMessage = translation;
      });

  }

  updateTable() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.ready = true;
  }

  async getData() {
    let r = await this.getActivitiesP();
    this.getActivities(r);
  }

  getActivitiesP() {
    return this.commons.getTypeOfActivity();
  }

  getActivities(r) {
    if (r !== null && r !== undefined) {
      this.data = (<NatureOfActivityDTO[]>r);
    }
  }

  openDialogAdd(): void {

    const dialogRef = this.dialog.open(DialogAddActivityComponent, {});

    dialogRef.afterClosed().subscribe(async result => {
      if (result === undefined) {
        return
      }
      if (result) {
        this.toaster.open({ text: this.doneMessage, duration: 4000, type: 'danger' });
        await this.getData();
        this.updateTable();
      } else {
        this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
      }
    })
  }

  delete(element) {
    const dialogRef = this.dialog.open(
      DialogDeleteActivityComponent,
      {
        data: {
          id: element.Id,
          code: element.Code,
          description: element.Description
        }
      }
    )

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        // No action
      } else if (!result) {
        // No use.
      } else {
        // Delete
        this.commons.deleteActivity(element.Id)
          .then(async r => {
            this.toaster.open({ text: this.doneMessage, duration: 4000, type: 'success' });
            await this.getData();
            this.updateTable();
          })
          .catch(err => {
            this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
          })
      }
    })
  }

  update(element) {
    this.openDialogUpdate({ data: element });
  }


  openDialogUpdate(data): void {

    const dialogRef = this.dialog.open(DialogAddActivityComponent, data);

    dialogRef.afterClosed().subscribe(async result => {
      if (result === undefined) {
        return
      }
      if (result) {
        await this.getData();
        this.updateTable();
      } else {
        this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
      }
    })
  }//dialog


}
