import { RemoteService } from './remote.service';
import { Injectable, Inject } from '@angular/core';
import { EvaluationQuestionaryCreateDTO } from '@app/models/EvaluationQuestionary';
import { HttpClient } from '@angular/common/http';
import { QuestionariesProxyClient } from 'src/proxy/apiQuestionaries/questionaries.proxy';
import { RiskCommentDto } from 'src/proxy/model/riskCommentDto';
import { QuestionaryCommentDto } from 'src/proxy/model/questionaryCommentDto';
import { Observable } from 'rxjs';
import { EvaluationQuestionaryDto } from 'src/proxy/model/evaluationQuestionaryDto';
import { QuestionScoreOverrideDto } from 'src/proxy/model/questionScoreOverrideDto';
import { QuestionaryThirdPartyDto } from 'src/proxy/model/questionaryThirdPartyDto';

@Injectable({
  providedIn: 'root',
})

export class QuestionariesService {

  constructor(
    private questionariesClient: QuestionariesProxyClient,
    private remoteService: RemoteService,
    private http: HttpClient,
    @Inject('API_URL') public serverUrl: string
  ) { }

  private apiURL = 'Questionaries';

  public getAllQuestionary() {
    return this.remoteService.getRequest(this.apiURL);
  }

  public duplicateQuestionary(id: number) {
    return this.remoteService.postRequest(`${this.apiURL}/DuplicateQuestionary/${id}`, {});
  }

  public saveQuestionayAnswer(idCampaign: number, idThird: number, answers: EvaluationQuestionaryCreateDTO) {
    const formData = this.ConstruirFormData(answers);
    return this.http.post(`${this.serverUrl}/${this.apiURL}/SaveQuestionaryAnswers/${idCampaign}/${idThird}`, formData).toPromise();
  }

  public updateQuestionaryComment(questionaryCommment: QuestionaryCommentDto) {
    this.questionariesClient.questionariesUpdateQuestionaryCommentPut(questionaryCommment).subscribe();
  }

  public updateQuestionaryRiskComments(riskComments: RiskCommentDto[]) {
    this.questionariesClient.questionariesUpdateQuestionaryRiskCommentsPut(riskComments).subscribe();
  }

  public getScores(thirdPartyId: number, campaignId: number): Observable<EvaluationQuestionaryDto> {
    return this.questionariesClient.questionariesGetScoresByThirdPartyAndCampaignIdThirdPartyIdCampaignGet(thirdPartyId, campaignId);
  }

  private ConstruirFormData(evaluation: EvaluationQuestionaryCreateDTO): FormData {
    const formData = new FormData();

    formData.append('ClientId', evaluation.ClientId ? evaluation.ClientId.toString() : '0');
    formData.append('Submit', evaluation.Submit.toString());

    evaluation.EvaluationQuestionaryRisk.forEach((risk, index1) => {
      formData.append('EvaluationQuestionaryRisk[' + index1 + '].RiskId', risk.RiskId.toString());
      risk.EvaluationQuestionaryRiskAnswer.forEach((answer, index2) => {
        formData.append('EvaluationQuestionaryRisk[' + index1 + '].EvaluationQuestionaryRiskAnswer[' + index2 + '].QuestionId', answer.QuestionId.toString());
        formData.append('EvaluationQuestionaryRisk[' + index1 + '].EvaluationQuestionaryRiskAnswer[' + index2 + '].Value', answer.Value.toString());
        if (answer.SelectedOption) {
          formData.append('EvaluationQuestionaryRisk[' + index1 + '].EvaluationQuestionaryRiskAnswer[' + index2 + '].SelectedOption', answer.SelectedOption);
        }
        if (answer.Comment) {
          formData.append('EvaluationQuestionaryRisk[' + index1 + '].EvaluationQuestionaryRiskAnswer[' + index2 + '].Comment', answer.Comment);
        }
        if (answer.FileName) {
          formData.append('EvaluationQuestionaryRisk[' + index1 + '].EvaluationQuestionaryRiskAnswer[' + index2 + '].FileName', answer.FileName);
        }
        if (answer.FileUri) {
          formData.append('EvaluationQuestionaryRisk[' + index1 + '].EvaluationQuestionaryRiskAnswer[' + index2 + '].FileUri', answer.FileUri);
        }
        if (answer.File) {
          formData.append('EvaluationQuestionaryRisk[' + index1 + '].EvaluationQuestionaryRiskAnswer[' + index2 + '].File', answer.File);
        }
      });
    });

    return formData;
  }

  async getFileStorageKey(filename: string, campaignId: number, thirdPartyId: number): Promise<any> {
    const res = await this.http.get(`${this.serverUrl}/Questionaries/SaveQuestionaryAnswersFile/${campaignId}/${thirdPartyId}/${filename}`).toPromise();
    return res;
  }

  deleteQuestionary(questionaryId: number): Observable<boolean> {
    return this.questionariesClient.questionariesDeleteIdGet(questionaryId);
  }

  overrideScores(campaignId: number, thirdPartyId: number, overridenScores: QuestionScoreOverrideDto[]): Observable<boolean> {
    return this.questionariesClient.questionariesOverrideScoreCampaignIdThirdPartyIdPost(campaignId, thirdPartyId, overridenScores)
  }

  submitQuestionary(campaignId: number, thirdPartyId: number): Observable<string>{
    return this.questionariesClient.questionariesSubmitQuestionaryIdCampaignIdThirdPartyPost(campaignId, thirdPartyId);
  }
}
