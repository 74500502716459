import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-third-massive-ko',
  templateUrl: './dialog-confirm-third-massive-ko.component.html',
  styles: [
  ]
})
export class DialogConfirmThirdMassiveComponentKo implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmThirdMassiveComponentKo>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
    }

    // cerrar modal
    onNoClick(): void {
        this.dialogRef.close();
    }
}
