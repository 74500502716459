import { PEPInfoDto } from 'src/proxy/model/pEPInfoDto';
import { PEPInfo } from '../../models/GesintelScreening/PEPInfo';

export function mapPEPInfo(dto: PEPInfoDto): PEPInfo {
  if (!dto) return null;

  return <PEPInfo>{
    fullname: dto.FullName,
    rut: dto.Rut,
    relationship: dto.Relationship,
    titularCategory: dto.TitularCategory,
    category: dto.Category,
    institution: dto.Institution,
    role: dto.Role,
    fromDate: dto.FromDate,
    toDate: dto.ToDate,
  };
}
