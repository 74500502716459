import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UsersProxyClient } from 'src/proxy/apiUser/users.proxy';
import { RiskallayUser } from '@app/models/RiskallayUser';
import { RiskallayUserDto } from 'src/proxy/model/riskallayUserDto';
import { mapUser } from './mappings/user.mappings';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private usersClient: UsersProxyClient
  ) { }

  public getAllClientUsers(): Observable<RiskallayUserDto[]> {
    return this.usersClient
      .usersGetAllUserDataGet();
  }

  public getCurrentUser(): Observable<RiskallayUserDto> {
    return this.usersClient
      .usersGetUserDataGet();
  }

  public getUserDetails(userId: string): Observable<RiskallayUser> {
    return this.usersClient.usersGetUserDataByIdUserIdGet(userId).pipe( map(u => mapUser(u)));
  }

  public deleteUser(userId: string): Observable<boolean> {
    return this.usersClient
      .usersUserIdDelete(userId);
  }
}
