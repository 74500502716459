<fieldset class="u-mb-1medium">
  <legend class="c-form__legend">
    {{ "ScoringCandidatesOrganizationSearch.singleScreening" | translate }}
  </legend>
  <div class="row">
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>{{
            "ScoringCandidatesOrganizationSearch.filter.name" | translate
          }}</mat-label>
          <input
            matInput
            name="companyName"
            value=""
            id="companyName"
            [(ngModel)]="model.name"
            placeholder="{{
              'ScoringCandidatesOrganizationSearch.filter.name' | translate
            }}"
            required
          />
          <mat-error>
            {{ "ScoringCandidatesOrganizationSearch.required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12 col-lg-3 col-xl-3">
      <div class="c-form__block">
        <div class="c-select">
          <mat-form-field>
            <mat-label>{{
              "ScoringCandidatesOrganizationSearch.filter.country" | translate
            }}</mat-label>
            <mat-select
              placeholder="{{
                'ScoringCandidatesOrganizationSearch.filter.country' | translate
              }}"
              [(ngModel)]="model.country"
            >
              <mat-option
                *ngFor="let country of countryList"
                [value]="country.codeAlpha3"
              >
                {{ country.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</fieldset>
<fieldset class="u-mb-1medium">
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-3">
      <div class="c-form__block">
        <button
          (click)="search()"
          type="button"
          [disabled]="!model.name?.length"
          class="c-button u-mr-1"
        >
          {{ "ScoringCandidatesOrganizationSearch.search" | translate }}
        </button>
      </div>
    </div>
  </div>
</fieldset>
