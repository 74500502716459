<div *ngIf="isLoading" class="overlay">
  <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
</div>

<app-start-screening
  *ngIf="refinitivScorings.length === 0 && amlUpdateScorings.length === 0 && equifaxWSScorings.length === 0 && memberCheckScorings.length === 0; else ScreeningScorings"
  [thirdPartyId]="thirdPartyId" (newScoring)="newScoring()">
</app-start-screening>

<ng-template #ScreeningScorings>
  <app-screening-scorings-container (newScoring)="newScoring()">
    <ng-container *ngFor="let scoring of refinitivScorings">
      <app-screening-scorings
        [scoring]="scoring"
        [software]="ScreeningProvider.Refinitiv"
        [thirdPartyId]="thirdPartyId"
        (refreshScoring)="refreshScoring($event)"
      ></app-screening-scorings>
    </ng-container>
    <ng-container *ngFor="let scoring of amlUpdateScorings">
      <app-screening-scorings
        [scoring]="scoring"
        [software]="ScreeningProvider.AMLUpdate"
        [thirdPartyId]="thirdPartyId"
        (refreshScoring)="refreshScoring($event)"
      ></app-screening-scorings>
    </ng-container>
    <ng-container *ngFor="let scoring of equifaxWSScorings">
      <app-screening-scorings
        [scoring]="scoring"
        [software]="ScreeningProvider.EquifaxWS"
        [thirdPartyId]="thirdPartyId"
        (refreshScoring)="refreshScoring($event)"
      ></app-screening-scorings>
    </ng-container>
    <ng-container *ngFor="let scoring of memberCheckScorings">
      <app-screening-scorings
        [scoring]="scoring"
        [software]="ScreeningProvider.MemberCheck"
        [thirdPartyId]="thirdPartyId"
        (refreshScoring)="refreshScoring($event)"
      ></app-screening-scorings>
    </ng-container>
  </app-screening-scorings-container>
</ng-template>
