/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 import { MonitoringConfigurationDto } from '../model/monitoringConfigurationDto';
 
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
 export class MonitoringConfigurationProxyClient {
 
     protected basePath = '/';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
        constructor(protected httpClient: HttpClient, @Optional()@Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * 
      * 
      * @param id 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public monitoringConfigurationDeleteGet(id?: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public monitoringConfigurationDeleteGet(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public monitoringConfigurationDeleteGet(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public monitoringConfigurationDeleteGet(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (id !== undefined && id !== null) {
             queryParameters = queryParameters.set('id', <any>id);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<boolean>('get',`${this.basePath}/MonitoringConfiguration/delete`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param refinitivThirdPartyId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public monitoringConfigurationGet(refinitivThirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<MonitoringConfigurationDto>;
     public monitoringConfigurationGet(refinitivThirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MonitoringConfigurationDto>>;
     public monitoringConfigurationGet(refinitivThirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MonitoringConfigurationDto>>;
     public monitoringConfigurationGet(refinitivThirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (refinitivThirdPartyId !== undefined && refinitivThirdPartyId !== null) {
             queryParameters = queryParameters.set('refinitivThirdPartyId', <any>refinitivThirdPartyId);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<MonitoringConfigurationDto>('get',`${this.basePath}/MonitoringConfiguration`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public monitoringConfigurationPost(body?: MonitoringConfigurationDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public monitoringConfigurationPost(body?: MonitoringConfigurationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public monitoringConfigurationPost(body?: MonitoringConfigurationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public monitoringConfigurationPost(body?: MonitoringConfigurationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<boolean>('post',`${this.basePath}/MonitoringConfiguration`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public monitoringConfigurationUpdatePost(body?: MonitoringConfigurationDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public monitoringConfigurationUpdatePost(body?: MonitoringConfigurationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public monitoringConfigurationUpdatePost(body?: MonitoringConfigurationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public monitoringConfigurationUpdatePost(body?: MonitoringConfigurationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<boolean>('post',`${this.basePath}/MonitoringConfiguration/update`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }
 