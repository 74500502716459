import { ThirdPartyConfigurationRequest } from "@app/models/ThirdPartyConfigurationRequest";
import { ThirdPartyConfigurationRequestDto } from "src/proxy/model/thirdPartyConfigurationRequestDto";

export function mapTPConfigRequestDtoToTPConfigRequest(dto: ThirdPartyConfigurationRequestDto): ThirdPartyConfigurationRequest {
  if (!dto) return null;

  return <ThirdPartyConfigurationRequest> {
    id: dto.Id,
    clientId: dto.ClientId,
    thirdPartyId : dto.ThirdPartyId,
    clientName : dto.ClientName,
    companyName : dto.CompanyName,
    taxId : dto.TaxId,
    countryId : dto.CountryId,
    typeOfActivityId : dto.TypeOfActivityId,
    sectorId : dto.SectorId,
    address : dto.Address,
    zipCode : dto.zipCode,
  };
}

export function mapTPConfigRequestToTPConfigRequestDto(dto: ThirdPartyConfigurationRequest): ThirdPartyConfigurationRequestDto {
  if (!dto) return null;

  return <ThirdPartyConfigurationRequestDto> {
    Id: dto.id,
    ClientId: dto.clientId,
    ThirdPartyId : dto.thirdPartyId,
    ClientName : dto.clientName,
    CompanyName : dto.companyName,
    TaxId : dto.taxId,
    CountryId : dto.countryId,
    TypeOfActivityId : dto.typeOfActivityId,
    SectorId : dto.sectorId,
    Address : dto.address,
    zipCode : dto.zipCode,
  };
}
