/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CampaignThirdPartyDto } from '../model/campaignThirdPartyDto';
import { Configuration } from '../configuration';


@Injectable()
export class CampaignThirdPartyProxy {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public campaignThirdPartyByCampaignIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CampaignThirdPartyDto>>;
    public campaignThirdPartyByCampaignIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CampaignThirdPartyDto>>>;
    public campaignThirdPartyByCampaignIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CampaignThirdPartyDto>>>;
    public campaignThirdPartyByCampaignIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling campaignThirdPartyByCampaignIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CampaignThirdPartyDto>>('get',`${this.basePath}/CampaignThirdParty/ByCampaign/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public campaignThirdPartyByThirdPartyIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CampaignThirdPartyDto>>;
    public campaignThirdPartyByThirdPartyIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CampaignThirdPartyDto>>>;
    public campaignThirdPartyByThirdPartyIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CampaignThirdPartyDto>>>;
    public campaignThirdPartyByThirdPartyIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling campaignThirdPartyByThirdPartyIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CampaignThirdPartyDto>>('get',`${this.basePath}/CampaignThirdParty/ByThirdParty/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param thirdPartyId 
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public campaignThirdPartyThirdPartyThirdPartyIdCampaignCampaignIdGet(thirdPartyId: number, campaignId: number, observe?: 'body', reportProgress?: boolean): Observable<CampaignThirdPartyDto>;
     public campaignThirdPartyThirdPartyThirdPartyIdCampaignCampaignIdGet(thirdPartyId: number, campaignId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampaignThirdPartyDto>>;
     public campaignThirdPartyThirdPartyThirdPartyIdCampaignCampaignIdGet(thirdPartyId: number, campaignId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampaignThirdPartyDto>>;
     public campaignThirdPartyThirdPartyThirdPartyIdCampaignCampaignIdGet(thirdPartyId: number, campaignId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (thirdPartyId === null || thirdPartyId === undefined) {
             throw new Error('Required parameter thirdPartyId was null or undefined when calling campaignThirdPartyThirdPartyThirdPartyIdCampaignCampaignIdGet.');
         }
 
         if (campaignId === null || campaignId === undefined) {
             throw new Error('Required parameter campaignId was null or undefined when calling campaignThirdPartyThirdPartyThirdPartyIdCampaignCampaignIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<CampaignThirdPartyDto>('get',`${this.basePath}/CampaignThirdParty/ThirdParty/${encodeURIComponent(String(thirdPartyId))}/Campaign/${encodeURIComponent(String(campaignId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
    }

}
