import { Injectable } from "@angular/core";
import { Screening } from "@app/models/scoring-tools/screening";
import { Observable, of } from "rxjs";
import { EquifaxWSLink } from "../models/EquifaxWS/EquifaxWSLink";
import { EquifaxWSRecord } from "../models/EquifaxWS/EquifaxWSRecord";
import { EquifaxWSScoringProxyClient } from "src/proxy/apiEquifaxWS/equifaxWSScoring.proxy";
import { map } from "rxjs/operators";
import { mapEquifaxWSRecord } from "./mappings/equifaxWS/equifax-ws-record.mapping";
import { mapEquifaxWSLink } from "./mappings/equifaxWS/equifax-ws-link.mapping";
import { mapEquifaxWSScreening } from "./mappings/equifaxWS/equifax-ws-screening.mapping";

@Injectable()
export class EquifaxWSScoringService {

  constructor(private equifaxWSClient: EquifaxWSScoringProxyClient) { }

  public getScreenings(id: number): Observable<Screening[]> {
    return this.equifaxWSClient.equifaxWSScoringScreeningsLinkIdGet(id).pipe(map(r => r.map(s => mapEquifaxWSScreening(s))))
  }

  public deleteLink(id: number): Observable<boolean> {
    return this.equifaxWSClient.equifaxWSScoringRemoveLinkLinkIdPost(id);
  }

  public refresh(cedula: string, thirdPartyId?: number): Observable<string> {
    return this.equifaxWSClient.equifaxWSScoringRefreshScreeinigCedulaPost(cedula, thirdPartyId);
  }

  public getScoringLinks(thirdPartyId?: number): Observable<EquifaxWSLink[]> {
    return this.equifaxWSClient.equifaxWSScoringLinksGet(thirdPartyId).pipe(map(r => r.map(d => mapEquifaxWSLink(d))));
  }

  public screeningDetails(id: number): Observable<EquifaxWSRecord> {
    return this.equifaxWSClient.equifaxWSScoringScreeningDetailsScreenIdGet(id).pipe(map(r => mapEquifaxWSRecord(r)));
  }

  public search(searchType: number, cedula: string): Observable<string> {
    return this.equifaxWSClient.equifaxWSScoringSearchGet(cedula, searchType);
  }

  public saveScreening(cedula: string, name: string, searchType: number, thirdPartyId?: number): Observable<boolean> {
    return this.equifaxWSClient.equifaxWSScoringCreateLinkPostForm(cedula, name, searchType, thirdPartyId);
  }

  public candidateDetails(cedula: string, searchType: number): Observable<EquifaxWSRecord> {
    return this.equifaxWSClient.equifaxWSScoringCandidateDetailsCedulaTypeGet(cedula, searchType).pipe(map(r => mapEquifaxWSRecord(r)));
  }
}
