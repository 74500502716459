import { Component } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
import { CommercialService } from '@app/services/commercial.service';

@Component({
  selector: 'app-dialog-expand-quotas',
  templateUrl: './dialog-expand-quotas.component.html',
  styleUrls: ['./dialog-expand-quotas.component.scss']
})
export class DialogExpandQuotasComponent {
  quotaTypeToIncrease: number;
  remarks: string;

  constructor(
    public dialogRef: MatDialogRef<DialogExpandQuotasComponent>,
    public commercialService: CommercialService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  sendExpandQuotaRequest(): void {
    this.commercialService
      .sendQuotaIncrementRequest(this.quotaTypeToIncrease, this.remarks)
      .subscribe(_ => {
        this.dialogRef.close();
      });
  }
}
