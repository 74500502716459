/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders,
          HttpResponse, HttpEvent }                           from '@angular/common/http';

 import { Observable }                                        from 'rxjs';

 import { CampaignDto } from '../model/campaignDto';
 import { RiskThirdPartyDto } from '../model/riskThirdPartyDto';

 import { Configuration }                                     from '../configuration';


 @Injectable()
 export class RiskListProxyClient {

     protected basePath = '/';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();

     constructor(protected httpClient: HttpClient, @Optional()@Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }

     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }


     /**
      *
      *
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public riskListGetAllLaunchedGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CampaignDto>>;
     public riskListGetAllLaunchedGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CampaignDto>>>;
     public riskListGetAllLaunchedGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CampaignDto>>>;
     public riskListGetAllLaunchedGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<Array<RiskThirdPartyDto>>('get',`${this.basePath}/RiskList/GetAllLaunched`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public riskListGetAllPendingsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CampaignDto>>;
     public riskListGetAllPendingsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CampaignDto>>>;
     public riskListGetAllPendingsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CampaignDto>>>;
     public riskListGetAllPendingsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<Array<CampaignDto>>('get',`${this.basePath}/RiskList/GetAllPendings`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

 }
