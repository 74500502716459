<div class="mortgage-credit">
  <p id="keyData" class="mortgage-credit__legend u-mt-1medium">{{ "EquifaxWSMortgageCredit.mortgageCredit" | translate }} {{ index }}</p>
  <div class="mortgage-credit__group" aria-labelledby="keyData">
    <!-- <div class="mortgage-credit-item">
      <p id="brand" class="mortgage-credit-item__title">
        {{ "EquifaxWSMortgageCredit.brand" | translate }}
      </p>
      <p class="mortgage-credit-item__text" aria-labelledby="brand">
        {{ credit?.brand || "" }}
      </p>
    </div>
    <div class="mortgage-credit-item">
      <p id="model" class="mortgage-credit-item__title">
        {{ "EquifaxWSMortgageCredit.model" | translate }}
      </p>
      <p class="mortgage-credit-item__text" aria-labelledby="model">
        {{ credit?.model || "" }}
      </p>
    </div>
    <div class="mortgage-credit-item">
      <p id="year" class="mortgage-credit-item__title">
        {{ "EquifaxWSMortgageCredit.year" | translate }}
      </p>
      <p class="mortgage-credit-item__text" aria-labelledby="year">
        {{ credit?.year || "" }}
      </p>
    </div>
    <div class="mortgage-credit-item">
      <p id="value" class="mortgage-credit-item__title">
        {{ "EquifaxWSMortgageCredit.value" | translate }}
      </p>
      <p class="mortgage-credit-item__text" aria-labelledby="value">
        {{ credit?.value || "" }}
      </p>
    </div> -->
  </div>
</div>
