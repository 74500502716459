import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-double-template',
  templateUrl: './dialog-double-template.component.html',
  styles: [
  ]
})
export class DialogDoubleTemplateComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogDoubleTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  //cancelar
  onNoClick(): void {
    this.dialogRef.close();
  }

  //aceptar
  finishCreate():void{
    this.dialogRef.close(true);
  }
}
