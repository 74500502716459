<section *ngIf="hasComments || editMode; else noComments" class="u-spaceLeft-bootstrap u-mt-2">
  <header class="d-flex justify-content-between" *ngIf="editMode; else editingComments">
    <div class="d-flex align-items-end">
      <button class="c-button c-button--light u-ml-1 u-mr-1" (click)="cancelEdit()">
        {{ "EvaluationQuestionaryRisksComments.cancelEdit" | translate }}
      </button>
      <button class="c-button u-ml-1 u-mr-1" (click)="saveEdit()">
        {{ "EvaluationQuestionaryRisksComments.saveEdit" | translate }}
      </button>
    </div>
  </header>
  <ng-template #editingComments>
    <header class="edit-comments">
      <div>
        <button class="c-button c-button--gradient u-ml-1 u-mr-1" (click)="editComments()">
          <span class="c-graphic-link__ico" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                d="M16.38.782,6.22,10.942A4.138,4.138,0,0,0,5,13.888v1.119a.833.833,0,0,0,.833.833H6.952A4.138,4.138,0,0,0,9.9,14.621l10.16-10.16a2.6,2.6,0,0,0,0-3.678,2.662,2.662,0,0,0-3.678,0Zm2.5,2.5L8.72,13.442a2.517,2.517,0,0,1-1.767.732H6.667v-.286A2.517,2.517,0,0,1,7.4,12.121l10.16-10.16a.957.957,0,0,1,1.322,0,.936.936,0,0,1,0,1.322Z"
                transform="translate(-.833-.008)" />
              <path
                d="M19.167,7.482a.833.833,0,0,0-.833.833V12.5H15A2.5,2.5,0,0,0,12.5,15v3.333H4.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5H11.7A.833.833,0,0,0,11.7,0H4.167A4.172,4.172,0,0,0,0,4.167V15.833A4.172,4.172,0,0,0,4.167,20h9.452a4.14,4.14,0,0,0,2.947-1.22l2.213-2.215A4.14,4.14,0,0,0,20,13.619v-5.3A.833.833,0,0,0,19.167,7.482ZM15.387,17.6a2.479,2.479,0,0,1-1.221.667V15A.833.833,0,0,1,15,14.167h3.271a2.513,2.513,0,0,1-.667,1.22Z" />
            </svg>
          </span>
          {{ "EvaluationQuestionaryRisksComments.edit" | translate }}
        </button>
      </div>
    </header>
  </ng-template>
  <div class="row">
    <div class="col-12 u-mt-1">
      <div *ngIf="editMode; else showComments" class="c-form">
        <mat-form-field class="c-form__block">
          <mat-label>{{ "EvaluationQuestionaryRisksComments.commentLabel" | translate }}</mat-label>
          <input matInput [(ngModel)]="resolutionRecord.comment" type="text" class="c-form__input" />
        </mat-form-field>
      </div>
      <ng-template #showComments>
        <p class="o-text u-mt-1 u-mb-2" [ngClass]="{ 'empty-comment': !resolutionRecord.comment }">
          {{resolutionRecord.comment || ("EvaluationQuestionaryRisksComments.noComment" | translate) }}
        </p>
      </ng-template>
    </div>
  </div>
</section>

<ng-template #noComments>
  <section class="u-spaceLeft-bootstrap u-mt-2">
    <div class="offset-4 col-4 d-flex flex-column align-items-center">
      <div class="col-8">
        <img src="assets/landing-scoring-tools.png" alt="scoring tools" loading="lazy" />
      </div>
      <h1 class="o-title o-title--medium u-mt-1">
        {{ "resolutionDetails.commentTitle" | translate }}
      </h1>
      <p class="o-text no-comments u-mb-1 u-mt-1">
        {{ "resolutionDetails.commentSubtitle" | translate }}
      </p>
      <button class="c-button c-button--gradient" (click)="editComments()">
        {{ "resolutionDetails.commentBtn" | translate }}
      </button>
    </div>
  </section>
</ng-template>
