import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonsService } from '../../services/commons-service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RiskallayUserDTO } from '@app/models/RiskallayUser';
import { RemoteService } from '@app/services/remote.service';
import { UpdateClientUsersData } from '@app/models/Client';
import { UserProfileService } from '@app/services/users-profile.service';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';

export interface AdjustsTable{
  code: number;
  description: string;
  actions: string
}

@Component({
  selector: 'app-general-adjustments',
  templateUrl: './general-adjustments.component.html',
  styleUrls: ['./general-adjustments.component.scss']
})
export class GeneralAdjustmentsComponent implements OnInit {

  @ViewChild('customFields') customFieldsPanel: MatExpansionPanel;
  cuota:any;
  form: FormGroup;
  userdata: RiskallayUserDTO;
  updatedDataMessage: string;
  errorUpdatingDataMessage: string;
  
  constructor(
    private commons:CommonsService,
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private userProfileService : UserProfileService,
    private translate: TranslateService,
    private toaster: Toaster,
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {

    this.form = this.formBuilder.group({
      TwoFactorEnabled: new FormControl(false, Validators.required)
    });

    this.translate.get('ConfigAccount.dataUpdated')
      .subscribe(translation => {
        this.updatedDataMessage = translation;
      });
    this.translate.get('ConfigAccount.errorUpdating')
      .subscribe(translation => {
        this.errorUpdatingDataMessage = translation;
      });

    await this.loadInitialData();

    this.route.params.subscribe(params => {
        if(params.table === 'custom-fields') {
            this.customFieldsPanel.open();
            this.scrollToPanel(this.customFieldsPanel);
        }
    });
  }

  async loadInitialData() {
    let promiseUserData = this.getUserDataP();
    let promiseCuota = this.getCuotaP();
    let promises = [
      promiseUserData,   // 0
      promiseCuota,      //1
    ];

    await Promise.all(promises).then(values => {
      this.getUserData(values[0]);
      this.getCuotaData(values[1]);
    });
  }

  getCuotaP(){
    return this.commons.getClientHaveFreeLicense();
  }

  getUserDataP() {
    return this.remoteService.getRequest("Users/GetUserData");
  }

  getCuotaData(r){
    if (r !== null && r !== undefined) {
      this.cuota=r;  
    } else {
      this.cuota=false;
    }   
  }

  getUserData(r) {
    if (r !== null && r !== undefined) {
      this.userdata = (<RiskallayUserDTO>r);   
      this.form.get('TwoFactorEnabled').setValue(this.userdata.TwoFactorEnabled);   
    }
  }

  saveData() {
    let updateClientUsersDataDto: UpdateClientUsersData = {
      TwoFactorEnabled : this.form.get("TwoFactorEnabled").value
    }

    this.userProfileService.UpdateClientUsersData(updateClientUsersDataDto)
    .then(res => {      
          this.toaster.open({ text: this.updatedDataMessage, duration: 6000, type: 'info' });
    },
    error => {
          this.toaster.open({ text: this.errorUpdatingDataMessage, duration: 6000, type: 'danger' });
    })
  }

  scrollToPanel(panel: MatExpansionPanel) {
    const el = document.getElementById(panel._headerId);
    el.scrollIntoView();
  }
}
