import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CampaignProxyClient } from "src/proxy/apiCampaign/campaign.proxy";
import { QuestionariesProxyClient } from "src/proxy/apiQuestionaries/questionaries.proxy";
import { CampaignDto } from "src/proxy/model/campaignDto";
import { EvaluationQuestionaryDto } from "src/proxy/model/evaluationQuestionaryDto";
import { Chart, ChartHeader } from "../pdf-template-generator/Models/chart";
import { ColorHex } from "../pdf-template-generator/Models/colorHex";
import { Content } from "../pdf-template-generator/Models/content";
import { CoverPage } from "../pdf-template-generator/Models/coverPage";
import { DocumentContent } from "../pdf-template-generator/Models/documentContent";
import { Font } from "../pdf-template-generator/Models/font";
import { Footer } from "../pdf-template-generator/Models/footer";
import { Header } from "../pdf-template-generator/Models/header";
import { Image } from "../pdf-template-generator/Models/image";
import { Row } from "../pdf-template-generator/Models/row";
import { Section } from "../pdf-template-generator/Models/section";
import { SvgPath } from "../pdf-template-generator/Models/svgPath";
import { Text } from "../pdf-template-generator/Models/text";
import { TextUnderline } from "../pdf-template-generator/Models/textUnderline";
import { templateGenerator } from "../pdf-template-generator/template-generator";
import { UsersProxyClient } from "src/proxy/apiUser/users.proxy";
import { EvaluationQuestionaryRiskDto } from "src/proxy/model/evaluationQuestionaryRiskDto";
import { TranslateDescriptionPipe } from "@app/shared/pipes/translate-description.pipe";
import { CampaignStatus } from "@app/models/CampaignStatus";
@Injectable()
export class ThirdPartyEvaluationReportService {
    locale: string;

    constructor(private campaignClient: CampaignProxyClient,
                private translateDescription: TranslateDescriptionPipe,
                private questionariesClient: QuestionariesProxyClient,
                private userClient: UsersProxyClient,
                private translate: TranslateService){ }

    async getReport(campaignId: number, thirdPartyId: number, imgSystemScoreGaugeChart: string, imgManualScoreGaugeChart: string, imgSystemScoresByRiskChart: string, imgManualScoresByRiskChart: string, risks: any[], manualRisks: any[]): Promise<[string, Uint8Array]> {
        this.locale = this.translate.currentLang.split("-")[0];
        let translations = await this.translate.get("thirdPartyAssesmentReport").toPromise();
        const campaignDetails = await this.campaignClient.campaignGetByIdAndUserIdCampaignGet(campaignId).toPromise();
        const campaign = await this.campaignClient.campaignIdCampaignGet(campaignId).toPromise();
        const thirdCampaignDetails = await this.questionariesClient.questionariesGetScoresByThirdPartyAndCampaignIdThirdPartyIdCampaignGet(thirdPartyId, campaignId).toPromise();
        const userDetails = await this.userClient.usersGetUserDataGet().toPromise();
        const username = `${userDetails.Name} ${userDetails.SurName}`;
        const printDate = new Date();
        const fonts = await this.getReportFonts();
        const coverPage = await this.getReportCoverPage(thirdCampaignDetails.ThirdParty.CompanyName, userDetails.Client.Name, username, printDate, campaignDetails.Name, translations);
        const pageHeader = await this.getPageHeader(translations);
        const pageFooter = this.getPageFooter(userDetails.Client.Name, printDate, username, translations);
        const evaluationSection = await this.getEvaluationSection(campaignDetails, campaign.Questionary.QuestionaryBlocks.reduce((sum, f) => sum + f.Questions.length, 0), translations);
        const riskReportSection = this.getRiskReportSection(imgSystemScoreGaugeChart, imgManualScoreGaugeChart, translations);
        const thirdRiskEvaluationSection = this.getThirdRiskEvaluation(imgSystemScoresByRiskChart, imgManualScoresByRiskChart,risks, manualRisks, translations);
        const disclaimerSection = this.getDisclaimerSection(thirdCampaignDetails, translations);
        const remarkSection = this.getThirdManualEvaluationDetails(translations, thirdCampaignDetails);
        const remarksByRiskSection = this.getRemarkByRiskSection(translations, thirdCampaignDetails.EvaluationQuestionaryRisk)

        const docContent = <DocumentContent>{
            header: pageHeader,
            footer: pageFooter,
            sections: [evaluationSection, riskReportSection, thirdRiskEvaluationSection, remarkSection, remarksByRiskSection, disclaimerSection]
        }

        return [`Riskallay-Report-${thirdCampaignDetails.ThirdParty.CompanyName}-${campaignDetails.Name}.pdf`,await templateGenerator(fonts, docContent, coverPage)];
    }

    async getReportFonts(): Promise<Font[]> {
        let fonts: Font[] = [];
        fonts.push(<Font>{font: await fetch('/assets/report/Roboto-Medium.ttf').then((res) => res.arrayBuffer()), name:'Roboto-Medium'});
        fonts.push(<Font>{font: await fetch('/assets/report/Roboto-Regular.ttf').then((res) => res.arrayBuffer()), name:'Roboto-Regular'});
        fonts.push(<Font>{font: await fetch('/assets/report/Roboto-Bold.ttf').then((res) => res.arrayBuffer()), name:'Roboto-Bold'});
        fonts.push(<Font>{font: await fetch('/assets/report/Poppins-SemiBold.ttf').then((res) => res.arrayBuffer()), name:'Poppins-SemiBold'});

        return fonts;
    }

    async getReportCoverPage(thirdName: string, companyName: string, username: string, printDate: Date, evaluationName: string, translations: any): Promise<CoverPage> {
        return <CoverPage>{content: [
                  <Content>{content:<Image>{ image: await fetch('/assets/report/background.png').then((res) => res.arrayBuffer()), height:842, width:595, customHeight:1.73},  posX:0, posY:0, isRelative: false},
                  <Content>{content:<Image>{ image: await fetch('/assets/report/coverImageThirdEval.png').then((res) => res.arrayBuffer()), height:398, width:515}, posX:40, posY:24, isRelative: false},
                  <Content>{content:<Image>{ image: await fetch('/assets/report/wave.png').then((res) => res.arrayBuffer()), height:842, width:595}, posX:0, posY:419, isRelative: false},
                  <Content>{content:<Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height:42, width:180}, posX:40, posY:454, isRelative: false},
                  <Content>{content:<Text>{text:translations.cover.report, font: 'Roboto-Medium', fontSize: 20, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 536, isRelative: false},
                  <Content>{content:<Text>{text:thirdName, font: 'Poppins-SemiBold', fontSize: 20, fontColor: <ColorHex>{hexCode: '121212'}, maxWidth: 515}, posX: 40, posY: 568, isRelative: false},
                  <Content>{content:<Text>{text:translations.cover.intro.replace('${thirdName}',thirdName).replace('${evaluationName}',evaluationName), font: 'Roboto-Regular',
                                fontSize: 10, fontColor: <ColorHex>{hexCode: '666666'}, lineHeight: 16, maxWidth: 335}, posX: 40, posY: 690, isRelative: false},
                  <Content>{content:<Text>{text: translations.infoCollectedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 399, posY: 690, isRelative: false},
                  <Content>{content:<Text>{text: companyName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 399, posY: 700, isRelative: false},
                  <Content>{content:<Text>{text:translations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 399, posY: 720, isRelative: false},
                  <Content>{content:<Text>{text: username, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 399, posY: 730, isRelative: false},
                  <Content>{content:<Text>{text:translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 399, posY: 750, isRelative: false},
                  <Content>{content:<Text>{text:formatDate(printDate,'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 399, posY: 760, isRelative: false},
        ]};
    }

    async getPageHeader(tranlations: any): Promise<Header> {
        return <Header>{height:67,
            content: [<Content>{content:<Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height:31, width:133}, posX:40, posY:24, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.confidential, font: 'Roboto-Medium', fontSize: 10, fontColor: <ColorHex>{hexCode: 'f8b410'}, lineHeight:16}, posX: 474, posY: 31.5, isRelative: false}
            ]
        };
    }

    getPageFooter(companyName: string, printDate: Date, username: string, tranlations: any): Footer {
        return <Footer>{height:71,
            content:[<Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, color:<ColorHex>{hexCode:'d9d9d9'}, width:515},  posX:40, posY:771, isRelative: false},
                     <Content>{content:<Text>{text:tranlations.infoCollectedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 783, isRelative: false},
                     <Content>{content:<Text>{text:tranlations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 796, isRelative: false},
                     <Content>{content:<Text>{text:tranlations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 809, isRelative: false},
                     <Content>{content:<Text>{text:companyName, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}}, posX: 154, posY: 783, isRelative: false},
                     <Content>{content:<Text>{text:username, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}},  posX: 154, posY: 796, isRelative: false},
                     <Content>{content:<Text>{text:formatDate(printDate,'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}}, posX: 154, posY: 809, isRelative: false},
                     <Content>{content:<Text>{text:tranlations.pageNumber, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}, lineHeight:16}, posX: 522, posY: 783, isRelative: false},
            ]
        };
    }

    async getEvaluationSection(campaignDetails: CampaignDto, questionNumber: number, tranlations: any): Promise<Section> {
        return <Section>{
            isFullPage: false,
            isAbsolute: true,
            content: [<Content>{content:<Text>{text: campaignDetails.Name, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'},underline:<TextUnderline>{color:<ColorHex>{hexCode:'fdc130'},thickness:2}}, posX: 40, posY: 84, isRelative: false},
                      <Content>{content:<Image>{image: await fetch('/assets/report/campaignStatusPending.png').then((res) => res.arrayBuffer()), height:28, width:28}, posX:40, posY:128, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.ThirdParties.filter(f => f.Status == CampaignStatus.pending).length.toString(), font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:98, lineHeight:17}, posX:72, posY:128, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.pending, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:98, lineHeight:10},  posX:72, posY:145, isRelative: false},
                      <Content>{content:<Image>{image: await fetch('/assets/report/campaignStatusInProgress.png').then((res) => res.arrayBuffer()), height:28, width:28}, posX:163, posY:128, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.ThirdParties.filter(f => f.Status == CampaignStatus.inProgress).length.toString(), font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:98, lineHeight:17}, posX:192, posY:128, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.inProgress, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:98, lineHeight:10},  posX:195, posY:145, isRelative: false},
                      <Content>{content:<Image>{image: await fetch('/assets/report/campaignStatusPendingSign.png').then((res) => res.arrayBuffer()), height:28, width:28}, posX:286, posY:128, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.ThirdParties.filter(f => f.Status == CampaignStatus.pendingSign).length.toString(), font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:98, lineHeight:17}, posX:318, posY:128, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.pendingSign, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:98, lineHeight:10},  posX:318, posY:145, isRelative: false},
                      <Content>{content:<Image>{image: await fetch('/assets/report/campaignStatusPendingReview.png').then((res) => res.arrayBuffer()), height:28, width:28}, posX:409, posY:128, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.ThirdParties.filter(f => f.Status == CampaignStatus.pendingReview).length.toString(), font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:98, lineHeight:17}, posX:441, posY:128, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.pendingReview, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:98, lineHeight:10},  posX:441, posY:145, isRelative: false},

                      <Content>{content:<Image>{image: await fetch('/assets/report/campaignStatusAcknowledged.png').then((res) => res.arrayBuffer()), height:28, width:28}, posX:40, posY:174, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.ThirdParties.filter(f => f.Status == CampaignStatus.acknowledged).length.toString(), font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:98, lineHeight:17}, posX:72, posY:174, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.acknowledged, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:98, lineHeight:10},  posX:72, posY:194, isRelative: false},

                      <Content>{content:<Image>{image: await fetch('/assets/report/campaignStatusDiscarded.png').then((res) => res.arrayBuffer()), height:28, width:28}, posX:163, posY:174, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.ThirdParties.filter(f => f.Status == CampaignStatus.discarded).length.toString(), font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:98, lineHeight:17}, posX:192, posY:174, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.discarded, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:98, lineHeight:10},  posX:192, posY:191, isRelative: false},

                      <Content>{content:<Image>{image: await fetch('/assets/report/campaignStatusReviewed.png').then((res) => res.arrayBuffer()), height:28, width:28}, posX:286, posY:174, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.ThirdParties.filter(f => f.Status == CampaignStatus.reviewed).length.toString(), font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:98, lineHeight:17}, posX:318, posY:174, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.reviewed, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:98, lineHeight:10},  posX:318, posY:191, isRelative: false},


                      <Content>{content:<Text>{text: tranlations.assesmentSection.details, font:'Roboto-Bold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:72, lineHeight:19},  posX:40, posY:220, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.assesmentSection.selectedChecklist, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:275, lineHeight:9},  posX:130, posY:220, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.Questionary.Code, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:275, lineHeight:12},  posX:130, posY:233, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.assesmentSection.questionNumber, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:125.7, lineHeight:9},  posX:130, posY:261, isRelative: false},
                      <Content>{content:<Text>{text: questionNumber.toString(), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:125.7, lineHeight:12},  posX:130, posY:274, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.assesmentSection.assesedThirds, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:125.7, lineHeight:9},  posX:279.7, posY:261, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.ThirdParties.length.toString(), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:125.7, lineHeight:12},  posX:279.7, posY:274, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.assesmentSection.amount, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:125.7, lineHeight:9},  posX:429.3, posY:261, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.Amount.toString(), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:125.7, lineHeight:12},  posX:429.3, posY:274, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.assesmentSection.startDate, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:200.5, lineHeight:9},  posX:130, posY:302, isRelative: false},
                      <Content>{content:<Text>{text: formatDate(campaignDetails.StartDate,'dd/MM/yyyy - HH:mm', this.locale), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:200.5, lineHeight:12},  posX:130, posY:315, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.assesmentSection.endDate, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:200.5, lineHeight:9},  posX:354.5, posY:302, isRelative: false},
                      <Content>{content:<Text>{text: formatDate(campaignDetails.EndDate,'dd/MM/yyyy - HH:mm', this.locale), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:200.5, lineHeight:12},  posX:354.5, posY:315, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.assesmentSection.remarks, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:425, lineHeight:9},  posX:130, posY:343, isRelative: false},
                      <Content>{content:<Text>{text: campaignDetails.Introduction, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:425, lineHeight:12},  posX:130, posY:356, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.assesmentSection.attachedDocuments, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:425, lineHeight:9},  posX:130, posY:16, isRelative: true},
                      <Content>{content:<Text>{text: campaignDetails.FileName, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:425, lineHeight:12},  posX:130, posY:4, isRelative: true},
                   ]
        }
    }

    getRiskReportSection(imgSystemScoreGaugeChart: string, imgManualScoreGaugeChart: string, translations: any): Section {

        if (imgManualScoreGaugeChart == '') {
            return <Section>{isFullPage: false,
                isAbsolute: false,
                content: [<Content>{content:<Text>{text:translations.riskReportSection.riskReport, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'},underline:<TextUnderline>{color:<ColorHex>{hexCode:'fdc130'},thickness:2}}, posX: 40, posY: 12, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                                <Content>{content:<Chart>{height:98,width:156, margin: 18,chart: imgSystemScoreGaugeChart, header:<ChartHeader>{height:19, text: translations.riskReportSection.riskListAssesmentChart, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'ffffff'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'000000'}}},posX:0,posY:12, isRelative: true},
                          ]},posX:40, posY:12, isRelative: true},
                ]
            }
        }
        return <Section>{isFullPage: false,
            isAbsolute: false,
            content: [<Content>{content:<Text>{text:translations.riskReportSection.riskReport, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'},underline:<TextUnderline>{color:<ColorHex>{hexCode:'fdc130'},thickness:2}}, posX: 40, posY: 12, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Chart>{height:98,width:156, margin: 18,chart: imgSystemScoreGaugeChart, header:<ChartHeader>{height:19, text: translations.riskReportSection.riskListAssesmentChart, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'ffffff'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'000000'}}},posX:0,posY:12, isRelative: true},
                            <Content>{content:<Chart>{height:98,width:156, margin: 18,chart: imgManualScoreGaugeChart, header:<ChartHeader>{height:19, text: translations.riskReportSection.manualAssesmentChart, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'ffffff'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'000000'}}},posX:23.5,posY:12, isRelative: true},
                      ]},posX:40, posY:12, isRelative: true},
            ]
        }
    }

    getThirdRiskEvaluation(imgSystemScoresByRiskChart:string, imgManualScoresByRiskChart: string, risks: any[], manualRisks: any[], translations: any): Section {

        const riskHigh = risks.filter(r => this.getRiskText(r.score, translations) === translations.highRiskText).length > 0 ?
            risks.filter(r => this.getRiskText(r.score, translations) === translations.highRiskText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        const riskHighManual = manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.highRiskText).length > 0 ?
            manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.highRiskText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        const riskMidHigh = risks.filter(r => this.getRiskText(r.score, translations) === translations.midHighRiskText).length > 0 ?
            risks.filter(r => this.getRiskText(r.score, translations) === translations.midHighRiskText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        const riskMidHighManual = manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.midHighRiskText).length > 0 ?
            manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.midHighRiskText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        const riskMedium = risks.filter(r => this.getRiskText(r.score, translations) === translations.mediumRiskText).length > 0 ?
            risks.filter(r => this.getRiskText(r.score, translations) === translations.mediumRiskText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        const riskMediumManual = manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.mediumRiskText).length > 0 ?
            manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.mediumRiskText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        const riskMidLow = risks.filter(r => this.getRiskText(r.score, translations) === translations.midLowRiskText).length > 0 ?
            risks.filter(r => this.getRiskText(r.score, translations) === translations.midLowRiskText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        const riskMidLowManual = manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.midLowRiskText).length > 0 ?
            manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.midLowRiskText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        const riskLow = risks.filter(r => this.getRiskText(r.score, translations) === translations.lowRiksText).length > 0 ?
            risks.filter(r => this.getRiskText(r.score, translations) === translations.lowRiksText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        const riskLowManual = manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.lowRiksText).length > 0 ?
            manualRisks.filter(r => this.getRiskText(r.score, translations) === translations.lowRiksText).map(r => `${r.risk} (${r.score})`).join(', ') : translations.thirdRisksAssesmentSection.noRisk;

        if (imgManualScoresByRiskChart == '') {
            return <Section>{isFullPage: false,
                isAbsolute: true,
                content: [<Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'},underline:<TextUnderline>{color:<ColorHex>{hexCode:'fdc130'},thickness:2}}, posX: 40, posY: 84, isRelative: false},
                          <Content>{content: <Row>{rowContent: [
                                <Content>{content:<Chart>{height:233,width:245, margin: 7,chart: imgSystemScoresByRiskChart, header:<ChartHeader>{height:19, text: translations.thirdRisksAssesmentSection.risklistAssesment, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:0,posY:16, isRelative: true},
                          ]},posX:40, posY:16, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                                <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.detectedRisks, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 16, isRelative: true},
                          ]},posX:40, posY:16, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                                <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.highRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'c80300'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                          ]},posX:40, posY:12, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                                <Content>{content:<Text>{text:riskHigh, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                          ]},posX:40, posY:4, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                                <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.midHighRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'e05b08'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                          ]},posX:40, posY:12, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text:riskMidHigh, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                          ]},posX:40, posY:4, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                                <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.mediumRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'7b410'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                          ]},posX:40, posY:12, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text:riskMedium, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                          ]},posX:40, posY:4, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                                <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.midLowRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'aebe08'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                          ]},posX:40, posY:12, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text:riskMidLow, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                          ]},posX:40, posY:4, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                                <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.lowRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'64c700'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                          ]},posX:40, posY:12, isRelative: true},
                          <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text:riskLow, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                          ]},posX:40, posY:4, isRelative: true},
                ]
            };
        }

        return <Section>{isFullPage: false,
            isAbsolute: true,
            content: [<Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'},underline:<TextUnderline>{color:<ColorHex>{hexCode:'fdc130'},thickness:2}}, posX: 40, posY: 84, isRelative: false},
                      <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Chart>{height:233,width:245, margin: 7,chart: imgSystemScoresByRiskChart, header:<ChartHeader>{height:19, text: translations.thirdRisksAssesmentSection.risklistAssesment, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:0,posY:16, isRelative: true},
                            <Content>{content:<Chart>{height:233,width:245, margin: 7,chart: imgManualScoresByRiskChart, header:<ChartHeader>{height:19, text: translations.thirdRisksAssesmentSection.manualAssesment, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:25,posY:16, isRelative: true},
                      ]},posX:40, posY:16, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.detectedRisks, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 16, isRelative: true},
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.detectedRisks, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 24, posY: 16, isRelative: true},
                      ]},posX:40, posY:16, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.highRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'c80300'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.highRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'c80300'}, maxWidth:245.5}, posX: 24, posY: 12, isRelative: true},
                      ]},posX:40, posY:12, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text:riskHigh, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                            <Content>{content:<Text>{text:riskHighManual, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 24, posY: 4, isRelative: true},
                      ]},posX:40, posY:4, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.midHighRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'e05b08'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.midHighRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'e05b08'}, maxWidth:245.5}, posX: 24, posY: 12, isRelative: true},
                      ]},posX:40, posY:12, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text:riskMidHigh, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                        <Content>{content:<Text>{text:riskMidHighManual, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 24, posY: 4, isRelative: true},
                      ]},posX:40, posY:4, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.mediumRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'7b410'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.mediumRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'7b410'}, maxWidth:245.5}, posX: 24, posY: 12, isRelative: true},
                      ]},posX:40, posY:12, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text:riskMedium, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                        <Content>{content:<Text>{text:riskMediumManual, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 24, posY: 4, isRelative: true},
                      ]},posX:40, posY:4, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.midLowRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'aebe08'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.midLowRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'aebe08'}, maxWidth:245.5}, posX: 24, posY: 12, isRelative: true},
                      ]},posX:40, posY:12, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text:riskMidLow, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                        <Content>{content:<Text>{text:riskMidLowManual, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 24, posY: 4, isRelative: true},
                      ]},posX:40, posY:4, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.lowRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'64c700'}, maxWidth:245.5}, posX: 0, posY: 12, isRelative: true},
                            <Content>{content:<Text>{text: translations.thirdRisksAssesmentSection.lowRisk, font:'Roboto-Bold', fontSize:12, lineHeight:14, fontColor:<ColorHex>{hexCode:'64c700'}, maxWidth:245.5}, posX: 24, posY: 12, isRelative: true},
                      ]},posX:40, posY:12, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text:riskLow, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 0, posY: 4, isRelative: true},
                        <Content>{content:<Text>{text:riskLowManual, font:'Roboto-Regular', fontSize:10, lineHeight:16, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:245.5}, posX: 24, posY: 4, isRelative: true},
                      ]},posX:40, posY:4, isRelative: true},
            ]
        };
    }

    getThirdManualEvaluationDetails(tranlations: any, thirdCampaignDetails: EvaluationQuestionaryDto): Section {
        return <Section>{
            isAbsolute: false,
            isFullPage: false,
            content: [<Content>{content:<Text>{text: tranlations.manualAssesementSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'},underline:<TextUnderline>{color:<ColorHex>{hexCode:'fdc130'},thickness:2}}, posX: 40, posY: 84, isRelative: false},
                      <Content>{content:<Text>{text: tranlations.manualAssesementSection.remarks, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:515, lineHeight:9},  posX:40, posY:120, isRelative: false},
                      <Content>{content:<Text>{text: thirdCampaignDetails.Comment, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:515, lineHeight:12},  posX:40, posY:133, isRelative: false}
            ]
        }
    }

    getDisclaimerSection(evaluationDetails: EvaluationQuestionaryDto, translations: any): Section {
        return <Section>{
            isAbsolute: true,
            isFullPage: false,
            content: [<Content>{content:<Text>{text: translations.disclaimerSection.checklistDigiallySigned, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'},underline:<TextUnderline>{color:<ColorHex>{hexCode:'fdc130'},thickness:2}}, posX: 40, posY: 84, isRelative: false},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: translations.disclaimerSection.digitallySignedBy, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:245.5, lineHeight:9},  posX:0, posY:10, isRelative: true},
                        <Content>{content:<Text>{text: translations.disclaimerSection.signDate, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:245.5, lineHeight:9},  posX:24, posY:10, isRelative: true},
                      ]},posX:40, posY:10, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text:evaluationDetails.SubmitedBy, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:245.5, lineHeight:12},  posX:0, posY:4, isRelative: true},
                        <Content>{content:<Text>{text:formatDate(evaluationDetails.SubmitTS,'dd/MM/yyyy - HH:mm', this.locale), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:245.5, lineHeight:12},  posX:24, posY:4, isRelative: true},
                      ]},posX:40, posY:10, isRelative: true},
                      <Content>{content:<Text>{text: translations.disclaimerSection.disclaimer, font:'Roboto-Light', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:515, lineHeight:9},  posX:40, posY:8, isRelative: true},
            ]
        }
    }

    getRemarkByRiskSection(tranlations: any, evaluationQuestionaryRisk: EvaluationQuestionaryRiskDto[]): Section {
        var section = <Section>{
            isAbsolute: false,
            isFullPage: false,
            content: [<Content>{content:<Text>{text: tranlations.remarksByRiskSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'},underline:<TextUnderline>{color:<ColorHex>{hexCode:'fdc130'},thickness:2}}, posX: 40, posY: 12, isRelative: true}]
        };

        const cont = evaluationQuestionaryRisk.map(r => [
            <Content>{content:<Text>{text: this.translateDescription.transform(r.Risk), font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:515, lineHeight:9},  posX:40, posY:12, isRelative: true},
            <Content>{content:<Text>{text: r.Comment ?? tranlations.remarksByRiskSection.noRemarks, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:515, lineHeight:12},  posX:40, posY:4, isRelative: true}
        ]).reduce((content, r) => content.concat(r), []);
        section.content = section.content.concat(cont);
        return section;
    }

    private getColor(score: number): string {
        switch (true) {
            case (0 <= score && score <= 1):
                return '#64c700';
            case (1 < score && score <= 2):
                return '#aebe08';
            case (2 < score && score <= 3):
                return '#f7b410';
            case (3 < score && score <= 4):
                return '#e05b08';
            case (4 < score):
                return '#c80300';
            default:
                return '#c80300';
        }
    }

    private getRiskText(score: number, translations: any): string {
        switch (true) {
            case (0 <= score && score <= 1):
                return translations.lowRiksText;
            case (1 < score && score <= 2):
                return translations.midLowRiskText;
            case (2 < score && score <= 3):
                return translations.mediumRiskText;
            case (3 < score && score <= 4):
                return translations.midHighRiskText;
            case (4 < score):
                return translations.highRiskText;
            default:
                return translations.highRiskText;
        }
    }
}
