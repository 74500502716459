<fieldset class="u-mb-1medium">
  <legend class="c-form__legend">
    {{ "MemberCheckMemberSearch.information" | translate }}
  </legend>
  <div class="row">
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>
            {{ "MemberCheckMemberSearch.filter.name" | translate }}
          </mat-label>
          <input type="text" matInput name="firstName" value="" id="firstName" [(ngModel)]="model.firstName"
            class="c-form__input" placeholder="{{ 'MemberCheckMemberSearch.filter.name' | translate}}*" required />
          <mat-error>
            {{ "MemberCheckMemberSearch.required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>
            {{ "MemberCheckMemberSearch.filter.middleName" | translate }}
          </mat-label>
          <input type="text" matInput name="middleName" value="" id="middleName" [(ngModel)]="model.middleName"
            class="c-form__input" placeholder="{{ 'MemberCheckMemberSearch.filter.name' | translate}}" />
        </mat-form-field>
      </div>
    </div>
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>
            {{ "MemberCheckMemberSearch.filter.lastname" | translate }}
          </mat-label>
          <input type="text" matInput name="lastname" value="" id="lastname" [(ngModel)]="model.lastname"
            class="c-form__input" placeholder="{{ 'MemberCheckMemberSearch.filter.lastname' | translate}}*" required />
          <mat-error>
            {{ "MemberCheckMemberSearch.required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-3 col-xl-3">
      <div class="c-form__block">
        <div class="c-select">
          <mat-form-field>
            <mat-select placeholder="{{'MemberCheckMemberSearch.filter.gender' | translate}}"
              [(ngModel)]="model.gender">
              <mat-option *ngFor="let gender of genderList" [value]="gender.code">
                {{ gender | translatedescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-6 col-xl-6">
      <legend class="c-form__legend">
        {{ "MemberCheckMemberSearch.birthDate" | translate }}
      </legend>
      <div class="c-form__block">
        <mat-form-field>
          <input matInput [matDatepicker]="picker"
            placeholder="{{'MemberCheckMemberSearch.filter.birthDate' | translate}}" [(ngModel)]="model.dob" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>
            {{ "MemberCheckMemberSearch.filter.address" | translate }}
          </mat-label>
          <input type="text" matInput name="address" value="" id="address" [(ngModel)]="model.address"
            class="c-form__input" placeholder="{{ 'MemberCheckMemberSearch.filter.address' | translate}}" />
        </mat-form-field>
      </div>
    </div>
  </div>
</fieldset>
<fieldset class="u-mb-1medium">
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-3">
      <div class="c-form__block">
        <button (click)="search()" type="button" [disabled]="!model.firstName?.length && !model.lastname?.length" class="c-button u-mr-1">
          {{ "MemberCheckMemberSearch.search" | translate }}
        </button>
      </div>
    </div>
  </div>
</fieldset>
