import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-template',
  templateUrl: './confirm-delete-template.component.html',
  styleUrls:['./confirm-delete-template.component.scss']
})
export class ConfirmDeleteTemplateComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  close(): void {
    this.dialogRef.close(false);
  }

  delete():void{
    this.dialogRef.close(true);
  }
}
