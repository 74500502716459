<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{ 'addThirdPartiesToCampaignDialog.title' | translate }}</h1>
        <button mat-button (click)="onNoClick()" class="c-dialog__close" attr.aria-label="{{ 'addThirdPartiesToCampaignDialog.closeModalWindow' | translate }}">
            <span aria-hidden="true"> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243" transform="translate(-309.86-406.47)"/>
                </svg>
            </span>
          </button>
    </header>
    <div class="c-dialog__content">
        <h2 class="c-dialog__subtitle">
            {{ ('addThirdPartiesToCampaignDialog.subTitle' | translate).replace('${numThirds}', data.CountNewThirdParties) }}
        </h2>
        <div class="c-dialog-info">
            <div class="c-dialog-info__left" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.5 20.484">
          <g transform="translate(-320.13-411.06)">
            <path d="m340.34 419.49l-3.23-2.826v-4.762a.836 .836 0 0 0 -.833 -.836c-.478-.006-1.734-.009-3.731-.009-2.847 0-6.446.007-7.628.009h-.441a.837 .837 0 0 0 -.836 .836v4.762l-3.23 2.826a.837 .837 0 0 0 -.285 .629v9.414a2.011 2.011 0 0 0 2.008 2.008h16.484a2.011 2.011 0 0 0 2.008 -2.008v-9.414a.835 .835 0 0 0 -.286 -.629m-18.11.629l1.41-1.234v2.468m13.469 0v-2.468l1.411 1.234m.433 1.842v6.9l-3.942-3.451m-4.858-1.643a.339 .339 0 0 1 .442 0l6.969 6.1h-14.38m5.869-7.358l-2.044 1.791-1.7-1.484v-10.08h10.125v10.08l-1.7 1.484-2.045-1.791a2.014 2.014 0 0 0 -2.645 0m-3.304 2.9l-3.944 3.453v-6.9"/>
            <path d="M329.556,420.117a2.309,2.309,0,0,1-1.768-.866L326.5,417.9a1.117,1.117,0,0,1-.067-1.393.747.747,0,0,1,1.1-.151.822.822,0,0,1,.074.071l1.321,1.394a.789.789,0,0,0,.6.325.775.775,0,0,0,.614-.29l3-3.382a.758.758,0,0,1,1.16.062,1.118,1.118,0,0,1-.009,1.37l-2.991,3.365A2.291,2.291,0,0,1,329.556,420.117Z"/>
          </g>
        </svg>
            </div>
            <div class="c-dialog-info__right">
                <h3 class="c-dialog__thirdtitle">{{ data.CampaignName }}</h3>
                <div class="c-dialog-data">
                    <div class="c-dialog-data__item">
                        <div class="c-dialog-data__ico" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z"/>
              </svg>
                        </div>
                        <div>
                            <span>
                                {{('addThirdPartiesToCampaignDialog.current' | translate).replace('${numThirds}', data.CountThirdParties) }}
                            </span>
                        </div>
                    </div>
                    <div class="c-dialog-data__item">
                        <div class="c-dialog-data__ico" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path class="shape" d="M15.833,1.667H15V.833a.833.833,0,0,0-1.667,0v.833H6.667V.833A.833.833,0,1,0,5,.833v.833H4.167A4.172,4.172,0,0,0,0,5.833v10A4.172,4.172,0,0,0,4.167,20H15.833A4.172,4.172,0,0,0,20,15.833v-10A4.172,4.172,0,0,0,15.833,1.667ZM1.667,5.833a2.5,2.5,0,0,1,2.5-2.5H15.833a2.5,2.5,0,0,1,2.5,2.5v.833H1.667Zm14.167,12.5H4.167a2.5,2.5,0,0,1-2.5-2.5v-7.5H18.333v7.5A2.5,2.5,0,0,1,15.833,18.333Z"/>
                <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 11.25)"/>
                <circle cx="1.25" cy="1.25" r="1.25" transform="translate(4.583 11.25)"/>
                <circle cx="1.25" cy="1.25" r="1.25" transform="translate(12.917 11.25)"/>
              </svg>
                        </div>
                        <span>{{ dateTimeStart }}</span> h</div>
                </div>
                <div class="c-dialog__template">
                    <span class="c-dialog-data__ico" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path class="shape" d="M3.333,5.462a2.384,2.384,0,0,1-1.768-.8L.278,3.412A.97.97,0,0,1,.211,2.126a.786.786,0,0,1,1.177-.074L2.709,3.339a.806.806,0,0,0,.6.3.8.8,0,0,0,.614-.268l3-3.122A.784.784,0,0,1,8.083.306a.971.971,0,0,1-.009,1.265L5.083,4.678A2.368,2.368,0,0,1,3.333,5.462ZM20,3.642a.874.874,0,0,0-.833-.91H10.833a.874.874,0,0,0-.833.91.874.874,0,0,0,.833.91h8.333A.874.874,0,0,0,20,3.642ZM5.083,11.958,8.074,8.851a.962.962,0,0,0,.258-.884A.88.88,0,0,0,7.746,7.3a.786.786,0,0,0-.821.238l-3,3.122a.8.8,0,0,1-1.178,0L1.422,9.213a.784.784,0,0,0-1.178,0,.97.97,0,0,0,0,1.287l1.321,1.442a2.353,2.353,0,0,0,3.522.015ZM20,10.923a.874.874,0,0,0-.833-.91H10.833a.914.914,0,0,0,0,1.82h8.333A.874.874,0,0,0,20,10.923ZM5.083,19.239,8.07,16.132a.962.962,0,0,0,.258-.884.88.88,0,0,0-.585-.672.786.786,0,0,0-.821.238l-3,3.122a.8.8,0,0,1-.614.268.806.806,0,0,1-.6-.3L1.388,16.613a.786.786,0,0,0-1.177.074.97.97,0,0,0,.067,1.286l1.287,1.25a2.353,2.353,0,0,0,3.522.015ZM20,18.2a.874.874,0,0,0-.833-.91H10.833a.914.914,0,0,0,0,1.82h8.333A.874.874,0,0,0,20,18.2Z" transform="translate(.0001-.023)"/>
            </svg>
          </span>
                    <p>{{ data.QuestionaryName }}</p>
                </div>
            </div>
        </div>
    </div>
    <footer class="c-dialog__footer">
        <button type="button" [mat-dialog-close]="false" class="c-button c-button--light">
            {{ 'addThirdPartiesToCampaignDialog.checkThirds' | translate }}
        </button>
        <button type="submit" [mat-dialog-close]="true" routerLinkActive="is-active" class="c-button c-button--green">
            {{ 'addThirdPartiesToCampaignDialog.acceptAndSend' | translate }}
        </button>
    </footer>
</div>
