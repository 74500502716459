import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryResult } from '@app/models/common/queryResult';
import { EquifaxWSCandidate } from '@app/modules/screening-hub/models/EquifaxWS/EquifaxWSCandidate';
import { ScreeningProvider } from '@app/modules/screening-hub/models/ScreeningProvider';
import { CreateResolutionComponent } from '@app/modules/screening-hub/resolutions/create-resolution/create-resolution.component';
import { EquifaxWSScoringService } from '@app/modules/screening-hub/services/equifax-ws-scoring.service';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'equifax-ws-scoring-candidates-list',
  templateUrl: './scoring-candidates-list.component.html',
  styleUrls: ['./scoring-candidates-list.component.scss'],
})
export class ScoringCandidatesListEquifaxWSComponent implements OnInit {

  displayedColumns: string[] = [
    'name',
    'matchStrength',
    'actions',
  ];
  queryResult: QueryResult<EquifaxWSCandidate> = { items: [], totalItems: 0 };

  thirdPartyId: number;
  candidates: EquifaxWSCandidate[] = [];
  result: string = '';
  //name: string;
  cedula: string;
  searchType: number;
  translations: any;

  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private toaster: Toaster,
    private activatedRoute: ActivatedRoute,
    private equifaxWSService: EquifaxWSScoringService,
    private router: Router) {
    this.result =
      this.router.getCurrentNavigation().extras.state.result;

    this.activatedRoute.params.subscribe((params) => {
      this.thirdPartyId = params.id;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.cedula = params.cedula;
      this.searchType = params.searchType;
    });

    this.translateService.get("ThirdPartyCandidateTable").subscribe(t => this.translations = t);
  }

  ngOnInit(): void {
    if (this.result === 'OK') {
      this.equifaxWSService.candidateDetails(this.cedula, this.searchType).subscribe(r => {
        const candidate = <EquifaxWSCandidate>{
          cedula: r.cedula,
          name: r.name
        }
        this.candidates = [candidate];

        this.getCandidates();
      })
    }
  }

  getCandidates() {
    this.queryResult.items = this.candidates
      .slice(
        (this.query.Page - 1) * this.query.PageSize,
        this.query.Page * this.query.PageSize
      );
    this.queryResult.totalItems = this.candidates.length;
  }

  sortBy(columnName) {
    this.query.Page = 1;
    this.query.SortBy = columnName.active;
    this.query.IsSortAscending = columnName.direction === 'asc';
    this.getCandidates();
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.queryResult.items = this.candidates.slice(
      (this.query.Page - 1) * this.query.PageSize,
      this.query.Page * this.query.PageSize
    );
  }


  back() {
    if (this.thirdPartyId) {
      this.router.navigate(['scoring-candidates', this.thirdPartyId]);
    } else {
      this.router.navigate(['scoring-candidates']);
    }
  }

  viewDetails(cedula: string) {
    if (!cedula) return;

    if (this.thirdPartyId) {
      this.router.navigate(
        ['equifaxws/scoring-candidate-details', cedula, this.searchType, this.thirdPartyId]
      );
    } else {
      this.router.navigate(
        ['equifaxws/scoring-candidate-details', cedula, this.searchType]
      );
    }
  }

  createResolution() {
    const dialogRef = this.dialog.open(CreateResolutionComponent, {
      data: {
        name: this.cedula,
        software: ScreeningProvider.EquifaxWS,
        data: JSON.stringify(this.candidates),
        totalResults: this.candidates.length,
        searchType: this.searchType,
        thirdPartyId: this.thirdPartyId
      }
    });
    dialogRef.afterClosed().subscribe((r: boolean) => {
      if (r != null || r != undefined) {
        if (r) {
          this.toaster.open({ text: this.translations.resolutionCreated, duration: 6000, type: 'success' });
        } else {
          this.toaster.open({ text: this.translations.resolutionCreationError, duration: 6000, type: 'danger' });
        }
      }
    })
  }
}
