import { MemberCheckLink } from "@app/modules/screening-hub/models/member-check/member-check-link";
import { MemberCheckLinkDto } from "src/proxy/model/memberCheckLinkDto";

export function mapMemberCheckLink(dto: MemberCheckLinkDto): MemberCheckLink {
  if (!dto) return null;

  return <MemberCheckLink>{
    id: dto.Id,
    name: dto.Name,
    memberCheckId: dto.MemberCheckId,
    location: dto.Location,
    dob: dto.Dob,
    entityType: dto.EntityType,
    creationTS: dto.CreationTS
  };
}
