import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AwsS3Service {

    constructor(private http: HttpClient, 
        private backendHandler: HttpBackend) { 
      this.http = new HttpClient(backendHandler);  
    }

    public uploadFileToS3(fileUri: string, file: any) {        
        return this.http.put(fileUri, file);
    }

    downloadFileFromS3(presignedUrl: string): Observable<any> {
        return this.http.get(presignedUrl,{responseType: 'arraybuffer'});
    }

}
