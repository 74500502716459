import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';
import { RemoteService } from '@app/services/remote.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  userName:string;
  companyLogo: string;
  imageloaded: boolean = false;
  logoUri: string;
  today: any;
  locale: string;
  private subscription: Subscription;

  constructor(private auth: AuthenticationService,
    private translate: TranslateService,
    private remoteService: RemoteService
    ) { }

  ngOnInit(): void {
    this.locale = this.translate.currentLang.split("-")[0];
    this.subscription = this.translate.onLangChange
      .subscribe((langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang.split("-")[0];
      })

    this.today = new Date();
    this.userName = this.auth.getFieldJWT('Name');
    this.companyLogo = this.auth.getFieldJWT('Logo');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadCompanyLogo() {
    if (!this.imageloaded) {
      this.remoteService.getTokenFileUri(this.companyLogo).subscribe(res => this.logoUri = res.url);
      this.imageloaded = true;
    }

    return this.logoUri;
  }
}
