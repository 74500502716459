<equifax-ws-individual-basic-data [scoringDetails]="scoringDetails?.individualData"></equifax-ws-individual-basic-data>
<mat-divider></mat-divider>
<equifax-ws-individual-marital-status
  [individualDetails]="scoringDetails?.individualData"></equifax-ws-individual-marital-status>
<mat-divider></mat-divider>
<equifax-ws-individual-working-info
  [individualDetails]="scoringDetails?.individualData"></equifax-ws-individual-working-info>
<mat-divider></mat-divider>
<equifax-ws-address-list [addressList]="scoringDetails?.individualData?.addresses"></equifax-ws-address-list>
<mat-divider></mat-divider>
<equifax-ws-telephone-list [telephoneList]="scoringDetails?.individualData?.telephones"></equifax-ws-telephone-list>
<mat-divider></mat-divider>
<equifax-ws-properties [individualDetails]="scoringDetails?.individualData"></equifax-ws-properties>
<mat-divider></mat-divider>
<equifax-ws-credit-data [individualDetails]="scoringDetails?.individualData"></equifax-ws-credit-data>
