import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { ResolutionReasonEnum } from "@app/models/scoring-tools/resolutionReasonEnum";
import { ResolutionRecord } from "@app/models/scoring-tools/resolutionRecord";
import { ScoringCandidate } from "@app/models/scoring-tools/scoringCandidate";
import { ScoringToolSearchTypeEnum } from "@app/models/scoring-tools/scoringToolSearchTypeEnum";
import { TranslateService } from "@ngx-translate/core";
import { UsersProxyClient } from "src/proxy/apiUser/users.proxy";
import { ColorHex } from "../pdf-template-generator/Models/colorHex";
import { Content } from "../pdf-template-generator/Models/content";
import { CoverPage } from "../pdf-template-generator/Models/coverPage";
import { DocumentContent } from "../pdf-template-generator/Models/documentContent";
import { Font } from "../pdf-template-generator/Models/font";
import { Footer } from "../pdf-template-generator/Models/footer";
import { Header } from "../pdf-template-generator/Models/header";
import { Image } from "../pdf-template-generator/Models/image";
import { Row } from "../pdf-template-generator/Models/row";
import { Section } from "../pdf-template-generator/Models/section";
import { SvgPath } from "../pdf-template-generator/Models/svgPath";
import { Text } from "../pdf-template-generator/Models/text";
import { templateGenerator } from "../pdf-template-generator/template-generator";
import { ScreeningProvider, mapProviderName } from "@app/modules/screening-hub/models/ScreeningProvider";
import { AMLUpdateCandidate } from "@app/modules/screening-hub/models/AMLUpdateCandidate";

@Injectable()
export class ResolutionReportService {

  locale: string;

  constructor(private translate: TranslateService,
    private userClient: UsersProxyClient) { }

  async getReport(name: string, resolutionRecord: ResolutionRecord): Promise<[string, Uint8Array]> {
    this.locale = this.translate.currentLang.split("-")[0];
    let translations = await this.translate.get("resolutionReport").toPromise();
    let providerNameTranslations = await this.translate.get("providerNames").toPromise();
    const userDetails = await this.userClient.usersGetUserDataGet().toPromise();
    const username = `${userDetails.Name} ${userDetails.SurName}`;
    const printDate = new Date();
    const fonts = await this.getReportFonts();
    const coverPage = await this.getReportCoverPage(name, userDetails.Client.Name, resolutionRecord.software, resolutionRecord.creationDate, printDate, translations, providerNameTranslations);
    const pageHeader = await this.getPageHeader(translations);
    const pageFooter = this.getPageFooter(name, printDate, username, translations);
    const basicInfoSection = this.getBasicInfoSection(printDate, name, resolutionRecord, translations);
    const resolutionSection = this.getResolutionSection(resolutionRecord, translations);
    const commentSection = this.getCommentSection(resolutionRecord, translations);

    const docContent = <DocumentContent>{
      header: pageHeader,
      footer: pageFooter,
      sections: [basicInfoSection, resolutionSection, commentSection]
    }

    if (resolutionRecord.software === ScreeningProvider.Refinitiv) {
      docContent.sections.push(this.getRefinitivResults(resolutionRecord));
      docContent.sections.push(this.getDisclaimerSection());
    }

    if (resolutionRecord.software === ScreeningProvider.AMLUpdate) {
      docContent.sections.push(this.getAMLUpdateResults(resolutionRecord));
    }

    return [`Riskallay-resolution-report-${userDetails.Client.Name}_${formatDate(resolutionRecord.creationDate, 'ddMMyyyy - HHmm', this.locale)}.pdf`, await templateGenerator(fonts, docContent, coverPage)];

  }

  async getReportFonts(): Promise<Font[]> {
    let fonts: Font[] = [];
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Medium.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Medium' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Regular.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Regular' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Bold.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Bold' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Poppins-SemiBold.ttf').then((res) => res.arrayBuffer()), name: 'Poppins-SemiBold' });

    return fonts;
  }

  async getReportCoverPage(name: string, companyName: string, software: ScreeningProvider, resolutionDate: Date, printDate: Date, translations: any, providerNameTranslations: { [key:string]: string }): Promise<CoverPage> {
    let coverContent = <CoverPage>{
      content: [
        <Content>{ content: <Image>{ image: await fetch('/assets/report/background.png').then((res) => res.arrayBuffer()), height: 842, width: 595, customHeight: 1.73 }, posX: 0, posY: 0, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/Riskboard03.png').then((res) => res.arrayBuffer()), height: 398, width: 515 }, posX: 40, posY: 24, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/wave.png').then((res) => res.arrayBuffer()), height: 842, width: 595 }, posX: 0, posY: 419, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height: 42, width: 180 }, posX: 40, posY: 454, isRelative: false },
        <Content>{ content: <Text>{ text: translations.cover.report, font: 'Roboto-Medium', fontSize: 24, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 536, isRelative: false },
        <Content>{ content: <Text>{ text: name, font: 'Poppins-SemiBold', fontSize: 40, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 585, isRelative: false },
        <Content>{
          content: <Text>{
            text: translations.cover.intro.replace('@Name', name).replace('@Software', mapProviderName(software, providerNameTranslations)), font: 'Roboto-Regular',
            fontSize: 10, fontColor: <ColorHex>{ hexCode: '666666' }, lineHeight: 16, maxWidth: 335
          }, posX: 40, posY: 660, isRelative: false
        },
        <Content>{ content: <Text>{ text: translations.reportBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 660, isRelative: false },
        <Content>{ content: <Text>{ text: companyName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 670, isRelative: false },
        <Content>{ content: <Text>{ text: translations.resolutionDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 690, isRelative: false },
        <Content>{ content: <Text>{ text: formatDate(resolutionDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 700, isRelative: false },
        <Content>{ content: <Text>{ text: translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 720, isRelative: false },
        <Content>{ content: <Text>{ text: formatDate(printDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 730, isRelative: false },
      ]
    };

    if (software === ScreeningProvider.Refinitiv) {
      coverContent.content.push(
        <Content>{ content: <Image>{ image: await fetch('/assets/report/poweredby.png').then((res) => res.arrayBuffer()), height: 16, width: 133 }, posX: 40, posY: 759, isRelative: false }
      );
    }

    return coverContent;
  }

  async getPageHeader(translations: any): Promise<Header> {
    return <Header>{
      height: 67,
      content: [<Content>{ content: <Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height: 31, width: 133 }, posX: 40, posY: 24, isRelative: false },
      <Content>{ content: <Text>{ text: translations.confidential, font: 'Roboto-Medium', fontSize: 10, fontColor: <ColorHex>{ hexCode: 'f8b410' }, lineHeight: 16 }, posX: 474, posY: 31.5, isRelative: false }
      ]
    };
  }

  getPageFooter(name: string, printDate: Date, username: string, translations: any): Footer {
    return <Footer>{
      height: 71,
      content: [<Content>{ content: <SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height: 1, width: 515, color: <ColorHex>{ hexCode: 'd9d9d9' } }, posX: 40, posY: 771, isRelative: false },
      <Content>{ content: <Text>{ text: translations.searchedName, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 783, isRelative: false },
      <Content>{ content: <Text>{ text: translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 796, isRelative: false },
      <Content>{ content: <Text>{ text: translations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 809, isRelative: false },
      <Content>{ content: <Text>{ text: name, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 124, posY: 783, isRelative: false },
      <Content>{ content: <Text>{ text: formatDate(printDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 124, posY: 796, isRelative: false },
      <Content>{ content: <Text>{ text: username, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 124, posY: 809, isRelative: false },
      <Content>{ content: <Text>{ text: translations.pageNumber, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, lineHeight: 16 }, posX: 513, posY: 783, isRelative: false },
      ]
    };
  }

  getBasicInfoSection(printDate: Date, name: string, resolutionRecord: ResolutionRecord, tranlations: any): Section {
    return <Section>{
      isFullPage: false,
      isAbsolute: true,
      content: [
        <Content>{ content: <Text>{ text: tranlations.basicInfo.title, font: 'Roboto-Medium', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 84, isRelative: false },
        <Content>{ content: <Text>{ text: tranlations.basicInfo.name, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 116, isRelative: false },
        <Content>{ content: <Text>{ text: name, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 129, isRelative: false },
        <Content>{ content: <Text>{ text: tranlations.basicInfo.creationDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 116, isRelative: false },
        <Content>{ content: <Text>{ text: formatDate(resolutionRecord.creationDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 129, isRelative: false },
        <Content>{ content: <Text>{ text: tranlations.basicInfo.createdBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 400, posY: 116, isRelative: false },
        <Content>{ content: <Text>{ text: `${resolutionRecord.user.name} ${resolutionRecord.user.surName}`, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 129, isRelative: false },
        <Content>{ content: <Text>{ text: tranlations.basicInfo.searchType, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 40, posY: 165, isRelative: false },
        <Content>{ content: <Text>{ text: this.getSearchTypeText(resolutionRecord.searchType, tranlations), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 178, isRelative: false },
        <Content>{ content: <Text>{ text: tranlations.basicInfo.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 220, posY: 165, isRelative: false },
        <Content>{ content: <Text>{ text: formatDate(printDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 178, isRelative: false },
        <Content>{ content: <SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height: 1, color: <ColorHex>{ hexCode: 'd9d9d9' }, width: 515 }, posX: 40, posY: 202, isRelative: false },
      ]
    }
  }

  getResolutionSection(resolutionRecord: ResolutionRecord, tranlations: any): Section {
    return <Section>{
      isFullPage: false,
      isAbsolute: true,
      content: [
        <Content>{ content: <Text>{ text: tranlations.resolution.title, font: 'Roboto-Medium', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 215, isRelative: false },
        <Content>{ content: <Text>{ text: tranlations.resolution.reason, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 247, isRelative: false },
        <Content>{ content: <Text>{ text: this.getReasonText(resolutionRecord.reason, tranlations), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 260, isRelative: false },
        <Content>{ content: <Text>{ text: resolutionRecord.reasonExplanation, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' } }, posX: 40, posY: 288, isRelative: false },
        <Content>{ content: <SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height: 1, color: <ColorHex>{ hexCode: 'd9d9d9' }, width: 515 }, posX: 40, posY: 12, isRelative: true }
      ]
    }
  }

  getCommentSection(resolutionRecord: ResolutionRecord, tranlations: any): Section {
    return <Section>{
      isFullPage: false,
      isAbsolute: true,
      content: [
        <Content>{ content: <Text>{ text: tranlations.comment, font: 'Roboto-Bold', fontSize: 12, lineHeight: 14, fontColor: <ColorHex>{ hexCode: '383838' } }, posX: 40, posY: 12, isRelative: true },
        <Content>{ content: <Text>{ text: resolutionRecord.comment, font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' } }, posX: 40, posY: 12, isRelative: true },
        <Content>{ content: <SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height: 1, color: <ColorHex>{ hexCode: 'd9d9d9' }, width: 515 }, posX: 40, posY: 12, isRelative: true }
      ]
    }
  }

  getRefinitivResults(resolutionRecord: ResolutionRecord): Section {

    const refinitivResults: ScoringCandidate[] = JSON.parse(resolutionRecord.data);
    let content: Content[] = []

    if (refinitivResults.length == 0) {
      return <Section>{
        isAbsolute: false,
        isFullPage: false,
        content: content
      }
    }

    content.push(<Content>{ content: <Text>{ text: 'World-Check Matches', font: 'Roboto-Bold', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 12, isRelative: true });

    refinitivResults.forEach(result => {
      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: 'Name', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 0, posY: 16, isRelative: true },
            <Content>{ content: <Text>{ text: 'Matched Alias', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 24, posY: 16, isRelative: true },
            <Content>{ content: <Text>{ text: 'Match strength', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 24, posY: 16, isRelative: true },
          ]
        }, posX: 40, posY: 16, isRelative: true
      });

      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: result.name, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 0, posY: 4, isRelative: true },
            <Content>{ content: <Text>{ text: result.matchedAlias, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 24, posY: 4, isRelative: true },
            <Content>{ content: <Text>{ text: result.matchStrength, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 24, posY: 4, isRelative: true },
          ]
        }, posX: 40, posY: 4, isRelative: true
      });

      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: 'World-Check ID', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 0, posY: 16, isRelative: true },
            <Content>{ content: <Text>{ text: 'Registered Country', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 24, posY: 16, isRelative: true },
            <Content>{ content: <Text>{ text: 'Category', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 24, posY: 16, isRelative: true },
          ]
        }, posX: 40, posY: 16, isRelative: true
      });

      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: result.referenceId, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 0, posY: 4, isRelative: true },
            <Content>{ content: <Text>{ text: '', font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 24, posY: 4, isRelative: true },
            <Content>{ content: <Text>{ text: result.category, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 24, posY: 4, isRelative: true },
          ]
        }, posX: 40, posY: 4, isRelative: true
      });

      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: 'Data set', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 0, posY: 16, isRelative: true }
          ]
        }, posX: 40, posY: 16, isRelative: true
      });

      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: result.thirdPartyRisks.join(', '), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 12 }, posX: 0, posY: 4, isRelative: true },
          ]
        }, posX: 40, posY: 4, isRelative: true
      });

      content.push(<Content>{ content: <SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height: 1, color: <ColorHex>{ hexCode: 'd9d9d9' }, width: 515 }, posX: 40, posY: 12, isRelative: true })
    });

    return <Section>{
      isAbsolute: false,
      isFullPage: false,
      content: content
    }
  }

  getAMLUpdateResults(resolutionRecord: ResolutionRecord): Section {

    const refinitivResults: AMLUpdateCandidate[] = JSON.parse(resolutionRecord.data);
    let content: Content[] = []

    if (refinitivResults.length == 0) {
      return <Section>{
        isAbsolute: false,
        isFullPage: false,
        content: content
      }
    }

    content.push(<Content>{ content: <Text>{ text: 'Matches', font: 'Roboto-Bold', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 12, isRelative: true });

    refinitivResults.forEach(result => {
      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: 'Name', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 0, posY: 16, isRelative: true },
            <Content>{ content: <Text>{ text: 'RUT', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 24, posY: 16, isRelative: true },
            <Content>{ content: <Text>{ text: 'Match strength', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 24, posY: 16, isRelative: true },
          ]
        }, posX: 40, posY: 16, isRelative: true
      });

      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: result.fullName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 0, posY: 4, isRelative: true },
            <Content>{ content: <Text>{ text: result.rut, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 24, posY: 4, isRelative: true },
            <Content>{ content: <Text>{ text: `${result.matchStrength}%`, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 24, posY: 4, isRelative: true },
          ]
        }, posX: 40, posY: 4, isRelative: true
      });

      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: 'Mandatory lists', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 0, posY: 16, isRelative: true },
            <Content>{ content: <Text>{ text: 'KYC', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 24, posY: 16, isRelative: true },
            <Content>{ content: <Text>{ text: 'UBO', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 24, posY: 16, isRelative: true },
          ]
        }, posX: 40, posY: 16, isRelative: true
      });

      content.push(<Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: `${result.mandatoryLists.numberOfLists}`, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 0, posY: 4, isRelative: true },
            <Content>{ content: <Text>{ text: `${result.kyc.numberOfLists}`, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 24, posY: 4, isRelative: true },
            <Content>{ content: <Text>{ text: `${result.ubo.numberOfLists}`, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 24, posY: 4, isRelative: true },
          ]
        }, posX: 40, posY: 4, isRelative: true
      });

      content.push(<Content>{ content: <SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height: 1, color: <ColorHex>{ hexCode: 'd9d9d9' }, width: 515 }, posX: 40, posY: 12, isRelative: true })
    });

    return <Section>{
      isAbsolute: false,
      isFullPage: false,
      content: content
    }
  }

  getDisclaimerSection(): Section {
    const line1 = "The contents of this record are private and confidential and should not be disclosed to third parties unless: (i) the terms of your agreement with Riskallay allow you to do so; (ii) the record subject requests any data that you may hold on them, and such data includes their World-Check record; or (iii) you are under some other legal obligation to do so. You must consider and abide by your own obligations in relation to the data privacy rights of individuals and must notify them of your intention to search against World-Check and provide them with information contained in the World-Check privacy statement :https://www.refinitiv.com/en/products/world-check-kyc-screening/privacy-statement.";
    const line2 = `You shall not rely upon the content of this report without making independent checks to verify the information contained therein. Information correlated is necessarily brief and should be read by you in the context of the fuller details available in the external sources to which links are provided. The accuracy of the information found in the underlying sources must be verified with the record subject before any action is taken and you should inform us if any links to the sources are broken. If this record contains negative allegations, it should be assumed that such allegations are denied by the subject. You should not draw any negative inferences about individuals or entities merely because they are identified in the database, nor because they are shown as """"Reported being linked to"""" others identified in the database. The nature of linking varies considerably. Many persons are included solely because they hold or have held prominent political positions or are connected to such individuals.`;
    return <Section>{
      isAbsolute: false,
      isFullPage: false,
      content: [<Content>{ content: <Text>{ text: 'Refinitiv World-Check Legal Notice :', font: 'Roboto-Regular', fontSize: 6, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 6, maxWidth: 515 }, posX: 40, posY: 16, isRelative: true },
      <Content>{ content: <Text>{ text: line1, font: 'Roboto-Regular', fontSize: 6, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 6, maxWidth: 515 }, posX: 40, posY: 0, isRelative: true },
      <Content>{ content: <Text>{ text: line2, font: 'Roboto-Regular', fontSize: 6, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 6, maxWidth: 515 }, posX: 40, posY: 0, isRelative: true }]
    }
  }

  private getSearchTypeText(searchType: ScoringToolSearchTypeEnum, tranlations: any): string {
    switch (searchType) {
      case ScoringToolSearchTypeEnum.Organization:
        return tranlations.searchType.organization;
      case ScoringToolSearchTypeEnum.Individual:
        return tranlations.searchType.individual;
      case ScoringToolSearchTypeEnum.All:
        return tranlations.searchType.all;
      default:
        return '';
    }
  }

  private getReasonText(resolutionReason: ResolutionReasonEnum, tranlations: any): string {
    switch (resolutionReason) {
      case ResolutionReasonEnum.NoMatches:
        return tranlations.reason.noMatch;
      case ResolutionReasonEnum.NoResults:
        return tranlations.reason.noResults;
      default:
        return '';
    }
  }
}
