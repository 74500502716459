<div class="o-wrapper">
    <main id="content">
        <div class="c-head-section c-head-section--tab c-head-section--bg-cream bg-cream-dark">
            <!--parte superior-->
            <div class="c-head-section--tab__top">
                <div class="c-head-section__left">
                    <div class="c-head-section--tab__info">
                        <h2 class="c-head-section--tab__title">{{'ConfiguracionEmpresa.title.valor'|translate}} - {{userData?userData.name:''}}</h2>
                    </div>
                </div>
            </div>
            <!--parte inferior-->
            <div class="c-head-section--tab__bottom">
                <mat-tab-group class="c-tabs">
                    <!--información visible-->
                    <mat-tab *appAuthRole="['Owner']" label="{{'ConfiguracionEmpresa.visinfo.valor'|translate}}">
                        <div class="o-scroll">
                            <div *ngIf="loaded" class="c-form c-form--space">
                                <form id="form-infoVisible" [formGroup]="form" class="c-form__form">
                                    <button [disabled]="!loaded || !form.valid" type="button" class="c-button u-align-right " (click)="updateProfileData()">{{'ConfiguracionEmpresa.button.save.valor' | translate }}</button>
                                    <!--datos perfil-->
                                    <fieldset class="u-mb-1medium">
                                        <legend class="c-form__legend">{{'ConfiguracionEmpresa.profiledata.valor'|translate}}</legend>
                                        <div class="row">
                                            <div class="col-12 col-lg-6 col-xl-3">
                                                <div class="c-form__block">
                                                    <!--nombre-->
                                                    <mat-form-field>
                                                        <input type="text" matInput formControlName="Name" class="c-form__input" placeholder="{{'ConfiguracionEmpresa.name.valor'|translate}}" required />
                                                        <mat-error>
                                                            {{'ConfiguracionEmpresa.required.valor'|translate}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <!--personalizar-->
                                    <fieldset class="u-mb-1medium">
                                        <legend class="c-form__legend">{{'ConfiguracionEmpresa.companyLogo'|translate}}</legend>
                                        <p class="o-text">{{'ConfiguracionEmpresa.logoRecomendation'|translate}}</p>
                                        <div class="c-form-upload c-form-upload--image" aria-labelledby="uploadTitle">
                                            <div class="c-form-upload--image__left">
                                                <img [src]="selectedIconFileUri ? loadIconImage() : '/assets/home-empty.png'" />
                                            </div>
                                            <div class="c-form-upload--image__right">
                                                <p class="c-form-upload--image__title">{{'ConfiguracionEmpresa.companyLogo'|translate}}</p>
                                                <app-file-upload formControlName="Icon" [buttonText]="uploadButtonText" [qid]="null" (fileSelected)="selectIcon($event)"></app-file-upload>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </mat-tab>
                    <!--datos de la empresa-->
                    <mat-tab label="{{'ConfiguracionEmpresa.companydata.valor'|translate}}">
                        <app-onboarding-card [showProgress]="false" [hideOnboardingNavbar]="true"></app-onboarding-card>
                    </mat-tab>
                    <!--facturación-->
                    <mat-tab *appAuthRole="['Owner']" label="{{'ConfiguracionEmpresa.consumption'|translate}}">
                        <app-consumption-usage></app-consumption-usage>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </main>
</div>
