import { Component, Input } from "@angular/core";
import { WatchListInfo } from "@app/modules/screening-hub/models/GesintelScreening/WatchListInfo";

@Component({
  selector: 'aml-update-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.scss'],
})
export class AMLUpdateWatchlistComponent {
  @Input() watchListInfo: WatchListInfo;

  constructor() { }
}
