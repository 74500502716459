import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EquifaxWSLink } from '../../models/EquifaxWS/EquifaxWSLink';

@Component({
  selector: 'equifax-ws-confirm-refresh-screening',
  templateUrl: './equifax-ws-confirm-refresh-screening.component.html',
  styleUrls: ['./equifax-ws-confirm-refresh-screening.component.scss'],
})
export class EquifaxWSConfirmRefreshScreeningComponent {
  constructor(
    public dialogRef: MatDialogRef<EquifaxWSConfirmRefreshScreeningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EquifaxWSLink
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
