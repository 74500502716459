import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ResolutionLink } from "@app/models/scoring-tools/resolutionLink";
import { ResolutionRecord } from "@app/models/scoring-tools/resolutionRecord";
import { ScoringToolsService } from "@app/services/scoring-tools.service";
import { ConfirmDeleteResolutionLinkComponent } from "../confirm-delete-resolution-link/confirm-delete-resolution-link.component";

@Component({
    selector: 'app-resolution-link',
    templateUrl: './resolution-link.component.html',
    styleUrls: ['./resolution-link.component.scss']
})
export class ResolutionLinkComponent implements OnInit {
    @Input() resolutionLink: ResolutionLink;
    @Input() thirdPartyId?: number;
    @Output() refreshResolution = new EventEmitter<ResolutionLink>();

    resolutionRecords: ResolutionRecord[];

    constructor(
        private scoringToolsService: ScoringToolsService,
        private router: Router,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.scoringToolsService.getResolutionRecords(this.resolutionLink.id).subscribe(r => this.resolutionRecords = r);
    }

    repeatSearch() {
        if (this.thirdPartyId) {
            this.router.navigate(['scoring-candidates', this.thirdPartyId], { queryParams: { name: this.resolutionLink.name } });
        } else {
            this.router.navigate(['scoring-candidates'], { queryParams: { name: this.resolutionLink.name } });
        }
    }

    openDialogDeleteResolution() {
        const dialogRef = this.dialog.open(ConfirmDeleteResolutionLinkComponent);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.scoringToolsService.deleteResolutionLink(this.resolutionLink.id).subscribe(res => {
                    if (res) {
                        this.refreshResolution.emit(this.resolutionLink);
                    }
                });
            }
        });
    }
}