import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ThirdPartyCreateDTO } from '@app/models/ThirdParty';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { CommonsService } from '@app/services/commons-service';
import { SectorDTO } from '@app/models/Sector';
import { CountryDTO } from '@app/models/Country';
import { Language, LanguageValues } from '@app/models/Language';
import { parsearErroresAPI } from '@app/utilities/utilities';
import { ThirdPartyTypeDTO } from '@app/models/ThirdPartyType';
import { Toaster } from 'ngx-toast-notifications';
import { ResponsiblesDTO } from '@app/models/Responsibles';
import { NatureOfActivityDTO } from '@app/models/NatureOfActivity';
import { TypeOfActivityDTO } from '@app/models/TypeOfActivity';
import { GlobalService } from "@app/services/global.service";
import { Location } from '@angular/common'
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogRequestThirdPartiesComponent } from '../dialog-request-more-third-parties/dialog-request-more-third-parties.component';
import { FormularioThirdComponent } from '../formulario-third/formulario-third.component';

@Component({
  selector: 'app-create-third',
  templateUrl: './create-third.component.html'
})
export class CreateThirdComponent implements OnInit {

  @ViewChild(FormularioThirdComponent) formularioThirdComponent: FormularioThirdComponent;

  errores: string[] = [];
  isFormValid = false;

  languagesNoSelected: Language[] = [];
  countriesNoSelected: Array<CountryDTO> = [];
  sectorsNoSelected: Array<SectorDTO> = [];
  thirdPartyTypeNoSelected: Array<ThirdPartyTypeDTO> = [];
  responsiblesNoSelected: Array<ResponsiblesDTO> = [];
  natureOfActivityNoSelected: Array<NatureOfActivityDTO> = [];
  typeOfActivityNoSelected: Array<TypeOfActivityDTO> = [];
  translations: any;

  isLoading = false;

  constructor(
    private router: Router,
    private thirdPartiesService: ThirdPartiesService,
    private commonsService: CommonsService,
    private toaster: Toaster,
    private globalService: GlobalService,
    private dialog: MatDialog,
    private location: Location,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getSectors();
    this.getContactLenguages();
    this.getCountries();
    this.getThirdPartyTypes();
    this.getResponsibles();
    this.getNatureOfActivity();
    this.getTypeOfActivity();
    this.globalService.activeThird();
    this.translateService.get("NuevoThird").subscribe(t => this.translations = t);
  }



  saveChanges(newThird: ThirdPartyCreateDTO) {
    if(!this.isLoading){
      this.isLoading = true;
      this.thirdPartiesService.createThirdParties(newThird).then(response => {
        if (response > 0) {
          this.router.navigate(['/busqueda']);
          this.toaster.open({ text: this.translations.success, duration: 6000, type: 'info' });
        } else {

          if (response == 0) {
            this.toaster.open({ text: this.translations.alreadyExists, duration: 6000, type: 'info' });
          } else {
            this.openRequestDialog();
          }
        }
        (error) => this.errores = parsearErroresAPI(response);
      }).catch(error => {
        this.errores = parsearErroresAPI(error);
      }).finally(() => this.isLoading = false);
    }
  }

  public formValidityHandler(isFormValid: boolean) {
    this.isFormValid = isFormValid;
  }

  public getResponsibles() {
    this.commonsService.getResponsibles().then(response => {
      this.responsiblesNoSelected = <ResponsiblesDTO[]>response;
    });

  }

  public getThirdPartyTypes() {
    this.commonsService.getThirdPartyTypes().then(response => {
      this.thirdPartyTypeNoSelected = <ThirdPartyTypeDTO[]>response;
    });
  }


  public getSectors() {
    this.commonsService.getSectors().then(response => {
      this.sectorsNoSelected = <SectorDTO[]>response;
    });
  }

  public getCountries() {
    this.commonsService.getCountries().then(response => {
      this.countriesNoSelected = <CountryDTO[]>response;
    });
  }

  public getContactLenguages() {
    this.languagesNoSelected = LanguageValues;
  }

  public getNatureOfActivity() {
    this.commonsService.getNatureOfActivity().then(response => {
      this.natureOfActivityNoSelected = <NatureOfActivityDTO[]>response;
    });
  }
  public getTypeOfActivity() {
    this.commonsService.getTypeOfActivity().then(response => {
      this.typeOfActivityNoSelected = <TypeOfActivityDTO[]>response;
    });
  }
  public back() {
    this.location.back();
    this.globalService.closeThird();
  }

  openRequestDialog() {
    const dialogRef = this.dialog.open(DialogRequestThirdPartiesComponent, { data: { totalLoaded: 1 } });

    dialogRef.afterClosed().subscribe(r => {
      if (r) {
        this.toaster.open({ text: this.translations.requestSent, duration: 6000, type: 'info' });
      }
    })
  }
}


