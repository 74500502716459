<div class="o-wrapper">
  <main id="content">
    <!--encabezado de sección-->
    <div class="c-head-section c-head-section--tab bg-cream">
      <!--parte superior-->
      <div class="c-head-section--tab__top">
        <div class="c-head-section__left">
          <div class="c-head-section--tab__info">
            <h2 class="c-head-section--tab__title">
              {{ "MemberCheckCandidateList.screeningResultsTitle" | translate }}
            </h2>
            <div class="c-head-section--tab-data">
              <div class="c-head-section--tab-data__item">
                <div>
                  {{ "MemberCheckCandidateList.screeningResultsSub" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-head-section__right">
          <button type="button" class="c-button c-button--gradient" (click)="createResolution()">
            {{ 'ScoringCandidates.createResolution'| translate }}
          </button>
        </div>
      </div>
      <!--volver a listado-->
      <div class="c-go-back">
        <a [routerLink]="" (click)="back()" class="c-go-back__button">
          <span class="c-go-back__arrow" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
              <path
                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                transform="translate(-5-6.01)" />
            </svg>
          </span>
          {{ "MemberCheckCandidateList.back" | translate }}
        </a>
      </div>
      <!--parte inferior-->
      <div class="c-head-section--tab__bottom"></div>
    </div>
    <section class="c-table u-mt-1">
      <div class="c-table__top"></div>
      <div class="o-scroll">
        <div class="c-table__bottom">
          <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort
            (matSortChange)="sortBy($event)">
            <ng-container matColumnDef="matchedAlias">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="false">
                {{ "MemberCheckCandidateList.matchedAlias" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="c-responsible">
                  <div class="c-responsible__info">
                    <span class="c-responsible__name" style="font-weight: 400; color: black">{{ element.name }}</span>
                    <div class="c-responsible__position">
                      {{ element.matchedAlias }}
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="matchStrength">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
                {{ "MemberCheckCandidateList.matchStrength" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.matchStrength }}
              </td>
            </ng-container>
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="false">
                {{ "MemberCheckCandidateList.location" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.location }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dob">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="false">
                {{ "MemberCheckCandidateList.dob" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.dob }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
                {{ "MemberCheckCandidateList.actions" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <a [routerLink]="" (click)="viewDetails(element)" class="c-table__watch">
                  <span class="c-box-edit__button-ico" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12.747">
                      <path
                        d="m19.873 98.82c-.179-.244-4.436-5.985-9.873-5.985s-9.695 5.74-9.873 5.984a.66 .66 0 0 0 0 .778c.178.244 4.436 5.985 9.873 5.985s9.694-5.74 9.873-5.984a.659 .659 0 0 0 0 -.778m-9.873 5.444c-4 0-7.474-3.81-8.5-5.05 1.026-1.247 4.487-5.06 8.5-5.06s7.473 3.809 8.5 5.06c-1.025 1.247-4.486 5.05-8.5 5.05"
                        transform="translate(0-92.83)" />
                      <path
                        d="M158.678 154.725a3.956 3.956 0 1 0 3.956 3.956A3.961 3.961 0 0 0 158.678 154.725m0 6.593a2.637 2.637 0 1 1 2.637 -2.637A2.64 2.64 0 0 1 158.678 161.318"
                        transform="translate(-148.68-152.3)" />
                    </svg>
                  </span>
                  {{
                  "MemberCheckCandidateList.details" | translate
                  }}
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9">
                {{ "MemberCheckCandidateList.noResults" | translate }}.
              </td>
            </tr>
          </table>
          <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize"
            [length]="this.queryResult.totalItems" [pageSizeOptions]="[5, 10, 25, 100]" class="c-pagination">
          </mat-paginator>
        </div>
      </div>
    </section>
  </main>
</div>
