import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClientProxyClient } from "src/proxy/apiClient/client.proxy";

@Injectable()
export class ClientsService {

  constructor(
    private clientService: ClientProxyClient
  ) { }

  getApiToken(): Observable<string> {
    return this.clientService.clientApiTokenGet();
  }
}
