<div class="row">
  <div class="col-lg-12">
    <p id="apiSecret" class="c-fields__legend">
      Datum user
    </p>
    <mat-form-field floatLabel="never">
      <input type="text" matInput name="sector-description" [(ngModel)]="datumUser" (keyup)="onDatumUserChange()"
        id="sector-description" class="c-form__input" placeholder="Datum user" aria-labelledby="datumUser" />
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <p id="apiSecret" class="c-fields__legend">
      User
    </p>
    <mat-form-field floatLabel="never">
      <input type="text" matInput name="api-secret" [(ngModel)]="user" id="api-secret" (keyup)="onUserChange()"
        class="c-form__input" placeholder="User" aria-labelledby="refinitivApiSecret" />
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <p id="reference" class="c-fields__legend">
      Reference
    </p>
    <mat-form-field floatLabel="never">
      <input type="text" matInput name="api-secret" [(ngModel)]="reference" id="reference" (keyup)="onReferenceChange()"
        class="c-form__input" placeholder="Reference" aria-labelledby="reference" />
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <p id="password" class="c-fields__legend">
      Password
    </p>
    <mat-form-field floatLabel="never">
      <input type="text" matInput name="password" [(ngModel)]="password" id="api-secret" (keyup)="onPasswordChange()"
        class="c-form__input" placeholder="Password" aria-labelledby="password" />
    </mat-form-field>
  </div>
</div>
