<div *ngIf="!ready">
  <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
</div>


<mat-form-field class="c-search u-mb-1medium" floatLabel="never">
  <mat-label class="c-search__label">
    <span class="c-search__button" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          d="M19.724,18.547,14.757,13.58a8.336,8.336,0,1,0-1.177,1.177l4.968,4.968a.832.832,0,1,0,1.177-1.177ZM8.317,14.975a6.658,6.658,0,1,1,6.658-6.658,6.658,6.658,0,0,1-6.658,6.658Z"
          transform="translate(.032.032)" />
      </svg>
    </span> {{'TablaUsers.search.valor' | translate}}
  </mat-label>
  <input matInput (keyup)="applyFilter($event)" class="c-search__input" #input [disabled]="(!dataSource)" />
</mat-form-field>


<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  <!-- Nombre -->
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaUsers.header.name.valor' | translate}}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
  </ng-container>

  <!-- Apellidos -->
  <ng-container matColumnDef="Surname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaUsers.header.surname.valor' | translate}} </th>
    <td mat-cell *matCellDef="let element"> {{element.SurName}} </td>
  </ng-container>

  <!-- Email -->
  <ng-container matColumnDef="Email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaUsers.header.email.valor' | translate}} </th>
    <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
  </ng-container>

  <!-- Cargo -->
  <ng-container matColumnDef="Position">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaUsers.header.position.valor' | translate}} </th>
    <td mat-cell *matCellDef="let element"> {{getPositionTranslationKey(element.Position) | translate}} </td>
  </ng-container>

  <!-- Roles -->
  <ng-container matColumnDef="Rols">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaUsers.header.role.valor' | translate}} </th>
    <td mat-cell *matCellDef="let element">{{getUserTypeRole(element)}} </td>
  </ng-container>

  <!-- Acciones -->
  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef> {{'TablaUsers.header.actions.valor' | translate}} </th>
    <td mat-cell *matCellDef="let element">
      <a *ngIf="this.actions.includes('update') && userCanModify(element)" (click)='update(element)'
        class="c-table__watch">
        <span class="c-box-edit__button-ico" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M16.38.782,6.22,10.942A4.138,4.138,0,0,0,5,13.888v1.119a.833.833,0,0,0,.833.833H6.952A4.138,4.138,0,0,0,9.9,14.621l10.16-10.16a2.6,2.6,0,0,0,0-3.678,2.662,2.662,0,0,0-3.678,0Zm2.5,2.5L8.72,13.442a2.517,2.517,0,0,1-1.767.732H6.667v-.286A2.517,2.517,0,0,1,7.4,12.121l10.16-10.16a.957.957,0,0,1,1.322,0,.936.936,0,0,1,0,1.322Z"
              transform="translate(-.833-.008)" />
            <path
              d="M19.167,7.482a.833.833,0,0,0-.833.833V12.5H15A2.5,2.5,0,0,0,12.5,15v3.333H4.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5H11.7A.833.833,0,0,0,11.7,0H4.167A4.172,4.172,0,0,0,0,4.167V15.833A4.172,4.172,0,0,0,4.167,20h9.452a4.14,4.14,0,0,0,2.947-1.22l2.213-2.215A4.14,4.14,0,0,0,20,13.619v-5.3A.833.833,0,0,0,19.167,7.482ZM15.387,17.6a2.479,2.479,0,0,1-1.221.667V15A.833.833,0,0,1,15,14.167h3.271a2.513,2.513,0,0,1-.667,1.22Z" />
          </svg>
        </span> {{'TablaUsers.actions.update.valor' | translate}}
      </a>
      <ng-container *appAuthRole="['Owner']">
        <a *ngIf="getUserTypeRole(element) !== 'Owner'" (click)="delete(element)" class="c-table__watch">
          <span class="c-box-edit__button-ico" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path
                d="M17.322,2.667h-2.1A3.383,3.383,0,0,0,11.9,0H8.1A3.383,3.383,0,0,0,4.78,2.667h-2.1A.667.667,0,1,0,2.678,4h.678v8.667A3.366,3.366,0,0,0,6.746,16h6.508a3.366,3.366,0,0,0,3.39-3.333V4h.678a.667.667,0,1,0,0-1.333ZM8.1,1.333h3.8a2.036,2.036,0,0,1,1.918,1.333H6.184A2.036,2.036,0,0,1,8.1,1.333Zm7.186,11.333a2.017,2.017,0,0,1-2.034,2H6.746a2.017,2.017,0,0,1-2.034-2V4H15.288Z"
                transform="translate(-2)" />
              <path d="M9.678,15.333a.672.672,0,0,0,.678-.667v-4a.678.678,0,0,0-1.356,0v4A.672.672,0,0,0,9.678,15.333Z"
                transform="translate(-4.39 -3.333)" />
              <path
                d="M13.678,15.333a.672.672,0,0,0,.678-.667v-4a.678.678,0,0,0-1.356,0v4A.672.672,0,0,0,13.678,15.333Z"
                transform="translate(-5.678 -3.333)" />
              <path
                d="M13.678,15.333a.672.672,0,0,0,.678-.667v-4a.678.678,0,0,0-1.356,0v4A.672.672,0,0,0,13.678,15.333Z"
                transform="translate(-2.966 -3.333)" />
            </svg>
          </span> {{'TablaUsers.actions.delete.valor' | translate}}
        </a>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9">{{'TablaUsers.noresults.valor' | translate}} </td>
  </tr>

</table>


<mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25]" class="c-pagination">
</mat-paginator>

<div *ngIf="cuota" class="row u-mt-2">
  <div class="col-12">
    <button type="button" (click)="openDialogAdd()" class="c-button c-button--add c-button--add-bg">
      <span class="c-button--add__ico" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path data-name=" ↳Color" d="M14,8H8v6H6V8H0V6H6V0H8V6h6Z" transform="translate(5 5)" />
        </svg>
      </span>
      {{'TablaUsers.adduser.valor' | translate}}
    </button>
  </div>
</div>
