import { Component, EventEmitter, OnInit, Input, Output, AfterContentInit, ElementRef, } from '@angular/core';
import { RemoteService } from '../../../services/remote.service';
import { ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Table3PPConfiguration } from '../../../utilities/utilities';
import { MatRadioChange } from '@angular/material/radio';
import { ThirdPartyDTO } from '../../../models/ThirdParty'
import { TranslateService } from '@ngx-translate/core';
import { SectorDto } from 'src/proxy/model/sectorDto';
import { CountryDto } from 'src/proxy/model/countryDto';
import { EmailNotificaitonService } from '@app/services/email-notification.service';
import { Toaster } from 'ngx-toast-notifications';
import { CampaignStatus } from '@app/models/CampaignStatus';

@Component({
  selector: 'app-third-party-table-extended',
  templateUrl: './third-party-table-extended.component.html',
  styleUrls: ['./third-party-table-extended.component.scss']
})
export class ThirdPartyTableExtendedComponent implements OnInit, AfterContentInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("input", { static: true }) input: ElementRef;

  @Output() onSelectRow: EventEmitter<ThirdPartyDTO[]> = new EventEmitter<ThirdPartyDTO[]>();
  @Input() config: Table3PPConfiguration;

  dataSource: any;
  selection = new SelectionModel<ThirdPartyDTO>(true, []);
  isLoading: boolean;

  filtertypes: any = [];
  selectedType: string = "All";

  failedToSendEmail: string;
  emailSent: string;
  campaignStatus = CampaignStatus;

  constructor(private remote: RemoteService,
    private translate: TranslateService,
    private emailNotificationSerivce: EmailNotificaitonService,
    private toaster: Toaster) { }

  ngAfterContentInit(): void {
    this.isLoading = false;
    this.get3PPs();
  }

  ngOnInit(): void {
    this.translate.get('CampaignTable.emailSent').subscribe(translation => {
      this.emailSent = translation;
    });

    this.translate.get('CampaignTable.failedToSendEmail').subscribe(translation => {
      this.failedToSendEmail = translation;
    });

    if (this.config.filterByTypes) {
      this.remote.getRequest("Commons/ThirdPartyType").then(r => {
        this.filtertypes.push({ 'name': 'All', code: 'All', 'checked': true })
        r.map(element => {
          this.filtertypes.push({ 'Description': element?.Description ?? '', 'DescriptionEN': element?.DescriptionEN ?? '', 'DescriptionPT': element?.DescriptionPT ?? '', code: element?.Code ?? '', 'checked': false, 'DescriptionFR': element?.DescriptionFR ?? '' })
        });
      }).catch(e => {
        console.log(e);
      })
    }
  }

  // Si el número de elementos seleccionados coincide con el número total de filas
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource != null ? this.dataSource.data.length : 0;
    return numSelected === numRows;
  }

  isAllTypeSelected(type: string) {
    let todos = true;
    for (let index = 0; index < this.dataSource.data.length; index++) {
      const element = this.dataSource.data[index];
      if (type == element.ThirdPartyConfig.ThirdPartyType.Code) {
        {
          let f = this.selection.selected.some(x => (x.CompanyCIF == element.CompanyCIF && x.Country == element.Country));
          if (!f) {
            todos = false;
            break;
          }
        }
      }
    }
    return todos;
  }

  // Selecciona todas las filas si no están todas seleccionadas; de lo contrario, limpia la selección.
  masterToggle() {
    if (this.selectedType == 'All') {
      if (this.isAllSelected()) {
        this.selection.clear()
      } else {
        this.dataSource.data.forEach(row => this.selection.select(row));
      }
    } else {


      if (this.isAllTypeSelected(this.selectedType)) {
        // Solo se deberían deseleccionar los del tipo filtrado
        this.dataSource.data.forEach(row => {
          if (this.selectedType == row.ThirdPartyConfig.ThirdPartyType.Code) {
            this.selection.deselect(row)
          }
        });

      } else {
        this.dataSource.data.forEach(row => {
          // Solo se deberían seleccionar los del tipo filtrado
          if (this.selectedType == row.ThirdPartyConfig.ThirdPartyType.Code) {
            this.selection.select(row)
          }
        });
      }
    }
  }

  checkboxLabel(row?: ThirdPartyDTO): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Id + 1}`;
  }

  get3PPs() {
    if (this.config) {
      if (this.config.tipo == null || this.config.tipo == undefined) {
        this.get3PPall();
      }
      else {
        this.get3PPTipo();
      }
    }
  }

  overrideFilter() {
    let _this = this;
    this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
      var inputFilter = filter.split("#<->#")[1];
      if (_this.selectedType.toLowerCase() == 'all') {
        if (filter == null || filter == undefined || inputFilter.trim().length == 0) {
          return true
        }
        else {
          switch (_this.config.tableTarget.toLowerCase()) {
            case 'campaign':
              return String(data.Id).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyType?.Code).toLowerCase().includes(inputFilter)
                || String(data.CompanyName).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.Country)).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.ThirdPartyConfig.Sector)).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyStatus?.Code).toLowerCase().includes(inputFilter)
                || String(data.NumCampaigns).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.FullName).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.Position).toLowerCase().includes(inputFilter)
                || String(data.Progress).toLowerCase().includes(inputFilter)
            case 'imported':
              return String(data.Id).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyType?.Code).toLowerCase().includes(inputFilter)
                || String(data.CompanyName).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.Country)).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.ThirdPartyConfig.Sector)).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyStatus?.Code).toLowerCase().includes(inputFilter)
                || String(data.NumCampaigns).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.FullName).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.Position).toLowerCase().includes(inputFilter)
            case 'startedcampaign':
              return String(data.Id).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyType?.Code).toLowerCase().includes(inputFilter)
                || String(data.CompanyName).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.Country)).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.ThirdPartyConfig.Sector)).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyStatus?.Code).toLowerCase().includes(inputFilter)
                || String(data.NumCampaigns).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.FullName).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.Position).toLowerCase().includes(inputFilter);
            default:
              return true;
          }
        }
      }
      else {
        var inputFilter = filter.split("#<->#")[1];
        if (filter == null || filter == undefined || inputFilter.trim().length == 0) {
          return (String(data.ThirdPartyConfig.ThirdPartyType.Code).toLowerCase() == _this.selectedType.toLowerCase())
        }
        else {

          switch (_this.config.tableTarget.toLowerCase()) {
            case 'campaign':
              return (String(data.Id).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyType?.Code).toLowerCase().includes(inputFilter)
                || String(data.CompanyName).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.Country)).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.ThirdPartyConfig.Sector)).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyStatus?.Code).toLowerCase().includes(inputFilter)
                || String(data.NumCampaigns).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.FullName).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.Position).toLowerCase().includes(inputFilter)
                || String(data.Progress).toLowerCase().includes(inputFilter))
                && (String(data.ThirdPartyConfig.ThirdPartyType?.Code).toLowerCase() == _this.selectedType.toLowerCase());
            case 'imported':
              return (String(data.Id).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyType?.Code).toLowerCase().includes(inputFilter)
                || String(data.CompanyName).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.Country)).toLowerCase().includes(inputFilter)
                || String(_this.translatedMasterDataObject(data.ThirdPartyConfig.Sector)).toLowerCase().includes(inputFilter)
                || String(data.ThirdPartyConfig.ThirdPartyStatus?.Code).toLowerCase().includes(inputFilter)
                || String(data.NumCampaigns).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.FullName).toLowerCase().includes(inputFilter)
                || String(data.UserOwner?.Position).toLowerCase().includes(inputFilter))
                && (String(data.ThirdPartyConfig.ThirdPartyType?.Code).toLowerCase() == _this.selectedType.toLowerCase());
            default:
              return true;
          }
        }
      }
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  radioChange(event: MatRadioChange) {
    this.selectedType = event.value;
    const filterValue = this.input.nativeElement.value;
    this.dataSource.filter = event.value + "#<->#" + filterValue.trim().toLowerCase();
  }

  get3PPall() {
    switch (this.config.tableTarget.toLowerCase()) {
      case 'importedstartedcampaign':
        this.isLoading = true;
        this.dataSource = new MatTableDataSource(this.config.importedItems);
        this.overrideFilter();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.selection = new SelectionModel<ThirdPartyDTO>(true, []);
        this.isLoading = false;

        break;
      case 'sendcampaign': this.isLoading = true;
        this.remote.getRequest("Campaign/GetByIdAndUser/" + this.config.campaign).then(r => {
          this.dataSource = new MatTableDataSource(r.ThirdParties);
          this.overrideFilter();
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.selection = new SelectionModel<ThirdPartyDTO>(true, []);
          this.isLoading = false;
        }).catch(e => {
          this.isLoading = false;
        })
        break;
      case 'campaign':
        this.isLoading = true;
        this.remote.getRequest("Campaign/GetByIdAndUser/" + this.config.campaign).then(r => {
          this.dataSource = new MatTableDataSource(r.ThirdParties);
          this.overrideFilter();
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.selection = new SelectionModel<ThirdPartyDTO>(true, []);
          this.isLoading = false;
        }).catch(e => {
          this.isLoading = false;
        })
        break;
      case 'imported':
        this.isLoading = true;
        this.dataSource = new MatTableDataSource(this.config.importedItems);
        this.overrideFilter();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.selection = new SelectionModel<ThirdPartyDTO>(true, []);
        this.isLoading = false;

        break;
      case 'startedcampaign':
        this.isLoading = true;
        this.remote.getRequest("ThirdParties/GetAllAvailableThirdPartiesByCampaign?idCampaign=" + this.config.campaign).then(r => {
          this.dataSource = new MatTableDataSource(r);
          this.overrideFilter();
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        }).catch(e => {
          this.isLoading = false;
        })

        break;
    }
  }

  get3PPTipo() {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim();
    this.dataSource.filter = this.selectedType + "#<->#" + filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.dataSource.filter = null;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  procesaSeleccion() {
    this.onSelectRow.emit(this.selection.selected);
  }

  resendInvitation(thirdPartyId: number, campaignId: number) {
    this.emailNotificationSerivce.resendInvitationEmail(thirdPartyId, campaignId).subscribe(r => {
      if (r) {
        this.toaster.open({ text: this.emailSent, duration: 6000, type: 'success' });
      } else {
        this.toaster.open({ text: this.failedToSendEmail, duration: 6000, type: 'danger' });
      }
    });
  }

  private translatedMasterDataObject(masterDataObject: SectorDto | CountryDto): string {
    switch (this.translate.currentLang) {
      case 'en-US':
        return masterDataObject?.DescriptionEN  ?? '';
      case 'es-ES':
        return masterDataObject?.Description  ?? '';
      case 'pt-PT':
        return masterDataObject?.DescriptionPT  ?? '';
      case 'fr-FR':
        return masterDataObject?.DescriptionFR  ?? '';
      default:
        return masterDataObject?.DescriptionEN  ?? '';
    }
  }
}
