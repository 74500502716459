<div class="o-wrapper">
    <main id="content">
        <mat-progress-bar *ngIf="loading" mode="indeterminate" class="c-spinner"></mat-progress-bar>
        <!--encabezado de sección-->
        <div class="c-head-section">
            <div class="c-head-section__left">
                <h2 class="o-title regular">{{'MassiveLoad.text1.valor'|translate}} - <strong>{{'MassiveLoad.text2.valor'|translate}}</strong> </h2>
                <p class="o-text">{{'MassiveLoad.intro.valor'|translate}}</p>
            </div>
            <div class="c-head-section__right">
                <button class="c-button" (click)="checkThirdPartiesExcel()" [disabled]="file==null">
                    {{'MassiveLoad.continuar.valor'|translate}}
                </button>
            </div>
        </div>
        <!--volver atrás-->
        <div class="c-go-back c-go-back--steps u-mb-1">
            <button type="button" class="c-go-back__button" (click)="back();global.closeImportThird();">
                <span class="c-go-back__arrow" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                        <path
                            d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                            transform="translate(-5-6.01)" />
                    </svg>
                </span>
                {{'MassiveLoad.volver.valor'|translate}}
            </button>
        </div>
        <div class="u-spaceLeft u-mt-2">
            <ol class="c-step">
                <li class="c-step-item c-step-item--download-excel">
                    <div class="c-step-item__top">
                        <p class="c-step-item__title">{{'MassiveLoad.paso1.valor'|translate}}</p>
                        <p class="o-text">{{'MassiveLoad.paso1.text.valor'|translate}}</p>
                    </div>
                    <app-file-download title="{{'MassiveLoad.descarga.valor'|translate}}" downloadedFileName="ThirdPartiesBulkUploadTemplate.xlsx" [getFileBytes]="getThirdPartiesTemplate">
                    </app-file-download>
                </li>
                <li class="c-step-item">
                    <div class="c-step-item__top">
                        <p class="c-step-item__title">{{'MassiveLoad.paso2.valor'|translate}}</p>
                        <p class="o-text">{{'MassiveLoad.paso2.text.valor'|translate}}</p>
                    </div>
                    <div class="c-step-item__action">
                        <app-file-upload [buttonText]="uploadButtonText" (fileSelected)="selectThirdPartiesExcel($event)"></app-file-upload>
                    </div>
                </li>
                <li class="c-step-item">
                    <div class="c-step-item__top">
                        <p class="c-step-item__title">{{'MassiveLoad.paso3.valor'|translate}}</p>
                        <p class="o-text">{{'MassiveLoad.paso3.text.valor'|translate}}</p>
                    </div>
                    <div class="c-step-item__action">
                        <div class="c-select c-select--image">
                            <div class="c-select__image" aria-hidden="true">
                                <img src="assets/placeholder-avatar.png" alt="" loading="lazy" />
                            </div>
                            <mat-label class="c-select__label u-sr-only">Responsable</mat-label>
                            <mat-select [(value)]="selectedResponsible">
                                <mat-option *ngFor="let responsible of responsibles" [value]="responsible">
                                    {{responsible?.Name}} {{responsible?.SurName}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </main>
</div>
