<div class="immovable-property">
  <p id="keyData" class="immovable-property__legend u-mt-1medium">{{ "EquifaxWSImmovableProperty.identification" |
    translate}} {{ property?.register }}</p>
  <div class="immovable-property__group" aria-labelledby="keyData">
    <div class="immovable-property-item">
      <p id="planNumber" class="immovable-property-item__title">
        {{ "EquifaxWSImmovableProperty.planNumber" | translate }}
      </p>
      <p class="immovable-property-item__text" aria-labelledby="planNumber">
        {{ property?.planNumber || "" }}
      </p>
    </div>
    <div class="immovable-property-item">
      <p id="location" class="immovable-property-item__title">
        {{ "EquifaxWSImmovableProperty.location" | translate }}
      </p>
      <p class="immovable-property-item__text" aria-labelledby="location">
        {{ property?.location || "" }}
      </p>
    </div>
    <div class="immovable-property-item">
      <p id="presentation" class="immovable-property-item__title">
        {{ "EquifaxWSImmovableProperty.presentation" | translate }}
      </p>
      <p class="immovable-property-item__text" aria-labelledby="presentation">
        {{ property?.presentation || "" }}
      </p>
    </div>
    <div class="immovable-property-item">
      <p id="size" class="immovable-property-item__title">
        {{ "EquifaxWSImmovableProperty.size" | translate }}
      </p>
      <p class="immovable-property-item__text" aria-labelledby="size">
        {{ property?.size || "" }}
      </p>
    </div>
  </div>
  <div class="immovable-property__group" aria-labelledby="keyData">
    <div class="immovable-property-item">
      <p id="value" class="immovable-property-item__title">
        {{ "EquifaxWSImmovableProperty.value" | translate }}
      </p>
      <p class="immovable-property-item__text" aria-labelledby="value">
        {{ property?.value || "" }}
      </p>
    </div>
    <div class="immovable-property-item">
      <p id="fiscalValue" class="immovable-property-item__title">
        {{ "EquifaxWSImmovableProperty.fiscalValue" | translate }}
      </p>
      <p class="immovable-property-item__text" aria-labelledby="fiscalValue">
        {{ property?.fiscalValue || "" }}
      </p>
    </div>
  </div>
  <p id="keyData" class="immovable-property__legend u-mt-1medium">{{ "EquifaxWSImmovableProperty.liens" | translate }}
  </p>
  <section class="c-table">
    <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.type" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.type }}
        </td>
      </ng-container>
      <ng-container matColumnDef="registrationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.registrationDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.registrationDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.startDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.expDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.expDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.amount" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.amount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.currency" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="partialCancels">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.partialCancels" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.partialCancels }}
        </td>
      </ng-container>
      <ng-container matColumnDef="creditor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.creditor" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.creditor }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">{{'EquifaxWSLien.noResults' | translate}} </td>
      </tr>
    </table>
    <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems"
      class="c-pagination">
    </mat-paginator>
  </section>
</div>
