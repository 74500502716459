<table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort
  (matSortChange)="sortBy($event)">
  <ng-container matColumnDef="matchedAlias">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
      Matched Alias
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="c-responsible">
        <div class="c-responsible__info">
          <span class="c-responsible__name">{{ scoring.name }}</span>
          <div class="c-responsible__position">{{ scoring.alias }}</div>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="worldCheckId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
      {{ "ThirdPartyScreeningTable.WorldCheckId.valor" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ scoring.worldCheckId }}</td>
  </ng-container>
  <ng-container matColumnDef="screeningDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="false">
      {{ "ThirdPartyScreeningTable.ScreeningDate.valor" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <span>
        {{ element.screeningDate | date : "dd/MM/yyyy - HH:mm" }}
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="thirdPartyRisks">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
      {{ "ThirdPartyScreeningTable.ThirdPartyRisks.valor" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-chip-list aria-label="Third party risks">
        <mat-chip *ngFor="let risk of element.thirdPartyRisks" color="primary">
          {{ risk }}
        </mat-chip>
      </mat-chip-list>
    </td>
  </ng-container>
  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
      {{ "ThirdPartyScreeningTable.CreatedBy.valor" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <app-responsible-user [responsibleUserId]="element.createdBy.id"></app-responsible-user>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
      {{ "ThirdPartyScreeningTable.Actions.valor" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="" (click)="viewDetails(element.id)" class="c-table__watch">
        <span class="c-box-edit__button-ico" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12.747">
            <path
              d="m19.873 98.82c-.179-.244-4.436-5.985-9.873-5.985s-9.695 5.74-9.873 5.984a.66 .66 0 0 0 0 .778c.178.244 4.436 5.985 9.873 5.985s9.694-5.74 9.873-5.984a.659 .659 0 0 0 0 -.778m-9.873 5.444c-4 0-7.474-3.81-8.5-5.05 1.026-1.247 4.487-5.06 8.5-5.06s7.473 3.809 8.5 5.06c-1.025 1.247-4.486 5.05-8.5 5.05"
              transform="translate(0-92.83)" />
            <path
              d="M158.678 154.725a3.956 3.956 0 1 0 3.956 3.956A3.961 3.961 0 0 0 158.678 154.725m0 6.593a2.637 2.637 0 1 1 2.637 -2.637A2.64 2.64 0 0 1 158.678 161.318"
              transform="translate(-148.68-152.3)" />
          </svg>
        </span>
        {{ "ThirdPartyScreeningTable.SeeReport.valor" | translate }}
      </a>

      <a *appAuthRole="['Owner', 'Manager']" [routerLink]="" (click)="downloadReport(element.id)" class="c-table__watch">
        <span class="c-box-edit__button-ico" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
            viewBox="0 0 18 18">
            <defs>
              <style>
                .a {
                  fill: url(#a);
                }
              </style>
              <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#fdc130" />
                <stop offset="1" stop-color="#faa868" />
              </linearGradient>
            </defs>
            <path class="a"
              d="M17.25,16h0a.724.724,0,0,0-.75.694v2.778a.724.724,0,0,1-.75.694H2.25a.724.724,0,0,1-.75-.694V16.694A.724.724,0,0,0,.75,16h0a.724.724,0,0,0-.75.694v2.778a2.171,2.171,0,0,0,2.25,2.083h13.5A2.171,2.171,0,0,0,18,19.472V16.694A.724.724,0,0,0,17.25,16Z"
              transform="translate(0 -3.555)" />
            <path class="a"
              d="M5.231,9.4l3.622,3.185a2.611,2.611,0,0,0,3.35,0L15.824,9.4a.637.637,0,0,0,0-.982.868.868,0,0,0-1.117,0l-3.39,2.98V.694a.8.8,0,0,0-1.579,0V11.4L6.348,8.419a.868.868,0,0,0-1.117,0,.637.637,0,0,0,0,.982Z"
              transform="translate(-1.528)" />
          </svg>
        </span>
        {{ "ThirdPartyScreeningTable.Download.valor" | translate }}
      </a>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems"
  [pageSizeOptions]="[5, 10, 25, 100]" class="c-pagination">
</mat-paginator>
