import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private http: HttpClient,
    @Inject('API_URL') public serverUrl: string
  ) { }

  public updateVisibleInfo(data) {
    const formData = this.ConstruirFormDataVisibleInfo(data);
    return this.http.put(`${this.serverUrl}/Users/UpdateVisibleInfoClientData`, formData);
  }

  private ConstruirFormDataVisibleInfo(data): FormData {
    const formData = new FormData();

    formData.append('Name', data.name);
    
    if (data.selectedCoverFileUri !== null && data.selectedCoverFileUri !== undefined)
      formData.append('CoverPageImageFileUri', data.selectedCoverFileUri);

    if (data.selectedCoverFileName !== null && data.selectedCoverFileName !== undefined)
      formData.append('CoverPageImageFileName', data.selectedCoverFileName);

    if (data.selectedCoverFile) {
      formData.append('CoverPageImageFile', data.selectedCoverFile);
    }

    if (data.selectedIconFileUri !== null && data.selectedIconFileUri !== undefined)
      formData.append('IconImageFileUri', data.selectedIconFileUri);

    if (data.selectedIconFileName !== null && data.selectedIconFileName !== undefined)
      formData.append('IconImageFileName', data.selectedIconFileName);

    if (data.selectedIconFile) {
      formData.append('IconImageFile', data.selectedIconFile);
    }

    return formData;
  }

  updateAccountInfo(data) {
    const formData = this.ConstruirFormDataAccountInfo(data);
    return this.http.put(`${this.serverUrl}/Users/UpdateUserData`, formData);
  }

  async getFileStorageKey(filename: string): Promise<any>{
    const res = await this.http.get(`${this.serverUrl}/Users/UpdateUserImage/${filename}`).toPromise();

    return res;
  }

  private ConstruirFormDataAccountInfo(data): FormData {
    const formData = new FormData();

    formData.append('Id', data.Id);
    formData.append('Name', data.Name);
    formData.append('SurName', data.SurName);
    formData.append('Email', data.Email);
    formData.append('Position', data.Position);
    if (data.OldPassword !== null && data.OldPassword !== undefined)
    formData.append('OldPassword', data.OldPassword);
    
    if (data.NewPassword !== null && data.NewPassword !== undefined)
    formData.append('NewPassword', data.NewPassword);

    if (data.ProfilePhotoFileName !== null && data.ProfilePhotoFileName !== undefined)
      formData.append('ProfilePhotoFileName', data.ProfilePhotoFileName);

    if (data.ProfilePhotoFileUri !== null && data.ProfilePhotoFileUri !== undefined)
    formData.append('ProfilePhotoFileUri', data.ProfilePhotoFileUri);

    if (data.ProfilePhotoFile) {
      formData.append('ProfilePhotoFile', data.ProfilePhotoFile);
    }

    formData.append('LanguageId', data.LanguageId);
    formData.append('TwoFactorEnabled', data.TwoFactorEnabled);
    
    return formData;
  }

  async getFileStorageKeyClientPublicImage(filename: string): Promise<any>{
    const res = await this.http.get(`${this.serverUrl}/Users/SaveClientPublicImage/${filename}`).toPromise();    
    return res;
  }
}
