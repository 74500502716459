import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MonitoringConfiguration } from '@app/models/MonitoringConfiguration';
import { PeriodicityType } from '@app/models/PeriodicityType';
import { MonitoringConfigurationService } from '@app/services/monitoring-configuration.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-monitoring',
  templateUrl: './dialog-monitoring.component.html',
  styleUrls: ['./dialog-monitoring.component.scss'],
})
export class DialogMonitoringComponent {
  isEditing: boolean;
  monitoringConfig: MonitoringConfiguration;
  thirdPartyRefinitivId: number;
  periodicityType: PeriodicityType = PeriodicityType.Monthly;
  repeatIn: string = '1';
  risks: string[];
  readonly PeriodicityType = PeriodicityType;

  constructor(
    public dialogRef: MatDialogRef<DialogMonitoringComponent>,
    public monitoringConfigurationService: MonitoringConfigurationService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      monitoringConfiguration: MonitoringConfiguration;
      thirdPartyRefinitivId: number;
    }
  ) {
    this.isEditing = this.data?.monitoringConfiguration != null;
    if (this.isEditing) {
      this.monitoringConfig = this.data.monitoringConfiguration;
      this.risks = this.data.monitoringConfiguration.risksToWatch.split(',');

      this.periodicityType = this.data.monitoringConfiguration.preriodicity;
      this.repeatIn = this.data.monitoringConfiguration.repeatIn.toString();
    }
    this.thirdPartyRefinitivId = data.thirdPartyRefinitivId;
  }

  public sendData(): void {
    const monitoringConfig: MonitoringConfiguration = {
      refinitivThirdPartyId: this.thirdPartyRefinitivId,
      preriodicity: this.periodicityType,
      repeatIn: +this.repeatIn,
      risksToWatch: this.risks.join(),
      id: this.isEditing ? this.monitoringConfig.id : undefined,
    };

    if (!this.isEditing) {
      this.monitoringConfigurationService
        .createMonitoring(monitoringConfig)
        .subscribe((_) => {
          this.dialogRef.close(true);
        });
    } else {
      this.monitoringConfigurationService
        .updateMonitoring(monitoringConfig)
        .subscribe((_) => {
          this.dialogRef.close(true);
        });
    }
  }

  public deleteMonitoring(): void {
    this.monitoringConfigurationService
      .deleteMonitoring(this.monitoringConfig.id)
      .subscribe((_) => {
        this.dialogRef.close(true);
      });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
