import { Component, Input, OnChanges } from '@angular/core';
import { QueryResult } from '@app/models/common/queryResult';
import { Document } from '@app/modules/screening-hub/models/GesintelScreening/Document';

@Component({
  selector: 'aml-update-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class AMLUpdateDocumentsComponent implements OnChanges {
  @Input() documents: Document[];

  displayedColumns: string[] = [
    'type',
    'number',
    'comments',
  ];

  queryResult: QueryResult<Document> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() { }

  ngOnChanges(): void {
    this.getDocuments();
  }

  getDocuments() {
    if (this.documents) {
      this.queryResult.items = this.documents
        .slice(
          (this.query.Page - 1) * this.query.PageSize,
          this.query.Page * this.query.PageSize
        );
      this.queryResult.totalItems = this.documents.length;
    }
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getDocuments();
  }
}
