import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-3pp-exists-in-bc',
  templateUrl: './dialog-3pp-exists-in-bc.component.html',
  styles: [],
})
export class Dialog3ppExistsInBc implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<Dialog3ppExistsInBc>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onCloseModal(): void {
    this.dialogRef.close();
  }
}
