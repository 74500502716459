<div class="working-info">
  <p id="keyData" class="working-info__legend u-mt-1medium">{{ "EquifaxWSWorkingInfo.title" | translate }}</p>
  <div class="working-info__group" aria-labelledby="keyData">
    <div class="working-info-item">
      <p id="lastEstimationIncome" class="working-info-item__title">
        {{ "EquifaxWSWorkingInfo.lastEstimationIncome" | translate }}
      </p>
      <p class="working-info-item__text" aria-labelledby="lastEstimationIncome">
        {{ individualDetails?.estimatedIncome || "" }}
      </p>
    </div>
    <div class="working-info-item">
      <p id="last3MonthEstimationIncome" class="working-info-item__title">
        {{ "EquifaxWSWorkingInfo.last3MonthEstimationIncome" | translate }}
      </p>
      <p class="working-info-item__text" aria-labelledby="last3MonthEstimationIncome">
        {{ individualDetails?.estimatedIncome3Month || "" }}
      </p>
    </div>
    <div class="working-info-item">
      <p id="last6MonthEstimationIncome" class="working-info-item__title">
        {{ "EquifaxWSWorkingInfo.last6MonthEstimationIncome" | translate }}
      </p>
      <p class="working-info-item__text" aria-labelledby="last6MonthEstimationIncome">
        {{ individualDetails?.estimatedIncome6Month || "" }}
      </p>
    </div>
    <div class="working-info-item">
      <p id="last12MonthEstimationIncome" class="working-info-item__title">
        {{ "EquifaxWSWorkingInfo.last12MonthEstimationIncome" | translate }}
      </p>
      <p class="working-info-item__text" aria-labelledby="last12MonthEstimationIncome">
        {{ individualDetails?.estimatedIncome12Month || "" }}
      </p>
    </div>
  </div>
</div>
