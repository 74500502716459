/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Observable } from 'rxjs';
import { ResolutionLinkDto } from '../model/resolutionLinkDto';
import { ResolutionRecordDto } from '../model/resolutionRecordDto';
import { Configuration } from '../configuration';

@Injectable()
export class ResolutionProxyClient {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param recordId 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resolutionAddCommentRecordIdPostForm(recordId: number, comment?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public resolutionAddCommentRecordIdPostForm(recordId: number, comment?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public resolutionAddCommentRecordIdPostForm(recordId: number, comment?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public resolutionAddCommentRecordIdPostForm(recordId: number, comment?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (recordId === null || recordId === undefined) {
            throw new Error('Required parameter recordId was null or undefined when calling resolutionAddCommentRecordIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        }

        if (comment !== undefined) {
            formParams = formParams.append('comment', <any>comment) as any || formParams;
        }

        return this.httpClient.request<boolean>('post', `${this.basePath}/Resolution/addComment/${encodeURIComponent(String(recordId))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param name 
     * @param software 
     * @param data 
     * @param totalResult 
     * @param reason 
     * @param explanation 
     * @param searchType 
     * @param thirdPartyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resolutionCreateLinkPostForm(name?: string, software?: number, data?: string, totalResult?: number, reason?: number, explanation?: string, searchType?: number, thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public resolutionCreateLinkPostForm(name?: string, software?: number, data?: string, totalResult?: number, reason?: number, explanation?: string, searchType?: number, thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public resolutionCreateLinkPostForm(name?: string, software?: number, data?: string, totalResult?: number, reason?: number, explanation?: string, searchType?: number, thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public resolutionCreateLinkPostForm(name?: string, software?: number, data?: string, totalResult?: number, reason?: number, explanation?: string, searchType?: number, thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {









        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (thirdPartyId !== undefined && thirdPartyId !== null) {
            queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        }

        if (name !== undefined) {
            formParams = formParams.append('name', <any>name) as any || formParams;
        }
        if (software !== undefined) {
            formParams = formParams.append('software', <any>software) as any || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }
        if (totalResult !== undefined) {
            formParams = formParams.append('totalResult', <any>totalResult) as any || formParams;
        }
        if (reason !== undefined) {
            formParams = formParams.append('reason', <any>reason) as any || formParams;
        }
        if (explanation !== undefined) {
            formParams = formParams.append('explanation', <any>explanation) as any || formParams;
        }
        if (searchType !== undefined) {
            formParams = formParams.append('searchType', <any>searchType) as any || formParams;
        }

        return this.httpClient.request<boolean>('post', `${this.basePath}/Resolution/createLink`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param thirdPartyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resolutionLinksGet(thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ResolutionLinkDto>>;
    public resolutionLinksGet(thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResolutionLinkDto>>>;
    public resolutionLinksGet(thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResolutionLinkDto>>>;
    public resolutionLinksGet(thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (thirdPartyId !== undefined && thirdPartyId !== null) {
            queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ResolutionLinkDto>>('get', `${this.basePath}/Resolution/links`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param linkId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resolutionRecordsLinkIdGet(linkId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ResolutionRecordDto>>;
    public resolutionRecordsLinkIdGet(linkId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResolutionRecordDto>>>;
    public resolutionRecordsLinkIdGet(linkId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResolutionRecordDto>>>;
    public resolutionRecordsLinkIdGet(linkId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (linkId === null || linkId === undefined) {
            throw new Error('Required parameter linkId was null or undefined when calling resolutionRecordsLinkIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ResolutionRecordDto>>('get', `${this.basePath}/Resolution/records/${encodeURIComponent(String(linkId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param linkId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resolutionRemoveLinkLinkIdPost(linkId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public resolutionRemoveLinkLinkIdPost(linkId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public resolutionRemoveLinkLinkIdPost(linkId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public resolutionRemoveLinkLinkIdPost(linkId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (linkId === null || linkId === undefined) {
            throw new Error('Required parameter linkId was null or undefined when calling resolutionRemoveLinkLinkIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('post', `${this.basePath}/Resolution/removeLink/${encodeURIComponent(String(linkId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}