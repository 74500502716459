import { AMLUpdateSearchResponse as AMLUpdateSearchResponseDto } from "src/proxy/model/aMLUpdateSearchResponse";
import { AMLUpdateSearchResponse } from "../../models/AMLUpdateSearchReponse";
import { mapAMLUpdateCandidate } from "./aml-update-candidate.mapping";

export function mapAMLUpdateSearchResponse(dto: AMLUpdateSearchResponseDto): AMLUpdateSearchResponse {
  if (!dto) return null;

  return <AMLUpdateSearchResponse> {
    response: dto.Response,
    results: dto.Results?.map(c => mapAMLUpdateCandidate(c)),
  }
}
