<div class="o-wrapper">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section c-head-section--bg large">
            <div class="c-head-section__left">
                <small class="o-title o-title--small">{{'Firmado.title1.valor'|translate}}</small>
                <h2 class="o-title"><strong>{{'Firmado.title2.valor'|translate}}</strong></h2>
                <p class="o-text">{{'Firmado.intro.valor'|translate}}</p>
            </div>
            <div class="c-head-section__right" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.993 56.004">
                    <path d="M731.078,547.534a28,28,0,1,1,0-56v8.2h-.006a19.816,19.816,0,1,0,19.813,19.819v-.013h8.2a27.98,27.98,0,0,1-27.958,28Z" transform="translate(-703.09-491.53)"/><path d="m1190.47 513.31l-.151-21.783h-21.783v8.2h8.03l-16.942 16.942 5.8 5.8 16.86-17.02v7.868" transform="translate(-1134.48-491.53)"/>
                    <circle cx="8.453" cy="8.453" r="8.453" transform="translate(20.07 20.08)"/>
                </svg>
            </div>
        </div>
        <!--volver atrás-->
        <div class="c-go-back c-go-back--steps">
            <a [routerLink]="['/mis-riesgos']" class="c-go-back__button">
                <span class="c-go-back__arrow" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                        <path d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0" transform="translate(-5-6.01)"/>
                    </svg>
                </span> {{'Firmado.back.valor'|translate}}
            </a>
        </div>
    </main>
</div>