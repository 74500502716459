import { Component } from '@angular/core';
import { TableTemplateConfiguration } from '../../../utilities/utilities';
import { canAccess } from '@app/security/riskallay.roles';
import { Role } from '@app/models/Role';
import { AuthenticationService } from '@app/services/authentication.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html'
})
export class TemplateComponent {

  templateConfig: TableTemplateConfiguration;

  constructor(private authenticationService: AuthenticationService) {

    const userRoles = this.authenticationService.getRoles();
    this.templateConfig = {
      'columnas': canAccess([Role.Owner, Role.Auditor, Role.Manager], userRoles) ? ['data', 'action'] : ['data'],
      'filterByTypes': true,
      'acciones': canAccess([Role.Owner, Role.Manager], userRoles) ? ['editar', 'duplicar'] : ['editar']
    }
  }
}
