import { Component, Input } from "@angular/core";
import { EquifaxWSIndividualData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSIndividualData";

@Component({
  selector: 'equifax-ws-individual-working-info',
  templateUrl: './working-info.component.html',
  styleUrls: ['./working-info.component.scss'],
})
export class EquifaxWSIndividualWorkingInfoComponent {
  @Input() individualDetails: EquifaxWSIndividualData;

  constructor() { }
}
