import { Component, Input } from "@angular/core";
import { CampaignStatus } from "@app/models/CampaignStatus";
import { CampaignThirdParty } from "@app/models/CampaignThirdParty";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-campaign-third-party-status',
    templateUrl: './campaign-third-party-status.component.html',
    styleUrls: ['./campaign-third-party-status.component.scss']
})
export class CampaignThirdPartyStatusComponent {

    @Input() campaignThirdParty: CampaignThirdParty;

    pendingStatus: string;
    inProgressStatus: string;
    pendingReviewStatus: string;
    reviewedStatus: string;
    pendingSign: string;
    discarded: string;
    acknowledged: string;
    campaignStatus = CampaignStatus;

    constructor(private translate: TranslateService) {
        this.translate.get('Tabla3PP.pendingSign').subscribe(translation => {
            this.pendingSign = translation;
        });

        this.translate.get('Tabla3PP.pending').subscribe(translation => {
            this.pendingStatus = translation;
        });

        this.translate.get('Tabla3PP.inProgress').subscribe(translation => {
            this.inProgressStatus = translation;
        });

        this.translate.get('Tabla3PP.pendingReview').subscribe(translation => {
            this.pendingReviewStatus = translation;
        });
    
        this.translate.get('Tabla3PP.reviewed').subscribe(translation => {
            this.reviewedStatus = translation;
        });

        this.translate.get('Tabla3PP.discarded').subscribe(translation => {
            this.discarded = translation;
        });

        this.translate.get('Tabla3PP.acknowledged').subscribe(translation => {
            this.acknowledged = translation;
        });
    }

    translateStatusDescription(status: number): string {
        switch(status) {
            case CampaignStatus.reviewed:
                return this.reviewedStatus;
            case CampaignStatus.pendingReview:
                return this.pendingReviewStatus;
            case CampaignStatus.inProgress:
                return this.inProgressStatus;
            case CampaignStatus.pending:
                return this.pendingStatus; 
            case CampaignStatus.pendingSign:
                return this.pendingSign;
            case CampaignStatus.discarded:
                return this.discarded;
                case CampaignStatus.acknowledged:
                    return this.acknowledged;
            default:
                return this.pendingStatus; ;
        }
    }
}