import { CampaignThirdParty } from "@app/models/CampaignThirdParty";
import { CampaignThirdPartyDto } from "src/proxy/model/campaignThirdPartyDto";

export function mapCampaignThirdParty(dto: CampaignThirdPartyDto): CampaignThirdParty {
    return <CampaignThirdParty> {
        campaignId: dto.CampaignId,
        campaignName: dto.CampaignName,
        endDate: dto.CampaignEndDate,
        progress: dto.Progress,
        responsibleUserId: dto.ResponsibleId,
        startDate: dto.CampaignStartDate,
        status: dto.Status,
        thirdPartyId: dto.ThirdPartyId,
        thirdPartyName: dto.ThirdPartyName,
        systemScore: dto?.SystemScore?.toString() ?? '-'
    };
}
