<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'createResolution.header' | translate }}</h1>
        <button mat-button (click)="closeModal()" class="c-dialog__close"
            attr.aria-label="{{'createResolution.close' | translate}}">
            <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path
                        d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
                        transform="translate(-309.86-406.47)" />
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content">
        <h2 class="c-dialog__subtitle">{{'createResolution.title' | translate }}</h2>
        <p class="info-text">{{'createResolution.subtitle' | translate }}</p>
        <form class="c-form">
            <fieldset>
                <legend class="c-form__legend">{{'createResolution.resolution'|translate}}</legend>
                <div class="row u-mt-1medium" aria-labelledby="requestedThirdParties">
                    <div class="col-lg-12 col-xl-12">
                        <mat-form-field class="c-select">
                            <mat-select name="reasonSelector" id="reasonSelector"
                                placeholder="{{ 'createResolution.reason' | translate }}" [(ngModel)]="selectedReason">
                                <mat-option value="1">
                                    {{ 'resolutionReason.noResults' | translate }}
                                </mat-option>
                                <mat-option value="2">
                                    {{ 'resolutionReason.noMatch' | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row u-mt-1medium" aria-labelledby="requestRemarks">
                    <div class="col-lg-12 col-xl-12">
                        <mat-form-field>
                            <input type="text" matInput name="requestRemarks" id="requestRemarks" class="c-form__input"
                                placeholder="{{'createResolution.explanation' | translate}}"
                                [(ngModel)]="resolutionExplanation" />
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </form>
        <footer class="c-dialog__footer no-paddingH u-mt-1medium u-pb-0">
            <mat-dialog-actions>
                <button class="c-button c-button--light" mat-button (click)="closeModal()">{{'createResolution.close' |
                    translate }}</button>
                <button class="c-button c-button--green" mat-button (click)="submitForm()">{{'createResolution.create' |
                    translate }}</button>
            </mat-dialog-actions>
        </footer>
    </div>
</div>