import { Component, Input } from "@angular/core";
import { EquifaxWSRelative } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSRelative";

@Component({
  selector: 'equifax-ws-individual-relative-data',
  templateUrl: './relative-data.component.html',
  styleUrls: ['./relative-data.component.scss'],
})
export class EquifaxWSIndividualRelativeDataComponent {
  @Input() relative: EquifaxWSRelative;

  constructor() { }
}
