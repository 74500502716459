<div class="row">
  <div class="col-12 col-md-9">
    <fieldset class="u-mb-1medium">
      <legend class="c-form__legend">
        {{ "ScoringCandidatesSearchGesintel.query" | translate }}
      </legend>
      <div class="row">
        <div class="col-12">
          <div class="c-form__block">
            <mat-form-field>
              <mat-label>
                {{ "ScoringCandidatesSearchGesintel.filter.nameOrRut" | translate }}
              </mat-label>
              <input matInput name="companyName" value="" id="companyName" [(ngModel)]="model.name"
                placeholder="{{'ScoringCandidatesSearchGesintel.filter.nameOrRut' | translate}}" required />
              <mat-error>
                {{ "ScoringCandidatesSearchGesintel.required" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
  <!-- <div class="col-12 col-md-3">
    <legend class="c-form__legend">
      {{ "ScoringCandidatesSearchGesintel.both.region" | translate }}
    </legend>
    <div class="row">
      <div class="col-12">
        <div class="c-form__block">
          <div class="c-select">
            <mat-form-field>
              <mat-label>{{
                "ScoringCandidatesSearchGesintel.filter.selectCountry" | translate
                }}</mat-label>
              <mat-select placeholder="{{'ScoringCandidatesSearchGesintel.filter.selectCountry' | translate }}"
                [(ngModel)]="model.country">
                <mat-option *ngFor="let country of countryList" [value]="country.codeAlpha3">
                  {{ country.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
<div class="row">
  <fieldset class="u-mb-1medium">
    <div class="row">
      <div class="col-12 col-lg-6 col-xl-3">
        <div class="c-form__block">
          <button (click)="search()" type="button" [disabled]="!model.name?.length" class="c-button u-mr-1">
            {{ "ScoringCandidatesSearchGesintel.search" | translate }}
          </button>
        </div>
      </div>
    </div>
  </fieldset>
</div>
