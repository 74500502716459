/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActivityNatureCreateDto } from '../model/activityNatureCreateDto';
import { ActivityNatureDto } from '../model/activityNatureDto';
import { ActivityNatureUpdateDto } from '../model/activityNatureUpdateDto';
import { ActivityTypeCreateDto } from '../model/activityTypeCreateDto';
import { ActivityTypeDto } from '../model/activityTypeDto';
import { ActivityTypeUpdateDto } from '../model/activityTypeUpdateDto';
import { CountryDto } from '../model/countryDto';
import { ExecutiveOrganDto } from '../model/executiveOrganDto';
import { FinanceTypesDto } from '../model/financeTypesDto';
import { IsoCertificationDto } from '../model/isoCertificationDto';
import { RiskCreateDto } from '../model/riskCreateDto';
import { RiskDto } from '../model/riskDto';
import { RiskUpdateDto } from '../model/riskUpdateDto';
import { RiskallayUserDto } from '../model/riskallayUserDto';
import { SectorCreateDto } from '../model/sectorCreateDto';
import { SectorDto } from '../model/sectorDto';
import { SectorUpdateDto } from '../model/sectorUpdateDto';
import { ThirdPartyTypeCreateDto } from '../model/thirdPartyTypeCreateDto';
import { ThirdPartyTypeDto } from '../model/thirdPartyTypeDto';
import { ThirdPartyTypeUpdateDto } from '../model/thirdPartyTypeUpdateDto';

import { Configuration }                                     from '../configuration';


@Injectable()
export class CommonsProxyClient {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityNatureGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ActivityNatureDto>>;
    public commonsActivityNatureGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActivityNatureDto>>>;
    public commonsActivityNatureGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActivityNatureDto>>>;
    public commonsActivityNatureGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ActivityNatureDto>>('get',`${this.basePath}/Commons/ActivityNature`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityNatureIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<ActivityNatureDto>;
    public commonsActivityNatureIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActivityNatureDto>>;
    public commonsActivityNatureIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActivityNatureDto>>;
    public commonsActivityNatureIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsActivityNatureIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ActivityNatureDto>('get',`${this.basePath}/Commons/ActivityNature/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityNatureIdPut(id: number, body?: ActivityNatureUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsActivityNatureIdPut(id: number, body?: ActivityNatureUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsActivityNatureIdPut(id: number, body?: ActivityNatureUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsActivityNatureIdPut(id: number, body?: ActivityNatureUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsActivityNatureIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Commons/ActivityNature/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityNatureListClientIdGet(clientId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ActivityNatureDto>>;
    public commonsActivityNatureListClientIdGet(clientId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActivityNatureDto>>>;
    public commonsActivityNatureListClientIdGet(clientId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActivityNatureDto>>>;
    public commonsActivityNatureListClientIdGet(clientId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling commonsActivityNatureListClientIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ActivityNatureDto>>('get',`${this.basePath}/Commons/ActivityNatureList/${encodeURIComponent(String(clientId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityNaturePost(body?: ActivityNatureCreateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsActivityNaturePost(body?: ActivityNatureCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsActivityNaturePost(body?: ActivityNatureCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsActivityNaturePost(body?: ActivityNatureCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Commons/ActivityNature`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityTypeGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ActivityTypeDto>>;
    public commonsActivityTypeGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActivityTypeDto>>>;
    public commonsActivityTypeGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActivityTypeDto>>>;
    public commonsActivityTypeGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ActivityTypeDto>>('get',`${this.basePath}/Commons/ActivityType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityTypeIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<ActivityTypeDto>;
    public commonsActivityTypeIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActivityTypeDto>>;
    public commonsActivityTypeIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActivityTypeDto>>;
    public commonsActivityTypeIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsActivityTypeIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ActivityTypeDto>('get',`${this.basePath}/Commons/ActivityType/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityTypeIdPut(id: number, body?: ActivityTypeUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsActivityTypeIdPut(id: number, body?: ActivityTypeUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsActivityTypeIdPut(id: number, body?: ActivityTypeUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsActivityTypeIdPut(id: number, body?: ActivityTypeUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsActivityTypeIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Commons/ActivityType/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityTypeListClientIdGet(clientId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ActivityTypeDto>>;
    public commonsActivityTypeListClientIdGet(clientId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActivityTypeDto>>>;
    public commonsActivityTypeListClientIdGet(clientId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActivityTypeDto>>>;
    public commonsActivityTypeListClientIdGet(clientId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling commonsActivityTypeListClientIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ActivityTypeDto>>('get',`${this.basePath}/Commons/ActivityTypeList/${encodeURIComponent(String(clientId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsActivityTypePost(body?: ActivityTypeCreateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsActivityTypePost(body?: ActivityTypeCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsActivityTypePost(body?: ActivityTypeCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsActivityTypePost(body?: ActivityTypeCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Commons/ActivityType`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsCountryGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CountryDto>>;
    public commonsCountryGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CountryDto>>>;
    public commonsCountryGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CountryDto>>>;
    public commonsCountryGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CountryDto>>('get',`${this.basePath}/Commons/Country`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsCountryIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<CountryDto>;
    public commonsCountryIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountryDto>>;
    public commonsCountryIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountryDto>>;
    public commonsCountryIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsCountryIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CountryDto>('get',`${this.basePath}/Commons/Country/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsExecutiveOrganGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ExecutiveOrganDto>>;
    public commonsExecutiveOrganGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ExecutiveOrganDto>>>;
    public commonsExecutiveOrganGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ExecutiveOrganDto>>>;
    public commonsExecutiveOrganGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ExecutiveOrganDto>>('get',`${this.basePath}/Commons/ExecutiveOrgan`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsFinanceTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<FinanceTypesDto>>;
    public commonsFinanceTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FinanceTypesDto>>>;
    public commonsFinanceTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FinanceTypesDto>>>;
    public commonsFinanceTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FinanceTypesDto>>('get',`${this.basePath}/Commons/FinanceTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsGetSectorListClientIdGet(clientId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SectorDto>>;
    public commonsGetSectorListClientIdGet(clientId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SectorDto>>>;
    public commonsGetSectorListClientIdGet(clientId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SectorDto>>>;
    public commonsGetSectorListClientIdGet(clientId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling commonsGetSectorListClientIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SectorDto>>('get',`${this.basePath}/Commons/GetSectorList/${encodeURIComponent(String(clientId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param fileUri
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsGetTokenFileUriGet(fileUri?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsGetTokenFileUriGet(fileUri?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsGetTokenFileUriGet(fileUri?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsGetTokenFileUriGet(fileUri?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileUri !== undefined && fileUri !== null) {
            queryParameters = queryParameters.set('fileUri', <any>fileUri);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/Commons/GetTokenFileUri`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsIsoCertificationGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IsoCertificationDto>>;
    public commonsIsoCertificationGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IsoCertificationDto>>>;
    public commonsIsoCertificationGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IsoCertificationDto>>>;
    public commonsIsoCertificationGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<IsoCertificationDto>>('get',`${this.basePath}/Commons/IsoCertification`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsResponsiblesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<RiskallayUserDto>>;
    public commonsResponsiblesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RiskallayUserDto>>>;
    public commonsResponsiblesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RiskallayUserDto>>>;
    public commonsResponsiblesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RiskallayUserDto>>('get',`${this.basePath}/Commons/Responsibles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsRiskGet(observe?: 'body', reportProgress?: boolean): Observable<Array<RiskDto>>;
    public commonsRiskGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RiskDto>>>;
    public commonsRiskGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RiskDto>>>;
    public commonsRiskGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RiskDto>>('get',`${this.basePath}/Commons/Risk`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsRiskIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<RiskDto>;
    public commonsRiskIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RiskDto>>;
    public commonsRiskIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RiskDto>>;
    public commonsRiskIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsRiskIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RiskDto>('get',`${this.basePath}/Commons/Risk/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsRiskIdPut(id: number, body?: RiskUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsRiskIdPut(id: number, body?: RiskUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsRiskIdPut(id: number, body?: RiskUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsRiskIdPut(id: number, body?: RiskUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsRiskIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Commons/Risk/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsRiskPost(body?: RiskCreateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsRiskPost(body?: RiskCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsRiskPost(body?: RiskCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsRiskPost(body?: RiskCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Commons/Risk`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsRolesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public commonsRolesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public commonsRolesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public commonsRolesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/Commons/Roles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsSectorGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SectorDto>>;
    public commonsSectorGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SectorDto>>>;
    public commonsSectorGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SectorDto>>>;
    public commonsSectorGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SectorDto>>('get',`${this.basePath}/Commons/Sector`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsSectorIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<SectorDto>;
    public commonsSectorIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SectorDto>>;
    public commonsSectorIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SectorDto>>;
    public commonsSectorIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsSectorIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SectorDto>('get',`${this.basePath}/Commons/Sector/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsSectorIdPut(id: number, body?: SectorUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsSectorIdPut(id: number, body?: SectorUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsSectorIdPut(id: number, body?: SectorUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsSectorIdPut(id: number, body?: SectorUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsSectorIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Commons/Sector/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsSectorPost(body?: SectorCreateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsSectorPost(body?: SectorCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsSectorPost(body?: SectorCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsSectorPost(body?: SectorCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Commons/Sector`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsThirdPartyTypeGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ThirdPartyTypeDto>>;
    public commonsThirdPartyTypeGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ThirdPartyTypeDto>>>;
    public commonsThirdPartyTypeGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ThirdPartyTypeDto>>>;
    public commonsThirdPartyTypeGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ThirdPartyTypeDto>>('get',`${this.basePath}/Commons/ThirdPartyType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsThirdPartyTypeIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<ThirdPartyTypeDto>;
    public commonsThirdPartyTypeIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ThirdPartyTypeDto>>;
    public commonsThirdPartyTypeIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ThirdPartyTypeDto>>;
    public commonsThirdPartyTypeIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsThirdPartyTypeIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ThirdPartyTypeDto>('get',`${this.basePath}/Commons/ThirdPartyType/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsThirdPartyTypeIdPut(id: number, body?: ThirdPartyTypeUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsThirdPartyTypeIdPut(id: number, body?: ThirdPartyTypeUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsThirdPartyTypeIdPut(id: number, body?: ThirdPartyTypeUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsThirdPartyTypeIdPut(id: number, body?: ThirdPartyTypeUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commonsThirdPartyTypeIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Commons/ThirdPartyType/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commonsThirdPartyTypePost(body?: ThirdPartyTypeCreateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commonsThirdPartyTypePost(body?: ThirdPartyTypeCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commonsThirdPartyTypePost(body?: ThirdPartyTypeCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commonsThirdPartyTypePost(body?: ThirdPartyTypeCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Commons/ThirdPartyType`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
