<div class="o-wrapper o-wrapper--no-padding">
    <main id="content">
        <section class="c-risklist">
            <h2 class="o-title o-title--small">{{'OnBoardingIntro.title1.valor'|translate}}</h2>
            <h3 class="o-title o-title--thin">¡{{'OnBoardingIntro.hi.valor'|translate}} <span>{{auth.getFieldJWT('Name')}}</span>!</h3>
            <p class="o-text o-text--maxwidth u-mt-1half">{{'OnBoardingIntro.text1.valor'|translate}} <strong>{{'OnBoardingIntro.text2.valor'|translate}}</strong>.</p>
            <p class="o-text o-text--maxwidth u-mt-1">{{'OnBoardingIntro.info.valor'|translate}}</p>
            <div class="c-group-btn-img">
                <a [routerLink]="['/onboarding-rellenar-ficha-cliente']" routerLinkActive="is-active" class="c-button c-button--gradient">{{'OnBoardingIntro.configura.valor'|translate}}</a>
                <div class="c-group-btn-img__image" aria-hidden="true">
                    <img src="assets/intro-onboarding.svg" alt="Dos personas manejando un panel de información" loading="lazy" />
                </div>
            </div>
        </section>
    </main>
</div>