<div class="relative-data">
  <div class="relative-data__group" aria-labelledby="keyData">
    <div class="relative-data-item">
      <p id="name" class="relative-data-item__title">
        {{ "EquifaxWSGeneralData.name" | translate }}
      </p>
      <p class="relative-data-item__text" aria-labelledby="name">
        {{ relative?.name || "" }}
      </p>
    </div>
    <div class="relative-data-item">
      <p id="cedula" class="relative-data-item__title">
        {{ "EquifaxWSGeneralData.cedula" | translate }}
      </p>
      <p class="relative-data-item__text" aria-labelledby="cedula">
        {{ relative?.cedula || "" }}
      </p>
    </div>
    <div class="relative-data-item">
      <p id="kinship" class="relative-data-item__title">
        {{ "EquifaxWSGeneralData.kniship" | translate }}
      </p>
      <p class="relative-data-item__text" aria-labelledby="kinship">
        {{ relative?.kinship || "" }}
      </p>
    </div>
    <div class="relative-data-item">
      <p id="nationality" class="relative-data-item__title">
        {{ "EquifaxWSGeneralData.nationality" | translate }}
      </p>
      <p class="relative-data-item__text" aria-labelledby="nationality">
        {{ relative?.nationality || "" }}
      </p>
    </div>
  </div>
</div>
