import { Injectable } from "@angular/core";
import { Risk } from "@app/models/Risk";
import { Sector, SectorDTO } from "@app/models/Sector";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CommonsProxyClient } from "src/proxy/apiCommons/commons.proxy";
import { mapRiskDtoToRisk } from "./mappings/risk.mappings";
import { mapSector, mapSectorDtoToSector } from "./mappings/third-party.mappings";

@Injectable({
    providedIn: 'root'
})
export class SectorService {
    constructor(
        private commonsClient: CommonsProxyClient,
        private translate: TranslateService
    ) { }

    getSectors(): Observable<Sector[]> {
        mapSector
        return this.commonsClient
            .commonsSectorGet()
            .pipe(
                map(sectors=> sectors.map(sector => mapSectorDtoToSector(sector)))
            );
    }
}