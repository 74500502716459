<div role="dialog" class="c-dialog" aria-labelledby="titleDialog" style="display: block; visibility: visible">
  <header class="c-dialog__header">
    <h1 id="titleDialog" class="c-dialog__title">
      {{ "DialogRefreshScreening.Title.valor" | translate }}
    </h1>
    <button mat-button (click)="onNoClick()" class="c-dialog__close" aria-label="Cerrar ventana modal">
      <span aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
          <path
            d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
            transform="translate(-309.86-406.47)" />
        </svg>
      </span>
    </button>
  </header>
  <div class="c-dialog__content small-padding">
    <h2 class="c-dialog__subtitle">
      {{ "DialogRefreshScreening.Subtitle.valor" | translate }}
    </h2>
    <div class="c-fields">
      <p id="infoThird" class="c-fields__legend u-mt-1medium">
        {{ data.name }}
      </p>
      <div class="c-fields__group" aria-labelledby="infoScoring">
        <div class="c-fields-item-dialog">
          <p id="nameScoring" class="c-fields-item__title">
            {{ "DialogRefreshScreening.Location.valor" | translate }}
          </p>
          <p class="c-fields-item__text-dark" aria-labelledby="nameScoring">
            {{ data.location }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <footer class="c-dialog__footer">
    <mat-dialog-actions>
      <button class="c-button" mat-button [mat-dialog-close]="false">
        {{ "DialogRefreshScreening.Cancel.valor" | translate }}
      </button>
      <button class="c-button c-button--green" mat-button [mat-dialog-close]="true">
        {{ "DialogRefreshScreening.Continue.valor" | translate }}
      </button>
    </mat-dialog-actions>
  </footer>
</div>
