import { AliasDto } from 'src/proxy/model/aliasDto';
import { Alias } from '../../models/GesintelScreening/Alias';

export function mapAlias(dto: AliasDto): Alias {
  if (!dto) return null;

  return <Alias>{
    firstName: dto.FirstName,
    middleName: dto.MiddleName,
    lastName: dto.LastName,
    originalScriptName: dto.OriginalScriptName,
  };
}
