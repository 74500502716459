<div class="o-wrapper">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section c-head-section--bg">
            <div class="c-head-section__left">
                <h2 class="o-title"><strong>{{'thirdPartiesAdded.title' | translate}}</strong></h2>
                <p class="o-text">
                    {{('thirdPartiesAdded.text' | translate).replace('${assessmentName}', this.model ? this.model.Name : '').replace('${thirdPartiesCount}', this.global.uploadedThirdParties ? this.global.uploadedThirdParties.length: '')}}
                    
                </p>
            </div>
            <div class="c-head-section__right" aria-hidden="true">
                <a class="c-button c-button--large" [routerLink]="['/detalle-evaluacion', this.model ? this.model.Id : 0]">
                    {{'thirdPartiesAdded.back' | translate}}
                </a>
            </div>
        </div>
        <!--volver atrás-->
        <div class="c-go-back c-go-back--steps">
            <a [routerLink]="['/detalle-evaluacion', this.model ? this.model.Id : 0]" class="c-go-back__button">
                <span class="c-go-back__arrow" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                        <path
                            d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                            transform="translate(-5-6.01)" />
                    </svg>
                </span> {{'thirdPartiesAdded.back' | translate}}
            </a>
        </div>
        <!--tabla-->
        <app-third-party-table-extended [config]="Imported3PPConfig"></app-third-party-table-extended>
    </main>
</div>
