import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ResolutionReasonEnum } from "@app/models/scoring-tools/resolutionReasonEnum";
import { ResolutionRecord } from "@app/models/scoring-tools/resolutionRecord";
import { TranslateService } from "@ngx-translate/core";
import { Location } from "@angular/common";
import { ScoringToolSearchTypeEnum } from "@app/models/scoring-tools/scoringToolSearchTypeEnum";
import download from "downloadjs";
import { ResolutionReportService } from "@app/services/reports/resolution-report.service";
import { Toaster } from "ngx-toast-notifications";
import { ScreeningProvider } from "../../models/ScreeningProvider";

@Component({
    selector: 'app-resolution-record-details',
    templateUrl: './resolution-record-details.component.html',
    styleUrls: ['./resolution-record-details.component.scss']
})
export class ResolutionRecordDetailsComponent implements OnInit {

    resolutionRecord: ResolutionRecord;
    name: string;
    translationsReason: any;
    translationsSearchType: any;
    translationsResolutionDetails: any;
    ScreeningProvider = ScreeningProvider;

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private reportService: ResolutionReportService,
        private toaster: Toaster,
        private location: Location
    ) {
        this.resolutionRecord = this.router.getCurrentNavigation().extras.state.data;
        this.name = this.router.getCurrentNavigation().extras.state.name;
        this.translateService.get("resolutionReason").subscribe(result => this.translationsReason = result);
        this.translateService.get("searchTypes").subscribe(result => this.translationsSearchType = result);
        this.translateService.get("resolutionDetails").subscribe(result => this.translationsResolutionDetails = result);
    }

    ngOnInit() { }

    getReason(reason: ResolutionReasonEnum): string {
        switch (reason) {
            case ResolutionReasonEnum.NoMatches:
                return this.translationsReason.noMatch;
            case ResolutionReasonEnum.NoResults:
                return this.translationsReason.noResults;
            default:
                return '';
        }
    }

    getSearchTypeName(searchType: ScoringToolSearchTypeEnum): string {
        switch (searchType) {
            case ScoringToolSearchTypeEnum.Individual:
                return this.translationsSearchType.individual;
            case ScoringToolSearchTypeEnum.Organization:
                return this.translationsSearchType.organization;
            case ScoringToolSearchTypeEnum.All:
                return this.translationsSearchType.all
            default:
                return '';
        }
    }

    back() {
        this.location.back();
    }

    async downloadReport(){
        this.toaster.open({ text: this.translationsResolutionDetails.generatingReport, duration: 4000, type: 'info' });
        const a = await this.reportService.getReport(this.name, this.resolutionRecord);
        this.toaster.open({ text: this.translationsResolutionDetails.reportGenerated, duration: 4000, type: 'success' });
        download(a[1], a[0], "application/pdf");
    }

}
