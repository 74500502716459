<div class="o-wrapper">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section">
            <div class="c-head-section__left">
                <h2 class="o-title">{{'Campañas.title' | translate}}</h2>
                <p class="o-text">{{'Campañas.text' | translate}}</p>
            </div>
            <div class="c-head-section__right">
                <button *appAuthRole="['Owner','Manager','Assistant','Collaborator']" type="button" (click)="activeCampaign.activeCampaign(0)" routerLinkActive="is-active" class="c-button c-button--campaign">
                    <span class="c-button--campaign__text">{{'Campañas.button.newCampaign.valor' | translate}}</span>
                </button>
            </div>
        </div>
        <mat-tab-group class="c-tabs">
            <mat-tab label="{{ 'TablaCampaña.tab.all' | translate}}">
                <app-campaign-table [statusPreFilter]="''"></app-campaign-table>
            </mat-tab>
            <mat-tab label="{{ 'TablaCampaña.tab.inProgress' | translate}}">
                <app-campaign-table [statusPreFilter]="'inProgress'"></app-campaign-table>
            </mat-tab>
            <mat-tab label="{{ 'TablaCampaña.tab.programmed' | translate}}">
                <app-campaign-table [statusPreFilter]="'programmed'"></app-campaign-table>
            </mat-tab>
            <mat-tab label="{{ 'TablaCampaña.tab.finished' | translate}}">
                <app-campaign-table [statusPreFilter]="'finished'"></app-campaign-table>
            </mat-tab>
        </mat-tab-group>
    </main>
</div>
