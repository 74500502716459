import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-sign-checklist',
  templateUrl: './dialog-sign-checklist.component.html',
  styles: [
  ]
})
export class DialogSignChecklistComponent {
  constructor(public dialogRef: MatDialogRef<DialogSignChecklistComponent>) { }

  onClose(): void {
    this.dialogRef.close();
  }
}
