import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-screening-scorings-container',
  templateUrl: './screening-scorings-container.component.html',
  styleUrls: ['./screening-scorings-container.component.scss'],
})
export class ScreeningScoringsContainerComponent {
  @Output() newScoring = new EventEmitter();

  onNewScoring(): void {
    this.newScoring.emit();
  }
}
