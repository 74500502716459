import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { RisklistFillComponent } from '@app/modules/myrisks/risklist-fill/risklist-fill.component';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsDirtyGuardService implements CanDeactivate<RisklistFillComponent> {
  canDeactivate(component: RisklistFillComponent,  currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {
    if (nextState.url === '/login') {
      return of(true);
    }

    return component.confirmExitingIfDirty();
  }
}
