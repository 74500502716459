import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Country } from '@app/models/Country';
import { OrganizationFilter } from '@app/models/scoring-tools/organization-filter';

@Component({
  selector: 'app-scoring-candidates-organization-search-refinitiv',
  templateUrl:
    './scoring-candidates-organization-search-refinitiv.component.html',
})
export class ScoringCandidatesOrganizationSearchRefinitivComponent {
  @Input() countryList: Country[];
  @Output() onSearchClick = new EventEmitter<OrganizationFilter>();

  model: OrganizationFilter;

  constructor() {
    this.model = new OrganizationFilter();
  }

  search() {
    this.onSearchClick.emit(this.model);
  }
}
