import { Component, Input, OnChanges } from '@angular/core';
import { QueryResult } from '@app/models/common/queryResult';
import { Alias } from '@app/modules/screening-hub/models/GesintelScreening/Alias';
import { RelatedPerson } from '@app/modules/screening-hub/models/GesintelScreening/RelatedPerson';

@Component({
  selector: 'aml-update-alias-list',
  templateUrl: './alias-list.component.html',
  styleUrls: ['./alias-list.component.scss'],
})
export class AMLUpdateAliasListComponent implements OnChanges {
  @Input() alias: Alias[];

  displayedColumns: string[] = [
    'name',
    'middleName',
    'lastname',
    'originalScript'
  ];

  queryResult: QueryResult<Alias> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() { }

  ngOnChanges(): void {
    this.getAlias();
  }

  getAlias() {
    if (this.alias) {
      this.queryResult.items = this.alias
        .slice(
          (this.query.Page - 1) * this.query.PageSize,
          this.query.Page * this.query.PageSize
        );
      this.queryResult.totalItems = this.alias.length;
    }
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getAlias();
  }
}
