export interface Gender {
  code: string;
  Description: string;
  DescriptionEN: string;
  DescriptionPT: string;
  DescriptionFR: string;
}

export function getGenderList(): Gender[] {
  return [
    <Gender>{ code: 'MALE', Description: 'Masculino', DescriptionEN: 'Male', DescriptionPT: 'Masculino', DescriptionFR: 'Mâle' },
    <Gender>{ code: 'FEMALE', Description: 'Femenino', DescriptionEN: 'Female', DescriptionPT: 'Feminino', DescriptionFR: 'Femme' },
    <Gender>{ code: 'UNSPECIFIED', Description: 'Sin especificar', DescriptionEN: 'Unspecified', DescriptionPT: 'Não especificado', DescriptionFR: 'Non spécifié' }
  ];
}
