import { AMLUpdateRecordDto } from 'src/proxy/model/aMLUpdateRecordDto';
import { AMLUpdateRecord } from '../../models/GesintelScreening/AMLUpdateRecord';
import { mapOwnership } from './ownership.mapping';
import { mapPEPListInfo } from './pep-list-info.mapping';
import { mapRelatedPerson } from './related-person.mapping';
import { mapWatchListInfo } from './watch-list-info.mapping';

export function mapAMLUpdateRecord(dto: AMLUpdateRecordDto): AMLUpdateRecord {
  if (!dto) return null;

  return <AMLUpdateRecord>{
    fullName: dto.FullName,
    rut: dto.Rut,
    nationality: dto.Nationality,
    birthDate: dto.BirthDate,
    deceaseDate: dto.DeceaseDate,
    parentalMesh: dto.ParentalMesh?.map((rp) => mapRelatedPerson(rp)),
    watchListInfo: mapWatchListInfo(dto.WatchListInfo, dto.EntityType),
    pepListInfo: mapPEPListInfo(dto.PEPListInfo),
    ownerships: dto.Ownerships?.map((o) => mapOwnership(o)),
    entityType: dto.EntityType,
    registerDate: dto.DateOfRegistration,
    adverseMediaInfo: mapWatchListInfo(dto.AdverseMediaInfo, dto.EntityType),
    pdfReport: dto.PdfReport
  };
}
