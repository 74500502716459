import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { RiskCreateDTO, RiskDTO, RiskUpdateDTO } from '@app/models/Risk';
import { CommonsService } from '../../../services/commons-service';

@Component({
  selector: 'app-dialog-add-risk',
  templateUrl: './dialog-add-risk.component.html',
  styles: [
  ]
})
export class DialogAddRiskComponent implements OnInit {
  form: any;
  entry: RiskDTO = null;

  constructor(

    public dialogRef: MatDialogRef<DialogAddRiskComponent>,
    private fb: FormBuilder,
    private commons: CommonsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    let entry = this.data;

    this.form = this.fb.group({
      Description: new FormControl(entry !== null ? entry.Description : '', Validators.required),
      DescriptionEN: new FormControl(entry !== null ? entry.DescriptionEN : '', Validators.required),
      DescriptionPT: new FormControl(entry !== null ? entry.DescriptionPT : '', Validators.required),
      DescriptionFR: new FormControl(entry !== null ? entry.DescriptionFR : '', Validators.required),
    });
  }

  // cerrar modal
  onNoClick(): void {
    this.dialogRef.close();
  }

  async addRisk() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;

    let r = (<RiskCreateDTO>{});
    r.Code = description;
    r.Description = description;
    r.DescriptionEN = descriptionEN;
    r.DescriptionPT = descriptionPT;
    r.DescriptionFR = descriptionFR;
    r.Client = null;

    this.commons.postRisk(r)
      .then(r => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }

  async updateRisk() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;

    let r = (<RiskUpdateDTO>{});
    r.Id = this.data.Id;
    r.Code = description;
    r.Description = description;
    r.DescriptionEN = descriptionEN;
    r.DescriptionPT = descriptionPT;
    r.DescriptionFR = descriptionFR;
    r.Client = null;

    this.commons.putRisk(r)
      .then(r => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }
}
