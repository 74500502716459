<ng-container
  *ngIf="campaignThirdParty.status == campaignStatus.pending && !campaignFinished(); else riskboardTemplate">
  <a *appAuthRole="['Owner', 'Manager']" [routerLink]=""
    (click)="resendInvitation(campaignThirdParty.thirdPartyId, campaignThirdParty.campaignId)" class="c-table__watch"
    lang="en">
    <span class="c-box-edit__button-ico" aria-hidden="true">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2905_58097)">
          <path
            d="M15.8333 0.833313H4.16667C3.062 0.834636 2.00296 1.27405 1.22185 2.05516C0.440735 2.83628 0.00132321 3.89532 0 4.99998L0 15C0.00132321 16.1046 0.440735 17.1637 1.22185 17.9448C2.00296 18.7259 3.062 19.1653 4.16667 19.1666H15.8333C16.938 19.1653 17.997 18.7259 18.7782 17.9448C19.5593 17.1637 19.9987 16.1046 20 15V4.99998C19.9987 3.89532 19.5593 2.83628 18.7782 2.05516C17.997 1.27405 16.938 0.834636 15.8333 0.833313ZM4.16667 2.49998H15.8333C16.3323 2.50096 16.8196 2.65124 17.2325 2.93148C17.6453 3.21172 17.9649 3.6091 18.15 4.07248L11.7683 10.455C11.2987 10.9227 10.6628 11.1854 10 11.1854C9.33715 11.1854 8.70131 10.9227 8.23167 10.455L1.85 4.07248C2.03512 3.6091 2.35468 3.21172 2.76754 2.93148C3.1804 2.65124 3.66768 2.50096 4.16667 2.49998ZM15.8333 17.5H4.16667C3.50363 17.5 2.86774 17.2366 2.3989 16.7677C1.93006 16.2989 1.66667 15.663 1.66667 15V6.24998L7.05333 11.6333C7.83552 12.4135 8.89521 12.8517 10 12.8517C11.1048 12.8517 12.1645 12.4135 12.9467 11.6333L18.3333 6.24998V15C18.3333 15.663 18.0699 16.2989 17.6011 16.7677C17.1323 17.2366 16.4964 17.5 15.8333 17.5Z"
            fill="#F8B410" />
        </g>
        <defs>
          <clipPath id="clip0_2905_58097">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span> {{'TablaCampaña.action.resend' | translate}}
  </a>
</ng-container>
<ng-template #riskboardTemplate>
  <ng-container *ngIf="campaignThirdParty.status == campaignStatus.pendingReview || campaignThirdParty.status == campaignStatus.reviewed || campaignThirdParty.status == campaignStatus.discarded || campaignThirdParty.status == campaignStatus.acknowledged; else disabledRiskboard">
    <a *appAuthRole="['Owner', 'Manager', 'Assistant']"
      [routerLink]="['/evaluaciones-lanzadas-riskboard/',campaignThirdParty.thirdPartyId,campaignThirdParty.campaignId]"
      class="c-table__watch" lang="en">
      <span class="c-box-edit__button-ico" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-labelledby="navRiskboard">
          <title id="navRiskboard">{{'TablaCampaña.action.riskboard.valor' | translate}}
          </title>
          <path class="shape"
            d="M19.167,18.333H2.5a.833.833,0,0,1-.833-.833V.833A.833.833,0,1,0,0,.833V17.5A2.5,2.5,0,0,0,2.5,20H19.167a.833.833,0,0,0,0-1.667Z" />
          <path class="shape"
            d="M14.833,18.5a.833.833,0,0,0,.833-.833V11.833a.833.833,0,1,0-1.667,0v5.833A.833.833,0,0,0,14.833,18.5Z"
            transform="translate(-2.333-1.833)" />
          <path class="shape"
            d="M6.833,18.5a.833.833,0,0,0,.833-.833V11.833a.833.833,0,1,0-1.667,0v5.833A.833.833,0,0,0,6.833,18.5Z"
            transform="translate(-1-1.833)" />
          <path class="shape"
            d="M18.833,17.667a.833.833,0,0,0,.833-.833v-10a.833.833,0,1,0-1.667,0v10A.833.833,0,0,0,18.833,17.667Z"
            transform="translate(-3-1)" />
          <path class="shape"
            d="M10.833,17.667a.833.833,0,0,0,.833-.833v-10a.833.833,0,0,0-1.667,0v10A.833.833,0,0,0,10.833,17.667Z"
            transform="translate(-1.667-1)" />
        </svg>
      </span> {{'TablaCampaña.action.riskboard.valor' | translate}}
    </a>
  </ng-container>
  <ng-template #disabledRiskboard>
    <div *appAuthRole="['Owner', 'Manager']" class="c-table__watch__disabled">
      <span class="c-box-edit__button-ico" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-labelledby="navRiskboard">
          <title id="navRiskboard">{{'Tabla3PP.actions.riskboard.valor' | translate}}</title>
          <path class="shape"
            d="M19.167,18.333H2.5a.833.833,0,0,1-.833-.833V.833A.833.833,0,1,0,0,.833V17.5A2.5,2.5,0,0,0,2.5,20H19.167a.833.833,0,0,0,0-1.667Z" />
          <path class="shape"
            d="M14.833,18.5a.833.833,0,0,0,.833-.833V11.833a.833.833,0,1,0-1.667,0v5.833A.833.833,0,0,0,14.833,18.5Z"
            transform="translate(-2.333-1.833)" />
          <path class="shape"
            d="M6.833,18.5a.833.833,0,0,0,.833-.833V11.833a.833.833,0,1,0-1.667,0v5.833A.833.833,0,0,0,6.833,18.5Z"
            transform="translate(-1-1.833)" />
          <path class="shape"
            d="M18.833,17.667a.833.833,0,0,0,.833-.833v-10a.833.833,0,1,0-1.667,0v10A.833.833,0,0,0,18.833,17.667Z"
            transform="translate(-3-1)" />
          <path class="shape"
            d="M10.833,17.667a.833.833,0,0,0,.833-.833v-10a.833.833,0,0,0-1.667,0v10A.833.833,0,0,0,10.833,17.667Z"
            transform="translate(-1.667-1)" />
        </svg>
      </span> {{'Tabla3PP.actions.riskboard.valor' | translate}}
    </div>
  </ng-template>
  <a [routerLink]="['/evaluation-answers',campaignThirdParty.thirdPartyId,campaignThirdParty.campaignId, campaignThirdParty.status == campaignStatus.pendingReview || campaignThirdParty.status == campaignStatus.reviewed || campaignThirdParty.status == campaignStatus.discarded || campaignThirdParty.status == campaignStatus.acknowledged ? 1 : 0]"
    class="c-table__watch">
    <span class="c-box-edit__button-ico" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12.747">
        <path
          d="m19.873 98.82c-.179-.244-4.436-5.985-9.873-5.985s-9.695 5.74-9.873 5.984a.66 .66 0 0 0 0 .778c.178.244 4.436 5.985 9.873 5.985s9.694-5.74 9.873-5.984a.659 .659 0 0 0 0 -.778m-9.873 5.444c-4 0-7.474-3.81-8.5-5.05 1.026-1.247 4.487-5.06 8.5-5.06s7.473 3.809 8.5 5.06c-1.025 1.247-4.486 5.05-8.5 5.05"
          transform="translate(0-92.83)" />
        <path
          d="M158.678 154.725a3.956 3.956 0 1 0 3.956 3.956A3.961 3.961 0 0 0 158.678 154.725m0 6.593a2.637 2.637 0 1 1 2.637 -2.637A2.64 2.64 0 0 1 158.678 161.318"
          transform="translate(-148.68-152.3)" />
      </svg>
    </span> {{'Tabla3PP.actions.question.valor' | translate}}
  </a>
</ng-template>
