<div class="o-wrapper">
    <main id="content">
        <div class="c-head-section small-height">
            <div class="c-head-section__left">
                <h2 class="o-title">{{ 'Plantillas.title' | translate }}</h2>
                <p class="o-text">{{ 'Plantillas.text' | translate }}</p>
            </div>
        </div>
        <app-template-table [config]="templateConfig"> </app-template-table>
    </main>
</div>
