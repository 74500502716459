import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sector } from '@app/models/Sector';
import { ThirdPartyConfigRequest } from '@app/models/ThirdPartyConfigRequest';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { ThirdPartySignUpService } from '@app/services/third-party-signup.service';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { forkJoin } from 'rxjs';
import { ActivityTypeDto } from 'src/proxy/model/activityTypeDto';
import { CountryDto } from 'src/proxy/model/countryDto';

@Component({
  selector: 'app-dialog-fill-client-data',
  templateUrl: './dialog-fill-client-data.component.html',
  styleUrls: ['./dialog-fill-client-data.component.scss'],
})
export class DialogFillClientDataComponent implements OnInit {
  thirdPartyClientInfoForm: FormGroup;
  countries: CountryDto[];
  sectors: Sector[];
  typeOfActivities: ActivityTypeDto[];

  informationUpdatedMessage: string;
  informationUpdateErrorMessage: string;

  isLoading = true;

  constructor(
    private thirdPartySignup: ThirdPartySignUpService,
    private thirdPartiesService: ThirdPartiesService,
    public dialogRef: MatDialogRef<DialogFillClientDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ThirdPartyConfigRequest,
    private fb: FormBuilder,
    private toast: Toaster,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const model = {
      id: [this.data.id],
      clientId: [this.data.clientId],
      thirdPartyId: [this.data.thirdPartyId],
      companyName: [this.data.companyName ?? '', Validators.required],
      clientName: [this.data.clientName],
      taxId: [this.data.taxId ?? '', Validators.required],
      countryId: [
        this.data.countryId > 0 ? this.data.countryId : null,
        Validators.required,
      ],
      typeOfActivityId: [
        this.data.typeOfActivityId > 0 ? this.data.typeOfActivityId : null,
        Validators.required,
      ],
      sectorId: [
        this.data.sectorId > 0 ? this.data.sectorId : null,
        Validators.required,
      ],
      address: [this.data.address, Validators.required],
      zipCode: [this.data.zipCode],
    };


    this.thirdPartyClientInfoForm = this.fb.group(model);

    this.translate
      .get('fillThirdPartyClientData.informationUpdatedMessage')
      .subscribe((translation) => {
        this.informationUpdatedMessage = translation;
      });

    this.translate
      .get('fillThirdPartyClientData.informationUpdateErrorMessage')
      .subscribe((translation) => {
        this.informationUpdateErrorMessage = translation;
      });

    forkJoin({
      countries: this.thirdPartySignup.getCountries(),
      sectors: this.thirdPartySignup.getSectors(this.data.clientId),
      activityTypes: this.thirdPartySignup.getActivityTypes(this.data.clientId),
    }).subscribe(({ countries, sectors, activityTypes }) => {
      this.countries = countries;
      this.sectors = sectors;
      this.typeOfActivities = activityTypes;
      this.isLoading = false;
    });
  }

  sendClientThirdPartyData(): void {
    this.thirdPartiesService
      .completeConfigRequest(this.thirdPartyClientInfoForm.value as ThirdPartyConfigRequest)
      .subscribe(result => {
        if (result) {
          this.toast.open({ text: this.informationUpdatedMessage, duration: 6000 });
          this.dialogRef.close();
        } else {
          this.toast.open({
            text: this.informationUpdateErrorMessage,
            duration: 6000,
            type: 'danger',
          });
        }});
  }
}
