<section class="c-table">
    <div class="c-table__top">
        <div class="row">
            <!--buscador y filtros-->
            <div class="col-8">
                <app-text-table-filter (filter)="onFilter($event)"></app-text-table-filter>
            </div>
            <div class="col-4 u-mr-0 d-flex justify-content-end align-items-center" *ngIf="showAddThirdPartiesButton()">
                <ng-container *appAuthRole="['Owner','Manager']">
                    <a [routerLink]="['/anadir-terceros', campaignId]" class="c-button c-dropdown-button__button mat-button mat-button-base"
                        style="cursor: pointer;">
                            + {{'TablaCampaña.addThirdParties' | translate}}
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="o-scroll">
        <div class="c-table__bottom">
            <div *ngIf="this.filteredCTPs == null">
                <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
            </div>
            <table mat-table [dataSource]="this.filteredCTPs" class="mat-elevation-z8" matSort
                (matSortChange)="sortBy($event)">
                <ng-container matColumnDef="thirdPartyName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'Tabla3PP.header.name.valor' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="bold"> <span>{{element.thirdPartyName}}</span> </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'campaignThirdPartyTable.header.status' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <app-campaign-third-party-status [campaignThirdParty]="element"></app-campaign-third-party-status>
                    </td>
                </ng-container>
                <ng-container matColumnDef="progress">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [disabled]="true">
                        {{'TablaCampaña.header.progress.valor' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="c-progress-bar">
                            <mat-progress-bar aria-labelledby="progressBar" class="c-progress-bar__bar" [color]="color"
                                [value]="element.progress">
                            </mat-progress-bar>
                            <p id="progressBar" class="c-progress-bar__percent">{{element.progress}}%</p>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="score">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
                        Total score
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.systemScore}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="responsible">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
                        {{'TablaCampaña.header.responsible.valor' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <app-responsible-user [responsibleUserId]="element.responsibleUserId"></app-responsible-user>
                    </td>
                </ng-container>
                <!--acciones-->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
                        {{'TablaCampaña.header.actions.valor' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <app-campaing-third-party-table-actions [campaignThirdParty]="element"></app-campaing-third-party-table-actions>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="this.tableColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: this.tableColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9">
                        {{'TablaCampaña.noresults.valor' | translate}}
                    </td>
                </tr>
            </table>
            <mat-paginator #paginator (page)="onPageChange($event)" [pageSize]="pageSize"
                [length]="totalCTPs" [pageSizeOptions]="[5, 10, 25, 100]" class="c-pagination">
            </mat-paginator>
        </div>
    </div>
</section>
