import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BusinessCentralProxyClient } from 'src/proxy/apiBusinessCentral/business-central.proxy';

@Injectable()
export class BusinessCentralService {
  constructor(private businessCentralClient: BusinessCentralProxyClient) {}

  sendDataToBC(tpId: number): Observable<string> {
    return this.businessCentralClient.businessCentralTpIdGet(tpId);
  }

  syncToExistingBC(tpId: number): Observable<string> {
    return this.businessCentralClient.businessCentralSyncToExistingBcTpIdGet(
      tpId
    );
  }
}
