<div class="o-wrapper">
  <main id="content" class="container-fluid">
    <!--encabezado de sección-->
    <div class="c-head-section c-head-section--block c-head-section--bg bg-cream">
      <div class="c-head-section__left">
        <!--se deja por si en algún momento se quisiera volver a agregar-->
        <div *ngIf="false" class="c-head-section__image" aria-hidden="true">
          <img src="" alt="" loading="lazy" />
        </div>
        <div class="c-head-section--tab__info">
          <h2 class="c-head-section--tab__title">{{campaign?.ThirdParty.CompanyName}}</h2>
          <div class="c-head-section--tab-data">
            <div class="c-head-section--tab-data__item">
              <div class="c-head-section--tab-data__ico" aria-hidden="true">
                <app-campaign-third-party-status
                  [campaignThirdParty]="campaignThirdParty"></app-campaign-third-party-status>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-head-section__right" style="float: right;">
        <button *appAuthRole="['Owner','Manager']" class="c-button c-button--light"
          [routerLink]="['/perfil-tercero',thirdPartyId]">
          {{ 'CampaignRiskboard.viewThirdParty' | translate}}
        </button>
        <button *appAuthRole="['Owner','Manager','Auditor']" class="c-button" (click)="downloadReport()">
          {{ 'CampaignRiskboard.download.report.valor' | translate}}
        </button>
      </div>
      <div class="c-go-back">
        <button (click)="goBack()" class="c-go-back__button">
          <span class="c-go-back__arrow" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
              <path
                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                transform="translate(-5-6.01)" />
            </svg>
          </span> {{ 'CampaignRiskboard.back.valor' | translate}}
        </button>
      </div>
    </div>
    <!--checklist enviada-->
    <section class="u-spaceLeft-bootstrap u-mb-1">
      <h2 class="o-title o-title--medium u-mb-1medium">{{ 'CampaignRiskboard.template.send.valor' | translate}}</h2>
      <div class="row">
        <div class="col-12">
          <div class="c-data-campaign__block">
            <div class="c-data-campaign__input-modal">
              <input type="text" class="c-form__input" disabled="disabled" value="{{campaign?.Questionary.Code}}" />
              <a
                [routerLink]="['/evaluation-answers', thirdPartyId, campaignId, campaignThirdParty?.status == campaignStatus.pendingReview || campaignThirdParty?.status == campaignStatus.reviewed || campaignThirdParty?.status == campaignStatus.discarded || campaignThirdParty?.status == campaignStatus.acknowledged ? 1 : 0]">
                <span aria-hidden="true">
                  <img src="assets/ico-eye-open-color.svg" alt="" loading="lazy" />
                </span> {{ 'CampaignRiskboard.see.questionary.valor' | translate}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--notas de evaluacion-->
    <section class="u-spaceLeft-bootstrap u-mb-1">
      <h2 class="o-title o-title--medium">{{'EvaluationQuestionaryComment.title' | translate}}</h2>
      <div class="row">
        <div class="col-12">
          <div class="c-form">
            <mat-form-field *ngIf="campaign" class="c-form__block">
              <mat-label>{{'EvaluationQuestionaryComment.label' | translate}}</mat-label>
              <input matInput type="text" (blur)="updateComment()" [(ngModel)]="campaign.Comment"
                class="c-form__input" />
              <mat-hint>{{'EvaluationQuestionaryComment.hint' | translate}}</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </section>
    <!--informe de riesgos-->
    <section class="u-spaceLeft-bootstrap">
      <h2 class="o-title section-title">{{ 'CampaignRiskboard.risk.report.valor' | translate}}</h2>
      <div class="row u-mt-2">
        <div class="col-lg-6">
          <article class="c-graphic c-graphic--dark">
            <header class="c-graphic__header c-graphic__header--flex">
              <h1 class="c-graphic__title">{{'CampaignRiskboard.evaluation.risklist.valor' | translate}}</h1>
              <button type="button" mat-raised-button matTooltip="*INFO" aria-label="Ver más información"
                class="o-tooltip c-graphic__header-button">
                <span class="c-graphic__header-ico" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      d="M10 0A10 10 0 1 0 20 10 10 10 0 0 0 10 0m0 18.333A8.333 8.333 0 1 1 18.333 10 8.333 8.333 0 0 1 10 18.333" />
                    <path
                      d="M11.667,10h-.833a.833.833,0,0,0,0,1.667h.833v5a.833.833,0,1,0,1.667,0v-5A1.667,1.667,0,0,0,11.667,10Z"
                      transform="translate(-1.667-1.666)" />
                    <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 4.166)" />
                  </svg>
                </span>
              </button>
            </header>
            <div class="c-graphic__graph">
              <div id="systemScoreChartDiv" style="width: 100%; height: 250px; font-family: 'Roboto';"></div>
            </div>
          </article>
        </div>
        <div class="col-lg-6">
          <article class="c-graphic c-graphic--dark">
            <header class="c-graphic__header c-graphic__header--flex">
              <h1 class="c-graphic__title">{{'CampaignRiskboard.evaluation.compliance.valor' | translate}}</h1>
              <button type="button" mat-raised-button matTooltip="*INFO" aria-label="Ver más información"
                class="o-tooltip c-graphic__header-button">
                <span class="c-graphic__header-ico" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      d="M10 0A10 10 0 1 0 20 10 10 10 0 0 0 10 0m0 18.333A8.333 8.333 0 1 1 18.333 10 8.333 8.333 0 0 1 10 18.333" />
                    <path
                      d="M11.667,10h-.833a.833.833,0,0,0,0,1.667h.833v5a.833.833,0,1,0,1.667,0v-5A1.667,1.667,0,0,0,11.667,10Z"
                      transform="translate(-1.667-1.666)" />
                    <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 4.166)" />
                  </svg>
                </span>
              </button>
            </header>
            <div class="c-graphic__graph">
              <div *ngIf="!hasManualScores && !hasOverridenScores" class="c-graphic__graph-empty">
                <h2 class="c-graphic__subtitle">{{'CampaignRiskboard.add.evaluation.valor' | translate}}</h2>
                <p class="o-text o-text--center">
                  {{'CampaignRiskboard.own.evaluation.valor' | translate}}
                </p>
                <ng-container *appAuthRole="['Owner', 'Manager']">
                  <a class="c-button c-button--gradient u-align-center"
                    [routerLink]="['/evaluation-answers',
                      thirdPartyId, campaignId, campaignThirdParty?.status == campaignStatus.pendingReview || campaignThirdParty?.status == campaignStatus.reviewed || campaignThirdParty?.status == campaignStatus.discarded || campaignThirdParty?.status == campaignStatus.acknowledged ? 1 : 0]">
                    {{'CampaignRiskboard.see.questionary.valor' | translate}}
                  </a>
                </ng-container>
              </div>
              <div id="manualScoreChartDiv" style="width: 100%; height: 250px; font-family: 'Roboto';"></div>
            </div>
          </article>
        </div>
      </div>
      <!--gráficos dos columnas-->
      <div class="row u-mt-2">
        <div class="col-lg-6">
          <article class="c-graphic">
            <header class="c-graphic__header">
              <h1 class="c-graphic__title">{{'CampaignRiskboard.evaluation.section.valor' | translate}}</h1>
            </header>
            <div class="c-graphic__graph">
              <div id="systemScoresByRiskChartDiv" style="width: 100%; height: 400px; font-family: 'Roboto';"></div>
            </div>
          </article>
        </div>
        <div class="col-lg-6">
          <article class="c-graphic">
            <header class="c-graphic__header c-graphic__header--flex">
              <h1 class="c-graphic__title">{{'CampaignRiskboard.evaluation.scoring.valor' | translate}}</h1>
              <ng-container *appAuthRole="['Owner', 'Manager']">
                <button type="button" (click)="openDialog(true)" class="c-graphic-link">
                  <span class="c-graphic-link__ico" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path
                        d="M16.38.782,6.22,10.942A4.138,4.138,0,0,0,5,13.888v1.119a.833.833,0,0,0,.833.833H6.952A4.138,4.138,0,0,0,9.9,14.621l10.16-10.16a2.6,2.6,0,0,0,0-3.678,2.662,2.662,0,0,0-3.678,0Zm2.5,2.5L8.72,13.442a2.517,2.517,0,0,1-1.767.732H6.667v-.286A2.517,2.517,0,0,1,7.4,12.121l10.16-10.16a.957.957,0,0,1,1.322,0,.936.936,0,0,1,0,1.322Z"
                        transform="translate(-.833-.008)" />
                      <path
                        d="M19.167,7.482a.833.833,0,0,0-.833.833V12.5H15A2.5,2.5,0,0,0,12.5,15v3.333H4.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5H11.7A.833.833,0,0,0,11.7,0H4.167A4.172,4.172,0,0,0,0,4.167V15.833A4.172,4.172,0,0,0,4.167,20h9.452a4.14,4.14,0,0,0,2.947-1.22l2.213-2.215A4.14,4.14,0,0,0,20,13.619v-5.3A.833.833,0,0,0,19.167,7.482ZM15.387,17.6a2.479,2.479,0,0,1-1.221.667V15A.833.833,0,0,1,15,14.167h3.271a2.513,2.513,0,0,1-.667,1.22Z" />
                    </svg>
                  </span>
                  {{'CampaignRiskboard.evaluation.edit.valor' | translate}}
                </button>
              </ng-container>
            </header>
            <div class="c-graphic__graph">
              <div *ngIf="!hasManualScores" class="c-graphic__graph-empty">
                <p class="o-text o-text--center">
                  {{'CampaignRiskboard.own.risk' | translate}}
                </p>
              </div>
              <div id="manualScoresByRiskChartDiv" style="width: 100%; height: 400px; font-family: 'Roboto';"
                [ngStyle]="{'height': !hasManualScores ? '0px' : '400px'}"></div>
            </div>
          </article>
        </div>
      </div>
    </section>
    <!-- comentarios por riesgo -->
    <app-campaign-risk-comments *ngIf="campaign?.EvaluationQuestionaryRisk"
      [evaluationQuestionaryRisks]="campaign.EvaluationQuestionaryRisk"></app-campaign-risk-comments>
  </main>
</div>
