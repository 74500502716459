import { Injectable } from "@angular/core";
import { Risk } from "@app/models/Risk";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CommonsProxyClient } from "src/proxy/apiCommons/commons.proxy";
import { mapRiskDtoToRisk } from "./mappings/risk.mappings";

@Injectable({
    providedIn: 'root'
})
export class RiskService {
    constructor(
        private commonsClient: CommonsProxyClient,
        private translate: TranslateService
    ) { }

    getRisks(): Observable<Risk[]> {
        return this.commonsClient
            .commonsRiskGet()
            .pipe(
                map(risks => risks.map(risk => mapRiskDtoToRisk(risk, this.translate.currentLang)))
            );
    }
}