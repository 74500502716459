import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { AuthenticationService } from './services/authentication.service';
import { AppRoutingCache } from './app-routing-cache';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { LoginComponent } from '@app/modules/access/login/login.component';
import { CustomToastComponent } from '@app/shared/custom-toast/custom-toast.component';
import { ForgottenPasswordComponent } from '@app/modules/access/forgotten-password/forgotten-password.component';
import { SearchComponent } from './modules/thirdparties/search/search.component';
import { DialogSendCampaignComponent } from './modules/campaigns/dialog-send-campaign/dialog-send-campaign.component';
import { CampaignsComponent } from '@app/modules/campaigns/campaigns/campaigns.component';
import { DialogDoubleTemplateComponent } from '@app/modules/templates/dialog-double-template/dialog-double-template.component';
import { RiskboardComponent } from '@app/modules/riskboard/riskboard/riskboard.component';
import { FormularioThirdComponent } from '@modules/thirdparties/formulario-third/formulario-third.component';
import { CreateThirdComponent } from '@modules/thirdparties/create-third/create-third.component';
import { TemplateDetailCopyMasterComponent } from '@app/modules/templates/template-detail-copy-master/template-detail-copy-master.component';
import { PortalPageIdComponent } from '@app/modules/myrisks/portal-page-id/portal-page-id.component';
import { PortalMyRisksComponent } from '@app/modules/myrisks/portal-my-risks/portal-my-risks.component';
import { ThirdPartyTableExtendedComponent } from '@modules/thirdparties/third-party-table-extended/third-party-table-extended.component';
import { EditThirdComponent } from '@modules/thirdparties/edit-third/edit-third.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { RisklistIntroComponent } from '@modules/myrisks/risklist-intro/risklist-intro.component';
import { RisklistFillComponent } from '@modules/myrisks/risklist-fill/risklist-fill.component';
import { RisklistSignComponent } from '@modules/myrisks/risklist-sign/risklist-sign.component';
import { RisklistSentComponent } from '@modules/myrisks/risklist-sent/risklist-sent.component';
import { DialogSendRisklistComponent } from '@app/modules/myrisks/dialog-send-risklist/dialog-send-risklist.component';
import { CampaignTableComponent } from '@modules/campaigns/campaign-table/campaign-table.component';
import { CustomMatPaginatorIntl } from './utilities/custom-paginator';
import { CreateCampaignComponent } from '@modules/campaigns/create-campaign/create-campaign.component';
import { DetailCampaignComponent } from '@modules/campaigns/detail-campaign/detail-campaign.component';
import { SentCampaignComponent } from '@modules/campaigns/sent-campaign/sent-campaign.component';
import { TemplateTableComponent } from '@modules/templates/template-table/template-table.component';
import { DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CampaignReleaseRiskboardComponent } from '@app/modules/campaigns/campaign-release-riskboard/campaign-release-riskboard.component';
import { ThirdImportMassiveComponent } from '@modules/thirdparties/third-import-massive/third-import-massive.component';
import { ThirdImportMassiveEndComponent } from '@modules/thirdparties/third-import-massive-end/third-import-massive-end.component';
import { SecurityInterceptorService } from './security/security-interceptor.service';
import { DialogConfirmThirdMassiveComponentOk } from '@modules/thirdparties/dialog-confirm-third-massive-ok/dialog-confirm-third-massive-ok.component';
import { DialogConfirmThirdMassiveComponentKo } from '@modules/thirdparties/dialog-confirm-third-massive-ko/dialog-confirm-third-massive-ko.component';
import { ConfigCompanyComponent } from '@app/modules/config-company/config-company.component';
import { OnboardingIntroComponent } from '@modules/onboarding/onboarding-intro/onboarding-intro.component';
import { OnboardingEndComponent } from '@modules/onboarding/onboarding-end/onboarding-end.component';
import { SectorTableComponent } from './modules/configuration/sector-table/sector-table.component';
import { NatureTableComponent } from './modules/configuration/nature-table/nature-table.component';
import { DialogDeleteSectorComponent } from './modules/configuration/dialog-delete-sector/dialog-delete-sector.component';
import { DialogDeleteNatureComponent } from './modules/configuration/dialog-delete-nature/dialog-delete-nature.component';
import { DialogAddNatureComponent } from './modules/configuration/dialog-add-nature/dialog-add-nature.component';
import { ActivityTableComponent } from './modules/configuration/activity-table/activity-table.component';
import { DialogAddActivityComponent } from './modules/configuration/dialog-add-activity/dialog-add-activity.component';
import { DialogDeleteActivityComponent } from './modules/configuration/dialog-delete-activity/dialog-delete-activity.component';
import { ThirdtypeTableComponent } from './modules/configuration/thirdtype-table/thirdtype-table.component';
import { DialogDeleteThirdtypeComponent } from './modules/configuration/dialog-delete-thirdtype/dialog-delete-thirdtype.component';
import { DialogAddThirdtypeComponent } from './modules/configuration/dialog-add-thirdtype/dialog-add-thirdtype.component';
import { DialogAddRiskComponent } from './modules/configuration/dialog-add-risk/dialog-add-risk.component';
import { DialogDeleteRiskComponent } from './modules/configuration/dialog-delete-risk/dialog-delete-risk.component';
import { RiskTableComponent } from './modules/configuration/risk-table/risk-table.component';
import { FileDownloadComponent } from '@app/shared/file-download/file-download.component';
import { OnboardingFillCardComponent } from '@modules/onboarding/onboarding-fill-card/onboarding-fill-card.component';
import { MyrisksTableComponent } from '@modules/myrisks/myrisks-table/myrisks-table.component';
import { Dialog3ppExistsComponent } from '@modules/thirdparties/dialog3pp-exists/dialog3pp-exists.component';
import { Dialog3ppExistsInBc } from '@modules/thirdparties/dialog-3pp-exists-in-bc/dialog-3pp-exists-in-bc.component';
import { DialogAddSectorComponent } from '@modules/configuration/dialog-add-sector/dialog-add-sector.component';
import { ResetPasswordComponent } from './modules/access/reset-password/reset-password.component';
import { UsersTableComponent } from './modules/configuration/users-table/users-table.component';
import { DialogAddUserComponent } from './modules/configuration/dialog-add-user/dialog-add-user.component';
import { DialogDeleteUserComponent } from './modules/configuration/dialog-delete-user/dialog-delete-user.component';
import { OnboardingCardComponent } from './modules/onboarding/onboarding-card/onboarding-card.component';
import { Dialog3ppIsLinkedComponent } from './modules/thirdparties/dialog3pp-is-linked/dialog3pp-is-linked.component';
import { ViewAnswersComponent } from './modules/templates/view-answers/view-answers.component';
import { ResetPasswordNewClientComponent } from './modules/access/reset-password-new-client/reset-password-new-client.component';
import { ResetPasswordThirdpartyComponent } from './modules/access/reset-password-thirdparty/reset-password-thirdparty.component';
import { DialogDeleteThirdpartyComponent } from './modules/thirdparties/dialog-delete-thirdparty/dialog-delete-thirdparty.component';
import { DialogDeleteCampaignComponent } from './modules/campaigns/dialog-delete-campaign/dialog-delete-campaign.component';
import { AddThirdpartiesComponent } from './modules/campaigns/add-thirdparties/add-thirdparties.component';
import { DialogConfirmImportThirdToCampaignComponent } from './modules/thirdparties/dialog-confirm-import-third-to-campaign/dialog-confirm-import-third-to-campaign.component';
import { EvaluacionEnviadaNewThirdpartiesComponent } from './modules/campaigns/evaluacion-enviada-new-thirdparties/evaluacion-enviada-new-thirdparties.component';
import { ThirdPartyTableFilesComponent } from './modules/thirdparties/third-party-table-files/third-party-table-files.component';
import { AppTwoStepVerificationComponent } from './modules/access/app-two-step-verification/app-two-step-verification.component';
import { ScoringToolsService } from './services/scoring-tools.service';
import { ThirdPartyScreeningDashboardComponent } from './modules/screening-hub/components/screening-dashboard/screening-dashboard.component';
import { DialogConfirmUnlinkScreeningComponent } from './modules/screening-hub/components/dialog-confirm-unlink-screening/dialog-confirm-unlink-screening.component';
import { DialogConfirmRefreshScreeningComponent } from './modules/screening-hub/components/dialog-confirm-refresh-screening/dialog-confirm-refresh-screening.component';
import { ThirdPartyScoringCandidatesComponent } from './modules/screening-hub/components/scoring-candidates/scoring-candidates.component';
import { ThirdPartyScoringCandidatesListComponent } from './modules/screening-hub/components/refinitiv/scoring-candidates-list/scoring-candidates-list.component';
import { ThirdPartyScoringCandidateDetailsComponent } from './modules/screening-hub/components/refinitiv/scoring-candidate-details/scoring-candidate-details.component';
import { ThirdPartyScreeningDetailsComponent } from './modules/screening-hub/components/refinitiv/screening-details/screening-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { ThirdPartiesProxyClient } from 'src/proxy/apiThirdParty/third-party.proxy';
import { appInitializer } from './security/appInitializer';
import { ErrorInterceptor } from './security/error.interceptor';
import { ThirdPartyScreeningReportService } from './services/reports/third-party-screening-report.service';
import { CampaignProxyClient } from 'src/proxy/apiCampaign/campaign.proxy';
import { ThirdPartyEvaluationReportService } from './services/reports/third-party-evaluation-report.service';
import { QuestionariesProxyClient } from 'src/proxy/apiQuestionaries/questionaries.proxy';
import { UsersProxyClient } from 'src/proxy/apiUser/users.proxy';
import { AuthRoleDirective } from './security/auth-role.directive';
import { CompanyProxyClient } from 'src/proxy/apiCompany/company.proxy';
import { CompanyService } from './services/company.service';
import { RiskListProxyClient } from 'src/proxy/apiRiskList/riskList.proxy';
import { RiskListService } from './services/risk-list.service';
import { CommonsProxyClient } from 'src/proxy/apiCommons/commons.proxy';
import { CampaignRiskboardReportService } from './services/reports/campaign-riskboard-report.service';
import { DialogRequestThirdPartiesComponent } from './modules/thirdparties/dialog-request-more-third-parties/dialog-request-more-third-parties.component';
import { CommercialProxyClient } from 'src/proxy/apiCommercial/commercial.proxy';
import { CommercialService } from './services/commercial.service';
import { setLanguageFromBrowser } from './utilities/locale-id.provider';
import { TranslateDescriptionPipe } from './shared/pipes/translate-description.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ScoringCandidatesOrganizationSearchRefinitivComponent } from './modules/screening-hub/components/refinitiv/scoring-candidates-organization-search-refinitiv/scoring-candidates-organization-search-refinitiv.component';
import { ScoringCandidatesIndividualSearchRefinitivComponent } from './modules/screening-hub/components/refinitiv/scoring-candidates-individual-search-refinitiv/scoring-candidates-individual-search-refinitiv.component';
import { RefinitivScoringProxyClient } from 'src/proxy/apiRefinitiv/refinitivScoring.proxy';
import { ThirdPartyScreeningScoringsComponent } from './modules/screening-hub/components/screening-scorings/screening-scorings.component';
import { ThirdPartyScreeningListComponent } from './modules/screening-hub/components/refinitiv/screening-list/screening-list.component';
import { CampaignRiskCommentsComponent } from './modules/campaigns/campaign-release-riskboard/campaign-risk-comments/campaign-risk-comments.component';
import { DialogConfirmExitComponent } from './modules/myrisks/dialog-confirm-exit/dialog-confirm-exit.component';
import { DialogSearchLimitReachedComponent } from './modules/screening-hub/components/dialog-search-limit-reached/dialog-search-limit-reached.component';
import { DialogMonitoringComponent } from './modules/screening-hub/components/dialog-monitoring/dialog-monitoring.component';
import { MonitoringConfigurationProxyClient } from 'src/proxy/apiMonitoringConfiguration/monitoring-configuration.proxy';
import { MonitoringConfigurationService } from './services/monitoring-configuration.service';
import { ConsumptionUsageComponent } from './modules/config-company/consumption-usage/consumption-usage.component';
import { UsageProxyClient } from 'src/proxy/apiUsage/usage.proxy';
import { DialogExpandQuotasComponent } from './modules/config-company/dialog-expand-quotas/dialog-expand-quotas.component';
import { AccountService } from './services/account.service';
import { AccountProxyClient } from 'src/proxy/apiAccount/account.proxy';
import { ThirdPartySignUpComponent } from './modules/access/third-party-sign-up/third-party-sign-up.component';
import { ConfirmEmailComponent } from './modules/access/confirm-email/confirm-email.component';
import { ThirdPartyUserCreatedComponent } from './modules/access/third-party-user-created/third-party-user-created.component';
import { CampaignThirdPartyStatusComponent } from './modules/campaigns/campaign-third-party-status/campaign-third-party-status.component';
import { EmailNotificationProxyClient } from 'src/proxy/apiEmailNotification/emailNotification.proxy';
import { EmailNotificaitonService } from './services/email-notification.service';
import { ResponsibleUserComponent } from './shared/responsible-user/responsible-user.component';
import { ThirdPartiesListTableComponent } from './modules/thirdparties/third-parties-list-table/third-parties-list-table.component';
import { CampaignThirdPartyTableComponent } from './modules/campaigns/campaign-third-party-table/campaign-third-party-table.component';
import { TextTableFilterComponent } from './shared/text-table-filter/text-table-filter.component';
import { CampaignThirdPartyTableActionsComponent } from './modules/campaigns/campaign-third-party-table-actions/campaign-third-party-table-actions.component';
import { CampaignThirdPartyService } from './services/campaign-third-party.service';
import { CampaignThirdPartyProxy } from 'src/proxy/apiCampaignThirdParty/campaignThirdParty.proxy';
import { ThirdPartyTypesService } from './services/third-party-types.service';
import { CampaignDetailStatisticsComponent } from './modules/campaigns/campaign-detail-statistics/campaign-detail-statistics.component';
import { ThirdPartyStatusComponent } from './modules/thirdparties/third-party-status/third-party-status.component';
import { CampaignRiskboardComponent } from './modules/riskboard/campaign-riskboard/campaign-riskboard.component';
import { ThirdPartyRiskboardComponent } from './modules/riskboard/third-party-riskboard/third-party-riskboard.component';
import { DialogSignChecklistComponent } from '@modules/myrisks/risklist-fill/dialog-sign-checklist/dialog-sign-checklist.component';
import { RisklistActionComponent } from '@app/modules/myrisks/risklist-actions/risklist-action.component';
import { SignActionIconComponent } from './modules/myrisks/risklist-actions/action-icons/sign-action-icon.component';
import { FillActionIconComponent } from './modules/myrisks/risklist-actions/action-icons/fill-action-icon.component';
import { ThirdPartyQuestionaryReportService } from './services/reports/third-party-questionary-report.service';
import { DialogThirdEvaluationComponent } from './modules/campaigns/dialog-third-evaluation/dialog-third-evaluation.component';
import { TemplateComponent } from './modules/templates/template/template.component';
import { MainComponent } from './modules/main/main.component';
import { HomeComponent } from './modules/home/home.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { SlideToggleComponent } from './shared/slide-toggle/slide-toggle.component';
import { MyAccountComponent } from './modules/my-account/my-account.component';
import { GeneralAdjustmentsComponent } from './modules/general-adjustments/general-adjustments.component';
import { DialogUpdatePasswordComponent } from './modules/my-account/dialog-update-password/dialog-update-password.component';
import { QuestionaryBlocksComponent } from './modules/templates/template-detail-copy-master/questionary-blocks/questionary-blocks.component';
import { QuestionaryConfigurationComponent } from './modules/templates/template-detail-copy-master/questionary-configuration/questionary-configuration.component';
import { QuestionaryWeightsComponent } from './modules/templates/template-detail-copy-master/questionary-weights/questionary-weights.component';
import { DialogBulkStatusChangeComponent } from './modules/thirdparties/dialog-bulk-status-change/dialog-bulk-status-change.component';
import { MatIconModule } from '@angular/material/icon';
import { ThirdPartyCampaignsTableComponent } from './modules/thirdparties/third-party-campaigns-table/third-party-campaigns-table.component';
import { ThirdPartyRiskboardReportService } from './services/reports/third-party-riskboard-report.service';
import { CreateCampaignThirdPartiesComponent } from './modules/campaigns/create-campaign/create-campaign-third-parties/create-campaign-third-parties.component';
import { FreshdeskWidgetService } from './services/helpdesk/freshdesk-widget.service';
import { FreshdeskProxyClient } from 'src/proxy/apiFreshdesk/freshdesk.proxy';
import { TemplateActionsComponent } from './modules/templates/template-actions/template-actions.component';
import { CustomFieldsTableComponent } from './modules/configuration/custom-fields-table/custom-fields-table.component';
import { CustomFieldsProxyClient } from 'src/proxy/apiCustomFields/customFields.proxy';
import { CustomFieldDetailComponent } from './modules/configuration/custom-field-detail/custom-field-detail.component';
import { CustomFieldFormComponent } from './modules/configuration/custom-field-form/custom-field-form.component';
import { CustomFieldAddComponent } from './modules/configuration/custom-field-add/custom-field-add.component';
import { ScreeningToolsHomeComponent } from './modules/screening-hub/pages/screening-tools-home/screening-tools-home.component';
import { ScreeningReportService } from './services/reports/screening-report.service';
import { MultipleAnswerComponent } from './modules/templates/template-detail-copy-master/multiple-answer/multiple-answer.component';
import { ThirdPartySignUpIntroComponent } from './modules/access/third-party-sign-up-intro/third-party-sign-up-intro.component';
import { MultipleChoiceComponent } from './modules/myrisks/risklist-fill/multiple-choice/multiple-choice.component';
import { DialogFillClientDataComponent } from './modules/myrisks/dialog-fill-client-data/dialog-fill-client-data.component';
import { StartScreeningComponent } from './modules/screening-hub/components/start-screening/start-screening.component';
import { ScreeningScoringsContainerComponent } from './modules/screening-hub/components/screening-scorings-container/screening-scorings-container.component';
import { ScoringCandidatesIndividualSearchGesintelComponent } from './modules/screening-hub/components/aml-update/scoring-candidates-individual-search-gesintel/scoring-candidates-individual-search-gesintel.component';
import { ScoringCandidatesOrganizationSearchGesintelComponent } from './modules/screening-hub/components/aml-update/scoring-candidates-organization-search-gesintel/scoring-candidates-organization-search-gesintel.component';
import { ScoringCandidatesBothSearchGesintelComponent } from './modules/screening-hub/components/aml-update/scoring-candidates-both-search-gesintel/scoring-candidates-both-search-gesintel.component';
import { AMLUpdateScoringProxyClient } from 'src/proxy/apiAMLUpdateScoring/amlUpdateScoring.proxy';
import { ScoringCandidatesListGesintelComponent } from './modules/screening-hub/components/aml-update/scoring-candidates-list-gesintel/scoring-candidates-list-gesintel.component';
import { AMLUpdateScoringCandidateDetailsComponent } from './modules/screening-hub/components/aml-update/scoring-candidate-details/scoring-candidate-details.component';
import { ResolutionsDashboardComponent } from './modules/screening-hub/resolutions/resolutions-dashboard/resolutions-dashboard.component';
import { ResolutionLinkComponent } from './modules/screening-hub/resolutions/resolution-link/resolution-link.component';
import { ResolutionRecordListComponent } from './modules/screening-hub/resolutions/resolution-record-list/resolution-record-list.component';
import { ResolutionRecordDetailsComponent } from './modules/screening-hub/resolutions/resolution-record-details/resolution-record-details.component';
import { ResolutionProxyClient } from 'src/proxy/apiResolution/resolution.proxy';
import { CreateResolutionComponent } from './modules/screening-hub/resolutions/create-resolution/create-resolution.component';
import { RefinitivResultListComponent } from './modules/screening-hub/resolutions/refinitiv-result-list/refinitiv-result-list.component';
import { ResolutionRecordCommentsComponent } from './modules/screening-hub/resolutions/resolution-record-comments/resolution-record-comments.component';
import { ConfirmDeleteResolutionLinkComponent } from './modules/screening-hub/resolutions/confirm-delete-resolution-link/confirm-delete-resolution-link.component';
import { ConfirmDeleteTemplateComponent } from './modules/templates/confirm-delete-template/confirm-delete-template.component';
import { ResolutionReportService } from './services/reports/resolution-report.service';
import { DialogUploadThirdPartyFileComponent } from './modules/thirdparties/dialog-upload-third-party-file/dialog-upload-third-party-file.component';
import { ParentalMeshComponent } from './modules/screening-hub/components/aml-update/basic-data/parental-mesh/parental-mesh.component';
import { AMLUpdateScreeningListComponent } from './modules/screening-hub/components/aml-update/screening-list/screening-list.component';
import { AMLUpdateBasicDataComponent } from './modules/screening-hub/components/aml-update/basic-data/basic-data.component';
import { AMLUpdateScreeninDetailsComponent } from './modules/screening-hub/components/aml-update/screening-details/screening-details.component';
import { AMLUpdateMandatoryListsComponent } from './modules/screening-hub/components/aml-update/mandatory-lists/mandatory-lists.component';
import { AMLUpdateMainPEPComponent } from './modules/screening-hub/components/aml-update/mandatory-lists/main-pep/main-pep.component';
import { KinshipPEPComponent } from './modules/screening-hub/components/aml-update/mandatory-lists/kinship-pep/kinship-pep.component';
import { PartnershipPEPComponent } from './modules/screening-hub/components/aml-update/mandatory-lists/partnership-pep/partnership-pep.component';
import { AMLUpdateWatchlistComponent } from './modules/screening-hub/components/aml-update/watchlist/watchlist.component';
import { AMLUpdateWatchlistBasicInfoComponent } from './modules/screening-hub/components/aml-update/basic-info/basic-info.component';
import { AMLUpdateAliasComponent } from './modules/screening-hub/components/aml-update/alias/alias.component';
import { AMLUpdateAliasVariationsComponent } from './modules/screening-hub/components/aml-update/alias-variations/alias-variations.component';
import { AMLUpdateAliasListComponent } from './modules/screening-hub/components/aml-update/alias-list/alias-list.component';
import { AMLUpdateRegionsComponent } from './modules/screening-hub/components/aml-update/regions/regions.component';
import { AMLUpdateRolesComponent } from './modules/screening-hub/components/aml-update/roles/roles.component';
import { AMLUpdateDocumentsComponent } from './modules/screening-hub/components/aml-update/documents/documents.component';
import { AMLUpdateCompanyBasicDataComponent } from './modules/screening-hub/components/aml-update/company-basic-data/company-basic-data.component';
import { AMLUpdateAdverseMediaComponent } from './modules/screening-hub/components/aml-update/adverse-media/adverse-media.component';
import { AMLUpdateConfirmRefreshScreeningComponent } from './modules/screening-hub/components/aml-update-confirm-refresh-screening/aml-update-confirm-refresh-screening.component';
import { AMLUpdateResultListComponent } from './modules/screening-hub/resolutions/aml-update-result-list/aml-update-result-list.component';
import { ScoringToolsAccessComponent } from './modules/configuration/scoring-tools-access/scoring-tools-access.component';
import { ScoringToolsConfigurationProxyClient } from 'src/proxy/apiScoringToolsAccess/scoringToolsConfiguration.proxy';
import { ScoringToolsConfigurationService } from './services/scoring-tools-configuration.service';
import { AddScoringToolsAccessComponent } from './modules/configuration/scoring-tools-access/add-scoring-tools-access/add-scoring-tools-access.component';
import { RefinitivConfigFormComponent } from './modules/configuration/scoring-tools-access/add-scoring-tools-access/refinitiv-config-form/refinitiv-config-form.component';
import { DeleteScoringToolsAccess } from './modules/configuration/scoring-tools-access/delete-scoring-tools-access/delete-scoring-tools-access.component';
import { AMLUpdateConfigFormComponent } from './modules/configuration/scoring-tools-access/add-scoring-tools-access/aml-update-config-form/aml-update-config-form.component';
import { EquifaxWSConfirmRefreshScreeningComponent } from './modules/screening-hub/components/equifax-ws-confirm-refresh-screening/equifax-ws-confirm-refresh-screening.component';
import { EquifaxWSScoringService } from './modules/screening-hub/services/equifax-ws-scoring.service';
import { EquifaxWSScreeningListComponent } from './modules/screening-hub/components/equifax-ws/screening-list/screening-list.component';
import { EquifaxWSScreeninDetailsComponent } from './modules/screening-hub/components/equifax-ws/screening-details/screening-details.component';
import { EquifaxWSScoringCandidateDetailsComponent } from './modules/screening-hub/components/equifax-ws/scoring-candidate-details/scoring-candidate-details.component';
import { ScoringCandidatesListEquifaxWSComponent } from './modules/screening-hub/components/equifax-ws/scoring-candidates-list/scoring-candidates-list.component';
import { ScoringCandidatesSearchEquifaxWSComponent } from './modules/screening-hub/components/equifax-ws/scoring-candidates-search/scoring-candidates-search.component';
import { EquifaxWSScoringProxyClient } from 'src/proxy/apiEquifaxWS/equifaxWSScoring.proxy';
import { EquifaxWSIndividualBasicDataComponent } from './modules/screening-hub/components/equifax-ws/individual/general-data/general-data.component';
import { EquifaxWSIndividualRelativeDataComponent } from './modules/screening-hub/components/equifax-ws/individual/general-data/relative-data/relative-data.component';
import { EquifaxWSIndividualMaritalStatusComponent } from './modules/screening-hub/components/equifax-ws/individual/marital-status/marital-status.component';
import { EquifaxWSIndividualMaritalStatusDataComponent } from './modules/screening-hub/components/equifax-ws/individual/marital-status/marital-status-data/marital-status-data.component';
import { EquifaxWSIndividualWorkingInfoComponent } from './modules/screening-hub/components/equifax-ws/individual/working-info/working-info.component';
import { EquifaxWSAddressListComponent } from './modules/screening-hub/components/equifax-ws/shared/address-list/address-list.component';
import { EquifaxWSTelephoneListComponent } from './modules/screening-hub/components/equifax-ws/shared/telephone-list/telephone-list.component';
import { EquifaxWSPropertiesComponent } from './modules/screening-hub/components/equifax-ws/shared/properties/properties.component';
import { EquifaxWSMovablePropertyComponent } from './modules/screening-hub/components/equifax-ws/shared/properties/movable-poroperty/movable-property.component';
import { EquifaxWSImmovablePropertyComponent } from './modules/screening-hub/components/equifax-ws/shared/properties/immovable-poroperty/immovable-property.component';
import { EquifaxWSPledgeCreditComponent } from './modules/screening-hub/components/equifax-ws/shared/credit-data/pledge-credit/pledge-credit.component';
import { EquifaxWSMortgageCreditComponent } from './modules/screening-hub/components/equifax-ws/shared/credit-data/mortgage-credit/mortgage-credit.component';
import { EquifaxWSCreditDataComponent } from './modules/screening-hub/components/equifax-ws/shared/credit-data/credit-data.component';
import { EquifaxWSIndividualDataComponent } from './modules/screening-hub/components/equifax-ws/individual/individual-data.component';
import { EquifaxWSConfigFormComponent } from './modules/configuration/scoring-tools-access/add-scoring-tools-access/equifax-ws-config-form/equifax-ws-config-form.component';
import { EquifaxWSScreeningReport } from './services/reports/equifax-ws-screening-report.service';
import { BusinessCentralProxyClient } from 'src/proxy/apiBusinessCentral/business-central.proxy';
import { BusinessCentralService } from './services/business-central.service';
import { SeeActionIconComponent } from './modules/myrisks/risklist-actions/action-icons/see-action-icon.component';
import { MemberCheckScoringMemberSearchComponent } from './modules/screening-hub/components/member-check/member-check-scoring-member-search/member-check-scoring-member-search.component';
import { MemberCheckScoringCorpSearchComponent } from './modules/screening-hub/components/member-check/member-check-scoring-corp-search/member-check-scoring-corp-search.component';
import { MemberCheckCandidatesComponent } from './modules/screening-hub/components/member-check/member-check-scoring-candidate/member-check-scoring-candidate.component';
import { ScreeningProviderService } from './modules/screening-hub/services/screening-provider.service';
import { MemberCheckScoringService } from './modules/screening-hub/services/member-check-scoring.service';
import { MemberCheckProxyClient } from 'src/proxy/apiMemberCheck/memberCheck.proxy';
import { MemberCheckCandidateDetailComponent } from './modules/screening-hub/components/member-check/member-check-candidate-detail/member-check-candidate-detail.component';
import { MemberCheckConfirmRefreshScreeningComponent } from './modules/screening-hub/components/member-check-confirm-refresh-screening/member-check-confirm-refresh-screening.component';
import { MemberCheckScreeningListComponent } from './modules/screening-hub/components/member-check/member-check-screening-list/member-check-screening-list.component';
import { MemberCheckScoringDetailComponent } from './modules/screening-hub/components/member-check/member-check-scoring-detail/member-check-scoring-detail.component';
import { ApiAccessDataComponent } from './modules/configuration/api-access-data/api-access-data.component';
import { ClientProxyClient } from 'src/proxy/apiClient/client.proxy';
import { ClientsService } from './services/clients.service';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HomeComponent,
    CustomToastComponent,
    ForgottenPasswordComponent,
    SearchComponent,
    DialogSendCampaignComponent,
    DialogMonitoringComponent,
    CampaignsComponent,
    TemplateComponent,
    DialogDoubleTemplateComponent,
    RiskboardComponent,
    FormularioThirdComponent,
    CreateThirdComponent,
    TemplateDetailCopyMasterComponent,
    PortalPageIdComponent,
    PortalMyRisksComponent,
    ThirdPartyTableExtendedComponent,
    EditThirdComponent,
    CampaignTableComponent,
    CreateCampaignComponent,
    DetailCampaignComponent,
    SentCampaignComponent,
    TemplateTableComponent,
    RisklistIntroComponent,
    RisklistFillComponent,
    RisklistSignComponent,
    RisklistSentComponent,
    DialogSendRisklistComponent,
    CampaignReleaseRiskboardComponent,
    ThirdImportMassiveComponent,
    ThirdImportMassiveEndComponent,
    DialogConfirmThirdMassiveComponentOk,
    DialogConfirmThirdMassiveComponentKo,
    FileUploadComponent,
    SlideToggleComponent,
    MyAccountComponent,
    ConfigCompanyComponent,
    OnboardingIntroComponent,
    OnboardingEndComponent,
    FileDownloadComponent,
    OnboardingFillCardComponent,
    MyrisksTableComponent,
    GeneralAdjustmentsComponent,
    Dialog3ppExistsComponent,
    Dialog3ppExistsInBc,
    SectorTableComponent,
    NatureTableComponent,
    DialogThirdEvaluationComponent,
    DialogAddSectorComponent,
    DialogDeleteSectorComponent,
    DialogDeleteNatureComponent,
    DialogAddNatureComponent,
    ActivityTableComponent,
    DialogAddActivityComponent,
    DialogDeleteActivityComponent,
    ThirdtypeTableComponent,
    DialogDeleteThirdtypeComponent,
    DialogAddThirdtypeComponent,
    DialogAddRiskComponent,
    DialogDeleteRiskComponent,
    RiskTableComponent,
    ResetPasswordComponent,
    UsersTableComponent,
    DialogAddUserComponent,
    DialogDeleteUserComponent,
    OnboardingCardComponent,
    Dialog3ppIsLinkedComponent,
    ViewAnswersComponent,
    ResetPasswordNewClientComponent,
    ResetPasswordThirdpartyComponent,
    DialogDeleteThirdpartyComponent,
    DialogDeleteCampaignComponent,
    DialogConfirmExitComponent,
    AddThirdpartiesComponent,
    DialogConfirmImportThirdToCampaignComponent,
    EvaluacionEnviadaNewThirdpartiesComponent,
    ThirdPartyTableFilesComponent,
    AppTwoStepVerificationComponent,
    ThirdPartyScreeningDashboardComponent,
    DialogConfirmUnlinkScreeningComponent,
    DialogConfirmRefreshScreeningComponent,
    ThirdPartyScoringCandidatesComponent,
    ThirdPartyScoringCandidatesListComponent,
    ScoringCandidatesListGesintelComponent,
    ThirdPartyScoringCandidateDetailsComponent,
    ThirdPartyScreeningDetailsComponent,
    AuthRoleDirective,
    DialogRequestThirdPartiesComponent,
    TranslateDescriptionPipe,
    ScoringCandidatesOrganizationSearchRefinitivComponent,
    ScoringCandidatesIndividualSearchRefinitivComponent,
    ThirdPartyScreeningScoringsComponent,
    ThirdPartyScreeningListComponent,
    CampaignRiskCommentsComponent,
    DialogSearchLimitReachedComponent,
    ConsumptionUsageComponent,
    DialogExpandQuotasComponent,
    DialogUpdatePasswordComponent,
    ThirdPartySignUpComponent,
    ConfirmEmailComponent,
    ThirdPartyUserCreatedComponent,
    CampaignThirdPartyStatusComponent,
    ResponsibleUserComponent,
    ThirdPartiesListTableComponent,
    CampaignThirdPartyTableComponent,
    CampaignThirdPartyTableActionsComponent,
    TextTableFilterComponent,
    CampaignDetailStatisticsComponent,
    ThirdPartyStatusComponent,
    CampaignRiskboardComponent,
    ThirdPartyRiskboardComponent,
    DialogSignChecklistComponent,
    RisklistActionComponent,
    SignActionIconComponent,
    FillActionIconComponent,
    SeeActionIconComponent,
    QuestionaryBlocksComponent,
    QuestionaryConfigurationComponent,
    QuestionaryWeightsComponent,
    DialogBulkStatusChangeComponent,
    ThirdPartyCampaignsTableComponent,
    CreateCampaignThirdPartiesComponent,
    TemplateActionsComponent,
    CustomFieldsTableComponent,
    CustomFieldDetailComponent,
    CustomFieldFormComponent,
    CustomFieldAddComponent,
    ScreeningToolsHomeComponent,
    MultipleAnswerComponent,
    ThirdPartySignUpIntroComponent,
    MultipleChoiceComponent,
    DialogFillClientDataComponent,
    StartScreeningComponent,
    ScreeningScoringsContainerComponent,
    ScoringCandidatesIndividualSearchGesintelComponent,
    ScoringCandidatesOrganizationSearchGesintelComponent,
    ScoringCandidatesBothSearchGesintelComponent,
    AMLUpdateScoringCandidateDetailsComponent,
    ResolutionsDashboardComponent,
    ResolutionLinkComponent,
    ResolutionRecordListComponent,
    ResolutionRecordDetailsComponent,
    CreateResolutionComponent,
    RefinitivResultListComponent,
    ResolutionRecordCommentsComponent,
    ConfirmDeleteResolutionLinkComponent,
    ConfirmDeleteTemplateComponent,
    DialogUploadThirdPartyFileComponent,
    ParentalMeshComponent,
    AMLUpdateScreeningListComponent,
    AMLUpdateBasicDataComponent,
    AMLUpdateScreeninDetailsComponent,
    AMLUpdateMandatoryListsComponent,
    AMLUpdateMainPEPComponent,
    KinshipPEPComponent,
    PartnershipPEPComponent,
    AMLUpdateWatchlistComponent,
    AMLUpdateWatchlistBasicInfoComponent,
    AMLUpdateAliasComponent,
    AMLUpdateAliasVariationsComponent,
    AMLUpdateAliasListComponent,
    AMLUpdateRegionsComponent,
    AMLUpdateRolesComponent,
    AMLUpdateDocumentsComponent,
    AMLUpdateCompanyBasicDataComponent,
    AMLUpdateAdverseMediaComponent,
    AMLUpdateConfirmRefreshScreeningComponent,
    AMLUpdateResultListComponent,
    ScoringToolsAccessComponent,
    AddScoringToolsAccessComponent,
    RefinitivConfigFormComponent,
    DeleteScoringToolsAccess,
    AMLUpdateConfigFormComponent,
    EquifaxWSConfirmRefreshScreeningComponent,
    EquifaxWSScreeningListComponent,
    EquifaxWSScreeninDetailsComponent,
    EquifaxWSScoringCandidateDetailsComponent,
    ScoringCandidatesListEquifaxWSComponent,
    ScoringCandidatesSearchEquifaxWSComponent,
    EquifaxWSIndividualBasicDataComponent,
    EquifaxWSIndividualRelativeDataComponent,
    EquifaxWSIndividualMaritalStatusComponent,
    EquifaxWSIndividualMaritalStatusDataComponent,
    EquifaxWSIndividualWorkingInfoComponent,
    EquifaxWSAddressListComponent,
    EquifaxWSTelephoneListComponent,
    EquifaxWSPropertiesComponent,
    EquifaxWSMovablePropertyComponent,
    EquifaxWSImmovablePropertyComponent,
    EquifaxWSMortgageCreditComponent,
    EquifaxWSPledgeCreditComponent,
    EquifaxWSCreditDataComponent,
    EquifaxWSIndividualDataComponent,
    EquifaxWSConfigFormComponent,
    MemberCheckScoringMemberSearchComponent,
    MemberCheckScoringCorpSearchComponent,
    MemberCheckCandidatesComponent,
    MemberCheckCandidateDetailComponent,
    MemberCheckConfirmRefreshScreeningComponent,
    MemberCheckScreeningListComponent,
    MemberCheckScoringDetailComponent,
    ApiAccessDataComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatCheckboxModule,
    MatStepperModule,
    MatIconModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatChipsModule,
    MatDividerModule,
    ToastNotificationsModule.forRoot({ component: CustomToastComponent }),
    BrowserAnimationsModule,
    NgxPaginationModule,
    MatProgressBarModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatButtonToggleModule,
    ClipboardModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthenticationService,
    { provide: 'API_URL', useValue: environment.hostUrl },
    { provide: RouteReuseStrategy, useClass: AppRoutingCache },
    {
      provide: LOCALE_ID,
      useFactory: setLanguageFromBrowser,
      deps: [TranslateService],
    },
    MatDatepickerModule,
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    DatePipe,
    TranslateDescriptionPipe,
    ScoringToolsService,
    ThirdPartyScreeningReportService,
    ThirdPartiesProxyClient,
    CampaignProxyClient,
    QuestionariesProxyClient,
    UsersProxyClient,
    ThirdPartyEvaluationReportService,
    CommonsProxyClient,
    CompanyService,
    CompanyProxyClient,
    MonitoringConfigurationProxyClient,
    MonitoringConfigurationService,
    RiskListProxyClient,
    RiskListService,
    CampaignRiskboardReportService,
    CommercialProxyClient,
    CommercialService,
    RefinitivScoringProxyClient,
    UsageProxyClient,
    AccountService,
    AccountProxyClient,
    EmailNotificationProxyClient,
    EmailNotificaitonService,
    CampaignThirdPartyService,
    CampaignThirdPartyProxy,
    ThirdPartyTypesService,
    ThirdPartyQuestionaryReportService,
    ThirdPartyRiskboardReportService,
    FreshdeskWidgetService,
    FreshdeskProxyClient,
    CustomFieldsProxyClient,
    ScreeningReportService,
    AMLUpdateScoringProxyClient,
    ResolutionProxyClient,
    ResolutionReportService,
    ScoringToolsConfigurationProxyClient,
    ScoringToolsConfigurationService,
    EquifaxWSScoringService,
    EquifaxWSScoringProxyClient,
    EquifaxWSScreeningReport,
    BusinessCentralProxyClient,
    BusinessCentralService,
    ScreeningProviderService,
    MemberCheckScoringService,
    MemberCheckProxyClient,
    ClientProxyClient,
    ClientsService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
