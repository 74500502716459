import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResolutionRecord } from '@app/models/scoring-tools/resolutionRecord';
import { ScoringToolsService } from '@app/services/scoring-tools.service';

@Component({
  selector: 'app-resolution-record-comments',
  templateUrl: './resolution-record-comments.component.html',
  styleUrls: ['./resolution-record-comments.component.scss'],
})
export class ResolutionRecordCommentsComponent implements OnInit {
  @Input() resolutionRecord: ResolutionRecord;

  editMode: boolean;
  hasComments: boolean;

  constructor(private scoringToolsService: ScoringToolsService) { }

  ngOnInit(): void {
    this.checkIfHasComments();
  }

  editComments() {
    this.editMode = true;
  }

  cancelEdit() {
    this.editMode = false;
  }

  saveEdit() {
    this.scoringToolsService.addCommentToResolution(this.resolutionRecord.id, this.resolutionRecord.comment).subscribe();
    this.editMode = false;
    this.checkIfHasComments();
  }

  private checkIfHasComments(): void {
    this.hasComments = (this.resolutionRecord.comment !== null && this.resolutionRecord.comment !== '');
  }
}
