import { Injectable } from "@angular/core";
import { Screening } from "@app/models/scoring-tools/screening";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MemberCheckProxyClient } from "src/proxy/apiMemberCheck/memberCheck.proxy";
import { MemberScanRqDto } from "src/proxy/model/memberScanRqDto";
import { MemberFilter } from "../models/member-check/member-filter";
import { mapMemberCheckScreening } from "./mappings/member-check/member-check-screening.mapping";
import { mapMemberCheckLink } from "./mappings/member-check/member-check-link.mapping";
import { MemberCheckLink } from "../models/member-check/member-check-link";
import { mapMemberCheckScanResult, mapMemberCheckScanResultDto } from "./mappings/member-check/member-check-scan-result.mapping";
import { MemberCheckScanResult } from "../models/member-check/member-check-scan-result";

@Injectable()
export class MemberCheckScoringService {

  constructor(private memberCheckClient: MemberCheckProxyClient) { }

  public getScreenings(id: number): Observable<Screening[]> {

    return this.memberCheckClient.memberCheckLinkIdScreeningsGet(id).pipe(map(r => r.map(s => mapMemberCheckScreening(s))))
  }

  public deleteLink(id: number): Observable<boolean> {

    return this.memberCheckClient.memberCheckLinkIdDelete(id);
  }

  public refresh(memberCheckId: number, thirdPartyId?: number): Observable<string> {

    return this.memberCheckClient.memberCheckLinkIdRefreshGet(memberCheckId, thirdPartyId);
  }

  public getScoringLinks(thirdPartyId?: number): Observable<MemberCheckLink[]> {

    return this.memberCheckClient.memberCheckLinksGet(thirdPartyId).pipe(map(r => r.map(d => mapMemberCheckLink(d))));
  }

  public screeningDetails(id: number): Observable<string> {

    return this.memberCheckClient.memberCheckScreeningIdPdfGet(id);
  }

  public searchCorp(companyName: string, registrationNumber: string, address: string): Observable<[string, number]> {

    return this.memberCheckClient.memberCheckCorpPostForm(companyName, registrationNumber, address).pipe(map(r => [r?.Item1, r?.Item2]));;
  }

  public searchMember(filter: MemberFilter): Observable<[string, number]> {

    const searchParams = <MemberScanRqDto>{
      Address: filter.address,
      Dob: filter.dob,
      FirstName: filter.firstName,
      Lastname: filter.lastname,
      MiddleName: filter.middleName,
      Gender: filter.gender?.code
    };

    return this.memberCheckClient.memberCheckMemberPost(searchParams).pipe(map(r => [r?.Item1, r?.Item2]));
  }

  public saveScreening(candidate: MemberCheckScanResult, thirdPartyId?: number): Observable<boolean> {

    return this.memberCheckClient.memberCheckLinkPost(mapMemberCheckScanResultDto(candidate), thirdPartyId);
  }

  public candidateCorpReport(memberCheckId: number): Observable<string> {

    return this.memberCheckClient.memberCheckCorpReportIdGet(memberCheckId);
  }

  public candidateMemberReport(memberCheckId: number): Observable<string> {

    return this.memberCheckClient.memberCheckMemberReportIdGet(memberCheckId);
  }

  public scanMemberCandidates(scanId: number): Observable<MemberCheckScanResult[]> {

    return this.memberCheckClient.memberCheckMemberScanIdGet(scanId).pipe(map(r => r.map(s => mapMemberCheckScanResult(s))));
  }

  public scanCorpCandidates(scanId: number): Observable<MemberCheckScanResult[]> {

    return this.memberCheckClient.memberCheckCorpScanIdGet(scanId).pipe(map(r => r.map(s => mapMemberCheckScanResult(s))));
  }
}
