<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
  <header class="c-dialog__header">
    <h1 id="titleDialog" class="c-dialog__title">
      {{ "fillThirdPartyClientData.title" | translate }}
    </h1>
  </header>
  <div class="c-dialog__content u-pb-1">
    <h2 class="c-dialog__subtitle">
      {{ ("fillThirdPartyClientData.subtitle" | translate).replace('${clientName}', data.clientName) }}
    </h2>
    <p class="o-text">{{ "fillThirdPartyClientData.text" | translate }}</p>
    <div *ngIf="isLoading">
      <mat-progress-bar mode="indeterminate" class="c-spinner u-mt-1 u-mb-1"></mat-progress-bar>
    </div>
    <div *ngIf="!isLoading" class="c-form" [formGroup]="thirdPartyClientInfoForm">
      <div class="row">
        <div class="col-12 u-mb-1">
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="companyName"
              name="company-name"
              id="company-name"
              class="c-form__input"
              placeholder="{{
                'fillThirdPartyClientData.companyNamePlaceholder' | translate
              }}"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="taxId"
              name="tax-id"
              id="tax-id"
              class="c-form__input"
              placeholder="{{
                'fillThirdPartyClientData.nifPlaceholder' | translate
              }}"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-6">
            <div class="c-select">
              <mat-form-field>
                <mat-select formControlName="countryId" placeholder="{{'fillThirdPartyClientData.countryPlaceholder' | translate }}"  required>
                  <mat-option *ngFor="let country of countries" [value]="country.Id">
                    {{country | translatedescription}}</mat-option>
                </mat-select>
              </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-xl-6">
          <div class="c-login-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select formControlName="typeOfActivityId" placeholder="{{'fillThirdPartyClientData.activityTypePlaceholder' | translate }}"   required>
                  <mat-option *ngFor="let typeOfActivity of typeOfActivities" [value]="typeOfActivity.Id">
                    {{typeOfActivity | translatedescription}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6">
          <div class="c-login-form__block">
            <div class="c-select">
              <mat-form-field>
                <mat-select formControlName="sectorId" placeholder="{{'fillThirdPartyClientData.sectorPlaceholder' | translate }}" required>
                  <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                    {{sector | translatedescription}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-8">
          <div class="c-login-form__block">
            <mat-form-field>
              <input
                type="text"
                matInput
                name="address"
                id="config-address"
                class="c-form__input"
                formControlName="address"
                placeholder="{{
                  'fillThirdPartyClientData.addressPlaceholder' | translate
                }}"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <div class="c-login-form__block">
            <mat-form-field>
              <input
                type="text"
                matInput
                name="zip-code"
                id="config-zip-code"
                class="c-form__input"
                formControlName="zipCode"
                placeholder="{{
                  'fillThirdPartyClientData.zipCodePlaceholder' | translate
                }}"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <footer *ngIf="!isLoading" class="c-dialog__footer">
      <button
        type="submit"
        (click)="sendClientThirdPartyData()"
        class="c-button c-button--green"
        [disabled]="!thirdPartyClientInfoForm.valid"
      >
        {{ "fillThirdPartyClientData.confirmInformation" | translate }}
      </button>
    </footer>
  </div>
</div>
