<div class="o-wrapper">
  <main id="content">
    <div [formGroup]="form">
      <section class="c-risklist">
        <div class="c-risklist-head box-shadow">
          <div class="c-risklist-head__wrapper">
            <div class="c-risklist-head__left">
              <h2 class="o-title o-title--small">{{'Firma.title1.valor'|translate}}</h2>
              <h3 class="o-title">{{'Firma.title2.valor'|translate}}</h3>
            </div>
            <div class="c-risklist-head__right">
              <button type="button" [disabled]="!canSign()" (click)="openDialog()" class="c-button"
                disabled>{{'Firma.button.continue.valor'|translate}}</button>
            </div>
          </div>
        </div>
        <div class="c-risklist-bottom">
          <div class="o-checkbox u-mt-1medium">
            <mat-checkbox formControlName="Sign">{{'Firma.info1.valor'|translate}}</mat-checkbox>
          </div>
          <div>
            <p class="o-text">{{'Firma.politica1.valor'|translate}} <a
                href="https://www.riskallay.com/legales/politica-privacidad" target="_blank"
                class="color-primary">{{'Firma.politica2.valor'|translate}} </a></p>
          </div>
          <div class="o-checkbox u-mt-1medium">
            <mat-checkbox formControlName="Sign2">{{'Firma.info2.valor'|translate}}</mat-checkbox>
          </div>
        </div>
      </section>
    </div>
  </main>
</div>
