import { Component, OnInit } from '@angular/core';

import { RemoteService } from '@app/services/remote.service';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications'

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styles: [
  ]
})
export class ForgottenPasswordComponent implements OnInit {
    email: string;
    sent = false;
    emailErrorMessage: string;

  constructor(
    private remote: RemoteService,
    private translate: TranslateService,
    private toaster: Toaster

  ) { }

  ngOnInit(): void {
    this.translate.get('ForgottenPassword.emailError')
      .subscribe(translation => {
        this.emailErrorMessage = translation;
      });

    //Obtenermos altura de ventana y la multiplicamos por 1% para obtener valor para vh
    let vh = window.innerHeight * 0.01;
    //Establecemos valor de --vh a la raíz del documento
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    //Evento redimensionar
    window.addEventListener('resize', () => {
        //Se ejecuta mismo script que antes
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

  }

  sendMail() {
    let emailInfo = { email: this.email }
    this.remote.postRequest('account/ForgotPassword/', emailInfo)
        .then(r => this.sent = true)
        .catch(r => this.toaster.open({ text: this.emailErrorMessage, duration: 10000, type: 'danger'}))
  }

}
