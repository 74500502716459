import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ManualScoreCreateDTO, ManualScoreUpdateDTO} from '@app/models/ManualScore';
import { RemoteService } from '@app/services/remote.service';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TranslateDescriptionPipe } from '@app/shared/pipes/translate-description.pipe';
import { EvaluationQuestionaryRiskDto } from 'src/proxy/model/evaluationQuestionaryRiskDto';

@Component({
  selector: 'app-dialog-third-evaluation',
  templateUrl: './dialog-third-evaluation.component.html',
  styles: [
  ]
})
export class DialogThirdEvaluationComponent implements OnInit {
  assessmentAddedMessage: string;
  assessmentAddErrorMessage: string;
  updatedAssessmentMessage: string;
  errorUpdatingAssessmentMessage: string;

  valorations = [
    {value: '1', viewValue: '1'},
    {value: '2', viewValue: '2'},
    {value: '3', viewValue: '3'},
    {value: '4', viewValue: '4'},
    {value: '5', viewValue: '5'},
  ];

  form=this.fb.group({
    Valoraciones: this.fb.array([])
  }) 

  constructor(
    private fb: FormBuilder,
    private remoteService: RemoteService,
    private toaster: Toaster,
    private translate: TranslateService,
    private translateDescription: TranslateDescriptionPipe,
    public dialogRef: MatDialogRef<DialogThirdEvaluationComponent>,
    @Inject(MAT_DIALOG_DATA)public data: {
      risks: EvaluationQuestionaryRiskDto[],
      campaign:number,
      third:number,
      editMode:boolean      
    }
  ) { 
    this.translate.get([
      'ListadoPreguntas.form.peso1.valor',
      'ListadoPreguntas.form.peso2.valor',
      'ListadoPreguntas.form.peso3.valor',
      'ListadoPreguntas.form.peso4.valor',
      'ListadoPreguntas.form.peso5.valor'
    ]).subscribe(r => {
      this.valorations = [
        {value: '1', viewValue: r['ListadoPreguntas.form.peso1.valor']},
        {value: '2', viewValue: r['ListadoPreguntas.form.peso2.valor']},
        {value: '3', viewValue: r['ListadoPreguntas.form.peso3.valor']},
        {value: '4', viewValue: r['ListadoPreguntas.form.peso4.valor']},
        {value: '5', viewValue: r['ListadoPreguntas.form.peso5.valor']},
      ];
    })
  }

  ngOnInit(): void {
    for (let index = 0; index < this.data.risks.length; index++) {
      const element = this.data.risks[index];
      let comboValue:any=null;
      
      if (this.data.editMode){
        const scores = element.ManualScore;
        if (scores) {
          let lastManualScore = scores;
          comboValue= lastManualScore.toString();
        }
      }

      let ctrl=new FormControl(comboValue,[Validators.required])
      this.valoraciones.push(ctrl);
    }
    this.translate.get('CampaignRiskboard.edit.assessmentAdded')
      .subscribe(translation => {
          this.assessmentAddedMessage = translation;
      });
    this.translate.get('CampaignRiskboard.edit.assessmentAddError')
      .subscribe(translation => {
          this.assessmentAddErrorMessage = translation;
      });
    this.translate.get('CampaignRiskboard.edit.updatedAssessment')
      .subscribe(translation => {
          this.updatedAssessmentMessage = translation;
      });
    this.translate.get('CampaignRiskboard.edit.errorUpdatingAssessment')
      .subscribe(translation => {
          this.errorUpdatingAssessmentMessage = translation;
      });
  }

  get valoraciones(){
    let v=this.form.get('Valoraciones') as FormArray;
    return v;
  }

  getRiskName(idx:number){
    return this.translateDescription.transform(this.data.risks[idx].Risk);
  }

  getRiskId(idx:number){
    return this.data.risks[idx].Risk.Id;
  }

  getscoreId(idx:number){
    return this.data.risks[idx].Id;
  }

  constructData():ManualScoreCreateDTO[]|ManualScoreUpdateDTO[]{
    let puntuaciones:ManualScoreCreateDTO[]=[];
    let puntuacionesUpdate:ManualScoreUpdateDTO[]=[];    

    if (this.data.editMode){
      for (let index = 0; index < this.data.risks.length; index++) {
        const element = this.data.risks[index];
        
        let puntuacion=(<ManualScoreUpdateDTO>{});
        puntuacion.ScoreValue=this.valoraciones.at(index).value;
        puntuacion.RiskId=element.RiskId;
        puntuacion.Id=0;
        puntuacionesUpdate.push(puntuacion);
      }
      return puntuacionesUpdate;
    } else {
      for (let index = 0; index < this.data.risks.length; index++) {
        const element = this.data.risks[index];
        let puntuacion=(<ManualScoreCreateDTO>{});
        puntuacion.ScoreValue=this.valoraciones.at(index).value;
        puntuacion.RiskId=element.RiskId;
        puntuaciones.push(puntuacion);
        
      }
      return puntuaciones;
    }
    
  }

  guardarEvaluacion() {

    let puntuaciones:ManualScoreCreateDTO[]=this.constructData();    
    let t=this.data.third;
    let c=this.data.campaign;
    this.remoteService.postRequest('Questionaries/AddNewManualScoreQuestionary/'+t+'/'+c,puntuaciones)
    .then(r=>{
      this.toaster.open({ text: this.assessmentAddedMessage, duration: 4000, type: 'success' });
      this.dialogRef.close(true);
    })
    .catch(err=>{
      this.toaster.open({ text: this.assessmentAddErrorMessage, duration: 4000, type: 'danger' });
      this.dialogRef.close(false);
    })
  }

  actualizarEvaluacion(){
    let puntuaciones:ManualScoreUpdateDTO[]=(<ManualScoreUpdateDTO[]>this.constructData());    
    let t=this.data.third;
    let c=this.data.campaign;
    this.remoteService.putRequest('Questionaries/UpdateManualScoreQuestionary/'+t+'/'+c,puntuaciones)
    .then(r=>{
      this.toaster.open({ text: this.updatedAssessmentMessage, duration: 4000, type: 'success' });
      this.dialogRef.close(true);
    })
    .catch(err=>{
      this.toaster.open({ text: this.errorUpdatingAssessmentMessage, duration: 4000, type: 'danger' });
      this.dialogRef.close(false);
    })
  }

  // cerrar modal
  onNoClick(): void {
    this.dialogRef.close();
  }
}
