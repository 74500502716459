<ng-container [formGroup]="form">
  <ng-container formArrayName="Options">
    <div class="row u-mb-editBlock option-container" *ngFor="let option of options.controls; let index = index">
      <ng-container [formGroup]="option">
        <div class="c-form__block col-7">
          <mat-form-field>
            <mat-label>
              {{'ListadoPreguntas.form.option' | translate }} {{ index + 1 }}
            </mat-label>
            <input matInput type="text" formControlName="Text"
                class="option-text" />
          </mat-form-field>
        </div>
        <mat-form-field class="col-3" *ngIf="!scoreType">
          <mat-select formControlName="Weight"
              placeholder="{{'ListadoPreguntas.form.answerRisk' | translate }}">
              <mat-option *ngFor="let weightValue of weightValues"
                  [value]="weightValue.value">
                  {{weightValue.text}}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <button *ngIf="canEdit" type="button" matTooltip="Borrar"
            class="col-1 delete-button" aria-label="Eliminar"
            (click)="deleteOption(index)">
            <span class="c-action-icons__ico" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 19.667 20"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path
                        d="M20.833,3.333H18.25A4.174,4.174,0,0,0,14.167,0H9.5A4.174,4.174,0,0,0,5.417,3.333H2.833A.833.833,0,0,0,2.833,5h.833V15.833A4.172,4.172,0,0,0,7.833,20h8A4.172,4.172,0,0,0,20,15.833V5h.833a.833.833,0,0,0,0-1.667ZM9.5,1.667h4.667a2.5,2.5,0,0,1,2.358,1.667H7.142A2.5,2.5,0,0,1,9.5,1.667Zm8.833,14.167a2.5,2.5,0,0,1-2.5,2.5h-8a2.5,2.5,0,0,1-2.5-2.5V5h13Z"
                        transform="translate(-2)" />
                    <path
                        d="M9.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,9.833,16.667Z"
                        transform="translate(-3.333-1.666)" />
                    <path
                        d="M13.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,13.833,16.667Z"
                        transform="translate(-4-1.666)" id="0" />
                    <use transform="translate(3.333)"
                        xlink:href="#0" />
                </svg>
            </span>
        </button>
      </ng-container>
    </div>
    <button *ngIf="canEdit" class="c-button c-button--gradient col-2" (click)="addOption()">
      <img src="./assets/nueva-evaluacion.svg" alt="" loading="lazy" />
      {{'ListadoPreguntas.form.addOption' | translate }}
    </button>
  </ng-container>
</ng-container>
