import { Injectable } from "@angular/core";
import { ScreeningProvider } from "@app/modules/screening-hub/models/ScreeningProvider";
import { Observable } from "rxjs";
import { AMLUpdateCredentialsDto } from "src/proxy/apiScoringToolsAccess/model/aMLUpdateCredentialsDto";
import { EquifaxWSCredentialsDto } from "src/proxy/apiScoringToolsAccess/model/equifaxWSCredentialsDto";
import { RefinitivCredentialsDto } from "src/proxy/apiScoringToolsAccess/model/refinitivCredentialsDto";
import { ScoringToolsConfigurationProxyClient } from "src/proxy/apiScoringToolsAccess/scoringToolsConfiguration.proxy";

@Injectable()
export class ScoringToolsConfigurationService {

  constructor(
    private scoringToolsConfigurationClientService: ScoringToolsConfigurationProxyClient,
  ) { }

  getConfigurations(): Observable<ScreeningProvider[]> {
    return this.scoringToolsConfigurationClientService.scoringToolsConfigurationGet().pipe(r => r.map(result => result.map(c => <ScreeningProvider>c)));
  }

  createRefinitivConfig(apiKey: string, apiSecret: string): Observable<boolean> {
    const config = <RefinitivCredentialsDto>{
      ApiKey: apiKey,
      ApiSecret: apiSecret
    }

    return this.scoringToolsConfigurationClientService.scoringToolsConfigurationRefinitivConfigPost(config);
  }

  createAMLUpdateConfig(apiAuthToken: string): Observable<boolean> {
    const config = <AMLUpdateCredentialsDto>{
      ApiAuthToken: apiAuthToken
    }

    return this.scoringToolsConfigurationClientService.scoringToolsConfigurationAmlUpdateConfigPost(config);
  }

  createEquifaxWSConfig(datumUser: string, password: string, reference: string, user: string): Observable<boolean> {
    const config = <EquifaxWSCredentialsDto>{
      DatumUser: datumUser,
      Password: password,
      Reference: reference,
      User: user
    }

    return this.scoringToolsConfigurationClientService.scoringToolsConfigurationEquifaxWSConfigPost(config);
  }

  deleteConfiguration(provider: ScreeningProvider): Observable<boolean> {
    return this.scoringToolsConfigurationClientService.scoringToolsConfigurationDeleteSoftwareDelete(provider);
  }
}
