<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'CampaignRiskboard.edit.modalTitle'|translate}}</h1>
        <button mat-button (click)="onNoClick()" class="c-dialog__close" aria-label="Cerrar ventana modal">
          <span aria-hidden="true"> 
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                  <path d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243" transform="translate(-309.86-406.47)"/>
              </svg>
          </span>
        </button>
    </header>
    <div class="c-dialog__content u-pb-1">
        <h2 class="c-dialog__subtitle">{{'CampaignRiskboard.edit.title'|translate}}</h2>
        <p class="o-text">{{'CampaignRiskboard.edit.text'|translate}}</p>
        <div class="row u-mt-1medium" [formGroup]="form">
            <ng-container formArrayName="Valoraciones">
                <div *ngFor="let v of valoraciones.controls; let i = index" class="col-lg-6 u-mb-2">
                    <p id="evaluationGeneral" class="c-fields__legend u-mb-1">{{ getRiskName(i) }}</p>
                    <div class="c-select" aria-labelledby="evaluationGeneral">
                        <mat-form-field>
                            <mat-select placeholder="{{'CampaignRiskboard.edit.score' | translate}}" [formControlName]="i">
                                <mat-option *ngFor="let valoration of valorations" [value]="valoration.value">{{ valoration.viewValue }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <footer class="c-dialog__footer flex-end">
        <button *ngIf="!data.editMode" class="c-button c-button--green u-align-right" mat-button (click)="guardarEvaluacion()" [disabled]="form.invalid">{{'CampaignRiskboard.edit.saveAssessment'|translate}}</button>
        <button *ngIf="data.editMode" class="c-button c-button--green u-align-right" mat-button (click)="actualizarEvaluacion()" [disabled]="form.invalid">{{'CampaignRiskboard.edit.updateAssessment'|translate}}</button>
    </footer>
</div>