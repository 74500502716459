import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Campaign } from "@app/models/Campaign";
import { CampaignService } from "@app/services/campaign-service";
import { CampaignRiskboardReportService } from "@app/services/reports/campaign-riskboard-report.service";
import { TranslateService } from "@ngx-translate/core";
import { Toaster } from "ngx-toast-notifications";
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import download from "downloadjs";
import { forkJoin } from "rxjs";
import { CampaignStatus } from "@app/models/CampaignStatus";

@Component({
  selector: 'app-campaign-riskboard',
  templateUrl: './campaign-riskboard.component.html',
  styleUrls: ['./campaign-riskboard.component.scss']
})
export class CampaignRiskboardComponent implements OnInit, AfterViewInit, OnDestroy {
  campaingEvoChart: am4charts.XYChart;
  evaluationsSectorsChart: am4charts.XYChart;
  evaluationsCountriesChart: am4charts.XYChart;

  clientCampaigns: Campaign[];
  assesments = 0;
  pending = 0;
  inProgress = 0;
  pendingSign = 0;
  pendingReview = 0;
  reviewed = 0;
  discarded = 0;
  acknowledged = 0;
  campaignFilter: number[];

  generatingReportMessage: string;
  reportGeneratedMessage: string;

  isLoading = true;

  constructor(
    private reportService: CampaignRiskboardReportService,
    private campaignService: CampaignService,
    private translate: TranslateService,
    private toaster: Toaster) { }

  ngOnInit() {
    this.campaignFilter = [];

    this.translate.get('Riskboard.generatingReport')
      .subscribe(translation => {
        this.generatingReportMessage = translation;
      });

    this.translate.get('Riskboard.reportGenerated')
      .subscribe(translation => {
        this.reportGeneratedMessage = translation;
      });
  }

  ngAfterViewInit(): void {
    forkJoin({
      t: this.translate.get("Riskboard"),
      r: this.campaignService.getClientCampaigns()
    })
      .subscribe(({ t, r }) => {
        this.campaignsEvoChart(t, r);
        this.createEvaluationsSectorsChart(t);
        this.createEvaluationCountriesChart(t);
        this.clientCampaigns = r;
        this.loadDataData();
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.campaingEvoChart?.dispose();
    this.evaluationsSectorsChart?.dispose();
    this.evaluationsCountriesChart?.dispose();
  }

  async downloadReport() {
    this.toaster.open({ text: this.generatingReportMessage, duration: 4000, type: 'info' });
    this.campaingEvoChart.scrollbarX = new am4core.Scrollbar();
    const reportCampaigns = this.campaignFilter.length > 0 ? this.clientCampaigns.filter(c => this.campaignFilter.find(f => c.id === f)) : this.clientCampaigns;
    const imgAssesmentEvoChart = await this.campaingEvoChart.exporting.getImage('png');
    const imgthirdPartyStatusByCountryChart = await this.evaluationsCountriesChart.exporting.getImage('png');
    const imgthirdPartyStatusBySectorChart = await this.evaluationsSectorsChart.exporting.getImage('png');
    const a = await this.reportService.getReport(reportCampaigns, imgAssesmentEvoChart, imgthirdPartyStatusByCountryChart, imgthirdPartyStatusBySectorChart);
    this.toaster.open({ text: this.reportGeneratedMessage, duration: 4000, type: 'success' });
    download(a[1], a[0], "application/pdf");
  }

  loadDataData() {
    const filteredCampaigns = this.campaignFilter.length > 0 ? this.clientCampaigns.filter(c => this.campaignFilter.find(f => c.id === f)) : this.clientCampaigns;
    this.campaignsEvoChartData(filteredCampaigns);
    this.evaluationsSectorsChartData(filteredCampaigns);
    this.evaluationsCountriesChartData(filteredCampaigns);
    this.assesments = filteredCampaigns.length;
    this.reviewed = filteredCampaigns.reduce((sum, c) => sum += c.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.reviewed).length, 0);
    this.pendingReview = filteredCampaigns.reduce((sum, c) => sum += c.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.pendingReview).length, 0);
    this.inProgress = filteredCampaigns.reduce((sum, c) => sum += c.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.inProgress).length, 0);
    this.pending = filteredCampaigns.reduce((sum, c) => sum += c.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.pending).length, 0);
    this.pendingSign = filteredCampaigns.reduce((sum, c) => sum += c.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.pendingSign).length, 0);
    this.discarded = filteredCampaigns.reduce((sum, c) => sum += c.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.discarded).length, 0);
    this.acknowledged = filteredCampaigns.reduce((sum, c) => sum += c.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.acknowledged).length, 0);
  }

  changeCampaign(e) {
    this.campaignFilter = e.value;
    this.loadDataData();
  }

  campaignsEvoChart(translations: any, campaigns: Campaign[]) {
    this.campaingEvoChart = am4core.create("assesmentEvolutionChartDiv", am4charts.XYChart);

    let categoryAxis = this.campaingEvoChart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.labels.template.rotation = 315;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.maxWidth = 100;
    categoryAxis.renderer.labels.template.tooltipText = "{category}"

    let valueAxis = this.campaingEvoChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 25;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });

    valueAxis.adapter.add("getTooltipText", (text) => {
      return text + "%";
    });

    let pendingResponseSerie = this.campaingEvoChart.series.push(new am4charts.ColumnSeries());
    pendingResponseSerie.dataFields.valueY = "pendingResponseThirdParties";
    pendingResponseSerie.columns.template.tooltipText = `${translations.pending} {valueY}`;
    pendingResponseSerie.dataFields.categoryX = "category";
    pendingResponseSerie.columns.template.width = am4core.percent(50);
    pendingResponseSerie.dataFields.valueYShow = "totalPercent";
    pendingResponseSerie.dataItems.template.locations.categoryX = 0.5;
    pendingResponseSerie.columns.template.strokeWidth = 0;
    pendingResponseSerie.stacked = true;
    pendingResponseSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#A6ADC4');
    });

    let inProgressSerie = this.campaingEvoChart.series.push(new am4charts.ColumnSeries());
    inProgressSerie.dataFields.valueY = "inProgressThirdParties";
    inProgressSerie.columns.template.tooltipText = `${translations.inProgress} {valueY}`;
    inProgressSerie.columns.template.width = am4core.percent(50);
    inProgressSerie.dataFields.valueYShow = "totalPercent";
    inProgressSerie.dataFields.categoryX = "category";
    inProgressSerie.dataItems.template.locations.categoryX = 0.5;
    inProgressSerie.columns.template.strokeWidth = 0;
    inProgressSerie.stacked = true;
    inProgressSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#7881A0');
    });

    let pendingSignSerie = this.campaingEvoChart.series.push(new am4charts.ColumnSeries());
    pendingSignSerie.dataFields.valueY = "pendingSignThirdParties";
    pendingSignSerie.columns.template.tooltipText = `${translations.pendingSign} {valueY}`;
    pendingSignSerie.dataFields.categoryX = "category";
    pendingSignSerie.columns.template.width = am4core.percent(50);
    pendingSignSerie.dataFields.valueYShow = "totalPercent";
    pendingSignSerie.dataItems.template.locations.categoryX = 0.5;
    pendingSignSerie.columns.template.strokeWidth = 0;
    pendingSignSerie.stacked = true;
    pendingSignSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#1F2D61');
    });

    let pendingReviewSerie = this.campaingEvoChart.series.push(new am4charts.ColumnSeries());
    pendingReviewSerie.dataFields.valueY = "pendingReviewThirdParties";
    pendingReviewSerie.columns.template.tooltipText = `${translations.pendingReview} {valueY}`;
    pendingReviewSerie.dataFields.categoryX = "category";
    pendingReviewSerie.columns.template.width = am4core.percent(50);
    pendingReviewSerie.dataFields.valueYShow = "totalPercent";
    pendingReviewSerie.dataItems.template.locations.categoryX = 0.5;
    pendingReviewSerie.columns.template.strokeWidth = 0;
    pendingReviewSerie.stacked = true;
    pendingReviewSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#F8B410');
    });

    let ackSerie = this.campaingEvoChart.series.push(new am4charts.ColumnSeries());
    ackSerie.dataFields.valueY = "ackThirdParties";
    ackSerie.columns.template.tooltipText = `${translations.acknowledged} {valueY}`;
    ackSerie.columns.template.width = am4core.percent(50);
    ackSerie.dataFields.valueYShow = "totalPercent";
    ackSerie.dataFields.categoryX = "category";
    ackSerie.dataItems.template.locations.categoryX = 0.5;
    ackSerie.columns.template.strokeWidth = 0;
    ackSerie.stacked = true;
    ackSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#64C700');
    });

    let discardedSerie = this.campaingEvoChart.series.push(new am4charts.ColumnSeries());
    discardedSerie.dataFields.valueY = "discardedThirdParties";
    discardedSerie.columns.template.tooltipText = `${translations.discarded} {valueY}`;
    discardedSerie.columns.template.width = am4core.percent(50);
    discardedSerie.dataFields.valueYShow = "totalPercent";
    discardedSerie.dataFields.categoryX = "category";
    discardedSerie.dataItems.template.locations.categoryX = 0.5;
    discardedSerie.columns.template.strokeWidth = 0;
    discardedSerie.stacked = true;
    discardedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#A30000');
    });

    let reviewedSerie = this.campaingEvoChart.series.push(new am4charts.ColumnSeries());
    reviewedSerie.dataFields.valueY = "reviewedThirdParties";
    reviewedSerie.columns.template.tooltipText = `${translations.reviewed} {valueY}`;
    reviewedSerie.columns.template.width = am4core.percent(50);
    reviewedSerie.dataFields.valueYShow = "totalPercent";
    reviewedSerie.dataFields.categoryX = "category";
    reviewedSerie.dataItems.template.locations.categoryX = 0.5;
    reviewedSerie.columns.template.strokeWidth = 0;
    reviewedSerie.stacked = true;
    reviewedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#AEBE08');
    });

    this.campaingEvoChart.scrollbarX = new am4core.Scrollbar();
    this.campaingEvoChart.zoomOutButton.disabled = true;
    this.campaingEvoChart.cursor = new am4charts.XYCursor();
    if (campaigns.length > 30) {
      categoryAxis.zoom({ start: 0, end: 0.1 });
    }
  }

  campaignsEvoChartData(campaigns: Campaign[]) {
    this.campaingEvoChart.data = campaigns.map(c => {
      return {
        category: c.name,
        reviewedThirdParties: c.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.reviewed).length,
        pendingReviewThirdParties: c.thirdParties.filter(f => f.CampaignStatus === CampaignStatus.pendingReview).length,
        inProgressThirdParties: c.thirdParties.filter(f => f.CampaignStatus === CampaignStatus.inProgress).length,
        pendingSignThirdParties: c.thirdParties.filter(f => f.CampaignStatus === CampaignStatus.pendingSign).length,
        pendingResponseThirdParties: c.thirdParties.filter(f => f.CampaignStatus === CampaignStatus.pending).length,
        discardedThirdParties: c.thirdParties.filter(f => f.CampaignStatus === CampaignStatus.discarded).length,
        ackThirdParties: c.thirdParties.filter(f => f.CampaignStatus === CampaignStatus.acknowledged).length,
      }
    });
  }

  evaluationsCountriesChartData(campaigns: Campaign[]) {
    const currentLang = this.translate.currentLang;
    const translatedNADescription = currentLang === 'es-ES' ? 'No especificado' : currentLang === 'en-US' ? 'Not specified' : 'Não especificado';
    this.evaluationsCountriesChart.data = campaigns.reduce((rv, c) =>
      c.thirdParties.reduce(function (rv, t) {
        if (t.Country === null) {
          t.Country = { Id: 0, Code: '', Description: translatedNADescription };
        }

        let index = rv.findIndex(g => g.category === t.Country.Description);
        if (index < 0) {
          rv.push({
            category: t.Country.Description,
            reviewedThirdParties: 0,
            pendingReviewThirdParties: 0,
            inProgressThirdParties: 0,
            pendingSignThirdParties: 0,
            pendingResponseThirdParties: 0,
            discardedThirdParties: 0,
            ackThirdParties: 0
          });
          index = rv.length - 1;
        }
        switch (t.CampaignStatus) {
          case CampaignStatus.reviewed:
            rv[index].reviewedThirdParties++;
            break;
          case CampaignStatus.pendingReview:
            rv[index].pendingReviewThirdParties++;
            break;
          case CampaignStatus.inProgress:
            rv[index].inProgressThirdParties++;
            break;
          case CampaignStatus.pendingSign:
            rv[index].pendingSignThirdParties++;
            break;
          case CampaignStatus.pending:
            rv[index].pendingResponseThirdParties++;
            break;
          case CampaignStatus.discarded:
            rv[index].discardedThirdParties++;
            break;
          case CampaignStatus.acknowledged:
            rv[index].ackThirdParties++;
            break;
          default:
            break;
        }
        return rv;
      }, rv), []);
  }

  evaluationsSectorsChartData(campaigns: Campaign[]) {
    this.evaluationsSectorsChart.data = campaigns.reduce((rv, c) =>
      c.thirdParties.reduce(function (rv, t) {
        if (t.ThirdPartyConfig.Sector.Description === '') {
          t.ThirdPartyConfig.Sector = { Id: 0, Code: 'Not especified', Description: 'No especificado', DescriptionEN: 'Not specified', DescriptionPT: 'Não especificado', DescriptionFR: 'Non spécifié', Client: null }
        }
        let index = rv.findIndex(g => g.category === t.ThirdPartyConfig.Sector.Description);
        if (index < 0) {
          rv.push({
            category: t.ThirdPartyConfig.Sector.Description,
            reviewedThirdParties: 0,
            pendingReviewThirdParties: 0,
            inProgressThirdParties: 0,
            pendingSignThirdParties: 0,
            pendingResponseThirdParties: 0,
            discardedThirdParties: 0,
            ackThirdParties: 0
          });
          index = rv.length - 1;
        }
        switch (t.CampaignStatus) {
          case CampaignStatus.reviewed:
            rv[index].reviewedThirdParties++;
            break;
          case CampaignStatus.pendingReview:
            rv[index].pendingReviewThirdParties++;
            break;
          case CampaignStatus.inProgress:
            rv[index].inProgressThirdParties++;
            break;
          case CampaignStatus.pendingSign:
            rv[index].pendingSignThirdParties++;
            break;
          case CampaignStatus.pending:
            rv[index].pendingResponseThirdParties++;
            break;
          case CampaignStatus.discarded:
            rv[index].discardedThirdParties++;
            break;
          case CampaignStatus.acknowledged:
            rv[index].ackThirdParties++;
            break;
          default:
            break;
        }
        return rv;
      }, rv), []);
  }

  createEvaluationsSectorsChart(translations: any) {
    this.evaluationsSectorsChart = am4core.create("evaluationsSectorsChartDiv", am4charts.XYChart);

    let categoryAxis = this.evaluationsSectorsChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.tooltipText = "{category}"

    let valueAxis = this.evaluationsSectorsChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minWidth = 25;

    let pendingResponseSerie = this.evaluationsSectorsChart.series.push(new am4charts.ColumnSeries());
    pendingResponseSerie.dataFields.valueX = "pendingResponseThirdParties";
    pendingResponseSerie.columns.template.tooltipText = `${translations.pending} {valueX}`;
    pendingResponseSerie.dataFields.categoryY = "category";
    pendingResponseSerie.columns.template.width = am4core.percent(50);
    pendingResponseSerie.dataItems.template.locations.categoryY = 0.5;
    pendingResponseSerie.columns.template.strokeWidth = 0;
    pendingResponseSerie.stacked = true;
    pendingResponseSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#A6ADC4');
    });

    let inProgressSerie = this.evaluationsSectorsChart.series.push(new am4charts.ColumnSeries());
    inProgressSerie.dataFields.valueX = "inProgressThirdParties";
    inProgressSerie.columns.template.tooltipText = `${translations.inProgress} {valueX}`;
    inProgressSerie.columns.template.width = am4core.percent(50);
    inProgressSerie.dataFields.categoryY = "category";
    inProgressSerie.dataItems.template.locations.categoryY = 0.5;
    inProgressSerie.columns.template.strokeWidth = 0;
    inProgressSerie.stacked = true;
    inProgressSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#7881A0');
    });

    let pendingSignSerie = this.evaluationsSectorsChart.series.push(new am4charts.ColumnSeries());
    pendingSignSerie.dataFields.valueX = "pendingSignThirdParties";
    pendingSignSerie.columns.template.tooltipText = `${translations.pendingSign} {valueX}`;
    pendingSignSerie.dataFields.categoryY = "category";
    pendingSignSerie.columns.template.width = am4core.percent(50);
    pendingSignSerie.dataItems.template.locations.categoryY = 0.5;
    pendingSignSerie.columns.template.strokeWidth = 0;
    pendingSignSerie.stacked = true;
    pendingSignSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#1F2D61');
    });

    let pendingReviewSerie = this.evaluationsSectorsChart.series.push(new am4charts.ColumnSeries());
    pendingReviewSerie.dataFields.valueX = "pendingReviewThirdParties";
    pendingReviewSerie.columns.template.tooltipText = `${translations.pendingReview} {valueX}`;
    pendingReviewSerie.dataFields.categoryY = "category";
    pendingReviewSerie.columns.template.width = am4core.percent(50);
    pendingReviewSerie.dataItems.template.locations.categoryY = 0.5;
    pendingReviewSerie.columns.template.strokeWidth = 0;
    pendingReviewSerie.stacked = true;
    pendingReviewSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#F8B410');
    });

    let ackSerie = this.evaluationsSectorsChart.series.push(new am4charts.ColumnSeries());
    ackSerie.dataFields.valueX = "ackThirdParties";
    ackSerie.columns.template.tooltipText = `${translations.acknowledged} {valueX}`;
    ackSerie.columns.template.width = am4core.percent(50);
    ackSerie.dataFields.categoryY = "category";
    ackSerie.dataItems.template.locations.categoryY = 0.5;
    ackSerie.columns.template.strokeWidth = 0;
    ackSerie.stacked = true;
    ackSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#64C700');
    });

    let discardedSerie = this.evaluationsSectorsChart.series.push(new am4charts.ColumnSeries());
    discardedSerie.dataFields.valueX = "discardedThirdParties";
    discardedSerie.columns.template.tooltipText = `${translations.discarded} {valueX}`;
    discardedSerie.columns.template.width = am4core.percent(50);
    discardedSerie.dataFields.categoryY = "category";
    discardedSerie.dataItems.template.locations.categoryY = 0.5;
    discardedSerie.columns.template.strokeWidth = 0;
    discardedSerie.stacked = true;
    discardedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#A30000');
    });

    let reviewedSerie = this.evaluationsSectorsChart.series.push(new am4charts.ColumnSeries());
    reviewedSerie.dataFields.valueX = "reviewedThirdParties";
    reviewedSerie.columns.template.tooltipText = `${translations.reviewed} {valueX}`;
    reviewedSerie.columns.template.width = am4core.percent(50);
    reviewedSerie.dataFields.categoryY = "category";
    reviewedSerie.dataItems.template.locations.categoryY = 0.5;
    reviewedSerie.columns.template.strokeWidth = 0;
    reviewedSerie.stacked = true;
    reviewedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#AEBE08');
    });
  }

  createEvaluationCountriesChart(translations: any) {
    this.evaluationsCountriesChart = am4core.create("evaluationsCountriesChartDiv", am4charts.XYChart);

    let categoryAxis = this.evaluationsCountriesChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.tooltipText = "{category}"

    let valueAxis = this.evaluationsCountriesChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minWidth = 25;

    let pendingResponseSerie = this.evaluationsCountriesChart.series.push(new am4charts.ColumnSeries());
    pendingResponseSerie.dataFields.valueX = "pendingResponseThirdParties";
    pendingResponseSerie.columns.template.tooltipText = `${translations.pending} {valueX}`;
    pendingResponseSerie.dataFields.categoryY = "category";
    pendingResponseSerie.columns.template.width = am4core.percent(50);
    pendingResponseSerie.dataItems.template.locations.categoryY = 0.5;
    pendingResponseSerie.columns.template.strokeWidth = 0;
    pendingResponseSerie.stacked = true;
    pendingResponseSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#A6ADC4');
    });

    let inProgressSerie = this.evaluationsCountriesChart.series.push(new am4charts.ColumnSeries());
    inProgressSerie.dataFields.valueX = "inProgressThirdParties";
    inProgressSerie.columns.template.tooltipText = `${translations.inProgress} {valueX}`;
    inProgressSerie.columns.template.width = am4core.percent(50);
    inProgressSerie.dataFields.categoryY = "category";
    inProgressSerie.dataItems.template.locations.categoryY = 0.5;
    inProgressSerie.columns.template.strokeWidth = 0;
    inProgressSerie.stacked = true;
    inProgressSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#7881A0');
    });

    let pendingSignSerie = this.evaluationsCountriesChart.series.push(new am4charts.ColumnSeries());
    pendingSignSerie.dataFields.valueX = "pendingSignThirdParties";
    pendingSignSerie.columns.template.tooltipText = `${translations.pendingSign} {valueX}`;
    pendingSignSerie.dataFields.categoryY = "category";
    pendingSignSerie.columns.template.width = am4core.percent(50);
    pendingSignSerie.dataItems.template.locations.categoryY = 0.5;
    pendingSignSerie.columns.template.strokeWidth = 0;
    pendingSignSerie.stacked = true;
    pendingSignSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#1F2D61');
    });

    let pendingReviewSerie = this.evaluationsCountriesChart.series.push(new am4charts.ColumnSeries());
    pendingReviewSerie.dataFields.valueX = "pendingReviewThirdParties";
    pendingReviewSerie.columns.template.tooltipText = `${translations.pendingReview} {valueX}`;
    pendingReviewSerie.dataFields.categoryY = "category";
    pendingReviewSerie.columns.template.width = am4core.percent(50);
    pendingReviewSerie.dataItems.template.locations.categoryY = 0.5;
    pendingReviewSerie.columns.template.strokeWidth = 0;
    pendingReviewSerie.stacked = true;
    pendingReviewSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#F8B410');
    });

    let ackSerie = this.evaluationsCountriesChart.series.push(new am4charts.ColumnSeries());
    ackSerie.dataFields.valueX = "ackThirdParties";
    ackSerie.columns.template.tooltipText = `${translations.acknowledged} {valueX}`;
    ackSerie.columns.template.width = am4core.percent(50);
    ackSerie.dataFields.categoryY = "category";
    ackSerie.dataItems.template.locations.categoryY = 0.5;
    ackSerie.columns.template.strokeWidth = 0;
    ackSerie.stacked = true;
    ackSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#64C700');
    });

    let discardedSerie = this.evaluationsCountriesChart.series.push(new am4charts.ColumnSeries());
    discardedSerie.dataFields.valueX = "discardedThirdParties";
    discardedSerie.columns.template.tooltipText = `${translations.discarded} {valueX}`;
    discardedSerie.columns.template.width = am4core.percent(50);
    discardedSerie.dataFields.categoryY = "category";
    discardedSerie.dataItems.template.locations.categoryY = 0.5;
    discardedSerie.columns.template.strokeWidth = 0;
    discardedSerie.stacked = true;
    discardedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#A30000');
    });

    let reviewedSerie = this.evaluationsCountriesChart.series.push(new am4charts.ColumnSeries());
    reviewedSerie.dataFields.valueX = "reviewedThirdParties";
    reviewedSerie.columns.template.tooltipText = `${translations.reviewed} {valueX}`;
    reviewedSerie.columns.template.width = am4core.percent(50);
    reviewedSerie.dataFields.categoryY = "category";
    reviewedSerie.dataItems.template.locations.categoryY = 0.5;
    reviewedSerie.columns.template.strokeWidth = 0;
    reviewedSerie.stacked = true;
    reviewedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#AEBE08');
    });
  }
}
