<div
  role="dialog"
  class="c-dialog"
  aria-labelledby="titleDialog"
  style="display: block; visibility: visible"
>
  <header class="c-dialog__header">
    <h1 id="titleDialog" class="c-dialog__title">
      {{ "RefinitivMonitoring.title" | translate }}
    </h1>
    <button
      mat-button
      (click)="onNoClick()"
      class="c-dialog__close"
      attr.aria-label="{{ 'RefinitivMonitoring.closeModalWindow' | translate }}"
    >
      <span aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
          <path
            d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
            transform="translate(-309.86-406.47)"
          />
        </svg>
      </span>
    </button>
  </header>
  <div class="c-dialog__content small-padding">
    <h2 class="c-dialog__subtitle">
      {{ "RefinitivMonitoring.subtitle" | translate }}
    </h2>
    <div class="c-fields">
      <p class="o-text">{{ "RefinitivMonitoring.text" | translate }}</p>
      <div class="row">
        <p class="col c-fields__legend u-mt-1medium">
          {{ "RefinitivMonitoring.monitoringFrequency" | translate }}
        </p>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="c-select">
            <mat-form-field class="c-form__block">
              <input
                type="number"
                matInput
                name="repeat-in"
                min="1"
                max="100"
                step="1"
                pattern="^[1-9][0-9]?$|^100$"
                [(ngModel)]="repeatIn"
                id="repeat-in"
                placeholder=""
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="col-6">
          <div class="c-select">
            <mat-form-field class="c-form__block">
              <mat-select required [(ngModel)]="periodicityType" placeholder="">
                <mat-option [value]="PeriodicityType.Monthly">
                  {{ "RefinitivMonitoring.frequencyTypes.month" | translate }}
                </mat-option>
                <mat-option [value]="PeriodicityType.Yearly">
                  {{ "RefinitivMonitoring.frequencyTypes.year" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <p class="c-fields__legend u-mt-1medium">
        {{ "RefinitivMonitoring.defineRiskAlerts" | translate }}
      </p>
      <div class="row">
        <div class="col-12">
          <div class="c-select">
            <mat-form-field class="c-form__block">
              <mat-hint>{{
                "RefinitivMonitoring.riskAlertsHint" | translate
              }}</mat-hint>
              <mat-select
                [(ngModel)]="risks"
                multiple
                placeholder="{{
                  'RefinitivMonitoring.risksWithAlertsViaEmail' | translate
                }}"
              >
                <mat-option value="Local Government"
                  >Local Government</mat-option
                >
                <mat-option value="Regulatory">Regulatory</mat-option>
                <mat-option value="Pending Assignment"
                  >Pending Assignment</mat-option
                >
                <mat-option value="National Government"
                  >National Government</mat-option
                >
                <mat-option value="World-Check other bodies"
                  >World-Check other bodies</mat-option
                >
                <mat-option value="Special Interest Categories"
                  >Special Interest Categories</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="c-dialog__footer">
    <mat-dialog-actions *ngIf="isEditing">
      <button
        class="c-button c-button--red"
        mat-button
        (click)="deleteMonitoring()"
      >
        {{ "RefinitivMonitoring.delete" | translate }}
      </button>
      <button
        class="c-button c-button--green"
        mat-button
        [disabled]="
          !(
            periodicityType != undefined &&
            repeatIn != undefined &&
            repeatIn >= 1 &&
            repeatIn <= 100 &&
            risks != undefined &&
            risks.length !== 0
          )
        "
        (click)="sendData()"
      >
        {{ "RefinitivMonitoring.saveChanges" | translate }}
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="!isEditing">
      <button class="c-button" mat-button [mat-dialog-close]="false">
        {{ "RefinitivMonitoring.cancel" | translate }}
      </button>
      <button
        class="c-button c-button--green"
        mat-button
        [disabled]="
          !(
            periodicityType != undefined &&
            repeatIn != undefined &&
            repeatIn >= 1 &&
            repeatIn <= 100 &&
            risks != undefined &&
            risks.length !== 0
          )
        "
        (click)="sendData()"
      >
        {{ "RefinitivMonitoring.activate" | translate }}
      </button>
    </mat-dialog-actions>
  </footer>
</div>
