import { Component, Input } from "@angular/core";
import { EquifaxWSIndividualData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSIndividualData";

@Component({
  selector: 'equifax-ws-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss'],
})
export class EquifaxWSPropertiesComponent {
  @Input() individualDetails: EquifaxWSIndividualData;

  constructor() { }
}
