import { Component, Input } from "@angular/core";
import { EquifaxWSMortgageCreditData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSMortgageCreditData";

@Component({
  selector: 'equifax-ws-mortgage-credit',
  templateUrl: './mortgage-credit.component.html',
  styleUrls: ['./mortgage-credit.component.scss'],
})
export class EquifaxWSMortgageCreditComponent {
  @Input() credit: EquifaxWSMortgageCreditData;
  @Input() index: number;

  constructor() { }

}
