import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

interface DtoWithDescription {
  Description?: string;
  DescriptionEN?: string;
  DescriptionPT?: string;
  DescriptionFR?: string;
  description?: string;
  descriptionEN?: string;
  descriptionPT?: string;
  descriptionFR?: string;
}

@Pipe({ name: 'translatedescription', pure: false })
export class TranslateDescriptionPipe implements PipeTransform, OnDestroy {
  private onLanguageChange: Subscription;
  private dtoWithDescription: DtoWithDescription;

  constructor(private translate: TranslateService) {
    this.onLanguageChange = this.translate.onLangChange.subscribe((_) =>
      this.transform(this.dtoWithDescription)
    );
  }

  transform(dtoWithDescription: DtoWithDescription) {
    this.dtoWithDescription = dtoWithDescription;
    return this.extractTranslatedDescription();
  }

  ngOnDestroy(): void {
    this.onLanguageChange.unsubscribe();
  }

  private extractTranslatedDescription() {
    switch (this.translate.currentLang) {
      case 'es-ES':
        return this.dtoWithDescription?.Description || this.dtoWithDescription?.description || '-';
      case 'pt-PT':
        return this.dtoWithDescription?.DescriptionPT || this.dtoWithDescription?.descriptionPT || '-';
      case 'en-US':
        return this.dtoWithDescription?.DescriptionEN || this.dtoWithDescription?.descriptionEN || '-';
      case 'fr-FR':
        return this.dtoWithDescription?.DescriptionFR || this.dtoWithDescription?.descriptionFR || '-';
      default:
        return this.dtoWithDescription?.DescriptionEN || this.dtoWithDescription?.descriptionEN || '-';
    }
  }
}
