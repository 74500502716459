import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThirdPartyDTO } from '@app/models/ThirdParty';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { DialogConfirmImportThirdToCampaignComponent } from '@app/modules/thirdparties/dialog-confirm-import-third-to-campaign/dialog-confirm-import-third-to-campaign.component';
import { parsearErroresAPI, Table3PPConfiguration } from '@app/utilities/utilities';
import { CampaignUpdateDTO } from '@app/models/Campaign';
import { CampaignService } from '@app/services/campaign-service';
import { GlobalService } from '@app/services/global.service';

@Component({
  selector: 'app-add-thirdparties',
  templateUrl: './add-thirdparties.component.html',
  styleUrls: ['./add-thirdparties.component.scss']
})
export class AddThirdpartiesComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public dialog: MatDialog,
    private campaignService: CampaignService,
    private router: Router,
    private global: GlobalService
  ) { }

  errores: string[] = [];
  model: CampaignUpdateDTO;
  idCampaign: number;
  selectedRows: Array<ThirdPartyDTO> = [];
  startedCampaign: Table3PPConfiguration;

  ngOnInit(): void {
    this.startedCampaign = {
      'columnas': ['select', 'id', 'name', 'type', 'headquarters', 'sector', 'responsible'],
      'acciones': [],
      'tipo': null,
      filterByTypes: true,
      'tableTarget': 'startedCampaign'
    }

    this.activatedRoute.params.subscribe(params => {
      if (params.id! > 0) {
        this.idCampaign = params.id;
        this.getData(this.idCampaign);
        this.startedCampaign.campaign = this.idCampaign;
      }
    });


  }

  public getData(id) {
    this.campaignService.getCampaignById(id).then(response => {
      this.model = <CampaignUpdateDTO>response;
      this.model.StartDate = new Date(this.model.StartDate);
      this.model.EndDate = new Date(this.model.EndDate);

    });
  }

  public back() {
    this.location.back()
  }

  public selectRows(selectRows: ThirdPartyDTO[]) {
    this.selectedRows = selectRows;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogConfirmImportThirdToCampaignComponent, {
      data: {
        CampaignName: this.model.Name,
        CountThirdParties: this.model.ThirdParties.length,
        CountNewThirdParties: this.selectedRows.length,
        StartDate: this.model.StartDate,
        QuestionaryName: this.model.Questionary.Description
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.model.ThirdParties = this.selectedRows;
        this.campaignService.addThirPartiesToCampaign(this.idCampaign, this.model).subscribe(response => {
          this.global.uploadedThirdParties = this.selectedRows;
          this.router.navigate(['/evaluacion-enviada-new-thirdparties/' + `/${response}`]);
        }, errores => this.errores = parsearErroresAPI(errores))
      }
    });
  }
}
