import { Component, Input, OnChanges } from '@angular/core';
import { QueryResult } from '@app/models/common/queryResult';
import { PEPInfo } from '@app/modules/screening-hub/models/GesintelScreening/PEPInfo';

@Component({
  selector: 'aml-update-kinship-pep',
  templateUrl: './kinship-pep.component.html',
  styleUrls: ['./kinship-pep.component.scss'],
})
export class KinshipPEPComponent implements OnChanges {
  @Input() kinships: PEPInfo[];

  displayedColumns: string[] = [
    'nameAndRut',
    'mainCategory',
    'kinship',
    'category',
    'role',
    'institution',
    'startDate',
    'endDate'
  ];

  queryResult: QueryResult<PEPInfo> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() { }

  ngOnChanges(): void {
    this.getScreenings();
  }

  getScreenings() {
    if (this.kinships) {
      this.queryResult.items = this.kinships;
      this.queryResult.totalItems = this.kinships.length;
    }
  }
}
