<div class="kinship-pep">
  <p id="kinshipPEPLegend" class="kinship-pep__legend u-mt-1medium">
    {{ "kinshipPEP.title" | translate }}
  </p>
  <section class="c-table">
    <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="nameAndRut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "kinshipPEP.nameAndRut" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="c-responsible">
            <div class="c-responsible__info">
              <span class="c-responsible__name">{{ element?.fullname }}</span>
              <div class="c-responsible__position">{{ element?.rut }}</div>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="mainCategory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "kinshipPEP.mainCategory" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.titularCategory }}
        </td>
      </ng-container>
      <ng-container matColumnDef="kinship">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "kinshipPEP.kinship" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.relationship }}
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "kinshipPEP.category" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.category }}
        </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "kinshipPEP.role" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.role }}
        </td>
      </ng-container>
      <ng-container matColumnDef="institution">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "kinshipPEP.institution" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.institution }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "kinshipPEP.startDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.fromDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "kinshipPEP.endDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.toDate }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">{{'kinshipPEP.noResults' | translate}} </td>
      </tr>
    </table>
  </section>
</div>
