<div class="o-wrapper">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section small-height">
            <div class="c-head-section__left">
                <h2 class="o-title">{{'Riskboard.title.valor'|translate}}</h2>
            </div>
            <div class="c-head-section__right">
            </div>
        </div>
        <!--tabs-->
        <mat-tab-group class="c-tabs" animationDuration="0ms">
            <!--todos-->
            <mat-tab label="{{'Riskboard.campaignTabTitle'|translate}}">
                <ng-template matTabContent>
                    <app-campaign-riskboard></app-campaign-riskboard>
                </ng-template>
            </mat-tab>
            <mat-tab label="{{'Riskboard.thirdPartyTabTitle'|translate}}">
                <ng-template matTabContent>
                    <app-third-party-riskboard></app-third-party-riskboard>
                </ng-template>                
            </mat-tab>                        
        </mat-tab-group>
    </main>
</div>