import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart, ChartHeader } from "../pdf-template-generator/Models/chart";
import { ColorHex } from "../pdf-template-generator/Models/colorHex";
import { Content } from "../pdf-template-generator/Models/content";
import { CoverPage } from "../pdf-template-generator/Models/coverPage";
import { DocumentContent } from "../pdf-template-generator/Models/documentContent";
import { Font } from "../pdf-template-generator/Models/font";
import { Footer } from "../pdf-template-generator/Models/footer";
import { Header } from "../pdf-template-generator/Models/header";
import { Image } from "../pdf-template-generator/Models/image";
import { Row } from "../pdf-template-generator/Models/row";
import { Section } from "../pdf-template-generator/Models/section";
import { SvgPath } from "../pdf-template-generator/Models/svgPath";
import { Text } from "../pdf-template-generator/Models/text";
import { TextUnderline } from "../pdf-template-generator/Models/textUnderline";
import { templateGenerator } from "../pdf-template-generator/template-generator";
import { UsersProxyClient } from "src/proxy/apiUser/users.proxy";
import { Campaign } from "@app/models/Campaign";
import { CampaignStatus } from "@app/models/CampaignStatus";
import { ThirdParty } from "@app/models/ThirdParty";
import { ThirdPartyTypeDto } from "src/proxy/model/thirdPartyTypeDto";
import { TranslateDescriptionPipe } from "@app/shared/pipes/translate-description.pipe";
import { ThirdPartyStatus } from "@app/models/ThirdPartyStatus";

@Injectable()
export class ThirdPartyRiskboardReportService {
    locale: string;

    constructor(private userClient: UsersProxyClient,
                private translateDescription: TranslateDescriptionPipe,
                private translate: TranslateService){ }

    async getReport(thirdParties: ThirdParty[], imgStatusEvoChart: string,
                    imgThirdPartyStatusByCountry: string,
                    imgThirdPartyStatusBySector: string
                    ): Promise<[string, Uint8Array]> {
        let sections: Section[] = [];
        this.locale = this.translate.currentLang.split("-")[0];
        const translations = await this.translate.get("thirdPartyRiskboardReport").toPromise();
        const userDetails = await this.userClient.usersGetUserDataGet().toPromise();
        const username = `${userDetails.Name} ${userDetails.SurName}`;
        const printDate = new Date();
        const fonts = await this.getReportFonts();

        const tpTypes = thirdParties.reduce((rv: ThirdPartyTypeDto[], tp) => {
            let index = rv.findIndex(g => g.Id === tp.thirdPartyConfig.ThirdPartyType?.Id);
            if(index < 0 && tp.thirdPartyConfig.ThirdPartyType != null) {
                rv.push(tp.thirdPartyConfig.ThirdPartyType);
            }

            return rv;
        }, []);

        const coverPage = await this.getReportCoverPage(userDetails.Client.Name, username, printDate, tpTypes, translations);
        const pageHeader = await this.getPageHeader(translations);
        const pageFooter = this.getPageFooter(userDetails.Client.Name, printDate, username, translations);
        const riskboardChartSection = this.getRiskboardChartSection(imgStatusEvoChart, translations);
        const imgPending = await fetch('/assets/report/thirdPartyPending.png').then((res) => res.arrayBuffer());
        const imgInProgress = await fetch('/assets/report/thirdPartyInProgress.png').then((res) => res.arrayBuffer());
        const imgApproved = await fetch('/assets/report/thirdPartyApproved.png').then((res) => res.arrayBuffer());
        const imgRejected = await fetch('/assets/report/thirdPartyRejected.png').then((res) => res.arrayBuffer());

        const thirdPartyResumeSection = this.getThirdPartyResumeSection(thirdParties, imgPending, imgInProgress, imgApproved, imgRejected, translations);

        const thirdPartiesStatusByCountryChartSection = this.getThirdPartiesStatusByCountryChartSection(imgThirdPartyStatusByCountry, translations);
        const thirdPartiesStatusByCountryDetailSection = this.getThirdPartiesStatusByCountryDetailsSection(thirdParties, translations);

        const thirdPartiesStatusBySectorChartSection = this.getThirdPartiesStatusBySectorChartSection(imgThirdPartyStatusBySector, translations);
        const thirdPartiesStatusBySectorDetailSection = this.getThirdPartiesStatusBySectorDetailsSection(thirdParties, translations);

        sections.push(riskboardChartSection, thirdPartyResumeSection);
        sections.push(thirdPartiesStatusByCountryChartSection);
        sections.push(thirdPartiesStatusByCountryDetailSection);
        sections.push(thirdPartiesStatusBySectorChartSection);
        sections.push(thirdPartiesStatusBySectorDetailSection);

        const docContent = <DocumentContent>{
            header: pageHeader,
            footer: pageFooter,
            sections: sections
        }

        return [`Third-Party-Riskboard-Report.pdf`,await templateGenerator(fonts, docContent, coverPage)];
    }

    async getReportFonts(): Promise<Font[]> {
        let fonts: Font[] = [];
        fonts.push(<Font>{font: await fetch('/assets/report/Roboto-Medium.ttf').then((res) => res.arrayBuffer()), name:'Roboto-Medium'});
        fonts.push(<Font>{font: await fetch('/assets/report/Roboto-Regular.ttf').then((res) => res.arrayBuffer()), name:'Roboto-Regular'});
        fonts.push(<Font>{font: await fetch('/assets/report/Roboto-Bold.ttf').then((res) => res.arrayBuffer()), name:'Roboto-Bold'});
        fonts.push(<Font>{font: await fetch('/assets/report/Poppins-SemiBold.ttf').then((res) => res.arrayBuffer()), name:'Poppins-SemiBold'});

        return fonts;
    }

    async getReportCoverPage(companyName: string, username: string, printDate: Date, thirdPartyTypes: ThirdPartyTypeDto[], translations: any): Promise<CoverPage> {
        let coverPage = <CoverPage>{content: [
                  <Content>{content:<Image>{ image: await fetch('/assets/report/background.png').then((res) => res.arrayBuffer()), height:842, width:595, customHeight:1.73},  posX:0, posY:0, isRelative: false},
                  <Content>{content:<Image>{ image: await fetch('/assets/report/coverImageRiskboard.png').then((res) => res.arrayBuffer()), height:398, width:515}, posX:40, posY:24, isRelative: false},
                  <Content>{content:<Image>{ image: await fetch('/assets/report/wave.png').then((res) => res.arrayBuffer()), height:842, width:595}, posX:0, posY:419, isRelative: false},
                  <Content>{content:<Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height:42, width:180}, posX:40, posY:454, isRelative: false},
                  <Content>{content:<Text>{text: translations.cover.report, font: 'Roboto-Medium', fontSize: 24, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 536, isRelative: false},
                  <Content>{content:<Text>{text: translations.cover.reportName, font: 'Poppins-SemiBold', fontSize: 40, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 40, posY: 585, isRelative: false},
                  <Content>{content:<Text>{text: translations.cover.intro, font: 'Roboto-Regular',
                                fontSize: 10, fontColor: <ColorHex>{hexCode: '666666'}, lineHeight: 16, maxWidth: 335}, posX: 40, posY: 660, isRelative: false}
        ]};

        let pos = 692;

        for (let index = 0; index < thirdPartyTypes.length; index++) {
            pos+=16;
            if(index<=4) {
                coverPage.content.push(<Content>{content:<Text>{text:` · ${this.translateDescription.transform(thirdPartyTypes[index])}`, font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{hexCode: '666666'}, lineHeight: 16, maxWidth: 335}, posX: 40, posY: pos, isRelative: false})
            } else {
                if(index == 5) {
                  coverPage.content.push(<Content>{content:<Text>{text:translations.cover.introEllipsis.replace('${numberOfTypes}', thirdPartyTypes.length-5), font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{hexCode: '666666'}, lineHeight: 16, maxWidth: 335}, posX: 40, posY: pos, isRelative: false})
                }
            }
        }

        coverPage.content.push(<Content>{content:<Text>{text:translations.infoCollectedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 399, posY: 660, isRelative: false},
                               <Content>{content:<Text>{text: companyName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 399, posY: 670, isRelative: false},
                               <Content>{content:<Text>{text:translations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 399, posY: 690, isRelative: false},
                               <Content>{content:<Text>{text: username, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 399, posY: 700, isRelative: false},
                               <Content>{content:<Text>{text:translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 399, posY: 720, isRelative: false},
                               <Content>{content:<Text>{text:formatDate(printDate,'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 399, posY: 730, isRelative: false}
        );

        return coverPage;
    }

    async getPageHeader(translations: any): Promise<Header> {
        return <Header>{height:67,
            content: [<Content>{content:<Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height:31, width:133}, posX:40, posY:24, isRelative: false},
                      <Content>{content:<Text>{text:translations.confidential, font: 'Roboto-Medium', fontSize: 10, fontColor: <ColorHex>{hexCode: 'f8b410'}, lineHeight:16}, posX: 474, posY: 31.5, isRelative: false}
            ]
        };
    }

    getPageFooter(companyName: string, printDate: Date, username: string, translations: any): Footer {
        return <Footer>{height:71,
            content:[<Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, color:<ColorHex>{hexCode:'d9d9d9'}, width:515},  posX:40, posY:771, isRelative: false},
                     <Content>{content:<Text>{text:translations.infoCollectedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 783, isRelative: false},
                     <Content>{content:<Text>{text:translations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 796, isRelative: false},
                     <Content>{content:<Text>{text:translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 809, isRelative: false},
                     <Content>{content:<Text>{text:companyName, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}}, posX: 154, posY: 783, isRelative: false},
                     <Content>{content:<Text>{text:username, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}},  posX: 154, posY: 796, isRelative: false},
                     <Content>{content:<Text>{text:formatDate(printDate,'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}}, posX: 154, posY: 809, isRelative: false},
                     <Content>{content:<Text>{text:translations.pageNumber, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}, lineHeight:16}, posX: 522, posY: 783, isRelative: false},
            ]
        };
    }

    getRiskboardChartSection(imgStatusEvoChart: string, translations: any) {
        return <Section>{
            isFullPage: false,
            isAbsolute: true,
            content: [<Content>{content:<Text>{text: translations.riskboardChartSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 84, isRelative: false},
                      <Content>{content:<Chart>{height: 233, width: 515, margin: 7,chart: imgStatusEvoChart, header:<ChartHeader>{height:19, text:translations.riskboardChartSection.chartTitle, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:40,posY:16, isRelative: true},
            ]
        };
    }

    getThirdPartyResumeSection(thirdParties: ThirdParty[], imgPending: ArrayBuffer, imgInProgress: ArrayBuffer, imgApproved: ArrayBuffer, imgRejected: ArrayBuffer, translations: any): Section {
        const section = <Section> {
            isFullPage: false,
            isAbsolute: false,
            content: [<Content>{content:<Text>{text:translations.thirdPartyResumeSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 16, isRelative: true}]
        };

        const groupedData = thirdParties.reduce((rv, tp) => {
            if (tp.thirdPartyConfig.ThirdPartyType === null) {
              return rv;
            }
            let index = rv.findIndex(g => g.tpTypeId === tp.thirdPartyConfig.ThirdPartyType.Id);
            if(index < 0) {
                rv.push({
                    tpTypeId: tp.thirdPartyConfig.ThirdPartyType.Id,
                    tpType: tp.thirdPartyConfig.ThirdPartyType,
                    pendingThirdParties: 0,
                    inProgressThirdParties: 0,
                    approvedThirdParties: 0,
                    rejectedThirdParties: 0,
                    totalTPs: 0
                });
                index = rv.length-1;
            }
            rv[index].totalTPs++;
            switch (tp.status) {
                case ThirdPartyStatus.pending:
                    rv[index].pendingThirdParties++;
                    break;
                case ThirdPartyStatus.inProgress:
                    rv[index].inProgressThirdParties++;
                    break;
                case ThirdPartyStatus.approved:
                    rv[index].approvedThirdParties++;
                    break;
                case ThirdPartyStatus.rejected:
                    rv[index].rejectedThirdParties++;
                    break;
                default:
                    break;
            }
            return rv;
        }, []);

        groupedData.map(tpType =>
            section.content.push(
                <Content>{content: <Row> {rowContent: [
                    <Content>{content:<Text>{text: this.translateDescription.transform(tpType.tpType), font:'Roboto-Bold', fontSize:10, lineHeight:12, fontColor:<ColorHex>{hexCode:'383838'}}, posX: 0, posY: 12, isRelative: true},
                    <Content>{content:<SvgPath>{ path: 'M 0,0 L 1,0 1,-12 0,-12 ', height:12, width: 1,color:<ColorHex>{hexCode:'fdc130'}, maxWidth:1},  posX:8, posY:12, isRelative: true},
                    <Content>{content:<Text>{text: `${tpType.totalTPs.toString()} ${translations.thirds}`, font:'Roboto-Regular', fontSize:10, lineHeight:12, fontColor:<ColorHex>{hexCode:'383838'}}, posX: 8, posY: 12, isRelative: true},
                ]},posX:40, posY:12, isRelative: true},
                <Content>{content: <Row>{rowContent: [
                    <Content>{content:<Image>{image: imgPending, height:28, width:28}, posX:0, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: tpType.pendingThirdParties.toString(), maxWidth:100, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.pending, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-100, posY:29.6, isRelative: true},
                    <Content>{content:<Image>{image: imgInProgress, height:28, width:28}, posX:25, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: tpType.inProgressThirdParties.toString(), maxWidth: 100, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-100, posY:29.6, isRelative: true},
                    <Content>{content:<Image>{image: imgApproved, height:28, width:28}, posX:25, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: tpType.approvedThirdParties.toString(), maxWidth:100, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.approved, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-100, posY:29.6, isRelative: true},
                    <Content>{content:<Image>{image: imgRejected, height:28, width:28}, posX:25, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: tpType.rejectedThirdParties.toString(), maxWidth:100, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.rejected, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-100, posY:29.6, isRelative: true},
                ]},posX:40, posY:12.5, isRelative: true},
                <Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, width: 515,color:<ColorHex>{hexCode:'d9d9d9'}},  posX:40, posY:17, isRelative: true}
            )
        );

        return section;
    }

    getThirdPartiesStatusByCountryChartSection(imgThirdPartyStatusByCountry: string, translations: any) {
        return <Section>{
            isFullPage: false,
            isAbsolute: true,
            content: [<Content>{content:<Text>{text: translations.thirdPartiesStatusByCountryChartSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 84, isRelative: false},
                      <Content>{content:<Chart>{height: 233, width: 515, margin: 7,chart: imgThirdPartyStatusByCountry, header:<ChartHeader>{height:19, text: translations.thirdPartiesStatusByCountryChartSection.chartTitle, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:40,posY:16, isRelative: true},
            ]
        };
    }

    getThirdPartiesStatusByCountryDetailsSection(thirdParties: ThirdParty[], translations: any): Section {
        const dotSvgPath = 'M 4, 0 m -4, 0 a 6,6 0 1,0 12,0 a 6,6 0 1,0 -12,0';
        const groupedData = thirdParties.reduce((rv, tp) => {
            if (tp.country === null) {
              return rv;
            }

            let index = rv.findIndex(g => g.countryId === tp.country.id);
            if(index < 0) {
                rv.push({
                    countryId: tp.country.id,
                    country: tp.country,
                    pendingThirdParties: 0,
                    inProgressThirdParties: 0,
                    approvedThirdParties: 0,
                    rejectedThirdParties: 0,
                    totalTPs: 0
                });
                index = rv.length-1;
            }
            rv[index].totalTPs++;
            switch (tp.status) {
                case ThirdPartyStatus.pending:
                    rv[index].pendingThirdParties++;
                    break;
                case ThirdPartyStatus.inProgress:
                    rv[index].inProgressThirdParties++;
                    break;
                case ThirdPartyStatus.approved:
                    rv[index].approvedThirdParties++;
                    break;
                case ThirdPartyStatus.rejected:
                    rv[index].rejectedThirdParties++;
                    break;
                default:
                    break;
            }
            return rv;
        }, []);

        let section = <Section> {
            isFullPage: false,
            isAbsolute: false,
            content: [<Content>{content:<Text>{text:translations.thirdPartiesStatusByCountryDetailsSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 32, isRelative: true},

            ]
        };

        const rowsToPrint = Math.ceil(groupedData.length / 2);

        for (let index = 0; index < rowsToPrint; index++) {
            const rowData = groupedData.splice(0,2);
            if(rowData.length > 1) {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: rowData[0].country.description, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:40, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].totalTPs} ${translations.thirds}`, font:'Roboto-Regular', maxWidth: 68, fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: rowData[1].country.description, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:24.5, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].totalTPs} ${translations.thirds}`, maxWidth: 68, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true}
                    ]},posX:0, posY:8, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].pendingThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.approved, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].approvedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.approved, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].approvedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'C70000'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.rejected, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].rejectedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'C70000'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.rejected, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].rejectedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );
            } else {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: rowData[0].country.description, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth: 168, lineHeight:12}, posX:40, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].totalTPs} ${translations.thirds}`, font:'Roboto-Regular', maxWidth: 68, fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true}
                    ]},posX:0, posY:8, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.approved, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].approvedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'C70000'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.rejected, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].rejectedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );
            }

            section.content.push(<Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, width: 515,color:<ColorHex>{hexCode:'d9d9d9'}},  posX:40, posY:10, isRelative: true});
        }

        return section;
    }

    getThirdPartiesStatusBySectorChartSection(imgThirdPartyStatusBySector: string, translations: any): Section {

        return <Section>{
            isFullPage: false,
            isAbsolute: true,
            content: [<Content>{content:<Text>{text: translations.thirdPartiesStatusBySectorChartSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 84, isRelative: false},
                      <Content>{content:<Chart>{height: 233, width: 515, margin: 7,chart: imgThirdPartyStatusBySector, header:<ChartHeader>{height:19, text: translations.thirdPartiesStatusBySectorChartSection.chartTitle, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:40,posY:16, isRelative: true},
            ]
        };
    }

    getThirdPartiesStatusBySectorDetailsSection(thirdParties: ThirdParty[], translations: any): Section {
        const dotSvgPath = 'M 4, 0 m -4, 0 a 6,6 0 1,0 12,0 a 6,6 0 1,0 -12,0';
        const groupedData = thirdParties.reduce((rv, tp) => {
            if (tp.thirdPartyConfig.Sector === null) {
              return rv;
            }

            let index = rv.findIndex(g => g.sectorId === tp.thirdPartyConfig.Sector.Id);
            if(index < 0) {
                rv.push({
                    sectorId: tp.thirdPartyConfig.Sector.Id,
                    sector: tp.thirdPartyConfig.Sector,
                    pendingThirdParties: 0,
                    inProgressThirdParties: 0,
                    approvedThirdParties: 0,
                    rejectedThirdParties: 0,
                    totalTPs: 0
                });
                index = rv.length-1;
            }
            rv[index].totalTPs++;
            switch (tp.status) {
                case ThirdPartyStatus.pending:
                    rv[index].pendingThirdParties++;
                    break;
                case ThirdPartyStatus.inProgress:
                    rv[index].inProgressThirdParties++;
                    break;
                case ThirdPartyStatus.approved:
                    rv[index].approvedThirdParties++;
                    break;
                case ThirdPartyStatus.rejected:
                    rv[index].rejectedThirdParties++;
                    break;
                default:
                    break;
            }
            return rv;
        }, []);

        let section = <Section> {
            isFullPage: false,
            isAbsolute: false,
            content: [<Content>{content:<Text>{text:translations.thirdPartiesStatusBySectorDetailsSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 32, isRelative: true},

            ]
        };

        const rowsToPrint = Math.ceil(groupedData.length / 2);

        //this.translateDescription.transform(thirdPartyTypes[index])

        for (let index = 0; index < rowsToPrint; index++) {
            const rowData = groupedData.splice(0,2);
            if(rowData.length > 1) {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: this.translateDescription.transform(rowData[0].sector), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:40, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].totalTPs} ${translations.thirds}`, font:'Roboto-Regular', maxWidth: 68, fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: this.translateDescription.transform(rowData[1].sector), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:24.5, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].totalTPs} ${translations.thirds}`, maxWidth: 68, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true}
                    ]},posX:0, posY:8, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].pendingThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.approved, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].approvedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.approved, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].approvedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'C70000'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.rejected, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].rejectedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'C70000'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.rejected, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].rejectedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );
            } else {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: this.translateDescription.transform(rowData[0].sector), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth: 168, lineHeight:12}, posX:40, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].totalTPs} ${translations.thirds}`, font:'Roboto-Regular', maxWidth: 68, fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true}
                    ]},posX:0, posY:8, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.approved, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].approvedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'C70000'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.rejected, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].rejectedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );
            }

            section.content.push(<Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, width: 515,color:<ColorHex>{hexCode:'d9d9d9'}},  posX:40, posY:10, isRelative: true});
        }

        return section;
    }
}
