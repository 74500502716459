import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { Toaster } from 'ngx-toast-notifications';

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements AfterViewInit {

    email: string;
    token: string;

    constructor(
        private router: Router, 
        private route: ActivatedRoute,
        private accountService: AccountService,
        private toast: Toaster
    ) { 
        this.route.queryParams.subscribe(params => {
            this.email = params['email'];
            this.token = params['token']
        });
    }

    ngAfterViewInit() {
        this.accountService.confirmEmail(this.email, this.token).subscribe(r => {
            if(r) {
                this.router.navigateByUrl('login');
            } else {
                this.toast.open({ text: 'Token expired. Please activate your account by resetting your password', duration: 6000, type: 'danger' })
            }
        });
    }

  
}
