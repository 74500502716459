import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { GlobalService } from "@app/services/global.service";

@Component({
  selector: 'app-onboarding-end',
  templateUrl: './onboarding-end.component.html',
  styles: [
  ]
})
export class OnboardingEndComponent implements OnInit {

  constructor(

    public global: GlobalService
  ) { }

  //Se agrega clase a body para customizar header y nav 
  ngOnInit(): void {

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('js-noHeader');

    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
        this.global.navShowOnboarding = false;
    }, 0);
   
  }

  ngOnDestroy(): void {

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('js-noHeader');

    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
        this.global.navShowOnboarding = true;
    }, 0);
  }

}
