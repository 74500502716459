import { Component } from '@angular/core';

@Component({
    selector: 'app-third-party-user-created',
    templateUrl: './third-party-user-created.component.html',
    styleUrls: ['./third-party-user-created.component.scss']
})
export class ThirdPartyUserCreatedComponent {

    constructor() { }
}
