import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonsService } from '@app/services/commons-service';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@app/services/account.service';
import { ThirdPartySignUpInfo } from '@app/models/ThirdPartySignUpInfo';
import { ThirdPartySignUpService } from '@app/services/third-party-signup.service';
import { forkJoin } from 'rxjs';
import { Sector } from '@app/models/Sector';
import { ActivityTypeDto } from 'src/proxy/model/activityTypeDto';
import { ThirdPartySignIn } from '@app/models/ThirdParty';
import { MatSelectChange } from '@angular/material/select';
import { CountryDto } from 'src/proxy/model/countryDto';
import { mapPositionToTranslationKey, Position, PositionValues } from '@app/models/Position';
import { Language, LanguageValues, mapLanguageToTranslationKey } from '@app/models/Language';

@Component({
  selector: 'app-third-party-sign-up',
  templateUrl: './third-party-sign-up.component.html',
  styleUrls: ['./third-party-sign-up.component.scss'],
})
export class ThirdPartySignUpComponent implements OnInit {
  thirdPartyInfo: ThirdPartySignUpInfo;
  typePassword: boolean;
  typePasswordRepeat: boolean;
  isShow = false;
  isShowRepeat = false;
  checkPolicy = false;
  createUserForm: FormGroup;
  tranlations: any;
  countries: CountryDto[];
  sectors: Sector[];
  typeOfActivities: ActivityTypeDto[];
  languages: Language[];
  loading = true;
  sendingData = false;
  positions: number[];

  constructor(
    private accountService: AccountService,
    private router: Router,
    private translate: TranslateService,
    private toast: Toaster,
    private commons: CommonsService,
    private thirdPartySignup: ThirdPartySignUpService
  ) {
    this.thirdPartyInfo =
      this.router.getCurrentNavigation().extras.state?.thirdPartyInfo ||
      ({} as ThirdPartySignUpInfo);

    if (
      !this.thirdPartyInfo ||
      !this.thirdPartyInfo.email ||
      !this.thirdPartyInfo.clientEmail ||
      !this.thirdPartyInfo.clientId ||
      !this.thirdPartyInfo.clientName ||
      !this.thirdPartyInfo.companyName
    ) {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
    this.positions = PositionValues;
    this.languages = LanguageValues;

    this.translate
      .get('createThirdPartyUser')
      .subscribe((t) => (this.tranlations = t));
    const model = {
      password: [
        '',
        [
          Validators.required,
          this.commons.passwordLengthValidator(),
          this.commons.passwordUpperValidator(),
          this.commons.passwordLowerValidator(),
          this.commons.passwordNumberValidator(),
          this.commons.passwordSpecialCharacterValidator(),
        ],
      ],
      passwordConfirm: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      checkPolicy: ['', Validators.required],
      email: [this.thirdPartyInfo.email ?? '', Validators.required],
      companyName: [this.thirdPartyInfo.companyName ?? '', Validators.required],
      taxId: [this.thirdPartyInfo.taxId ?? '', Validators.required],
      country: [
        this.thirdPartyInfo.countryId > 0
          ? this.thirdPartyInfo.countryId
          : null,
        Validators.required,
      ],
      typeOfActivity: [
        this.thirdPartyInfo.typeOfActivity > 0
          ? this.thirdPartyInfo.typeOfActivity
          : null,
        Validators.required,
      ],
      sector: [
        this.thirdPartyInfo.sectorId > 0 ? this.thirdPartyInfo.sectorId : null,
        Validators.required,
      ],
      address: [this.thirdPartyInfo.address, Validators.required],
      zipCode: [this.thirdPartyInfo.zipCode],
      jobTitle: ['', Validators.required],
      language: [this.getLanguageFromCurrentLanguage(), Validators.required],
    };

    const formBuilder = new FormBuilder();

    this.createUserForm = formBuilder.group(model, {
      validators: this.commons.passwordsEqualValidator,
    });

    forkJoin({
      countries: this.thirdPartySignup.getCountries(),
      sectors: this.thirdPartySignup.getSectors(this.thirdPartyInfo.clientId),
      activityTypes: this.thirdPartySignup.getActivityTypes(
        this.thirdPartyInfo.clientId
      ),
    }).subscribe(({ countries, sectors, activityTypes }) => {
      this.countries = countries;
      this.sectors = sectors;
      this.typeOfActivities = activityTypes;
      this.loading = false;
    });

    if (this.thirdPartyInfo.email) {
      this.createUserForm.get('email').disable();
    }

    if (this.thirdPartyInfo.companyName) {
      this.createUserForm.get('companyName').disable();
    }
  }

  createThirdPartyUser() {
    const thirdPartyInfo = this.formDataToThirdPartyInfo();
    this.sendingData = true;
    this.accountService.createThirdPartyAccount(thirdPartyInfo).subscribe(
      (r) => {
        this.sendingData = false;
        if (r) {
          this.toast.open({
            text: this.tranlations['createdSuccessfully'],
            duration: 6000,
          });
          this.router.navigateByUrl('third-party-user-created');
        } else {
          this.toast.open({
            text: this.tranlations['createFailed'],
            duration: 6000,
            type: 'danger',
          });
        }
      },
      (err) => {
        this.sendingData = false;
        this.toast.open({
          text: this.tranlations['createFailed'],
          duration: 6000,
          type: 'danger',
        });
      }
    );
  }

  formDataToThirdPartyInfo(): ThirdPartySignIn {
    return <ThirdPartySignIn>{
      email: this.thirdPartyInfo.email,
      contactName: this.createUserForm.value.name,
      companyName: this.createUserForm.value.companyName,
      taxId: this.createUserForm.value.taxId,
      zipCode: this.createUserForm.value.zipCode,
      address: this.createUserForm.value.address,
      countryId: this.createUserForm.value.country,
      sectorId: this.createUserForm.value.sector,
      activityTypeId: this.createUserForm.value.typeOfActivity,
      surname: this.createUserForm.value.surname,
      password: this.createUserForm.value.password,
      position: this.createUserForm.value.jobTitle,
      languageId: this.createUserForm.value.language,
    };
  }

  get password() {
    return this.createUserForm.get('password');
  }

  get passwordConfirm() {
    return this.createUserForm.get('passwordConfirm');
  }

  //Mostrar password
  togglePassword = function () {
    this.typePassword = !this.typePassword;
    this.isShow = !this.isShow;
  };

  //Mostrar repetir password
  togglePasswordRepeat = function () {
    this.typePasswordRepeat = !this.typePasswordRepeat;
    this.isShowRepeat = !this.isShowRepeat;
  };

  compareById(item: { Id?: number; id?: number }, selected: number) {
    return item.Id === selected || item.id === selected;
  }

  getLanguageFromCurrentLanguage() {
    const currentLang = this.translate.currentLang;
    switch (currentLang) {
      case 'es-ES':
        return Language.Spanish;
      case 'en-US':
        return Language.English;
      case 'pt-PT':
        return Language.Portuguese;
      default:
        return Language.English;
    }
  }

  changeLanguage(selectChange: MatSelectChange): void {
    const language = selectChange.value as Language;

    switch (language) {
      case 2:
        this.translate.use('es-ES');
        break;
      case 3:
        this.translate.use('en-US');
        break;
      case 4:
        this.translate.use('pt-PT');
        break;
      default:
        this.translate.use('en-US');
        break;
    }
  }

  getPositionTranslationKey(position: Position) {
    return mapPositionToTranslationKey(position);
  }

  getLanguageTranslationKey(language: Language) {
    return mapLanguageToTranslationKey(language);
  }
}
