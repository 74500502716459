<div class="basic-data">
  <p id="keyData" class="basic-data__legend u-mt-1medium">{{ "AMLUpdateBasicData.title" | translate }}</p>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="thirdName" class="basic-data-item__title">
        {{ "AMLUpdateBasicData.name" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdName">
        {{ scoringDetails?.fullName || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="thirdAlias" class="basic-data-item__title">
        {{ "AMLUpdateBasicData.rut" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdAlias">
        {{ scoringDetails?.rut || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="thirdRisks" class="basic-data-item__title">
        {{ "AMLUpdateBasicData.nationality" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdAlias">
        {{ scoringDetails?.nationality || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="thirdCategory" class="basic-data-item__title">
        {{ "AMLUpdateBasicData.birthDate" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdCategory">
        {{ scoringDetails?.birthDate || "" }}
      </p>
    </div>
  </div>
</div>
<aml-update-parental-mesh [parentalMesh]="scoringDetails?.parentalMesh"></aml-update-parental-mesh>
