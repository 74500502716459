import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ScreeningProvider, mapProviderName } from "@app/modules/screening-hub/models/ScreeningProvider";
import { ScreeningProviderService } from "@app/modules/screening-hub/services/screening-provider.service";
import { ScoringToolsConfigurationService } from "@app/services/scoring-tools-configuration.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-add-scoring-tools-access',
  templateUrl: './add-scoring-tools-access.component.html',
  styleUrls: ['./add-scoring-tools-access.component.scss']
})
export class AddScoringToolsAccessComponent {

  screeningProviders: ScreeningProvider[];
  ScreeningProvider = ScreeningProvider;
  selectedProvider: ScreeningProvider;

  apiKey: string = "";
  apiSecret: string = "";

  datumUser: string = "";
  user: string = "";
  password: string = "";
  reference: string = "";

  providerNameTranslations: { [key:string]: string };

  constructor(
    private screeningProviderService: ScreeningProviderService,
    public dialogRef: MatDialogRef<AddScoringToolsAccessComponent>,
    private scoringToolsConfigurationService: ScoringToolsConfigurationService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { currentConfigs: ScreeningProvider[] }

  ) {

    this.screeningProviders = this.screeningProviderService.clientProviders().filter(p => data.currentConfigs.indexOf(p) < 0);

    this.translate.get('providerNames')
      .subscribe(translation => {
        this.providerNameTranslations = translation;
      });
  }

  getProviderName(provider: ScreeningProvider): string {
    return mapProviderName(provider, this.providerNameTranslations);
  }

  isValid() {
    switch (this.selectedProvider) {
      case ScreeningProvider.Refinitiv:
        return this.apiKey !== "" && this.apiSecret !== "";
      case ScreeningProvider.AMLUpdate:
        return this.apiKey !== "";
      case ScreeningProvider.EquifaxWS:
        return this.datumUser !== "" && this.reference !== "" && this.user !== "" && this.password !== "";
      default:
        return false;
    }
  }

  save() {
    switch (this.selectedProvider) {
      case ScreeningProvider.Refinitiv:
        this.scoringToolsConfigurationService.createRefinitivConfig(this.apiKey, this.apiSecret).subscribe(r => this.dialogRef.close(r));
        break;
      case ScreeningProvider.AMLUpdate:
        this.scoringToolsConfigurationService.createAMLUpdateConfig(this.apiKey).subscribe(r => this.dialogRef.close(r));
        break;
      case ScreeningProvider.EquifaxWS:
        this.scoringToolsConfigurationService.createEquifaxWSConfig(this.datumUser, this.password, this.reference, this.user)
          .subscribe(r => this.dialogRef.close(r));
        break;
      default:
        break;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
