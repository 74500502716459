<div class="c-group-select">
    <!--evaluaciones-->
    <div class="c-group-select__item">
        <div class="c-select">
            <mat-form-field>
                <mat-select (selectionChange)="changeCampaign($event)" placeholder="{{'Riskboard.filterPlaceholder.valor' | translate}}" multiple>
                    <mat-option *ngFor="let campaign of clientCampaigns" [value]="campaign.id">{{campaign.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="c-group-select__item"></div>
    <div class="c-group-select__item"></div>
    <div class="c-group-select__item"></div>
    <div class="c-group-select__item">
        <button *appAuthRole="['Owner','Manager']" class="c-button" (click)="downloadReport()">
            {{'Riskboard.DownloadReport.valor'|translate}}
        </button>
    </div>
</div>
<div *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
</div>
<div class="o-space-lateral o-scroll">
    <!--estados-->
    <ul class="c-statistics">
        <li class="c-statistics-item">
            <div class="c-statistics-item__left" aria-hidden="true">
                <img src="/assets/assesments.svg" [alt]="" loading="lazy" />
            </div>
            <div class="c-statistics-item__right">
                <p class="c-statistics-item__value">{{ assesments }}</p>
                <p class="c-statistics-item__text">{{'Riskboard.total.valor'|translate}}</p>
            </div>
        </li>
        <li class="c-statistics-item">
            <div class="c-statistics-item__left" aria-hidden="true">
                <img src="/assets/campaingStatusPendingResponse.svg" [alt]="" loading="lazy" />
            </div>
            <div class="c-statistics-item__right">
                <p class="c-statistics-item__value">{{ pending }}</p>
                <p class="c-statistics-item__text">{{'Riskboard.pending'|translate}}</p>
            </div>
        </li>
        <li class="c-statistics-item">
            <div class="c-statistics-item__left" aria-hidden="true">
                <img src="/assets/campaignStatusInProgress.svg" [alt]="" loading="lazy" />
            </div>
            <div class="c-statistics-item__right">
                <p class="c-statistics-item__value">{{ inProgress }}</p>
                <p class="c-statistics-item__text">{{'Riskboard.inProgress'|translate}}</p>
            </div>
        </li>
        <li class="c-statistics-item">
            <div class="c-statistics-item__left" aria-hidden="true">
                <img src="/assets/campaignStatusPendingSign.svg" [alt]="" loading="lazy" />
            </div>
            <div class="c-statistics-item__right">
                <p class="c-statistics-item__value">{{ pendingSign }}</p>
                <p class="c-statistics-item__text">{{'Riskboard.pendingSign'|translate}}</p>
            </div>
        </li>
        <li class="c-statistics-item">
            <div class="c-statistics-item__left" aria-hidden="true">
                <img src="/assets/campaignStatusPendingReview.svg" [alt]="" loading="lazy" />
            </div>
            <div class="c-statistics-item__right">
                <p class="c-statistics-item__value">{{ pendingReview }}</p>
                <p class="c-statistics-item__text">{{'Riskboard.pendingReview'|translate}}</p>
            </div>
        </li>
        <li class="c-statistics-item">
            <div class="c-statistics-item__left" aria-hidden="true">
                <img src="/assets/campaignStatusAcknowledged.svg" [alt]="" loading="lazy" />
            </div>
            <div class="c-statistics-item__right">
                <p class="c-statistics-item__value">{{ acknowledged }}</p>
                <p class="c-statistics-item__text">{{'Riskboard.acknowledged'|translate}}</p>
            </div>
        </li>
        <li class="c-statistics-item">
            <div class="c-statistics-item__left" aria-hidden="true">
                <img src="/assets/campaignStatusDiscarded.svg" [alt]="" loading="lazy" />
            </div>
            <div class="c-statistics-item__right">
                <p class="c-statistics-item__value">{{ discarded }}</p>
                <p class="c-statistics-item__text">{{'Riskboard.discarded'|translate}}</p>
            </div>
        </li>
        <li class="c-statistics-item">
            <div class="c-statistics-item__left" aria-hidden="true">
                <img src="/assets/campaignStatusReviewed.svg" [alt]="" loading="lazy" />
            </div>
            <div class="c-statistics-item__right">
                <p class="c-statistics-item__value">{{ reviewed }}</p>
                <p class="c-statistics-item__text">{{'Riskboard.reviewed'|translate}}</p>
            </div>
        </li>
    </ul>
    <!--gráficos dos columnas-->
    <div class="row">
        <div class="col-lg-12">
            <article class="c-graphic">
                <header class="c-graphic__header">
                    <h1 class="c-graphic__title">{{'Riskboard.title.chart.assesments.valor'|translate}}</h1>
                </header>
                <div class="c-graphic__graph">
                    <div id="assesmentEvolutionChartDiv" style="width: 100%; height: 400px"></div>
                </div>
            </article>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <article class="c-graphic">
                <header class="c-graphic__header c-graphic__header--flex">
                    <h1 class="c-graphic__title">{{'Riskboard.title.chart.thirdPartyStatusByCountryAndAssesment.valor'|translate}}</h1>
                    <span class="c-graphic__header-ico" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <path d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z"/>
                        </svg>
                    </span>
                </header>
                <div class="c-graphic__graph">
                    <div id="evaluationsCountriesChartDiv" style="width: 100%; height: 400px"></div>
                </div>
            </article>
        </div>
        <div class="col-lg-6">
            <article class="c-graphic">
                <header class="c-graphic__header c-graphic__header--flex">
                    <h1 class="c-graphic__title">{{'Riskboard.title.chart.thirdPartyStatusBySectorAndAssesment.valor'|translate}}</h1>
                    <span class="c-graphic__header-ico" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <path d="M12.667,2.667h-.733A3.339,3.339,0,0,0,8.667,0H7.333A3.339,3.339,0,0,0,4.067,2.667H3.333A3.337,3.337,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6a3.337,3.337,0,0,0-3.333-3.333ZM7.333,1.333H8.667a2,2,0,0,1,1.877,1.333H5.456A2,2,0,0,1,7.333,1.333ZM3.333,4h9.333a2,2,0,0,1,2,2V8H1.333V6a2,2,0,0,1,2-2Zm9.333,10.667H3.333a2,2,0,0,1-2-2V9.333h6V10a.667.667,0,0,0,1.333,0V9.333h6v3.333A2,2,0,0,1,12.667,14.667Z"/>
                        </svg>
                    </span>
                </header>
                <div class="c-graphic__graph">
                    <div id="evaluationsSectorsChartDiv" style="width: 100%; height: 400px"></div>
                </div>
            </article>
        </div>
    </div>
</div>
