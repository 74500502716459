import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ActiveCampaignService {

  navShow = true;

  constructor(private router: Router) {}

  // Activar evaluación cuando se da a botón crear nueva evaluación
  activeCampaign(id) {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
        this.navShow = false;
    }, 0);
    this.router.navigate(['/nueva-evaluacion', id]);
  }

  // Cerrar evaluación cuando se da al botón cerrar
  closeCampaign() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
        this.navShow = true;
    }, 0);
    this.router.navigate(['/evaluaciones']);
  }

  closeCampaignToCampaigns() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
        this.navShow = true;
    }, 0);
    this.router.navigate(['/evaluaciones']);
  }
}
