<div class="movable-property">
  <p id="keyData" class="movable-property__legend u-mt-1medium">{{ "EquifaxWSMovableProperty.identification" | translate}} {{ property?.plate }}</p>
  <div class="movable-property__group" aria-labelledby="keyData">
    <div class="movable-property-item">
      <p id="brand" class="movable-property-item__title">
        {{ "EquifaxWSMovableProperty.brand" | translate }}
      </p>
      <p class="movable-property-item__text" aria-labelledby="brand">
        {{ property?.brand || "" }}
      </p>
    </div>
    <div class="movable-property-item">
      <p id="model" class="movable-property-item__title">
        {{ "EquifaxWSMovableProperty.model" | translate }}
      </p>
      <p class="movable-property-item__text" aria-labelledby="model">
        {{ property?.model || "" }}
      </p>
    </div>
    <div class="movable-property-item">
      <p id="year" class="movable-property-item__title">
        {{ "EquifaxWSMovableProperty.year" | translate }}
      </p>
      <p class="movable-property-item__text" aria-labelledby="year">
        {{ property?.year || "" }}
      </p>
    </div>
    <div class="movable-property-item">
      <p id="value" class="movable-property-item__title">
        {{ "EquifaxWSMovableProperty.value" | translate }}
      </p>
      <p class="movable-property-item__text" aria-labelledby="value">
        {{ property?.value || "" }}
      </p>
    </div>
  </div>
  <p id="keyData" class="movable-property__legend u-mt-1medium">{{ "EquifaxWSMovableProperty.liens" | translate }}</p>
  <section class="c-table">
    <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.type" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.type }}
        </td>
      </ng-container>
      <ng-container matColumnDef="registrationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.registrationDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.registrationDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.startDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.expDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.expDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.amount" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.amount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.currency" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="term">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.term" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.term }}
        </td>
      </ng-container>
      <ng-container matColumnDef="creditor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSLien.creditor" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.creditor }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">{{'EquifaxWSLien.noResults' | translate}} </td>
      </tr>
    </table>
    <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems"
      class="c-pagination">
    </mat-paginator>
  </section>
</div>
