<ng-container [formGroup]="questionForm">
  <div class="c-select col-12 col-xl-6">
    <mat-form-field>
      <mat-select
        class="c-form__select"
        formControlName="SelectedOption"
        placeholder="{{'RisklistFill.answer' | translate }}"
      >
        <mat-option *ngFor="let option of options" [value]="option.Text">
          {{ option.Text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
