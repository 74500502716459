<section class="c-table u-mt-1">
    <div class="o-scroll">
        <div class="c-table__bottom">
            <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort
                (matSortChange)="sortBy($event)">
                <ng-container matColumnDef="matchedAlias">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='false'>
                        {{ 'ThirdPartyCandidateTable.MatchedAlias.valor'|translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="c-responsible">
                            <div class="c-responsible__info">
                                <span class="c-responsible__name"
                                    style="font-weight: 400; color: black;">{{element.name}}</span>
                                <div class="c-responsible__position">{{element.matchedAlias}}</div>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="matchStrength">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='true'>
                        {{'ThirdPartyCandidateTable.MatchStrength.valor'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.matchStrength}}</td>
                </ng-container>
                <ng-container matColumnDef="worldCheckId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='false'>
                        {{'ThirdPartyCandidateTable.WorldCheckId.valor'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.worldCheckId}} </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='false'>
                        {{'ThirdPartyCandidateTable.Category.valor'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.category}} </td>
                </ng-container>
                <ng-container matColumnDef="software">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='false'>
                        {{'ThirdPartyCandidateTable.Software.valor'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.software}} </td>
                </ng-container>
                <ng-container matColumnDef="thirdPartyRisks">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='true'>
                        {{'ThirdPartyCandidateTable.ThirdPartyRisks.valor'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-chip-list aria-label="Third party risks">
                            <mat-chip *ngFor="let risk of element.thirdPartyRisks" color="primary">
                                {{ risk }}
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: false"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9">
                        {{'ThirdPartyCandidateTable.ThirdsNotFound.valor'|translate}}.
                    </td>
                </tr>
            </table>
            <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize"
                [length]="this.queryResult.totalItems" class="c-pagination">
            </mat-paginator>
        </div>
    </div>
</section>
