import { RoleDto } from "src/proxy/model/roleDto";
import { Role } from "../../models/GesintelScreening/Role";

export function mapRole(dto: RoleDto): Role {
  if (!dto) return null;

  return <Role> {
    type: dto.Type,
    category: dto.Category,
    role: dto.Role,
    fromDate: dto.FromDate,
    toDate: dto.ToDate,
  };
}
