import { Injectable } from '@angular/core';
import { Role } from '@app/models/Role';
import { AuthenticationService } from '@app/services/authentication.service';
import { ScreeningProvider } from '../models/ScreeningProvider';

@Injectable()
export class ScreeningProviderService {
  constructor(private authenticationService: AuthenticationService) { }

  clientProviders(): ScreeningProvider[] {

    let providers: ScreeningProvider[] = []
    const roles = this.authenticationService.getRoles();

    if (roles.includes(Role.Refinitiv)) {
      providers.push(ScreeningProvider.Refinitiv);
    }
    if (roles.includes(Role.AMLUpdate)) {
      providers.push(ScreeningProvider.AMLUpdate);
    }
    if (roles.includes(Role.EquifaxWS)) {
      providers.push(ScreeningProvider.EquifaxWS);
    }
    if (roles.includes(Role.MemberCheck)) {
      providers.push(ScreeningProvider.MemberCheck);
    }

    return providers;
  }
}
