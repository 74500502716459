import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-delete-risk',
  templateUrl: './dialog-delete-risk.component.html',
  styles: []
})
export class DialogDeleteRiskComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteRiskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  // cerrar modal
  onNoClick(): void {

    this.dialogRef.close();

  }
}
