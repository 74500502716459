<div class="o-wrapper">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section">
            <div class="c-head-section__left">
                <h2 class="o-title">
                   {{ 'addThirdPartiesToCampaign.title' | translate }}
                 </h2>
                <p class="o-text">{{ 'addThirdPartiesToCampaign.text' | translate}} <strong>{{ this.model ? this.model.Name : ''}}</strong> </p>
            </div>
            <div class="c-head-section__right">
                <button [disabled]="selectedRows.length == 0" (click)="openDialog()"  class="c-button c-button--large">
                  {{('addThirdPartiesToCampaign.button' | translate).replace('${numThirds}', this.selectedRows.length) }}
                </button>
            </div>
        </div>
        <!--volver atrás-->
        <div class="c-go-back c-go-back--steps">
            <button class="c-go-back__button" (click)="back()">
                <span class="c-go-back__arrow" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                        <path
                            d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                            transform="translate(-5-6.01)" />
                    </svg>
                </span>
                {{ 'addThirdPartiesToCampaign.back' | translate }}
            </button>
        </div>
        <!--tabs-->
        <app-third-party-table-extended (onSelectRow)="selectRows($event)" [config]="startedCampaign"></app-third-party-table-extended>
    </main>
</div>
