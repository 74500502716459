<div role="dialog" class="c-dialog" aria-labelledby="titleDialog" style="display:block;visibility:visible;">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'removeResolutionLink.title'|translate}}</h1>
        <button mat-button (click)="close()" class="c-dialog__close" aria-label="Cerrar ventana modal">
            <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path
                        d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
                        transform="translate(-309.86-406.47)" />
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content small-padding">
        <h2 class="c-dialog__subtitle">{{'removeResolutionLink.subtitle'|translate}}</h2>
        <p class="o-text">{{'removeResolutionLink.info'|translate}}</p>
    </div>
    <footer class="c-dialog__footer">
        <mat-dialog-actions>
            <button class="c-button" mat-button [mat-dialog-close]="false">
                {{'removeResolutionLink.cancel'|translate}}
            </button>
            <button class="c-button c-button--red" mat-button [mat-dialog-close]="true">
                {{'removeResolutionLink.confirm'|translate}}
            </button>
        </mat-dialog-actions>
    </footer>
</div>