<div class="o-wrapper">
  <main id="content">
    <!--encabezado de sección-->
    <div class="c-head-section c-head-section--tab bg-cream">
      <!--parte superior-->
      <div class="c-head-section--tab__top">
        <div class="c-head-section__left">
          <div class="c-head-section--tab__info">
            <h2 class="c-head-section--tab__title">
              Screening - {{ screeningDetails?.name || "" }}
            </h2>
          </div>
        </div>
        <div class="c-head-section__right">
          <button *appAuthRole="['Owner', 'Manager', 'Auditor','Assistant', 'Collaborator']" type="button"
            class="c-button" (click)="downloadReport()">
            {{ "ScreeningDetails.DownloadReport.valor" | translate }}
          </button>
        </div>
      </div>
      <!--volver a listado-->
      <div class="c-go-back">
        <a [routerLink]="" (click)="back()" class="c-go-back__button">
          <span class="c-go-back__arrow" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
              <path
                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                transform="translate(-5-6.01)" />
            </svg>
          </span>
          {{ "ScreeningDetails.GoBack.valor" | translate }}
        </a>
      </div>
      <!--parte inferior-->
      <div class="c-head-section--tab__bottom"></div>
    </div>
    <div class="o-space-lateral o-scroll">
      <div class="c-form c-form--space">
        <div class="c-fields">
          <p id="keyData" class="c-fields__legend-l u-mt-1medium">Key data</p>
          <div class="c-fields__group" aria-labelledby="keyData">
            <div class="c-fields-item-l">
              <p id="thirdName" class="c-fields-item-l__title">
                {{ "ScreeningDetails.Name.valor" | translate }}
              </p>
              <p class="c-fields-item-l__text" aria-labelledby="thirdName">
                {{ screeningDetails?.name || "" }}
              </p>
            </div>
            <div class="c-fields-item-l">
              <p id="thirdAlias" class="c-fields-item-l__title">
                {{ "ScreeningDetails.Alias.valor" | translate }}
              </p>
              <p class="c-fields-item-l__text" aria-labelledby="thirdAlias">
                {{ screeningDetails?.alias || "" }}
              </p>
            </div>
            <div class="c-fields-item-l">
              <p id="thirdRisks" class="c-fields-item-l__title">
                {{ "ScreeningDetails.Risks.valor" | translate }}
              </p>
              <mat-chip-list aria-label="Third party risks">
                <mat-chip *ngFor="
                    let risk of getRisks(screeningDetails?.thirdPartyRisks)
                  " color="primary">
                  {{ risk }}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="c-fields-item-l">
              <p id="thirdCategory" class="c-fields-item__title">
                {{ "ScreeningDetails.Category.valor" | translate }}
              </p>
              <p class="c-fields-item-l__text" aria-labelledby="thirdCategory">
                {{ screeningDetails?.category || "" }}
              </p>
            </div>
          </div>
        </div>
        <div class="c-fields">
          <div class="c-fields__group" aria-labelledby="keyData">
            <div class="c-fields-item-l">
              <p id="matchStrength" class="c-fields-item-l__title">
                {{ "ScreeningDetails.MatchStrength.valor" | translate }}
              </p>
              <p class="c-fields-item-l__text" aria-labelledby="thirdCategory">
                {{ screeningDetails?.matchStrength || "" }}
              </p>
            </div>
            <div class="c-fields-item-l">
              <p id="worldCheckId" class="c-fields-item-l__title">
                {{ "ScreeningDetails.WorldCheckId.valor" | translate }}
              </p>
              <p class="c-fields-item-l__text" aria-labelledby="worldCheckId">
                {{ screeningDetails?.worldCheckId || "" }}
              </p>
            </div>
            <div class="c-fields-item-l">
              <p id="screeningDate" class="c-fields-item-l__title">
                {{ "ScreeningDetails.ScreeningDate.valor" | translate }}
              </p>
              <p class="c-fields-item-l__text" aria-labelledby="screeningDate">
                {{
                screeningDetails?.screeningDate | date : "dd/MM/yyyy - HH:mm"
                }}
              </p>
            </div>
            <div class="c-fields-item-l">
              <p id="createdBy" class="c-fields-item-l__title">
                {{ "ScreeningDetails.CreatedBy.valor" | translate }}:
              </p>
              <p class="c-fields-item-l__text" aria-labelledby="createdBy">
                {{ screeningDetails?.createdBy?.name || "" }}
                {{ screeningDetails?.createdBy?.surName || "" }}
              </p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="c-fields">
          <p id="detectedRisks" class="c-fields__legend-l u-mt-1medium">
            {{ "ScoringDetails.detectedRisks" | translate }}
          </p>
          <ng-container>
            <div *ngFor="let risk of screeningDetails?.thirdPartyRisks" class="c-fields__group"
              aria-labelledby="detectedRisks">
              <div class="c-fields-item-xl">
                <p class="c-fields-item-xl__text">
                  {{ risk.type }} -
                  {{ risk.creationDate | date : "dd/MM/yyyy" }}
                </p>
                <p class="c-fields-item-xl__title" aria-labelledby="thirdName">
                  {{ risk.name }}
                </p>
              </div>
            </div>
          </ng-container>
        </div>
        <mat-divider></mat-divider>
        <div class="c-fields">
          <p id="moreInfo" class="c-fields__legend-l u-mt-1medium">
            {{ "ScreeningDetails.FurtherInformation.valor" | translate }}
          </p>
          <div class="c-fields__group" aria-labelledby="moreInfo">
            <div class="c-fields-item-xl">
              <p class="c-fields-item-xl__text">
                {{ "ScreeningDetails.Biography.valor" | translate }}
              </p>
              <p class="c-fields-item-xl__title" aria-labelledby="thirdName">
                {{ screeningDetails?.biography || "" }}
              </p>
            </div>
          </div>
          <div class="c-fields__group" aria-labelledby="moreInfo">
            <div class="c-fields-item-xl">
              <p class="c-fields-item-xl__text">
                {{ "ScreeningDetails.Identification.valor" | translate }}
              </p>
              <p class="c-fields-item-xl__title" aria-labelledby="thirdName">
                {{ screeningDetails?.identification || "" }}
              </p>
            </div>
          </div>
          <div class="c-fields__group" aria-labelledby="moreInfo">
            <div class="c-fields-item-xl">
              <p class="c-fields-item-xl__text">
                {{ "ScreeningDetails.Funding.valor" | translate }}
              </p>
              <p class="c-fields-item-xl__title" aria-labelledby="thirdName">
                {{ screeningDetails?.funding || "" }}
              </p>
            </div>
          </div>
          <div class="c-fields__group" aria-labelledby="moreInfo">
            <div class="c-fields-item-xl">
              <p class="c-fields-item-xl__text">
                {{ "ScreeningDetails.Reports.valor" | translate }}
              </p>
              <p class="c-fields-item-xl__title" aria-labelledby="thirdName">
                {{ screeningDetails?.reports || "" }}
              </p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="c-fields">
          <p id="externalSources" class="c-fields__legend-l u-mt-1medium">
            {{ "ScreeningDetails.ExternalSources.valor" | translate }}
          </p>
          <div *ngFor="let extSources of screeningDetails?.externalSources" class="c-fields__group"
            aria-labelledby="externalSources">
            <div class="c-fields-item-xl">
              <a href="{{ extSources }}" target="_blank" rel="noopener noreferrer"
                style="cursor: pointer; color: #f89f10">{{ extSources }}</a>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="c-fields">
          <p id="externalSources" class="c-fields__legend-l u-mt-1medium">
            Refinitiv World-Check Legal Notice :
          </p>
          <div class="c-fields-item-xl">
            The contents of this record are private and confidential and should
            not be disclosed to third parties unless: (i) the terms of your
            agreement with Riskallay allow you to do so; (ii) the record subject
            requests any data that you may hold on them, and such data includes
            their World-Check record; or (iii) you are under some other legal
            obligation to do so. You must consider and abide by your own
            obligations in relation to the data privacy rights of individuals
            and must notify them of your intention to search against World-Check
            and provide them with information contained in the World-Check
            privacy statement :
            <a href="https://www.refinitiv.com/en/products/world-check-kyc-screening/privacy-statement" target="_blank"
              rel="noopener noreferrer"
              style="cursor: pointer; color: #f89f10">https://www.refinitiv.com/en/products/world-check-kyc-screening/privacy-statement</a>
            You shall not rely upon the content of this report without making
            independent checks to verify the information contained therein.
            Information correlated is necessarily brief and should be read by
            you in the context of the fuller details available in the external
            sources to which links are provided. The accuracy of the information
            found in the underlying sources must be verified with the record
            subject before any action is taken and you should inform us if any
            links to the sources are broken. If this record contains negative
            allegations, it should be assumed that such allegations are denied
            by the subject. You should not draw any negative inferences about
            individuals or entities merely because they are identified in the
            database, nor because they are shown as """"Reported being linked
            to"""" others identified in the database. The nature of linking
            varies considerably. Many persons are included solely because they
            hold or have held prominent political positions or are connected to
            such individuals.
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
