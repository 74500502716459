import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ThirdPartyDTO } from '@app/models/ThirdParty';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  navShow = true;
  navShowImportThird = true;
  navShowRisklist = true;
  navShowOnboarding = true;
  navShowSignRiskList = true;

  // last uploaded third parties
  uploadedThirdParties: ThirdPartyDTO[];

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private translate: TranslateService) { }

  convertImage(image) {
    if ((image) != null) {
      image = 'data:image/*;base64, ' + image;
      image = this.sanitizer.bypassSecurityTrustResourceUrl(image);
    } else {
      image = '../../assets/images/no-product.svg';
    }

    return image;
  }

  // NAVEGACIÓN tercero
  activeThird() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.navShow = false;
    }, 0);
  }

  // Cerrar evaluación cuando se da al botón cerrar
  closeThird() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.navShow = true;
      this.navShowImportThird = true;
      this.navShowRisklist = true;
      this.navShowOnboarding = true;
    }, 0);
    this.router.navigate(['/busqueda']);
  }

  // NAVEGACIÓN importar terceros
  activeImportThird() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.navShowImportThird = false;
    }, 0);
  }

  // Cerrar evaluación cuando se da al botón cerrar
  closeImportThird() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.navShowImportThird = true;
      this.navShow = true;
      this.navShowRisklist = true;
      this.navShowOnboarding = true;
    }, 0);
    this.router.navigate(['/busqueda']);
  }

  // Cerrar evaluación cuando se da al botón cerrar
  closeRisklist() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('o-risklist');
  }

  // NAVEGACIÓN onboarding
  activeOnboarding() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.navShowOnboarding = false;
    }, 0);
    this.router.navigate(['/onboarding-introduccion']);
  }

  // Cerrar evaluación cuando se da al botón cerrar
  closeOnboarding() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.navShowOnboarding = true;
      this.navShowImportThird = true;
      this.navShow = true;
      this.navShowRisklist = true;
    }, 0);
  }

  // CERRAR todas los nav secundarios (excepto general)
  closeAllNav() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.navShow = true;
      this.navShowOnboarding = true;
      this.navShowImportThird = true;
      this.navShowRisklist = true;
    }, 0);
  }

  chageLanguage(language: string): string {
    if (language === '2') {
      this.translate.use('es-ES');
      return 'es';
    } else if (language === '3') {
      this.translate.use('en-US');
      return 'en';
    } else if (language === '4') {
      this.translate.use('pt-PT');
      return 'pt-PT';
    } else if (language === '1') {
      this.translate.use('fr-FR');
      return 'fr-FR';
    } else {
      this.translate.use('es-ES');
      return 'es';
    }
  }

  setDisableSignRiskList() {
    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.navShowSignRiskList = false;
    }, 0);
  }
}
