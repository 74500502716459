import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThirdPartyDTO } from '@app/models/ThirdParty';
import { AwsS3Service } from '@app/services/awsS3.service';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-dialog-upload-third-party-file',
  templateUrl: './dialog-upload-third-party-file.component.html',
  styleUrls: ['./dialog-upload-third-party-file.component.scss'],
})
export class DialogUploadThirdPartyFileComponent implements OnInit {
  fileSelectButtonLbl: string;
  fileUploadingErrorMsg: string;
  saveSuccessfulMsg: string;

  file: File = null;

  form = this.fb.group({
    fileName: '',
  });

  constructor(
    public dialogRef: MatDialogRef<DialogUploadThirdPartyFileComponent>,
    private fb: FormBuilder,
    private toast: Toaster,
    private translate: TranslateService,
    private thirdPartiesService: ThirdPartiesService,
    private awsS3Service: AwsS3Service,
    @Inject(MAT_DIALOG_DATA) public data: ThirdPartyDTO
  ) {}

  ngOnInit(): void {
    this.translate
      .get('uploadFileToThirdProfile.uploadError')
      .subscribe((translation) => {
        this.fileUploadingErrorMsg = translation;
      });

    this.translate
      .get('uploadFileToThirdProfile.buttonLabel')
      .subscribe((translation) => {
        this.fileSelectButtonLbl = translation;
      });

    this.translate
      .get('uploadFileToThirdProfile.successfullySaved')
      .subscribe((translation) => {
        this.saveSuccessfulMsg = translation;
      });
  }

  fileSelected(event: any): void {
    this.file = event?.file || null;
  }

  submitFile(): void {
    try {
      this.thirdPartiesService
        .getAttachFileUri(this.data.Id, this.file.name)
        .subscribe((fileUri) => {
          if (!fileUri) {
            this.closeAndDisplayError();
          }
          this.awsS3Service
            .uploadFileToS3(fileUri, this.file)
            .subscribe((_) => {
              this.toast.open({
                text: this.saveSuccessfulMsg,
                duration: 3000,
              });
              this.dialogRef.close(true);
            });
        });
    } catch {
      this.closeAndDisplayError();
    }
  }

  private closeAndDisplayError(): void {
    this.toast.open({
      text: this.fileUploadingErrorMsg,
      duration: 6000,
    });
    this.dialogRef.close(false);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
