import { RelatedPersonDto } from 'src/proxy/model/relatedPersonDto';
import { RelatedPerson } from '../../models/GesintelScreening/RelatedPerson';

export function mapRelatedPerson(dto: RelatedPersonDto): RelatedPerson {
  if (!dto) return null;

  return <RelatedPerson>{
    fullName: dto.FullName,
    rut: dto.Rut,
    relationType: dto.RelationType,
    category: dto.Category,
  };
}
