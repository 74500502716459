import { Component, Input, OnChanges } from '@angular/core';
import { QueryResult } from '@app/models/common/queryResult';
import { EquifaxWSAddressData } from '@app/modules/screening-hub/models/EquifaxWS/EquifaxWSAddressData';

@Component({
  selector: 'equifax-ws-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
})
export class EquifaxWSAddressListComponent implements OnChanges {
  @Input() addressList: EquifaxWSAddressData[];

  displayedColumns: string[] = [
    'address',
    'telephone',
    'date'
  ];

  queryResult: QueryResult<EquifaxWSAddressData> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() { }

  ngOnChanges(): void {
    this.getAddresses();
  }

  getAddresses() {
    if (this.addressList) {
      this.queryResult.items = this.addressList
        .slice(
          (this.query.Page - 1) * this.query.PageSize,
          this.query.Page * this.query.PageSize
        );
      this.queryResult.totalItems = this.addressList.length;
    }
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getAddresses();
  }
}
