import { Component, OnInit } from '@angular/core';
import { ThirdPartyDTO } from '@app/models/ThirdParty';
import { GlobalService } from '@app/services/global.service';
import { Table3PPConfiguration } from '@app/utilities/utilities';

@Component({
  selector: 'app-third-import-massive-end',
  templateUrl: './third-import-massive-end.component.html',
  styleUrls: ['./third-import-massive-end.component.scss']
})
export class ThirdImportMassiveEndComponent implements OnInit {

    Imported3PPConfig: Table3PPConfiguration
    uploadedThirdParties: ThirdPartyDTO[]

    constructor(private global: GlobalService) { }

    ngOnInit(): void {
        this.Imported3PPConfig = {
            columnas: [ 'id','name', 'headquarters', 'sector', 'responsible','action'],
            acciones: ['detalle'],
            tipo :null,
            filterByTypes: true,
            tableTarget: 'imported',
            importedItems : this.global.uploadedThirdParties,
        }
    }
}
