import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignUpdateDTO } from '@app/models/Campaign';
import { CampaignService } from '@app/services/campaign-service';
import { groupByKey } from '@app/utilities/utilities';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Toaster } from 'ngx-toast-notifications';
import { parsearErroresAPI } from '@app/utilities/utilities';
import { ThemePalette } from '@angular/material/core';
import { RemoteService } from '@app/services/remote.service';
import { canAccess } from '@app/security/riskallay.roles';
import { Role } from '@app/models/Role';
import { AuthenticationService } from '@app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-campaign',
  templateUrl: './detail-campaign.component.html',
  styleUrls: ['./detail-campaign.component.scss']
})
export class DetailCampaignComponent implements OnInit {

  form: FormGroup;

  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDateEnd: Date;
  public maxDateEnd: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public color: ThemePalette = 'primary';
  public disableMinute = false;
  public hideTime = false;
  public disableEndDate = false;
  enableAutomatizationDeny: boolean = false;
  enableAutomatizationApprove: boolean = false;


  thirdLabel = true;
  changes: boolean = false;
  model: CampaignUpdateDTO;
  errores: string[] = [];
  id: number;
  canEdit = false;
  canAddTP = false;

  total = 0;
  aprobados = 0;
  denegados = 0;
  enProceso = 0;

  private assessmentCreatedMessage: string;
  private assessmentUpdatedMessage: string;
  private assessmentDeletedMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private campaignService: CampaignService,
    private translate: TranslateService,
    private toaster: Toaster,
    private remoteService: RemoteService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    const userRoles = this.authenticationService.getRoles();
    this.canEdit = canAccess([Role.Manager, Role.Owner], userRoles);

    this.form = new FormGroup({
      StartDate: new FormControl({ value: '', disabled: true }, Validators.required),
      EndDate: new FormControl({ value: '', disabled: true }, Validators.required)
    });

    this.id = this.activatedRoute.snapshot.params.id;
    this.getData(this.id);

    this.translate.get('assesmentDetail.assessmentCreated')
      .subscribe(transtlation => {
        this.assessmentCreatedMessage = transtlation;
      });
    this.translate.get('assesmentDetail.assessmentUpdated')
      .subscribe(transtlation => {
        this.assessmentUpdatedMessage = transtlation;
      });
    this.translate.get('assesmentDetail.assessmentDeleted')
      .subscribe(transtlation => {
        this.assessmentDeletedMessage = transtlation;
      });
  }

  public getData(id) {
    this.campaignService.getCampaignById(id).then(response => {
      this.model = <CampaignUpdateDTO>response;
      this.model.StartDate = new Date(this.model.StartDate);
      this.model.EndDate = new Date(this.model.EndDate);
      this.form.setValue({
        StartDate: this.model.StartDate,
        EndDate: this.model.EndDate
      })

      this.enableAutomatizationDeny = !!response.DiscardThreshold;
      this.enableAutomatizationApprove = !!response.ApproveThreshold;

      const now = new Date();

      if (this.model.EndDate > now) {
        if (this.canEdit) {
          this.form.get('EndDate').enable();
        }
        this.disableEndDate = (false || !this.canEdit);
        this.minDateEnd = new Date();
        this.minDateEnd.setDate(this.model.EndDate.getDate() + 1);
        this.minDateEnd.setTime(this.model.EndDate.getTime() + 1);
        this.changes = true;
        this.canAddTP = this.canEdit;
      }

      this.loadStatistics();

    });
  }

  saveChanges() {
    if (new Date(this.form.get('EndDate').value) > this.model.EndDate) {
      this.model.EndDate = <Date>this.form.get('EndDate').value;
      this.campaignService.updateCampaign(this.model.Id, this.model).subscribe(response => {
        this.toaster.open({ text: this.assessmentUpdatedMessage, duration: 6000, type: 'info' });
        (error) => this.errores = parsearErroresAPI(response);
      });
    }
  }

  public download(idfile: string) {
    this.remoteService.downloadMyFile(idfile, this.model.FileName);
  }

  public loadStatistics() {

    this.total = this.model.ThirdParties.length;

    var groupedByData = groupByKey(this.model.ThirdParties, 'Status')

    if (groupedByData['Denegado'] != null)
      this.denegados = groupedByData['Denegado'].length;

    if (groupedByData['En proceso'] != null)
      this.enProceso = groupedByData['En proceso'].length;

    if (groupedByData['Aprobado'] != null)
      this.aprobados = groupedByData['Aprobado'].length;

  }

}
