import { RegionDto } from 'src/proxy/model/regionDto';
import { Region } from '../../models/GesintelScreening/Region';

export function mapRegion(dto: RegionDto): Region {
  if (!dto) return null;

  return <Region>{
    type: dto.Type,
    country: dto.Country,
    sanctions: dto.Sanctions,
  };
}
