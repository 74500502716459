import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MemberCheckLink } from '../../models/member-check/member-check-link';

@Component({
  selector: 'member-check-confirm-refresh-screening',
  templateUrl: './member-check-confirm-refresh-screening.component.html',
  styleUrls: ['./member-check-confirm-refresh-screening.component.scss'],
})
export class MemberCheckConfirmRefreshScreeningComponent {
  constructor(
    public dialogRef: MatDialogRef<MemberCheckConfirmRefreshScreeningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MemberCheckLink
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
