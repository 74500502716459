<div class="o-wrapper">
  <main id="content">
    <div class="c-head-section c-head-section--tab bg-gray-light">
      <!--parte superior-->
      <div class="c-head-section--tab__top">
        <div class="c-head-section__left">
          <h2 class="o-title">{{'Config.title.valor' | translate}}</h2>
        </div>
      </div>
      <!--parte inferior-->
      <div class="c-head-section--tab__bottom">
        <!--tabs-->
        <mat-tab-group class="c-tabs">
          <!--módulo third party-->
          <mat-tab label="{{'Config.3pp.valor' | translate}}">
            <div class="o-scroll">
              <p class="o-text u-spaceLeft u-mt-1 u-text-indent">{{'Config.info.valor' | translate}}</p>
              <mat-accordion class="c-accordion u-mt-1" multi>
                <ng-container>
                  <!--naturaleza actividad-->
                  <mat-expansion-panel class="c-accordion__panel">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="c-accordion__title">
                        {{'Config.tab.natures.valor' | translate}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description class="column">
                      <div class="c-text-button">
                        <div class="c-text-button__left">
                          <div class="c-text-button__ico">
                            <span aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path
                                  d="M10 0A10 10 0 1 0 20 10 10 10 0 0 0 10 0m0 18.333A8.333 8.333 0 1 1 18.333 10 8.333 8.333 0 0 1 10 18.333" />
                                <path
                                  d="M11.667,10h-.833a.833.833,0,0,0,0,1.667h.833v5a.833.833,0,1,0,1.667,0v-5A1.667,1.667,0,0,0,11.667,10Z"
                                  transform="translate(-1.667-1.666)" />
                                <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 4.166)" />
                              </svg>
                            </span>
                          </div>
                          <p class="o-text">{{'Config.tab.natures.intro.valor' | translate}}
                          </p>
                        </div>
                      </div>
                      <div class="c-table u-mt-1medium">
                        <app-nature-table></app-nature-table>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel>
                  <!--tipo de actividad-->
                  <mat-expansion-panel class="c-accordion__panel">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="c-accordion__title">
                        {{'Config.tab.activities.valor' | translate}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description class="column">
                      <div class="c-text-button">
                        <div class="c-text-button__left">
                          <div class="c-text-button__ico">
                            <span aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path
                                  d="M10 0A10 10 0 1 0 20 10 10 10 0 0 0 10 0m0 18.333A8.333 8.333 0 1 1 18.333 10 8.333 8.333 0 0 1 10 18.333" />
                                <path
                                  d="M11.667,10h-.833a.833.833,0,0,0,0,1.667h.833v5a.833.833,0,1,0,1.667,0v-5A1.667,1.667,0,0,0,11.667,10Z"
                                  transform="translate(-1.667-1.666)" />
                                <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 4.166)" />
                              </svg>
                            </span>
                          </div>
                          <p class="o-text">{{'Config.tab.activities.intro.valor' |
                            translate}}</p>
                        </div>
                      </div>
                      <div class="c-table u-mt-1medium">
                        <app-activity-table></app-activity-table>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel>
                  <!--clasificación de terceros-->
                  <mat-expansion-panel class="c-accordion__panel">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="c-accordion__title">
                        {{'Config.tab.3pptype.valor' | translate}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description class="column">
                      <div class="c-text-button">
                        <div class="c-text-button__left">
                          <div class="c-text-button__ico">
                            <span class="c-box-edit__left-ico" aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path
                                  d="M10 0A10 10 0 1 0 20 10 10 10 0 0 0 10 0m0 18.333A8.333 8.333 0 1 1 18.333 10 8.333 8.333 0 0 1 10 18.333" />
                                <path
                                  d="M11.667,10h-.833a.833.833,0,0,0,0,1.667h.833v5a.833.833,0,1,0,1.667,0v-5A1.667,1.667,0,0,0,11.667,10Z"
                                  transform="translate(-1.667-1.666)" />
                                <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 4.166)" />
                              </svg>
                            </span>
                          </div>
                          <p class="o-text">{{'Config.tab.3pptype.intro.valor' | translate}}
                          </p>
                        </div>
                      </div>
                      <div class="c-table u-mt-1medium">
                        <app-thirdtype-table></app-thirdtype-table>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel>
                  <!--sectores-->
                  <mat-expansion-panel class="c-accordion__panel">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="c-accordion__title">
                        {{'Config.tab.sector.valor' | translate}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description class="column">
                      <div class="c-text-button">
                        <div class="c-text-button__left">
                          <div class="c-text-button__ico">
                            <span class="c-box-edit__left-ico" aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path
                                  d="M10 0A10 10 0 1 0 20 10 10 10 0 0 0 10 0m0 18.333A8.333 8.333 0 1 1 18.333 10 8.333 8.333 0 0 1 10 18.333" />
                                <path
                                  d="M11.667,10h-.833a.833.833,0,0,0,0,1.667h.833v5a.833.833,0,1,0,1.667,0v-5A1.667,1.667,0,0,0,11.667,10Z"
                                  transform="translate(-1.667-1.666)" />
                                <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 4.166)" />
                              </svg>
                            </span>
                          </div>
                          <p class="o-text">{{'Config.tab.sector.intro.valor' | translate}}
                          </p>
                        </div>
                      </div>
                      <div class="c-table u-mt-1medium">
                        <app-sector-table></app-sector-table>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel>
                  <!--riesgos-->
                  <mat-expansion-panel class="c-accordion__panel">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="c-accordion__title">
                        {{'Config.tab.risks.valor' | translate}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description class="column">
                      <div class="c-text-button">
                        <div class="c-text-button__left">
                          <div class="c-text-button__ico">
                            <span aria-hidden="true">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path
                                  d="M10 0A10 10 0 1 0 20 10 10 10 0 0 0 10 0m0 18.333A8.333 8.333 0 1 1 18.333 10 8.333 8.333 0 0 1 10 18.333" />
                                <path
                                  d="M11.667,10h-.833a.833.833,0,0,0,0,1.667h.833v5a.833.833,0,1,0,1.667,0v-5A1.667,1.667,0,0,0,11.667,10Z"
                                  transform="translate(-1.667-1.666)" />
                                <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 4.166)" />
                              </svg>
                            </span>
                          </div>
                          <p class="o-text">{{'Config.tab.risks.intro.valor' | translate}}</p>
                        </div>
                      </div>
                      <div class="c-table u-mt-1medium">
                        <app-risk-table></app-risk-table>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel>
                  <!--custom fields-->
                  <mat-expansion-panel #customFields class="c-accordion__panel">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="c-accordion__title">
                        {{'Config.tab.customFields.title' | translate}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description class="column">
                      <div class="c-table u-mt-1medium">
                        <app-custom-fields-table></app-custom-fields-table>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>
          </mat-tab>
          <!--cuentas y permiso-->
          <mat-tab label="{{'Config.account.valor' | translate }}">
            <div class="o-scroll">
              <p *appAuthRole="['Owner']" class="o-text u-spaceLeft u-mt-1 u-text-indent">{{'ConfigAccount.text1.valor'
                | translate }}
                <strong>
                  <a href="mailto:backoffice@riskallay.com?Subject=Extra%20users">{{'ConfigAccount.text2.valor' |
                    translate }}</a>
                </strong>.
              </p>
              <mat-accordion class="c-accordion u-mt-1" multi>
                <ng-container>
                  <!-- Usuarios -->
                  <mat-expansion-panel class="c-accordion__panel">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="c-accordion__title">
                        {{'ConfigAccount.tab.users.valor' | translate }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description class="column">
                      <div class="c-table u-mt-1medium">
                        <app-users-table></app-users-table>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel>
                  <!-- Seguridad -->
                  <mat-expansion-panel *appAuthRole="['Owner']" class="c-accordion__panel">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="c-accordion__title">
                        {{'ConfigAccount.tab.security.valor' | translate }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description class="column">
                      <form [formGroup]="form" id="form-security" autocomplete="off" class="c-form__form">
                        <div class="row">
                          <!-- TwoFactor -->
                          <div class="col-lg-6">
                            <div class="c-switches c-switches--cols">
                              <p id="twoFactorEnabled" class="c-select__label c-select__label--mb">
                                {{'ConfigAccount.twofactor.header.valor' | translate }}
                              </p>
                              <p class="c-select__label--mb">
                                {{'ConfigAccount.twofactor.text.valor' | translate }}
                              </p>
                              <div class="c-switches--cols__group" aria-labelledby="attachedDocs">
                                <div class="c-switches__block">
                                  <div class="c-switches__items">
                                    <mat-label class="mat-slide-toggle-content"
                                      style="vertical-align: top;
                                                                        line-height: 24px;
                                                                        margin-right:0.5rem;
                                                                        margin-bottom:0.5rem;">{{'ConfigAccount.twofactor.off' | translate }}</mat-label>
                                    <mat-slide-toggle formControlName="TwoFactorEnabled" class="c-switch">
                                      {{'ConfigAccount.twofactor.on' | translate }}
                                    </mat-slide-toggle>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 d-flex justify-content-end align-center">
                            <button type="button" (click)="saveData()" class="c-button">
                              {{'ConfigAccount.tab.security.button.valor' | translate }}</button>
                          </div>
                        </div>
                      </form>
                    </mat-panel-description>
                  </mat-expansion-panel>
                  <!--scoring tools-->
                  <ng-container *appAuthRole="['AMLUpdate', 'Refinitiv']">
                    <mat-expansion-panel *appAuthRole="['Owner']" class="c-accordion__panel">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="c-accordion__title">
                          Scoring tools
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-panel-description class="column">
                        <div class="c-table u-mt-1medium">
                          <app-scoring-tools-access></app-scoring-tools-access>
                        </div>
                      </mat-panel-description>
                    </mat-expansion-panel>
                  </ng-container>
                  <mat-expansion-panel *appAuthRole="['Owner']" class="c-accordion__panel">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="c-accordion__title">
                        Riskallay API
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description class="column">
                      <div class="u-mt-1medium">
                        <app-api-access-data></app-api-access-data>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </main>
</div>
