import { ThirdPartyStatusHistoryRecord } from "@app/models/ThirdPartyStatusHistoryRecord";
import { ThirdPartyStatusHistoryRecordDto } from "src/proxy/model/thirdPartyStatusHistoryRecordDto";

export function mapThirdPartyStatusRecordFromDto(dto: ThirdPartyStatusHistoryRecordDto): ThirdPartyStatusHistoryRecord {
    return <ThirdPartyStatusHistoryRecord> {
        clientId: dto.ClientId,
        status: dto.Status,
        thirdPartyId: dto.ThirdPartyId,
        thirdPartyTypeId: dto.ThirdPartyTypeId,
        timeStamp: new Date(dto.TimeStamp)
    }
}