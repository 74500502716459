import { Injectable } from "@angular/core";
import { ScreeningDetails } from "@app/models/scoring-tools/screeningDetails";
import { Screening } from "@app/models/scoring-tools/screening";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { mapIndividualFilterToDto, mapOrganizationFilterToDto, mapScoring, mapScoringCandidate, mapScoringCandidateDetails, mapScreening, mapScreeningDetails } from "./mappings/scoring-tools.mappings";
import { ScoringCandidate } from "@app/models/scoring-tools/scoringCandidate";
import { Scoring } from "@app/models/scoring-tools/scoring";
import { ScoringCandidateDetails } from "@app/models/scoring-tools/scoringCandidateDetails";
import { RefinitivScoringProxyClient } from "src/proxy/apiRefinitiv/refinitivScoring.proxy";
import { IndividualFilter } from "@app/models/scoring-tools/individual-filter";
import { OrganizationFilter } from "@app/models/scoring-tools/organization-filter";
import { ResolutionLink } from "@app/models/scoring-tools/resolutionLink";
import { ResolutionRecord } from "@app/models/scoring-tools/resolutionRecord";
import { ResolutionProxyClient } from "src/proxy/apiResolution/resolution.proxy";
import { mapResolutionLink, mapResolutionRecord } from "./mappings/resolution.mappings";

@Injectable()
export class ScoringToolsService {

    constructor(
        private refinitivClientService: RefinitivScoringProxyClient,
        private resolutionClientService: ResolutionProxyClient) { }

    getScreeningsForThirdParty(scoringId: number, worldCheckId: string): Observable<Screening[]> {
        return this.refinitivClientService.refinitivScoringGetRefinitivScreeningsRefinitiveLinkIdGet(scoringId).pipe(
            map(res => res.map(r => mapScreening(r, worldCheckId))));
    }

    getScoringForThirdParty(thirdPartyId: number): Observable<Scoring[]> {
        return this.refinitivClientService.refinitivScoringGetThirdPartyLinkRefinitivGet(thirdPartyId).pipe(
            map(result => result.map(r => mapScoring(r)))
        );
    }

    unlinkScoring(linkId: number): Observable<boolean> {
        return this.refinitivClientService.refinitivScoringUnLinkThirdPartyWithRefinitivLinkIdPost(linkId).pipe(
            map(r => { return r })
        )
    }

    refreshScreening(worldCheckId: string, thirdPartyId?: number): Observable<string> {
        return this.refinitivClientService.refinitivScoringGetNewRefinitivScreeningWorldCheckIdGet(worldCheckId, thirdPartyId).pipe(
            map(res => { return res; })
        );
    }

    createIndividualCase(filter: IndividualFilter): Observable<string> {
        return this.refinitivClientService.refinitivScoringCreateIndividualCasePost(mapIndividualFilterToDto(filter));
    }

    createOrganizationCase(filter: OrganizationFilter): Observable<string> {
        return this.refinitivClientService.refinitivScoringCreateOrganizationCasePost(mapOrganizationFilterToDto(filter));
    }

    getCaseResults(caseId: string): Observable<ScoringCandidate[]> {
        return this.refinitivClientService.refinitivScoringGetCaseDataCaseIdGet(caseId).pipe(
            map(res => res.map(r => mapScoringCandidate(r)))
        );
    }

    getScoringCandidateDetails(referenceId: string): Observable<ScoringCandidateDetails> {
        return this.refinitivClientService.refinitivScoringGetProfileDetailsRefinitivReferenceIdGet(referenceId).pipe(
            map(res => mapScoringCandidateDetails(res)
            ));
    }

    getScreeningDetails(screeningId: number): Observable<ScreeningDetails> {
        return this.refinitivClientService.refinitivScoringGetRefinitivScreeningDetailsScreenIdGet(screeningId).pipe(
            map(res => mapScreeningDetails(res))
        );
    }

    saveScreening(referenceId: string, matchStrength: string, thirdPartyId?: number): Observable<boolean> {
        return this.refinitivClientService.refinitivScoringLinkThirdPartyWithRefinitivPostForm(referenceId, matchStrength, thirdPartyId).pipe(map(res => { return res }));
    }

    getResolutionLinks(thirdPartyId?: number): Observable<ResolutionLink[]> {
        return this.resolutionClientService.resolutionLinksGet(thirdPartyId).pipe(map(res => res.map(r => mapResolutionLink(r))));
    }

    getResolutionRecords(resolutionLinkId: number): Observable<ResolutionRecord[]> {
        return this.resolutionClientService.resolutionRecordsLinkIdGet(resolutionLinkId).pipe(map(res => res.map(r => mapResolutionRecord(r))))
    }

    createResolution(name: string, software: number, data: string, totalResults: number, reason: number, explanation: string, searchType: number, thirdPartyId?: number): Observable<boolean> {
        return this.resolutionClientService.resolutionCreateLinkPostForm(name, software, data, totalResults, reason, explanation, searchType, thirdPartyId);
    }

    addCommentToResolution(recordId: number, comment: string): Observable<boolean> {
        return this.resolutionClientService.resolutionAddCommentRecordIdPostForm(recordId, comment)
    }

    deleteResolutionLink(resolutionLinkId: number): Observable<boolean> {
        return this.resolutionClientService.resolutionRemoveLinkLinkIdPost(resolutionLinkId)
    }
}
