import { Component, Input } from "@angular/core";
import { EquifaxWSPledgeCreditData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSPledgeCreditData";

@Component({
  selector: 'equifax-ws-pledge-credit',
  templateUrl: './pledge-credit.component.html',
  styleUrls: ['./pledge-credit.component.scss'],
})
export class EquifaxWSPledgeCreditComponent {
  @Input() credit: EquifaxWSPledgeCreditData;
  @Input() index: number;
  constructor() { }

}
