<!--modal añadir nature-->
<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <div [formGroup]="form">
        <header class="c-dialog__header">

            <h1 *ngIf="data===null" id="titleDialog" class="c-dialog__title">{{'TablaNaturesDialog.titleAdd.valor' | translate}}</h1>
            <h1 *ngIf="data!==null" id="titleDialog" class="c-dialog__title">{{'TablaNaturesDialog.titleUpdate.valor' | translate}}</h1>

            <button mat-button (click)="onNoClick()" class="c-dialog__close" aria-label="Cerrar ventana modal">
          <span aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                  <path d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243" transform="translate(-309.86-406.47)"/>
              </svg>
          </span>
        </button>
        </header>
        <div class="c-dialog__content u-pb-1">

            <div *ngIf="data===null">
                <h2 class="c-dialog__subtitle">{{'TablaNaturesDialog.title2Add.valor' | translate}} </h2>
                <p class="o-text">{{'TablaNaturesDialog.introAdd.valor' | translate}}</p>
            </div>
            <div *ngIf="data!==null">
                <h2 class="c-dialog__subtitle">{{'TablaNaturesDialog.title2Update.valor' | translate}}</h2>
                <p class="o-text">{{'TablaNaturesDialog.introUpdate.valor' | translate}}</p>
            </div>
            <div class="c-form">
                <div class="row">
                    <div class="col-lg-3">
                        <p id="sectorDescription" class="c-fields__legend">{{'TablaNaturesDialog.description.valor' | translate}} (ES)</p>
                        <mat-form-field floatLabel="never">
                            <input type="text" matInput formControlName="Description" name="sector-description" value="" id="sector-description" class="c-form__input" placeholder="{{'TablaNaturesDialog.description.valor' | translate}}" aria-labelledby="sectorDescription" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <p id="sectorDescriptionEN" class="c-fields__legend">{{'TablaNaturesDialog.description.valor' | translate}} (EN)</p>
                        <mat-form-field floatLabel="never">
                            <input type="text" matInput formControlName="DescriptionEN" name="sector-description-en" value="" id="sector-description-en" class="c-form__input" placeholder="{{'TablaNaturesDialog.description.valor' | translate}}" aria-labelledby="sectorDescriptionEN" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <p id="sectorDescriptionPT" class="c-fields__legend">{{'TablaNaturesDialog.description.valor' | translate}} (PT)</p>
                        <mat-form-field floatLabel="never">
                            <input type="text" matInput formControlName="DescriptionPT" name="sector-description-pt" value="" id="sector-description-pt" class="c-form__input" placeholder="{{'TablaNaturesDialog.description.valor' | translate}}" aria-labelledby="sectorDescriptionPT" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                      <p id="sectorDescriptionFR" class="c-fields__legend">{{'TablaNaturesDialog.description.valor' | translate}} (FR)</p>
                      <mat-form-field floatLabel="never">
                          <input type="text" matInput formControlName="DescriptionFR" name="sector-description-fr" value="" id="sector-description-fr" class="c-form__input" placeholder="{{'TablaNaturesDialog.description.valor' | translate}}" aria-labelledby="sectorDescriptionFR" />
                      </mat-form-field>
                  </div>
                </div>
            </div>
        </div>
        <footer class="c-dialog__footer flex-end">
            <button *ngIf="data===null" [disabled]="!form.valid" (click)="addNature()" class="c-button c-button--green u-align-right" mat-button>{{'TablaNaturesDialog.add.valor' | translate}}</button>
            <button *ngIf="data!==null" [disabled]="!form.valid" (click)="updateNature()" class="c-button c-button--green u-align-right" mat-button>{{'TablaNaturesDialog.update.valor' | translate}}</button>
        </footer>
    </div>
</div>
