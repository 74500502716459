import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignStatus } from '@app/models/CampaignStatus';
import { InherentRiskEnum, InherentRiskEnumValues, mapInherentRiskToTranslationKey } from '@app/models/InherentRiskEnum';
import { ThirdPartyDTO } from '@app/models/ThirdParty';
import { ThirdPartyStatus, ThirdPartyStatusValues, mapTPStatusToTranslationKey } from '@app/models/ThirdPartyStatus';
import { RemoteService } from '@app/services/remote.service';
import { TranslateService } from '@ngx-translate/core';
import { CountryDto } from 'src/proxy/model/countryDto';
import { SectorDto } from 'src/proxy/model/sectorDto';

@Component({
  selector: 'app-create-campaign-third-parties',
  templateUrl: './create-campaign-third-parties.component.html',
  styleUrls: ['./create-campaign-third-parties.component.scss']
})
export class CreateCampaignThirdPartiesComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("input", { static: true }) input: ElementRef;

  @Input() campaign: number; // TODO since receiving thirdParties, this is probably unnecessary
  @Input() thirdParties: ThirdPartyDTO[];
  @Input() selectedRows: ThirdPartyDTO[];
  @Output() onSelectRow: EventEmitter<ThirdPartyDTO[]> = new EventEmitter<ThirdPartyDTO[]>();

  displayColumns: string[] = ['select', 'id', 'name', 'type', 'headquarters', 'sector', 'status', 'inherentRisk', 'responsible'];

  dataSource: any;
  selection = new SelectionModel<ThirdPartyDTO>(true, []);
  isLoading: boolean;

  selectedType: string = "All";

  failedToSendEmail: string;
  emailSent: string;
  campaignStatus = CampaignStatus;
  inherentRiskFilterList: number[] = [];
  statusFilterList: number[] = [];

  searchWord: string;
  statusList: ThirdPartyStatus[] = ThirdPartyStatusValues;
  inherentRiskList: InherentRiskEnum[] = InherentRiskEnumValues;

  constructor(private remote: RemoteService, private translate: TranslateService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.translate.get('CampaignTable.emailSent').subscribe(translation => {
      this.emailSent = translation;
    });

    this.translate.get('CampaignTable.failedToSendEmail').subscribe(translation => {
      this.failedToSendEmail = translation;
    });
  }

  ngAfterViewInit(): void {
    this.get3PPall();
    this.isLoading = false;
    this.cd.detectChanges();
  }

  get3PPall() {
    this.dataSource = new MatTableDataSource(this.thirdParties.filter(tp => this.filterFunction(tp)));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.selection = new SelectionModel<ThirdPartyDTO>(true, this.selectedRows);
    if (this.campaign > 0) {
      // Editando una evaluación
      this.remote.getRequest("Campaign/GetByIdAndUser/" + this.campaign).then(r2 => {
        let v = [];
        let s = new Set<number>();
        for (let index = 0; index < r2.ThirdParties.length; index++) {
          s.add(r2.ThirdParties[index].Id)
        }

        for (let index = 0; index < this.thirdParties.length; index++) {
          if (s.has(this.thirdParties[index].Id)) {
            v.push(this.thirdParties[index])
          }
        }

        this.selection = new SelectionModel<ThirdPartyDTO>(true, v);
        this.procesaSeleccion();
      })
    }
  }

  isAllSelected() {
    if (!this.dataSource) return false;

    return this.dataSource.data.every((tp: ThirdPartyDTO) => this.selection.selected.includes(tp));
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach((tp: ThirdPartyDTO) => {
        if (this.selection.selected.includes(tp)) {
          this.selection.deselect(tp);
        }
      });
    } else {
      this.dataSource.data.forEach((row: ThirdPartyDTO) => this.selection.select(row));
    }
  }

  checkboxLabel(row?: ThirdPartyDTO): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Id + 1}`;
  }

  filterFunction(tp: ThirdPartyDTO): boolean {
    return (
      this.searchWord == null
      || this.searchWord == undefined
      || this.searchWord.length == 0
      || String(tp.Id).toLowerCase().includes(this.searchWord.toLowerCase())
      || String(tp.ThirdPartyConfig.ThirdPartyType?.Code).toLowerCase().includes(this.searchWord.toLowerCase())
      || String(tp.CompanyName).toLowerCase().includes(this.searchWord.toLowerCase())
      || String(tp.Country?.Description ?? '').toLowerCase().includes(this.searchWord.toLowerCase())
      || String(tp.Country?.Code ?? '').toLowerCase().includes(this.searchWord.toLowerCase())
      || String(this.translatedMasterDataObject(tp.ThirdPartyConfig?.Sector)).toLowerCase().includes(this.searchWord.toLowerCase())
      || String(tp.UserOwner.Name).toLowerCase().includes(this.searchWord.toLowerCase())
      || String(tp.UserOwner.SurName).toLowerCase().includes(this.searchWord.toLowerCase())
      || String(tp.UserOwner.Position).toLowerCase().includes(this.searchWord.toLowerCase())
    )
      && (this.inherentRiskFilterList.length == 0 || this.inherentRiskFilterList.indexOf(tp.ThirdPartyConfig.InherentRisk) > -1)
      && (this.statusFilterList.length == 0 || this.statusFilterList.indexOf(tp.ThirdPartyConfig.ThirdPartyStatus) > -1)

  }

  updateSearchWord(value: string): void {
    this.searchWord = value;
    this.paginator.pageIndex = 0;
    this.get3PPall();
  }

  procesaSeleccion() {
    this.onSelectRow.emit(this.selection.selected);
  }

  getInherentRiskText(inherentRisk: InherentRiskEnum): string {
    return mapInherentRiskToTranslationKey(inherentRisk);
  }

  getStatusName(status: ThirdPartyStatus): string {
    return mapTPStatusToTranslationKey(status);
  }

  statusChange(event) {
    this.statusFilterList = event.value;
    this.paginator.pageIndex = 0;
    this.get3PPall();
  }

  inherentRiskChange(event) {
    this.inherentRiskFilterList = event.value;
    this.paginator.pageIndex = 0;
    this.get3PPall();
  }

  private translatedMasterDataObject(masterDataObject: SectorDto | CountryDto): string {
    if (!masterDataObject) return '-';

    switch (this.translate.currentLang) {
      case 'en-US':
        return masterDataObject.DescriptionEN;
      case 'es-ES':
        return masterDataObject.Description;
      case 'pt-PT':
        return masterDataObject.DescriptionPT;
      case 'fr-FR':
        return masterDataObject.DescriptionFR;
      default:
        return masterDataObject.DescriptionEN;
    }
  }
}
