<div class="c-responsible">
    <div class="c-responsible__image" aria-hidden="true">
        <ng-container *ngIf="user?.profilePhotoFileUri; else circledInitial">
            <img [src]="getResponsibleAvatar()"
            alt="" loading="lazy" />
        </ng-container>
        <ng-template #circledInitial>
            <div class="circledChar">{{getCircleLetter(user)}}</div>
        </ng-template>
    </div>
    <div class="c-responsible__info">
        <span class="c-responsible__name">
            {{user?.name}} {{user?.surName}}
        </span>
        <div class="c-responsible__position">{{getPositionTranslationKey(user?.position) | translate}}</div>
    </div>
</div>
