import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from "@angular/router";
import { formatearFecha } from '@app/utilities/utilities'

@Component({
  selector: 'app-dialog-delete-campaign',
  templateUrl: './dialog-delete-campaign.component.html'
})
export class DialogDeleteCampaignComponent implements OnInit {

  public dateTimeStart: string;
  
  constructor(public dialogRef: MatDialogRef<DialogDeleteCampaignComponent>,
    @Inject(MAT_DIALOG_DATA)public data: {
      CampaignName: string,
      CountThirdParties: number,
      StartDate: Date,
      EndDate:Date,
      QuestionaryName: string
      Introduction: string,
      Amount: number
      
    }) { }

  //cancelar
  onNoClick(): void {
    this.dialogRef.close();
  }

  //aceptar

  finishCreate():void{

    //this.router.navigate(['//evaluacion-enviada']);
    this.dialogRef.close(); 

  }

  ngOnInit(): void {
    this.dateTimeStart = formatearFecha(this.data.StartDate);
  }

}
