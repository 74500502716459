<div *ngIf="isLoading" class="overlay">
  <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
</div>
<div *ngIf="resolutionLinks.length === 0 && thirdPartyId === null" class="start-screening-container">
  <div class="start-screening-item-image">
    <img src='assets/no-resolutions.svg' alt="" loading="lazy" />
  </div>
  <div class="start-screening-item-info">
    <p><strong><span style="font-size: x-large">{{'resolutionDashboard.intro'|translate}}</span></strong></p>
    <p style="color: #8c8c8c; margin-top: 12px;">{{'resolutionDashboard.explanation'|translate}}</p>
  </div>
</div>

<section *ngIf="resolutionLinks.length !== 0" class="c-table u-mt-1">
  <div class="c-table__top">
    <div class="row">
      <div class="col-7 table-title">
        <h2>{{'resolutionDashboard.resolutionList'|translate}}</h2>
      </div>
      <div class="col-8">
        <app-text-table-filter (filter)="onFilter($event)"></app-text-table-filter>
      </div>
    </div>
  </div>
  <div class="c-table__bottom">
    <mat-accordion>
      <ng-container *ngFor="let resolutionLink of resolutionLinksPaged">
        <app-resolution-link [resolutionLink]="resolutionLink" [thirdPartyId]="thirdPartyId"
          (refreshResolution)="refreshResolution($event)"></app-resolution-link>
      </ng-container>
    </mat-accordion>
    <mat-paginator #paginator (page)="onPageChange($event)" [pageSize]="pageSize" [length]="totalResolutions"
      [pageSizeOptions]="[5, 10]" class="c-pagination">
    </mat-paginator>
  </div>
</section>
