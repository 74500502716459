import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RemoteService } from '@app/services/remote.service';
import { CommonsService } from '@app/services/commons-service';
import { GlobalService } from "@app/services/global.service";
import { UserProfileService } from "@app/services/users-profile.service";
import { CountryDTO } from '@app/models/Country';
import { TypeOfActivityDTO } from '@app/models/TypeOfActivity';
import { IsoCertificationsDto, IsoCertificationsFileCreateDto } from '@app/models/IsoCertifications';
import { FinanceTypesDto } from '@app/models/FinanceTypes';
import { ExecutiveOrganDto } from '@app/models/ExecutiveOrgan';
import { ClienOnBoarding, ClienOnBoardingUpdate } from '@app/models/Client';
import { ClientInfoCreateDto, ClientInfoUpdateDto } from '@app/models/ClientInfo';
import { Router } from "@angular/router";
import { Client } from '@app/models/Client';
import { Toaster } from 'ngx-toast-notifications';
import { AwsS3Service } from '@app/services/awsS3.service';
import { mapClient } from '@app/services/mappings/client.mappings';

@Component({
  selector: 'app-onboarding-card',
  templateUrl: './onboarding-card.component.html',
  styles: []
})
export class OnboardingCardComponent implements OnInit {


  countries: Array<CountryDTO> = [];
  activities: Array<TypeOfActivityDTO> = [];
  compliancePrograms: Array<any> = [];
  organs: Array<ExecutiveOrganDto> = [];
  sponsors: Array<FinanceTypesDto> = [];
  isos: Array<IsoCertificationsDto> = [];

  selectedData1ExtraDocFile: any;
  selectedData1ExtraDocFileName: any;
  selectedData1ExtraDocFileUri: any;

  selectedInternalAuditDocFile: any;
  selectedInternalAuditDocFileName: any;
  selectedInternalAuditDocFileUri: any;

  selectedExternalAuditDocFile: any;
  selectedExternalAuditDocFileName: any;
  selectedExternalAuditDocFileUri: any;

  selectedAccountDocFile: any;
  selectedAccountDocFileName: any;
  selectedAccountDocFileUri: any;

  uploadButtonTextAccountDoc: string;
  uploadButtonTextInternalAuditDoc: string;
  uploadButtonTextExternalAuditDoc: string;
  uploadButtonTextExtraDoc: string;
  uploadButtonTextIsoDoc: string;

  inputData: Client;
  @Input() showProgress;
  @Input() hideOnboardingNavbar = false;

  isoFilesMap = {};
  accountYearFilesMap = {};
  loaded: boolean = false;

  //form: any;

  rate: string = '';
  ratenum = 0;

  //showRateCompleted: boolean = false;
  editMode: boolean = false;

  private successfullySavedMessage: string;

  form = this.fb.group({

    Datos1: this.fb.group({
      Cif: new FormControl('', Validators.required),
      ActivityGroup: this.fb.group({
        Activity: new FormControl(null, []),
        OtherActivity: new FormControl('', []),
      }),
      Data1ExtraDoc: new FormControl('', []),
    }),
    Datos2: this.fb.group({
      CompanyGroup: new FormControl('', Validators.required),
      Country: new FormControl('', Validators.required),
      ComplianceProgram: new FormControl('', Validators.required),
    }),
    Datos3: this.fb.group({
      Workers: new FormControl('', Validators.required),
      OrganGroup: this.fb.group({
        Organ: new FormControl(null, Validators.required),
        OtherOrgan: new FormControl('', []),
      }),
      Subcontracted: new FormControl('', Validators.required),
      Participated: new FormControl('', Validators.required),
    }),
    Datos4: this.fb.group({
      Sponsors: this.fb.array([]),
    }),
    Datos5: this.fb.array([]),

    Datos6: this.fb.group({
      InternalAudit: new FormControl('', [Validators.required]),
      InternalAuditDoc: new FormControl({ value: '', disabled: true }, []),
      ExternalAudit: new FormControl('', [Validators.required]),
      ExternalAuditDoc: new FormControl({ value: '', disabled: true }, []),
    }),
    Datos7: this.fb.group({
      Isos: this.fb.array([]),
    })
  });

  constructor(

    public global: GlobalService,
    private fb: FormBuilder,
    private remoteService: RemoteService,
    private toaster: Toaster,
    private translate: TranslateService,
    public router: Router,
    private commons: CommonsService,
    private userProfileService: UserProfileService,
    private awsS3Service : AwsS3Service
  ) { }

  public comboInputValidator(combo, input): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls[combo];
      const control2 = group.controls[input];
      if (control1.value == null || control1.value == undefined) {
        if (control2.value == null || control2.value == undefined || control2.value.trim().length == 0) {
          control1.setErrors({ notProvided: true });
        } else {
          control1.setErrors(null);
          control2.setErrors(null);
        }
      } else {
        control1.setErrors(null);
        control2.setErrors(null);
      }
      return;
    };
  }

  async ngOnInit(): Promise<void> {
    this.translate.get('OnboardingFillCard.successfullySaved')
      .subscribe(translation => {
        this.successfullySavedMessage = translation;
      });

    await this.loadInitialData();

    this.form.get('Datos6').get('InternalAudit').valueChanges.subscribe(val => {
      if (val == 2) {
        this.form.get('Datos6').get('InternalAuditDoc').enable();
        this.form.get('Datos6').get('InternalAuditDoc').setValidators([Validators.required])
        this.form.get('Datos6').get('InternalAuditDoc').updateValueAndValidity();
        this.form.updateValueAndValidity();
      } else {
        this.selectInternalAuditDocument({ file: null })
        this.form.get('Datos6').get('InternalAuditDoc').setValidators([])
        this.form.get('Datos6').get('InternalAuditDoc').updateValueAndValidity();
        this.form.updateValueAndValidity();
        this.form.get('Datos6').get('InternalAuditDoc').disable();
      }
    });

    this.form.get('Datos6').get('ExternalAudit').valueChanges.subscribe(val => {
      if (val == 2) {
        this.form.get('Datos6').get('ExternalAuditDoc').enable();
        this.form.get('Datos6').get('ExternalAuditDoc').setValidators([Validators.required])
        this.form.get('Datos6').get('ExternalAuditDoc').updateValueAndValidity();
        this.form.updateValueAndValidity();
      } else {
        this.selectExternalAuditDocument({ file: null })
        this.form.get('Datos6').get('ExternalAuditDoc').setValidators([])
        this.form.get('Datos6').get('ExternalAuditDoc').updateValueAndValidity();
        this.form.updateValueAndValidity();
        this.form.get('Datos6').get('ExternalAuditDoc').disable();
      }
    });

    this.form.get('Datos1').get("ActivityGroup").setValidators(this.comboInputValidator('Activity', 'OtherActivity'))
    this.form.get('Datos3').get("OrganGroup").setValidators(this.comboInputValidator('Organ', 'OtherOrgan'))

    for (let index = 0; index < this.sponsors.length; index++) {
      let ctrl = new FormControl(false, [])
      this.Sponsors.push(ctrl);
    }

    for (let index = 0; index < this.isos.length; index++) {
      let grp = this.fb.group({
        Iso: new FormControl(false, []),
        IsoFile: new FormControl({ value: '', disabled: true }, [])
      })

      grp.get('Iso').valueChanges.subscribe(val => {        
        if (val) {
          let a = this.form.get("Datos7").get('Isos') as FormArray;
          a.at(index).get('IsoFile').enable()
          a.at(index).get('IsoFile').setValidators([Validators.required])
          a.at(index).get('IsoFile').updateValueAndValidity();
          a.updateValueAndValidity();
        } else {
          let a = this.form.get("Datos7").get('Isos') as FormArray;
          a.at(index).get('IsoFile').setValidators([])
          a.at(index).get('IsoFile').updateValueAndValidity();
          a.updateValueAndValidity();
          a.at(index).get('IsoFile').disable();
        }

      });

      this.Isos.push(grp);
    }

    this.form.valueChanges.subscribe(val => {
      this.adjustStats();
    });

    if (this.inputData.clientInfo != null) {
      this.editMode = true;
    }
    this.patchInput();

    this.loaded = true;
    setTimeout(() => {
      this.adjustStats();
    }, 500);

    // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
        this.global.navShowOnboarding = this.hideOnboardingNavbar;
    }, 0);
  }

  //    ██████   █████  ████████  ██████ ██   ██ 
  //    ██   ██ ██   ██    ██    ██      ██   ██ 
  //    ██████  ███████    ██    ██      ███████ 
  //    ██      ██   ██    ██    ██      ██   ██ 
  //    ██      ██   ██    ██     ██████ ██   ██ 

  patchInput() {
    if (this.editMode) {
      this.form.get('Datos1').get('Cif').setValue(this.inputData.companyCif);
      this.form.get('Datos1').get("ActivityGroup").get('Activity').setValue(this.findInObjectArray(this.activities, this.inputData.typeOfActivity));
      this.form.get('Datos1').get("ActivityGroup").get('OtherActivity').setValue(this.inputData.otherTypeOfActivity);

      if (this.inputData.companyRegistrationFileName !== null && this.inputData.companyRegistrationFileUri !== null) {
        this.form.get('Datos1').get('Data1ExtraDoc').setValue({
          'FileName': this.inputData.companyRegistrationFileName,
          'FileUri': this.inputData.companyRegistrationFileUri,
          'File': null
        });
        this.selectedData1ExtraDocFile = null
        this.selectedData1ExtraDocFileName = this.inputData.companyRegistrationFileName
        this.selectedData1ExtraDocFileUri = this.inputData.companyRegistrationFileUri
      }

      this.form.get('Datos2').get('CompanyGroup').setValue(this.inputData.clientInfo.companyGroup ? 2 : 0);
      this.form.get('Datos2').get('Country').setValue(this.inputData.country)
      this.form.get('Datos2').get('ComplianceProgram').setValue(this.findInObjectArray(this.compliancePrograms, this.inputData.clientInfo.complianceProgram));

      this.form.get('Datos3').get('Workers').setValue(this.inputData.clientInfo.numWorkers);
      this.form.get('Datos3').get('OrganGroup').get('Organ').setValue(this.inputData.clientInfo.executiveOrgan);
      this.form.get('Datos3').get('OrganGroup').get('OtherOrgan').setValue(this.inputData.clientInfo.otherExecutiveOrgan);
      this.form.get('Datos3').get('Subcontracted').setValue(this.inputData.clientInfo.outSourcedCompanies ? 2 : 0);
      this.form.get('Datos3').get('Participated').setValue(this.inputData.clientInfo.investeeCompanies ? 2 : 0);

      let financeArray = this.form.get('Datos4').get('Sponsors') as FormArray;
      for (let index = 0; index < this.sponsors.length; index++) {
        const element = this.sponsors[index];
        if (this.isObjectInArray(element.Id, this.inputData.clientInfo.financeTypes)) {
          financeArray.at(index).setValue(true)
        }
      }

      this.form.get('Datos6').get('InternalAudit').setValue(this.inputData.clientInfo.internalAudit ? 2 : 0);
      this.form.get('Datos6').get('ExternalAudit').setValue(this.inputData.clientInfo.externalAudit ? 2 : 0);

      if (this.inputData.clientInfo.internalAuditFileName !== null && this.inputData.clientInfo.internalAuditFileUri !== null) {
        this.form.get('Datos6').get('InternalAuditDoc').setValue({
          'FileName': this.inputData.clientInfo.internalAuditFileName,
          'FileUri': this.inputData.clientInfo.internalAuditFileUri,
          'File': null
        });
        this.selectedInternalAuditDocFile = null
        this.selectedInternalAuditDocFileName = this.inputData.clientInfo.internalAuditFileName
        this.selectedInternalAuditDocFileUri = this.inputData.clientInfo.internalAuditFileUri
      }

      if (this.inputData.clientInfo.externalAuditFileName !== null && this.inputData.clientInfo.externalAuditFileUri !== null) {
        this.form.get('Datos6').get('ExternalAuditDoc').setValue({
          'FileName': this.inputData.clientInfo.externalAuditFileName,
          'FileUri': this.inputData.clientInfo.externalAuditFileUri,
          'File': null
        });
        this.selectedExternalAuditDocFile = null
        this.selectedExternalAuditDocFileName = this.inputData.clientInfo.externalAuditFileName
        this.selectedExternalAuditDocFileUri = this.inputData.clientInfo.externalAuditFileUri
      }

      let isosArray = this.form.get('Datos7').get('Isos') as FormArray;
      for (let index = 0; index < this.isos.length; index++) {
        const element = this.isos[index];
        if (this.isObjectInArray2(element.Id, this.inputData.clientInfo.isoCertifications)) {
          isosArray.at(index).get('Iso').setValue(true)
          let isoItem = this.findIsoItem(element.Id, this.inputData.clientInfo.isoCertifications);          
          if (isoItem.IsoCertificationFileName !== null && isoItem.IsoCertificationFileUri !== null) {
            isosArray.at(index).get('IsoFile').setValue({
              'FileName': isoItem.IsoCertificationFileName,
              'FileUri': isoItem.IsoCertificationFileUri,
              'File': null
            });
            this.isoFilesMap[index] = { file: null, filename: isoItem.IsoCertificationFileName, fileuri: isoItem.IsoCertificationFileUri };
          }
        }
      }

      if (this.inputData.clientInfo.annualAccountingFirstYearFileUri != null) {
        let newyear = new FormControl(this.inputData.clientInfo.annualAccountingFirstYear, Validators.required)
        let newDoc = new FormControl({
          'FileName': this.inputData.clientInfo.annualAccountingFirstYearFileName,
          'FileUri': this.inputData.clientInfo.annualAccountingFirstYearFileUri,
          'File': null
        }, Validators.required)
        this.accountYearFilesMap[0] = { file: null, filename: this.inputData.clientInfo.annualAccountingFirstYearFileName, fileuri: this.inputData.clientInfo.annualAccountingFirstYearFileUri };
        this.AccountYears.push(this.fb.group({ "Year": newyear, "Doc": newDoc }));
      }

      if (this.inputData.clientInfo.annualAccountingSecondYearFileUri != null) {
        let newyear = new FormControl(this.inputData.clientInfo.annualAccountingSecondYear, Validators.required)
        let newDoc = new FormControl({
          'FileName': this.inputData.clientInfo.annualAccountingSecondYearFileName,
          'FileUri': this.inputData.clientInfo.annualAccountingSecondYearFileUri,
          'File': null
        }, Validators.required)
        this.accountYearFilesMap[1] = { file: null, filename: this.inputData.clientInfo.annualAccountingSecondYearFileName, fileuri: this.inputData.clientInfo.annualAccountingSecondYearFileUri };
        this.AccountYears.push(this.fb.group({ "Year": newyear, "Doc": newDoc }));
      }
    }

  }

  findIsoItem(id, array) {
    let res = null;
    for (let index = 0; index < array.length; index++) {
      const element = array[index].IsoCertification;
      if (element.Id === id) {
        {
          res = array[index];
          break;
        }
      }
    }
    return res;
  }

  isObjectInArray(id, array) {
    let res: boolean = false;
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (element.FinanceType.Id == id) {
        {
          res = true;
          break;
        }
      }
    }

    return res;
  }

  isObjectInArray2(id, array) {
    let res: boolean = false;
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (element.IsoCertification.Id == id) {
        {
          res = true;
          break;
        }
      }
    }

    return res;
  }

  findInObjectArray(array, text) {
    let res: string = null;
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (element.Code === text) {
        {
          res = element;
          break;
        }
      }
    }

    return res;
  }


  adjustStats() {
    this.rate = this.getvalidQuestionsRate();
    this.ratenum = this.getvalidQuestionsRateNum();
  }


  get Sponsors() {
    return this.form.get('Datos4').get('Sponsors') as FormArray;
  }

  get Isos() {
    return this.form.get('Datos7').get('Isos') as FormArray;
  }

  get AccountYears() {
    return this.form.get('Datos5') as FormArray;
  }

  ngOnDestroy(): void {
        // using setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.global.navShowOnboarding = true;
        }, 0);
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (option && value) {
      return option.Id === value.Id;
    }
    else {
      return false;
    }
  }

  /*
  ██ ███    ██ ██ ████████ ██  █████  ██          ██████   █████  ████████  █████  
  ██ ████   ██ ██    ██    ██ ██   ██ ██          ██   ██ ██   ██    ██    ██   ██ 
  ██ ██ ██  ██ ██    ██    ██ ███████ ██          ██   ██ ███████    ██    ███████ 
  ██ ██  ██ ██ ██    ██    ██ ██   ██ ██          ██   ██ ██   ██    ██    ██   ██ 
  ██ ██   ████ ██    ██    ██ ██   ██ ███████     ██████  ██   ██    ██    ██   ██ 
  */

  async loadInitialData() {
    let promiseCountries = this.getCountriesP();
    let promiseActivity = this.getActivityP();
    let promiseIsos = this.getIsosP();
    let promiseSponsors = this.getSponsorsP();
    let promiseOrgans = this.getOrgansP();
    let promiseImplementedMatrix = this.getImplementedFromMatrixP();
    let promiseInputData = this.getUserDataP();

    let promises = [
      promiseCountries,   // 0
      promiseActivity,    // 1
      promiseIsos,        // 2
      promiseSponsors,    // 3
      promiseOrgans,      // 4
      promiseImplementedMatrix, // 5
      promiseInputData,   // 6
    ];

    await Promise.all(promises).then(values => {
      this.getCountries(values[0]);
      this.getActivity(values[1]);
      this.getIsos(values[2]);
      this.getSponsors(values[3]);
      this.getOrgans(values[4]);
      this.getImplementedFromMatrix(values[5]);
      this.getUserData(values[6]);
    });

  }

  getUserDataP() {
    return this.remoteService.getRequest("Users/GetClientData");
  }

  getUserData(r) {
    if (r !== null && r !== undefined) {
      this.inputData = mapClient(r, this.translate.currentLang);
    }
  }

  getCountriesP() {
    return this.remoteService.getRequest("Commons/Country");
  }

  getCountries(r) {
    if (r !== null && r !== undefined) {
      this.countries = (<CountryDTO[]>r);
    }
  }


  getActivityP() {
    return this.remoteService.getRequest("Commons/ActivityType");
  }

  getActivity(r) {
    if (r !== null && r !== undefined) {
      this.activities = (<TypeOfActivityDTO[]>r);
    }
  }

  getIsosP() {
    return this.commons.getIsoCertifications();
  }


  getIsos(r) {
    if (r !== null && r !== undefined) {
      this.isos = (<IsoCertificationsDto[]>r);
    }
  }


  getSponsorsP() {
    return this.commons.getSponsors();
  }


  getSponsors(r) {
    if (r !== null && r !== undefined) {
      this.sponsors = (<FinanceTypesDto[]>r);
    }
  }

  getOrgansP() {
    return this.commons.getOrgans();
  }

  getOrgans(r) {
    if (r !== null && r !== undefined) {
      this.organs = (<ExecutiveOrganDto[]>r);
    }
  }


  getImplementedFromMatrixP() {
    return this.translate.get([
      'OnboardingFillCard.form.switch.no.valor',
      'OnboardingFillCard.form.switch.yes.valor',
      'OnboardingFillCard.account.button.text.valor',
      'OnboardingFillCard.internal.button.text.valor',
      'OnboardingFillCard.external.button.text.valor',
      'OnboardingFillCard.data1extradoc.button.text.valor',
      'OnboardingFillCard.iso.button.text.valor',
    ]).toPromise();
  }

  getImplementedFromMatrix(translation) {
    this.compliancePrograms.push({ Id: 0, Code: false, Description: translation['OnboardingFillCard.form.switch.no.valor'] });
    this.compliancePrograms.push({ Id: 1, Code: true, Description: translation['OnboardingFillCard.form.switch.yes.valor'] });

    this.uploadButtonTextAccountDoc = translation['OnboardingFillCard.account.button.text.valor']
    this.uploadButtonTextInternalAuditDoc = translation['OnboardingFillCard.internal.button.text.valor']
    this.uploadButtonTextExternalAuditDoc = translation['OnboardingFillCard.external.button.text.valor']
    this.uploadButtonTextExtraDoc = translation['OnboardingFillCard.data1extradoc.button.text.valor']
    this.uploadButtonTextIsoDoc = translation['OnboardingFillCard.iso.button.text.valor']
  }

  /*
  ███████  ██████  ██████  ███    ███ 
  ██      ██    ██ ██   ██ ████  ████ 
  █████   ██    ██ ██████  ██ ████ ██ 
  ██      ██    ██ ██   ██ ██  ██  ██ 
  ██       ██████  ██   ██ ██      ██ 
  */


  selectInternalAuditDocument(resp) {
    if (resp.file !== null) {
      this.selectedInternalAuditDocFile = resp.file;
      this.selectedInternalAuditDocFileName = resp.file.name;
      this.selectedInternalAuditDocFileUri = null;
    } else {
      this.selectedInternalAuditDocFile = null;
      this.selectedInternalAuditDocFileName = null;
      this.selectedInternalAuditDocFileUri = null;
    }
  }

  selectExternalAuditDocument(resp) {
    if (resp.file !== null) {
      this.selectedExternalAuditDocFile = resp.file;
      this.selectedExternalAuditDocFileName = resp.file.name;
      this.selectedExternalAuditDocFileUri = null;
    } else {
      this.selectedExternalAuditDocFile = null;
      this.selectedExternalAuditDocFileName = null
      this.selectedExternalAuditDocFileUri = null;
    }
  }

  selectDocumentAccount(resp) {
    if (resp.file !== null) {
      this.selectedAccountDocFile = resp.file;
      this.selectedAccountDocFileName = resp.file.name;
      this.selectedAccountDocFileUri = null
    } else {
      this.selectedAccountDocFile = null;
      this.selectedAccountDocFileName = null;
      this.selectedAccountDocFileUri = null
    }
  }

  selectData1ExtraDoc(resp) {
    if (resp.file !== null) {
      this.selectedData1ExtraDocFile = resp.file;
      this.selectedData1ExtraDocFileName = resp.file.name;
      this.selectedData1ExtraDocFileUri = null;
    } else {
      this.selectedData1ExtraDocFile = null;
      this.selectedData1ExtraDocFileName = null;
      this.selectedData1ExtraDocFileUri = null;
    }
  }

  selectIsoDocument(resp, i) {
    if (resp.file !== null) {
      // Add
      this.isoFilesMap[i] = { file: resp.file, filename: resp.file.name, fileuri: null };
    } else {
      // Remove
      delete this.isoFilesMap[i];
    }
  }


  addAccountYear() {
    if (this.AccountYears.length < 2) {
      let xx = this.fb.group({
        Year: new FormControl(null, [Validators.required]),
        Doc: new FormControl(null, [Validators.required]),
      })

      this.AccountYears.push(xx);
    }
  }

  deleteAccountYear(i: number) {
    if (i == 1) {
      delete this.accountYearFilesMap[i];
    }
    else {
      this.accountYearFilesMap[0] = this.accountYearFilesMap[1];
      delete this.accountYearFilesMap[1];
    }
    this.AccountYears.removeAt(i);
  }

  selectDocumentAccountYear(resp, i) {
    if (resp.file !== null) {
      // Add
      this.accountYearFilesMap[i] = { file: resp.file, filename: resp.file.name, fileuri: null };
    } else {
      // Remove
      if (i == 1) {
        delete this.accountYearFilesMap[i];
      }
      else {
        this.accountYearFilesMap[0] = this.accountYearFilesMap[1];
        delete this.accountYearFilesMap[1];
      }
    }
  }

  private async generateCommonData(salida: ClienOnBoarding): Promise<ClienOnBoarding> {
    let Cif = this.form.get("Datos1").get("Cif").value;
    let Activity = this.form.get("Datos1").get("ActivityGroup").get("Activity").value ? this.form.get("Datos1").get("ActivityGroup").get("Activity").value.Code : null;
    let OtherActivity = this.form.get("Datos1").get("ActivityGroup").get("OtherActivity").value;
    salida.CompanyCif = Cif;
    salida.TypeOfActivity = Activity;
    salida.OtherTypeOfActivity = OtherActivity;

    let CompanyGroup = this.form.get("Datos2").get("CompanyGroup").value;
    let Country = this.form.get("Datos2").get("Country").value.Id;
    let ComplianceProgram = this.form.get("Datos2").get("ComplianceProgram").value.Code;
    salida.ClientInfo = (<ClientInfoCreateDto>{});
    salida.ClientInfo.CompanyGroup = CompanyGroup == 2 ? true : false;
    salida.CountryId = Country;
    salida.ClientInfo.ComplianceProgram = ComplianceProgram;    

    let Workers = this.form.get("Datos3").get("Workers").value;
    let Organ = this.form.get("Datos3").get("OrganGroup").get("Organ").value ? this.form.get("Datos3").get("OrganGroup").get("Organ").value.Id : null;
    let OtherOrgan = this.form.get("Datos3").get("OrganGroup").get("OtherOrgan").value;
    let Subcontracted = this.form.get("Datos3").get("Subcontracted").value;
    let Participated = this.form.get("Datos3").get("Participated").value;
    let NumWorkers = this.form.get("Datos3").get("Workers").value;
    salida.ClientInfo.ExecutiveOrganId = Organ;
    salida.ClientInfo.OtherExecutiveOrgan = OtherOrgan;
    salida.ClientInfo.OutSourcedCompanies = Subcontracted == 2 ? true : false;
    salida.ClientInfo.InvesteeCompanies = Participated == 2 ? true : false;
    salida.ClientInfo.NumWorkers = NumWorkers;

    let Sponsors = this.form.get("Datos4").get("Sponsors") as FormArray;
    salida.ClientInfo.FinanceTypes = (<FinanceTypesDto[]>[]);
    for (let index = 0; index < Sponsors.length; index++) {
      const element = Sponsors.at(index);
      if (element.value) {
        salida.ClientInfo.FinanceTypes.push(this.sponsors[index])
      }
    }

    return await this.generateInternalAuditData(salida);
  }

  private async generateInternalAuditData(salida: ClienOnBoarding) : Promise<ClienOnBoarding> {
    let InternalAudit = this.form.get("Datos6").get("InternalAudit").value;
    salida.ClientInfo.InternalAudit = InternalAudit == 2 ? true : false;
    salida.ClientInfo.InternalAuditFileName = this.selectedInternalAuditDocFileName;

    if (this.selectedInternalAuditDocFile){
      return await this.userProfileService.getFileStorageKey(this.selectedInternalAuditDocFileName).then(res => {
        salida.ClientInfo.InternalAuditFileUri = res.FileId;        
        this.awsS3Service.uploadFileToS3(res.FileUri, this.selectedInternalAuditDocFile).subscribe();
      }).then(() => this.generateExternalAuditData(salida));
    } else {
      salida.ClientInfo.InternalAuditFileUri = this.selectedInternalAuditDocFileUri;
      return await this.generateExternalAuditData(salida);
    }    
  }

  private async generateExternalAuditData(salida: ClienOnBoarding): Promise<ClienOnBoarding> {
    let ExternalAudit = this.form.get("Datos6").get("ExternalAudit").value; 

    salida.ClientInfo.ExternalAudit = ExternalAudit == 2 ? true : false;
    salida.ClientInfo.ExternalAuditFileName = this.selectedExternalAuditDocFileName;

    if (this.selectedExternalAuditDocFile){
      return await this.userProfileService.getFileStorageKey(this.selectedExternalAuditDocFileName).then(res => {
        salida.ClientInfo.ExternalAuditFileUri = res.FileId;        
        this.awsS3Service.uploadFileToS3(res.FileUri, this.selectedExternalAuditDocFile).subscribe();
      }).then(() => this.generateIsoData(salida));
    } else {
      salida.ClientInfo.ExternalAuditFileUri = this.selectedExternalAuditDocFileUri;
      return await this.generateIsoData(salida);
    }    
  }

  private async generateIsoData(salida: ClienOnBoarding): Promise<ClienOnBoarding> {
    let Isos = this.form.get("Datos7").get("Isos") as FormArray;
    salida.ClientInfo.IsoCertifications = (<IsoCertificationsFileCreateDto[]>[]);
    for (let index = 0; index < Isos.length; index++) {
      const element = Isos.at(index).get('Iso');
      if (element.value) {
        let newIso = (<IsoCertificationsFileCreateDto>{});
        newIso.IsoCertificationId = this.isos[index].Id;
        newIso.IsoCertificationFileName = this.isoFilesMap[index].filename;
        if (this.isoFilesMap[index].file) {
          newIso.IsoCertificationFile = this.isoFilesMap[index].file;                    
        } else {
          newIso.IsoCertificationFile = null;
          newIso.IsoCertificationFileUri = this.isoFilesMap[index].fileuri;
        }
        salida.ClientInfo.IsoCertifications.push(newIso);        
      }
    }
    return await this.uploadIsoFiles(salida, 0);
  }

  async uploadIsoFiles(salida: ClienOnBoarding, index: number): Promise<ClienOnBoarding> {
    if (index < salida.ClientInfo.IsoCertifications.length){
      if(salida.ClientInfo.IsoCertifications[index].IsoCertificationFile){
        return await this.userProfileService.getFileStorageKey(salida.ClientInfo.IsoCertifications[index].IsoCertificationFileName).then(res => {
          salida.ClientInfo.IsoCertifications[index].IsoCertificationFileUri = res.FileId;        
          this.awsS3Service.uploadFileToS3(res.FileUri, salida.ClientInfo.IsoCertifications[index].IsoCertificationFile).subscribe();
        }).then(()=> this.uploadIsoFiles(salida, index + 1));
      }
      else {
        return await this.uploadIsoFiles(salida, index + 1);
      }
    } else {
      return await this.generateAccountYearData(salida);
    }
  }

  async generateAccountYearData(salida: ClienOnBoarding): Promise<ClienOnBoarding> {
    for (let index = 0; index < this.AccountYears.length; index++) {
      const ay = this.AccountYears.at(index);
      if (index == 0) {
        salida.ClientInfo.AnnualAccountingFirstYear = ay.get("Year").value;
        if (this.accountYearFilesMap[index].file){          
          salida.ClientInfo.AnnualAccountingFirstYearFile = this.accountYearFilesMap[index].file;
        } else {
          salida.ClientInfo.AnnualAccountingFirstYearFileUri = this.accountYearFilesMap[index].fileuri;
        }
        salida.ClientInfo.AnnualAccountingFirstYearFileName = this.accountYearFilesMap[index].filename;
      } else if (index == 1) {
        salida.ClientInfo.AnnualAccountingSecondYear = ay.get("Year").value;
        if (this.accountYearFilesMap[index].file){
          salida.ClientInfo.AnnualAccountingSecondYearFile = this.accountYearFilesMap[index].file;
        } else {
          salida.ClientInfo.AnnualAccountingSecondYearFileUri = this.accountYearFilesMap[index].fileuri;
        }
        salida.ClientInfo.AnnualAccountingSecondYearFileName = this.accountYearFilesMap[index].filename;
      }
    }

    if(salida.ClientInfo.AnnualAccountingFirstYearFile){
      return await this.userProfileService.getFileStorageKey(salida.ClientInfo.AnnualAccountingFirstYearFileName).then(res => {
          salida.ClientInfo.AnnualAccountingFirstYearFileUri = res.FileId;        
          this.awsS3Service.uploadFileToS3(res.FileUri, salida.ClientInfo.AnnualAccountingFirstYearFile).subscribe();
        }).then(() => this.generateAccountSecondYearData(salida));
    } else {
      return await this.generateAccountSecondYearData(salida);
    }
  } 

  async generateAccountSecondYearData(salida: ClienOnBoarding): Promise<ClienOnBoarding> {
    if(salida.ClientInfo.AnnualAccountingSecondYearFile){
      return await this.userProfileService.getFileStorageKey(salida.ClientInfo.AnnualAccountingSecondYearFileName).then(res => {
          salida.ClientInfo.AnnualAccountingSecondYearFileUri = res.FileId;        
          this.awsS3Service.uploadFileToS3(res.FileUri, salida.ClientInfo.AnnualAccountingSecondYearFile).subscribe();
        }).then(() => salida);
    } else {
      return await new Promise((resolve) => {
        resolve(salida);
    });
    }
  }

  private async generateOutput(salida: ClienOnBoarding): Promise<ClienOnBoarding> {    
    salida.CompanyRegistrationFileName = this.selectedData1ExtraDocFileName;
    if (this.selectedData1ExtraDocFile){      
      return await this.userProfileService.getFileStorageKey(this.selectedData1ExtraDocFileName).then(res => {
        salida.CompanyRegistrationFileUri = res.FileId;        
        this.awsS3Service.uploadFileToS3(res.FileUri, this.selectedData1ExtraDocFile).subscribe();
      }).then(() => this.generateCommonData(salida));
    } else {
      salida.CompanyRegistrationFileUri = this.selectedData1ExtraDocFileUri;
      return await this.generateCommonData(salida)
    }    
  }

  async saveOnboarding() {  
    let salida = (<ClienOnBoarding>{});  
    await this.generateOutput(salida).then(res => {
      this.userProfileService.SaveOnboarding(res)
        .then(response => {
          this.toaster.open({ text: this.successfullySavedMessage, duration: 6000, type: 'info' });
          this.router.navigate(['/onboarding-cuenta-configurada']);
        })
        .catch(errores => { console.log(errores) });
    });
  }

  async updateOnboarding() {   
    let salida = (<ClienOnBoarding>{}); 
    await this.generateOutput(salida).then(res => {
      let newClientInfo = (<ClientInfoUpdateDto>res.ClientInfo);
      newClientInfo.Id = this.inputData.clientInfo.id;
      let newClient = (<ClienOnBoardingUpdate>res);
      newClient.ClientInfo = newClientInfo;
      newClient.Id = this.inputData.id;
      this.userProfileService.UpdateOnboarding(<ClienOnBoardingUpdate>res)
        .then(response => {
          this.toaster.open({ text: this.successfullySavedMessage, duration: 6000, type: 'info' });
        })
        .catch(errores => { console.log(errores) });
    });
  }



  getvalidQuestionsRateNum() {
    if (this.form !== null && this.form !== undefined) {
      let total = Object.keys(this.form.controls).length;
      let keys = Object.keys(this.form.controls);
      let totalOk = 0;
      for (let indexB = 0; indexB < total; indexB++) {
        if (this.form.get(keys[indexB]).valid) {
          totalOk++;
        }
      }
      return (totalOk / total * 100);
    } else {
      return 0;
    }
  }

  getvalidQuestionsRate() {
    if (this.form !== null && this.form !== undefined) {
      let total = Object.keys(this.form.controls).length;
      let keys = Object.keys(this.form.controls);
      let totalOk = 0;
      for (let indexB = 0; indexB < total; indexB++) {
        if (this.form.get(keys[indexB]).valid) {
          totalOk++;
        }
      }
      return totalOk.toString() + "/" + total;
    } else {
      return ''
    }
  }
}
