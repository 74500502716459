import { Component, EventEmitter, Output } from '@angular/core';
import { EquifaxWSFilter } from '@app/modules/screening-hub/models/EquifaxWS/EquifaxWSFilter';

@Component({
  selector: 'equifax-ws-scoring-candidates-search',
  templateUrl: './scoring-candidates-search.component.html',
  styleUrls: ['./scoring-candidates-search.component.scss'],
})
export class ScoringCandidatesSearchEquifaxWSComponent {
  @Output() onSearchClick = new EventEmitter<EquifaxWSFilter>();

  model: EquifaxWSFilter;

  constructor() {
    this.model = new EquifaxWSFilter();
  }

  search() {
    this.onSearchClick.emit(this.model);
  }
}
