import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Questionary } from '@app/models/Questionary';
import { CountriesService } from '@app/services/countries.service';
import { SectorService } from '@app/services/sector.service';
import { ThirdPartyTypesService } from '@app/services/third-party-types.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-questionary-configuration',
    templateUrl: './questionary-configuration.component.html',
    styleUrls: ['./questionary-configuration.component.scss']
})
export class QuestionaryConfigurationComponent implements OnInit {
    @Input() questionary: Questionary;
    @Input() canEdit: boolean;
    @Output() openDeleteDialog = new EventEmitter<Questionary>();

    form: FormGroup;

    tipos3pp = [];
    tipoSector = [];
    tipoCountry = [];

    constructor(
        public rootFormGroup: FormGroupDirective,
        private thirdPartyTypesService: ThirdPartyTypesService,
        private sectorService: SectorService,
        private countriesService: CountriesService
    ) { }

    ngOnInit(): void {
        this.form = this.rootFormGroup.control;

        forkJoin({
            thirdPartyTypes: this.thirdPartyTypesService.getThirdPartyTypes(),
            sectors: this.sectorService.getSectors(),
            countries: this.countriesService.getCountries(),
        })
            .subscribe(({
                thirdPartyTypes,
                sectors,
                countries,
            }) => {
                this.tipos3pp = thirdPartyTypes;
                this.tipoSector = sectors;
                this.tipoCountry = countries;

                if (this.questionary.isMaster) {
                    this.tipoSector = [this.questionary.sector];
                    this.tipos3pp = [this.questionary.thirdPartyType];
                }
            });
    }

    public objectComparisonFunction = function (option, value): boolean {
        if (option && value) {
            return option.id === value.id;
        }
        else {
            return false;
        }
    }

    openDialogDeleteQuestionary() {
        this.openDeleteDialog.emit(this.questionary);
    }
}
