import { Component, Input } from "@angular/core";
import { WatchListInfo } from "@app/modules/screening-hub/models/GesintelScreening/WatchListInfo";

@Component({
  selector: 'aml-update-watchlist-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
})
export class AMLUpdateWatchlistBasicInfoComponent {
  @Input() watchlistInfo: WatchListInfo;

  constructor() { }
}
