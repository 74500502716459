/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Observable } from 'rxjs';
import { MemberCheckScanResultDto } from '../model/memberCheckScanResultDto';
import { MemberCheckScreeningDto } from '../model/memberCheckScreeningDto';
import { Configuration } from '../configuration';
import { MemberCheckLinkDto } from '../model/memberCheckLinkDto';
import { MemberScanRqDto } from '../model/memberScanRqDto';


@Injectable()
export class MemberCheckProxyClient {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   *
   * @param companyName
   * @param registrationNumber
   * @param address
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckCorpPostForm(companyName?: string, registrationNumber?: string, address?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public memberCheckCorpPostForm(companyName?: string, registrationNumber?: string, address?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public memberCheckCorpPostForm(companyName?: string, registrationNumber?: string, address?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public memberCheckCorpPostForm(companyName?: string, registrationNumber?: string, address?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {




    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (companyName !== undefined) {
      formParams = formParams.append('companyName', <any>companyName) as any || formParams;
    }
    if (registrationNumber !== undefined) {
      formParams = formParams.append('registrationNumber', <any>registrationNumber) as any || formParams;
    }
    if (address !== undefined) {
      formParams = formParams.append('address', <any>address) as any || formParams;
    }

    return this.httpClient.request<any>('post', `${this.basePath}/MemberCheck/corp`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckCorpReportIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public memberCheckCorpReportIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public memberCheckCorpReportIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public memberCheckCorpReportIdGet(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling memberCheckCorpReportIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<string>('get', `${this.basePath}/MemberCheck/corp/report/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param scanId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckCorpScanIdGet(scanId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MemberCheckScanResultDto>>;
  public memberCheckCorpScanIdGet(scanId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MemberCheckScanResultDto>>>;
  public memberCheckCorpScanIdGet(scanId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MemberCheckScanResultDto>>>;
  public memberCheckCorpScanIdGet(scanId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (scanId === null || scanId === undefined) {
      throw new Error('Required parameter scanId was null or undefined when calling memberCheckCorpScanIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<MemberCheckScanResultDto>>('get', `${this.basePath}/MemberCheck/corp/${encodeURIComponent(String(scanId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckLinkIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public memberCheckLinkIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public memberCheckLinkIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public memberCheckLinkIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling memberCheckLinkIdDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<boolean>('delete', `${this.basePath}/MemberCheck/link/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param thirdPartyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckLinkIdRefreshGet(id: number, thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public memberCheckLinkIdRefreshGet(id: number, thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public memberCheckLinkIdRefreshGet(id: number, thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public memberCheckLinkIdRefreshGet(id: number, thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling memberCheckLinkIdRefreshGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (thirdPartyId !== undefined && thirdPartyId !== null) {
      queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<string>('get', `${this.basePath}/MemberCheck/link/${encodeURIComponent(String(id))}/refresh`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckLinkIdScreeningsGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MemberCheckScreeningDto>>;
  public memberCheckLinkIdScreeningsGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MemberCheckScreeningDto>>>;
  public memberCheckLinkIdScreeningsGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MemberCheckScreeningDto>>>;
  public memberCheckLinkIdScreeningsGet(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling memberCheckLinkIdScreeningsGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<MemberCheckScreeningDto>>('get', `${this.basePath}/MemberCheck/link/${encodeURIComponent(String(id))}/screenings`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param thirdPartyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckLinkPost(body?: MemberCheckScanResultDto, thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public memberCheckLinkPost(body?: MemberCheckScanResultDto, thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public memberCheckLinkPost(body?: MemberCheckScanResultDto, thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public memberCheckLinkPost(body?: MemberCheckScanResultDto, thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (thirdPartyId !== undefined && thirdPartyId !== null) {
      queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<boolean>('post', `${this.basePath}/MemberCheck/link`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param thirdPartyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckLinksGet(thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MemberCheckLinkDto>>;
  public memberCheckLinksGet(thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MemberCheckLinkDto>>>;
  public memberCheckLinksGet(thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MemberCheckLinkDto>>>;
  public memberCheckLinksGet(thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (thirdPartyId !== undefined && thirdPartyId !== null) {
      queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<MemberCheckLinkDto>>('get', `${this.basePath}/MemberCheck/links`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckMemberPost(body?: MemberScanRqDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public memberCheckMemberPost(body?: MemberScanRqDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public memberCheckMemberPost(body?: MemberScanRqDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public memberCheckMemberPost(body?: MemberScanRqDto, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/MemberCheck/member`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckMemberReportIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public memberCheckMemberReportIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public memberCheckMemberReportIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public memberCheckMemberReportIdGet(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling memberCheckMemberReportIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<string>('get', `${this.basePath}/MemberCheck/member/report/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param scanId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckMemberScanIdGet(scanId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MemberCheckScanResultDto>>;
  public memberCheckMemberScanIdGet(scanId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MemberCheckScanResultDto>>>;
  public memberCheckMemberScanIdGet(scanId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MemberCheckScanResultDto>>>;
  public memberCheckMemberScanIdGet(scanId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (scanId === null || scanId === undefined) {
      throw new Error('Required parameter scanId was null or undefined when calling memberCheckMemberScanIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<MemberCheckScanResultDto>>('get', `${this.basePath}/MemberCheck/member/${encodeURIComponent(String(scanId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public memberCheckScreeningIdPdfGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public memberCheckScreeningIdPdfGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public memberCheckScreeningIdPdfGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public memberCheckScreeningIdPdfGet(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling memberCheckScreeningIdPdfGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<string>('get', `${this.basePath}/MemberCheck/screening/${encodeURIComponent(String(id))}/pdf`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
