import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { CommonsService } from '@app/services/commons-service';
import { SectorDTO } from '@app/models/Sector';
import { CountryDTO } from '@app/models/Country';
import { parsearErroresAPI } from '@app/utilities/utilities';
import { ThirdPartyTypeDTO } from '@app/models/ThirdPartyType';
import { ThirdPartyDTO, ThirdPartyCreateDTO } from '@app/models/ThirdParty';
import { Toaster } from 'ngx-toast-notifications';
import { ResponsiblesDTO } from '@app/models/Responsibles';
import { NatureOfActivityDTO } from '@app/models/NatureOfActivity';
import { TypeOfActivityDTO } from '@app/models/TypeOfActivity';
import { Location } from '@angular/common'
import { TranslateService } from '@ngx-translate/core';
import { FormularioThirdComponent } from '../formulario-third/formulario-third.component';
import { Language, LanguageValues } from '@app/models/Language';

@Component({
  selector: 'app-edit-third',
  templateUrl: './edit-third.component.html',
  styleUrls: ['./edit-third.component.scss']
})
export class EditThirdComponent implements OnInit {

  @ViewChild(FormularioThirdComponent) formularioThirdComponent: FormularioThirdComponent;

  errores: string[] = [];

  model: ThirdPartyDTO;

  languagesNoSelected: Language[] = [];
  countriesNoSelected: Array<CountryDTO> = [];
  sectorsNoSelected: Array<SectorDTO> = [];
  thirdPartyTypeNoSelected: Array<ThirdPartyTypeDTO> = [];
  responsiblesNoSelected: Array<ResponsiblesDTO> = [];
  natureOfActivityNoSelected: Array<NatureOfActivityDTO> = [];
  typeOfActivityNoSelected: Array<TypeOfActivityDTO> = [];
  tabIndex = 0;

  thirdUpdatedMessage: string;
  errorUpdatingThirdMsg: string;

  isLoading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private thirdPartiesService: ThirdPartiesService,
    private commonsService: CommonsService,
    private toaster: Toaster,
    private location: Location,
    private translate: TranslateService
  ) { }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.getData(params.id);

      this.tabIndex = params.tabIndex ?? 0;
    });

    this.getSectors();
    this.getContactLenguages();
    this.getCountries();
    this.getThirdPartyTypes();
    this.getResponsibles();
    this.getResponsibles();
    this.getNatureOfActivity();
    this.getTypeOfActivity();
    this.translate
      .get('InfoThird.successfullyUpdated')
      .subscribe((translation) => {
        this.thirdUpdatedMessage = translation;
      });

    this.translate.get('InfoThird.errorUpdating')
      .subscribe((translation) => {
        this.errorUpdatingThirdMsg = translation;
      });
  }

  saveChanges(updateThird: ThirdPartyCreateDTO) {
    this.isLoading = true;
    // TODO what is the failure response from the server?
    this.thirdPartiesService.updateThirdParties(this.model.Id, updateThird)
      .then(response => {
        this.isLoading = false;
        this.toaster.open({ text: this.thirdUpdatedMessage, duration: 6000, type: 'info' });
        this.model.ThirdPartyConfig.ThirdPartyStatus = updateThird.ThirdPartyConfig.ThirdPartyStatus;
        this.formularioThirdComponent.thirdPartyWasSaved(true);
        (error) => this.errores = parsearErroresAPI(response);
      })
      .catch(_ => {
        this.toaster.open({text: this.errorUpdatingThirdMsg, duration: 6000, type: 'danger'});
        this.isLoading = false;
      });
  }

  getData(id: string) {
    this.thirdPartiesService.getThirdPartiesById(id).then(response => {
      this.model = <ThirdPartyDTO>response;
      this.isLoading = false;
    });
  }

  public getResponsibles() {
    this.commonsService.getResponsibles().then(response => {
      this.responsiblesNoSelected = <ResponsiblesDTO[]>response;
    });

  }

  public getThirdPartyTypes() {
    this.commonsService.getThirdPartyTypes().then(response => {
      this.thirdPartyTypeNoSelected = <ThirdPartyTypeDTO[]>response;
    });
  }


  public getSectors() {
    this.commonsService.getSectors().then(response => {
      this.sectorsNoSelected = <SectorDTO[]>response;
    });
  }

  public getCountries() {
    this.commonsService.getCountries().then(response => {
      this.countriesNoSelected = <CountryDTO[]>response;
    });
  }

  public getContactLenguages() {
    this.languagesNoSelected = LanguageValues;
  }

  public getNatureOfActivity() {
    this.commonsService.getNatureOfActivity().then(response => {
      this.natureOfActivityNoSelected = <NatureOfActivityDTO[]>response;
    });
  }

  public getTypeOfActivity() {
    this.commonsService.getTypeOfActivity().then(response => {
      this.typeOfActivityNoSelected = <TypeOfActivityDTO[]>response;
    });
  }

  public back() {
    this.location.back()
  }

}
