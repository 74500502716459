<table mat-table [dataSource]="queryResult.items" class="mat-elevation-z8" matSort>
  <!-- Descripción -->
  <ng-container matColumnDef="software">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Software </th>
    <td mat-cell *matCellDef="let element"> {{getProviderName(element)}} </td>
  </ng-container>
  <ng-container matColumnDef="configuration">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'scoringToolsAccess.configuration' | translate}}</th>
    <td mat-cell *matCellDef="let element"> ****** </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> {{'scoringToolsAccess.actions' | translate}} </th>
    <td mat-cell *matCellDef="let element">
      <a (click)='delete(element)' class="c-table__watch">
        <span class="c-box-edit__button-ico" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
              d="M17.322,2.667h-2.1A3.383,3.383,0,0,0,11.9,0H8.1A3.383,3.383,0,0,0,4.78,2.667h-2.1A.667.667,0,1,0,2.678,4h.678v8.667A3.366,3.366,0,0,0,6.746,16h6.508a3.366,3.366,0,0,0,3.39-3.333V4h.678a.667.667,0,1,0,0-1.333ZM8.1,1.333h3.8a2.036,2.036,0,0,1,1.918,1.333H6.184A2.036,2.036,0,0,1,8.1,1.333Zm7.186,11.333a2.017,2.017,0,0,1-2.034,2H6.746a2.017,2.017,0,0,1-2.034-2V4H15.288Z"
              transform="translate(-2)" />
            <path d="M9.678,15.333a.672.672,0,0,0,.678-.667v-4a.678.678,0,0,0-1.356,0v4A.672.672,0,0,0,9.678,15.333Z"
              transform="translate(-4.39 -3.333)" />
            <path d="M13.678,15.333a.672.672,0,0,0,.678-.667v-4a.678.678,0,0,0-1.356,0v4A.672.672,0,0,0,13.678,15.333Z"
              transform="translate(-5.678 -3.333)" />
            <path d="M13.678,15.333a.672.672,0,0,0,.678-.667v-4a.678.678,0,0,0-1.356,0v4A.672.672,0,0,0,13.678,15.333Z"
              transform="translate(-2.966 -3.333)" />
          </svg>
        </span> {{'scoringToolsAccess.delete' | translate}}
      </a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9">{{'scoringToolsAccess.noConfigs' | translate}} </td>
  </tr>
</table>

<div class="row u-mt-2">
  <div class="col-12">
    <button type="button" (click)="openDialogAdd()" class="c-button c-button--add c-button--add-bg">
      <span class="c-button--add__ico" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path data-name=" ↳Color" d="M14,8H8v6H6V8H0V6H6V0H8V6h6Z" transform="translate(5 5)" />
        </svg>
      </span>
      {{'scoringToolsAccess.add' | translate}}
    </button>
  </div>
</div>
