import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { QuestionariesProxyClient } from "src/proxy/apiQuestionaries/questionaries.proxy";
import { Chart, ChartHeader } from "../pdf-template-generator/Models/chart";
import { ColorHex } from "../pdf-template-generator/Models/colorHex";
import { Content } from "../pdf-template-generator/Models/content";
import { CoverPage } from "../pdf-template-generator/Models/coverPage";
import { DocumentContent } from "../pdf-template-generator/Models/documentContent";
import { Font } from "../pdf-template-generator/Models/font";
import { Footer } from "../pdf-template-generator/Models/footer";
import { Header } from "../pdf-template-generator/Models/header";
import { Image } from "../pdf-template-generator/Models/image";
import { Row } from "../pdf-template-generator/Models/row";
import { Section } from "../pdf-template-generator/Models/section";
import { SvgPath } from "../pdf-template-generator/Models/svgPath";
import { Text } from "../pdf-template-generator/Models/text";
import { TextUnderline } from "../pdf-template-generator/Models/textUnderline";
import { templateGenerator } from "../pdf-template-generator/template-generator";
import { UsersProxyClient } from "src/proxy/apiUser/users.proxy";
import { Campaign } from "@app/models/Campaign";
import { QuestionaryDto } from "src/proxy/model/questionaryDto";
import { CampaignStatus } from "@app/models/CampaignStatus";

@Injectable()
export class CampaignRiskboardReportService {
    locale: string;

    constructor(private questionariesClient: QuestionariesProxyClient,
                private userClient: UsersProxyClient,
                private translate: TranslateService){ }

    async getReport(campaigns: Campaign[], imgAssesmentEvoChart: string,
                    imgThirdPartyStatusByCountryAndAssesmentChart: string,
                    imgThirdPartyStatusBySectorAndAssesmentChart: string
                    ): Promise<[string, Uint8Array]> {
        let sections: Section[] = [];
        this.locale = this.translate.currentLang.split("-")[0];
        const translations = await this.translate.get("riskboardReport").toPromise();
        const userDetails = await this.userClient.usersGetUserDataGet().toPromise();
        const username = `${userDetails.Name} ${userDetails.SurName}`;        
        const printDate = new Date();
        const fonts = await this.getReportFonts();
        const questionaryIds = [...new Set(campaigns.map(c => c.questionaryId))];
        const questionaries = await Promise.all(questionaryIds.map(async qId =>
            await this.questionariesClient.questionariesIdGet(qId).toPromise()  
        ));
        const coverPage = await this.getReportCoverPage(userDetails.Client.Name, username, printDate, campaigns.map(c => c.name), translations);        
        const pageHeader = await this.getPageHeader(translations);
        const pageFooter = this.getPageFooter(userDetails.Client.Name, printDate, username, translations);
        const riskboardChartSection = this.getRiskboardChartSection(imgAssesmentEvoChart, translations);
        const imgPending = await fetch('/assets/report/campaignStatusPending.png').then((res) => res.arrayBuffer());
        const imgInProgress = await fetch('/assets/report/campaignStatusInProgress.png').then((res) => res.arrayBuffer());
        const imgPendingSign = await fetch('/assets/report/campaignStatusPendingSign.png').then((res) => res.arrayBuffer());
        const imgPendingReview = await fetch('/assets/report/campaignStatusPendingReview.png').then((res) => res.arrayBuffer());
        const imgReviewed = await fetch('/assets/report/campaignStatusReviewed.png').then((res) => res.arrayBuffer());
        const imgDiscarded = await fetch('/assets/report/campaignStatusDiscarded.png').then((res) => res.arrayBuffer());
        const imgAcknowledged = await fetch('/assets/report/campaignStatusAcknowledged.png').then((res) => res.arrayBuffer());
        const assesmentResumeSection = this.getAssesmentResumeSection(campaigns,imgPending, imgPendingSign, imgInProgress, imgPendingReview, imgReviewed, imgDiscarded, imgAcknowledged, translations);
        const assesmentDetailsSection =  campaigns.map(c => this.getEvaluationDetailSection(c, questionaries.find(q => q.Id === c.questionaryId), imgPending, imgPendingSign, imgInProgress, imgPendingReview, imgReviewed, imgDiscarded, imgAcknowledged, translations));

        const thirdPartiesByCountryAndAssesmentChartSection = this.getThirdPartiesByCountryAndAssesmentChartSection(imgThirdPartyStatusByCountryAndAssesmentChart, translations);
        const thirdPartiesByCountryAndAssesmentDetailSection = this.getThirdPartiesByCountryAndAssesmentDetailsSection(campaigns, translations);
        
        const thirdPartiesBySectorAndAssesmentChartSection = this.getThirdPartiesBySectorAndAssesmentChartSection(imgThirdPartyStatusBySectorAndAssesmentChart, translations);
        const thirdPartiesBySectorAndAssesmentDetailSection = this.getThirdPartiesBySectorAndAssesmentDetailsSection(campaigns, translations);
        
        sections.push(riskboardChartSection, assesmentResumeSection);
        assesmentDetailsSection.forEach(a => sections.push(a));
        sections.push(thirdPartiesByCountryAndAssesmentChartSection);
        sections.push(thirdPartiesByCountryAndAssesmentDetailSection);
        sections.push(thirdPartiesBySectorAndAssesmentChartSection);
        sections.push(thirdPartiesBySectorAndAssesmentDetailSection);

        const docContent = <DocumentContent>{
            header: pageHeader,
            footer: pageFooter,
            sections: sections
        }

        return [`Riskallay-Riskboard-Report.pdf`,await templateGenerator(fonts, docContent, coverPage)];
    }

    async getReportFonts(): Promise<Font[]> {
        let fonts: Font[] = [];
        fonts.push(<Font>{font: await fetch('/assets/report/Roboto-Medium.ttf').then((res) => res.arrayBuffer()), name:'Roboto-Medium'});
        fonts.push(<Font>{font: await fetch('/assets/report/Roboto-Regular.ttf').then((res) => res.arrayBuffer()), name:'Roboto-Regular'});
        fonts.push(<Font>{font: await fetch('/assets/report/Roboto-Bold.ttf').then((res) => res.arrayBuffer()), name:'Roboto-Bold'});
        fonts.push(<Font>{font: await fetch('/assets/report/Poppins-SemiBold.ttf').then((res) => res.arrayBuffer()), name:'Poppins-SemiBold'});

        return fonts;
    }

    async getReportCoverPage(companyName: string, username: string, printDate: Date, evaluationNames: string[], translations: any): Promise<CoverPage> {
        let coverPage = <CoverPage>{content: [
                  <Content>{content:<Image>{ image: await fetch('/assets/report/background.png').then((res) => res.arrayBuffer()), height:842, width:595, customHeight:1.73},  posX:0, posY:0, isRelative: false},
                  <Content>{content:<Image>{ image: await fetch('/assets/report/coverImageRiskboard.png').then((res) => res.arrayBuffer()), height:398, width:515}, posX:40, posY:24, isRelative: false},
                  <Content>{content:<Image>{ image: await fetch('/assets/report/wave.png').then((res) => res.arrayBuffer()), height:842, width:595}, posX:0, posY:419, isRelative: false},
                  <Content>{content:<Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height:42, width:180}, posX:40, posY:454, isRelative: false},                  
                  <Content>{content:<Text>{text: translations.cover.report, font: 'Roboto-Medium', fontSize: 24, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 536, isRelative: false},
                  <Content>{content:<Text>{text: translations.cover.reportName, font: 'Poppins-SemiBold', fontSize: 40, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 40, posY: 585, isRelative: false},
                  <Content>{content:<Text>{text: translations.cover.intro, font: 'Roboto-Regular', 
                                fontSize: 10, fontColor: <ColorHex>{hexCode: '666666'}, lineHeight: 16, maxWidth: 335}, posX: 40, posY: 660, isRelative: false}                  
        ]};

        let pos = 692;

        for (let index = 0; index < evaluationNames.length; index++) {
            pos+=16;
            if(index<=4) {
                coverPage.content.push(<Content>{content:<Text>{text:` · ${evaluationNames[index]}`, font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{hexCode: '666666'}, lineHeight: 16, maxWidth: 335}, posX: 40, posY: pos, isRelative: false})
            } else {
                if(index == 5) {
                    coverPage.content.push(<Content>{content:<Text>{text:translations.cover.introEllipsis.replace('${numberOfEvaluations}', evaluationNames.length-5), font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{hexCode: '666666'}, lineHeight: 16, maxWidth: 335}, posX: 40, posY: pos, isRelative: false})
                }
            }
        }

        coverPage.content.push(<Content>{content:<Text>{text:translations.infoCollectedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 399, posY: 660, isRelative: false},
                               <Content>{content:<Text>{text: companyName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 399, posY: 670, isRelative: false},
                               <Content>{content:<Text>{text:translations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 399, posY: 690, isRelative: false},
                               <Content>{content:<Text>{text: username, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 399, posY: 700, isRelative: false},
                               <Content>{content:<Text>{text:translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 399, posY: 720, isRelative: false},
                               <Content>{content:<Text>{text:formatDate(printDate,'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{hexCode: '121212'}}, posX: 399, posY: 730, isRelative: false}
        );

        return coverPage;
    }

    async getPageHeader(translations: any): Promise<Header> {
        return <Header>{height:67,
            content: [<Content>{content:<Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height:31, width:133}, posX:40, posY:24, isRelative: false},
                      <Content>{content:<Text>{text:translations.confidential, font: 'Roboto-Medium', fontSize: 10, fontColor: <ColorHex>{hexCode: 'f8b410'}, lineHeight:16}, posX: 474, posY: 31.5, isRelative: false}
            ]
        };
    }

    getPageFooter(companyName: string, printDate: Date, username: string, translations: any): Footer {
        return <Footer>{height:71,
            content:[<Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, color:<ColorHex>{hexCode:'d9d9d9'}, width:515},  posX:40, posY:771, isRelative: false},
                     <Content>{content:<Text>{text:translations.infoCollectedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 783, isRelative: false},
                     <Content>{content:<Text>{text:translations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 796, isRelative: false},
                     <Content>{content:<Text>{text:translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{hexCode: '666666'}}, posX: 40, posY: 809, isRelative: false},
                     <Content>{content:<Text>{text:companyName, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}}, posX: 154, posY: 783, isRelative: false},
                     <Content>{content:<Text>{text:username, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}},  posX: 154, posY: 796, isRelative: false},
                     <Content>{content:<Text>{text:formatDate(printDate,'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}}, posX: 154, posY: 809, isRelative: false},
                     <Content>{content:<Text>{text:translations.pageNumber, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{hexCode: '8c8c8c'}, lineHeight:16}, posX: 522, posY: 783, isRelative: false},
            ]
        };
    }

    getRiskboardChartSection(imgAssesmentEvoChart: string, translations: any) {        
        return <Section>{
            isFullPage: false, 
            isAbsolute: true,
            content: [<Content>{content:<Text>{text: translations.riskboardChartSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 84, isRelative: false},                      
                      <Content>{content:<Chart>{height: 233, width: 515, margin: 7,chart: imgAssesmentEvoChart, header:<ChartHeader>{height:19, text:translations.riskboardChartSection.title, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:40,posY:16, isRelative: true},
            ]            
        };
    }

    getAssesmentResumeSection(campaigns: Campaign[], imgPending: ArrayBuffer, imgPendingSign: ArrayBuffer, imgInProgress: ArrayBuffer, imgPendingReview: ArrayBuffer, imgReviewed: ArrayBuffer, imgDiscarded: ArrayBuffer, imgAcknowledged: ArrayBuffer,translations: any): Section {
        const section = <Section> {
            isFullPage: false, 
            isAbsolute: false,
            content: [<Content>{content:<Text>{text:translations.assesmentResumeSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 16, isRelative: true}]
        };

        campaigns.map(campaign => 
            section.content.push(                
                <Content>{content: <Row> {rowContent: [
                    <Content>{content:<Text>{text: campaign.name, font:'Roboto-Bold', fontSize:10, lineHeight:12, fontColor:<ColorHex>{hexCode:'383838'}}, posX: 0, posY: 12, isRelative: true},
                    <Content>{content:<SvgPath>{ path: 'M 0,0 L 1,0 1,-12 0,-12 ', height:12, width: 1,color:<ColorHex>{hexCode:'fdc130'}, maxWidth:1},  posX:8, posY:12, isRelative: true},
                    <Content>{content:<Text>{text: campaign.thirdParties.length.toString(), font:'Roboto-Regular', fontSize:10, lineHeight:12, fontColor:<ColorHex>{hexCode:'383838'}}, posX: 8, posY: 12, isRelative: true},
                    <Content>{content:<SvgPath>{ path: 'M 0,0 L 1,0 1,-12 0,-12 ', height:12, width: 1,color:<ColorHex>{hexCode:'fdc130'}, maxWidth: 1},  posX:8, posY:12, isRelative: true},
                    <Content>{content:<Text>{text: `${formatDate(campaign.startDate,'dd/MM/yyyy HH:mm', this.locale)} - ${formatDate(campaign.endDate,'dd/MM/yyyy HH:mm', this.locale)}`, font:'Roboto-Regular', fontSize:10, lineHeight:12, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth:200}, posX: 8, posY: 12, isRelative: true},
                ]},posX:40, posY:12, isRelative: true},
                <Content>{content: <Row>{rowContent: [
                    <Content>{content:<Image>{image: imgPending, height:28, width:28}, posX:0, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.pending).length.toString(), maxWidth:72, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.pending, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-72, posY:29.6, isRelative: true},
                    <Content>{content:<Image>{image: imgInProgress, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.inProgress).length.toString(), maxWidth: 72, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-72, posY:29.6, isRelative: true},
                    <Content>{content:<Image>{image: imgPendingSign, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.pendingSign).length.toString(), maxWidth:72, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.pendingSign, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-72, posY:29.6, isRelative: true},
                    <Content>{content:<Image>{image: imgPendingReview, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.pendingReview).length.toString(), maxWidth:72, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.pendingReview, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-72, posY:29.6, isRelative: true},
                ]},posX:40, posY:12.5, isRelative: true},
                <Content>{content: <Row>{rowContent: [
                    <Content>{content:<Image>{image: imgAcknowledged, height:28, width:28}, posX:0, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.acknowledged).length.toString(), maxWidth:72, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.acknowledged, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-72, posY:29.6, isRelative: true},
                    <Content>{content:<Image>{image: imgDiscarded, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.discarded).length.toString(), maxWidth:72, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.discarded, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-72, posY:29.6, isRelative: true},
                    <Content>{content:<Image>{image: imgReviewed, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                    <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.reviewed).length.toString(), maxWidth:72, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                    <Content>{content:<Text>{text: translations.reviewed, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-72, posY:29.6, isRelative: true},
                ]},posX:40, posY:12.5, isRelative: true},
                <Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, width: 515,color:<ColorHex>{hexCode:'d9d9d9'}},  posX:40, posY:17, isRelative: true}
            )                  
        );

        return section;
    }

    getEvaluationDetailSection(campaign: Campaign, questionary: QuestionaryDto, imgPending: ArrayBuffer, imgPendingSign: ArrayBuffer, imgInProgress: ArrayBuffer, imgPendingReview: ArrayBuffer, imgReviewed: ArrayBuffer, imgDiscarded: ArrayBuffer, imgAcknowledged: ArrayBuffer, translations: any): Section {
        return <Section>{
            isFullPage: false, 
            isAbsolute: false,
            content: [<Content>{content:<Text>{text: campaign.name, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'},underline:<TextUnderline>{color:<ColorHex>{hexCode:'fdc130'},thickness:2}}, posX: 40, posY: 12, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Image>{image: imgPending, height:28, width:28}, posX:0, posY:12.5, isRelative: true},
                        <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.pending).length.toString(), maxWidth:60, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-60, posY:29.6, isRelative: true},
                        <Content>{content:<Image>{image: imgInProgress, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                        <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.inProgress).length.toString(), maxWidth:60, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-60, posY:29.6, isRelative: true},
                        <Content>{content:<Image>{image: imgPendingSign, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                        <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.pendingSign).length.toString(), maxWidth:60, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingSign, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-60, posY:29.6, isRelative: true},
                        <Content>{content:<Image>{image: imgPendingReview, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                        <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.pendingReview).length.toString(), maxWidth:60, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingReview, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-60, posY:29.6, isRelative: true},
                      ]},posX:40, posY:12.5, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Image>{image: imgAcknowledged, height:28, width:28}, posX:0, posY:12.5, isRelative: true},
                        <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.acknowledged).length.toString(), maxWidth:60, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                        <Content>{content:<Text>{text: translations.acknowledged, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-60, posY:29.6, isRelative: true},
                        <Content>{content:<Image>{image: imgDiscarded, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                        <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.discarded).length.toString(), maxWidth:60, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                        <Content>{content:<Text>{text: translations.discarded, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-60, posY:29.6, isRelative: true},
                        <Content>{content:<Image>{image: imgReviewed, height:28, width:28}, posX:15, posY:12.5, isRelative: true},
                        <Content>{content:<Text>{text: campaign.thirdParties.filter(f => f.CampaignStatus == CampaignStatus.reviewed).length.toString(), maxWidth:60, font:'Poppins-SemiBold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:17}, posX:6.6, posY:12.6, isRelative: true},
                        <Content>{content:<Text>{text: translations.reviewed, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'}, lineHeight:10},  posX:-60, posY:29.6, isRelative: true},
                      ]},posX:40, posY:12.5, isRelative: true},                    
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: 'Detalles', font:'Roboto-Bold', fontSize:16, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:72, lineHeight:19},  posX:0, posY:12.5, isRelative: true},
                        <Content>{content:<Text>{text: translations.evaluationDetailSection.selectedChecklist, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:275, lineHeight:9},  posX:18, posY:12.5, isRelative: true},
                        <Content>{content:<Text>{text: questionary.Code, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:275, lineHeight:12},  posX:-275, posY:25.5, isRelative: true}
                      ]},posX:40, posY:12.5, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: translations.evaluationDetailSection.questionNumber, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:125.7, lineHeight:9},  posX:90, posY:16, isRelative: true},
                        <Content>{content:<Text>{text: translations.evaluationDetailSection.assesedThirds, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:125.7, lineHeight:9},  posX:24, posY:16, isRelative: true},
                        <Content>{content:<Text>{text: translations.evaluationDetailSection.amount, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:125.7, lineHeight:9},  posX:24, posY:16, isRelative: true}
                      ]},posX:40, posY:16, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: questionary.QuestionaryBlocks.reduce((sum, f) => sum + f.Questions.length, 0).toString(), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:125.7, lineHeight:12},  posX:90, posY:4, isRelative: true},                      
                        <Content>{content:<Text>{text: campaign.thirdParties.length.toString(), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:125.7, lineHeight:12},  posX:24, posY:4, isRelative: true},                      
                        <Content>{content:<Text>{text: campaign.amount.toString(), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:125.7, lineHeight:12},  posX:24, posY:4, isRelative: true}
                      ]},posX:40, posY:4, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: translations.evaluationDetailSection.startDate, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:200.5, lineHeight:9},  posX:90, posY:16, isRelative: true},
                        <Content>{content:<Text>{text: translations.evaluationDetailSection.endDate, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:200.5, lineHeight:9},  posX:24, posY:16, isRelative: true}
                      ]},posX:40, posY:16, isRelative: true},
                      <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: formatDate(campaign.startDate,'dd/MM/yyyy - HH:mm', this.locale), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:200.5, lineHeight:12},  posX:90, posY:4, isRelative: true},                      
                        <Content>{content:<Text>{text: formatDate(campaign.endDate,'dd/MM/yyyy - HH:mm', this.locale), font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:200.5, lineHeight:12},  posX:24, posY:4, isRelative: true}
                      ]},posX:40, posY:4, isRelative: true},                      
                      <Content>{content:<Text>{text: translations.evaluationDetailSection.remarks, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:425, lineHeight:9},  posX:130, posY:16, isRelative: true},
                      <Content>{content:<Text>{text: campaign.introduction, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:425, lineHeight:12},  posX:130, posY:4, isRelative: true},
                      <Content>{content:<Text>{text: translations.evaluationDetailSection.remarks, font:'Roboto-Bold', fontSize:8, fontColor:<ColorHex>{hexCode:'8c8c8c'},maxWidth:425, lineHeight:9},  posX:130, posY:16, isRelative: true},
                      <Content>{content:<Text>{text: campaign.fileName, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'},maxWidth:425, lineHeight:12},  posX:130, posY:4, isRelative: true},
                   ]
        };
    }

    getThirdPartiesByCountryChartSection(imgThirdPartyByCountryChart: string, translations: any) {
        return <Section>{
            isFullPage: false, 
            isAbsolute: true,
            content: [<Content>{content:<Text>{text: translations.thirdsByCountryChartSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 84, isRelative: false},                      
                      <Content>{content:<Chart>{height: 233, width: 515, margin: 7,chart: imgThirdPartyByCountryChart, header:<ChartHeader>{height:19, text:translations.thirdsByCountryChartSection.title, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:40,posY:16, isRelative: true},                  
            ]            
        };
    }

    getThirdPartiesByCountryDetailsSection(campaigns: Campaign[], translations: any): Section {
        const groupedData = campaigns.reduce((rv, c) =>
            c.thirdParties.reduce(function(rv, t) {
                let index = rv.findIndex(g => g.category === t.Country.Description);
                if(index < 0) {
                    rv.push({category: t.Country.Description, value: 1, thirdParties: [t.Id]});
                } else {
                    if(rv[index].thirdParties.findIndex(tp => t.Id === tp) < 0) {
                    rv[index].value++;
                    rv[index].thirdParties.push(t.Id);
                    } 
                }
                return rv;
            }, rv), []);

        let section = <Section> {
            isFullPage: false, 
            isAbsolute: false,
            content: [<Content>{content:<Text>{text:translations.thirdsByCountryDetailSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 32, isRelative: true},                      
                                            
            ]            
        };

        const rowsToPrint = Math.ceil(groupedData.length / 2);

        for (let index = 0; index < rowsToPrint; index++) {
            const rowData = groupedData.splice(0,2);
            if(rowData.length > 1) {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [                    
                        <Content>{content:<Text>{text: rowData[0].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:40, posY:12, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].value} ${translations.thirds}`, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 68},  posX:8, posY:12, isRelative: true},
                    ]},posX:0, posY:12, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: rowData[1].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:300.5, posY:-12, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].value} ${translations.thirds}`, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 68},  posX:8, posY:-12, isRelative: true},
                    ]},posX:0, posY:12, isRelative: true}
                );
            } else {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [                    
                        <Content>{content:<Text>{text: rowData[0].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:40, posY:12, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].value} ${translations.thirds}`, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 68},  posX:8, posY:12, isRelative: true}
                    ]},posX:0, posY:12, isRelative: true}
                );
            }
            section.content.push(<Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, width: 515,color:<ColorHex>{hexCode:'d9d9d9'}},  posX:40, posY:8, isRelative: true});
        }       
        
        return section;
    }

    getThirdPartiesByCountryAndAssesmentChartSection(imgThirdPartyStatusByCountryAndAssesmentChart: string, translations: any) {
        return <Section>{
            isFullPage: false, 
            isAbsolute: true,
            content: [<Content>{content:<Text>{text: translations.thirdsByCountryAndAssesmentChartSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 84, isRelative: false},                      
                      <Content>{content:<Chart>{height: 233, width: 515, margin: 7,chart: imgThirdPartyStatusByCountryAndAssesmentChart, header:<ChartHeader>{height:19, text: translations.thirdsByCountryAndAssesmentChartSection.title, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:40,posY:16, isRelative: true},                  
            ]            
        };
    }

    getThirdPartiesByCountryAndAssesmentDetailsSection(campaigns: Campaign[], translations: any): Section {
        const dotSvgPath = 'M 4, 0 m -4, 0 a 6,6 0 1,0 12,0 a 6,6 0 1,0 -12,0';
        const groupedData = campaigns.reduce((rv, c) =>
        c.thirdParties.reduce(function(rv, t) {
            let index = rv.findIndex(g => g.category === t.Country.Description);
            if(index < 0) {
                rv.push({
                    category: t.Country.Description,
                    reviewedThirdParties: 0,
                    pendingReviewThirdParties: 0,
                    pendingSignThirdParties: 0,
                    inProgressThirdParties: 0,
                    pendingResponseThirdParties: 0,
                    discardedThirdParties: 0,
                    ackThirdParties: 0
                });
                index = rv.length-1;
            }
            switch (t.CampaignStatus) {
                case CampaignStatus.reviewed:
                    rv[index].reviewedThirdParties++;    
                    break;
                case CampaignStatus.pendingReview:
                    rv[index].pendingReviewThirdParties++;    
                    break;
                case CampaignStatus.inProgress:
                    rv[index].inProgressThirdParties++;    
                    break;
                case CampaignStatus.pendingSign:
                    rv[index].pendingSignThirdParties++;
                    break;
                case CampaignStatus.pending:
                    rv[index].pendingResponseThirdParties++;
                    break;
                case CampaignStatus.discarded:
                    rv[index].discardedThirdParties++;
                    break;
                    case CampaignStatus.acknowledged:
                        rv[index].ackThirdParties++;
                        break;
                default:
                    break;
            }
            return rv;
        }, rv), []);

        let section = <Section> {
            isFullPage: false, 
            isAbsolute: false,
            content: [<Content>{content:<Text>{text:translations.thirdsByCountryAndAssesmentDetailSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 32, isRelative: true},                      
                                          
            ]            
        };

        const rowsToPrint = Math.ceil(groupedData.length / 2);

        for (let index = 0; index < rowsToPrint; index++) {
            const rowData = groupedData.splice(0,2);
            if(rowData.length > 1) {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [                    
                        <Content>{content:<Text>{text: rowData[0].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:40, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].reviewedThirdParties + rowData[0].pendingReviewThirdParties + rowData[0].inProgressThirdParties + rowData[0].pendingResponseThirdParties + rowData[0].pendingSignThirdParties + rowData[0].ackThirdParties} ${translations.thirds}`, font:'Roboto-Regular', maxWidth: 68, fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: rowData[1].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:24.5, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].reviewedThirdParties + rowData[1].pendingReviewThirdParties + rowData[1].inProgressThirdParties + rowData[1].pendingResponseThirdParties + rowData[1].pendingSignThirdParties + rowData[1].ackThirdParties} ${translations.thirds}`, maxWidth: 68, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true}
                    ]},posX:0, posY:8, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'AEBE08'}},  posX:40, posY:6, isRelative: true},                        
                        <Content>{content:<Text>{text: translations.reviewed, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].reviewedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'AEBE08'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.reviewed, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].reviewedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:40, posY:6, isRelative: true},                        
                        <Content>{content:<Text>{text: translations.acknowledged, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].ackThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.acknowledged, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].ackThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A30000'}},  posX:40, posY:6, isRelative: true},                        
                        <Content>{content:<Text>{text: translations.discarded, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].discardedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A30000'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.discarded, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].discardedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'F8B410'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingReview, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingReviewThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'F8B410'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingReview, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].pendingReviewThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'1F2D61'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingSign, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingSignThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'1F2D61'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingSign, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].pendingSignThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingResponseThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].pendingResponseThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );
            } else {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [                    
                        <Content>{content:<Text>{text: rowData[0].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, maxWidth: 168, lineHeight:12}, posX:40, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].reviewedThirdParties + rowData[0].pendingReviewThirdParties + rowData[0].inProgressThirdParties + rowData[0].pendingResponseThirdParties + rowData[0].pendingSignThirdParties + rowData[0].ackThirdParties} ${translations.thirds}`, font:'Roboto-Regular', maxWidth: 68, fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true}
                    ]},posX:0, posY:8, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'AEBE08'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.reviewed, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].reviewedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.acknowledged, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].ackThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A30000'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.discarded, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].discardedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'F8B410'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingReview, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingReviewThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'1F2D61'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingSign, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingSignThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingResponseThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );
            }

            section.content.push(<Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, width: 515,color:<ColorHex>{hexCode:'d9d9d9'}},  posX:40, posY:10, isRelative: true});
        }       
        
        return section;
    }

    getThirdPartiesBySectorChartSection(imgThirdPartyBySectorChart: string, translations: any) {
        return <Section>{
            isFullPage: false, 
            isAbsolute: true,
            content: [<Content>{content:<Text>{text:translations.thirdsBySectorChartSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 84, isRelative: false},                      
                      <Content>{content:<Chart>{height: 233, width: 515, margin: 7,chart: imgThirdPartyBySectorChart, header:<ChartHeader>{height:19, text: translations.thirdsBySectorChartSection.title, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:40,posY:16, isRelative: true},                  
            ]            
        };
    } 

    getThirdPartiesBySectorDetailsSection(campaigns: Campaign[], translations: any): Section {
        const groupedData = campaigns.reduce((rv, c) =>
            c.thirdParties.reduce(function(rv, t) {
                let index = rv.findIndex(g => g.category === t.ThirdPartyConfig.Sector.Description);
                if(index < 0) {
                    rv.push({category: t.ThirdPartyConfig.Sector.Description, value: 1, thirdParties: [t.Id]});
                } else {
                    if(rv[index].thirdParties.findIndex(tp => t.Id === tp) < 0) {
                        rv[index].value++;
                        rv[index].thirdParties.push(t.Id);
                    }  
                }
                return rv;
        }, rv), []);

        let section = <Section> {
            isFullPage: false, 
            isAbsolute: false,
            content: [<Content>{content:<Text>{text: translations.thirdsBySectorDetailSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 32, isRelative: true},                      
                                          
            ]            
        };

        const rowsToPrint = Math.ceil(groupedData.length / 2);

        for (let index = 0; index < rowsToPrint; index++) {
            const rowData = groupedData.splice(0,2);
            if(rowData.length > 1) {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [                    
                        <Content>{content:<Text>{text: rowData[0].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:40, posY:12, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].value} ${translations.thirds}`, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 68},  posX:8, posY:12, isRelative: true}
                    ]},posX:0, posY:12, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<Text>{text: rowData[1].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:300.5, posY:-12, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].value} ${translations.thirds}`, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 68},  posX:8, posY:-12, isRelative: true},
                    ]},posX:0, posY:12, isRelative: true}
                );
            } else {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [                    
                        <Content>{content:<Text>{text: rowData[0].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:40, posY:12, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].value} ${translations.thirds}`, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 68},  posX:8, posY:12, isRelative: true}
                    ]},posX:0, posY:12, isRelative: true}
                );
            }

            section.content.push(<Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, width: 515,color:<ColorHex>{hexCode:'d9d9d9'}},  posX:40, posY:8, isRelative: true});
        }       
        
        return section;
    }

    getThirdPartiesBySectorAndAssesmentChartSection(imgThirdPartyStatusBySectorAndAssesmentChart: string, translations: any): Section {
        
        return <Section>{
            isFullPage: false, 
            isAbsolute: true,
            content: [<Content>{content:<Text>{text: translations.thirdsBySectorAndAssesmentChartSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 84, isRelative: false},                      
                      <Content>{content:<Chart>{height: 233, width: 515, margin: 7,chart: imgThirdPartyStatusBySectorAndAssesmentChart, header:<ChartHeader>{height:19, text: translations.thirdsBySectorAndAssesmentChartSection.title, font:'Roboto-Bold',fontColor:<ColorHex>{hexCode:'000000'},fontSize:12,lineHeight:19,marginLeft:12,borderColor:<ColorHex>{hexCode:'000000'},fillColor:<ColorHex>{hexCode:'ffffff'}}},posX:40,posY:16, isRelative: true},                  
            ]            
        };
    } 

    getThirdPartiesBySectorAndAssesmentDetailsSection(campaigns: Campaign[], translations: any): Section {
        const dotSvgPath = 'M 4, 0 m -4, 0 a 6,6 0 1,0 12,0 a 6,6 0 1,0 -12,0';
        const groupedData = campaigns.reduce((rv, c) =>
            c.thirdParties.reduce(function(rv, t) {
                let index = rv.findIndex(g => g.category === t.ThirdPartyConfig.Sector.Description);
                if(index < 0) {
                    rv.push({
                        category: t.ThirdPartyConfig.Sector.Description, 
                        reviewedThirdParties: 0,
                        pendingReviewThirdParties: 0,
                        pendingSignThirdParties: 0,
                        inProgressThirdParties: 0,
                        pendingResponseThirdParties: 0,
                        discardedThirdParties: 0,
                        ackThirdParties: 0
                    });
                    index = rv.length-1;
                }
                switch (t.CampaignStatus) {
                    case CampaignStatus.reviewed:
                        rv[index].reviewedThirdParties++;    
                        break;
                    case CampaignStatus.pendingReview:
                        rv[index].pendingReviewThirdParties++;    
                        break;
                    case CampaignStatus.inProgress:
                        rv[index].inProgressThirdParties++;    
                        break;
                    case CampaignStatus.pendingSign:
                        rv[index].pendingSignThirdParties++;
                        break;
                    case CampaignStatus.pending:
                        rv[index].pendingResponseThirdParties++;
                        break;
                    case CampaignStatus.discarded:
                        rv[index].discardedThirdParties++;
                        break;
                    case CampaignStatus.acknowledged:
                        rv[index].ackThirdParties++;
                        break;
                    default:
                        break;
                }
                return rv;
            }, rv), []);

        let section = <Section> {
            isFullPage: false, 
            isAbsolute: false,
            content: [<Content>{content:<Text>{text: translations.thirdsBySectorAndAssesmentDetailSection.title, font:'Roboto-Bold', fontSize:16, lineHeight:24, fontColor:<ColorHex>{hexCode:'121212'}}, posX: 40, posY: 32, isRelative: true},                      
                                          
            ]            
        };

        const rowsToPrint = Math.ceil(groupedData.length / 2);

        for (let index = 0; index < rowsToPrint; index++) {
            const rowData = groupedData.splice(0,2);
            if(rowData.length > 1) {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [                    
                        <Content>{content:<Text>{text: rowData[0].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:40, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].reviewedThirdParties + rowData[0].pendingReviewThirdParties + rowData[0].inProgressThirdParties + rowData[0].pendingResponseThirdParties + rowData[0].pendingSignThirdParties + rowData[0].ackThirdParties} ${translations.thirds}`, maxWidth: 68, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: rowData[1].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:24.5, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].reviewedThirdParties + rowData[1].pendingReviewThirdParties + rowData[1].inProgressThirdParties + rowData[1].pendingResponseThirdParties + rowData[1].pendingSignThirdParties + rowData[1].ackThirdParties} ${translations.thirds}`, maxWidth: 68, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true},
                    ]},posX:0, posY:8, isRelative: true}
                );                

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'AEBE08'}},  posX:40, posY:6, isRelative: true},                        
                        <Content>{content:<Text>{text: translations.reviewed, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].reviewedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'AEBE08'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.reviewed, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].reviewedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:40, posY:6, isRelative: true},                        
                        <Content>{content:<Text>{text: translations.acknowledged, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].ackThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.acknowledged, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].ackThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A30000'}},  posX:40, posY:6, isRelative: true},                        
                        <Content>{content:<Text>{text: translations.discarded, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].discardedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A30000'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.discarded, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].discardedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'F8B410'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingReview, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingReviewThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'F8B410'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingReview, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].pendingReviewThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'1F2D61'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingSign, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingSignThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'1F2D61'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingSign, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].pendingSignThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingResponseThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true},
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:14, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:168}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[1].pendingResponseThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9, maxWidth:68},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );
            } else {
                section.content.push(
                    <Content>{content: <Row>{rowContent: [                    
                        <Content>{content:<Text>{text: rowData[0].category, font:'Roboto-Bold', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12, maxWidth: 168}, posX:40, posY:8, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].reviewedThirdParties + rowData[0].pendingReviewThirdParties + rowData[0].inProgressThirdParties + rowData[0].pendingResponseThirdParties + rowData[0].pendingSignThirdParties + rowData[0].ackSignThirdParties} ${translations.thirds}`, maxWidth: 68, font:'Roboto-Regular', fontSize:10, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:12},  posX:8, posY:8, isRelative: true}
                    ]},posX:0, posY:8, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'AEBE08'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.reviewed, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].reviewedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'64C700'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.acknowledged, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].ackThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A30000'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.discarded, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].discardedThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'F8B410'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingReview, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingReviewThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'1F2D61'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pendingSign, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingSignThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'7881A0'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.inProgress, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].inProgressThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );

                section.content.push(
                    <Content>{content: <Row>{rowContent: [
                        <Content>{content:<SvgPath>{ path: dotSvgPath, height:8, width: 8,color:<ColorHex>{hexCode:'A6ADC4'}},  posX:40, posY:6, isRelative: true},
                        <Content>{content:<Text>{text: translations.pending, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9}, posX:8, posY:7, isRelative: true},
                        <Content>{content:<Text>{text: `${rowData[0].pendingResponseThirdParties}`, font:'Roboto-Regular', fontSize:8, fontColor:<ColorHex>{hexCode:'383838'}, lineHeight:9},  posX:4, posY:7, isRelative: true}
                    ]},posX:0, posY:7, isRelative: true}
                );
            }

            section.content.push(<Content>{content:<SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height:1, width: 515,color:<ColorHex>{hexCode:'d9d9d9'}},  posX:40, posY:10, isRelative: true});
        }       
        
        return section;        
    }
}