import { DataSource } from "@angular/cdk/collections";
import { QueryResult } from "@app/models/common/queryResult";
import { ThirdParty } from "@app/models/ThirdParty";
import { ThirdPartiesService } from "@app/services/third-parties.services";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";

export class ThirdPartiesDataSource implements DataSource<ThirdParty> {
  private thirdPartiesSubject = new BehaviorSubject<ThirdParty[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalItemsSubject = new BehaviorSubject<number>(0);
  private connectionErrorSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalItems$ = this.totalItemsSubject.asObservable();
  public connectionError$ = this.connectionErrorSubject.asObservable();

  constructor(private thirdPartiesService: ThirdPartiesService) { }

  connect(): Observable<ThirdParty[]> {
    return this.thirdPartiesSubject.asObservable();
  }

  disconnect(): void {
    this.thirdPartiesSubject.complete();
    this.loadingSubject.complete();
    this.totalItemsSubject.complete();
    this.connectionErrorSubject.complete();
  }

  loadThirdParties(
    searchWord: string,
    sortBy: string,
    isSortAscending: boolean,
    page: number,
    thirdPartyTypeId: number | null,
    pageSize: number,
    statusList: number[],
    inherentRiskList: number[]
  ) {
    this.loadingSubject.next(true);

    this.thirdPartiesService
      .getThirdPartiesFiltered(searchWord, sortBy, isSortAscending, page, thirdPartyTypeId, pageSize, statusList, inherentRiskList)
      .pipe(
        catchError(() => {
            this.connectionErrorSubject.next(true);
            return of([]);
        }),
        finalize(() => {
            this.loadingSubject.next(false);
        })
      )
      .subscribe(thirdParties => {
        this.thirdPartiesSubject.next((thirdParties as QueryResult<ThirdParty>).items);
        this.totalItemsSubject.next((thirdParties as QueryResult<ThirdParty>).totalItems);
      });
  }
}
