<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
  <header class="c-dialog__header">
    <h1 id="titleDialog" class="c-dialog__title">
      {{ "RefinitivSearchLimit.header" | translate }}
    </h1>
    <button
      mat-button
      (click)="closeModal()"
      class="c-dialog__close"
      attr.aria-label="{{ 'RefinitivSearchLimit.close' | translate }}"
    >
      <span aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
          <path
            d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
            transform="translate(-309.86-406.47)"
          />
        </svg>
      </span>
    </button>
  </header>
  <div class="c-dialog__content">
    <h2 class="c-dialog__subtitle">
      {{ "RefinitivSearchLimit.title" | translate }}
    </h2>
    <p class="info-text">{{ "RefinitivSearchLimit.subtitle" | translate }}</p>
    <footer class="c-dialog__footer no-paddingH u-mt-1medium u-pb-0">
      <mat-dialog-actions>
        <button
          class="c-button c-button--light"
          mat-button
          (click)="closeModal()"
        >
          {{ "RefinitivSearchLimit.close" | translate }}
        </button>
      </mat-dialog-actions>
    </footer>
  </div>
</div>
