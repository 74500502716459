import { RemoteService } from './remote.service';
import { Injectable, Inject } from '@angular/core';
import { ThirdParty, ThirdPartyCreateDTO } from '../models/ThirdParty';
import { BulkInsertionResultDto, BulkLoadDTO } from '@app/models/BulkLoad';
import { HttpClient } from '@angular/common/http';
import { ThirdPartiesProxyClient } from 'src/proxy/apiThirdParty/third-party.proxy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapThirdPartyDtoToThirdParty } from './mappings/third-party.mappings';
import { TranslateService } from '@ngx-translate/core';
import { QueryResult } from '@app/models/common/queryResult';
import { ThirdPartyStatusHistoryRecord } from '@app/models/ThirdPartyStatusHistoryRecord';
import { mapThirdPartyStatusRecordFromDto } from './mappings/third-party-status-history-record.mapping';
import { ThirdPartyStatus } from '@app/models/ThirdPartyStatus';
import { ThirdPartyConfigurationRequest } from '@app/models/ThirdPartyConfigurationRequest';
import {
  mapTPConfigRequestDtoToTPConfigRequest,
  mapTPConfigRequestToTPConfigRequestDto,
} from './mappings/third-party-configuration-request.mapping';
import { mapThirdPartyFile } from './mappings/third-party-file.mappings';
import { ThirdPartyFile } from '@app/models/ThirdPartyFile';
import { ThirdPartyFileDto } from 'src/proxy/model/thirdPartyFileDto';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartiesService {
  constructor(
    private remoteService: RemoteService,
    private http: HttpClient,
    private translate: TranslateService,
    private thirdPartiesProxy: ThirdPartiesProxyClient,
    @Inject('API_URL') public serverUrl: string
  ) {}

  private apiURL = 'ThirdParties';

  public getAllThirdParties(): Observable<ThirdParty[]> {
    return this.thirdPartiesProxy
      .thirdPartiesGet()
      .pipe(
        map((tps) =>
          tps.map((tp) =>
            mapThirdPartyDtoToThirdParty(tp, this.translate.currentLang)
          )
        )
      );
  }

  public getThirdPartiesFiltered(
    searchWord: string,
    sortBy: string,
    isSortAscending: boolean,
    page: number,
    thirdPartyTypeId: number | null,
    pageSize: number,
    statusList: number[],
    inherentRiskList: number[]
  ): Observable<QueryResult<ThirdParty>> {
    return this.thirdPartiesProxy
      .thirdPartiesFilteredGet(
        searchWord,
        sortBy,
        isSortAscending,
        page,
        thirdPartyTypeId,
        pageSize,
        statusList,
        inherentRiskList
      )
      .pipe(
        map((r) => {
          return <QueryResult<ThirdParty>>{
            totalItems: r.TotalItems,
            items: r.Items.map((c) =>
              mapThirdPartyDtoToThirdParty(c, this.translate.currentLang)
            ),
          };
        })
      );
  }

  public createThirdParties(thirdParties: ThirdPartyCreateDTO) {
    return this.remoteService.postRequest(this.apiURL, thirdParties);
  }

  public getThirdPartiesById(id: string) {
    return this.remoteService.getRequest(`${this.apiURL}/${id}`);
  }

  public updateThirdParties(id: number, thirdParties: ThirdPartyCreateDTO) {
    return this.remoteService.putRequest(`${this.apiURL}/${id}`, thirdParties);
  }

  public checkThirdPartiesExcel(bulkLoad: BulkLoadDTO) {
    const formData = this.ConstruirFormDataCargaMasiva(bulkLoad);
    return this.http.post<BulkInsertionResultDto>(
      `${this.serverUrl}/${this.apiURL}/BulkLoad`,
      formData
    );
  }

  public GetAllAvailableThirdPartiesByCampaign(idCampaign: string) {
    return this.remoteService.getRequest(
      `${this.apiURL}/GetAllAvailableThirdPartiesByCampaign/${idCampaign}`
    );
  }

  public deleteThirdParty(id: string) {
    return this.http.delete(`${this.serverUrl}/${this.apiURL}/${id}`);
  }

  public getThirdPartyFiles(id: string) {
    return this.remoteService.getRequest(
      `${this.apiURL}/GetThirdPartyFiles/${id}`
    );
  }

  private ConstruirFormDataCargaMasiva(bulkLoad: BulkLoadDTO): FormData {
    const formData = new FormData();

    formData.append('UserResponsibleId', String(bulkLoad.UserResponsibleId));
    formData.append('Save', String(bulkLoad.Save));
    if (bulkLoad.File) {
      formData.append('File', bulkLoad.File);
    }

    return formData;
  }

  public getThirdPartyStatusHistoryRecords(): Observable<
    ThirdPartyStatusHistoryRecord[]
  > {
    return this.thirdPartiesProxy
      .thirdPartiesGetThirdPartiesStatusEvolutionGet()
      .pipe(map((r) => r.map((dto) => mapThirdPartyStatusRecordFromDto(dto))));
  }

  public bulkUpdateStatus(
    thirdPartyIds: number[],
    status: ThirdPartyStatus
  ): Observable<boolean> {
    return this.thirdPartiesProxy.thirdPartiesBulkUpdateStatusPost({
      thirdPartyIds,
      status,
    });
  }

  public hasPendingConfigRequest(): Observable<ThirdPartyConfigurationRequest> {
    return this.thirdPartiesProxy
      .thirdPartiesHasPendingConfigRequestGet()
      .pipe(map((cr) => mapTPConfigRequestDtoToTPConfigRequest(cr)));
  }

  public completeConfigRequest(
    configRequest: ThirdPartyConfigurationRequest
  ): Observable<boolean> {
    return this.thirdPartiesProxy.thirdPartiesCompleteConfigRequestPost(
      mapTPConfigRequestToTPConfigRequestDto(configRequest)
    );
  }

  public getThirdPartyFilesById(id: number): Observable<ThirdPartyFile[]> {
    return this.thirdPartiesProxy
      .thirdPartiesGetThirdPartyFilesIdThirdPartyGet(id)
      .pipe(
        map((f: ThirdPartyFileDto[]) =>
          f.map((tpf: ThirdPartyFileDto) => mapThirdPartyFile(tpf))
        )
      );
  }

  public getAttachFileUri(id: number, filename: string): Observable<string> {
    return this.thirdPartiesProxy.thirdPartiesAttachFileThirdPartyIdFilenameGet(
      id,
      filename
    );
  }
}
