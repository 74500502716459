<ng-container *ngIf="token; else noToken">
  <div class="row">
    <div class="col-lg-3">
      <p id="apiToken" class="c-fields__legend">
        API Token
      </p>
      <mat-form-field floatLabel="never" style="display: block;">
        <input type="password" matInput name="api-secret" [value]="token" id="api-secret" class="c-form__input"
          placeholder="" aria-labelledby="apiToken" disabled />
      </mat-form-field>
    </div>
    <div class="col-lg-3">
      <button type="button" [cdkCopyToClipboard]="token" style="margin-top: 2.5rem;" class="c-button c-button--gradient">
        {{ 'apiAccess.copyToClipboard' | translate }}
      </button>
    </div>
  </div>
</ng-container>

<ng-template #noToken>
  <p class="o-text"> {{ 'apiAccess.noToken' | translate }} </p>
</ng-template>
