import { DocumentDto } from 'src/proxy/model/documentDto';
import { Document } from '../../models/GesintelScreening/Document';

export function mapDocument(dto: DocumentDto): Document {
  if (!dto) return null;

  return <Document>{
    type: dto.Type,
    number: dto.Number,
    comments: dto.Comments,
  };
}
