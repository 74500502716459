import { Component, Input, OnInit } from "@angular/core";
import { CampaignThirdParty } from "@app/models/CampaignThirdParty";
import { Role } from "@app/models/Role";
import { canAccess } from "@app/security/riskallay.roles";
import { AuthenticationService } from "@app/services/authentication.service";
import { CampaignThirdPartyService } from "@app/services/campaign-third-party.service";
import { sort } from "@app/utilities/utilities";

@Component({
  selector: 'app-third-party-campaigns-table',
  templateUrl: './third-party-campaigns-table.component.html',
  styleUrls: ['./third-party-campaigns-table.component.scss']
})
export class ThirdPartyCampaignsTableComponent implements OnInit {

  @Input() thirdPartyId: number;

  pageSize: number = 5;
  page: number = 1;
  totalCTPs: number = 0;
  filteredCTPs: CampaignThirdParty[];
  sortingColumn: string = 'startDate';
  isSortAscending: boolean = false;
  filterText: string = '';
  campaignThirdParties: CampaignThirdParty[];

  tableColumns: string[] = ['campaignName', 'startDate', 'endDate', 'status', 'progress', 'score', 'responsible']

  constructor(
    private campaignThirdPartyService: CampaignThirdPartyService,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    const userRoles = this.authenticationService.getRoles();

    if(canAccess([Role.Owner, Role.Manager, Role.Auditor, Role.Assistant], userRoles)) {
      this.tableColumns.push('actions')
    }
    this.campaignThirdPartyService.getCampaignThirdPartiesByThirdParty(this.thirdPartyId).subscribe(r => {
      this.campaignThirdParties = r;
      this.getData();
    });
  }

  getData() {
    let filtered = this.campaignThirdParties?.filter(ctp => this.filterFunction(ctp)).sort((a, b) => sort(a, b, this.sortingColumn, this.isSortAscending)) ?? []
    this.filteredCTPs = filtered.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    this.totalCTPs = filtered.length;
  }

  sortBy(columnName) {
    this.page = 1;
    this.sortingColumn = columnName.active;
    this.isSortAscending = columnName.direction === 'asc'
    this.getData();
  }

  onFilter(text: string): void {
    this.page = 1;
    this.filterText = text.toLowerCase();
    this.getData();
  }

  onPageChange(page) {
    this.page = page.pageIndex + 1;
    this.pageSize = page.pageSize;
    this.getData();
  }

  filterFunction(ctp: CampaignThirdParty): boolean {
    if (this.filterText == '') return true;

    return String(ctp.thirdPartyName).toLowerCase().includes(this.filterText)
      || String(ctp.campaignName).toLowerCase().includes(this.filterText);
  }
}
