import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.scss']
})
export class MultipleChoiceComponent implements OnInit {

  @Input() rawOptions: string;
  @Input() blockIndex: number;
  @Input() questionIndex: number;

  options: any[] = [];
  form: FormGroup;
  questionForm: FormGroup;

  constructor(public rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    this.questionForm = this.form.get(`bloques.${this.blockIndex}`).get(`Questions.${this.questionIndex}`) as FormGroup;
    this.questionForm.get('SelectedOption').enable();
    this.options = JSON.parse(this.rawOptions);
  }

}
