import { HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Questionary } from "@app/models/Questionary";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { QuestionariesProxyClient } from "src/proxy/apiQuestionaries/questionaries.proxy";
import { mapQuestionaryDtoToQuestionary, mapQuestionaryToQuestionaryUpdateDto } from "./mappings/questionary.mappings";

@Injectable({
    providedIn: 'root'
})
export class QuestionaryService {
    constructor(
        private questionariesClient: QuestionariesProxyClient,
        private translate: TranslateService
    ) { }

    getQuestionaryById(id: number): Observable<Questionary> {
        return this.questionariesClient.questionariesIdGet(id)
            .pipe(
                map(questionary => mapQuestionaryDtoToQuestionary(questionary, this.translate.currentLang))
            );
    }
    
    putQuestionaryById(id: number, questionary: Questionary): Observable<HttpEvent<any>> {
        return this.questionariesClient.questionariesIdPut(id, mapQuestionaryToQuestionaryUpdateDto(questionary));
    }

    isQuestionaryInUse(id: number): Observable<boolean> {
        return this.questionariesClient.questionariesIsQuestionaryInUseQuestionaryIdGet(id);
    }
}