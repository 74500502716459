import { Component, Input, OnChanges } from '@angular/core';
import { QueryResult } from '@app/models/common/queryResult';
import { Region } from '@app/modules/screening-hub/models/GesintelScreening/Region';

@Component({
  selector: 'aml-update-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss'],
})
export class AMLUpdateRegionsComponent implements OnChanges {
  @Input() regions: Region[];

  displayedColumns: string[] = [
    'type',
    'country',
    'sanctions',
  ];

  queryResult: QueryResult<Region> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() { }

  ngOnChanges(): void {
    this.getRegions();
  }

  getRegions() {
    if (this.regions) {
      this.queryResult.items = this.regions
        .slice(
          (this.query.Page - 1) * this.query.PageSize,
          this.query.Page * this.query.PageSize
        );
      this.queryResult.totalItems = this.regions.length;
    }
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getRegions();
  }
}
