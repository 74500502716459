<a

  href="javascript:void(0);"
  title="Descarga"
  class="c-button text-light excel prev"
  (click)="downloadFile()"
>
  <div class="c-step-item__wrapper">
    <div class="c-step-item__action">
      {{ "FileDownload.download" | translate }}
    </div>
  </div>
</a>
