import { Injectable } from "@angular/core";
import { CustomFieldType } from "@app/models/CustomFieldType";

@Injectable({
  providedIn: 'root'
})
export class CustomFieldTypesService {
  private customFieldTypes: CustomFieldType[] = [
    {
      id: 0,
      description: 'Campo texto libre',
      descriptionEN: 'Text field',
      descriptionPT: 'Campo texto',
      descriptionFR: 'champ de texte',
      hasOptions: false,
    },
    {
      id: 2,
      description: 'Campo texto multilínea',
      descriptionEN: 'Multiline text field',
      descriptionPT: 'Campo de texto multilinha',
      descriptionFR: 'Champ de texte multiligne',
      hasOptions: false,
    },
    {
      id: 1,
      description: 'Seleccionar del listado',
      descriptionEN: 'Select from list',
      descriptionPT: 'Selecione da lista',
      descriptionFR: 'Sélectionner dans la liste',
      hasOptions: true,
    },
  ];

  public getAll(): CustomFieldType[] {
    return this.customFieldTypes;
  }

  public getById(id: number): CustomFieldType {
    return this.customFieldTypes.find(cf => cf.id === id);
  }
}
