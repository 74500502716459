<mat-radio-group *ngIf="filtertypes" [(ngModel)]="selectedType" class="c-radiotab">
    <mat-radio-button *ngFor="let t of filtertypes" [value]="t.code" [checked]="t.checked"
        (change)="radioChange($event)" [disabled]="(!dataSource)" class="c-radiotab__item">
        {{'TablaPlantillas.tab.'+t.name+'.valor' | translate }}
    </mat-radio-button>
</mat-radio-group>
<section class="c-table">
    <div class="c-table__top">
        <!--buscador y filtros-->
        <mat-form-field class="c-search" floatLabel="never">
            <mat-label class="c-search__label">
                <span class="c-search__button" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path
                            d="M19.724,18.547,14.757,13.58a8.336,8.336,0,1,0-1.177,1.177l4.968,4.968a.832.832,0,1,0,1.177-1.177ZM8.317,14.975a6.658,6.658,0,1,1,6.658-6.658,6.658,6.658,0,0,1-6.658,6.658Z"
                            transform="translate(.032.032)" />
                    </svg>
                </span> {{'TablaPlantillas.search.valor' | translate}}
            </mat-label>
            <input matInput (keyup)="applyFilter($event)" class="c-search__input" #input [disabled]="(!dataSource)" />
        </mat-form-field>
    </div>
    <div>
        <div class="c-table__bottom">
            <div *ngIf="isLoading">
                <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
            </div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>                
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaPlantillas.header.id.valor' |
                        translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.Id}}
                    </td>
                </ng-container>
                <!--data columna-->
                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaPlantillas.header.data.valor' |
                        translate}} </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'is-master' : element.IsMaster === true}">
                        <div class="c-box-edit__left">
                            <div class="c-box-edit__left-ico normal" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path
                                        d="M3.333,5.462a2.384,2.384,0,0,1-1.768-.8L.278,3.412A.97.97,0,0,1,.211,2.126a.786.786,0,0,1,1.177-.074L2.709,3.339a.806.806,0,0,0,.6.3.8.8,0,0,0,.614-.268l3-3.122A.784.784,0,0,1,8.083.306a.971.971,0,0,1-.009,1.265L5.083,4.678A2.368,2.368,0,0,1,3.333,5.462ZM20,3.642a.874.874,0,0,0-.833-.91H10.833a.874.874,0,0,0-.833.91.874.874,0,0,0,.833.91h8.333A.874.874,0,0,0,20,3.642ZM5.083,11.958,8.074,8.851a.962.962,0,0,0,.258-.884A.88.88,0,0,0,7.746,7.3a.786.786,0,0,0-.821.238l-3,3.122a.8.8,0,0,1-1.178,0L1.422,9.213a.784.784,0,0,0-1.178,0,.97.97,0,0,0,0,1.287l1.321,1.442a2.353,2.353,0,0,0,3.522.015ZM20,10.923a.874.874,0,0,0-.833-.91H10.833a.914.914,0,0,0,0,1.82h8.333A.874.874,0,0,0,20,10.923ZM5.083,19.239,8.07,16.132a.962.962,0,0,0,.258-.884.88.88,0,0,0-.585-.672.786.786,0,0,0-.821.238l-3,3.122a.8.8,0,0,1-.614.268.806.806,0,0,1-.6-.3L1.388,16.613a.786.786,0,0,0-1.177.074.97.97,0,0,0,.067,1.286l1.287,1.25a2.353,2.353,0,0,0,3.522.015ZM20,18.2a.874.874,0,0,0-.833-.91H10.833a.914.914,0,0,0,0,1.82h8.333A.874.874,0,0,0,20,18.2Z"
                                        transform="translate(.0001-.023)" />
                                </svg>
                            </div>
                            <div class="c-box-edit__left-ico updated" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 57 57">
                                    <defs>
                                        <style>
                                            .ar {
                                                fill: #fff;
                                            }

                                            .br {
                                                fill: #6c6c6c;
                                            }

                                            .cr {
                                                fill: url(#ar);
                                            }
                                        </style>
                                        <linearGradient id="ar" x1="0.5" x2="0.5" y2="1"
                                            gradientUnits="objectBoundingBox">
                                            <stop offset="0" stop-color="#fdc130" />
                                            <stop offset="1" stop-color="#faa868" />
                                        </linearGradient>
                                    </defs>
                                    <g transform="translate(-107 -323)">
                                        <circle class="ar" cx="28.5" cy="28.5" r="28.5"
                                            transform="translate(107 323)" />
                                        <g transform="translate(116.285 331.783)">
                                            <path class="br"
                                                d="M5.354,32.121a3.83,3.83,0,0,1-2.84-1.284L.449,28.828a1.559,1.559,0,0,1-.11-2.065,1.3,1.3,0,0,1,1-.49,1.274,1.274,0,0,1,.891.373L4.351,28.71a1.306,1.306,0,0,0,.964.491h.033A1.283,1.283,0,0,0,6.3,28.77l4.818-5.014a1.29,1.29,0,0,1,.955-.437,1.249,1.249,0,0,1,.362.055,1.413,1.413,0,0,1,.942,1.079,1.551,1.551,0,0,1-.415,1.419l-4.8,4.99H8.17A3.826,3.826,0,0,1,5.354,32.121ZM21.073,30.66H17.4a1.468,1.468,0,0,1,0-2.924h4.4a10.68,10.68,0,0,0-.724,2.923ZM5.354,20.428a3.826,3.826,0,0,1-2.84-1.284L.393,16.829a1.563,1.563,0,0,1,0-2.069,1.278,1.278,0,0,1,.947-.427,1.278,1.278,0,0,1,.947.427l2.127,2.318a1.286,1.286,0,0,0,1.893,0l4.816-5.015a1.294,1.294,0,0,1,.954-.437,1.219,1.219,0,0,1,.364.055,1.414,1.414,0,0,1,.94,1.079,1.543,1.543,0,0,1-.413,1.419l-4.8,4.99H8.17A3.826,3.826,0,0,1,5.354,20.428Zm25.428-1.46H17.4a1.467,1.467,0,0,1,0-2.923H30.781a1.467,1.467,0,0,1,0,2.923ZM5.369,8.737H5.341A3.8,3.8,0,0,1,2.514,7.451L.449,5.444a1.565,1.565,0,0,1-.11-2.067,1.3,1.3,0,0,1,1-.488,1.277,1.277,0,0,1,.892.37L4.351,5.327a1.3,1.3,0,0,0,.964.489h.04A1.278,1.278,0,0,0,6.3,5.385L11.123.371a1.257,1.257,0,0,1,1.86.084,1.553,1.553,0,0,1-.014,2.032l-4.8,4.99A3.829,3.829,0,0,1,5.369,8.737ZM30.781,7.274H17.4a1.406,1.406,0,0,1-1.339-1.461A1.406,1.406,0,0,1,17.4,4.353H30.781A1.406,1.406,0,0,1,32.12,5.814,1.406,1.406,0,0,1,30.781,7.274Z"
                                                transform="translate(0 0)" />
                                            <g transform="translate(24.323 25.151)">
                                                <path class="cr"
                                                    d="M710.282,505.924a7.2,7.2,0,1,1,0-14.394v2.106h0a5.093,5.093,0,1,0,5.092,5.094v0h2.106a7.191,7.191,0,0,1-7.186,7.2Z"
                                                    transform="translate(-703.088 -491.53)" />
                                                <path class="cr"
                                                    d="M1167.549,497.129l-.039-5.6h-5.6v2.106h2.062l-4.354,4.354,1.49,1.489,4.333-4.374v2.022Z"
                                                    transform="translate(-1153.158 -491.53)" />
                                                <circle class="cr" cx="2.173" cy="2.173" r="2.173"
                                                    transform="translate(5.157 5.16)" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div>
                                <small *ngIf="(element.IsMaster)"
                                    class="c-box-edit__updated">{{'TablaPlantillas.updated.valor' | translate}}:
                                    <span>{{ element.UpdateTime | date:'MMMM yyyy' }}</span></small>
                                <h1 class="c-box-edit__title">{{ element.Code }}</h1>
                                <ul class="c-box-edit-info">
                                    <li class="c-box-edit-info__item">
                                        <span class="c-box-edit-info__ico" aria-hidden="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path
                                                    d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z" />
                                            </svg>
                                        </span> {{element.ThirdPartyType | translatedescription}}
                                    </li>
                                    <li class="c-box-edit-info__item">
                                        <span class="c-box-edit-info__ico" aria-hidden="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path
                                                    d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm6.667,8a6.625,6.625,0,0,1-1.108,3.672l-.795-.8a.333.333,0,0,1-.1-.235V10a2,2,0,0,0-2-2h-2A.667.667,0,0,1,8,7.333V7a.333.333,0,0,1,.333-.333A1.667,1.667,0,0,0,10,5V4.333A.333.333,0,0,1,10.333,4h.919a1.677,1.677,0,0,0,1.178-.488l.251-.251A6.646,6.646,0,0,1,14.667,8Zm-13.3.642,2.105,2.105a1.985,1.985,0,0,0,1.415.586H6.667A.667.667,0,0,1,7.333,12v2.633A6.677,6.677,0,0,1,1.366,8.642Zm7.3,5.991V12a2,2,0,0,0-2-2H4.886a.667.667,0,0,1-.472-.2L1.442,6.833a6.652,6.652,0,0,1,10.2-4.413l-.149.149a.338.338,0,0,1-.235.1h-.919A1.667,1.667,0,0,0,8.667,4.333V5a.333.333,0,0,1-.333.333A1.667,1.667,0,0,0,6.667,7v.333a2,2,0,0,0,2,2h2a.667.667,0,0,1,.667.667v.641a1.677,1.677,0,0,0,.488,1.178l.891.891A6.647,6.647,0,0,1,8.667,14.633Z" />
                                            </svg>
                                        </span> {{ element.Country | translatedescription}}
                                    </li>
                                    <li class="c-box-edit-info__item">
                                        <span class="c-box-edit-info__ico" aria-hidden="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path
                                                    d="M12.667,2.667h-.733A3.339,3.339,0,0,0,8.667,0H7.333A3.339,3.339,0,0,0,4.067,2.667H3.333A3.337,3.337,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6a3.337,3.337,0,0,0-3.333-3.333ZM7.333,1.333H8.667a2,2,0,0,1,1.877,1.333H5.456A2,2,0,0,1,7.333,1.333ZM3.333,4h9.333a2,2,0,0,1,2,2V8H1.333V6a2,2,0,0,1,2-2Zm9.333,10.667H3.333a2,2,0,0,1-2-2V9.333h6V10a.667.667,0,0,0,1.333,0V9.333h6v3.333A2,2,0,0,1,12.667,14.667Z" />
                                            </svg>
                                        </span> {{ element.Sector | translatedescription}}
                                    </li>
                                    <li class="c-box-edit-info__item">
                                        <span class="c-box-edit-info__ico" aria-hidden="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 16 16.002">
                                                <path
                                                    d="M15.482,3.829,13.474,1.117A2.67,2.67,0,0,0,11.3,0H4.7A2.667,2.667,0,0,0,2.533,1.1L.49,3.836a2.662,2.662,0,0,0,.082,3.19l5.862,8.22A1.99,1.99,0,0,0,8,16H8a2,2,0,0,0,1.586-.783L15.4,7.09a2.667,2.667,0,0,0,.08-3.261ZM12.4,1.9,14.408,4.62c.011.015.013.032.024.047H11.17l-.952-3.334H11.3A1.341,1.341,0,0,1,12.4,1.9ZM8,12.747,6.2,6H9.8ZM6.218,4.667,7.17,1.333H8.831l.952,3.334ZM3.611,1.892A1.338,1.338,0,0,1,4.7,1.333H5.783L4.831,4.667H1.545c.009-.015.012-.033.023-.047ZM1.632,6.217A1.267,1.267,0,0,1,1.509,6H4.822l2,7.488Zm7.55,7.278,2-7.494h3.324a1.4,1.4,0,0,1-.162.281Z"
                                                    transform="translate(.0001)" />
                                            </svg>
                                        </span> &#36; {{'TablaPlantillas.amount' | translate}} {{element.Amount}} 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!--acciones-->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Tabla3PP.header.actions.valor' | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <app-template-actions
                            [questionary]="element"
                            [actions]="this.config.acciones"
                            (editTemplate)="editarPlantilla($event)"
                            (duplicateTemplate)="duplicarPlantilla($event)"
                            >
                        </app-template-actions>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="config.columnas"></tr>
                <tr mat-row *matRowDef="let row; columns: config.columnas;" [class.is-master]="IsMaster">
                </tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9">{{'Tabla3PP.noresults.valor' | translate}} </td>
                </tr>
            </table>

            <mat-paginator [pageSize]="7" [pageSizeOptions]="[7, 10, 25, 100]" class="c-pagination">
            </mat-paginator>

        </div>
    </div>
</section>