import { Component, OnInit, Input } from '@angular/core';
import { base64ToArrayBuffer } from '@app/utilities/utilities'

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent {

    @Input() title: string
    @Input() clazz: string
    @Input() downloadedFileName: string
    @Input() getFileBytes: Function 

    constructor() { }

    async downloadFile() {
        let fileBytes = await this.getFileBytes()
        if (fileBytes != null) {
            let myBlob = new Blob([base64ToArrayBuffer(fileBytes)], { type: 'octect/stream' })
            let urlexcel = window.URL.createObjectURL(myBlob)
            var link = document.createElement('a')
            link.href = urlexcel
            link.download = this.downloadedFileName
            link.click()
            setTimeout(() => { window.URL.revokeObjectURL(urlexcel) }, 10)
        }
    }

}
