<!--modal confirmación-->
<div role="dialog" class="c-dialog" aria-labelledby="titleDialog" style="display:block;visibility:visible;">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">Confirmación campos</h1>
        <button mat-button (click)="onNoClick()" class="c-dialog__close" aria-label="Cerrar ventana modal">
            <span aria-hidden="true"> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243" transform="translate(-309.86-406.47)"/>
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content small-padding">
        <h2 class="c-dialog__subtitle">{{introText}}</h2>
        <p class="o-text">{{'DialogExisting3pp.introText2.valor' | translate}}</p>
        <div class="c-fields">
            <p id="infoThird" class="c-fields__legend u-mt-1medium">{{'DialogExisting3pp.3ppinfo.valor' | translate}}</p>
            <div class="c-fields__group" aria-labelledby="infoThird">
                <div class="c-fields-item">
                    <p id="countryThird" class="c-fields-item__title">{{'DialogExisting3pp.pais.valor' | translate}}</p>
                    <p class="c-fields-item__text" aria-labelledby="countryThird">{{data.country}}</p>
                </div>
                <div class="c-fields-item">
                    <p id="cifThird" class="c-fields-item__title">{{'DialogExisting3pp.cif.valor' | translate}}</p>
                    <p class="c-fields-item__text" aria-labelledby="cifThird">{{data.cif}}</p>
                </div>
            </div>
        </div>
        <div class="c-fields">
            <p id="infoAutofill" class="c-fields__legend u-mt-1medium">{{'DialogExisting3pp.autoinfo.valor' | translate}}</p>
            <div class="c-fields__group" aria-labelledby="infoAutofill">
                <div class="c-fields-item c-fields-item--predef">
                    <p id="autofillThird" class="c-fields-item__title">{{'DialogExisting3pp.company.valor' | translate}}</p>
                    <p class="c-fields-item__text" aria-labelledby="autofillThird">{{data.name}}</p>
                </div>
            </div>
        </div>
    </div>    
    <footer class="c-dialog__footer">
        <mat-dialog-actions>
            <button class="c-button c-button--red" mat-button [mat-dialog-close]="false">Es incorrecto</button>
            <button class="c-button c-button--green" mat-button [mat-dialog-close]="true">Entendido</button>
        </mat-dialog-actions>
    </footer>
</div>