import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonsService } from '../../../services/commons-service';
import { Toaster } from 'ngx-toast-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ThirdPartyTypeDTO } from '@app/models/ThirdPartyType';
import { DialogAddThirdtypeComponent } from '../dialog-add-thirdtype/dialog-add-thirdtype.component';
import { DialogDeleteThirdtypeComponent } from '../dialog-delete-thirdtype/dialog-delete-thirdtype.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-thirdtype-table',
  templateUrl: './thirdtype-table.component.html',
  styles: []
})
export class ThirdtypeTableComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  data: any;
  dataSource: any;
  displayedColumns: string[] = ['Description', 'DescriptionEN', 'DescriptionPT', 'DescriptionFR', 'Actions'];
  actions: string[] = ['delete', 'update'];
  ready: boolean = false;
  errorMessage: string;
  doneMessage: string;

  constructor(
    private commons: CommonsService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private toaster: Toaster,
  ) { }

  ngOnInit(): void {
    this.translate.get('TablaThirdtype.error')
      .subscribe(translation => {
        this.errorMessage = translation;
      });
    this.translate.get('TablaRisks.done')
      .subscribe(translation => {
        this.doneMessage = translation;
      });
  }

  async ngAfterContentInit(): Promise<void> {
    await this.getData();
    this.updateTable();
  }

  updateTable() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.ready = true;
  }

  async getData() {
    let r = await this.get3ppTypeP();
    this.get3ppType(r);
  }

  get3ppTypeP() {
    return this.commons.getThirdPartyTypes();
  }

  get3ppType(r) {
    if (r !== null && r !== undefined) {
      this.data = (<ThirdPartyTypeDTO[]>r);
    }
  }

  openDialogAdd(): void {
    const dialogRef = this.dialog.open(DialogAddThirdtypeComponent, {});
    dialogRef.afterClosed().subscribe(async result => {
      if (result === undefined) {
        return
      }
      if (result) {
        this.toaster.open({ text: this.doneMessage, duration: 4000, type: 'danger' });
        await this.getData();
        this.updateTable();
      } else {
        this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
      }
    })
  }

  delete(element) {
    const dialogRef = this.dialog.open(
      DialogDeleteThirdtypeComponent,
      {
        data: {
          id: element.Id,
          code: element.Code,
          description: element.Description
        }
      }
    )

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        // No action
      } else if (!result) {
        // No use.
      } else {
        // Delete
        this.commons.deleteThirdtype(element.Id)
          .then(async r => {
            this.toaster.open({ text: this.doneMessage, duration: 4000, type: 'success' });
            await this.getData();
            this.updateTable();
          })
          .catch(err => {
            this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
          })
      }
    })
  }

  update(element) {
    this.openDialogUpdate({ data: element });
  }

  openDialogUpdate(data): void {
    const dialogRef = this.dialog.open(DialogAddThirdtypeComponent, data);

    dialogRef.afterClosed().subscribe(async result => {
      if (result === undefined) {
        return
      }
      if (result) {
        await this.getData();
        this.updateTable();
      } else {
        this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
      }
    })
  }
}
