<div class="row">
  <div class="col-lg-12">
    <p id="apiSecret" class="c-fields__legend">
      API Key
    </p>
    <mat-form-field floatLabel="never">
      <input type="text" matInput name="sector-description" [(ngModel)]="apiKey" (keyup)="onApiKeyChange()"
        id="sector-description" class="c-form__input" placeholder="API Key" aria-labelledby="refinitivApiKey" />
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <p id="apiSecret" class="c-fields__legend">
      API Secret
    </p>
    <mat-form-field floatLabel="never">
      <input type="text" matInput name="api-secret" [(ngModel)]="apiSecret" id="api-secret" (keyup)="onApiSecretChange()"
        class="c-form__input" placeholder="API Secret" aria-labelledby="refinitivApiSecret" />
    </mat-form-field>
  </div>
</div>
