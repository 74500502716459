<div class="pledge-credit">
  <p id="keyData" class="pledge-credit__legend u-mt-1medium">{{ "EquifaxWSPledgeCredit.pledgeCredit" | translate }} {{ index }}</p>
  <div class="pledge-credit__group" aria-labelledby="keyData">
    <div class="pledge-credit-item">
      <p id="startDate" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.startDate" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="startDate">
        {{ credit?.startDate || "" }}
      </p>
    </div>
    <div class="pledge-credit-item">
      <p id="expDate" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.expDate" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="expDate">
        {{ credit?.expDate || "" }}
      </p>
    </div>
    <div class="pledge-credit-item">
      <p id="status" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.status" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="status">
        {{ credit?.status || "" }}
      </p>
    </div>
    <div class="pledge-credit-item">
      <p id="remainingQuotas" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.remainingQuotas" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="remainingQuotas">
        {{ credit?.remainingQuotas || "" }}
      </p>
    </div>
  </div>
  <div class="pledge-credit__group" aria-labelledby="keyData">
    <div class="pledge-credit-item">
      <p id="term" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.term" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="term">
        {{ credit?.term || "" }}
      </p>
    </div>
    <div class="pledge-credit-item">
      <p id="goodAmount" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.goodAmount" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="goodAmount">
        {{ credit?.goodAmount || "" }}
      </p>
    </div>
    <div class="pledge-credit-item">
      <p id="pledgeAmount" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.pledgeAmount" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="pledgeAmount">
        {{ credit?.pledgeAmount || "" }}
      </p>
    </div>
    <div class="pledge-credit-item">
      <p id="currency" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.currency" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="currency">
        {{ credit?.currency || "" }}
      </p>
    </div>
  </div>
  <div class="pledge-credit__group" aria-labelledby="keyData">
    <div class="pledge-credit-item">
      <p id="creditorCedula" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.creditorCedula" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="creditorCedula">
        {{ credit?.creditorCedula || "" }}
      </p>
    </div>
    <div class="pledge-credit-item">
      <p id="creditorName" class="pledge-credit-item__title">
        {{ "EquifaxWSPledgeCredit.creditorName" | translate }}
      </p>
      <p class="pledge-credit-item__text" aria-labelledby="creditorName">
        {{ credit?.creditorName || "" }}
      </p>
    </div>
  </div>
</div>
