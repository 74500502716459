import { Component, Input, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { QueryResult } from "@app/models/common/queryResult";
import { ResolutionLink } from "@app/models/scoring-tools/resolutionLink";
import { ResolutionRecord } from "@app/models/scoring-tools/resolutionRecord";
import { ScoringToolSearchTypeEnum } from "@app/models/scoring-tools/scoringToolSearchTypeEnum";
import { TranslateService } from "@ngx-translate/core";
import { Toaster } from "ngx-toast-notifications";
import { ScreeningProvider, mapProviderName } from "../../models/ScreeningProvider";

@Component({
  selector: 'app-resolution-record-list',
  templateUrl: './resolution-record-list.component.html',
  styleUrls: ['./resolution-record-list.component.scss']
})
export class ResolutionRecordListComponent implements OnChanges {

  @Input() resolutionLink: ResolutionLink;
  @Input() resolutionRecords: ResolutionRecord[];
  @Input() thirdPartyId?: number;

  displayedColumns: string[] = ['creationDate', 'software', 'createdBy', 'searchType', 'totalResults', 'actions'];

  translations: any;
  providerNameTranslations: { [key:string]: string };

  queryResult: QueryResult<ResolutionRecord> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: 'creationDate',
    IsSortAscending: false,
    Page: 1
  };

  constructor(
    private translate: TranslateService,
    private router: Router,
    private toaster: Toaster
  ) {
    this.translate.get('searchTypes')
      .subscribe(translation => {
        this.translations = translation;
      });
    this.translate.get('providerNames')
      .subscribe(translation => {
        this.providerNameTranslations = translation;
      });
  }

  ngOnChanges(): void {
    this.getResolutionRecords();
  }

  sortingFunction(a: ResolutionRecord, b: ResolutionRecord): number {
    if (a[this.query.SortBy] > b[this.query.SortBy]) {
      return this.query.IsSortAscending ? 1 : -1;
    }

    if (a[this.query.SortBy] < b[this.query.SortBy]) {
      return this.query.IsSortAscending ? -1 : 1;
    }

    return 0;
  }

  getResolutionRecords() {
    this.queryResult.items = this.resolutionRecords.sort((a, b) => this.sortingFunction(a, b)).slice((this.query.Page - 1) * this.query.PageSize, this.query.Page * this.query.PageSize);
    this.queryResult.totalItems = this.resolutionRecords.length;
  }

  onFilterChange(event: Event) {
    this.query.Page = 1;
    this.getResolutionRecords();
  }

  resetFilter() {
    this.query = {
      PageSize: 5,
      SortBy: '',
      IsSortAscending: false,
      Page: 1
    };

    this.getResolutionRecords();
  }

  sortBy(columnName) {
    this.query.Page = 1;
    this.query.SortBy = columnName.active;
    this.query.IsSortAscending = columnName.direction === 'asc'
    this.getResolutionRecords();
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getResolutionRecords();
  }

  viewDetails(resolutionRecordId: number) {
    if (this.thirdPartyId) {
      this.router.navigate(['resolution-details', resolutionRecordId, this.thirdPartyId], { state: { data: this.resolutionRecords.find(r => r.id === resolutionRecordId), name: this.resolutionLink.name } });
    } else {
      this.router.navigate(['resolution-details', resolutionRecordId], { state: { data: this.resolutionRecords.find(r => r.id === resolutionRecordId), name: this.resolutionLink.name } });
    }
  }

  getSearchTypeName(searchType: ScoringToolSearchTypeEnum): string {
    switch (searchType) {
      case ScoringToolSearchTypeEnum.Individual:
        return this.translations.individual;
      case ScoringToolSearchTypeEnum.Organization:
        return this.translations.organization;
      case ScoringToolSearchTypeEnum.All:
        return this.translations.all
      default:
        return '';
    }
  }

  getSofwareName(provider: ScreeningProvider): string {
    return mapProviderName(provider, this.providerNameTranslations);
  }

}
