import { Component, Input, OnInit } from "@angular/core";
import { EquifaxWSIndividualData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSIndividualData";

@Component({
  selector: 'equifax-ws-individual-basic-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss'],
})
export class EquifaxWSIndividualBasicDataComponent implements OnInit {
  @Input() scoringDetails: EquifaxWSIndividualData;

  under6: number = 0;
  from6to12: number = 0;
  from13to17: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.under6 = this.scoringDetails?.offspring?.filter(o => o.age<6).length ?? 0;
    this.from6to12 = this.scoringDetails?.offspring?.filter(o => o.age>5 && o.age<13).length ?? 0;
    this.from13to17 = this.scoringDetails?.offspring?.filter(o => o.age>12 && o.age<18).length ?? 0;
  }
}
