import { Injectable } from '@angular/core';
import { BehaviorSubject  } from 'rxjs'; 
import { interval } from 'rxjs';
import { RemoteService } from '../services/remote.service';
@Injectable({
  providedIn: 'root'
})
export class TemplateBlockerService {

  public notifier: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  suscription:any;

  constructor(
    private remoteService:RemoteService
  ) {
    const ticks = interval(20000);
    this.suscription=ticks.subscribe(x => {
      this.notifier.next(new Date());
    });
  }

  stop() {
    this.suscription.unsubscribe();
  }

  async getTemplateLock(id:number) {
    const res=await this.remoteService.getRequest('Questionaries/GetQuestionaryLock/' + (id).toString())    
     .then(x=>{
      return x;
    })
    .catch(err=>{
      return undefined;
    }) 
    return res;
  }  

  async updateTemplateLock(id:number) {
    const res=await this.remoteService.putRequest('Questionaries/UpdateQuestionaryLock/' + (id).toString(),{})    
     .then(x=>{
      return x;
    })
    .catch(err=>{
      return undefined;
    }) 
    return res;
  }

  addTemplateLock(id:number){
    return this.remoteService.postRequest('Questionaries/CreateQuestionaryLock',{"questionaryId": id });
    
  }

  deleteTemplateLock(id:number){
    return this.remoteService.deleteRequest('Questionaries/DeleteQuestionaryLock/'+id.toString(),null);    
  }  

  unblockTemplate(n:number){
    this.stop();
    this.deleteTemplateLock(n)
    .catch(
      err=> {console.log(err);}
    )
  }
}
