<ng-container [formGroup]="form">
    <div class="container-fluid c-form c-form--space">
        <div class="row u-mb-1">
            <div class="col-lg-6 col-xl-4">
                <div class="c-form__block u-mb-1medium">
                    <mat-form-field>
                        <input type="text" class="c-form__input" matInput formControlName="Template"
                            placeholder="{{'ListadoPreguntas.form.template.valor'|translate}}">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="o-title o-title--medium">{{'ListadoPreguntas.form.categ.valor' | translate}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-xl-4">
                <div class="c-form__block">
                    <div class="c-select c-select--height-input">
                        <mat-form-field>
                            <label for="typeThird" class="u-sr-only">{{'ListadoPreguntas.form.type3pp.valor' |
                                translate}}</label>
                            <mat-select formControlName="ThirdPartyType" [compareWith]="objectComparisonFunction"
                                id="typeThird" placeholder="{{'ListadoPreguntas.form.type3pp.valor' | translate}}">
                                <mat-option *ngFor="let tipo of tipos3pp" [value]="tipo">
                                    {{tipo | translatedescription}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-4">
                <div class="c-form__block">
                    <div class="c-select c-select--height-input">
                        <mat-form-field>
                            <label for="sector" class="u-sr-only">{{'ListadoPreguntas.form.sector.valor' |
                                translate}}</label>
                            <mat-select formControlName="Sector" [compareWith]="objectComparisonFunction"
                                placeholder="{{'ListadoPreguntas.form.sector.valor' | translate}}">
                                <mat-option *ngFor="let tipo of tipoSector" [value]="tipo">
                                    {{tipo | translatedescription}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-2">
                <div class="c-form__block">
                    <div class="c-select c-select--height-input">
                        <mat-form-field>
                            <label for="country" class="u-sr-only">{{'ListadoPreguntas.form.country.valor' |
                                translate}}</label>
                            <mat-select formControlName="Country" [compareWith]="objectComparisonFunction"
                                placeholder="{{'ListadoPreguntas.form.country.valor' | translate}}" id="country">
                                <mat-option *ngFor="let country of tipoCountry" [value]="country">
                                    {{country?.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-2">
                <div class="c-form__block">
                    <mat-form-field>
                        <label for="monto" class="u-sr-only">{{'ListadoPreguntas.form.monto.valor'|translate}}</label>
                        <input matInput type="number" formControlName="Amount" id="monto"
                            placeholder="{{'ListadoPreguntas.form.monto.valor'|translate}} $" class="c-form__input" />
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="c-form__block u-mt-1medium">
                    <mat-form-field>
                        <textarea matInput class="c-form__textarea" formControlName="Notes"
                            placeholder="{{'ListadoPreguntas.form.notes.valor' | translate}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div *appAuthRole="['Owner','Manager']" class="col-12 d-flex justify-content-end align-center">
                <button *ngIf="!this.questionary.isMaster && canEdit" (click)="openDialogDeleteQuestionary()" type="button"
                    class="c-button c-button--remove u-mr-1">
                    {{'ListadoPreguntas.button.delete' | translate}}
                </button>
            </div>
        </div>
    </div>
</ng-container>
