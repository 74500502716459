import { Component, Input, OnChanges } from '@angular/core';
import { QueryResult } from '@app/models/common/queryResult';
import { PEPInfo } from '@app/modules/screening-hub/models/GesintelScreening/PEPInfo';

@Component({
  selector: 'aml-update-partnership-pep',
  templateUrl: './partnership-pep.component.html',
  styleUrls: ['./partnership-pep.component.scss'],
})
export class PartnershipPEPComponent implements OnChanges {
  @Input() partnerships: PEPInfo[];

  displayedColumns: string[] = [
    'nameAndRut',
    'mainCategory',
    'partnership',
    'category',
    'role',
    'institution',
    'startDate',
    'endDate'
  ];

  queryResult: QueryResult<PEPInfo> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() { }

  ngOnChanges(): void {
    this.getScreenings();
  }

  getScreenings() {
    if (this.partnerships) {
      this.queryResult.items = this.partnerships;
      this.queryResult.totalItems = this.partnerships.length;
    }
  }
}
