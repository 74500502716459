import { RiskallayUser } from '@app/models/RiskallayUser';
import { RiskallayUserDto } from 'src/proxy/model/riskallayUserDto';

export function mapUser(dto: RiskallayUserDto): RiskallayUser {
  if (!dto) return null;

  return <RiskallayUser>{
    email: dto.Email,
    id: dto.Id,
    name: dto.Name,
    surName: dto.SurName,
    phoneNumber: dto.PhoneNumber,
    twoFactorEnabled: dto.TwoFactorEnabled,
    position: dto.Position,
    profilePhotoFileName: dto.ProfilePhotoFileName,
    profilePhotoFileUri: dto.ProfilePhotoFileUri,
  };
}
