import { Injectable } from "@angular/core";
import { CampaignThirdParty } from "@app/models/CampaignThirdParty";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CampaignThirdPartyProxy } from "src/proxy/apiCampaignThirdParty/campaignThirdParty.proxy";
import { mapCampaignThirdParty } from "./mappings/campaign-third-party.mapping";

@Injectable()
export class CampaignThirdPartyService {

    constructor(private ctpProxy: CampaignThirdPartyProxy) { }

    getCampaignThirdPartiesByCampaign(campaignId: number): Observable<CampaignThirdParty[]> {
        return this.ctpProxy.campaignThirdPartyByCampaignIdGet(campaignId).pipe(map(r => r.map(ctp => mapCampaignThirdParty(ctp))));
    }

    getCampaignThirdPartiesByThirdParty(thirdPartyId: number): Observable<CampaignThirdParty[]> {
        return this.ctpProxy.campaignThirdPartyByThirdPartyIdGet(thirdPartyId).pipe(map(r => r.map(ctp => mapCampaignThirdParty(ctp))));
    }

    getCampaignThirdPartiesByThirdPartyAndCampaign(thirdPartyId: number, campaignId: number): Observable<CampaignThirdParty> {
        return this.ctpProxy.campaignThirdPartyThirdPartyThirdPartyIdCampaignCampaignIdGet(thirdPartyId, campaignId).pipe(map(r => mapCampaignThirdParty(r)));
    }
}
