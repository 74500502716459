import { Component, Input, OnInit } from "@angular/core";
import { ScoringToolsService } from "@app/services/scoring-tools.service";
import { ResolutionLink } from "@app/models/scoring-tools/resolutionLink";

@Component({
  selector: 'app-resolutions-dashboard',
  templateUrl: './resolutions-dashboard.component.html',
  styleUrls: ['./resolutions-dashboard.component.scss']
})
export class ResolutionsDashboardComponent implements OnInit {
  @Input() thirdPartyId?: number;

  isLoading = true;
  resolutionLinks: ResolutionLink[] = [];
  resolutionLinksPaged: ResolutionLink[] = [];
  pageSize: number = 5;
  page: number = 1;
  totalResolutions: number = 0;
  filterText: string = '';

  constructor(private scoringToolsService: ScoringToolsService) { }

  ngOnInit() {
    this.getResolutions();
  }

  getResolutions() {
    this.scoringToolsService.getResolutionLinks(this.thirdPartyId).subscribe(r => {
      this.resolutionLinks = r;
      this.getData();
    });
    this.isLoading = false;
  }

  getData() {
    let filtered = this.resolutionLinks?.filter(res => this.filterFunction(res));
    this.resolutionLinksPaged = filtered.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    this.totalResolutions = filtered.length;
  }

  filterFunction(res: ResolutionLink): boolean {
    return this.filterText == '' || String(res.name).toLowerCase().includes(this.filterText);
  }

  onPageChange(page) {
    this.page = page.pageIndex + 1;
    this.pageSize = page.pageSize;
    this.getData();
  }

  refreshResolution() {
    this.isLoading = true;
    this.getResolutions();
  }

  onFilter(text: string): void {
    this.page = 1;
    this.filterText = text.toLowerCase();
    this.getData();
  }
}
