import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ThirdPartyTypeCreateDTO, ThirdPartyTypeUpdateDTO, ThirdPartyTypeDTO } from '@app/models/ThirdPartyType';
import { CommonsService } from '../../../services/commons-service';

@Component({
  selector: 'app-dialog-add-thirdtype',
  templateUrl: './dialog-add-thirdtype.component.html',
  styles: []
})
export class DialogAddThirdtypeComponent implements OnInit {

  entry: ThirdPartyTypeDTO = null;
  form: any;

  constructor(

    public dialogRef: MatDialogRef<DialogAddThirdtypeComponent>,
    private fb: FormBuilder,
    private commons: CommonsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    let entry = this.data;
    this.form = this.fb.group({
      Description: new FormControl(entry !== null ? entry.Description : '', Validators.required),
      DescriptionEN: new FormControl(entry !== null ? entry.DescriptionEN : '', Validators.required),
      DescriptionPT: new FormControl(entry !== null ? entry.DescriptionPT : '', Validators.required),
      DescriptionFR: new FormControl(entry !== null ? entry.DescriptionFR : '', Validators.required),
    });
  }

  // cerrar modal
  onNoClick(): void {
    this.dialogRef.close();
  }

  async addThirdtype() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;

    let t = (<ThirdPartyTypeCreateDTO>{});
    t.Code = description;
    t.Description = description;
    t.DescriptionEN = descriptionEN;
    t.DescriptionPT = descriptionPT;
    t.DescriptionFR = descriptionFR;

    this.commons.postThirdtype(t)
      .then(r => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }


  async updateThirdtype() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;

    let t = (<ThirdPartyTypeUpdateDTO>{});
    t.Id = this.data.Id;
    t.Code = description;
    t.Description = description;
    t.DescriptionEN = descriptionEN;
    t.DescriptionPT = descriptionPT;
    t.DescriptionFR = descriptionFR;

    this.commons.putThirdtype(t)
      .then(r => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }
}
