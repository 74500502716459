import { breakTextIntoLines, PageSizes, PDFDocument, PDFFont, PDFPage, rgb } from "pdf-lib"
import fontkit from '@pdf-lib/fontkit'
import { Font } from "./Models/font";
import { Image } from "./Models/image";
import { Text } from "./Models/text";
import { ColorHex } from "./Models/colorHex";
import { ColorRGB } from "./Models/colorRgb";
import { CoverPage } from "./Models/coverPage";
import { DocumentContent } from "./Models/documentContent";
import { Header } from "./Models/header";
import { Footer } from "./Models/footer";
import { Section } from "./Models/section";
import { Content } from "./Models/content";
import { Table } from "./Models/table";
import { SvgPath } from "./Models/svgPath";
import { Chart } from "./Models/chart";
import { Row } from "./Models/row";


export async function templateGenerator(fonts: Font[], docContent: DocumentContent, cover?: CoverPage) : Promise<Uint8Array>{
    
    const pdfDoc = await PDFDocument.create();    
    pdfDoc.registerFontkit(fontkit);
    let pageNumber = 0;
    const embededFonts = await embedFonts(fonts, pdfDoc);    
    pageNumber = createCoverPage(pdfDoc, embededFonts, pageNumber, cover);
    await generateDocumentContent(pdfDoc,embededFonts,docContent, pageNumber);
    return pdfDoc.save(); 
}

async function embedFonts(fonts: Font[], pdfDoc: PDFDocument): Promise<PDFFont[]> {
    return Promise.all(fonts.map(font => {
        return pdfDoc.embedFont(font.font);
    }));
}

async function insertImagePng(content: Content, pdfDoc: PDFDocument, page: PDFPage, pageNumber: number, lastContentPosition: number, availableHeightInPage: number, fonts: PDFFont[], docContent?: DocumentContent): Promise<[number, PDFPage, number, number, number]> {
    const image = <Image>content.content;
    const img = await pdfDoc.embedPng(image.image);
    const scale = img.scaleToFit(image.width,image.height);
    const scaleOverwriteHeight = image.customHeight ? image.customHeight : 1;
    const scaleOverwriteWidth = image.customWidth ? image.customWidth : 1;
    
    if(content.isRelative && (scale.height > (availableHeightInPage-lastContentPosition))){
        [page, pageNumber, availableHeightInPage] = createPage(pdfDoc, pageNumber, fonts, docContent);
        lastContentPosition = docContent?.header?.height || 0;
    }

    const posY = content.isRelative ?  lastContentPosition + content.posY : content.posY;

    page.drawImage(img, { x: content.posX, y: page.getHeight()-posY-(scale.height/scaleOverwriteHeight), height: scale.height/scaleOverwriteHeight, width: scale.width/scaleOverwriteWidth});

    return Promise.resolve([posY+(scale.height/scaleOverwriteHeight), page, pageNumber, content.posX+(scale.width/scaleOverwriteWidth),scale.height/scaleOverwriteHeight]);
}

function insertText(content: Content, page: PDFPage, fonts: PDFFont[], pageNumber: number, lastContentPosition: number, availableHeightInPage: number, pdfDoc: PDFDocument, docContent?: DocumentContent): [number, PDFPage, number, number, number] {
    const text = <Text> content.content;
    const fontColor = isHexColor(text.fontColor) ? convertHexToRGB(text.fontColor) : text.fontColor;
    const textStr = replaceBookmarks(text.text, pageNumber);
    const font = fonts.find(f => f.name === text.font);
    const posY = content.isRelative ?  lastContentPosition + content.posY : content.posY;
    const textWidth = font ? (t: string) => font.widthOfTextAtSize(t, text.fontSize || 10) : (t: string) => 10;
    const textLines = textStr ? breakTextIntoLines(textStr, text.wordBreaks || pdfDoc.defaultWordBreaks, text.maxWidth || page.getWidth(), textWidth) : [];
    const contentHeight = textLines.length * (text.lineHeight || text.fontSize || 10);    

    if(content.isRelative && (contentHeight > (availableHeightInPage-lastContentPosition))){        
        const availableLines = (availableHeightInPage-lastContentPosition)/(text.lineHeight || text.fontSize || 10);
        if(text.underline && availableLines >= 1){
            const underlineColor = isHexColor(text.underline.color) ? convertHexToRGB(text.underline.color) : text.underline.color;
            page.drawLine({start:{x: content.posX, y: page.getHeight()-posY- (text.lineHeight || text.fontSize || 0)}, end:{x: (font?.widthOfTextAtSize(textStr, text.fontSize || 10)||page.getWidth())+content.posX, y: page.getHeight()-posY- (text.lineHeight || text.fontSize || 0)}, thickness: text.underline.thickness, color:rgb(underlineColor.red/256,underlineColor.green/256,underlineColor.blue/256)});
        }
        page.drawText(textLines.slice(0, availableLines).join(' '), {color: rgb(fontColor.red/256,fontColor.green/256,fontColor.blue/256), font: font, size: text.fontSize, x: content.posX, y: page.getHeight()-posY - (text.lineHeight || text.fontSize || 0), lineHeight: text.lineHeight, maxWidth: text.maxWidth, wordBreaks: text.wordBreaks});
        [page, pageNumber, availableHeightInPage] = createPage(pdfDoc, pageNumber, fonts, docContent);

        return insertText(<Content>{content:<Text>{text: textLines.slice(availableLines, textLines.length).join(' '),font: text.font, fontColor: text.fontColor, fontSize: text.fontSize, lineHeight: text.lineHeight, maxWidth: text.maxWidth, underline: text.underline}, posX: content.posX, posY: content.posY, isRelative: content.isRelative},
                        page, fonts, pageNumber, docContent?.header?.height || 0, availableHeightInPage, pdfDoc,docContent);

    } else {
        if(text.underline){
            const underlineColor = isHexColor(text.underline.color) ? convertHexToRGB(text.underline.color) : text.underline.color;
            page.drawLine({start:{x: content.posX, y: page.getHeight()-posY- (text.lineHeight || text.fontSize || 0)}, end:{x: (font?.widthOfTextAtSize(textStr, text.fontSize || 10)||page.getWidth())+content.posX, y: page.getHeight()-posY- (text.lineHeight || text.fontSize || 0)}, thickness: text.underline.thickness, color:rgb(underlineColor.red/256,underlineColor.green/256,underlineColor.blue/256)});
        }
        page.drawText(textStr, {color: rgb(fontColor.red/256,fontColor.green/256,fontColor.blue/256), font: font, size: text.fontSize, x: content.posX, y: page.getHeight()-posY - (text.lineHeight || text.fontSize || 0), lineHeight: text.lineHeight, maxWidth: text.maxWidth, wordBreaks: text.wordBreaks});
    }

    return [contentHeight + posY, page, pageNumber, content.posX + (text.maxWidth ?? textWidth(textStr)), contentHeight];
}

function replaceBookmarks(text: string, pagenNumber: number){
    try {
        return text?.replace('$pageNumber$',pagenNumber.toString()) || '';    
    } catch (error) {
        console.log(text)
        console.log(pagenNumber);
        return '';
    }
    
}

function isHexColor(obj: any): obj is ColorHex {
    return obj.hexCode;
}

function convertHexToRGB(color: ColorHex) : ColorRGB {    
    color.hexCode = color.hexCode ? color.hexCode : "000000";
    let initIndex = color.hexCode[0] === '#' ? 1 : 0;
    return <ColorRGB> {
        blue:getDecFromHex(color.hexCode.substring(initIndex+4, initIndex+6)),
        green:getDecFromHex(color.hexCode.substring(initIndex+2, initIndex+4)),
        red: getDecFromHex(color.hexCode.substring(initIndex, initIndex+2))
    };
}

function getDecFromHex(hexString: string): number {
    return parseInt(hexString, 16);
}

function createCoverPage( pdfDoc: PDFDocument, fonts: PDFFont[], pageNumber: number, cover?: CoverPage): number {
    if(cover){
        const page = pdfDoc.addPage(PageSizes.A4);
        pageNumber++;        
        cover.content?.forEach(cont => {
            if(contentIsImage(cont.content)) {
                insertImagePng(cont, pdfDoc, page, pageNumber, 0, page.getHeight(), fonts);
            } else {
                if(contentIsText(cont.content)) {
                    insertText(cont, page, fonts, pageNumber, 0, page.getHeight(), pdfDoc);
                } else {
                    if(contentIsSvgPath(cont.content)){
                        insertSvgPath(cont, page, fonts, pageNumber, 0, page.getHeight(), pdfDoc);
                    } else {
                        if(contentIsChart(cont.content)) {
                            insertChart(cont, page, fonts, pageNumber, 0, page.getHeight(), pdfDoc);
                        } else {
                            if(contentIsRow(cont.content)){
                                insertRow(cont, page, fonts, pageNumber, 0, page.getHeight(), pdfDoc);
                            }else {
                                insertTable(cont, page, fonts, pageNumber, 0, page.getHeight(), pdfDoc);
                            }                            
                        }
                        
                    }
                }  
            }             
        });
    }

    return pageNumber;
}

async function generateDocumentContent(pdfDoc: PDFDocument, fonts: PDFFont[], docContent: DocumentContent, pageNumber: number) {
    let currentPage: PDFPage;
    let lastContentPosition: number = 0;
    let availableHeightInPage: number = 0;
    for(const section of docContent.sections){

        if(!currentPage || section.isFullPage) {
            [currentPage, pageNumber, availableHeightInPage] = createPage(pdfDoc, pageNumber, fonts, docContent);
        }

        const firstElementPosition = getFirstElementPosition(section, currentPage, lastContentPosition);
        
        if(lastContentPosition > firstElementPosition){
            [currentPage, pageNumber, availableHeightInPage] = createPage(pdfDoc, pageNumber, fonts, docContent);
            lastContentPosition = 0;
        }

        for(const cont of section.content ??[]){
            if(contentIsImage(cont.content)) {
                [lastContentPosition, currentPage, pageNumber] = await insertImagePng(cont, pdfDoc, currentPage, pageNumber, lastContentPosition, availableHeightInPage, fonts, docContent);
            } else {
                if(contentIsText(cont.content)){
                    [lastContentPosition, currentPage, pageNumber] = insertText(cont, currentPage, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc, docContent);
                } else {
                    if(contentIsSvgPath(cont.content)){
                        [lastContentPosition, currentPage, pageNumber] = insertSvgPath(cont, currentPage, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc, docContent);
                    } else {
                        if(contentIsChart(cont.content)){
                            [lastContentPosition, currentPage, pageNumber] = await insertChart(cont, currentPage, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc, docContent);
                        } else {
                            if(contentIsRow(cont.content)){
                                [lastContentPosition, currentPage, pageNumber] = await insertRow(cont, currentPage, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc,docContent);
                            }else {
                                [lastContentPosition, currentPage, pageNumber] = insertTable(cont, currentPage, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc, docContent);
                            }                            
                        }
                    }                    
                }                
            } 
        }
    }
}

function insertSvgPath(content: Content, page: PDFPage, fonts: PDFFont[], pageNumber: number, lastContentPosition: number, availableHeightInPage: number, pdfDoc: PDFDocument, docContent?: DocumentContent): [number, PDFPage, number, number, number] {
    const svgPath = <SvgPath>content.content;
    const color = isHexColor(svgPath.color) ? convertHexToRGB(svgPath.color) : svgPath.color;
    const posY = content.isRelative ?  lastContentPosition + content.posY : content.posY;
    page.drawSvgPath(svgPath.path, {color:rgb(color.red/256,color.green/256,color.blue/256), x: content.posX, y: page.getHeight()-posY-svgPath.height})

    return [posY+svgPath.height, page, pageNumber, content.posX + svgPath.width, svgPath.height];
}

function insertTable(content: Content, page: PDFPage, fonts: PDFFont[], pageNumber: number, lastContentPosition: number, availableHeightInPage: number, pdfDoc: PDFDocument, docContent?: DocumentContent): [number, PDFPage, number, number, number] {
    const dataTable = <Table> content.content;
    const headerFont = dataTable.header.font ? fonts.find(f => f.name === dataTable.header.font) : fonts.find(f => f.name === dataTable.font);
    const dataFont = fonts.find(f => f.name === dataTable.font);
    const headerHeight = dataTable.lineHeight + 8;
    let contentHeight = headerHeight;
    let posX = content.posX;
    let posY = content.isRelative ?  lastContentPosition + content.posY : content.posY;
    dataTable.header.columns.forEach(col => {        
        page.drawRectangle({height: headerHeight, borderColor: rgb(0,0,0), width: col.width, x: posX,y: page.getHeight()-posY-headerHeight, color: rgb(0,0,0)})
        page.drawText(col.displayName, {color: rgb(1,1,1), font: headerFont, lineHeight: dataTable.lineHeight, maxWidth: col.width-dataTable.marginLeft, x: posX+dataTable.marginLeft, y: page.getHeight()-posY-dataTable.lineHeight,size: dataTable.header.fontSize || dataTable.fontSize})
        posX = posX + col.width;
    });

    lastContentPosition = posY + headerHeight;
    
    dataTable.data.forEach(item => {
        posX = content.posX;
        posY = lastContentPosition;
        dataTable.header.columns.forEach(col => {     
            const textStr = item[col.propertyName] ? item[col.propertyName] : '';
            const textWidth = dataFont ? (t: string) => dataFont.widthOfTextAtSize(t, dataTable.fontSize) : (t: string) => 10;
            const textLines = breakTextIntoLines(textStr, pdfDoc.defaultWordBreaks, col.width-dataTable.marginLeft, textWidth);
            lastContentPosition = (textLines.length < 2) ? lastContentPosition : (lastContentPosition + (textLines.length* dataTable.lineHeight));
            page.drawText(textStr, {color: rgb(0,0,0), font: dataFont, lineHeight: dataTable.lineHeight, maxWidth: col.width-dataTable.marginLeft, x: posX+dataTable.marginLeft, y: page.getHeight()-posY-dataTable.lineHeight,size: dataTable.fontSize});
            posX = posX + col.width;
        });
        page.drawLine({start: {x:content.posX, y: page.getHeight()-lastContentPosition-4}, end:{x: dataTable.header.columns.reduce((sum, current) => sum + current.width, 0)+content.posX, y: page.getHeight()-lastContentPosition-4}, color: rgb(102/255,102/255,102/255),thickness:1});
        lastContentPosition = (posY === lastContentPosition) ? lastContentPosition + dataTable.lineHeight : lastContentPosition;
    })

    return [contentHeight + posY, page, pageNumber, posX, contentHeight];
}

function contentIsImage(content: any): content is Image {
    return 'image' in content;
}

function contentIsText(content: any): content is Text {
    return 'text' in content;
}

function contentIsSvgPath(content: any): content is SvgPath {
    return 'path' in content;
}

function contentIsChart(content: any): content is Chart {
    return 'chart' in content
}

function contentIsRow(content: any): content is Row {
    return 'rowContent' in content;
}

function createPage(pdfDoc: PDFDocument, pageNumber: number, fonts: PDFFont[], content?: DocumentContent): [PDFPage, number, number] {
    const newPage = pdfDoc.addPage(PageSizes.A4);
    pageNumber++;
    addHeader(newPage, pdfDoc, fonts, pageNumber, content?.header);
    addFooter(newPage, pdfDoc, fonts, pageNumber, content?.footer);
    const availableHeightInPage = newPage.getHeight() - (content?.footer?.height || 0) - (content?.header?.height || 0);
    return [newPage, pageNumber, availableHeightInPage];
}

function getFirstElementPosition(section: Section, page: PDFPage, lastContentPosition: number): number {    
    return Math.min.apply(Math, section.content?.map(i => {return i.isRelative ? i.posY+lastContentPosition: i.posY}) || [page.getHeight()]);
}

function addHeader(pdfPage: PDFPage, pdfDoc: PDFDocument, fonts: PDFFont[], pageNumber: number, header?: Header) {
    if(header){
        header.content?.forEach(cont => {
            if(contentIsImage(cont.content)) {
                insertImagePng(cont, pdfDoc, pdfPage, pageNumber, 0, pdfPage.getHeight(), fonts);
            } else {
                if(contentIsText(cont.content)) {
                    insertText(cont, pdfPage, fonts, pageNumber, 0, pdfPage.getHeight(), pdfDoc);
                } else {
                    if(contentIsSvgPath(cont.content)){
                        insertSvgPath(cont, pdfPage, fonts, pageNumber, 0, pdfPage.getHeight(), pdfDoc);
                    } else {
                        insertTable(cont, pdfPage, fonts, pageNumber, 0, pdfPage.getHeight(), pdfDoc);
                    }
                }                
            }
        });
    }
}

function addFooter(pdfPage: PDFPage, pdfDoc: PDFDocument, fonts: PDFFont[], pageNumber: number, footer?: Footer) {
    if(footer){
        footer.content?.forEach(cont => {
            if(contentIsImage(cont.content)) {
                insertImagePng(cont, pdfDoc, pdfPage, pageNumber, 0, pdfPage.getHeight(), fonts);
            } else {
                if(contentIsText(cont.content)) {
                    insertText(cont, pdfPage, fonts, pageNumber, 0, pdfPage.getHeight(), pdfDoc);
                } else {
                    if(contentIsSvgPath(cont.content)){
                        insertSvgPath(cont, pdfPage, fonts, pageNumber, 0, pdfPage.getHeight(), pdfDoc);
                    } else {
                        insertTable(cont, pdfPage, fonts, pageNumber, 0, pdfPage.getHeight(), pdfDoc);
                    }
                }  
            }
        });
    }
}

async function insertChart(content: Content, page: PDFPage, fonts: PDFFont[], pageNumber: number, lastContentPosition: number, availableHeightInPage: number, pdfDoc: PDFDocument, docContent?: DocumentContent): Promise<[number, PDFPage, number, number, number]> {
    const chartData = <Chart> content.content;
    const headerHeight = (chartData.header.height ?? (chartData.header.fontSize ?? 10)) + 12;
    let posX = content.posX;
    let posY = content.isRelative ?  lastContentPosition + content.posY : content.posY;    

    const img = await pdfDoc.embedPng(chartData.chart);
    const scale = img.scaleToFit(chartData.width-chartData.margin,chartData.height);
    const headerBorderColor = chartData.header.borderColor ? (isHexColor(chartData.header.borderColor) ? convertHexToRGB(chartData.header.borderColor) : chartData.header.borderColor) :  <ColorRGB> {blue:0, green:0, red:0};
    const headerFillColor = chartData.header.fillColor ? (isHexColor(chartData.header.fillColor) ? convertHexToRGB(chartData.header.fillColor) : chartData.header.fillColor) :  <ColorRGB> {blue:0, green:0, red:0};
    const headerFontColor = chartData.header.fontColor ? (isHexColor(chartData.header.fontColor) ? convertHexToRGB(chartData.header.fontColor) : chartData.header.fontColor) :  <ColorRGB> {blue:256, green:256, red:256};
    const headerFont = fonts.find(f => f.name === chartData.header.font);
    const chartBorderColor = chartData.borderColor ? (isHexColor(chartData.borderColor) ? convertHexToRGB(chartData.borderColor) : chartData.borderColor) :  <ColorRGB> {blue:0, green:0, red:0};
    const chartFillColor = chartData.fillColor ? (isHexColor(chartData.fillColor) ? convertHexToRGB(chartData.fillColor) : chartData.fillColor) :  <ColorRGB> {blue:256, green:256, red:256};

    if(content.isRelative && ((scale.height +chartData.header.height) > (availableHeightInPage-lastContentPosition))){
        [page, pageNumber, availableHeightInPage] = createPage(pdfDoc, pageNumber, fonts, docContent);
    }

    page.drawRectangle({height: headerHeight, borderColor: rgb(headerBorderColor.red/256,headerBorderColor.green/256,headerBorderColor.blue/256), width: chartData.width, x: posX,y: page.getHeight()-posY-headerHeight, color: rgb(headerFillColor.red/256,headerFillColor.green/256,headerFillColor.blue/256), borderWidth:1})
    page.drawText(chartData.header.text, {color: rgb(headerFontColor.red/256,headerFontColor.green/256,headerFontColor.blue/256), font: headerFont, lineHeight: chartData.header.lineHeight, maxWidth: chartData.width-chartData.header.marginLeft, x: posX+chartData.header.marginLeft, y: page.getHeight()-posY-chartData.header.height,size: chartData.header.fontSize || 10})
    posY = posY + headerHeight;
    page.drawRectangle({height: scale.height, borderColor: rgb(chartBorderColor.red/256,chartBorderColor.green/256,chartBorderColor.blue/256), width: chartData.width, x: posX,y: page.getHeight()-posY-scale.height, color: rgb(chartFillColor.red/256,chartFillColor.green/256,chartFillColor.blue/256), borderWidth:1})
    const centre = (chartData.width - scale.width)/2;
    page.drawImage(img, { x: posX+centre, y: page.getHeight()-posY-scale.height+1, height: scale.height-2, width: scale.width});

    return Promise.resolve([posY+scale.height, page, pageNumber, posX+chartData.width, scale.height]);
}

async function insertRow(content: Content, page: PDFPage, fonts: PDFFont[], pageNumber: number, lastContentPosition: number, availableHeightInPage: number, pdfDoc: PDFDocument, docContent?: DocumentContent): Promise<[number, PDFPage, number, number]> {
    const rowContent = <Row>content.content;
    let lastContentPositionInRow = lastContentPosition;
    let maxContentPositionInRow = lastContentPosition;
    let currentPageNumber = pageNumber;   
    let posX = content.posX;
    let lastContentHeight = 0 

    for(const cont of rowContent.rowContent){
        cont.posX = cont.posX + posX;
        if(contentIsImage(cont.content)) {
            [lastContentPositionInRow, page, pageNumber, posX, lastContentHeight] = await insertImagePng(cont, pdfDoc, page, pageNumber, lastContentPosition, availableHeightInPage, fonts, docContent);            
        } else {
            if(contentIsText(cont.content)){     
                [lastContentPositionInRow, page, pageNumber, posX, lastContentHeight] = insertText(cont, page, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc, docContent);
            } else {
                if(contentIsSvgPath(cont.content)){
                    [lastContentPositionInRow, page, pageNumber, posX, lastContentHeight] = insertSvgPath(cont, page, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc, docContent);
                } else {
                    if(contentIsChart(cont.content)){                        
                        [lastContentPositionInRow, page, pageNumber, posX, lastContentHeight] = await insertChart(cont, page, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc, docContent);                        
                    } else {
                        if(contentIsRow(cont.content)){
                            [lastContentPositionInRow, page, pageNumber, lastContentHeight] = await insertRow(cont, page, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc, docContent);
                        }else {
                            [lastContentPositionInRow, page, pageNumber, posX, lastContentHeight] = insertTable(cont, page, fonts, pageNumber, lastContentPosition, availableHeightInPage, pdfDoc, docContent);
                        }                        
                    }
                }                    
            }            
        }
        if(currentPageNumber < pageNumber) {
            currentPageNumber = pageNumber;
            lastContentPosition = lastContentPositionInRow - lastContentHeight - content.posY;
            maxContentPositionInRow = lastContentPosition;
        } else {
            maxContentPositionInRow = (lastContentPositionInRow > maxContentPositionInRow) ? lastContentPositionInRow : maxContentPositionInRow; 
        }  
        
    }    

    return Promise.resolve([maxContentPositionInRow, page, pageNumber, lastContentHeight]);
}