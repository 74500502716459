export enum Role {
    ThirdParty = 'ThirdParty',
    Manager = 'Manager',
    Assistant = 'Assistant',
    Collaborator = 'Collaborator',
    Owner = 'Owner',
    Refinitiv = 'Refinitiv',
    AMLUpdate = 'AMLUpdate',
    EquifaxWS = 'EquifaxWS',
    Auditor = 'Auditor',
    MemberCheck = 'MemberCheck'
}

export interface RolDTO{
    Id: string;
    Name: string;
    NormalizedName: string;
    ConcurrencyStamp: string;
}
