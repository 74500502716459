import { Campaign } from "@app/models/Campaign";
import { CampaignDto } from "src/proxy/model/campaignDto";
import { mapClient } from "./client.mappings";
import { mapQuestionaryDtoToQuestionaryDTO } from "./questionary.mappings";
import { mapThirdParty } from "./third-party.mappings";

export function mapCampaign(dto: CampaignDto, currentLanguage: string): Campaign {
    if (!dto) return null;

    return <Campaign> {
        amount: dto.Amount,
        campaignStatus: dto.Status,
        canEdit: dto.CanEdit,
        endDate: dto.EndDate,
        fileName: dto.FileName,
        fileUri: dto.FileUri,
        file: null,
        id: dto.Id,
        introduction: dto.Introduction,
        name: dto.Name,
        startDate: dto.StartDate,
        clientId: dto.ClientId,
        questionaryId: dto.QuestionaryId,
        thirdParties: dto.ThirdParties?.map(tp => mapThirdParty(tp, currentLanguage, dto.Progress)) ?? [],
        client: dto.Client ? mapClient(dto.Client, currentLanguage) : null,
        questionary: dto.Questionary ? mapQuestionaryDtoToQuestionaryDTO(dto.Questionary, currentLanguage) : null,
        userResponsibleId: dto.UserResponsibleId,
        discardThreshold: dto.DiscardThreshold,
        approveThreshold: dto.ApproveThreshold,
    };
}