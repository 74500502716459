<div class="o-wrapper">
  <main id="content">
    <!--encabezado de sección-->
    <div class="c-head-section c-head-section--tab bg-cream">
      <!--parte superior-->
      <div class="c-head-section--tab__top">
        <div class="c-head-section__left">
          <div class="c-head-section--tab__info">
            <h2 class="c-head-section--tab__title">
              {{ scoringDetails?.name || "" }}
            </h2>
            <div class="c-head-section--tab-data">
              <div class="c-head-section--tab-data__item">
                <div>
                  {{ "ScoringDetailsAMLUpdate.subtitle" | translate }} {{ scoringDetails?.name || "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-head-section__right">
          <button type="button" class="c-button" (click)="save()">
            {{ "ScoringDetailsAMLUpdate.save" | translate }}
          </button>
        </div>
      </div>
      <!--volver a listado-->
      <div class="c-go-back">
        <a [routerLink]="" (click)="back()" class="c-go-back__button">
          <span class="c-go-back__arrow" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
              <path
                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                transform="translate(-5-6.01)"
              />
            </svg> </span
          >{{ "ScoringDetailsAMLUpdate.back" | translate }}
        </a>
      </div>
    </div>
    <div class="o-space-lateral o-scroll">
      <div class="c-form c-form--space" style="height: 64vh;">
        <iframe id="ifrm" type="application/pdf" width="100%" height="100%">
        </iframe>
        <!-- <equifax-ws-individual [scoringDetails]="scoringDetails"></equifax-ws-individual> -->
      </div>
    </div>
  </main>
</div>
