<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'signRisklistDialog.title'|translate}}</h1>
        <button mat-button (click)="onClose()" class="c-dialog__close" attr.aria-label="{{'signRisklistDialog.closeModalWindow' | translate}}">
          <span aria-hidden="true"> 
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                  <path d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243" transform="translate(-309.86-406.47)"/>
              </svg>
          </span>
        </button>
    </header>
    <div class="c-dialog__content u-pb-1">
        <h2 class="c-dialog__subtitle">{{'signRisklistDialog.subtitle'|translate}}</h2>
        <p class="o-text">{{'signRisklistDialog.text'|translate}}</p>
    </div>
    <footer class="c-dialog__footer">
        <button class="c-button c-button--light" [mat-dialog-close]="false">
            {{'signRisklistDialog.continueEditing'|translate}}
        </button>
        <button class="c-button c-button--green" [mat-dialog-close]="true">
            {{'signRisklistDialog.signAndSend'|translate}}
        </button>
    </footer>
</div>