import { Component, Input, OnInit } from '@angular/core';
import { ScoringToolsService } from '@app/services/scoring-tools.service';
import { Scoring } from '@app/models/scoring-tools/scoring';
import { Router } from '@angular/router';
import { ScreeningProvider } from '../../models/ScreeningProvider';
import { ScreeningProviderService } from '../../services/screening-provider.service';
import { AmlUpdateScoringService } from '../../services/aml-update-scoring.service';
import { AMLUpdateLink } from '../../models/AMLUpdateLink';
import { EquifaxWSLink } from '../../models/EquifaxWS/EquifaxWSLink';
import { EquifaxWSScoringService } from '../../services/equifax-ws-scoring.service';
import { MemberCheckScoringService } from '../../services/member-check-scoring.service';
import { MemberCheckLink } from '../../models/member-check/member-check-link';

@Component({
  selector: 'app-screening-dashboard',
  templateUrl: './screening-dashboard.component.html',
  styleUrls: ['./screening-dashboard.component.scss'],
})
export class ThirdPartyScreeningDashboardComponent implements OnInit {
  @Input() thirdPartyId?: number;

  isLoading = true;
  refinitivScorings: Scoring[] = [];
  amlUpdateScorings: AMLUpdateLink[] = [];
  equifaxWSScorings: EquifaxWSLink[] = [];
  memberCheckScorings: MemberCheckLink[] = [];
  screeningProviders: ScreeningProvider[];
  ScreeningProvider = ScreeningProvider;


  constructor(
    private scoringToolsService: ScoringToolsService,
    private screeningProviderService: ScreeningProviderService,
    private amlUpdateScoringService: AmlUpdateScoringService,
    private equifaxWSScoringService: EquifaxWSScoringService,
    private memberCheckScoringService: MemberCheckScoringService,
    private router: Router
  ) {}

  ngOnInit() {
    this.screeningProviders = this.screeningProviderService.clientProviders();
    this.getScorings();
  }

  refreshScoring() {
    this.isLoading = true;
    this.getScorings();
  }

  getScorings() {
    if(this.screeningProviders.includes(ScreeningProvider.Refinitiv)){
      this.scoringToolsService
      .getScoringForThirdParty(this.thirdPartyId)
      .subscribe(
        (res) => {
          this.refinitivScorings = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
    }

    if(this.screeningProviders.includes(ScreeningProvider.AMLUpdate)){
      this.amlUpdateScoringService
      .getScoringLinks(this.thirdPartyId)
      .subscribe(
        (res) => {
          this.amlUpdateScorings = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
    }

    if(this.screeningProviders.includes(ScreeningProvider.EquifaxWS)){
      this.equifaxWSScoringService
      .getScoringLinks(this.thirdPartyId)
      .subscribe(
        (res) => {
          this.equifaxWSScorings = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
    }

    if(this.screeningProviders.includes(ScreeningProvider.MemberCheck)){
      this.memberCheckScoringService
      .getScoringLinks(this.thirdPartyId)
      .subscribe(
        (res) => {
          this.memberCheckScorings = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
    }
  }

  newScoring() {
    if (this.thirdPartyId) {
      this.router.navigate(['scoring-candidates', this.thirdPartyId]);
    } else {
      this.router.navigate(['scoring-candidates']);
    }
  }
}
