export enum InherentRiskEnum {
  notSpecified = 0,
  low = 1,
  mid = 2,
  high = 3
}

export const InherentRiskEnumValues = Object.values(InherentRiskEnum).filter(value => !isNaN(+value)) as InherentRiskEnum[];

export function mapInherentRiskToTranslationKey(inherentRisk: InherentRiskEnum): string {
  switch (inherentRisk) {
    case InherentRiskEnum.notSpecified:
      return 'inherentRisk.notSpecified';
    case InherentRiskEnum.low:
      return 'inherentRisk.low';
    case InherentRiskEnum.mid:
      return 'inherentRisk.mid';
    case InherentRiskEnum.high:
      return 'inherentRisk.high';
    default:
      return 'inherentRisk.notSpecified';
  }
}
