import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RiskallayUserDTO, RiskallayUserUpdateDTO } from '../../models/RiskallayUser';
import { Toaster } from 'ngx-toast-notifications';
import { RemoteService } from '@app/services/remote.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '@app/services/configuration.service'
import { parsearErroresAPI } from '@app/utilities/utilities';
import { AuthenticationService } from '@app/services/authentication.service';
import { GlobalService } from '@app/services/global.service';
import { AwsS3Service } from '@app/services/awsS3.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogUpdatePasswordComponent } from './dialog-update-password/dialog-update-password.component';
import { mapPositionToTranslationKey, Position, PositionValues } from '@app/models/Position';
import { Language, LanguageValues, mapLanguageToTranslationKey } from '@app/models/Language';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})

export class MyAccountComponent implements OnInit {

  form: FormGroup;
  errores: string[] = [];
  userdata: RiskallayUserDTO;

  typePassword: boolean;
  typePasswordNew: boolean;
  isShow = false;
  isShowNew = false;

  uploadButtonText: string;
  selectedIconFileUri = null;
  selectedIconFileName = null;
  selectedIconFile = null;
  avatarUri: string;
  avatarUrl: string;
  languages: Language[] = [];

  dataUpdatedMessage: string;
  errorUpdatingMessage: string;

  positions: Position[];

  constructor(
    private fb: FormBuilder,
    private remoteService: RemoteService,
    private toaster: Toaster,
    private translate: TranslateService,
    private configService: ConfigurationService,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private awsS3Service: AwsS3Service,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    this.positions = PositionValues;
    this.languages = LanguageValues;

    this.form = this.fb.group({
      Name: new FormControl('', Validators.required),
      SurName: new FormControl('', Validators.required),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Position: new FormControl('', Validators.required),
      OldPassword: new FormControl(null, []),
      NewPassword: new FormControl(null, []),
      Foto: new FormControl(null, []),
      Language: new FormControl(null, Validators.required),
      TwoFactorEnabled: new FormControl(false, Validators.required)
    });

    await this.loadInitialData();
    this.form.get('Name').setValue(this.userdata.Name);
    this.form.get('SurName').setValue(this.userdata.SurName);
    this.form.get('Email').setValue(this.userdata.Email);
    this.form.get('Position').setValue(this.userdata.Position);
    this.form.get('Foto').setValue({ 'FileUri': this.selectedIconFileUri, 'FileName': this.selectedIconFileName, 'File': this.selectedIconFile });
    this.form.get('Language').setValue(this.userdata.Language);
    this.form.get('TwoFactorEnabled').setValue(this.userdata.TwoFactorEnabled);
    this.form.get('Email').disable();
    this.form.get('OldPassword').disable();
    this.form.get('NewPassword').disable();

    this.translate.onLangChange.subscribe(_ => {
      this.updateTranslatedValues();
    });

    this.updateTranslatedValues();
  }

  async loadInitialData() {
    let promiseUserData = this.getUserDataP();

    let promises = [
      promiseUserData,   // 0
    ];

    await Promise.all(promises).then(values => {
      this.getUserData(values[0]);
    });

  }

  getUserDataP() {
    return this.remoteService.getRequest("Users/GetUserData");
  }

  getUserData(r) {
    if (r !== null && r !== undefined) {
      this.userdata = (<RiskallayUserDTO>r);

      if (this.userdata.ProfilePhotoFileUri !== null || this.userdata.ProfilePhotoFileName !== null) {
        this.selectedIconFileUri = this.userdata.ProfilePhotoFileUri;
        this.selectedIconFileName = this.userdata.ProfilePhotoFileName;
      } else {
        this.selectedIconFileUri = null;
        this.selectedIconFileName = null;
      }
    }
  }

  update() {
    let item = (<RiskallayUserUpdateDTO>{});

    item.Id = this.userdata.Id;
    item.Name = this.form.get("Name").value;
    item.SurName = this.form.get("SurName").value;
    item.Email = this.form.get("Email").value;
    item.Position = this.form.get("Position").value;
    item.OldPassword = this.form.get("OldPassword").value;
    item.NewPassword = this.form.get("NewPassword").value;
    item.ProfilePhotoFileName = this.selectedIconFileName;
    item.ProfilePhotoFileUri = this.selectedIconFileUri;
    item.LanguageId = this.form.get("Language").value;
    item.TwoFactorEnabled = this.form.get("TwoFactorEnabled").value;
    if (!!this.selectedIconFile) {
      this.configService.getFileStorageKey(item.ProfilePhotoFileName)
        .then(r => {
          item.ProfilePhotoFileUri = r.FileId;
          this.awsS3Service.uploadFileToS3(r.FileUri, this.selectedIconFile).subscribe();
        }).then(r => {
          this.updateAccountInfo(item);
        });
    } else {
      this.updateAccountInfo(item);
    }
  }

  private updateAccountInfo(item) {
    this.configService.updateAccountInfo(item)
      .subscribe(response => {
        this.authenticationService.refreshToken().subscribe(response => {
          this.globalService.chageLanguage(this.authenticationService.getFieldJWT('Language'));
        });

        this.loadInitialData();
        this.toaster.open({ text: this.dataUpdatedMessage, duration: 6000, type: 'info' });
      },
        errores => {
          var erroresList = parsearErroresAPI(errores);
          this.toaster.open({ text: erroresList.length > 0 ? erroresList[0] : this.errorUpdatingMessage, duration: 6000, type: 'danger' });
        });
  }

  loadAvatarImage() {
    if (this.selectedIconFileUri !== this.avatarUri) {
      this.remoteService.getTokenFileUri(this.selectedIconFileUri).subscribe(res => this.avatarUrl = res.url);
      this.avatarUri = this.selectedIconFileUri;
    }

    return this.avatarUrl;
  }

  changePassword(): void {
    this.dialog.open(DialogUpdatePasswordComponent, {});
  }

  selectDocument(resp) {
    this.selectedIconFile = resp.file;
    this.selectedIconFileName = resp.file.name;
  }

  private updateTranslatedValues() {
    this.translate.get('MiCuenta.foto.valor').subscribe((translation) => {
      this.uploadButtonText = translation;
    });

    this.translate.get('MiCuenta.dataUpdated')
      .subscribe(translation => {
        this.dataUpdatedMessage = translation;
      });

    this.translate.get('MiCuenta.errorUpdating')
      .subscribe(translation => {
        this.errorUpdatingMessage = translation;
      });
  }

  getPositionTranslationKey(position: Position) {
    if (position == undefined) {
      return '';
    }
    return mapPositionToTranslationKey(position);
  }

  getLanguageTranslationKey(language: Language) {
    if (language == undefined) {
      return '';
    }
    return mapLanguageToTranslationKey(language);
  }
}
