import { Component, Input, OnChanges } from '@angular/core';
import { QueryResult } from '@app/models/common/queryResult';
import { Role } from '@app/modules/screening-hub/models/GesintelScreening/Role';

@Component({
  selector: 'aml-update-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class AMLUpdateRolesComponent implements OnChanges {
  @Input() roles: Role[];

  displayedColumns: string[] = [
    'type',
    'category',
    'role',
    'startDate',
    'endDate'
  ];

  queryResult: QueryResult<Role> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() { }

  ngOnChanges(): void {
    this.getRoles();
  }

  getRoles() {
    if (this.roles) {
      this.queryResult.items = this.roles
        .slice(
          (this.query.Page - 1) * this.query.PageSize,
          this.query.Page * this.query.PageSize
        );
      this.queryResult.totalItems = this.roles.length;
    }
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getRoles();
  }
}
