import { AMLUpdateLinkDto } from "src/proxy/model/aMLUpdateLinkDto";
import { AMLUpdateLink } from "../../models/AMLUpdateLink";

export function mapAMLUpdateLink(dto: AMLUpdateLinkDto): AMLUpdateLink {
  if (!dto) return null;

  return <AMLUpdateLink>{
    creationTime: dto.CreationTime,
    id: dto.Id,
    name: dto.Name,
    rut: dto.Rut
  }
}
