import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CampaignProxyClient } from "src/proxy/apiCampaign/campaign.proxy";
import { QuestionariesProxyClient } from "src/proxy/apiQuestionaries/questionaries.proxy";
import { CampaignDto } from "src/proxy/model/campaignDto";
import { EvaluationQuestionaryDto } from "src/proxy/model/evaluationQuestionaryDto";
import { ColorHex } from "../pdf-template-generator/Models/colorHex";
import { Content } from "../pdf-template-generator/Models/content";
import { CoverPage } from "../pdf-template-generator/Models/coverPage";
import { DocumentContent } from "../pdf-template-generator/Models/documentContent";
import { Font } from "../pdf-template-generator/Models/font";
import { Footer } from "../pdf-template-generator/Models/footer";
import { Header } from "../pdf-template-generator/Models/header";
import { Image } from "../pdf-template-generator/Models/image";
import { Row } from "../pdf-template-generator/Models/row";
import { Section } from "../pdf-template-generator/Models/section";
import { SvgPath } from "../pdf-template-generator/Models/svgPath";
import { Text } from "../pdf-template-generator/Models/text";
import { TextUnderline } from "../pdf-template-generator/Models/textUnderline";
import { templateGenerator } from "../pdf-template-generator/template-generator";
import { UsersProxyClient } from "src/proxy/apiUser/users.proxy";
import { TranslateDescriptionPipe } from "@app/shared/pipes/translate-description.pipe";
import { QuestionaryBlockDto } from "src/proxy/model/questionaryBlockDto";
import { QuestionaryThirdPartyDto } from "src/proxy/model/questionaryThirdPartyDto";
import { EvaluationQuestionaryRiskDto } from "src/proxy/model/evaluationQuestionaryRiskDto";
import { QuestionDto } from "src/proxy/model/questionDto";
import { EvaluationQuestionaryRiskAnswerDto } from "src/proxy/model/evaluationQuestionaryRiskAnswerDto";

@Injectable()
export class ThirdPartyQuestionaryReportService {
  locale: string;

  constructor(private campaignClient: CampaignProxyClient,
    private translateDescription: TranslateDescriptionPipe,
    private questionariesClient: QuestionariesProxyClient,
    private userClient: UsersProxyClient,
    private translate: TranslateService) { }

  async getReport(campaignId: number, thirdPartyId: number): Promise<[string, Uint8Array]> {
    this.locale = this.translate.currentLang.split("-")[0];
    let translations = await this.translate.get("thirdPartyResponsesReport").toPromise();
    const campaignDetails = await this.campaignClient.campaignGetByIdAndUserIdCampaignGet(campaignId).toPromise();
    const campaign = await this.campaignClient.campaignIdCampaignGet(campaignId).toPromise();
    const thirdCampaignDetails = await this.questionariesClient.questionariesGetScoresByThirdPartyAndCampaignIdThirdPartyIdCampaignGet(thirdPartyId, campaignId).toPromise();
    const thirdPartyResponses = await this.questionariesClient.questionariesGetByThirdPartyAndCampaignIdThirdpartyIdCampaignGet(thirdPartyId, campaignId).toPromise();
    const userDetails = await this.userClient.usersGetUserDataGet().toPromise();
    const username = `${userDetails.Name} ${userDetails.SurName}`;
    const printDate = new Date();
    const fonts = await this.getReportFonts();
    const coverPage = await this.getReportCoverPage(thirdCampaignDetails.ThirdParty.CompanyName, userDetails.Client.Name, username, printDate, campaignDetails.Name, translations);
    const pageHeader = await this.getPageHeader(translations);
    const pageFooter = this.getPageFooter(userDetails.Client.Name, printDate, username, translations);
    const evaluationSection = this.getEvaluationSection(campaignDetails, campaign.Questionary.QuestionaryBlocks.reduce((sum, f) => sum + f.Questions.length, 0), translations);
    const responsesSection = this.getResponsesSection(thirdPartyResponses, translations)
    const disclaimerSection = this.getDisclaimerSection(thirdCampaignDetails, translations);

    const docContent = <DocumentContent>{
      header: pageHeader,
      footer: pageFooter,
      sections: [evaluationSection, responsesSection, disclaimerSection]
    }

    return [`Riskallay-Questionary-Report-${thirdCampaignDetails.ThirdParty.CompanyName}-${campaignDetails.Name}.pdf`, await templateGenerator(fonts, docContent, coverPage)];
  }

  async getReportFonts(): Promise<Font[]> {
    let fonts: Font[] = [];
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Medium.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Medium' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Regular.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Regular' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Bold.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Bold' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Poppins-SemiBold.ttf').then((res) => res.arrayBuffer()), name: 'Poppins-SemiBold' });

    return fonts;
  }

  async getReportCoverPage(thirdName: string, companyName: string, username: string, printDate: Date, evaluationName: string, translations: any): Promise<CoverPage> {
    return <CoverPage>{
      content: [
        <Content>{ content: <Image>{ image: await fetch('/assets/report/background.png').then((res) => res.arrayBuffer()), height: 842, width: 595, customHeight: 1.73 }, posX: 0, posY: 0, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/coverImageThirdEval.png').then((res) => res.arrayBuffer()), height: 398, width: 515 }, posX: 40, posY: 24, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/wave.png').then((res) => res.arrayBuffer()), height: 842, width: 595 }, posX: 0, posY: 419, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height: 42, width: 180 }, posX: 40, posY: 454, isRelative: false },
        <Content>{ content: <Text>{ text: translations.cover.report, font: 'Roboto-Medium', fontSize: 24, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 536, isRelative: false },
        <Content>{ content: <Text>{ text: thirdName, font: 'Poppins-SemiBold', fontSize: 40, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 585, isRelative: false },
        <Content>{
          content: <Text>{
            text: translations.cover.intro.replace('${thirdName}', thirdName).replace('${evaluationName}', evaluationName), font: 'Roboto-Regular',
            fontSize: 10, fontColor: <ColorHex>{ hexCode: '666666' }, lineHeight: 16, maxWidth: 335
          }, posX: 40, posY: 660, isRelative: false
        },
        <Content>{ content: <Text>{ text: translations.infoCollectedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 660, isRelative: false },
        <Content>{ content: <Text>{ text: companyName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 670, isRelative: false },
        <Content>{ content: <Text>{ text: translations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 690, isRelative: false },
        <Content>{ content: <Text>{ text: username, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 700, isRelative: false },
        <Content>{ content: <Text>{ text: translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 720, isRelative: false },
        <Content>{ content: <Text>{ text: formatDate(printDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 730, isRelative: false },
      ]
    };
  }

  async getPageHeader(tranlations: any): Promise<Header> {
    return <Header>{
      height: 67,
      content: [<Content>{ content: <Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height: 31, width: 133 }, posX: 40, posY: 24, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.confidential, font: 'Roboto-Medium', fontSize: 10, fontColor: <ColorHex>{ hexCode: 'f8b410' }, lineHeight: 16 }, posX: 474, posY: 31.5, isRelative: false }
      ]
    };
  }

  getPageFooter(companyName: string, printDate: Date, username: string, tranlations: any): Footer {
    return <Footer>{
      height: 71,
      content: [<Content>{ content: <SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height: 1, color: <ColorHex>{ hexCode: 'd9d9d9' }, width: 515 }, posX: 40, posY: 771, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.infoCollectedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 783, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 796, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 809, isRelative: false },
      <Content>{ content: <Text>{ text: companyName, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 154, posY: 783, isRelative: false },
      <Content>{ content: <Text>{ text: username, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 154, posY: 796, isRelative: false },
      <Content>{ content: <Text>{ text: formatDate(printDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 154, posY: 809, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.pageNumber, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, lineHeight: 16 }, posX: 522, posY: 783, isRelative: false },
      ]
    };
  }

  getEvaluationSection(campaignDetails: CampaignDto, questionNumber: number, tranlations: any): Section {
    return <Section>{
      isFullPage: false,
      isAbsolute: true,
      content: [<Content>{ content: <Text>{ text: campaignDetails.Name, font: 'Roboto-Bold', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' }, underline: <TextUnderline>{ color: <ColorHex>{ hexCode: 'fdc130' }, thickness: 2 } }, posX: 40, posY: 84, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.assesmentSection.details, font: 'Roboto-Bold', fontSize: 16, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 72, lineHeight: 19 }, posX: 40, posY: 126, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.assesmentSection.selectedChecklist, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 275, lineHeight: 9 }, posX: 130, posY: 126, isRelative: false },
      <Content>{ content: <Text>{ text: campaignDetails.Questionary.Code, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 275, lineHeight: 12 }, posX: 130, posY: 139, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.assesmentSection.questionNumber, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 125.7, lineHeight: 9 }, posX: 130, posY: 167, isRelative: false },
      <Content>{ content: <Text>{ text: questionNumber.toString(), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 125.7, lineHeight: 12 }, posX: 130, posY: 180, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.assesmentSection.assesedThirds, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 125.7, lineHeight: 9 }, posX: 279.7, posY: 167, isRelative: false },
      <Content>{ content: <Text>{ text: campaignDetails.ThirdParties.length.toString(), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 125.7, lineHeight: 12 }, posX: 279.7, posY: 180, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.assesmentSection.amount, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 125.7, lineHeight: 9 }, posX: 429.3, posY: 167, isRelative: false },
      <Content>{ content: <Text>{ text: campaignDetails.Amount.toString(), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 125.7, lineHeight: 12 }, posX: 429.3, posY: 180, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.assesmentSection.startDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 200.5, lineHeight: 9 }, posX: 130, posY: 208, isRelative: false },
      <Content>{ content: <Text>{ text: formatDate(campaignDetails.StartDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 200.5, lineHeight: 12 }, posX: 130, posY: 221, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.assesmentSection.endDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 200.5, lineHeight: 9 }, posX: 354.5, posY: 208, isRelative: false },
      <Content>{ content: <Text>{ text: formatDate(campaignDetails.EndDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 200.5, lineHeight: 12 }, posX: 354.5, posY: 221, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.assesmentSection.remarks, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 425, lineHeight: 9 }, posX: 130, posY: 249, isRelative: false },
      <Content>{ content: <Text>{ text: campaignDetails.Introduction, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 425, lineHeight: 12 }, posX: 130, posY: 262, isRelative: false },
      <Content>{ content: <Text>{ text: tranlations.assesmentSection.attachedDocuments, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 425, lineHeight: 9 }, posX: 130, posY: 16, isRelative: true },
      <Content>{ content: <Text>{ text: campaignDetails.FileName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 425, lineHeight: 12 }, posX: 130, posY: 4, isRelative: true },
      ]
    }
  }

  getResponsesSection(responses: QuestionaryThirdPartyDto, translations: any): Section {
    const contentResponses = responses.QuestionaryBlocks.reduce((arr: Content[], q) => {
      return arr.concat(this.mapQuestionaryBlock(q, responses.EvaluationQuestionary[0].EvaluationQuestionaryRisk.find(r => r.RiskId == q.Risk.Id), translations))
    }, []);
    return <Section>{
      isAbsolute: false,
      isFullPage: false,
      content: [
        <Content>{ content: <Text>{ text: translations.responsesSection.title, font: 'Roboto-Bold', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' }, underline: <TextUnderline>{ color: <ColorHex>{ hexCode: 'fdc130' }, thickness: 2 } }, posX: 40, posY: 84, isRelative: true },
      ].concat(contentResponses)
    };
  }

  mapQuestionaryBlock(questionaryBlock: QuestionaryBlockDto, responses: EvaluationQuestionaryRiskDto, translations: any): Content[] {
    let index = 1;
    const responsesContent = questionaryBlock.Questions.reduce((arr: Content[], q) => {
      return arr.concat(this.mapQuestion(q, responses.EvaluationQuestionaryRiskAnswer.find(r => r.QuestionId == q.Id), this.translateDescription.transform(questionaryBlock.Risk), translations, index++));
    }, []);
    return [
      <Content>{ content: <Text>{ text: this.translateDescription.transform(questionaryBlock.Risk), font: 'Roboto-Bold', fontSize: 16, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 19 }, posX: 40, posY: 8, isRelative: true },
    ].concat(responsesContent);
  }

  mapQuestion(question: QuestionDto, response: EvaluationQuestionaryRiskAnswerDto, blockName: string, tranlations: any, index: number): Content[] {
    let questionContent = [
      <Content>{ content: <Text>{ text: `${blockName} - ${index}`, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: 'fdc130' }, lineHeight: 9 }, posX: 40, posY: 8, isRelative: true },
      <Content>{ content: <Text>{ text: question.Description, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 515, lineHeight: 12 }, posX: 40, posY: 2, isRelative: true },
      <Content>{ content: <Text>{ text: tranlations.answer, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 515, lineHeight: 9 }, posX: 40, posY: 10, isRelative: true },
      <Content>{ content: <Text>{ text: response ? this.getAnswer(response, question, tranlations) : '', font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 515, lineHeight: 12 }, posX: 40, posY: 4, isRelative: true },
      <Content>{ content: <Text>{ text: tranlations.answerWeight, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 515, lineHeight: 9 }, posX: 40, posY: 10, isRelative: true },
      <Content>{ content: <Text>{ text: response ? this.getQuestionResponseWeight(response, question, tranlations) : '', font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 515, lineHeight: 12 }, posX: 40, posY: 4, isRelative: true },
    ];

    if (question.CanAddComments) {
      questionContent = questionContent.concat([
        <Content>{ content: <Text>{ text: tranlations.comment, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, lineHeight: 9 }, posX: 40, posY: 10, isRelative: true },
        <Content>{ content: <Text>{ text: response?.Comment ?? '', font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 515, lineHeight: 12 }, posX: 40, posY: 10, isRelative: true }
      ]);
    }

    if (question.CanAddDoc) {
      questionContent = questionContent.concat([
        <Content>{ content: <Text>{ text: tranlations.attachedDocuments, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, lineHeight: 9 }, posX: 40, posY: 10, isRelative: true },
        <Content>{ content: <Text>{ text: response?.FileName ?? '', font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 12 }, posX: 40, posY: 10, isRelative: true }
      ]);
    }

    return questionContent;
  }

  getAnswer(response: EvaluationQuestionaryRiskAnswerDto, question: QuestionDto, tranlations: any): string {
    if (question.IsMultipleChoice) {
      return response.SelectedOption;
    } else {
      return response.Value ? tranlations.yes : tranlations.no
    }
  }

  getQuestionResponseWeight(response: EvaluationQuestionaryRiskAnswerDto, question: QuestionDto, translations: any): string {

    let weight: number;
    if (question.IsAlternativeScorinig) {
      weight = 6;
    } else {
      if (question.IsMultipleChoice) {
        const options = JSON.parse(question.Options);
        weight = options.find(o => o.Text == response.SelectedOption).Weight;
      } else {
        weight = response?.Value ? question.WeightYes : question.WeightNo;
      }
    }

    switch (weight) {
      case 1:
        return translations.lowRisk;
      case 2:
        return translations.midLowRisk;
      case 3:
        return translations.mediumRisk;
      case 4:
        return translations.midHighRisk;
      case 5:
        return translations.highRisk;
      case 6:
        return translations.basedOnCommentAndAttachments;
      default:
        return ''
    }
  }

  getDisclaimerSection(evaluationDetails: EvaluationQuestionaryDto, translations: any): Section {
    return <Section>{
      isAbsolute: true,
      isFullPage: false,
      content: [<Content>{ content: <Text>{ text: translations.disclaimerSection.checklistDigiallySigned, font: 'Roboto-Bold', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' }, underline: <TextUnderline>{ color: <ColorHex>{ hexCode: 'fdc130' }, thickness: 2 } }, posX: 40, posY: 84, isRelative: false },
      <Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: translations.disclaimerSection.digitallySignedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 245.5, lineHeight: 9 }, posX: 0, posY: 10, isRelative: true },
            <Content>{ content: <Text>{ text: translations.disclaimerSection.signDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 245.5, lineHeight: 9 }, posX: 24, posY: 10, isRelative: true },
          ]
        }, posX: 40, posY: 10, isRelative: true
      },
      <Content>{
        content: <Row>{
          rowContent: [
            <Content>{ content: <Text>{ text: evaluationDetails.SubmitedBy, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 245.5, lineHeight: 12 }, posX: 0, posY: 4, isRelative: true },
            <Content>{ content: <Text>{ text: formatDate(evaluationDetails.SubmitTS, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 245.5, lineHeight: 12 }, posX: 24, posY: 4, isRelative: true },
          ]
        }, posX: 40, posY: 10, isRelative: true
      },
      <Content>{ content: <Text>{ text: translations.disclaimerSection.disclaimer, font: 'Roboto-Light', fontSize: 8, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 515, lineHeight: 9 }, posX: 40, posY: 8, isRelative: true },
      ]
    }
  }
}
