import { Component, Input } from "@angular/core";
import { Alias } from "@app/modules/screening-hub/models/GesintelScreening/Alias";

@Component({
  selector: 'aml-update-alias',
  templateUrl: './alias.component.html',
  styleUrls: ['./alias.component.scss'],
})
export class AMLUpdateAliasComponent {
  @Input() alias: Alias[];

  constructor() { }
}
