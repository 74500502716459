import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-risklist-sent',
  templateUrl: './risklist-sent.component.html',
  styles: [
  ]
})
export class RisklistSentComponent implements OnInit {

  locale: string;
  private subscription: Subscription;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.locale = this.translate.currentLang.split("-")[0];
    // don't forget to unsubscribe!
    this.subscription=this.translate.onLangChange
        .subscribe((langChangeEvent: LangChangeEvent) => {
            this.locale = langChangeEvent.lang.split("-")[0];
        })

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('o-risklist', 'js-noHeader');

  }

  ngOnDestroy(): void {

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('o-risklist', 'js-noHeader');

  }

}
