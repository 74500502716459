<!-- Paso 2 -->
<div class="o-wrapper" [style.display]="controlStep == 2? 'block' : 'none'">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section">
            <div class="c-head-section__left">
                <h2 class="o-title">{{'NuevaCampaña.title1.valor' | translate }} - <strong>{{'NuevaCampaña.title2.valor'
                        | translate }}</strong> </h2>
                <p class="o-text">{{'NuevaCampaña.title3.valor' | translate }}</p>
            </div>
            <div class="c-head-section__right">
                <button [disabled]="selectedRows.length == 0 || creatingCampaign == true" routerLinkActive="is-active" class="c-button c-button--large" (click)="saveChanges()">{{'NuevaCampaña.continue.valor' | translate
                    }} ({{'NuevaCampaña.selected.valor' | translate }}: {{selectedRows.length}})</button>
            </div>
        </div>
        <!--volver atrás-->
        <div class="c-go-back c-go-back--steps">
            <button class="c-go-back__button" (click)="changeStep(1)">
                <span class="c-go-back__arrow" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                        <path
                            d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                            transform="translate(-5-6.01)" />
                    </svg>
                </span>
                {{'NuevaCampañaConfig.back.valor' | translate }}
            </button>
        </div>

        <!--tabs-->
        <mat-tab-group class="c-radiotab" animationDuration="0" dynamicHeight>
            <mat-tab *ngFor="let thirdPartyType of thirdPartyTypes" class="c-radiotab__item">
                <ng-template mat-tab-label>
                    <span *ngIf="thirdPartyType.code=='All'">{{'Tabla3PP.tab.all.valor' | translate }}</span>
                    <span *ngIf="thirdPartyType.code!=='All'">{{thirdPartyType | translatedescription}}</span>
                </ng-template>
                <ng-template matTabContent>
                    <app-create-campaign-third-parties
                      [thirdParties]="getThirdPartiesWithThirdPartyTypeId(thirdPartyType.id)"
                      [campaign]="campaignId"
                      [selectedRows]="selectedRows"
                      (onSelectRow)="selectRows($event)"
                    >
                    </app-create-campaign-third-parties>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

    </main>
</div>


<!-- Paso 1 -->
<div class="o-wrapper" [style.display]="controlStep == 1? 'block' : 'none'">
    <main id="content">
        <!--encabezado sección-->
        <div id="newCampaignSecond" class="c-head-section">
            <div class="c-head-section__left">
                <h2 class="o-title">{{'NuevaCampañaConfig.title1.valor' | translate }} -
                    <strong>{{'NuevaCampañaConfig.title2.valor' | translate }}</strong>
                </h2>
                <p class="o-text">{{'NuevaCampañaConfig.title3.valor' | translate }}</p>
            </div>
            <div class="c-head-section__right">
                <button *ngIf="this.canRemove" (click)="openDialogDeleteCampaign()"  type="button" class="c-button c-button--remove">Eliminar evaluación</button>
                <button type="button" (click)="changeStep(2)" class="c-button">{{'NuevaCampañaConfig.button.continue.valor' | translate }}</button>
            </div>
        </div>
        <div class="o-scroll o-scroll--small">
            <!--formulario-->
            <div class="c-form c-form--space">
                <form [formGroup]="form" id="form-config" autocomplete="off" class="c-form__form">
                    <fieldset class="u-mb-2">
                        <legend class="c-form__legend">{{'NuevaCampañaConfig.template.valor' | translate }}</legend>
                        <div class="row">
                            <div class="col-12">
                                <div class="c-form__block">
                                    <div id="selectTemplate" class="c-select">
                                        <mat-select formControlName="Questionary" [compareWith]="objectComparisonFunction" placeholder="{{'NuevaCampañaConfig.templateph.valor' | translate }}" floatlLabel="never">
                                            <mat-option *ngFor="let questionary of questionariesNoSelected" [value]="questionary">
                                                {{questionary.Code}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <mat-error role="alert" *ngIf="isSubmitted && Questionary.errors?.required">
                                        <p class="c-form-error__text">{{'NuevaCampañaConfig.templateError.valor' | translate }}
                                        </p>
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="c-form-info" aria-labelledby="selectTemplate">
                                    <p class="c-form-info__text">
                                        <span class="c-form-info__ico" aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path
                                                d="M10 0A10 10 0 1 0 20 10 10 10 0 0 0 10 0m0 18.333A8.333 8.333 0 1 1 18.333 10 8.333 8.333 0 0 1 10 18.333" />
                                            <path
                                                d="M11.667,10h-.833a.833.833,0,0,0,0,1.667h.833v5a.833.833,0,1,0,1.667,0v-5A1.667,1.667,0,0,0,11.667,10Z"
                                                transform="translate(-1.667-1.666)" />
                                            <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 4.166)" />
                                        </svg>
                                    </span>
                                        <span>{{'NuevaCampañaConfig.GoToChecklistList.part1.valor' | translate }}
                                        <a [routerLink]="['/plantillas']"> {{'NuevaCampañaConfig.GoToChecklistList.part2.valor' | translate }} </a>
                                        {{'NuevaCampañaConfig.GoToChecklistList.part3.valor' | translate }}
                                    </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend class="c-form__legend">{{'NuevaCampañaConfig.basicData.valor' | translate }}</legend>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="c-form__block">
                                    <mat-form-field>
                                        <input type="text" matInput name="Name" value="" id="config-Name" class="c-form__input" formControlName="Name" placeholder="{{'NuevaCampañaConfig.nameph.valor' | translate }}" />
                                        <mat-error>
                                            {{'NuevaCampañaConfig.nameError.valor' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="c-form__block">
                                    <mat-form-field>
                                        <input type="number" matInput name="Amount" value="" id="config-Amount" class="c-form__input" formControlName="Amount" placeholder="{{'NuevaCampañaConfig.monto.valor' | translate }}" required />
                                        <mat-error>
                                            {{'NuevaCampañaConfig.montoError.valor' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div class="row">
                        <div class="col-12 col-lg-6 col-xl-4">
                            <fieldset>
                                <legend class="c-form__legend">{{'NuevaCampañaConfig.start.valor' | translate }}</legend>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="c-form__block">
                                            <mat-form-field>
                                                <input matInput [ngxMatDatetimePicker]="picker" placeholder="{{'NuevaCampañaConfig.startph.valor' | translate }}" formControlName="StartDate" [min]="minDateStart">
                                                <button mat-icon-button matSuffix (click)="picker.open(); $event.stopPropagation()">
                                                    <mat-icon>today</mat-icon>
                                                </button>
                                                <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
                                                    <ng-template>
                                                        <span>OK</span>
                                                    </ng-template>
                                                </ngx-mat-datetime-picker>
                                                <mat-error>
                                                    {{'NuevaCampañaConfig.startError.valor' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                            <div *ngIf="form.errors" role="alert" aria-labelledby="config-startDate" class="c-form-error">
                                                <p class="c-form-error__text"> {{'NuevaCampañaConfig.startError2.valor' | translate }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-4">
                            <fieldset>
                                <legend class="c-form__legend">{{'NuevaCampañaConfig.end.valor' | translate }}</legend>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="c-form__block">
                                            <mat-form-field>
                                                <mat-form-field>
                                                    <ngx-mat-datetime-picker #pickerf [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
                                                        <ng-template>
                                                            <span>OK</span>
                                                        </ng-template>
                                                    </ngx-mat-datetime-picker>
                                                    <input matInput [ngxMatDatetimePicker]="pickerf" placeholder="{{'NuevaCampañaConfig.endph.valor' | translate }}" formControlName="EndDate" [min]="minDateEnd">
                                                    <button mat-icon-button matSuffix (click)="pickerf.open(); $event.stopPropagation()">
                                                        <mat-icon>today</mat-icon>
                                                    </button>
                                                    <mat-error>
                                                        {{'NuevaCampañaConfig.startError.valor' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-error>
                                                    {{'NuevaCampañaConfig.startError.valor' | translate }}
                                                </mat-error>
                                             </mat-form-field>
                                            <div *ngIf="form.errors" role="alert" aria-labelledby="config-startDate" class="c-form-error">
                                                <p class="c-form-error__text"> {{'NuevaCampañaConfig.startError2.valor' | translate }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <fieldset>
                                <legend class="c-form__legend">{{'NuevaCampañaConfig.automatization' | translate }}</legend>
                                <div class="c-form__block">
                                    <div class="c-switches c-switches--cols">
                                        <div class="c-switches--cols__group" aria-labelledby="">
                                            <mat-slide-toggle class="c-switch" name="automatization-enabled" (change)="slideChangeApprove()"
                                                [(ngModel)]="enableAutomatizationApprove" [ngModelOptions]="{standalone: true}">
                                            </mat-slide-toggle>
                                            <app-third-party-status [status]="2"></app-third-party-status>
                                            <p class="automatize-paragraph-right">{{'NuevaCampañaConfig.automatizationApproveParagraph' | translate}}</p>
                                            <mat-form-field style="width: 10%;">
                                                <input type="number" matInput name="ApproveThreshold" value="" id="ApproveThreshold" class="c-form__input" formControlName="ApproveThreshold" placeholder=""/>
                                                <mat-error>
                                                    {{'NuevaCampañaConfig.thresholdBounds' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="c-form__block">
                                    <div class="c-switches c-switches--cols">
                                        <div class="c-switches--cols__group" aria-labelledby="">
                                            <mat-slide-toggle class="c-switch" name="automatization-enabled" (change)="slideChange()"
                                                [(ngModel)]="enableAutomatizationDeny" [ngModelOptions]="{standalone: true}">
                                            </mat-slide-toggle>
                                            <app-third-party-status [status]="3"></app-third-party-status>
                                            <p class="automatize-paragraph-right">{{'NuevaCampañaConfig.automatizationDenyParagraph' | translate}}</p>
                                            <mat-form-field style="width: 10%;">
                                                <input type="number" matInput name="DiscardThreshold" value="" id="DiscardThreshold" class="c-form__input" formControlName="DiscardThreshold" placeholder=""/>
                                                <mat-error>
                                                    {{'NuevaCampañaConfig.thresholdBounds' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <fieldset>
                                <legend class="c-form__legend">{{'NuevaCampañaConfig.intro.valor' | translate }}</legend>
                                <div class="c-form__block">
                                    <mat-form-field>
                                        <textarea matInput name="Intro" id="config-Intro" formControlName="Introduction" class="c-form__textarea"></textarea>
                                        <mat-error>
                                            {{'NuevaCampañaConfig.introError.valor' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="c-form__block">
                                    <div class="c-form-upload">
                                        <app-file-upload formControlName="Doc" [buttonText]="uploadButtonText" [qid]="null" (fileSelected)="selectDocument($event)"></app-file-upload>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </main>
</div>
