<div class="o-wrapper">
    <main id="content">
        <section class="c-risklist">
            <h2 class="o-title o-title--small">{{'Risklist.intro.intro.valor'|translate}}</h2>
            <h3 class="o-title o-title--thin">¡{{'Risklist.intro.hola.valor'|translate}} <span>{{auth.getFieldJWT('Name')}}</span>!</h3>
            <p class="o-text u-mt-1half">{{'Risklist.intro.text.valor'|translate}} <strong>{{ campaign?campaign.name: '' }}</strong>.</p>
            <p class="o-text u-mt-1">{{ campaign?campaign.introduction: '' }}</p>
            <div *ngIf="campaign && campaign.fileName" class="c-ico-link">
                <div class="c-ico-link__left">
                    <span class="c-ico-link__ico" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20">
                            <path d="M16.779,2.6,15.4,1.22A4.14,4.14,0,0,0,12.452,0H7.167A4.172,4.172,0,0,0,3,4.167V15.833A4.172,4.172,0,0,0,7.167,20h6.667A4.172,4.172,0,0,0,18,15.833V5.547A4.14,4.14,0,0,0,16.779,2.6ZM15.6,3.78a2.443,2.443,0,0,1,.316.387H13.833V2.083a2.583,2.583,0,0,1,.388.317Zm.733,12.053a2.5,2.5,0,0,1-2.5,2.5H7.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5h5v2.5a1.667,1.667,0,0,0,1.667,1.667h2.5ZM13.833,7.5a.833.833,0,1,1,0,1.667H7.167a.833.833,0,1,1,0-1.667Zm.833,4.167a.833.833,0,0,1-.833.833H7.167a.833.833,0,1,1,0-1.667h6.667A.833.833,0,0,1,14.667,11.667Zm-.16,2.844a.833.833,0,0,1-.181,1.162,5.387,5.387,0,0,1-2.88.994,2.71,2.71,0,0,1-1.667-.583c-.273-.188-.377-.25-.583-.25a3.293,3.293,0,0,0-1.527.662.833.833,0,0,1-1.012-1.323A4.884,4.884,0,0,1,9.2,14.167a2.521,2.521,0,0,1,1.527.546,1.122,1.122,0,0,0,.72.287,3.822,3.822,0,0,0,1.9-.674A.833.833,0,0,1,14.507,14.511Z" transform="translate(-3)"/>
                        </svg>
                    </span>
                </div>
                <div  class="c-ico-link__right">
                    <p class="c-ico-link__title">{{ campaign?campaign.fileName: '' }}</p>
                    <a (click)="download(campaign.fileUri)" class="c-ico-link__link" title="Abre ventana nueva con archivo PDF" style="cursor: pointer;">{{ campaign?campaign.fileName: '' }}</a>
                </div>
            </div>
            <div class="c-group-btn-img">
                <a *ngIf="submitted" (click)="gotoViewRisklist(this.campaignId, this.thirdPartyId)" class="c-button c-button--gradient">{{'Risklist.intro.ver.valor'|translate}}</a>
                <a *ngIf="pendingSubmission" (click)="gotoFillRisklist(this.campaignId, this.thirdPartyId)" class="c-button c-button--gradient">{{'Risklist.intro.rellenar.valor'|translate}}</a>
                <div class="c-group-btn-img__image" aria-hidden="true">
                    <img src="assets/intro-risklist.svg" alt="Personas y lupa enfocando un documento" loading="lazy" />
                </div>
            </div>
        </section>
    </main>
</div>
