import { Component, EventEmitter, Output } from '@angular/core';
import { Gender } from '@app/models/scoring-tools/gender';
import { CorpFilter } from '@app/modules/screening-hub/models/member-check/corp-filter';

@Component({
  selector: 'member-check-scoring-corp-search',
  templateUrl: './member-check-scoring-corp-search.component.html',
  styleUrls: ['./member-check-scoring-corp-search.component.scss']
})
export class MemberCheckScoringCorpSearchComponent {
  @Output() onSearchClick = new EventEmitter<CorpFilter>();

  model: CorpFilter;
  genderList: Gender[];

  constructor() {
    this.model = new CorpFilter();
  }

  search() {
    this.onSearchClick.emit(this.model);
  }
}
