import { Risk } from '@app/models/Risk';
import { RiskDto } from 'src/proxy/model/riskDto';
import { mapClient } from './client.mappings';

export function mapRiskDtoToRisk(dto: RiskDto, currentLanguage: string): Risk {
  if (!dto) return null;

  return <Risk>{
    id: dto.Id,
    code: dto.Code,
    description: dto.Description,
    descriptionEN: dto.DescriptionEN,
    descriptionPT: dto.DescriptionPT,
    descriptionFR: dto.DescriptionFR,
    client: mapClient(dto.Client, currentLanguage)
  };
}
