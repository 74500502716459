import { OwnershipDto } from "src/proxy/model/ownershipDto";
import { Ownership } from "../../models/GesintelScreening/Ownership";

export function mapOwnership(dto: OwnershipDto): Ownership {
  if (!dto) return null;

  return <Ownership> {
    type: dto.Type,
    companyName: dto.CompanyName,
    rut: dto.Rut,
    percentage: dto.Percentage,
  };
}
