import { AMLUpdateCandidateDto } from 'src/proxy/model/aMLUpdateCandidateDto';
import { CandidateCompliance } from '../../models/CandidateCompliance';
import { AMLUpdateCandidate } from '../../models/AMLUpdateCandidate';
import { CandidateCompliance as CandidateComplianceDto } from 'src/proxy/model/candidateCompliance';

export function mapAMLUpdateCandidate(
  dto: AMLUpdateCandidateDto
): AMLUpdateCandidate {
  if (!dto) return null;

  return <AMLUpdateCandidate>{
    fullName: dto.FullName,
    rut: dto.Rut,
    mandatoryLists: mapCandidateCompliance(dto.MandatoryLists),
    kyc: mapCandidateCompliance(dto.KYC),
    ubo: mapCandidateCompliance(dto.UBO),
    matchStrength: dto.MacthStrength,
  };
}

function mapCandidateCompliance(
  dto: CandidateComplianceDto
): CandidateCompliance {
  if (!dto) return null;

  return <CandidateCompliance>{
    color: dto.Color,
    numberOfLists: dto.NumberOfLists,
  };
}
