<section class="c-table">
  <div class="c-table__top">
    <div class="row">
      <!--buscador y filtros-->
      <div class="col-5">
        <app-text-table-filter (filter)="updateSearchWord($event)"></app-text-table-filter>
      </div>
      <div class="col-12 col-lg-6 col-xl-3">
        <div class="c-select">
          <mat-form-field>
            <mat-select (selectionChange)="statusChange($event)"
              placeholder="{{'Tabla3PP.header.status.valor' | translate}}" multiple>
              <mat-option *ngFor="let status of statusList" [value]="status">
                {{ getStatusName(status) | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-3">
        <div class="c-select">
          <mat-form-field>
            <mat-select (selectionChange)="inherentRiskChange($event)"
              placeholder="{{'Tabla3PP.header.inherentRisk' | translate}}" multiple>
              <mat-option *ngFor="let inherentRisk of inherentRiskList" [value]="inherentRisk">
                {{ getInherentRiskText(inherentRisk) | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="o-scroll o-scroll--small">
    <div class="c-table__bottom">
      <div *ngIf="isLoading != undefined? isLoading : false">
        <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
      </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <!--columna checkboxes-->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <mat-checkbox (change)="$event ? masterToggle() : null; procesaSeleccion()"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null; procesaSeleccion()" [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <!--id columna-->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'Tabla3PP.header.id.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element" class="bold">{{element.Id}} </td>
        </ng-container>
        <!--nombre columna-->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'Tabla3PP.header.name.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element" class="bold">
            <span>
              {{element.CompanyName}}
            </span>
          </td>
        </ng-container>
        <!--tipo columna-->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'Tabla3PP.header.type.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.ThirdPartyConfig.ThirdPartyType | translatedescription}}
            </span>
          </td>
        </ng-container>
        <!--sede-->
        <ng-container matColumnDef="headquarters">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'Tabla3PP.header.hq.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.Country | translatedescription}}
            </span>
          </td>
        </ng-container>
        <!--sector-->
        <ng-container matColumnDef="sector">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'Tabla3PP.header.sector.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.ThirdPartyConfig.Sector | translatedescription}}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            {{'Tabla3PP.header.status.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <app-third-party-status [status]="element.ThirdPartyConfig.ThirdPartyStatus"></app-third-party-status>
          </td>
        </ng-container>
        <ng-container matColumnDef="inherentRisk">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            {{'Tabla3PP.header.inherentRisk'| translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{ getInherentRiskText(element.ThirdPartyConfig?.InherentRisk) | translate}}</span>
          </td>
        </ng-container>
        <!--responsable-->
        <ng-container matColumnDef="responsible">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'Tabla3PP.header.responsible.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <app-responsible-user [responsibleUserId]="element.UserOwner.Id"></app-responsible-user>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;" (click)="selection.toggle(row);procesaSeleccion()">
        </tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">
            {{'Tabla3PP.noresults.valor' | translate}}
          </td>
        </tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" class="c-pagination">
      </mat-paginator>

    </div>
  </div>
</section>
