<section class="c-table">
  <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
        {{ "aliasList.name" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.firstName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="middleName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
        {{ "aliasList.middleName" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.middleName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
        {{ "aliasList.lastname" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.lastName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="originalScript">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
        {{ "aliasList.originalScript" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.originalScriptName }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9">{{'aliasList.noResults' | translate}} </td>
    </tr>
  </table>
  <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems"
    class="c-pagination">
  </mat-paginator>
</section>
