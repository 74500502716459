<div class="o-wrapper">
  <main id="content">
    <div *ngIf="isLoading">
      <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
    </div>
    <div *ngIf="!isLoading" [formGroup]="form">
      <section class="c-risklist">
        <div class="c-head-section small-height u-pl-0">
          <div class="c-head-section__left">
            <h2 class="o-title o-title--small" lang="en">risklist</h2>
            <h3 class="o-title">{{'ViewAnswers.title1.valor'|translate}}</h3>
          </div>
          <div class="c-head-section__right">
            <ng-container *ngIf="editable">
              <button *appAuthRole="['Owner','Manager']" class="c-button c-button--light" (click)="save()">
                {{'ViewAnswers.save'|translate}}
              </button>
            </ng-container>
            <button *appAuthRole="['Owner','Manager','Auditor']" class="c-button" (click)="downloadReport()">
              {{'ViewAnswers.downloadReport'|translate}}
            </button>
          </div>
        </div>
        <div class="c-go-back c-go-back--spaceLeft u-pl-1medium">
          <a (click)="back()" class="c-go-back__button u-pl-1">
            <span class="c-go-back__arrow" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                <path
                  d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                  transform="translate(-5-6.01)" />
              </svg>
            </span>
            {{'ViewAnswers.back.valor'|translate}}
          </a>
        </div>
        <!--tabs-->
        <mat-tab-group #tabs class="c-tabs">
          <form [formGroup]="form">
            <div formArrayName="bloques">
              <div *ngFor="let bloqueForm of bloques.controls; let bloqueIndex = index">
                <div [formGroupName]="bloqueIndex">
                  <mat-tab label="{{getBlock(bloqueForm) | translatedescription}}">
                    <!-- {{bloqueForm.valid}} -->
                    <div class="o-scroll">
                      <!--bloque preguntas-->
                      <div formArrayName="Questions">

                        <div *ngFor="let q of bloqueQuestions(bloqueIndex).controls; let questionIndex = index"
                          class="c-risklist-question-wrapper">
                          <div [formGroupName]="questionIndex">
                            <!--cada item-->
                            <article class="c-risklist-question">
                              <h1 class="c-risklist-question__title">
                                {{ (getBlock(bloqueForm) | translatedescription) }} - #{{questionIndex+1}}</h1>
                              <span id="answerSelectionGroup" class="question-text">
                                {{q.get('Description').value}}
                              </span>
                              <label class="label-question">{{'ViewAnswers.answer' | translate}}</label>
                              <ng-container *ngIf="!q.get('IsMultipleChoice').value">
                                <span class="question-answer">
                                  {{ getYesNoText(q.get('Answer').value) | translate}}
                                </span>
                              </ng-container>
                              <ng-container *ngIf="q.get('IsMultipleChoice').value">
                                <span class="question-answer" *ngIf="q.get('SelectedOption').value ; else emptyOptionResponse">
                                  {{ q.get('SelectedOption').value}}
                                </span>
                                <ng-template #emptyOptionResponse>
                                  <span class="question-answer">-</span>
                                </ng-template>
                              </ng-container>

                              <span class="label-question"> {{ 'ViewAnswers.calculatedRisk' | translate }} </span>
                              <div class="row">
                                <span class="col-3 col-xl-2 question-answer" *ngIf="q.get('QuestionScore').value; else notEvaluated"> {{ q.get('QuestionScore').value }} ({{getValueText(q.get('QuestionScore').value) | translate }})</span>
                                <ng-template #notEvaluated>
                                  <span class="col-3 col-xl-2 question-answer">-</span>
                                </ng-template>
                                <div class="c-select col-3 col-xl-2">
                                  <mat-form-field>
                                    <mat-select class="c-form__select" formControlName="OverrideScore"
                                      placeholder="{{'ViewAnswers.manualRisk' | translate }}">
                                      <mat-option [value]="1"> {{ getValueText(1) | translate }} </mat-option>
                                      <mat-option [value]="2"> {{ getValueText(2) | translate }} </mat-option>
                                      <mat-option [value]="3"> {{ getValueText(3) | translate }} </mat-option>
                                      <mat-option [value]="4"> {{ getValueText(4) | translate }} </mat-option>
                                      <mat-option [value]="5"> {{ getValueText(5) | translate }} </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                              <ng-container *ngIf="bloqueQuestions(bloqueIndex).at(questionIndex).get('Comment')">
                                <span class="label-question"> {{ 'ViewAnswers.thirdPartyComments' | translate }}
                                </span>
                                <textarea matInput *ngIf="hasComment(q)" formControlName="Comment" id="infoExtra"
                                  class="c-extra-info__textarea"></textarea>
                              </ng-container>
                              <ng-container *ngIf="bloqueQuestions(bloqueIndex).at(questionIndex).get('Doc')">
                                <span class="label-question"> {{ 'ViewAnswers.attachedDoc' | translate }} </span>
                                <a *ngIf="hasDoc(q)"
                                  (click)="download(q.get('Doc').value.FileUri, q.get('Doc').value.FileName)"
                                  class="c-ico-link__link" [title]="q.get('Doc').value.FileName"
                                  style="cursor: pointer;">
                                  {{ q.get('Doc').value.FileName }}</a>
                              </ng-container>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </div>
              </div>
            </div>
          </form>
        </mat-tab-group>
      </section>
    </div>
  </main>
</div>
