import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-equifax-ws-config-form',
  templateUrl: './equifax-ws-config-form.component.html',
  styleUrls: ['./equifax-ws-config-form.component.scss']
})
export class EquifaxWSConfigFormComponent {

  @Input() datumUser: string;
  @Input() password: string;
  @Input() user: string;
  @Input() reference: string;

  @Output() datumUserChange = new EventEmitter<string>();
  @Output() userChange = new EventEmitter<string>();
  @Output() passwordChange = new EventEmitter<string>();
  @Output() referenceChange = new EventEmitter<string>();

  constructor() { }

  onDatumUserChange() {
    this.datumUserChange.emit(this.datumUser);
  }

  onUserChange() {
    this.userChange.emit(this.user);
  }

  onPasswordChange() {
    this.passwordChange.emit(this.password);
  }

  onReferenceChange() {
    this.referenceChange.emit(this.reference);
  }
}
