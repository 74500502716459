import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EvaluationQuestionaryRiskDTO } from '@app/models/EvaluationQuestionaryRisk';
import { QuestionariesService } from '@app/services/questionaries-service';
import { RiskCommentDto } from 'src/proxy/model/riskCommentDto';

@Component({
  selector: 'app-campaign-risk-comments',
  templateUrl: './campaign-risk-comments.component.html',
  styleUrls: ['./campaign-risk-comments.component.scss'],
})
export class CampaignRiskCommentsComponent implements OnInit {
  @Input() evaluationQuestionaryRisks: EvaluationQuestionaryRiskDTO[];
  @Output() commentAdded = new EventEmitter<EvaluationQuestionaryRiskDTO[]>();

  editMode: boolean;
  hasComments: boolean;

  constructor(
    private translate: TranslateService,
    private questionariesService: QuestionariesService
  ) {}

  ngOnInit(): void {
    this.checkIfHasComments();
  }

  editComments() {
    this.editMode = true;
  }

  cancelEdit() {
    this.editMode = false;
  }

  saveEdit() {
    const payload: RiskCommentDto[] = this.evaluationQuestionaryRisks.map(
      (r) =>
        ({
          riskId: r.Id,
          comment: r.Comment,
        } as RiskCommentDto)
    );
    this.questionariesService.updateQuestionaryRiskComments(payload);
    this.editMode = false;
    this.checkIfHasComments();
  }

  private checkIfHasComments(): void {
    this.hasComments = !this.evaluationQuestionaryRisks.every(
      (r) => r.Comment === null || r.Comment === ''
    );
  }
}
