import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Scoring } from '@app/models/scoring-tools/scoring';

@Component({
  selector: 'app-dialog-confirm-refresh-screening',
  templateUrl: './dialog-confirm-refresh-screening.component.html',
  styleUrls: ['./dialog-confirm-refresh-screening.component.scss'],
})
export class DialogConfirmRefreshScreeningComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmRefreshScreeningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Scoring
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
