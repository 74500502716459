import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: 'app-text-table-filter',
    templateUrl: './text-table-filter.component.html',
    styleUrls: ['./text-table-filter.component.scss']
})
export class TextTableFilterComponent implements OnInit, OnDestroy {

    @Output() filter = new EventEmitter<string>();
    private subject: Subject<string> = new Subject();
    private subscription: Subscription;
    
    constructor() {}

    ngOnInit(): void {
        this.subscription = this.subject.pipe(
            debounceTime(500)
        ).subscribe((filterValue: string) => {
            this.filter.emit(filterValue);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onFilterChange(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.subject.next(filterValue);
    }
}