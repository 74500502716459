import { Component, Input } from "@angular/core";
import { EquifaxWSIndividualData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSIndividualData";

@Component({
  selector: 'equifax-ws-individual-marital-status',
  templateUrl: './marital-status.component.html',
  styleUrls: ['./marital-status.component.scss'],
})
export class EquifaxWSIndividualMaritalStatusComponent {
  @Input() individualDetails: EquifaxWSIndividualData;

  constructor() { }

}
