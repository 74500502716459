/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Observable } from 'rxjs';
import { ClientDto } from '../model/clientDto';
import { ClientUsersDataUpdateDto } from '../model/clientUsersDataUpdateDto';
import { FinanceTypesDto } from '../model/financeTypesDto';
import { IsoCertificationFileCreateDto } from '../model/isoCertificationFileCreateDto';
import { RiskallayUserCreateDto } from '../model/riskallayUserCreateDto';
import { RiskallayUserDto } from '../model/riskallayUserDto';
import { RiskallayUserUpdateDto } from '../model/riskallayUserUpdateDto';
import { Configuration } from '../configuration';


@Injectable()
export class UsersProxyClient {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersClientHaveConfigGet(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public usersClientHaveConfigGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public usersClientHaveConfigGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public usersClientHaveConfigGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<boolean>('get', `${this.basePath}/Users/ClientHaveConfig`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersClientHaveFreeLicenseGet(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public usersClientHaveFreeLicenseGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public usersClientHaveFreeLicenseGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public usersClientHaveFreeLicenseGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<boolean>('get', `${this.basePath}/Users/ClientHaveFreeLicense`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersCreateClientUserPost(body?: RiskallayUserCreateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersCreateClientUserPost(body?: RiskallayUserCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersCreateClientUserPost(body?: RiskallayUserCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersCreateClientUserPost(body?: RiskallayUserCreateDto, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/Users/CreateClientUser`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersGetAllUserDataGet(observe?: 'body', reportProgress?: boolean): Observable<Array<RiskallayUserDto>>;
  public usersGetAllUserDataGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RiskallayUserDto>>>;
  public usersGetAllUserDataGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RiskallayUserDto>>>;
  public usersGetAllUserDataGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<RiskallayUserDto>>('get', `${this.basePath}/Users/GetAllUserData`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersGetClientDataGet(observe?: 'body', reportProgress?: boolean): Observable<ClientDto>;
  public usersGetClientDataGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientDto>>;
  public usersGetClientDataGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientDto>>;
  public usersGetClientDataGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<ClientDto>('get', `${this.basePath}/Users/GetClientData`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersGetUserDataByIdUserIdGet(userId: string, observe?: 'body', reportProgress?: boolean): Observable<RiskallayUserDto>;
  public usersGetUserDataByIdUserIdGet(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RiskallayUserDto>>;
  public usersGetUserDataByIdUserIdGet(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RiskallayUserDto>>;
  public usersGetUserDataByIdUserIdGet(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling usersGetUserDataByIdUserIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<RiskallayUserDto>('get', `${this.basePath}/Users/GetUserDataById/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersGetUserDataGet(observe?: 'body', reportProgress?: boolean): Observable<RiskallayUserDto>;
  public usersGetUserDataGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RiskallayUserDto>>;
  public usersGetUserDataGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RiskallayUserDto>>;
  public usersGetUserDataGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<RiskallayUserDto>('get', `${this.basePath}/Users/GetUserData`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param filename
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersSaveClientPublicImageFilenameGet(filename: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersSaveClientPublicImageFilenameGet(filename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersSaveClientPublicImageFilenameGet(filename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersSaveClientPublicImageFilenameGet(filename: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (filename === null || filename === undefined) {
      throw new Error('Required parameter filename was null or undefined when calling usersSaveClientPublicImageFilenameGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/Users/SaveClientPublicImage/${encodeURIComponent(String(filename))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param clientInfoCompanyGroup
   * @param clientInfoComplianceProgram
   * @param clientInfoExecutiveOrganId
   * @param clientInfoOtherExecutiveOrgan
   * @param clientInfoOutSourcedCompanies
   * @param clientInfoInvesteeCompanies
   * @param clientInfoInternalAudit
   * @param clientInfoInternalAuditFileName
   * @param clientInfoInternalAuditFileUri
   * @param clientInfoExternalAudit
   * @param clientInfoExternalAuditFileName
   * @param clientInfoExternalAuditFileUri
   * @param clientInfoNumWorkers
   * @param clientInfoAnnualAccountingFirstYear
   * @param clientInfoAnnualAccountingFirstYearFileName
   * @param clientInfoAnnualAccountingFirstYearFileUri
   * @param clientInfoAnnualAccountingSecondYear
   * @param clientInfoAnnualAccountingSecondYearFileName
   * @param clientInfoAnnualAccountingSecondYearFileUri
   * @param clientInfoFinanceTypes
   * @param clientInfoIsoCertifications
   * @param name
   * @param companyCif
   * @param numOfLicenses
   * @param typeOfActivity
   * @param otherTypeOfActivity
   * @param companyRegistrationFileName
   * @param companyRegistrationFileUri
   * @param iconImageFileName
   * @param iconImageFileUri
   * @param coverPageImageFileName
   * @param coverPageImageFileUri
   * @param countryId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersSaveOnboardingPostForm(clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersSaveOnboardingPostForm(clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersSaveOnboardingPostForm(clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersSaveOnboardingPostForm(clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


































    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (clientInfoCompanyGroup !== undefined) {
      formParams = formParams.append('ClientInfo.CompanyGroup', <any>clientInfoCompanyGroup) as any || formParams;
    }
    if (clientInfoComplianceProgram !== undefined) {
      formParams = formParams.append('ClientInfo.ComplianceProgram', <any>clientInfoComplianceProgram) as any || formParams;
    }
    if (clientInfoExecutiveOrganId !== undefined) {
      formParams = formParams.append('ClientInfo.ExecutiveOrganId', <any>clientInfoExecutiveOrganId) as any || formParams;
    }
    if (clientInfoOtherExecutiveOrgan !== undefined) {
      formParams = formParams.append('ClientInfo.OtherExecutiveOrgan', <any>clientInfoOtherExecutiveOrgan) as any || formParams;
    }
    if (clientInfoOutSourcedCompanies !== undefined) {
      formParams = formParams.append('ClientInfo.OutSourcedCompanies', <any>clientInfoOutSourcedCompanies) as any || formParams;
    }
    if (clientInfoInvesteeCompanies !== undefined) {
      formParams = formParams.append('ClientInfo.InvesteeCompanies', <any>clientInfoInvesteeCompanies) as any || formParams;
    }
    if (clientInfoInternalAudit !== undefined) {
      formParams = formParams.append('ClientInfo.InternalAudit', <any>clientInfoInternalAudit) as any || formParams;
    }
    if (clientInfoInternalAuditFileName !== undefined) {
      formParams = formParams.append('ClientInfo.InternalAuditFileName', <any>clientInfoInternalAuditFileName) as any || formParams;
    }
    if (clientInfoInternalAuditFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.InternalAuditFileUri', <any>clientInfoInternalAuditFileUri) as any || formParams;
    }
    if (clientInfoExternalAudit !== undefined) {
      formParams = formParams.append('ClientInfo.ExternalAudit', <any>clientInfoExternalAudit) as any || formParams;
    }
    if (clientInfoExternalAuditFileName !== undefined) {
      formParams = formParams.append('ClientInfo.ExternalAuditFileName', <any>clientInfoExternalAuditFileName) as any || formParams;
    }
    if (clientInfoExternalAuditFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.ExternalAuditFileUri', <any>clientInfoExternalAuditFileUri) as any || formParams;
    }
    if (clientInfoNumWorkers !== undefined) {
      formParams = formParams.append('ClientInfo.NumWorkers', <any>clientInfoNumWorkers) as any || formParams;
    }
    if (clientInfoAnnualAccountingFirstYear !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingFirstYear', <any>clientInfoAnnualAccountingFirstYear) as any || formParams;
    }
    if (clientInfoAnnualAccountingFirstYearFileName !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingFirstYearFileName', <any>clientInfoAnnualAccountingFirstYearFileName) as any || formParams;
    }
    if (clientInfoAnnualAccountingFirstYearFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingFirstYearFileUri', <any>clientInfoAnnualAccountingFirstYearFileUri) as any || formParams;
    }
    if (clientInfoAnnualAccountingSecondYear !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingSecondYear', <any>clientInfoAnnualAccountingSecondYear) as any || formParams;
    }
    if (clientInfoAnnualAccountingSecondYearFileName !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingSecondYearFileName', <any>clientInfoAnnualAccountingSecondYearFileName) as any || formParams;
    }
    if (clientInfoAnnualAccountingSecondYearFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingSecondYearFileUri', <any>clientInfoAnnualAccountingSecondYearFileUri) as any || formParams;
    }
    if (clientInfoFinanceTypes) {
      clientInfoFinanceTypes.forEach((element) => {
        formParams = formParams.append('ClientInfo.FinanceTypes', <any>element) as any || formParams;
      })
    }
    if (clientInfoIsoCertifications) {
      clientInfoIsoCertifications.forEach((element) => {
        formParams = formParams.append('ClientInfo.IsoCertifications', <any>element) as any || formParams;
      })
    }
    if (name !== undefined) {
      formParams = formParams.append('Name', <any>name) as any || formParams;
    }
    if (companyCif !== undefined) {
      formParams = formParams.append('CompanyCif', <any>companyCif) as any || formParams;
    }
    if (numOfLicenses !== undefined) {
      formParams = formParams.append('NumOfLicenses', <any>numOfLicenses) as any || formParams;
    }
    if (typeOfActivity !== undefined) {
      formParams = formParams.append('TypeOfActivity', <any>typeOfActivity) as any || formParams;
    }
    if (otherTypeOfActivity !== undefined) {
      formParams = formParams.append('OtherTypeOfActivity', <any>otherTypeOfActivity) as any || formParams;
    }
    if (companyRegistrationFileName !== undefined) {
      formParams = formParams.append('CompanyRegistrationFileName', <any>companyRegistrationFileName) as any || formParams;
    }
    if (companyRegistrationFileUri !== undefined) {
      formParams = formParams.append('CompanyRegistrationFileUri', <any>companyRegistrationFileUri) as any || formParams;
    }
    if (iconImageFileName !== undefined) {
      formParams = formParams.append('IconImageFileName', <any>iconImageFileName) as any || formParams;
    }
    if (iconImageFileUri !== undefined) {
      formParams = formParams.append('IconImageFileUri', <any>iconImageFileUri) as any || formParams;
    }
    if (coverPageImageFileName !== undefined) {
      formParams = formParams.append('CoverPageImageFileName', <any>coverPageImageFileName) as any || formParams;
    }
    if (coverPageImageFileUri !== undefined) {
      formParams = formParams.append('CoverPageImageFileUri', <any>coverPageImageFileUri) as any || formParams;
    }
    if (countryId !== undefined) {
      formParams = formParams.append('CountryId', <any>countryId) as any || formParams;
    }

    return this.httpClient.request<any>('post', `${this.basePath}/Users/SaveOnboarding`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param clientInfoId
   * @param clientInfoClientId
   * @param clientInfoCompanyGroup
   * @param clientInfoComplianceProgram
   * @param clientInfoExecutiveOrganId
   * @param clientInfoOtherExecutiveOrgan
   * @param clientInfoOutSourcedCompanies
   * @param clientInfoInvesteeCompanies
   * @param clientInfoInternalAudit
   * @param clientInfoInternalAuditFileName
   * @param clientInfoInternalAuditFileUri
   * @param clientInfoExternalAudit
   * @param clientInfoExternalAuditFileName
   * @param clientInfoExternalAuditFileUri
   * @param clientInfoNumWorkers
   * @param clientInfoAnnualAccountingFirstYear
   * @param clientInfoAnnualAccountingFirstYearFileName
   * @param clientInfoAnnualAccountingFirstYearFileUri
   * @param clientInfoAnnualAccountingSecondYear
   * @param clientInfoAnnualAccountingSecondYearFileName
   * @param clientInfoAnnualAccountingSecondYearFileUri
   * @param clientInfoFinanceTypes
   * @param clientInfoIsoCertifications
   * @param name
   * @param companyCif
   * @param numOfLicenses
   * @param typeOfActivity
   * @param otherTypeOfActivity
   * @param companyRegistrationFileName
   * @param companyRegistrationFileUri
   * @param iconImageFileName
   * @param iconImageFileUri
   * @param coverPageImageFileName
   * @param coverPageImageFileUri
   * @param countryId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersUpdateClientDataPutForm(id?: number, clientInfoId?: number, clientInfoClientId?: number, clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersUpdateClientDataPutForm(id?: number, clientInfoId?: number, clientInfoClientId?: number, clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersUpdateClientDataPutForm(id?: number, clientInfoId?: number, clientInfoClientId?: number, clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersUpdateClientDataPutForm(id?: number, clientInfoId?: number, clientInfoClientId?: number, clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {





































    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (id !== undefined) {
      formParams = formParams.append('Id', <any>id) as any || formParams;
    }
    if (clientInfoId !== undefined) {
      formParams = formParams.append('ClientInfo.Id', <any>clientInfoId) as any || formParams;
    }
    if (clientInfoClientId !== undefined) {
      formParams = formParams.append('ClientInfo.ClientId', <any>clientInfoClientId) as any || formParams;
    }
    if (clientInfoCompanyGroup !== undefined) {
      formParams = formParams.append('ClientInfo.CompanyGroup', <any>clientInfoCompanyGroup) as any || formParams;
    }
    if (clientInfoComplianceProgram !== undefined) {
      formParams = formParams.append('ClientInfo.ComplianceProgram', <any>clientInfoComplianceProgram) as any || formParams;
    }
    if (clientInfoExecutiveOrganId !== undefined) {
      formParams = formParams.append('ClientInfo.ExecutiveOrganId', <any>clientInfoExecutiveOrganId) as any || formParams;
    }
    if (clientInfoOtherExecutiveOrgan !== undefined) {
      formParams = formParams.append('ClientInfo.OtherExecutiveOrgan', <any>clientInfoOtherExecutiveOrgan) as any || formParams;
    }
    if (clientInfoOutSourcedCompanies !== undefined) {
      formParams = formParams.append('ClientInfo.OutSourcedCompanies', <any>clientInfoOutSourcedCompanies) as any || formParams;
    }
    if (clientInfoInvesteeCompanies !== undefined) {
      formParams = formParams.append('ClientInfo.InvesteeCompanies', <any>clientInfoInvesteeCompanies) as any || formParams;
    }
    if (clientInfoInternalAudit !== undefined) {
      formParams = formParams.append('ClientInfo.InternalAudit', <any>clientInfoInternalAudit) as any || formParams;
    }
    if (clientInfoInternalAuditFileName !== undefined) {
      formParams = formParams.append('ClientInfo.InternalAuditFileName', <any>clientInfoInternalAuditFileName) as any || formParams;
    }
    if (clientInfoInternalAuditFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.InternalAuditFileUri', <any>clientInfoInternalAuditFileUri) as any || formParams;
    }
    if (clientInfoExternalAudit !== undefined) {
      formParams = formParams.append('ClientInfo.ExternalAudit', <any>clientInfoExternalAudit) as any || formParams;
    }
    if (clientInfoExternalAuditFileName !== undefined) {
      formParams = formParams.append('ClientInfo.ExternalAuditFileName', <any>clientInfoExternalAuditFileName) as any || formParams;
    }
    if (clientInfoExternalAuditFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.ExternalAuditFileUri', <any>clientInfoExternalAuditFileUri) as any || formParams;
    }
    if (clientInfoNumWorkers !== undefined) {
      formParams = formParams.append('ClientInfo.NumWorkers', <any>clientInfoNumWorkers) as any || formParams;
    }
    if (clientInfoAnnualAccountingFirstYear !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingFirstYear', <any>clientInfoAnnualAccountingFirstYear) as any || formParams;
    }
    if (clientInfoAnnualAccountingFirstYearFileName !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingFirstYearFileName', <any>clientInfoAnnualAccountingFirstYearFileName) as any || formParams;
    }
    if (clientInfoAnnualAccountingFirstYearFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingFirstYearFileUri', <any>clientInfoAnnualAccountingFirstYearFileUri) as any || formParams;
    }
    if (clientInfoAnnualAccountingSecondYear !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingSecondYear', <any>clientInfoAnnualAccountingSecondYear) as any || formParams;
    }
    if (clientInfoAnnualAccountingSecondYearFileName !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingSecondYearFileName', <any>clientInfoAnnualAccountingSecondYearFileName) as any || formParams;
    }
    if (clientInfoAnnualAccountingSecondYearFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingSecondYearFileUri', <any>clientInfoAnnualAccountingSecondYearFileUri) as any || formParams;
    }
    if (clientInfoFinanceTypes) {
      clientInfoFinanceTypes.forEach((element) => {
        formParams = formParams.append('ClientInfo.FinanceTypes', <any>element) as any || formParams;
      })
    }
    if (clientInfoIsoCertifications) {
      clientInfoIsoCertifications.forEach((element) => {
        formParams = formParams.append('ClientInfo.IsoCertifications', <any>element) as any || formParams;
      })
    }
    if (name !== undefined) {
      formParams = formParams.append('Name', <any>name) as any || formParams;
    }
    if (companyCif !== undefined) {
      formParams = formParams.append('CompanyCif', <any>companyCif) as any || formParams;
    }
    if (numOfLicenses !== undefined) {
      formParams = formParams.append('NumOfLicenses', <any>numOfLicenses) as any || formParams;
    }
    if (typeOfActivity !== undefined) {
      formParams = formParams.append('TypeOfActivity', <any>typeOfActivity) as any || formParams;
    }
    if (otherTypeOfActivity !== undefined) {
      formParams = formParams.append('OtherTypeOfActivity', <any>otherTypeOfActivity) as any || formParams;
    }
    if (companyRegistrationFileName !== undefined) {
      formParams = formParams.append('CompanyRegistrationFileName', <any>companyRegistrationFileName) as any || formParams;
    }
    if (companyRegistrationFileUri !== undefined) {
      formParams = formParams.append('CompanyRegistrationFileUri', <any>companyRegistrationFileUri) as any || formParams;
    }
    if (iconImageFileName !== undefined) {
      formParams = formParams.append('IconImageFileName', <any>iconImageFileName) as any || formParams;
    }
    if (iconImageFileUri !== undefined) {
      formParams = formParams.append('IconImageFileUri', <any>iconImageFileUri) as any || formParams;
    }
    if (coverPageImageFileName !== undefined) {
      formParams = formParams.append('CoverPageImageFileName', <any>coverPageImageFileName) as any || formParams;
    }
    if (coverPageImageFileUri !== undefined) {
      formParams = formParams.append('CoverPageImageFileUri', <any>coverPageImageFileUri) as any || formParams;
    }
    if (countryId !== undefined) {
      formParams = formParams.append('CountryId', <any>countryId) as any || formParams;
    }

    return this.httpClient.request<any>('put', `${this.basePath}/Users/UpdateClientData`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param idUpdateUser
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersUpdateClientUserIdUpdateUserPut(idUpdateUser: string, body?: RiskallayUserUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersUpdateClientUserIdUpdateUserPut(idUpdateUser: string, body?: RiskallayUserUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersUpdateClientUserIdUpdateUserPut(idUpdateUser: string, body?: RiskallayUserUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersUpdateClientUserIdUpdateUserPut(idUpdateUser: string, body?: RiskallayUserUpdateDto, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idUpdateUser === null || idUpdateUser === undefined) {
      throw new Error('Required parameter idUpdateUser was null or undefined when calling usersUpdateClientUserIdUpdateUserPut.');
    }


    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/Users/UpdateClientUser/${encodeURIComponent(String(idUpdateUser))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersUpdateClientUsersDataPut(body?: ClientUsersDataUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersUpdateClientUsersDataPut(body?: ClientUsersDataUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersUpdateClientUsersDataPut(body?: ClientUsersDataUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersUpdateClientUsersDataPut(body?: ClientUsersDataUpdateDto, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/Users/UpdateClientUsersData`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param name
   * @param surName
   * @param email
   * @param position
   * @param phoneNumber
   * @param role
   * @param newPassword
   * @param oldPassword
   * @param profilePhotoFileName
   * @param profilePhotoFileUri
   * @param languageId
   * @param twoFactorEnabled
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersUpdateUserDataPutForm(id?: string, name?: string, surName?: string, email?: string, position?: string, phoneNumber?: string, role?: string, newPassword?: string, oldPassword?: string, profilePhotoFileName?: string, profilePhotoFileUri?: string, languageId?: number, twoFactorEnabled?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersUpdateUserDataPutForm(id?: string, name?: string, surName?: string, email?: string, position?: string, phoneNumber?: string, role?: string, newPassword?: string, oldPassword?: string, profilePhotoFileName?: string, profilePhotoFileUri?: string, languageId?: number, twoFactorEnabled?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersUpdateUserDataPutForm(id?: string, name?: string, surName?: string, email?: string, position?: string, phoneNumber?: string, role?: string, newPassword?: string, oldPassword?: string, profilePhotoFileName?: string, profilePhotoFileUri?: string, languageId?: number, twoFactorEnabled?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersUpdateUserDataPutForm(id?: string, name?: string, surName?: string, email?: string, position?: string, phoneNumber?: string, role?: string, newPassword?: string, oldPassword?: string, profilePhotoFileName?: string, profilePhotoFileUri?: string, languageId?: number, twoFactorEnabled?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {














    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (id !== undefined) {
      formParams = formParams.append('Id', <any>id) as any || formParams;
    }
    if (name !== undefined) {
      formParams = formParams.append('Name', <any>name) as any || formParams;
    }
    if (surName !== undefined) {
      formParams = formParams.append('SurName', <any>surName) as any || formParams;
    }
    if (email !== undefined) {
      formParams = formParams.append('Email', <any>email) as any || formParams;
    }
    if (position !== undefined) {
      formParams = formParams.append('Position', <any>position) as any || formParams;
    }
    if (phoneNumber !== undefined) {
      formParams = formParams.append('PhoneNumber', <any>phoneNumber) as any || formParams;
    }
    if (role !== undefined) {
      formParams = formParams.append('Role', <any>role) as any || formParams;
    }
    if (newPassword !== undefined) {
      formParams = formParams.append('NewPassword', <any>newPassword) as any || formParams;
    }
    if (oldPassword !== undefined) {
      formParams = formParams.append('OldPassword', <any>oldPassword) as any || formParams;
    }
    if (profilePhotoFileName !== undefined) {
      formParams = formParams.append('ProfilePhotoFileName', <any>profilePhotoFileName) as any || formParams;
    }
    if (profilePhotoFileUri !== undefined) {
      formParams = formParams.append('ProfilePhotoFileUri', <any>profilePhotoFileUri) as any || formParams;
    }
    if (languageId !== undefined) {
      formParams = formParams.append('LanguageId', <any>languageId) as any || formParams;
    }
    if (twoFactorEnabled !== undefined) {
      formParams = formParams.append('TwoFactorEnabled', <any>twoFactorEnabled) as any || formParams;
    }

    return this.httpClient.request<any>('put', `${this.basePath}/Users/UpdateUserData`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param filename
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersUpdateUserImageFilenameGet(filename: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersUpdateUserImageFilenameGet(filename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersUpdateUserImageFilenameGet(filename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersUpdateUserImageFilenameGet(filename: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (filename === null || filename === undefined) {
      throw new Error('Required parameter filename was null or undefined when calling usersUpdateUserImageFilenameGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/Users/UpdateUserImage/${encodeURIComponent(String(filename))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param clientInfoId
   * @param clientInfoClientId
   * @param clientInfoCompanyGroup
   * @param clientInfoComplianceProgram
   * @param clientInfoExecutiveOrganId
   * @param clientInfoOtherExecutiveOrgan
   * @param clientInfoOutSourcedCompanies
   * @param clientInfoInvesteeCompanies
   * @param clientInfoInternalAudit
   * @param clientInfoInternalAuditFileName
   * @param clientInfoInternalAuditFileUri
   * @param clientInfoExternalAudit
   * @param clientInfoExternalAuditFileName
   * @param clientInfoExternalAuditFileUri
   * @param clientInfoNumWorkers
   * @param clientInfoAnnualAccountingFirstYear
   * @param clientInfoAnnualAccountingFirstYearFileName
   * @param clientInfoAnnualAccountingFirstYearFileUri
   * @param clientInfoAnnualAccountingSecondYear
   * @param clientInfoAnnualAccountingSecondYearFileName
   * @param clientInfoAnnualAccountingSecondYearFileUri
   * @param clientInfoFinanceTypes
   * @param clientInfoIsoCertifications
   * @param name
   * @param companyCif
   * @param numOfLicenses
   * @param typeOfActivity
   * @param otherTypeOfActivity
   * @param companyRegistrationFileName
   * @param companyRegistrationFileUri
   * @param iconImageFileName
   * @param iconImageFileUri
   * @param coverPageImageFileName
   * @param coverPageImageFileUri
   * @param countryId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersUpdateVisibleInfoClientDataPutForm(id?: number, clientInfoId?: number, clientInfoClientId?: number, clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersUpdateVisibleInfoClientDataPutForm(id?: number, clientInfoId?: number, clientInfoClientId?: number, clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersUpdateVisibleInfoClientDataPutForm(id?: number, clientInfoId?: number, clientInfoClientId?: number, clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersUpdateVisibleInfoClientDataPutForm(id?: number, clientInfoId?: number, clientInfoClientId?: number, clientInfoCompanyGroup?: boolean, clientInfoComplianceProgram?: boolean, clientInfoExecutiveOrganId?: number, clientInfoOtherExecutiveOrgan?: string, clientInfoOutSourcedCompanies?: boolean, clientInfoInvesteeCompanies?: boolean, clientInfoInternalAudit?: boolean, clientInfoInternalAuditFileName?: string, clientInfoInternalAuditFileUri?: string, clientInfoExternalAudit?: boolean, clientInfoExternalAuditFileName?: string, clientInfoExternalAuditFileUri?: string, clientInfoNumWorkers?: number, clientInfoAnnualAccountingFirstYear?: number, clientInfoAnnualAccountingFirstYearFileName?: string, clientInfoAnnualAccountingFirstYearFileUri?: string, clientInfoAnnualAccountingSecondYear?: number, clientInfoAnnualAccountingSecondYearFileName?: string, clientInfoAnnualAccountingSecondYearFileUri?: string, clientInfoFinanceTypes?: Array<FinanceTypesDto>, clientInfoIsoCertifications?: Array<IsoCertificationFileCreateDto>, name?: string, companyCif?: string, numOfLicenses?: number, typeOfActivity?: string, otherTypeOfActivity?: string, companyRegistrationFileName?: string, companyRegistrationFileUri?: string, iconImageFileName?: string, iconImageFileUri?: string, coverPageImageFileName?: string, coverPageImageFileUri?: string, countryId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {





































    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (id !== undefined) {
      formParams = formParams.append('Id', <any>id) as any || formParams;
    }
    if (clientInfoId !== undefined) {
      formParams = formParams.append('ClientInfo.Id', <any>clientInfoId) as any || formParams;
    }
    if (clientInfoClientId !== undefined) {
      formParams = formParams.append('ClientInfo.ClientId', <any>clientInfoClientId) as any || formParams;
    }
    if (clientInfoCompanyGroup !== undefined) {
      formParams = formParams.append('ClientInfo.CompanyGroup', <any>clientInfoCompanyGroup) as any || formParams;
    }
    if (clientInfoComplianceProgram !== undefined) {
      formParams = formParams.append('ClientInfo.ComplianceProgram', <any>clientInfoComplianceProgram) as any || formParams;
    }
    if (clientInfoExecutiveOrganId !== undefined) {
      formParams = formParams.append('ClientInfo.ExecutiveOrganId', <any>clientInfoExecutiveOrganId) as any || formParams;
    }
    if (clientInfoOtherExecutiveOrgan !== undefined) {
      formParams = formParams.append('ClientInfo.OtherExecutiveOrgan', <any>clientInfoOtherExecutiveOrgan) as any || formParams;
    }
    if (clientInfoOutSourcedCompanies !== undefined) {
      formParams = formParams.append('ClientInfo.OutSourcedCompanies', <any>clientInfoOutSourcedCompanies) as any || formParams;
    }
    if (clientInfoInvesteeCompanies !== undefined) {
      formParams = formParams.append('ClientInfo.InvesteeCompanies', <any>clientInfoInvesteeCompanies) as any || formParams;
    }
    if (clientInfoInternalAudit !== undefined) {
      formParams = formParams.append('ClientInfo.InternalAudit', <any>clientInfoInternalAudit) as any || formParams;
    }
    if (clientInfoInternalAuditFileName !== undefined) {
      formParams = formParams.append('ClientInfo.InternalAuditFileName', <any>clientInfoInternalAuditFileName) as any || formParams;
    }
    if (clientInfoInternalAuditFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.InternalAuditFileUri', <any>clientInfoInternalAuditFileUri) as any || formParams;
    }
    if (clientInfoExternalAudit !== undefined) {
      formParams = formParams.append('ClientInfo.ExternalAudit', <any>clientInfoExternalAudit) as any || formParams;
    }
    if (clientInfoExternalAuditFileName !== undefined) {
      formParams = formParams.append('ClientInfo.ExternalAuditFileName', <any>clientInfoExternalAuditFileName) as any || formParams;
    }
    if (clientInfoExternalAuditFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.ExternalAuditFileUri', <any>clientInfoExternalAuditFileUri) as any || formParams;
    }
    if (clientInfoNumWorkers !== undefined) {
      formParams = formParams.append('ClientInfo.NumWorkers', <any>clientInfoNumWorkers) as any || formParams;
    }
    if (clientInfoAnnualAccountingFirstYear !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingFirstYear', <any>clientInfoAnnualAccountingFirstYear) as any || formParams;
    }
    if (clientInfoAnnualAccountingFirstYearFileName !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingFirstYearFileName', <any>clientInfoAnnualAccountingFirstYearFileName) as any || formParams;
    }
    if (clientInfoAnnualAccountingFirstYearFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingFirstYearFileUri', <any>clientInfoAnnualAccountingFirstYearFileUri) as any || formParams;
    }
    if (clientInfoAnnualAccountingSecondYear !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingSecondYear', <any>clientInfoAnnualAccountingSecondYear) as any || formParams;
    }
    if (clientInfoAnnualAccountingSecondYearFileName !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingSecondYearFileName', <any>clientInfoAnnualAccountingSecondYearFileName) as any || formParams;
    }
    if (clientInfoAnnualAccountingSecondYearFileUri !== undefined) {
      formParams = formParams.append('ClientInfo.AnnualAccountingSecondYearFileUri', <any>clientInfoAnnualAccountingSecondYearFileUri) as any || formParams;
    }
    if (clientInfoFinanceTypes) {
      clientInfoFinanceTypes.forEach((element) => {
        formParams = formParams.append('ClientInfo.FinanceTypes', <any>element) as any || formParams;
      })
    }
    if (clientInfoIsoCertifications) {
      clientInfoIsoCertifications.forEach((element) => {
        formParams = formParams.append('ClientInfo.IsoCertifications', <any>element) as any || formParams;
      })
    }
    if (name !== undefined) {
      formParams = formParams.append('Name', <any>name) as any || formParams;
    }
    if (companyCif !== undefined) {
      formParams = formParams.append('CompanyCif', <any>companyCif) as any || formParams;
    }
    if (numOfLicenses !== undefined) {
      formParams = formParams.append('NumOfLicenses', <any>numOfLicenses) as any || formParams;
    }
    if (typeOfActivity !== undefined) {
      formParams = formParams.append('TypeOfActivity', <any>typeOfActivity) as any || formParams;
    }
    if (otherTypeOfActivity !== undefined) {
      formParams = formParams.append('OtherTypeOfActivity', <any>otherTypeOfActivity) as any || formParams;
    }
    if (companyRegistrationFileName !== undefined) {
      formParams = formParams.append('CompanyRegistrationFileName', <any>companyRegistrationFileName) as any || formParams;
    }
    if (companyRegistrationFileUri !== undefined) {
      formParams = formParams.append('CompanyRegistrationFileUri', <any>companyRegistrationFileUri) as any || formParams;
    }
    if (iconImageFileName !== undefined) {
      formParams = formParams.append('IconImageFileName', <any>iconImageFileName) as any || formParams;
    }
    if (iconImageFileUri !== undefined) {
      formParams = formParams.append('IconImageFileUri', <any>iconImageFileUri) as any || formParams;
    }
    if (coverPageImageFileName !== undefined) {
      formParams = formParams.append('CoverPageImageFileName', <any>coverPageImageFileName) as any || formParams;
    }
    if (coverPageImageFileUri !== undefined) {
      formParams = formParams.append('CoverPageImageFileUri', <any>coverPageImageFileUri) as any || formParams;
    }
    if (countryId !== undefined) {
      formParams = formParams.append('CountryId', <any>countryId) as any || formParams;
    }

    return this.httpClient.request<any>('put', `${this.basePath}/Users/UpdateVisibleInfoClientData`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param filename
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersUploadOnBoardingFileFilenameGet(filename: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public usersUploadOnBoardingFileFilenameGet(filename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public usersUploadOnBoardingFileFilenameGet(filename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public usersUploadOnBoardingFileFilenameGet(filename: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (filename === null || filename === undefined) {
      throw new Error('Required parameter filename was null or undefined when calling usersUploadOnBoardingFileFilenameGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/Users/UploadOnBoardingFile/${encodeURIComponent(String(filename))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersUserIdDelete(userId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public usersUserIdDelete(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public usersUserIdDelete(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public usersUserIdDelete(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling usersUserIdDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<boolean>('delete', `${this.basePath}/Users/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
