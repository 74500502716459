<fieldset class="u-mb-1medium">
  <legend class="c-form__legend">
    {{ "ScoringCandidatesIndividualSearch.information" | translate }}
  </legend>
  <div class="row">
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>{{
            "ScoringCandidatesIndividualSearch.filter.name" | translate
          }}</mat-label>
          <input
            type="text"
            matInput
            name="companyName"
            value=""
            id="companyName"
            [(ngModel)]="model.name"
            class="c-form__input"
            placeholder="{{
              'ScoringCandidatesIndividualSearch.filter.name' | translate
            }}"
            required
          />
          <mat-error>
            {{ "ScoringCandidatesIndividualSearch.required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12 col-lg-3 col-xl-3">
      <div class="c-form__block">
        <div class="c-select">
          <mat-form-field>
            <mat-select
              placeholder="{{
                'ScoringCandidatesIndividualSearch.filter.gender' | translate
              }}"
              [(ngModel)]="model.gender"
            >
              <mat-option
                *ngFor="let gender of genderList"
                [value]="gender.code"
              >
                {{ gender | translatedescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-lg-6 col-xl-6">
      <legend class="c-form__legend">
        {{ "ScoringCandidatesIndividualSearch.birthDate" | translate }}
      </legend>
      <div class="c-form__block">
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="{{
              'ScoringCandidatesIndividualSearch.filter.birthDate' | translate
            }}"
            [(ngModel)]="model.birthDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="col-2 col-lg-2 col-xl-2">
      <legend class="c-form__legend">
        {{ "ScoringCandidatesIndividualSearch.location" | translate }}
      </legend>
      <div class="c-form__block">
        <div class="c-select">
          <mat-form-field>
            <mat-select
              placeholder="{{
                'ScoringCandidatesIndividualSearch.filter.location' | translate
              }}"
              [(ngModel)]="model.country"
            >
              <mat-option
                *ngFor="let country of countryList"
                [value]="country.codeAlpha3"
              >
                {{ country.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-2 col-lg-2 col-xl-2">
      <legend class="c-form__legend">
        {{ "ScoringCandidatesIndividualSearch.birthPlace" | translate }}
      </legend>
      <div class="c-form__block">
        <div class="c-select">
          <mat-form-field>
            <mat-select
              placeholder="{{
                'ScoringCandidatesIndividualSearch.filter.birthPlace'
                  | translate
              }}"
              [(ngModel)]="model.birthPlace"
            >
              <mat-option
                *ngFor="let country of countryList"
                [value]="country.codeAlpha3"
              >
                {{ country.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-2 col-lg-2 col-xl-2">
      <legend class="c-form__legend">
        {{ "ScoringCandidatesIndividualSearch.nationality" | translate }}
      </legend>
      <div class="c-form__block">
        <div class="c-select">
          <mat-form-field>
            <mat-select
              placeholder="{{
                'ScoringCandidatesIndividualSearch.filter.nationality'
                  | translate
              }}"
              [(ngModel)]="model.nationality"
            >
              <mat-option
                *ngFor="let country of countryList"
                [value]="country.codeAlpha3"
              >
                {{ country.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</fieldset>
<fieldset class="u-mb-1medium">
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-3">
      <div class="c-form__block">
        <button
          (click)="search()"
          type="button"
          [disabled]="!model.name?.length"
          class="c-button u-mr-1"
        >
          {{ "ScoringCandidatesIndividualSearch.search" | translate }}
        </button>
      </div>
    </div>
  </div>
</fieldset>
