import { Component, Input } from "@angular/core";
import { CampaignStatus } from "@app/models/CampaignStatus";
import { CampaignThirdParty } from "@app/models/CampaignThirdParty";
import { EmailNotificaitonService } from "@app/services/email-notification.service";
import { TranslateService } from "@ngx-translate/core";
import { Toaster } from "ngx-toast-notifications";

@Component({
    selector: 'app-campaing-third-party-table-actions',
    templateUrl: './campaign-third-party-table-actions.component.html',
    styleUrls:['./campaign-third-party-table-actions.component.scss']
})
export class CampaignThirdPartyTableActionsComponent {

    @Input() campaignThirdParty: CampaignThirdParty;

    campaignStatus = CampaignStatus;
    failedToSendEmail: string;
    emailSent: string;

    constructor(private emailNotificationSerivce: EmailNotificaitonService,
        private translate: TranslateService,
        private toaster: Toaster) {

        this.translate.get('CampaignTable.emailSent').subscribe(translation => {
            this.emailSent = translation;
        });
        
        this.translate.get('CampaignTable.failedToSendEmail').subscribe(translation => {
            this.failedToSendEmail = translation;
        });            
    }

    campaignFinished(): boolean {
        const currentDate = new Date();
        const endDate = new Date(this.campaignThirdParty.endDate);
        return endDate.getTime() < currentDate.getTime();
    }

    resendInvitation() {
        this.emailNotificationSerivce.resendInvitationEmail(this.campaignThirdParty.thirdPartyId, this.campaignThirdParty.campaignId).subscribe(r => {
            if (r) {
                this.toaster.open({ text: this.emailSent, duration: 6000, type: 'success' });
            } else {
                this.toaster.open({ text: this.failedToSendEmail, duration: 6000, type: 'danger' });
            }
        });
    }
}
