<div class="o-wrapper o-wrapper--no-padding">
    <section class="c-home">
        <article class="c-home__left">
            <div class="c-home__left-wrapper">
                <time class="o-date u-mb-1">{{today | date:'d MMM y, H:mm':undefined:locale}}</time>
                <h2 class="c-home__title">{{'Home.hi.valor'|translate}}, <span>{{ userName }}</span></h2>
                <h3 class="c-home__subtitle">{{'Home.intro.valor'|translate}}</h3>
                <a [routerLink]="['/evaluaciones']" routerLinkActive="is-active" class="c-button c-button--gradient">{{'Home.evaluaciones.valor'|translate}}</a>
                <a [routerLink]="['/busqueda']" routerLinkActive="is-active" class="c-button c-button--gradient inverse">{{'Home.terceros.valor'|translate}}</a>
            </div>
        </article>
        <div class="c-home__right o-aspect-ratio" aria-hidden="true">
            <img src="assets/home-bubbles.svg" alt="" class="o-aspect-ratio__target" loading="lazy" />
            <img [src]="companyLogo ? loadCompanyLogo() : '/assets/home-empty.png'" alt="riskallay logo" class="company-logo" loading="lazy" />
        </div>
    </section>
</div>
