import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionaryDTO } from '@app/models/Questionary';
import { QuestionaryService } from '@app/services/questionary.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-template-actions',
    templateUrl: './template-actions.component.html',
    styleUrls: ['./template-actions.component.scss']
})
export class TemplateActionsComponent implements OnInit {

    @Input() actions: String[];
    @Input() questionary: QuestionaryDTO;

    @Output() editTemplate = new EventEmitter<number>();
    @Output() duplicateTemplate = new EventEmitter<QuestionaryDTO>();

    isQuestionaryInUse$: Observable<boolean>

    constructor(private questionaryService: QuestionaryService) { }

    ngOnInit(): void {
        this.isQuestionaryInUse$ = this.questionaryService.isQuestionaryInUse(this.questionary.Id);
    }

    onEditTemplate() {
        this.editTemplate.emit(this.questionary.Id);
    }

    onDuplicateTemplate() {
        this.duplicateTemplate.emit(this.questionary);
    }

}
