import { Component, Input } from "@angular/core";
import { AMLUpdateRecord } from "@app/modules/screening-hub/models/GesintelScreening/AMLUpdateRecord";

@Component({
  selector: 'aml-update-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.scss'],
})
export class AMLUpdateBasicDataComponent {
  @Input() scoringDetails: AMLUpdateRecord;

  constructor() { }
}
