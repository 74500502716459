<div class="o-wrapper">
    <main id="content">
        <div *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
        </div>
        <!--encabezado de sección-->
        <div class="c-head-section">
            <div class="c-head-section__left">
                <h2 class="o-title">{{'NuevoThird.newTitle.valor' | translate}}</h2>
                <p class="o-text">{{'NuevoThird.newDescription.valor' | translate}}</p>
            </div>
            <div class="c-head-section__right c-head-section__right--bootstrap">
                <button
                    class="c-button"
                    (click)="formularioThirdComponent?.saveChanges()"
                    [disabled]="!isFormValid"
                >
                    {{'NuevoThird.form.button.save.valor' | translate}}
                </button>
            </div>
        </div>
        <!--volver atrás-->
        <div class="c-go-back c-go-back--spaceLeft">
            <a [routerLink]="['/busqueda']" class="c-go-back__button" (click)="back()">
                <span class="c-go-back__arrow" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                        <path
                            d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                            transform="translate(-5-6.01)" />
                    </svg>
                </span> {{'NuevoThird.back2search.valor' | translate}}
            </a>
        </div>
        
        <!--formulario-->
        <div class="o-scroll o-scroll--small">
            <app-formulario-third
                [errores]="errores"
                [sectorsNoSelected]="sectorsNoSelected"
                [countriesNoSelected]="countriesNoSelected"
                [languagesNoSelected]="languagesNoSelected"
                [thirdPartyTypeNoSelected]="thirdPartyTypeNoSelected"
                [responsiblesNoSelected]="responsiblesNoSelected"
                [natureOfActivityNoSelected]="natureOfActivityNoSelected"
                [typeOfActivityNoSelected]="typeOfActivityNoSelected"
                (onSubmit)="saveChanges($event)"
                (isFormValidEmitter)="formValidityHandler($event)"
            >
            </app-formulario-third>
        </div>
    </main>
</div>