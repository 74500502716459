import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AMLUpdateLink } from '../../models/AMLUpdateLink';

@Component({
  selector: 'aml-update-confirm-refresh-screening',
  templateUrl: './aml-update-confirm-refresh-screening.component.html',
  styleUrls: ['./aml-update-confirm-refresh-screening.component.scss'],
})
export class AMLUpdateConfirmRefreshScreeningComponent {
  constructor(
    public dialogRef: MatDialogRef<AMLUpdateConfirmRefreshScreeningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AMLUpdateLink
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
