
import { RemoteService } from './remote.service';
import { Injectable, Inject } from '@angular/core';
import { CampaignCreateDTO, Campaign, CampaignQueryResourceDto, CampaignUpdateDTO } from '@app/models/Campaign';
import { formatearFecha, toQueryString } from '@app/utilities/utilities';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CampaignProxyClient } from 'src/proxy/apiCampaign/campaign.proxy';
import { mapCampaign } from './mappings/campaign.mappings';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { QueryResult } from '@app/models/common/queryResult';
@Injectable({
    providedIn: 'root'
})
export class CampaignService {

    constructor(
        private remoteService: RemoteService,
        private http: HttpClient,
        private campaignProxy: CampaignProxyClient,
        private translate: TranslateService,
        @Inject('API_URL') public serverUrl: string
    ) { }

    private apiURL = 'Campaign';

    public getClientCampaigns(): Observable<Campaign[]> {
        return this.campaignProxy.campaignGet().pipe(map(r => r.map(c => mapCampaign(c, this.translate.currentLang))));
    }

    public getAllCampaignFilter(filter : CampaignQueryResourceDto): Observable<QueryResult<Campaign>>{
        return this.campaignProxy.campaignGetCampaignsFilterGet(filter.AccountStateId, filter.SearchWord, filter.SortBy, filter.IsSortAscending, filter.Page, filter.PageSize, filter.Status)
            .pipe(map(r => <QueryResult<Campaign>> {
                totalItems: r.TotalItems,
                items: r.Items.map(c => mapCampaign(c, this.translate.currentLang))
            }));            
    }

    public getAllCampaignByThirdPartyFilter(filter: CampaignQueryResourceDto, idThirdParty: number){
        return this.remoteService.getRequest(`${this.apiURL}/GetAllCampaignByThirdPartyFilter/${idThirdParty}?` + toQueryString(filter));
    }

    public createCampaign(campaign: CampaignCreateDTO) {
        const formData = this.ConstruirFormData(campaign);
        return this.http.post(`${this.serverUrl}/${this.apiURL}`, formData);
    }

    public addThirPartiesToCampaign(id: number, campaign: CampaignUpdateDTO) {
        const formData = this.ConstruirFormData(campaign);
        return this.http.put(`${this.serverUrl}/${this.apiURL}/AddThirdPartiesToStartedCampaign/${id}`, formData);
    }

    public getCampaignById(id: string) {
        return this.remoteService.getRequest(`${this.apiURL}/GetByIdAndUser/${id}`);
    }

    public getCampaign(id: number): Observable<Campaign> {
        return this.campaignProxy.campaignGetByIdAndUserIdCampaignGet(id).pipe(map(r => mapCampaign(r, this.translate.currentLang)))
    }

    public getCampaignForPageId(id: number): Observable<Campaign> {
        return this.campaignProxy.campaignIdCampaignGet(id).pipe(map(r => mapCampaign(r, this.translate.currentLang)))
    }
 
    public updateCampaign(id: number, campaign: CampaignUpdateDTO) {
        const formData = this.ConstruirFormData(campaign);
        return this.http.put(`${this.serverUrl}/${this.apiURL}/${id}`, formData);
    }

    public deleteCampaign(id: string){        
        return this.http.delete(`${this.serverUrl}/${this.apiURL}/${id}`);
    }

    private ConstruirFormData(campaign: CampaignCreateDTO): FormData {
        const formData =  new FormData();
    
        formData.append('Name', campaign.Name);
        formData.append('StartDate', formatearFecha(
            new Date(campaign.StartDate.getUTCFullYear(),
            campaign.StartDate.getUTCMonth(),
            campaign.StartDate.getUTCDate(),
            campaign.StartDate.getUTCHours(),
            campaign.StartDate.getUTCMinutes(),
            campaign.StartDate.getUTCSeconds()
        )));
        formData.append('EndDate', formatearFecha(new Date(campaign.EndDate.getUTCFullYear(),
        campaign.EndDate.getUTCMonth(),
        campaign.EndDate.getUTCDate(),
        campaign.EndDate.getUTCHours(),
        campaign.EndDate.getUTCMinutes(),
        campaign.EndDate.getUTCSeconds())));
        formData.append('Questionary', JSON.stringify(campaign.Questionary));
        formData.append('CampaignStatus', JSON.stringify(campaign.CampaignStatus));
        formData.append('ThirdParties', JSON.stringify(campaign.ThirdParties));
        formData.append('Amount', campaign.Amount.toString());
        formData.append('Introduction', campaign.Introduction);

        if(campaign.DiscardThreshold) {
            formData.append('DiscardThreshold', campaign.DiscardThreshold.toFixed(2));
        }  

        if(campaign.ApproveThreshold) {
            formData.append('ApproveThreshold', campaign.ApproveThreshold.toFixed(2));
        }  
        
        if (campaign.FileName!==null && campaign.FileName!==undefined)
            formData.append('FileName', campaign.FileName);
        
        if (campaign.FileUri!==null && campaign.FileUri!==undefined)
            formData.append('FileUri', campaign.FileUri);

        if (campaign.File){
          formData.append('File', campaign.File);
        }  

        return formData;
    }

    async getFileStorageKey(filename: string, evaluationName: string): Promise<any>{
        const res = await this.http.get(`${this.serverUrl}/Campaign/SaveClientEvaluationsFile/${filename}/${evaluationName}`).toPromise();
    
        return res;
    }
}