/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional } from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent } from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec } from '../encoder';
 import { Observable } from 'rxjs';
 import { EvaluationQuestionaryDto } from '../model/evaluationQuestionaryDto';
 import { EvaluationQuestionaryRiskCreateDto } from '../model/evaluationQuestionaryRiskCreateDto';
 import { FileDto } from '../model/fileDto';
 import { ManualScoreDto } from '../model/manualScoreDto';
 import { ManualScoreUpdateDto } from '../model/manualScoreUpdateDto';
 import { QuestionScoreOverrideDto } from '../model/questionScoreOverrideDto';
 import { QuestionaryCommentDto } from '../model/questionaryCommentDto';
 import { QuestionaryDto } from '../model/questionaryDto';
 import { QuestionaryLockCreateDto } from '../model/questionaryLockCreateDto';
 import { QuestionaryLockDto } from '../model/questionaryLockDto';
 import { QuestionaryThirdPartyDto } from '../model/questionaryThirdPartyDto';
 import { QuestionaryUpdateDto } from '../model/questionaryUpdateDto';
 import { RiskCommentDto } from '../model/riskCommentDto';
 import { Configuration } from '../configuration';

 @Injectable()
 export class QuestionariesProxyClient {

     protected basePath = '/';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();

     constructor(protected httpClient: HttpClient, @Optional()@Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }

     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }


     /**
      *
      *
      * @param idThirdParty
      * @param idCampaign
      * @param body
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesAddNewManualScoreQuestionaryIdThirdPartyIdCampaignPost(idThirdParty: number, idCampaign: number, body?: Array<ManualScoreDto>, observe?: 'body', reportProgress?: boolean): Observable<QuestionaryThirdPartyDto>;
     public questionariesAddNewManualScoreQuestionaryIdThirdPartyIdCampaignPost(idThirdParty: number, idCampaign: number, body?: Array<ManualScoreDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionaryThirdPartyDto>>;
     public questionariesAddNewManualScoreQuestionaryIdThirdPartyIdCampaignPost(idThirdParty: number, idCampaign: number, body?: Array<ManualScoreDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionaryThirdPartyDto>>;
     public questionariesAddNewManualScoreQuestionaryIdThirdPartyIdCampaignPost(idThirdParty: number, idCampaign: number, body?: Array<ManualScoreDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idThirdParty === null || idThirdParty === undefined) {
             throw new Error('Required parameter idThirdParty was null or undefined when calling questionariesAddNewManualScoreQuestionaryIdThirdPartyIdCampaignPost.');
         }

         if (idCampaign === null || idCampaign === undefined) {
             throw new Error('Required parameter idCampaign was null or undefined when calling questionariesAddNewManualScoreQuestionaryIdThirdPartyIdCampaignPost.');
         }


         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }

         return this.httpClient.request<QuestionaryThirdPartyDto>('post',`${this.basePath}/Questionaries/AddNewManualScoreQuestionary/${encodeURIComponent(String(idThirdParty))}/${encodeURIComponent(String(idCampaign))}`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param body
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesCreateQuestionaryLockPost(body?: QuestionaryLockCreateDto, observe?: 'body', reportProgress?: boolean): Observable<QuestionaryLockCreateDto>;
     public questionariesCreateQuestionaryLockPost(body?: QuestionaryLockCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionaryLockCreateDto>>;
     public questionariesCreateQuestionaryLockPost(body?: QuestionaryLockCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionaryLockCreateDto>>;
     public questionariesCreateQuestionaryLockPost(body?: QuestionaryLockCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }

         return this.httpClient.request<QuestionaryLockCreateDto>('post',`${this.basePath}/Questionaries/CreateQuestionaryLock`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param id
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesDeleteIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public questionariesDeleteIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public questionariesDeleteIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public questionariesDeleteIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling questionariesDeleteIdGet.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<boolean>('get',`${this.basePath}/Questionaries/delete/${encodeURIComponent(String(id))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param idQuestionary
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesDeleteQuestionaryLockIdQuestionaryDelete(idQuestionary: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public questionariesDeleteQuestionaryLockIdQuestionaryDelete(idQuestionary: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public questionariesDeleteQuestionaryLockIdQuestionaryDelete(idQuestionary: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public questionariesDeleteQuestionaryLockIdQuestionaryDelete(idQuestionary: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idQuestionary === null || idQuestionary === undefined) {
             throw new Error('Required parameter idQuestionary was null or undefined when calling questionariesDeleteQuestionaryLockIdQuestionaryDelete.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<any>('delete',`${this.basePath}/Questionaries/DeleteQuestionaryLock/${encodeURIComponent(String(idQuestionary))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param id
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesDuplicateQuestionaryIdPost(id: number, observe?: 'body', reportProgress?: boolean): Observable<QuestionaryDto>;
     public questionariesDuplicateQuestionaryIdPost(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionaryDto>>;
     public questionariesDuplicateQuestionaryIdPost(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionaryDto>>;
     public questionariesDuplicateQuestionaryIdPost(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling questionariesDuplicateQuestionaryIdPost.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<QuestionaryDto>('post',`${this.basePath}/Questionaries/DuplicateQuestionary/${encodeURIComponent(String(id))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<QuestionaryDto>>;
     public questionariesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<QuestionaryDto>>>;
     public questionariesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<QuestionaryDto>>>;
     public questionariesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<Array<QuestionaryDto>>('get',`${this.basePath}/Questionaries`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param idCampaign
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesGetByIdAndThirdPartyIdCampaignGet(idCampaign: number, observe?: 'body', reportProgress?: boolean): Observable<QuestionaryThirdPartyDto>;
     public questionariesGetByIdAndThirdPartyIdCampaignGet(idCampaign: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionaryThirdPartyDto>>;
     public questionariesGetByIdAndThirdPartyIdCampaignGet(idCampaign: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionaryThirdPartyDto>>;
     public questionariesGetByIdAndThirdPartyIdCampaignGet(idCampaign: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idCampaign === null || idCampaign === undefined) {
             throw new Error('Required parameter idCampaign was null or undefined when calling questionariesGetByIdAndThirdPartyIdCampaignGet.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<QuestionaryThirdPartyDto>('get',`${this.basePath}/Questionaries/GetByIdAndThirdParty/${encodeURIComponent(String(idCampaign))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param idThirdparty
      * @param idCampaign
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesGetByThirdPartyAndCampaignIdThirdpartyIdCampaignGet(idThirdparty: number, idCampaign: number, observe?: 'body', reportProgress?: boolean): Observable<QuestionaryThirdPartyDto>;
     public questionariesGetByThirdPartyAndCampaignIdThirdpartyIdCampaignGet(idThirdparty: number, idCampaign: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionaryThirdPartyDto>>;
     public questionariesGetByThirdPartyAndCampaignIdThirdpartyIdCampaignGet(idThirdparty: number, idCampaign: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionaryThirdPartyDto>>;
     public questionariesGetByThirdPartyAndCampaignIdThirdpartyIdCampaignGet(idThirdparty: number, idCampaign: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idThirdparty === null || idThirdparty === undefined) {
             throw new Error('Required parameter idThirdparty was null or undefined when calling questionariesGetByThirdPartyAndCampaignIdThirdpartyIdCampaignGet.');
         }

         if (idCampaign === null || idCampaign === undefined) {
             throw new Error('Required parameter idCampaign was null or undefined when calling questionariesGetByThirdPartyAndCampaignIdThirdpartyIdCampaignGet.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<QuestionaryThirdPartyDto>('get',`${this.basePath}/Questionaries/GetByThirdPartyAndCampaign/${encodeURIComponent(String(idThirdparty))}/${encodeURIComponent(String(idCampaign))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param idQuestionary
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesGetQuestionaryLockIdQuestionaryGet(idQuestionary: number, observe?: 'body', reportProgress?: boolean): Observable<QuestionaryLockDto>;
     public questionariesGetQuestionaryLockIdQuestionaryGet(idQuestionary: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionaryLockDto>>;
     public questionariesGetQuestionaryLockIdQuestionaryGet(idQuestionary: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionaryLockDto>>;
     public questionariesGetQuestionaryLockIdQuestionaryGet(idQuestionary: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idQuestionary === null || idQuestionary === undefined) {
             throw new Error('Required parameter idQuestionary was null or undefined when calling questionariesGetQuestionaryLockIdQuestionaryGet.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<QuestionaryLockDto>('get',`${this.basePath}/Questionaries/GetQuestionaryLock/${encodeURIComponent(String(idQuestionary))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param idThirdParty
      * @param idCampaign
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesGetScoresByThirdPartyAndCampaignIdThirdPartyIdCampaignGet(idThirdParty: number, idCampaign: number, observe?: 'body', reportProgress?: boolean): Observable<EvaluationQuestionaryDto>;
     public questionariesGetScoresByThirdPartyAndCampaignIdThirdPartyIdCampaignGet(idThirdParty: number, idCampaign: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EvaluationQuestionaryDto>>;
     public questionariesGetScoresByThirdPartyAndCampaignIdThirdPartyIdCampaignGet(idThirdParty: number, idCampaign: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EvaluationQuestionaryDto>>;
     public questionariesGetScoresByThirdPartyAndCampaignIdThirdPartyIdCampaignGet(idThirdParty: number, idCampaign: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idThirdParty === null || idThirdParty === undefined) {
             throw new Error('Required parameter idThirdParty was null or undefined when calling questionariesGetScoresByThirdPartyAndCampaignIdThirdPartyIdCampaignGet.');
         }

         if (idCampaign === null || idCampaign === undefined) {
             throw new Error('Required parameter idCampaign was null or undefined when calling questionariesGetScoresByThirdPartyAndCampaignIdThirdPartyIdCampaignGet.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<EvaluationQuestionaryDto>('get',`${this.basePath}/Questionaries/GetScoresByThirdPartyAndCampaign/${encodeURIComponent(String(idThirdParty))}/${encodeURIComponent(String(idCampaign))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param id
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<QuestionaryDto>;
     public questionariesIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionaryDto>>;
     public questionariesIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionaryDto>>;
     public questionariesIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling questionariesIdGet.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<QuestionaryDto>('get',`${this.basePath}/Questionaries/${encodeURIComponent(String(id))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param id
      * @param body
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesIdPut(id: number, body?: QuestionaryUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public questionariesIdPut(id: number, body?: QuestionaryUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public questionariesIdPut(id: number, body?: QuestionaryUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public questionariesIdPut(id: number, body?: QuestionaryUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling questionariesIdPut.');
         }


         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }

         return this.httpClient.request<any>('put',`${this.basePath}/Questionaries/${encodeURIComponent(String(id))}`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param questionaryId
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesIsQuestionaryInUseQuestionaryIdGet(questionaryId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public questionariesIsQuestionaryInUseQuestionaryIdGet(questionaryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public questionariesIsQuestionaryInUseQuestionaryIdGet(questionaryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public questionariesIsQuestionaryInUseQuestionaryIdGet(questionaryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (questionaryId === null || questionaryId === undefined) {
             throw new Error('Required parameter questionaryId was null or undefined when calling questionariesIsQuestionaryInUseQuestionaryIdGet.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<boolean>('get',`${this.basePath}/Questionaries/IsQuestionaryInUse/${encodeURIComponent(String(questionaryId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
     *
     *
     * @param campaignId
     * @param thirdPartyId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public questionariesOverrideScoreCampaignIdThirdPartyIdPost(campaignId: number, thirdPartyId: number, body?: Array<QuestionScoreOverrideDto>, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public questionariesOverrideScoreCampaignIdThirdPartyIdPost(campaignId: number, thirdPartyId: number, body?: Array<QuestionScoreOverrideDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public questionariesOverrideScoreCampaignIdThirdPartyIdPost(campaignId: number, thirdPartyId: number, body?: Array<QuestionScoreOverrideDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public questionariesOverrideScoreCampaignIdThirdPartyIdPost(campaignId: number, thirdPartyId: number, body?: Array<QuestionScoreOverrideDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling questionariesOverrideScoreCampaignIdThirdPartyIdPost.');
        }

        if (thirdPartyId === null || thirdPartyId === undefined) {
            throw new Error('Required parameter thirdPartyId was null or undefined when calling questionariesOverrideScoreCampaignIdThirdPartyIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/Questionaries/OverrideScore/${encodeURIComponent(String(campaignId))}/${encodeURIComponent(String(thirdPartyId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
      *
      *
      * @param idCampaign
      * @param idThirdParty
      * @param filename
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesSaveQuestionaryAnswersFileIdCampaignIdThirdPartyFilenameGet(idCampaign: number, idThirdParty: number, filename: string, observe?: 'body', reportProgress?: boolean): Observable<FileDto>;
     public questionariesSaveQuestionaryAnswersFileIdCampaignIdThirdPartyFilenameGet(idCampaign: number, idThirdParty: number, filename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileDto>>;
     public questionariesSaveQuestionaryAnswersFileIdCampaignIdThirdPartyFilenameGet(idCampaign: number, idThirdParty: number, filename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileDto>>;
     public questionariesSaveQuestionaryAnswersFileIdCampaignIdThirdPartyFilenameGet(idCampaign: number, idThirdParty: number, filename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idCampaign === null || idCampaign === undefined) {
             throw new Error('Required parameter idCampaign was null or undefined when calling questionariesSaveQuestionaryAnswersFileIdCampaignIdThirdPartyFilenameGet.');
         }

         if (idThirdParty === null || idThirdParty === undefined) {
             throw new Error('Required parameter idThirdParty was null or undefined when calling questionariesSaveQuestionaryAnswersFileIdCampaignIdThirdPartyFilenameGet.');
         }

         if (filename === null || filename === undefined) {
             throw new Error('Required parameter filename was null or undefined when calling questionariesSaveQuestionaryAnswersFileIdCampaignIdThirdPartyFilenameGet.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<FileDto>('get',`${this.basePath}/Questionaries/SaveQuestionaryAnswersFile/${encodeURIComponent(String(idCampaign))}/${encodeURIComponent(String(idThirdParty))}/${encodeURIComponent(String(filename))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param idCampaign
      * @param idThirdParty
      * @param thirdPartyId
      * @param questionaryId
      * @param clientId
      * @param campaignId
      * @param evaluationQuestionaryRisk
      * @param submit
      * @param creationTime
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesSaveQuestionaryAnswersIdCampaignIdThirdPartyPostForm(idCampaign: number, idThirdParty: number, thirdPartyId?: number, questionaryId?: number, clientId?: number, campaignId?: number, evaluationQuestionaryRisk?: Array<EvaluationQuestionaryRiskCreateDto>, submit?: boolean, creationTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<QuestionaryThirdPartyDto>>;
     public questionariesSaveQuestionaryAnswersIdCampaignIdThirdPartyPostForm(idCampaign: number, idThirdParty: number, thirdPartyId?: number, questionaryId?: number, clientId?: number, campaignId?: number, evaluationQuestionaryRisk?: Array<EvaluationQuestionaryRiskCreateDto>, submit?: boolean, creationTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<QuestionaryThirdPartyDto>>>;
     public questionariesSaveQuestionaryAnswersIdCampaignIdThirdPartyPostForm(idCampaign: number, idThirdParty: number, thirdPartyId?: number, questionaryId?: number, clientId?: number, campaignId?: number, evaluationQuestionaryRisk?: Array<EvaluationQuestionaryRiskCreateDto>, submit?: boolean, creationTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<QuestionaryThirdPartyDto>>>;
     public questionariesSaveQuestionaryAnswersIdCampaignIdThirdPartyPostForm(idCampaign: number, idThirdParty: number, thirdPartyId?: number, questionaryId?: number, clientId?: number, campaignId?: number, evaluationQuestionaryRisk?: Array<EvaluationQuestionaryRiskCreateDto>, submit?: boolean, creationTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idCampaign === null || idCampaign === undefined) {
             throw new Error('Required parameter idCampaign was null or undefined when calling questionariesSaveQuestionaryAnswersIdCampaignIdThirdPartyPost.');
         }

         if (idThirdParty === null || idThirdParty === undefined) {
             throw new Error('Required parameter idThirdParty was null or undefined when calling questionariesSaveQuestionaryAnswersIdCampaignIdThirdPartyPost.');
         }








         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];

         const canConsumeForm = this.canConsumeForm(consumes);

         let formParams: { append(param: string, value: any): void; };
         let useForm = false;
         let convertFormParamsToString = false;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }

         if (thirdPartyId !== undefined) {
             formParams = formParams.append('ThirdPartyId', <any>thirdPartyId) as any || formParams;
         }
         if (questionaryId !== undefined) {
             formParams = formParams.append('QuestionaryId', <any>questionaryId) as any || formParams;
         }
         if (clientId !== undefined) {
             formParams = formParams.append('ClientId', <any>clientId) as any || formParams;
         }
         if (campaignId !== undefined) {
             formParams = formParams.append('CampaignId', <any>campaignId) as any || formParams;
         }
         if (evaluationQuestionaryRisk) {
             evaluationQuestionaryRisk.forEach((element) => {
                 formParams = formParams.append('EvaluationQuestionaryRisk', <any>element) as any || formParams;
             })
         }
         if (submit !== undefined) {
             formParams = formParams.append('Submit', <any>submit) as any || formParams;
         }
         if (creationTime !== undefined) {
             formParams = formParams.append('CreationTime', <any>creationTime) as any || formParams;
         }

         return this.httpClient.request<Array<QuestionaryThirdPartyDto>>('post',`${this.basePath}/Questionaries/SaveQuestionaryAnswers/${encodeURIComponent(String(idCampaign))}/${encodeURIComponent(String(idThirdParty))}`,
             {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
     *
     *
     * @param idCampaign
     * @param idThirdParty
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public questionariesSubmitQuestionaryIdCampaignIdThirdPartyPost(idCampaign: number, idThirdParty: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public questionariesSubmitQuestionaryIdCampaignIdThirdPartyPost(idCampaign: number, idThirdParty: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public questionariesSubmitQuestionaryIdCampaignIdThirdPartyPost(idCampaign: number, idThirdParty: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public questionariesSubmitQuestionaryIdCampaignIdThirdPartyPost(idCampaign: number, idThirdParty: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCampaign === null || idCampaign === undefined) {
            throw new Error('Required parameter idCampaign was null or undefined when calling questionariesSubmitQuestionaryIdCampaignIdThirdPartyPost.');
        }

        if (idThirdParty === null || idThirdParty === undefined) {
            throw new Error('Required parameter idThirdParty was null or undefined when calling questionariesSubmitQuestionaryIdCampaignIdThirdPartyPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/Questionaries/SubmitQuestionary/${encodeURIComponent(String(idCampaign))}/${encodeURIComponent(String(idThirdParty))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
      *
      *
      * @param idThirdParty
      * @param idCampaign
      * @param body
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesUpdateManualScoreQuestionaryIdThirdPartyIdCampaignPut(idThirdParty: number, idCampaign: number, body?: Array<ManualScoreUpdateDto>, observe?: 'body', reportProgress?: boolean): Observable<QuestionaryThirdPartyDto>;
     public questionariesUpdateManualScoreQuestionaryIdThirdPartyIdCampaignPut(idThirdParty: number, idCampaign: number, body?: Array<ManualScoreUpdateDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionaryThirdPartyDto>>;
     public questionariesUpdateManualScoreQuestionaryIdThirdPartyIdCampaignPut(idThirdParty: number, idCampaign: number, body?: Array<ManualScoreUpdateDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionaryThirdPartyDto>>;
     public questionariesUpdateManualScoreQuestionaryIdThirdPartyIdCampaignPut(idThirdParty: number, idCampaign: number, body?: Array<ManualScoreUpdateDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idThirdParty === null || idThirdParty === undefined) {
             throw new Error('Required parameter idThirdParty was null or undefined when calling questionariesUpdateManualScoreQuestionaryIdThirdPartyIdCampaignPut.');
         }

         if (idCampaign === null || idCampaign === undefined) {
             throw new Error('Required parameter idCampaign was null or undefined when calling questionariesUpdateManualScoreQuestionaryIdThirdPartyIdCampaignPut.');
         }


         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }

         return this.httpClient.request<QuestionaryThirdPartyDto>('put',`${this.basePath}/Questionaries/UpdateManualScoreQuestionary/${encodeURIComponent(String(idThirdParty))}/${encodeURIComponent(String(idCampaign))}`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param body
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesUpdateQuestionaryCommentPut(body?: QuestionaryCommentDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public questionariesUpdateQuestionaryCommentPut(body?: QuestionaryCommentDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public questionariesUpdateQuestionaryCommentPut(body?: QuestionaryCommentDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public questionariesUpdateQuestionaryCommentPut(body?: QuestionaryCommentDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }

         return this.httpClient.request<any>('put',`${this.basePath}/Questionaries/UpdateQuestionaryComment`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param idQuestionary
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesUpdateQuestionaryLockIdQuestionaryPut(idQuestionary: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public questionariesUpdateQuestionaryLockIdQuestionaryPut(idQuestionary: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public questionariesUpdateQuestionaryLockIdQuestionaryPut(idQuestionary: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public questionariesUpdateQuestionaryLockIdQuestionaryPut(idQuestionary: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (idQuestionary === null || idQuestionary === undefined) {
             throw new Error('Required parameter idQuestionary was null or undefined when calling questionariesUpdateQuestionaryLockIdQuestionaryPut.');
         }

         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.request<any>('put',`${this.basePath}/Questionaries/UpdateQuestionaryLock/${encodeURIComponent(String(idQuestionary))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      *
      *
      * @param body
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public questionariesUpdateQuestionaryRiskCommentsPut(body?: Array<RiskCommentDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public questionariesUpdateQuestionaryRiskCommentsPut(body?: Array<RiskCommentDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public questionariesUpdateQuestionaryRiskCommentsPut(body?: Array<RiskCommentDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public questionariesUpdateQuestionaryRiskCommentsPut(body?: Array<RiskCommentDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


         let headers = this.defaultHeaders;

         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }

         return this.httpClient.request<any>('put',`${this.basePath}/Questionaries/UpdateQuestionaryRiskComments`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

 }
