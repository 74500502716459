import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThirdPartySignUpInfo } from '@app/models/ThirdPartySignUpInfo';

@Component({
  selector: 'app-third-party-sign-up-intro',
  templateUrl: './third-party-sign-up-intro.component.html',
  styleUrls: ['./third-party-sign-up-intro.component.scss']
})
export class ThirdPartySignUpIntroComponent implements OnInit {

  thirdPartySignUpInfo = {} as ThirdPartySignUpInfo;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.thirdPartySignUpInfo.email = params['email'];
      this.thirdPartySignUpInfo.companyName = params['third'];
      this.thirdPartySignUpInfo.clientName = params['client'];
      this.thirdPartySignUpInfo.clientEmail = params['clientEmail'];
      this.thirdPartySignUpInfo.clientId = params['clientid'];
      this.thirdPartySignUpInfo.taxId = params['taxid'];
      this.thirdPartySignUpInfo.countryId = +params['country'];
      this.thirdPartySignUpInfo.typeOfActivity = +params['soctype'];
      this.thirdPartySignUpInfo.sectorId = +params['secid'];
      this.thirdPartySignUpInfo.address = params['address'];
      this.thirdPartySignUpInfo.zipCode = params['zipcode'];

      if (!this.thirdPartySignUpInfo.email || !this.thirdPartySignUpInfo.clientEmail || !this.thirdPartySignUpInfo.clientId || !this.thirdPartySignUpInfo.clientName || !this.thirdPartySignUpInfo.companyName) {
        this.router.navigateByUrl('/');
      }
  });
  }

  goToSignUp(): void {
    this.router.navigateByUrl('/third-party-sign-up-form', { state: { thirdPartyInfo: this.thirdPartySignUpInfo } });
  }

}
