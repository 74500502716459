import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from "../../../services/global.service";
import { ThirdPartyTypesService } from '@app/services/third-party-types.service';
import { ThirdPartyType } from '@app/models/ThirdPartyType';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ThirdPartiesListTableComponent } from '../third-parties-list-table/third-parties-list-table.component';
import { Toaster } from 'ngx-toast-notifications';
import { Role } from '@app/models/Role';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @ViewChild(ThirdPartiesListTableComponent) thirdPartiesListTable: ThirdPartiesListTableComponent;

  thirdPartyTypes: ThirdPartyType[] = [];

  loading: boolean;
  errorDownloadingMsg: string;
  Role = Role;

  constructor(
    public global: GlobalService,
    private thirdPartyTypesService: ThirdPartyTypesService,
    private translate: TranslateService,
    public toaster: Toaster
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.global.closeThird();

    this.translate.get('Tabla3PP.errorDownloading').subscribe(translation => {
      this.errorDownloadingMsg = translation;
    });

    forkJoin({
      translation: this.translate.get('Tabla3PP.tab.all.valor'),
      thirdPartyTypes: this.thirdPartyTypesService.getThirdPartyTypes()
    })
      .subscribe(
        ({ translation, thirdPartyTypes }) => {
          const allFilterType: ThirdPartyType =
            { code: 'All', id: null, description: translation, descriptionEN: translation, descriptionPT: translation, descriptionFR: translation };
          this.thirdPartyTypes = [allFilterType, ...thirdPartyTypes];
          this.loading = false;
        },
        _ => {
          this.toaster.open({ text: this.errorDownloadingMsg, duration: 6000, type: 'danger' });
          this.loading = false;
        });
  }

  changeStates(): void {
    this.thirdPartiesListTable.changeStates();
  }
}
