import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomField } from '@app/models/CustomField';
import { CustomFieldsService } from '@app/services/custom-fields.service';

@Component({
    selector: 'app-custom-field-detail',
    templateUrl: './custom-field-detail.component.html',
    styleUrls: ['./custom-field-detail.component.scss']
})
export class CustomFieldDetailComponent implements OnInit {

    isValid: boolean;
    customField: CustomField;
    loading = true;

    constructor(
        private customFieldsService: CustomFieldsService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.customFieldsService
            .getCustomFieldById(params.id)
            .subscribe((customField: CustomField) => {
                this.customField = customField;
                this.loading = false;
            });
        });
    }

    updateCustomField() {
        this.customFieldsService
            .updateCustomField(this.customField)
            .subscribe((response) => {
                this.router.navigate(['/ajustes-generales/custom-fields']);
            });
    }

    isFormValid(isValid: boolean) {
        this.isValid = isValid;
    }

    customFieldUpdated(customField: CustomField) {
        this.customField = customField;
    }
}
