/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsageDto } from '../model/usageDto';
import { Configuration } from '../configuration';


@Injectable()
export class UsageProxyClient {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }



  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usageAmlUpdateUsageGet(observe?: 'body', reportProgress?: boolean): Observable<UsageDto>;
  public usageAmlUpdateUsageGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsageDto>>;
  public usageAmlUpdateUsageGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsageDto>>;
  public usageAmlUpdateUsageGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<UsageDto>('get', `${this.basePath}/Usage/amlUpdateUsage`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usageCampaingnsCreatedGet(observe?: 'body', reportProgress?: boolean): Observable<number>;
  public usageCampaingnsCreatedGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
  public usageCampaingnsCreatedGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
  public usageCampaingnsCreatedGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<number>('get', `${this.basePath}/Usage/campaingnsCreated`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public usageEquifaxWSUsageGet(observe?: 'body', reportProgress?: boolean): Observable<UsageDto>;
  public usageEquifaxWSUsageGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsageDto>>;
  public usageEquifaxWSUsageGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsageDto>>;
  public usageEquifaxWSUsageGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let headers = this.defaultHeaders;

      // authentication (Bearer) required
      if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
          headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'text/plain',
          'application/json',
          'text/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<UsageDto>('get',`${this.basePath}/Usage/equifaxWSUsage`,
          {
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
  }

  /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public usageMemberCheckUsageGet(observe?: 'body', reportProgress?: boolean): Observable<UsageDto>;
  public usageMemberCheckUsageGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsageDto>>;
  public usageMemberCheckUsageGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsageDto>>;
  public usageMemberCheckUsageGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let headers = this.defaultHeaders;

      // authentication (Bearer) required
      if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
          headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'text/plain',
          'application/json',
          'text/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<UsageDto>('get',`${this.basePath}/Usage/memberCheckUsage`,
          {
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usageRefinitivUsageGet(observe?: 'body', reportProgress?: boolean): Observable<UsageDto>;
  public usageRefinitivUsageGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsageDto>>;
  public usageRefinitivUsageGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsageDto>>;
  public usageRefinitivUsageGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<UsageDto>('get', `${this.basePath}/Usage/refinitivUsage`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usageThirdPartyUsageGet(observe?: 'body', reportProgress?: boolean): Observable<UsageDto>;
  public usageThirdPartyUsageGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsageDto>>;
  public usageThirdPartyUsageGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsageDto>>;
  public usageThirdPartyUsageGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<UsageDto>('get', `${this.basePath}/Usage/thirdPartyUsage`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
