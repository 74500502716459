<table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort
    (matSortChange)="sortBy($event)">

    <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='false'>
            {{'resolutionRecordList.resolutionDate'|translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <span>
                {{element.creationDate | date:'dd/MM/yyyy - HH:mm'}}
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="software">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='true'>
          Software
      </th>
      <td mat-cell *matCellDef="let element"> {{ getSofwareName(element.software) }}</td>
  </ng-container>
    <ng-container matColumnDef="searchType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='true'>
            {{'resolutionRecordList.searchType'|translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{ getSearchTypeName(element.searchType) }}</td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            {{'resolutionRecordList.createdBy' | translate}} </th>
        <td mat-cell *matCellDef="let element">
            <app-responsible-user [responsibleUserId]="element.userId"></app-responsible-user>
        </td>
    </ng-container>
    <ng-container matColumnDef="totalResults">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='true'>
            {{'resolutionRecordList.totalResults' | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.totalResults }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='true'>
            {{'resolutionRecordList.actions'|translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <a [routerLink]="" (click)="viewDetails(element.id)" class="c-table__watch">
                <span class="c-box-edit__button-ico" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12.747">
                        <path
                            d="m19.873 98.82c-.179-.244-4.436-5.985-9.873-5.985s-9.695 5.74-9.873 5.984a.66 .66 0 0 0 0 .778c.178.244 4.436 5.985 9.873 5.985s9.694-5.74 9.873-5.984a.659 .659 0 0 0 0 -.778m-9.873 5.444c-4 0-7.474-3.81-8.5-5.05 1.026-1.247 4.487-5.06 8.5-5.06s7.473 3.809 8.5 5.06c-1.025 1.247-4.486 5.05-8.5 5.05"
                            transform="translate(0-92.83)" />
                        <path
                            d="M158.678 154.725a3.956 3.956 0 1 0 3.956 3.956A3.961 3.961 0 0 0 158.678 154.725m0 6.593a2.637 2.637 0 1 1 2.637 -2.637A2.64 2.64 0 0 1 158.678 161.318"
                            transform="translate(-148.68-152.3)" />
                    </svg>
                </span> {{'resolutionRecordList.viewDetails'|translate}}
            </a>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems"
    [pageSizeOptions]="[5, 10, 25, 100]" class="c-pagination">
</mat-paginator>
