import { Component, OnInit } from "@angular/core";
import { ClientsService } from "@app/services/clients.service";

@Component({
  selector: 'app-api-access-data',
  templateUrl: './api-access-data.component.html',
  styleUrls: ['./api-access-data.component.scss']
})
export class ApiAccessDataComponent implements OnInit {

  token: string = '';

  constructor(private clientService: ClientsService) { }

  ngOnInit(): void {
    this.clientService.getApiToken().subscribe(r => {
      if (r) {
        this.token = r;
      }
    });
  }
}
