<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
  <header class="c-dialog__header">
    <h1 id="titleDialog" class="c-dialog__title">{{'deleteScoringToolConfig.title1' | translate}}</h1>
    <button mat-button (click)="close()" class="c-dialog__close" aria-label="Cerrar ventana modal"
      data-test-id="close-modal-btn">
      <span aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
          <path
            d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
            transform="translate(-309.86-406.47)" />
        </svg>
      </span>
    </button>
  </header>
  <div class="c-dialog__content">
    <h2 class="c-dialog__subtitle">{{'deleteScoringToolConfig.title2' | translate}}</h2>
    <p class="subtitle-comment">{{'deleteScoringToolConfig.title3' | translate}}</p>
    <div class="c-dialog-info u-mb-1medium">
    </div>
    <footer class="c-dialog__footer c-dialog__footer--full">
      <button mat-button (click)="close()" class="c-button c-button--light">
        {{'deleteScoringToolConfig.cancel' | translate}}</button>
      <button mat-button (click)="delete()" class="c-button c-button--green">
        {{'deleteScoringToolConfig.accept' | translate}}</button>
    </footer>
  </div>
