<div class="roles">
  <p id="keyData" class="roles__legend u-mt-1medium">{{ "roles.title" | translate }}</p>
  <section class="c-table">
    <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "roles.type" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.type }}
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "roles.category" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.category }}
        </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "roles.role" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.role }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "roles.startDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.fromDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "roles.endDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.toDate }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">{{'roles.noResults' | translate}} </td>
      </tr>
    </table>
    <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems"
      class="c-pagination">
    </mat-paginator>
  </section>
</div>
