<fieldset class="u-mb-1medium">
  <legend class="c-form__legend">
    {{ "ScoringCandidatesSearchGesintel.query" | translate }}
  </legend>
  <div class="row">
    <div class="col-12 col-md-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>
            Cedula
          </mat-label>
          <input matInput name="cedula" value="" id="cedula" [(ngModel)]="model.cedula" placeholder="Cedula" required />
          <mat-error>
            {{ "ScoringCandidatesSearchGesintel.required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</fieldset>
<fieldset class="u-mb-1medium">
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-3">
      <div class="c-form__block">
        <button (click)="search()" type="button" [disabled]="!model.cedula?.length" class="c-button u-mr-1">
          {{ "ScoringCandidatesSearchGesintel.search" | translate }}
        </button>
      </div>
    </div>
  </div>
</fieldset>
