import { Screening } from "@app/models/scoring-tools/screening";
import { mapUser } from "@app/services/mappings/user.mappings";
import { EquifaxWSScreeningDto } from "src/proxy/apiEquifaxWS/model/equifaxWSScreeningDto";

export function mapEquifaxWSScreening(dto: EquifaxWSScreeningDto): Screening {
  return <Screening>{
    createdBy: mapUser(dto.User),
    id: dto.Id,
    screeningDate: dto.ScreeningDate
  }
}
