import { Component, OnInit, Input } from '@angular/core';
import { CampaignUpdateDTO } from '@app/models/Campaign';
import { CampaignService } from '@app/services/campaign-service';
import { ActivatedRoute } from '@angular/router';
import { Table3PPConfiguration } from '@app/utilities/utilities';
import { GlobalService } from '@app/services/global.service';


@Component({
  selector: 'app-evaluacion-enviada-new-thirdparties',
  templateUrl: './evaluacion-enviada-new-thirdparties.component.html'
})
export class EvaluacionEnviadaNewThirdpartiesComponent implements OnInit {
  @Input() ThirdParties: number;
  Imported3PPConfig: Table3PPConfiguration
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private campaignService: CampaignService,
    public global: GlobalService
  ) { }

  model: CampaignUpdateDTO;
  
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.getData(params.id);

      this.Imported3PPConfig = {
            columnas: [  'id', 'name', 'type', 'headquarters', 'sector', 'responsible' , 'action'],
            acciones: ['detalle'],
            tipo :null,
            filterByTypes: true,
            tableTarget: 'importedstartedcampaign',
            importedItems : this.global.uploadedThirdParties,
        }
    });
  }

  public getData(id) {
    this.campaignService.getCampaignById(id).then(response => {
      this.model = <CampaignUpdateDTO>response;
      this.model.StartDate = new Date(this.model.StartDate);
      this.model.EndDate = new Date(this.model.EndDate);

    });


  }
}
