<div>
    <section class="c-risklist">

        <!--bloque progreso-->
        <div class="c-risklist-progress c-risklist-progress--full">
            <!--parte izquierda progress-->
            <div *ngIf="showProgress" class="c-risklist-progress__left">
                <!--izquierda progress textos-->
                <div class="c-risklist-progress-title">
                    <figure class="c-risklist-progress-title__ico" aria-hidden="true">
                        <img src="assets/hourglass.svg" alt="Reloj de arena" />
                    </figure>
                    <div class="c-risklist-progress-title__text">
                        <h4 class="o-title o-title--small dark">{{'OnboardingFillCard.progress.valor' | translate }}:
                        </h4>
                        <h5 class="o-title o-title--small">{{'OnboardingFillCard.initialData.valor' | translate }}</h5>
                    </div>
                </div>
                <!--izquierda progress barra progreso-->
                <div class="c-progress-bar c-progress-bar--row">
                    <mat-progress-bar aria-labelledby="progressBar" class="c-progress-bar__bar" value="{{ratenum}}">
                    </mat-progress-bar>
                    <p id="progressBar" class="c-progress-bar__percent">
                        <span>{{rate}}</span>
                    </p>
                </div>
            </div>
            <div id="buttonAction" class="c-risklist-progress__button-group">
                <button *ngIf="!editMode" [disabled]="!loaded || !form.valid" type="button" class="c-button"
                    (click)="saveOnboarding()">{{'OnboardingFillCard.button.continue.valor' | translate }}</button>
                <button *ngIf="editMode" [disabled]="!loaded || !form.valid" type="button" class="c-button"
                    (click)="updateOnboarding()">{{'OnboardingFillCard.button.update.valor' | translate }}</button>
            </div>
        </div>
        <mat-progress-bar *ngIf="!loaded" mode="indeterminate" class="c-spinner"></mat-progress-bar>
        <div *ngIf="loaded" [formGroup]="form" class="c-form" aria-labelledby="buttonAction">
            <div class="o-scroll">
                <!--1-->
                <article class="c-data">
                    <h1 class="c-data__title">{{'OnboardingFillCard.data1.valor'|translate}}</h1>
                    <div formGroupName="Datos1">
                        <div class="row">
                            <!--CIF-->
                            <div class="col-lg-4 col-xl-3">
                                <mat-form-field>
                                    <mat-label>{{'OnboardingFillCard.form.cif.valor'|translate}}</mat-label>
                                    <input type="text" class="c-form__input" matInput id="cif" formControlName="Cif"
                                        placeholder="{{ 'OnboardingFillCard.form.cif.valor'|translate}}">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-8 col-xl-9">
                                <div formGroupName="ActivityGroup">
                                    <div class="row">
                                        <!--tipo de actividad-->
                                        <div class="col-lg-4 col-xl-4">
                                            <div class="c-select">
                                                <mat-form-field>
                                                    <mat-label>{{'OnboardingFillCard.form.activity.valor' | translate}}
                                                    </mat-label>
                                                    <mat-select formControlName="Activity"
                                                        [compareWith]="objectComparisonFunction" id="activities"
                                                        placeholder="{{ 'OnboardingFillCard.form.activity.valor' | translate}}">
                                                        <mat-option *ngFor="let activity of activities"
                                                            [value]="activity">
                                                            {{activity | translatedescription}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <!--otro tipo-->
                                        <div class="col-lg-4 col-xl-5">
                                            <mat-form-field>
                                                <mat-label>{{'OnboardingFillCard.form.otheractivity.valor'|translate}}
                                                </mat-label>
                                                <input type="text" class="c-form__input" matInput id="otherActivity"
                                                    formControlName="OtherActivity"
                                                    placeholder="{{ 'OnboardingFillCard.form.otheractivity.valor'|translate}}">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--información extra-->
                        <div class="c-data-extra u-mt-1half">
                            <div class="c-extra-info-add">
                                <label for="infoInicial"
                                    class="c-extra-info__title">{{'OnboardingFillCard.extrainfo.valor' | translate
                                    }}</label>
                                <app-file-upload formControlName="Data1ExtraDoc" [buttonText]="uploadButtonTextExtraDoc"
                                    [qid]="null" (fileSelected)="selectData1ExtraDoc($event)" id="infoInicial"
                                    class="c-extra-info-add__file"></app-file-upload>
                            </div>
                        </div>
                    </div>
                </article>
                <!--2-->
                <article class="c-data">
                    <div formGroupName="Datos2">
                        <h1 class="c-data__title">{{'OnboardingFillCard.data2.valor'|translate}}</h1>
                        <!-- CompanyGroup -->
                        <div class="c-text-toggle u-mb-1medium">
                            <div class="c-text-toggle__item">
                                <p id="questionBusinessEstructure" class="o-text o-text--bold u-pl-0">
                                    {{'OnboardingFillCard.form.companygroup.valor'|translate}}</p>
                                <div class="c-slide-toggle" aria-labelledby="questionBusinessEstructure">
                                    <p class="c-slide-toggle__text no" aria-labelledby="businessStructure">
                                        {{'OnboardingFillCard.form.switch.no.valor'|translate}}</p>
                                    <app-slide-toggle formControlName="CompanyGroup" id="businessStructure"
                                        class="o-flex o-flex--center"></app-slide-toggle>
                                    <p class="c-slide-toggle__text yes" aria-labelledby="businessStructure">
                                        {{'OnboardingFillCard.form.switch.yes.valor'|translate}}</p>
                                </div>
                            </div>
                        </div>
                        <!--país y programa-->
                        <div class="row u-mt-1half">
                            <div class="col-lg-6 col-xl-4">
                                <div class="c-select">
                                    <mat-form-field>
                                        <mat-label>{{'OnboardingFillCard.form.country.valor' | translate}}
                                        </mat-label>
                                        <mat-select formControlName="Country" [compareWith]="objectComparisonFunction"
                                            id="country"
                                            placeholder="{{ 'OnboardingFillCard.form.country.valor' | translate}}">
                                            <mat-option *ngFor="let country of countries" [value]="country">
                                                {{country | translatedescription}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xl-4">
                                <div class="c-select">
                                    <mat-form-field>
                                        <mat-label>{{'OnboardingFillCard.form.complianceprogramLabel.valor' |
                                            translate}}
                                        </mat-label>
                                        <mat-select formControlName="ComplianceProgram"
                                            [compareWith]="objectComparisonFunction" id="complianceprogram"
                                            placeholder="{{ 'OnboardingFillCard.form.complianceprogramPlaceholder.valor' | translate}}">
                                            <mat-option *ngFor="let cp of compliancePrograms" [value]="cp">
                                                {{cp.Description}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <!--3-->
                <article class="c-data">
                    <div formGroupName="Datos3">
                        <h1 class="c-data__title">{{'OnboardingFillCard.data3.valor'|translate}}</h1>
                        <div class="row">
                            <!--número de trabajadores-->
                            <div class="col-lg-4 col-xl-3">
                                <mat-form-field>
                                    <mat-label>{{'OnboardingFillCard.form.workers.valor'|translate}}</mat-label>
                                    <input type="number" class="c-form__input" matInput id="workers"
                                        formControlName="Workers"
                                        placeholder="{{ 'OnboardingFillCard.form.workers.valor'|translate}}">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-8 col-xl-9">
                                <div formGroupName="OrganGroup">
                                    <div class="row">
                                        <!--órgano ejecutivo-->
                                        <div class="col-lg-6 col-xl-3">
                                            <div class="c-select">
                                                <mat-form-field>
                                                    <mat-label>{{'OnboardingFillCard.form.organ.valor' | translate}}
                                                    </mat-label>
                                                    <mat-select formControlName="Organ"
                                                        [compareWith]="objectComparisonFunction" id="organ"
                                                        placeholder="{{ 'OnboardingFillCard.form.organ.valor' | translate}}">
                                                        <mat-option *ngFor="let organ of organs" [value]="organ">
                                                            {{organ | translatedescription}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <!--otro tipo-->
                                        <div class="col-lg-12 col-xl-6">
                                            <mat-form-field>
                                                <mat-label>{{'OnboardingFillCard.form.organother.valor'|translate}}
                                                </mat-label>
                                                <input type="text" class="c-form__input" matInput id="organother"
                                                    formControlName="OtherOrgan"
                                                    placeholder="{{ 'OnboardingFillCard.form.organother.valor'|translate}}">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="c-text-toggle u-mt-1half">
                            <div class="c-text-toggle__item">
                                <!--empresas subcontratadas-->
                                <div class="c-text-toggle__item-col">
                                    <p id="questionSubcontract" class="o-text c-text-toggle__text u-pl-0">
                                        {{'OnboardingFillCard.form.subcontracted.valor'|translate}}</p>
                                    <div class="c-slide-toggle" aria-labelledby="questionSubcontract">
                                        <p class="c-slide-toggle__text no" aria-labelledby="subcontractCompanies">
                                            {{'OnboardingFillCard.form.switch.no.valor'|translate}}</p>
                                        <app-slide-toggle formControlName="Subcontracted" id="subcontractCompanies"
                                            class="o-flex o-flex--center"></app-slide-toggle>
                                        <p class="c-slide-toggle__text yes" aria-labelledby="subcontractCompanies">
                                            {{'OnboardingFillCard.form.switch.yes.valor'|translate}}</p>
                                    </div>
                                </div>
                                <!--empresas participadas-->
                                <div class="c-text-toggle__item-col">
                                    <p id="questionInvestee" class="o-text c-text-toggle__text u-pl-0">
                                        {{'OnboardingFillCard.form.participated.valor'|translate}}</p>
                                    <div class="c-slide-toggle" aria-labelledby="questionInvestee">
                                        <p class="c-slide-toggle__text no" aria-labelledby="investeeCompanies">
                                            {{'OnboardingFillCard.form.switch.no.valor'|translate}}</p>
                                        <app-slide-toggle formControlName="Participated" id="investeeCompanies"
                                            class="o-flex o-flex--center"></app-slide-toggle>
                                        <p class="c-slide-toggle__text yes" aria-labelledby="investeeCompanies">
                                            {{'OnboardingFillCard.form.switch.yes.valor'|translate}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <!--4-->
                <article class="c-data">
                    <div formGroupName="Datos4">
                        <h1 class="c-data__title">{{'OnboardingFillCard.data4.valor'|translate}}</h1>
                        <div formArrayName="Sponsors">
                            <p id="sponsors" class="o-text o-text--bold u-pl-0 u-mb-1">
                                {{'OnboardingFillCard.form.sponsored.valor' | translate}}</p>
                            <div class="c-data__col-3">
                                <div *ngFor="let ds of Sponsors.controls; index as i" aria-labelledby="sponsors">
                                    <div class="c-checkbox">
                                        <mat-checkbox [formControlName]="i">{{this.sponsors[i] | translatedescription}}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <!--5-->
                <article class="c-data">
                    <div formGroupName="Datos5">
                        <h1 class="c-data__title">{{'OnboardingFillCard.data5.valor'|translate}}</h1>
                        <p id="anualStatements" class="o-text o-text--bold u-pl-0">
                            {{'OnboardingFillCard.form.accountIntro.valor'|translate}}</p>
                        <div *ngFor="let ay of AccountYears.controls; index as i" aria-labelledby="accountYear">
                            <div [formGroupName]="i">
                                <div class="o-flex o-flex--center">
                                    <mat-form-field>
                                        <mat-label>{{'OnboardingFillCard.form.accountYear.valor'|translate}}</mat-label>
                                        <input type="number" class="c-form__input" matInput id="accountyear"
                                            formControlName="Year"
                                            placeholder="{{ 'OnboardingFillCard.form.accountYear.valor'|translate}}" />
                                    </mat-form-field>
                                    <div class="c-extra-info-add no-delete-upload u-ml-2">
                                        <label for="anualCount" class="c-extra-info__title u-sr-only">información
                                            extra</label>
                                        <app-file-upload formControlName="Doc" [buttonText]="uploadButtonTextAccountDoc"
                                            [qid]="null" (fileSelected)="selectDocumentAccountYear($event,i)"
                                            id="anualCount" class="c-extra-info-add__file"></app-file-upload>
                                        <button type="button" class="c-file-upload__button c-file-upload__delete"
                                            (click)="deleteAccountYear(i)">
                                            <span class="c-file-upload__button-ico" aria-hidden="true">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.667 20"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    <path
                                                        d="M20.833,3.333H18.25A4.174,4.174,0,0,0,14.167,0H9.5A4.174,4.174,0,0,0,5.417,3.333H2.833A.833.833,0,0,0,2.833,5h.833V15.833A4.172,4.172,0,0,0,7.833,20h8A4.172,4.172,0,0,0,20,15.833V5h.833a.833.833,0,0,0,0-1.667ZM9.5,1.667h4.667a2.5,2.5,0,0,1,2.358,1.667H7.142A2.5,2.5,0,0,1,9.5,1.667Zm8.833,14.167a2.5,2.5,0,0,1-2.5,2.5h-8a2.5,2.5,0,0,1-2.5-2.5V5h13Z"
                                                        transform="translate(-2)" />
                                                    <path
                                                        d="M9.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,9.833,16.667Z"
                                                        transform="translate(-3.333-1.666)" />
                                                    <path
                                                        d="M13.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,13.833,16.667Z"
                                                        transform="translate(-4-1.666)" id="0" />
                                                    <use transform="translate(3.333)" xlink:href="#0" />
                                                </svg>
                                            </span>
                                            {{'OnboardingFillCard.remove'|translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" (click)="addAccountYear()"
                            class="c-button c-button--add u-align-left u-pl-0">
                            <span class="c-button--add__ico" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path data-name=" ↳Color" d="M14,8H8v6H6V8H0V6H6V0H8V6h6Z"
                                        transform="translate(5 5)" />
                                </svg>
                            </span>
                            {{'OnboardingFillCard.button.add.year.valor'|translate}}
                        </button>
                    </div>
                </article>
                <!--6-->
                <article class="c-data">
                    <div formGroupName="Datos6">
                        <h1 class="c-data__title">{{'OnboardingFillCard.data6.valor'|translate}}</h1>
                        <div class="c-text-toggle c-text-toggle--block u-mb-1">
                            <!--auditorías internas-->
                            <div class="c-text-toggle__item">
                                <p id="questionInternalAudit" class="o-text o-text--bold u-pl-0">
                                    {{'OnboardingFillCard.form.internalAudit.valor'|translate}}</p>
                                <div class="c-slide-toggle" aria-labelledby="questionInternalAudit">
                                    <p class="c-slide-toggle__text no" aria-labelledby="internalAudit">
                                        {{'OnboardingFillCard.form.switch.no.valor'|translate}}</p>
                                    <app-slide-toggle formControlName="InternalAudit" id="internalAudit"
                                        class="o-flex o-flex--center"></app-slide-toggle>
                                    <p class="c-slide-toggle__text yes" aria-labelledby="internalAudit">
                                        {{'OnboardingFillCard.form.switch.yes.valor'|translate}}</p>
                                </div>
                                <div class="c-extra-info-add no-full">
                                    <label for="internalAudits"
                                        class="c-extra-info__title u-sr-only">{{'OnboardingFillCard.info.extra.valor'|translate}}</label>
                                    <app-file-upload formControlName="InternalAuditDoc"
                                        [buttonText]="uploadButtonTextInternalAuditDoc" [qid]="null"
                                        (fileSelected)="selectInternalAuditDocument($event)" id="internalAudits"
                                        class="c-extra-info-add__file"></app-file-upload>
                                </div>
                            </div>
                        </div>
                        <div class="c-text-toggle c-text-toggle--block">
                            <!--auditorías externas-->
                            <div class="c-text-toggle__item">
                                <p id="questionExternalAudit" class="o-text o-text--bold u-pl-0">
                                    {{'OnboardingFillCard.form.externalAudit.valor'|translate}}</p>
                                <div class="c-slide-toggle" aria-labelledby="questionExternalAudit">
                                    <p class="c-slide-toggle__text no" aria-labelledby="externalAudit">
                                        {{'OnboardingFillCard.form.switch.no.valor'|translate}}</p>
                                    <app-slide-toggle formControlName="ExternalAudit" id="externalAudit"
                                        class="o-flex o-flex--center"></app-slide-toggle>
                                    <p class="c-slide-toggle__text yes" aria-labelledby="externalAudit">
                                        {{'OnboardingFillCard.form.switch.yes.valor'|translate}}</p>
                                </div>
                                <div class="c-extra-info-add no-full">
                                    <label for="externalAudits"
                                        class="c-extra-info__title u-sr-only">{{'OnboardingFillCard.info.extra.valor'|translate}}</label>
                                    <app-file-upload formControlName="ExternalAuditDoc"
                                        [buttonText]="uploadButtonTextExternalAuditDoc" [qid]="null"
                                        (fileSelected)="selectExternalAuditDocument($event)" id="externalAudits"
                                        class="c-extra-info-add__file"></app-file-upload>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <!--7-->
                <article class="c-data">
                    <div formGroupName="Datos7">
                        <h1 class="c-data__title">{{'OnboardingFillCard.data7.valor'|translate}}</h1>
                        <div formArrayName="Isos">
                            <p id="isos" class="o-text o-text--bold u-pl-0 u-mb-1">
                                {{'OnboardingFillCard.form.isosIntro.valor'|translate}}</p>
                            <!-- Group Isos: {{Isos.valid}} -->
                            <div class="c-data__col-3">
                                <div *ngFor="let iso of Isos.controls; index as i" aria-labelledby="isos">

                                    <div [formGroupName]="i">

                                        <div class="c-checkbox">
                                            <mat-checkbox formControlName="Iso">{{this.isos[i].Description}}
                                            </mat-checkbox>
                                        </div>
                                        <div class="c-extra-info-add no-full">

                                            <app-file-upload formControlName="IsoFile"
                                                [buttonText]="uploadButtonTextIsoDoc" [qid]="null"
                                                (fileSelected)="selectIsoDocument($event,i)"
                                                class="c-extra-info-add__file"></app-file-upload>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <!-- form -->
    </section>
</div>