<!--modal borrar 3pptype-->
<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'TablaThirdtypesDeleteDialog.title.valor' | translate}}</h1>
        <button mat-button (click)="onNoClick()" class="c-dialog__close" aria-label="Cerrar ventana modal">
          <span aria-hidden="true"> 
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                  <path d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243" transform="translate(-309.86-406.47)"/>
              </svg>
          </span>
        </button>
    </header>
    <div class="c-dialog__content small-padding u-pb-1">
        <h2 class="c-dialog__subtitle">{{'TablaThirdtypesDeleteDialog.warn1.valor' | translate}}</h2>
        <p class="o-text">{{'TablaThirdtypesDeleteDialog.warn2.valor' | translate}}</p>
        <div class="c-form">
            <p id="deleteUser" class="c-fields__legend u-mt-1medium">{{'TablaThirdtypesDeleteDialog.info.valor' | translate}}</p>
            <div class="u-mt-1" aria-labelledby="deleteUser">
                <div class="row">
                    <!--nombre-->
                    <div class="col-lg-6 col-xl-2 u-mb-2">
                        <div class="c-fields-item">
                            <p id="nameUser" class="c-fields-item__title">{{'TablaThirdtypesDeleteDialog.code.valor' | translate}}</p>
                            <p class="c-fields-item__text" aria-labelledby="nameUser">{{data.code}}</p>
                        </div>
                    </div>
                    <!--apellidos-->
                    <div class="col-lg-6 col-xl-2 u-mb-2">
                        <div class="c-fields-item">
                            <p id="surnameUser" class="c-fields-item__title">{{'TablaThirdtypesDeleteDialog.description.valor' | translate}}</p>
                            <p class="c-fields-item__text" aria-labelledby="surnameUser">{{data.description}}</p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <footer class="c-dialog__footer flex-end">
        <button type="submit" class="c-button c-button--remove u-align-right" [mat-dialog-close]="true">{{'TablaThirdtypesDeleteDialog.delete.valor' | translate}}</button>
    </footer>
</div>