import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryResult } from '@app/models/common/queryResult';
import { AMLUpdateCandidate } from '@app/modules/screening-hub/models/AMLUpdateCandidate';
import { ScreeningProvider } from '@app/modules/screening-hub/models/ScreeningProvider';
import { CreateResolutionComponent } from '@app/modules/screening-hub/resolutions/create-resolution/create-resolution.component';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-scoring-candidates-list-gesintel',
  templateUrl: './scoring-candidates-list-gesintel.component.html',
  styleUrls: ['./scoring-candidates-list-gesintel.component.scss'],
})
export class ScoringCandidatesListGesintelComponent {
  displayedColumns: string[] = [
    'personalData',
    'mandatoryLists',
    'kyc',
    'ubo',
    'matchStrength',
    'actions',
  ];
  queryResult: QueryResult<AMLUpdateCandidate> = { items: [], totalItems: 0 };

  thirdPartyId: number;
  candidates: AMLUpdateCandidate[] = [];
  name: string;
  searchType: number;
  translations: any;

  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private toaster: Toaster,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.candidates =
      this.router.getCurrentNavigation().extras.state.candidates;

    this.activatedRoute.params.subscribe((params) => {
      this.thirdPartyId = params.id;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.name = params.name;
      this.searchType = params.searchType;
    });

    this.translateService.get("ThirdPartyCandidateTable").subscribe(t => this.translations = t);

    this.queryResult.items = this.candidates.slice(
      (this.query.Page - 1) * this.query.PageSize,
      this.query.Page * this.query.PageSize
    );
    this.queryResult.totalItems = this.candidates.length;
  }

  getCandidates() {
    this.queryResult.items = this.candidates
      .sort((a, b) => this.sortingFunction(a, b))
      .slice(
        (this.query.Page - 1) * this.query.PageSize,
        this.query.Page * this.query.PageSize
      );
    this.queryResult.totalItems = this.candidates.length;
  }

  sortBy(columnName) {
    this.query.Page = 1;
    this.query.SortBy = columnName.active;
    this.query.IsSortAscending = columnName.direction === 'asc';
    this.getCandidates();
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.queryResult.items = this.candidates.slice(
      (this.query.Page - 1) * this.query.PageSize,
      this.query.Page * this.query.PageSize
    );
  }

  sortingFunction(a: AMLUpdateCandidate, b: AMLUpdateCandidate): number {
    if (a[this.query.SortBy] > b[this.query.SortBy]) {
      return this.query.IsSortAscending ? 1 : -1;
    }

    if (a[this.query.SortBy] < b[this.query.SortBy]) {
      return this.query.IsSortAscending ? -1 : 1;
    }

    return 0;
  }

  calculateProgressColor(candidate: AMLUpdateCandidate): string {
    if (candidate.matchStrength <= 30) {
      return 'red-progress';
    } else if (candidate.matchStrength < 70) {
      return 'orange-progress';
    } else {
      return 'green-progress';
    }
  }

  back() {
    if (this.thirdPartyId) {
      this.router.navigate(['scoring-candidates', this.thirdPartyId]);
    } else {
      this.router.navigate(['scoring-candidates']);
    }
  }

  viewDetails(rut: string) {
    if (!rut) return;

    if (this.thirdPartyId) {
      this.router.navigate(
        ['scoring-candidate-details-gesintel', rut, this.thirdPartyId],
        { state: { candidates: this.candidates } }
      );
    } else {
      this.router.navigate(
        ['scoring-candidate-details-gesintel', rut],
        { state: { candidates: this.candidates } }
      );
    }
  }

  createResolution() {
    const dialogRef = this.dialog.open(CreateResolutionComponent, {
      data: {
        name: this.name,
        software: ScreeningProvider.AMLUpdate,
        data: JSON.stringify(this.candidates),
        totalResults: this.candidates.length,
        searchType: this.searchType,
        thirdPartyId: this.thirdPartyId
      }
    });
    dialogRef.afterClosed().subscribe((r: boolean) => {
      if (r != null || r != undefined) {
        if (r) {
          this.toaster.open({ text: this.translations.resolutionCreated, duration: 6000, type: 'success' });
        } else {
          this.toaster.open({ text: this.translations.resolutionCreationError, duration: 6000, type: 'danger' });
        }
      }
    })
  }
}
