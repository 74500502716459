<div class="basic-data">
  <p id="keyData" class="basic-data__legend u-mt-1medium">{{ "watchlistBasicData.title" | translate }}</p>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="category" class="basic-data-item__title">
        {{ "watchlistBasicData.profileId" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdName">
        {{ watchlistInfo?.profileId || "-" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="institution" class="basic-data-item__title">
        {{ "watchlistBasicData.type" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdAlias">
        {{ watchlistInfo?.entityType || "-" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="role" class="basic-data-item__title">
        {{ "watchlistBasicData.classification" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdAlias">
        {{ watchlistInfo?.clasification || "-" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="startDate" class="basic-data-item__title">
        {{ "watchlistBasicData.updateDate" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdCategory">
        {{ watchlistInfo?.profileUpdateDate || "-"}}
      </p>
    </div>
  </div>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="category" class="basic-data-item__title">
        {{ "watchlistBasicData.gender" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdName">
        {{ watchlistInfo?.gender || "-" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="institution" class="basic-data-item__title">
        {{ "watchlistBasicData.deceased" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdAlias">
        {{ (watchlistInfo?.deseaced ?  "watchlistBasicData.yes" : "watchlistBasicData.no") | translate}}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="role" class="basic-data-item__title">
        {{ "watchlistBasicData.name" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdAlias">
        {{ watchlistInfo?.firstName || "-" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="startDate" class="basic-data-item__title">
        {{ "watchlistBasicData.middleName" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdCategory">
        {{ watchlistInfo?.middleName || "-"}}
      </p>
    </div>
  </div>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="category" class="basic-data-item__title">
        {{ "watchlistBasicData.lastname" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdName">
        {{ watchlistInfo?.lastName || "-" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="institution" class="basic-data-item__title">
        {{ "watchlistBasicData.originalScript" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdAlias">
        {{ watchlistInfo?.originalScriptName || "-" }}
      </p>
    </div>
  </div>
</div>
