import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DocumentContent } from "../pdf-template-generator/Models/documentContent";
import { templateGenerator } from "../pdf-template-generator/template-generator";
import { Font } from "../pdf-template-generator/Models/font";
import { CoverPage } from "../pdf-template-generator/Models/coverPage";
import { Image } from "../pdf-template-generator/Models/image";
import { Content } from "../pdf-template-generator/Models/content";
import { Text } from "../pdf-template-generator/Models/text";
import { ColorHex } from "../pdf-template-generator/Models/colorHex";
import { formatDate } from "@angular/common";
import { PDFDocument, rgb } from "pdf-lib";
import { UsersProxyClient } from "src/proxy/apiUser/users.proxy";
import { PageSizes } from 'pdf-lib'

@Injectable()
export class EquifaxWSScreeningReport {

  locale: string;

  constructor(
    private translate: TranslateService,
    private userClient: UsersProxyClient
    ) { }

  async getScreeningReport(base64: string, name: string, screeningDate: Date): Promise<string> {
    this.locale = this.translate.currentLang.split("-")[0];
    let translations = await this.translate.get("screeningReport").toPromise();

    const printDate = new Date();
    const fonts = await this.getReportFonts();
    const userDetails = await this.userClient.usersGetUserDataGet().toPromise();
    const coverPage = await this.getReportCoverPage(name, userDetails.Client.Name, screeningDate, printDate, translations);

    const docContent = <DocumentContent>{
      header: {},
      footer: {},
      sections: []
    }
    const pdfCover = await templateGenerator(fonts, docContent, coverPage);
    const pdfBody = await this.modifyReport(base64);
    return await this.mergePDF(pdfCover, pdfBody, userDetails.Client.Name, screeningDate);

  }

  async getReportFonts(): Promise<Font[]> {
    let fonts: Font[] = [];
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Medium.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Medium' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Regular.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Regular' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Bold.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Bold' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Poppins-SemiBold.ttf').then((res) => res.arrayBuffer()), name: 'Poppins-SemiBold' });

    return fonts;
  }

  async getReportCoverPage(thirdName: string, companyName: string, screeningDate: Date, printDate: Date, translations: any): Promise<CoverPage> {
    return <CoverPage>{
      content: [
        <Content>{ content: <Image>{ image: await fetch('/assets/report/background.png').then((res) => res.arrayBuffer()), height: 842, width: 595, customHeight: 1.73 }, posX: 0, posY: 0, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/Riskboard03.png').then((res) => res.arrayBuffer()), height: 398, width: 515 }, posX: 40, posY: 24, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/wave.png').then((res) => res.arrayBuffer()), height: 842, width: 595 }, posX: 0, posY: 419, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height: 42, width: 180 }, posX: 40, posY: 454, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/equifax-logo.png').then((res) => res.arrayBuffer()), height: 16, width: 133 }, posX: 40, posY: 759, isRelative: false },
        <Content>{ content: <Text>{ text: translations.cover.report, font: 'Roboto-Medium', fontSize: 24, fontColor: <ColorHex>{ hexCode: '666666' }, maxWidth: 515 }, posX: 40, posY: 536, isRelative: false },
        <Content>{ content: <Text>{ text: thirdName, font: 'Poppins-SemiBold', fontSize: 36, fontColor: <ColorHex>{ hexCode: '121212' }, maxWidth: 515, lineHeight: 40 }, posX: 40, posY: 570, isRelative: false },
        <Content>{
          content: <Text>{
            text: translations.cover.intro.replace('${thirdName}', thirdName), font: 'Roboto-Regular',
            fontSize: 10, fontColor: <ColorHex>{ hexCode: '666666' }, lineHeight: 16, maxWidth: 335
          }, posX: 40, posY: 660, isRelative: false
        },
        <Content>{ content: <Text>{ text: translations.screeningBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 660, isRelative: false },
        <Content>{ content: <Text>{ text: companyName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 670, isRelative: false },
        <Content>{ content: <Text>{ text: translations.screeningDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 690, isRelative: false },
        <Content>{ content: <Text>{ text: formatDate(screeningDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 700, isRelative: false },
        <Content>{ content: <Text>{ text: translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 720, isRelative: false },
        <Content>{ content: <Text>{ text: formatDate(printDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 730, isRelative: false },
      ]
    };
  }

  async modifyReport(base64: string): Promise<Uint8Array> {
    const pdfdoc_report = await PDFDocument.load(base64);
    const footerImageArray = await fetch('/assets/report/equifax-footer.png').then((res) => res.arrayBuffer());
    const footerImage = await pdfdoc_report.embedPng(footerImageArray);
    const imageDims = footerImage.scale(0.5);

    try {
      const numPages = pdfdoc_report.getPageCount();
      for (let i = 0; i < numPages; i++) {
        const page = pdfdoc_report.getPage(i);
        try {
          page.setSize(PageSizes.A4[0], PageSizes.A4[1]);
          if (i === 0) {
            page.drawRectangle({
              x: 300,
              y: page.getHeight() - 168,
              width: 250,
              height: 58,
              borderWidth: 0,
              color: rgb(1, 1, 1),
              opacity: 1,
            })
          }

        } catch (e) {
          console.log(e)
        }

        page.drawImage(footerImage, {
          x: 0,
          y: 0,
          width: imageDims.width,
          height: imageDims.height,
        });
      }

    } catch (e) {
      console.error(e)
    }
    return await pdfdoc_report.save()
  }

  async mergePDF(cover: Uint8Array, body: Uint8Array, companyName: string, screeningDate: Date): Promise<string> {
    const merged = await PDFDocument.create();
    merged.setTitle(`Riskallay-Report-screening-${companyName}_${formatDate(screeningDate,'ddMMyyyy - HHmm', this.locale)}.pdf`, { showInWindowTitleBar: true });
    const pdfCover = await PDFDocument.load(cover);
    const pdfBody = await PDFDocument.load(body);
    const [coverPage] = await merged.copyPages(pdfCover, [0])
    merged.addPage(coverPage);

    const numPages = pdfBody.getPageCount();
    for (let i = 0; i < numPages; i++) {
      const [page] = await merged.copyPages(pdfBody, [i]);
      merged.addPage(page);
    }

    return merged.saveAsBase64()
  }
}
