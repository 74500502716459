<div class="start-screening-container">
  <div class="start-screening-item-image">
    <img src="assets/landing-scoring-tools.png" alt="" loading="lazy" />
  </div>
  <div class="start-screening-item-info">
    <p>
      <strong>
        <span style="font-size: x-large">
          {{ "InfoThird.startScreeningInfo.valor" | translate }}
        </span>
      </strong>
    </p>
  </div>
  <div *appAuthRole="['Owner', 'Manager', 'Assistant', 'Collaborator']" class="start-screening-item-info">
    <a [routerLink]="" (click)="onNewScoring()" routerLinkActive="is-active"
      class="c-button c-button--large c-button--gradient">
      {{ "InfoThird.startScreeningButton.valor" | translate }}
    </a>
  </div>
</div>
