import { CustomFieldOption } from "@app/models/CustomFieldOption";
import { CustomFieldType } from "@app/models/CustomFieldType";

export function mapOptionsStringToMaxOptionId(optionsInput: string): number {
    const parsedOptions = JSON.parse(optionsInput);
    return parsedOptions?.maxOptionsId || 0;
}

export function mapOptionsStringToCustomFieldOptions(optionsInput: string): CustomFieldOption[] {
    let optionsData = JSON.parse(optionsInput);
    return optionsData?.customFieldOptions as CustomFieldOption[] || [];
}

export function mapCustomFieldOptionsToOptionsString(
    customFieldOptions: CustomFieldOption[],
    customFieldType: CustomFieldType,
    maxOptionsId: number
): string {
    if (!customFieldOptions || !customFieldType.hasOptions || customFieldOptions.length === 0) {
        return null;
    }

    const customFieldOptionsObj = {
        maxOptionsId,
        customFieldOptions,
    };

    return JSON.stringify(customFieldOptionsObj);
}
