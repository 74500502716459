import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { Location } from '@angular/common';
import { EquifaxWSScoringService } from '@app/modules/screening-hub/services/equifax-ws-scoring.service';
import { EquifaxWSRecord } from '@app/modules/screening-hub/models/EquifaxWS/EquifaxWSRecord';
import { DomSanitizer } from '@angular/platform-browser';
import { EquifaxWSScreeningReport } from '@app/services/reports/equifax-ws-screening-report.service';

@Component({
  selector: 'equifax-ws-screening-details-gesintel',
  templateUrl: './screening-details.component.html',
  styleUrls: ['./screening-details.component.scss'],
})
export class EquifaxWSScreeninDetailsComponent implements OnInit {
  thirdPartyId: number;
  screeningId: number;

  scoringDetails: EquifaxWSRecord;

  generatingReportMessage: string;
  reportGeneratedMessage: string;
  report: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private equifaxWSScoringService: EquifaxWSScoringService,
    private location: Location,
    private translate: TranslateService,
    private toaster: Toaster,
    private sanitizer: DomSanitizer,
    private reportService: EquifaxWSScreeningReport
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      (this.thirdPartyId = params.id), (this.screeningId = params.screeningId);
    });

    this.equifaxWSScoringService
      .screeningDetails(this.screeningId)
      .subscribe(async res => {
        this.scoringDetails = res;
        const base64 = await this.reportService.getScreeningReport(res.report, res.name, res.creationDate);
        this.report = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(base64) as any).changingThisBreaksApplicationSecurity;
        top.document.getElementById('ifrm').setAttribute("src", this.report);
      });

    this.translate
      .get('ScreeningDetails.generatingReport')
      .subscribe((translation) => {
        this.generatingReportMessage = translation;
      });

    this.translate
      .get('ScreeningDetails.reportGenerated')
      .subscribe((translation) => {
        this.reportGeneratedMessage = translation;
      });
  }

  back() {
    this.location.back();
  }

  async downloadReport() {
    this.toaster.open({
      text: this.generatingReportMessage,
      duration: 4000,
      type: 'info',
    });

    // let a: [string, Uint8Array];
    // if (this.thirdPartyId) {
    //   a = await this.tpScreeningReportService.getScreeningReport(
    //     this.thirdPartyId,
    //     this.screeningId
    //   );
    // } else {
    //   a = await this.screeningReportService.getScreeningReport(
    //     this.screeningId
    //   );
    // }

    this.toaster.open({
      text: this.reportGeneratedMessage,
      duration: 4000,
      type: 'success',
    });
    //download(a[1], a[0], 'application/pdf');
  }
}
