import { AfterContentInit, Component, Input } from "@angular/core";
import { QueryResult } from "@app/models/common/queryResult";
import { AMLUpdateCandidate } from "../../models/AMLUpdateCandidate";

@Component({
  selector: 'aml-update-result-list',
  templateUrl: './aml-update-result-list.component.html',
  styleUrls: ['./aml-update-result-list.component.scss']
})
export class AMLUpdateResultListComponent implements AfterContentInit {
  @Input() data: string;

  displayedColumns: string[] = [
    'personalData',
    'mandatoryLists',
    'kyc',
    'ubo',
    'matchStrength'
  ];
  queryResult: QueryResult<AMLUpdateCandidate> = { items: [], totalItems: 0 };
  scoringCandidates: AMLUpdateCandidate[] = [];

  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor() {

  }

  ngAfterContentInit(): void {
    this.scoringCandidates = JSON.parse(this.data);
    this.getCandidates();
  }

  getCandidates() {
    this.queryResult.items = this.scoringCandidates.sort((a, b) => this.sortingFunction(a, b)).slice((this.query.Page - 1) * this.query.PageSize, this.query.Page * this.query.PageSize);
    this.queryResult.totalItems = this.scoringCandidates.length;
  }

  sortBy(columnName) {
    this.query.Page = 1;
    this.query.SortBy = columnName.active;
    this.query.IsSortAscending = columnName.direction === 'asc'
    this.getCandidates();
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.queryResult.items = this.scoringCandidates.slice((this.query.Page - 1) * this.query.PageSize, this.query.Page * this.query.PageSize);
  }

  sortingFunction(a: AMLUpdateCandidate, b: AMLUpdateCandidate): number {
    if (a[this.query.SortBy] > b[this.query.SortBy]) {
      return this.query.IsSortAscending ? 1 : -1;
    }

    if (a[this.query.SortBy] < b[this.query.SortBy]) {
      return this.query.IsSortAscending ? -1 : 1;
    }

    return 0;
  }

  calculateProgressColor(candidate: AMLUpdateCandidate): string {
    if (candidate.matchStrength <= 30) {
      return 'red-progress';
    } else if (candidate.matchStrength < 70) {
      return 'orange-progress';
    } else {
      return 'green-progress';
    }
  }

}
