import { Component, OnInit, Input, AfterViewInit, } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ThirdPartiesDataSource } from '@app/utilities/third-parties-data-source';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { tap } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { merge } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { ThirdParty } from '@app/models/ThirdParty';
import { MatDialog } from '@angular/material/dialog';
import { DialogBulkStatusChangeComponent } from '../dialog-bulk-status-change/dialog-bulk-status-change.component';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { InherentRiskEnum, InherentRiskEnumValues, mapInherentRiskToTranslationKey } from '@app/models/InherentRiskEnum';
import { ThirdPartyStatus, ThirdPartyStatusValues, mapTPStatusToTranslationKey } from '@app/models/ThirdPartyStatus';
import { AuthenticationService } from '@app/services/authentication.service';

@Component({
  selector: 'app-third-parties-list-table',
  templateUrl: './third-parties-list-table.component.html',
  styleUrls: ['./third-parties-list-table.component.scss']
})
export class ThirdPartiesListTableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() thirdPartyTypeId: number = null;

  displayedColumns = ['select', 'Name', 'Country', 'Sector', 'Status', 'InherentRisk', 'Responsible', 'Action'];

  dataSource: ThirdPartiesDataSource;

  selection = new SelectionModel<ThirdParty>(true, []);

  searchWord: string;
  inherentRiskFilterList: number[] = [];
  statusFilterList: number[] = [];
  initialPageSize = 10;

  statusList: ThirdPartyStatus[];
  inherentRiskList: InherentRiskEnum[] = InherentRiskEnumValues;

  statusChangedMsg: string;
  errorDownloadingMsg: string;

  constructor(
    private thirdPartiesService: ThirdPartiesService,
    public dialog: MatDialog,
    public toaster: Toaster,
    private translate: TranslateService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.statusList = ThirdPartyStatusValues;
    this.translate.get('Tabla3PP.errorDownloading').subscribe(translation => {
      this.errorDownloadingMsg = translation;
    });

    this.translate.get('Tabla3PP.statusChanged').subscribe(translation => {
      this.statusChangedMsg = translation;
    });

    this.dataSource = new ThirdPartiesDataSource(this.thirdPartiesService);
    this.dataSource.connectionError$.subscribe(error => {
      if (error) {
        this.toaster.open({ text: this.errorDownloadingMsg, duration: 6000, type: 'danger' });
      }
    });
    this.dataSource.loadThirdParties('', 'Id', false, 1, this.thirdPartyTypeId, this.initialPageSize, this.statusFilterList, this.inherentRiskFilterList);
    this.selection.isSelected = this.isChecked.bind(this);

    if(this.authService.getFieldJWT("CanSendToBC") === "1") {
      this.displayedColumns = ['select', 'Name', 'Country', 'Sector', 'Status', 'InherentRisk', 'Responsible', 'Sync', 'Action'];
    }
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
    });

    merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadThirdPartiesPage())
    ).subscribe();
  }

  loadThirdPartiesPage(): void {
    this.dataSource
      .loadThirdParties(
        this.searchWord,
        this.sort.direction !== '' ? this.sort.active : 'Id',
        this.sort.direction === 'asc',
        this.paginator.pageIndex + 1,
        this.thirdPartyTypeId,
        this.paginator.pageSize,
        this.statusFilterList,
        this.inherentRiskFilterList);
  }

  changeStates(): void {
    const dialogRef = this.dialog.open(DialogBulkStatusChangeComponent, { data: this.selection.selected });

    dialogRef.afterClosed()
      .subscribe(updated => {
        if (updated) {
          this.toaster.open({ text: this.statusChangedMsg, duration: 6000, type: 'info' });
          this.selection.clear();
          this.loadThirdPartiesPage();
        }
      });
  }

  onThirdPartyToggled(row: ThirdParty): void {
    const thirdParty = this.selection.selected.find(tp => tp.id === row.id) || row;
    this.selection.toggle(thirdParty);
  }

  isChecked(row: ThirdParty): boolean {
    return this.selection.selected.some(tp => tp.id === row.id);
  }

  updateSearchWord(value: string): void {
    this.searchWord = value;
    this.paginator.pageIndex = 0;
    this.loadThirdPartiesPage();
  }

  getInherentRiskText(inherentRisk: InherentRiskEnum): string {
    return mapInherentRiskToTranslationKey(inherentRisk);
  }

  getStatusName(status: ThirdPartyStatus): string {
    return mapTPStatusToTranslationKey(status);
  }

  statusChange(event) {
    this.statusFilterList = event.value;
    this.paginator.pageIndex = 0;
    this.loadThirdPartiesPage();
  }

  inherentRiskChange(event) {
    this.inherentRiskFilterList = event.value;
    this.paginator.pageIndex = 0;
    this.loadThirdPartiesPage();
  }
}
