import { Component, Input, OnInit } from "@angular/core";
import { mapPositionToTranslationKey, Position } from "@app/models/Position";
import { RiskallayUser } from "@app/models/RiskallayUser";
import { RemoteService } from "@app/services/remote.service";
import { UsersService } from "@app/services/users.service";

@Component({
  selector: 'app-responsible-user',
  templateUrl: './responsible-user.component.html',
  styleUrls: ['./responsible-user.component.scss']
})
export class ResponsibleUserComponent implements OnInit {

  @Input() responsibleUserId: string;

  user: RiskallayUser;
  imageUrl: string = null;

  constructor(private userService: UsersService,
    private remoteService: RemoteService) { }

  ngOnInit(): void {
    this.userService.getUserDetails(this.responsibleUserId).subscribe(r => this.user = r);
  }

  getResponsibleAvatar(): string {

    if (this.imageUrl == null) {
      this.imageUrl = '';
      this.remoteService.getTokenFileUri(this.user.profilePhotoFileUri).subscribe(res => this.imageUrl = res.url);
    }

    return this.imageUrl;
  }

  getPositionTranslationKey(position: Position): string {
    return mapPositionToTranslationKey(position);
  }

  getCircleLetter(user: RiskallayUser): string {
    let letter = user?.name?.slice(0,1);

    if(letter === null || letter === undefined || letter === '') {
      return user?.surName?.slice(0,1);
    }

    return letter;
  }
}
