import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '@app/services/account.service';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-dialog-update-password',
  templateUrl: './dialog-update-password.component.html',
  styles: [
  ]
})
export class DialogUpdatePasswordComponent {
  oldPassword: string;
  newPassword: string;
  repeatedNewPassword: string;
  
  typeOldPassword = false;
  isOldShow = false;
  typeNewPassword = false;
  isNewShow = false;
  typeRepeatedPassword = false;
  isRepeatedShow = false;

  updateError: string;
  updateSuccess: string;

  constructor(
    public dialogRef: MatDialogRef<DialogUpdatePasswordComponent>,
    private accountService: AccountService,
    private translate: TranslateService,
    private toast: Toaster,
  ) { 
      this.translate.get('UpdatePasswordDialog.updateError')
        .subscribe(translation => {
            this.updateError = translation;
        });

      this.translate.get('UpdatePasswordDialog.updateSuccess')
        .subscribe(translation => {
            this.updateSuccess = translation;
        });
  }

  public get isFormValid(): boolean {
    return this.oldPassword && this.newPassword && this.newPassword === this.repeatedNewPassword;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async updatePassword(): Promise<void> {
    if (!this.isFormValid) {
      return;
    }

    this.accountService
      .updatePassword(this.oldPassword, this.newPassword)
      .subscribe(response => {
        if (!response) {
          this.toast.open({ text: this.updateError, duration: 6000, type: 'danger' })
        } else {
          this.toast.open({ text: this.updateSuccess, duration: 3000 })
          this.dialogRef.close();
        }
      });
  }

  toggleOldPassword(): void {
    this.typeOldPassword = !this.typeOldPassword;
    this.isOldShow = !this.isOldShow;
  };

  toggleNewPassword(): void {
    this.typeNewPassword = !this.typeNewPassword;
    this.isNewShow = !this.isNewShow;
  };

  toggleRepeatedPassword(): void {
    this.typeRepeatedPassword = !this.typeRepeatedPassword;
    this.isRepeatedShow = !this.isRepeatedShow;
  };
}
