<section class="c-table">
  <div class="c-table__top">
    <div class="row">
      <!--buscador y filtros-->
      <div class="col-8">
        <app-text-table-filter (filter)="onFilter($event)"></app-text-table-filter>
      </div>
    </div>
  </div>
  <div class="o-scroll">
    <div class="c-table__bottom">
      <div *ngIf="this.filteredAssessments == null || isLoading">
        <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
      </div>
      <table mat-table [dataSource]="this.filteredAssessments" class="mat-elevation-z8" matSort
        (matSortChange)="sortBy($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef style="width: 28%;"> {{'PageId.table.name.valor' | translate}} </th>
          <td mat-cell style="width: 28%;" *matCellDef="let element">
            <div class="table-cell">
              <span class="assesment-name">{{element.name}}</span>
              <span class="company-name">{{element.client.name}}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="assessed">
          <th mat-header-cell *matHeaderCellDef style="width: 28%;"> {{'PageId.table.assessed' | translate}} </th>
          <td mat-cell style="width: 28%;" *matCellDef="let element">
            <div class="table-cell">
              <span class="assesment-name">{{element.thirdParties[0].CompanyName}}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="dates">
          <th mat-header-cell *matHeaderCellDef style="width: 14%;"> {{'PageId.table.dates.valor' | translate}} </th>
          <td mat-cell style="width: 14%;" *matCellDef="let element">
            <div class="table-cell">
              <span class="start-date">{{element.startDate | date:'dd/MM/yyyy - HH:mm'}}</span>
              <span [ngClass]="{'expiring': checkIfExpiring(element.endDate)}" class="end-date">{{element.endDate |
                date:'dd/MM/yyyy - HH:mm'}}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="width: 25%;"> {{'PageId.table.status.valor' | translate}} </th>
          <td mat-cell style="width: 25%;" *matCellDef="let element">
            <div class="c-progress-bar" style="width: 90%; float: left;">
              <mat-progress-bar aria-labelledby="progressBar" class="c-progress-bar__bar" [color]="color"
                [value]="element.thirdParties[0].progress">
              </mat-progress-bar>
              <p id="progressBar" class="c-progress-bar__percent">{{element.thirdParties[0].progress}}%</p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width: 15%;"> {{'PageId.table.actions.valor' | translate}} </th>
          <td mat-cell *matCellDef="let element" style="width: 15%;">
            <app-risklist-action [element]="element"></app-risklist-action>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="this.tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: this.tableColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">
            {{'TablaCampaña.noresults.valor' | translate}}
          </td>
        </tr>
      </table>
      <mat-paginator #paginator (page)="onPageChange($event)" [pageSize]="pageSize" [length]="totalAssessments"
        [pageSizeOptions]="[5, 10, 25, 100]" class="c-pagination">
      </mat-paginator>
    </div>
  </div>
</section>
