import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RemoteService } from '../../../services/remote.service';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Questionary, QuestionaryDTO } from '../../../models/Questionary';
import { TemplateBlockerService } from '../../../services/template-blocker.service';
import { MatDialog } from '@angular/material/dialog';
import { QuestionariesService } from '../../../services/questionaries-service';
import { DialogDoubleTemplateComponent } from '../dialog-double-template/dialog-double-template.component'
import { Role } from '@app/models/Role';
import { canAccess } from '@app/security/riskallay.roles';
import { AuthenticationService } from '@app/services/authentication.service';
import { QuestionaryService } from '@app/services/questionary.service';
import { QuestionaryFormService } from './questionary-form.service';
import { Observable, of } from 'rxjs';
import { DialogConfirmExitComponent } from '@app/modules/myrisks/dialog-confirm-exit/dialog-confirm-exit.component';
import { ConfirmDeleteTemplateComponent } from '../confirm-delete-template/confirm-delete-template.component';
import { RiskService } from '@app/services/risk.service';
import { Risk } from '@app/models/Risk';

@Component({
  selector: 'app-template-detail-copy-master',
  templateUrl: './template-detail-copy-master.component.html',
  styleUrls: ['./template-detail-copy-master.component.scss']
})
export class TemplateDetailCopyMasterComponent implements OnInit, OnDestroy, AfterViewChecked {

  questionary: Questionary;
  questionaryId: number;

  form: FormGroup;

  firstUpdateBlock: boolean = true;
  isMaster: boolean = false;
  canEdit = false;
  translations: any;

  formHadIncorrectWeights = false;
  weightsErrorMsg: string;

  risks: Risk[];

  isLoading = true;

  constructor(
    private remoteService: RemoteService,
    private toaster: Toaster,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private blocker: TemplateBlockerService,
    public router: Router,
    private questionariesService: QuestionariesService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private questionaryService: QuestionaryService,
    private questionaryFormService: QuestionaryFormService,
    private riskService: RiskService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.translate.get('ListadoPreguntas.weights.weightErrorMsg')
      .subscribe(translation => {
        this.weightsErrorMsg = translation;
      });

    this.riskService.getRisks().subscribe(risks => {
      this.risks = risks;
      this.activatedRoute.params.subscribe(routeParams => {
        this.questionaryId = +routeParams.qid;
        this.questionaryService.getQuestionaryById(this.questionaryId)
          .subscribe(questionary => {
            this.questionary = questionary;
            this.questionary.updateTime = new Date(this.questionary.updateTime);
            this.questionary.creationTime = new Date(this.questionary.creationTime);

            this.loadQuestionary();
          });
      });
    });
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  async loadQuestionary(): Promise<void> {
    const userRoles = this.authenticationService.getRoles();
    const isChecklistInUse = await this.questionaryService.isQuestionaryInUse(this.questionary.id).toPromise();
    this.canEdit = canAccess([Role.Manager, Role.Owner], userRoles) && !isChecklistInUse;

    this.translate.get('ListadoPreguntas').subscribe(t => this.translations = t);

    if (this.questionary.isMaster || !this.canEdit) {
      this.isMaster = true;
      this.buildFormulario(this.questionary, true);
    } else {
      await this.bloquearPlantilla(this.questionaryId);

      this.blocker.notifier.subscribe((data) => {
        if (this.firstUpdateBlock) {
          this.firstUpdateBlock = false;
        }
        else {
          this.blocker.updateTemplateLock(this.questionaryId)
            .then(
              r => {
                if (r === null) {

                } else {
                  this.toaster.open({ text: this.translations.failedToUpdateChecklistBlock, duration: 4000, type: 'danger' });
                }

              }
            )
            .catch(err => {
              this.toaster.open({ text: this.translations.failedToUpdateChecklistBlock, duration: 4000, type: 'danger' });
            }
            );
        }
      });

      this.buildFormulario(this.questionary, false);
    }
  }

  ngOnDestroy(): void {
    this.unblockTemplate();
  }

  unblockTemplate(): void {
    if (this.questionary && !this.questionary.isMaster) {
      this.blocker.unblockTemplate(this.questionaryId);
    }
  }

  buildFormulario(data: Questionary, disabledValue: boolean) {
    this.form = this.questionaryFormService.build(data, disabledValue, this.risks);
    this.form.valueChanges.subscribe(_ => {
      if (!this.formHadIncorrectWeights && this.form.errors && this.form.errors['incorrectWeights']) {
        this.toaster.open({ text: this.weightsErrorMsg, duration: 4000, type: 'danger' });
      }
      this.formHadIncorrectWeights = this.form.errors && !!this.form.errors['incorrectWeights'];
    });
    this.isLoading = false;
  }

  async bloquearPlantilla(id) {
    let lock = await this.blocker.getTemplateLock(id);
    if (lock === undefined) {
      this.toaster.open({ text: this.translations.errorCheckingBlock, duration: 4000, type: 'danger' });
      this.router.navigate(['/plantillas']);
      return;
    } else if (lock != null) {

      if (lock.UserOwner.Email.toLowerCase() != localStorage.getItem('currentUser')) {

        let fileDate = new Date((<string>lock.UpdateTime).substring(0, 19)).getTime();
        let nowDate = new Date(new Date().toISOString().substring(0, 19)).getTime();
        if (nowDate - fileDate < 1 * 60 * 1000) {
          this.toaster.open({ text: this.translations.fileBlockedBy.replace('${userEmail}', lock.UserOwner.Email), duration: 4000, type: 'danger' });
          this.router.navigate(['/plantillas']);
          return;
        }

        let updatelock = await this.blocker.updateTemplateLock(id);
        if (updatelock === null) {
          return
        }
        else {
          this.toaster.open({ text: this.translations.errorBlockingTemplate, duration: 4000, type: 'danger' });
          this.router.navigate(['/plantillas']);
        }
      } else {
        return
      }
    }

    this.blocker.addTemplateLock(this.questionaryId)
      .catch(_ => {
        this.toaster.open({ text: this.translations.errorBlockingTemplate, duration: 4000, type: 'danger' });
        this.router.navigate(['/plantillas']);
      });
  }

  async buildOutput() {
    let lock = await this.blocker.getTemplateLock(this.questionaryId);

    if (lock === undefined) {
      this.toaster.open({ text: this.translations.errorCheckingBlock, duration: 4000, type: 'danger' });
      return;
    } else if (lock != null) {
      if (lock.UserOwner.Email.toLowerCase() != localStorage.getItem('currentUser')) {
        this.toaster.open({ text: this.translations.fileBlockedBy.replace('${userEmail}', lock.UserOwner.Email), duration: 4000, type: 'success' });
        return;
      }
    }

    const salida = this.questionaryFormService.outputQuestionaryFromForm();

    this.questionaryService.putQuestionaryById(this.questionaryId, salida)
      .subscribe(
        async r => {
          this.toaster.open({ text: this.translations.updatedSuccessfully, duration: 4000, type: 'success' });
          this.form.markAsPristine();
        },
        err => {
          this.toaster.open({ text: this.translations.errorUpdating, duration: 8000, type: 'danger' });
          this.router.navigate(['/plantillas']);
        });
  }

  duplicarPlantilla() {
    this.openDialogUpdate({ data: this.questionary });
  }

  openDialogUpdate(data): void {
    const dialogRef = this.dialog.open(DialogDoubleTemplateComponent, data);

    dialogRef.afterClosed().subscribe(async result => {
      if (result === undefined) {
        return
      }
      this.isLoading = true;
      this.questionariesService.duplicateQuestionary(data.data.id)
        .then(r => {
          this.unblockTemplate();
          this.firstUpdateBlock = true;
          let q = (<QuestionaryDTO>r);
          this.router.navigate(['/detalle-plantilla', q.Id]);
        })
        .catch(
          err => {
            this.toaster.open({ text: this.translations.errorDuplicating, duration: 4000, type: 'danger' });
          }
        )
    })

  }

  openDeleteDialog($event) {
    const dialogRef = this.dialog.open(ConfirmDeleteTemplateComponent, { data: this.questionary });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.questionariesService.deleteQuestionary(this.questionary.id).subscribe(r => {
          if (r) {
            this.toaster.open({ text: this.translations.deleteSuccess, duration: 4000, type: 'success' });
            this.router.navigate(['/plantillas']);
          } else {
            this.toaster.open({ text: this.translations.deleteError, duration: 4000, type: 'danger' });
          }

        })
      }
    })
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (option && value) {
      return option.id === value.id;
    }
    else {
      return false;
    }
  }

  confirmExitingIfDirty(): Observable<boolean> {
    if (this.form.dirty) {
      const dialogRef = this.dialog.open(DialogConfirmExitComponent);
      return dialogRef.afterClosed();
    } else {
      return of(true);
    }
  }
}
