import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { QueryResult } from '@app/models/common/queryResult';
import { Scoring } from '@app/models/scoring-tools/scoring';
import { Screening } from '@app/models/scoring-tools/screening';
import { AMLUpdateLink } from '@app/modules/screening-hub/models/AMLUpdateLink';
import { TranslateService } from '@ngx-translate/core';
import download from 'downloadjs';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'aml-update-screening-list',
  templateUrl: './screening-list.component.html',
  styleUrls: ['./screening-list.component.scss'],
})
export class AMLUpdateScreeningListComponent implements OnChanges {
  @Input() screenings: Screening[];
  @Input() thirdPartyId?: number;
  @Input() scoring: AMLUpdateLink | Scoring;

  displayedColumns: string[] = [
    'matchedAlias',
    'worldCheckId',
    'screeningDate',
    'createdBy',
    'actions',
  ];
  generatingReportMessage: string;
  reportGeneratedMessage: string;

  queryResult: QueryResult<Screening> = { items: [], totalItems: 0 };
  query = {
    PageSize: 5,
    SortBy: 'worldCheckId',
    IsSortAscending: false,
    Page: 1,
  };

  constructor(
    private translate: TranslateService,
    private router: Router,
    private toaster: Toaster
  ) {
    this.translate
      .get('ThirdPartyScreeningTable.generatingReport')
      .subscribe((translation) => {
        this.generatingReportMessage = translation;
      });

    this.translate
      .get('ThirdPartyScreeningTable.reportGenerated')
      .subscribe((translation) => {
        this.reportGeneratedMessage = translation;
      });
  }

  ngOnChanges(): void {
    this.getScreenings();
  }

  sortingFunction(a: Screening, b: Screening): number {
    if (a[this.query.SortBy] > b[this.query.SortBy]) {
      return this.query.IsSortAscending ? 1 : -1;
    }

    if (a[this.query.SortBy] < b[this.query.SortBy]) {
      return this.query.IsSortAscending ? -1 : 1;
    }

    return 0;
  }

  getScreenings() {
    this.queryResult.items = this.screenings
      .sort((a, b) => this.sortingFunction(a, b))
      .slice(
        (this.query.Page - 1) * this.query.PageSize,
        this.query.Page * this.query.PageSize
      );
    this.queryResult.totalItems = this.screenings.length;
  }

  onFilterChange(event: Event) {
    this.query.Page = 1;
    this.getScreenings();
  }

  resetFilter() {
    this.query = {
      PageSize: 5,
      SortBy: '',
      IsSortAscending: false,
      Page: 1,
    };

    this.getScreenings();
  }

  sortBy(columnName) {
    this.query.Page = 1;
    this.query.SortBy = columnName.active;
    this.query.IsSortAscending = columnName.direction === 'asc';
    this.getScreenings();
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.getScreenings();
  }

  viewDetails(screeningId: number) {
    if (this.thirdPartyId) {
      this.router.navigate(['amlupdate/screening-details', screeningId, this.thirdPartyId]);
    } else {
      this.router.navigate(['amlupdate/screening-details', screeningId]);
    }
  }

  async downloadReport(screeningId: number) {
    this.toaster.open({
      text: this.generatingReportMessage,
      duration: 4000,
      type: 'info',
    });
    // let a: [string, Uint8Array];
    // if (this.thirdPartyId) {
    //   a = await this.tpScreeningReportService.getScreeningReport(
    //     this.thirdPartyId,
    //     screeningId
    //   );
    // } else {
    //   a = await this.screeningReportService.getScreeningReport(screeningId);
    // }

    this.toaster.open({
      text: this.reportGeneratedMessage,
      duration: 4000,
      type: 'success',
    });
    // download(a[1], a[0], 'application/pdf');
  }
}
