<div class="o-wrapper o-wrapper--no-padding">
    <main id="content">
        <section class="c-risklist">
            <!--encabezado de sección-->
            <div class="c-head-section">
                <div class="c-head-section__left">
                    <h2 class="o-title o-title--small u-pl-0">{{'OnboardingFillCard.title1.valor' | translate }}</h2>
                    <h3 class="o-title o-title--thin u-pl-0">{{'OnboardingFillCard.title2.valor' | translate }}</h3>
                    <p class="o-text u-pl-0">{{'OnboardingFillCard.title3.valor' | translate }}</p>
                </div>
            </div>
        </section>
        <hr class="c-risklist__separator" />
        <app-onboarding-card [showProgress]="false"></app-onboarding-card>
    </main>
</div>