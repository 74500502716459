import { Screening } from "@app/models/scoring-tools/screening";
import { mapUser } from "@app/services/mappings/user.mappings";
import { MemberCheckScreeningDto } from "src/proxy/model/memberCheckScreeningDto";

export function mapMemberCheckScreening(dto: MemberCheckScreeningDto): Screening {

  return <Screening>{
    createdBy: mapUser(dto.User),
    id: dto.Id,
    screeningDate: dto.ScreeningDate,
    entityType: dto.EntityType,
  }
}
