import { Component, AfterContentInit, ElementRef, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonsService } from '../../../services/commons-service';
import { Toaster } from 'ngx-toast-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DialogAddUserComponent } from '../dialog-add-user/dialog-add-user.component';
import { DialogDeleteUserComponent } from '../dialog-delete-user/dialog-delete-user.component';
import { Role } from '@app/models/Role';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { UsersService } from '@app/services/users.service';
import { RiskallayUserDto } from 'src/proxy/model/riskallayUserDto';
import { mapPositionToTranslationKey, Position } from '@app/models/Position';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styles: []
})
export class UsersTableComponent implements OnInit, AfterContentInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("input", { static: true }) input: ElementRef;
  data: RiskallayUserDto[];

  dataSource: any;
  displayedColumns: string[] = ['Name', 'Surname', 'Email', 'Position', 'Rols', 'Actions'];
  actions: string[] = ['delete', 'update'];
  ready: boolean = false;
  cuota: any;
  errorMessage: string;
  doneMessage: string;
  currentUser: RiskallayUserDto;

  constructor(
    private commons: CommonsService,
    private usersService: UsersService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private toaster: Toaster,
  ) { }

  ngOnInit(): void {
    this.translate.get('TablaUsers.error')
      .subscribe(translation => {
        this.errorMessage = translation;
      });
    this.translate.get('TablaUsers.done')
      .subscribe(translation => {
        this.doneMessage = translation;
      });
  }

  ngAfterContentInit(): void {
    this.getData();
  }

  updateTable() {
    this.dataSource = new MatTableDataSource(this.data);
    this.overrideFilter();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.ready = true;
    this.getCuota();
  }

  getData(): void {
    forkJoin({
      users: this.usersService.getAllClientUsers(),
      currentUser: this.usersService.getCurrentUser()
    }).subscribe(({ users, currentUser }) => {
      this.data = users;
      this.currentUser = currentUser;
      this.updateTable();
    });
  }

  openDialogAdd(): void {
    const dialogRef = this.dialog.open(DialogAddUserComponent, {});

    dialogRef.afterClosed().subscribe(async result => {
      if (result === undefined) {
        return
      }
      if (result) {
        this.getData();
      } else {
        this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
      }
    })
  }

  delete(element: RiskallayUserDto) {
    const dialogRef = this.dialog.open(
      DialogDeleteUserComponent,
      {
        data: element
      }
    )

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        // No action
      } else if (!result) {
        // No use.
      } else {
        // Delete
        this.usersService.deleteUser(element.Id)
          .subscribe(r => {
            if(r){
              this.toaster.open({ text: this.doneMessage, duration: 4000, type: 'success' });
            } else {
              this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
            }
            this.getData();
          },
          err => {
            this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
          });
      }

    })
  }

  update(element: RiskallayUserDto) {
    this.openDialogUpdate(element);
  }

  openDialogUpdate(data: RiskallayUserDto): void {
    const dialogRef = this.dialog.open(DialogAddUserComponent, { data });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === undefined) {
        return
      }
      if (result) {
        this.getData();
      } else {
        this.toaster.open({ text: this.errorMessage, duration: 4000, type: 'danger' });
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim();
    this.dataSource.filter = filterValue;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  overrideFilter() {
    this.dataSource.filterPredicate = function (data: RiskallayUserDto, filter: string): boolean {
      return String(data.Name).toLowerCase().includes(filter.toLowerCase())
        || String(data.SurName).toLowerCase().includes(filter.toLowerCase())
        || String(data.Email).toLowerCase().includes(filter.toLowerCase())
        || String(data.Position).toLowerCase().includes(filter.toLowerCase())
        || String(data.Roles).toLowerCase().includes(filter.toLowerCase())
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getCuota() {
    this.commons.getClientHaveFreeLicense()
      .then(r => {
        this.cuota = Boolean(r);
      })
      .catch(err => {
        this.cuota = false;
      })
  }

  getUserTypeRole(element: RiskallayUserDto): string {

    let role = element?.Roles?.filter(r => r == Role.Owner ||
      r == Role.Manager ||
      r == Role.Collaborator ||
      r == Role.Assistant ||
      r == Role.Auditor) ?? [];

    return role.length > 0 ? role[0] : '';
  }

  userCanModify(userToModify: RiskallayUserDto) {
    return this.getUserTypeRole(this.currentUser) !== Role.Manager || this.getUserTypeRole(userToModify) !== Role.Owner;
  }

  getPositionTranslationKey(position: Position) {
    return mapPositionToTranslationKey(position);
  }
}
