import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { EquifaxWSScoringService } from '@app/modules/screening-hub/services/equifax-ws-scoring.service';
import { EquifaxWSRecord } from '@app/modules/screening-hub/models/EquifaxWS/EquifaxWSRecord';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'equifax-ws-scoring-candidate-details',
  templateUrl: './scoring-candidate-details.component.html',
  styleUrls: ['./scoring-candidate-details.component.scss'],
})
export class EquifaxWSScoringCandidateDetailsComponent implements OnInit {
  thirdPartyId: number;
  cedula: string;
  result: string;
  searchType: number;

  scoringDetails: EquifaxWSRecord;
  returnTabIndex: number = 3;
  report: string;

  private screeningSucceededMessage: string;
  private screeningFailedMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private equifaxWSScoringService: EquifaxWSScoringService,
    private router: Router,
    private translate: TranslateService,
    private toaster: Toaster,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.translate
      .get('ScoringDetails.screeningSucceeded')
      .subscribe((translation) => {
        this.screeningSucceededMessage = translation;
      });

    this.translate
      .get('ScoringDetails.screeningFailed')
      .subscribe((translation) => {
        this.screeningFailedMessage = translation;
      });

    this.activatedRoute.params.subscribe((params) => {
      this.thirdPartyId = params.id;
      this.cedula = params.cedula;
      this.searchType = params.searchType;

      this.equifaxWSScoringService
        .candidateDetails(this.cedula, this.searchType)
        .subscribe((record) => {
          this.scoringDetails = record;
          this.report = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(record.report) as any).changingThisBreaksApplicationSecurity;
          top.document.getElementById('ifrm').setAttribute("src", this.report);
        });
    });
  }

  save(): void {
    this.equifaxWSScoringService
      .saveScreening(this.cedula, this.scoringDetails.name, this.searchType, this.thirdPartyId)
      .subscribe(
        async (res) => {
          if (res) {
            this.toaster.open({
              text: this.screeningSucceededMessage,
              duration: 6000,
              type: 'success',
            });
            if (this.thirdPartyId) {
              this.router.navigate([
                'perfil-tercero',
                this.thirdPartyId,
                this.returnTabIndex,
              ]);
            } else {
              this.router.navigate(['screening-tools']);
            }
          } else {
            this.toaster.open({
              text: this.screeningFailedMessage,
              duration: 6000,
              type: 'danger',
            });
          }
        },
        (err) =>
          this.toaster.open({
            text: this.screeningFailedMessage,
            duration: 6000,
            type: 'danger',
          })
      );
  }

  back() {
    if (this.thirdPartyId) {
      this.router.navigate(
        ['equifaxws/scoring-candidates-results', this.thirdPartyId],
        {
          state: { result: 'OK' },
          queryParams: { cedula: this.cedula, searchType: this.searchType }
        }
      );
    } else {
      this.router.navigate(
        ['equifaxws/scoring-candidates-results'],
        {
          state: { result: 'OK' },
          queryParams: { cedula: this.cedula, searchType: this.searchType }
        }
      );
    }
  }
}
