import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Language, mapLanguageToTranslationKey } from '@app/models/Language';
import { CountryDTO } from '@app/models/Country';
import { SectorDTO } from '@app/models/Sector';
import { ThirdPartyDTO, ThirdPartyCreateDTO, ThirdPartyUpdateDTO } from '@app/models/ThirdParty';
import { CommonsService } from '@app/services/commons-service';
import { ThirdPartyTypeDTO } from '@app/models/ThirdPartyType';
import { ResponsiblesDTO } from '@app/models/Responsibles';
import { NatureOfActivityDTO } from '@app/models/NatureOfActivity';
import { TypeOfActivityDTO } from '@app/models/TypeOfActivity';
import { GlobalService } from "../../../services/global.service";
import { RemoteService } from "../../../services/remote.service";
import { ContactCreateDTO, ContactUpdateDTO } from '@app/models/Contact';
import { RiskallayUserCreateDTO, RiskallayUserUpdateDTO } from '@app/models/RiskallayUser';
import { ThirdPartyConfigCreateDTO, ThirdPartyConfigUpdateDTO } from '@app/models/ThirdPartyConfig';
import { MatDialog } from '@angular/material/dialog';
import { Dialog3ppExistsComponent } from '@app/modules/thirdparties/dialog3pp-exists/dialog3pp-exists.component';
import { Dialog3ppIsLinkedComponent } from '@app/modules/thirdparties/dialog3pp-is-linked/dialog3pp-is-linked.component';
import { Dialog3ppExistsInBc } from '@app/modules/thirdparties/dialog-3pp-exists-in-bc/dialog-3pp-exists-in-bc.component';
import { Router } from '@angular/router';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { Toaster } from 'ngx-toast-notifications';
import { parsearErroresAPI } from '@app/utilities/utilities';
import { DialogDeleteThirdpartyComponent } from '../dialog-delete-thirdparty/dialog-delete-thirdparty.component';
import { canAccess } from '@app/security/riskallay.roles';
import { Role } from '@app/models/Role';
import { AuthenticationService } from '@app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ThirdPartyStatus } from '@app/models/ThirdPartyStatus';
import { CustomFieldsService } from '@app/services/custom-fields.service';
import { CustomField } from '@app/models/CustomField';
import { InherentRiskEnum, InherentRiskEnumValues } from '@app/models/InherentRiskEnum';
import { mapInherentRiskToTranslationKey } from '@app/models/InherentRiskEnum';
import { BusinessCentralService } from '@app/services/business-central.service';

@Component({
  selector: 'app-formulario-third',
  templateUrl: './formulario-third.component.html',
  styleUrls: ['./formulario-third.component.scss']
})
export class FormularioThirdComponent implements OnInit {

  translations: any;
  pendingStatus: string;
  inProgressStatus: string;
  approvedStatus: string;
  rejectedStatus: string;

  constructor(
    private formBuilder: FormBuilder,
    public commonsService: CommonsService,
    private globalService: GlobalService,
    private remoteService: RemoteService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    public router: Router,
    private thirdPartiesService: ThirdPartiesService,
    private toaster: Toaster,
    private translate: TranslateService,
    private customFieldsService: CustomFieldsService,
    private businessCentralService: BusinessCentralService,
    private authService: AuthenticationService
  ) {
    this.translate.get('ThirdPartyStatus.pending').subscribe(translation => {
      this.pendingStatus = translation;
    });

    this.translate.get('ThirdPartyStatus.inProgress').subscribe(translation => {
      this.inProgressStatus = translation;
    });

    this.translate.get('ThirdPartyStatus.approved').subscribe(translation => {
      this.approvedStatus = translation;
    });

    this.translate.get('ThirdPartyStatus.rejected').subscribe(translation => {
      this.rejectedStatus = translation;
    });
  }

  form: FormGroup;
  @Output() isFormValidEmitter = new EventEmitter<boolean>();

  @Input()
  errores: string[] = [];

  @Input()
  model: ThirdPartyDTO;

  @Input()
  isSaeta: boolean = false;

  @Input()
  sectorsNoSelected: SectorDTO[] = [];

  @Input()
  countriesNoSelected: CountryDTO[] = [];

  @Input()
  languagesNoSelected: Language[] = [];

  @Input()
  thirdPartyTypeNoSelected: ThirdPartyTypeDTO[] = [];

  @Input()
  responsiblesNoSelected: ResponsiblesDTO[] = [];

  @Input()
  natureOfActivityNoSelected: NatureOfActivityDTO[] = [];

  @Input()
  typeOfActivityNoSelected: TypeOfActivityDTO[] = [];

  @Output()
  onSubmit: EventEmitter<ThirdPartyCreateDTO> = new EventEmitter<ThirdPartyCreateDTO>();

  isSubmitted = false;
  avisado: boolean = false;
  id3pp: any;
  contactEmailExist: false;
  canEdit = false;
  possibleStatus = [];
  inherentRisks: InherentRiskEnum[] = [];

  get customFieldsControl(): FormArray {
    return this.form.get('CustomFields') as FormArray;
  }

  ngOnInit(): void {
    this.isSaeta = this.authenticationService.isSaeta();
    this.inherentRisks = InherentRiskEnumValues;
    this.customFieldsService
      .getCustomFields()
      .subscribe((customFields: CustomField[]) => {
        this.populateFormWithCustomFields(customFields);
      });

    const userRoles = this.authenticationService.getRoles();
    this.canEdit = canAccess([Role.Manager, Role.Owner, Role.Collaborator, Role.Assistant], userRoles);

    this.translate.get('FormularioThird').subscribe(t => this.translations = t);

    this.form = this.formBuilder.group({
      CompanyName: new FormControl({ value: '', disabled: !this.canEdit }, Validators.required),
      Alias: new FormControl({ value: '', disabled: !this.canEdit }),
      Website: new FormControl({ value: '', disabled: !this.canEdit }),
      Fax: new FormControl({ value: '', disabled: !this.canEdit }),
      ContactName: new FormControl({ value: '', disabled: !this.canEdit }),
      ContactPhoneNumber: new FormControl({ value: '', disabled: !this.canEdit }),
      ContactSurnames: new FormControl({ value: '', disabled: !this.canEdit }),
      ContactPosition: new FormControl({ value: '', disabled: !this.canEdit }),
      ContactEmail: new FormControl({ value: '', disabled: !this.canEdit }, [Validators.required, Validators.email]),
      ContactLanguage: new FormControl({ value: null, disabled: !this.canEdit }),
      CompanyCP: new FormControl({ value: '', disabled: !this.canEdit }),
      Sector: new FormControl({ value: null, disabled: !this.canEdit }),
      Country: new FormControl({ value: null, disabled: !this.canEdit }, Validators.required),
      ThirdPartyType: new FormControl({ value: null, disabled: !this.canEdit }),
      CompanyAddress: new FormControl({ value: '', disabled: !this.canEdit }),
      CompanyCIF: new FormControl({ value: '', disabled: !this.canEdit }),
      UserResponsible: new FormControl({ value: null, disabled: !this.canEdit }, Validators.required),
      NatureOfActivity: new FormControl({ value: null, disabled: !this.canEdit }),
      TypeOfActivity: new FormControl({ value: null, disabled: !this.canEdit }),
      ThirdProvince: new FormControl({ value: null, disabled: !this.canEdit }, []),
      ThirdPartyStatusSelect: new FormControl({ value: null, disabled: !canAccess([Role.Manager, Role.Owner], userRoles) }),
      ThirdPartyInherentRiskSelect: new FormControl({ value: InherentRiskEnum.notSpecified, disabled: !this.canEdit }),
      CustomFields: this.formBuilder.array([]),
    });

    this.form.get('ContactName').disable();
    this.form.get('ContactSurnames').disable();
    this.form.get('ContactPosition').disable();

    if (this.authService.getFieldJWT("BlockInherentRisk") == "1") {
      this.form.get('ThirdPartyInherentRiskSelect').disable();
    }

    if (this.model !== undefined) {

      if (this.model.Contact.Name !== null || !this.canEdit) {
        this.form.get('ContactLanguage').disable();
      }
    }

    if (this.model !== undefined) {
      this.activateEditMode();
      this.form.get('CompanyAddress').patchValue(this.model.ThirdPartyConfig.CompanyAddress);
      this.form.get('CompanyCIF').patchValue(this.model.CompanyCIF);
      this.form.get('CompanyCP').patchValue(this.model.ThirdPartyConfig.CompanyCP);
      this.form.get('CompanyName').patchValue(this.model.CompanyName);
      this.form.get('Alias').patchValue(this.model.ThirdPartyConfig.Alias);
      this.form.get('Website').patchValue(this.model.ThirdPartyConfig.Website);
      this.form.get('Fax').patchValue(this.model.ThirdPartyConfig.Fax);

      this.form.get('Country').patchValue(this.model.Country);
      this.form.get('NatureOfActivity').patchValue(this.model.ThirdPartyConfig.NatureOfActivity);
      this.form.get('Sector').patchValue(this.model.ThirdPartyConfig.Sector);

      this.form.get('ThirdPartyType').patchValue(this.model.ThirdPartyConfig.ThirdPartyType);
      this.form.get('TypeOfActivity').patchValue(this.model.ThirdPartyConfig.TypeOfActivity);
      this.form.get('ThirdProvince').patchValue(this.model.ThirdPartyConfig.CompanyProvince);
      this.form.get('UserResponsible').patchValue(this.model.UserOwner);

      this.form.get('ContactName').patchValue(this.model.Contact.Name);
      this.form.get('ContactSurnames').patchValue(this.model.Contact.Surnames);
      this.form.get('ContactEmail').patchValue(this.model.Contact.Email);
      this.form.get('ContactPosition').patchValue(this.model.Contact.Position);

      this.form.get('ContactLanguage').patchValue(this.model.Contact.Language);
      this.form.get('ContactPhoneNumber').patchValue(this.model.Contact.PhoneNumber);
      this.form.get('ThirdPartyInherentRiskSelect').patchValue(this.model.ThirdPartyConfig.InherentRisk);

      if (this.model.ThirdPartyConfig.ThirdPartyStatus == ThirdPartyStatus.pending) {
        this.possibleStatus = [{ code: ThirdPartyStatus.pending, description: this.pendingStatus, image: '/assets/thirdPartyPending.svg' }];
        this.form.get('ThirdPartyStatusSelect').disable();
      } else {
        this.possibleStatus = [{ code: ThirdPartyStatus.inProgress, description: this.inProgressStatus, image: '/assets/thirdPartyInProgress.svg' },
        { code: ThirdPartyStatus.approved, description: this.approvedStatus, image: '/assets/thirdPartyApproved.svg' },
        { code: ThirdPartyStatus.rejected, description: this.rejectedStatus, image: '/assets/thirdPartyRejected.svg' }];
      }

      this.form.get('ThirdPartyStatusSelect').patchValue(this.model.ThirdPartyConfig.ThirdPartyStatus);
    }

    if (this.model === undefined) {
      this.possibleStatus = [{ code: ThirdPartyStatus.pending, description: this.pendingStatus }];
      this.form.get('ThirdPartyStatusSelect').patchValue(ThirdPartyStatus.pending);
      this.form.get('ThirdPartyStatusSelect').disable();
    }

    this.onChanges();
    this.form.get('Country').valueChanges.subscribe(value => {
      this.form.get('Country').setValue(value, { onlySelf: true, emitEvent: false, emitModelToViewChange: true });
    }, error => { }, () => { });

    this.form.valueChanges.subscribe(_ => {
      this.isFormValidEmitter.emit(this.form.valid);
    });
  }

  onChanges() {
    this.form.get('CompanyCIF').valueChanges.subscribe(r => {
      this.verifica3PP();
    });

    this.form.get('Country').valueChanges.subscribe(r => {
      this.verifica3PP();
    });
  }

  async openDialog3ppIsLinked(third) {
    const dialogRef = this.dialog.open(Dialog3ppIsLinkedComponent, { data: third });

    return await dialogRef.afterClosed().toPromise();
  }

  async verifica3PP() {
    let country = this.form.get('Country').value;
    let nif = String(this.form.get('CompanyCIF').value);
    let countryId = (country !== null && country.Id) ? country.Id : -1;

    if (countryId != -1 && nif != null && nif.length > 0) {
      await this.get3PPalready(countryId, nif)
        .then(async r => {
          if (r != null) {
            this.id3pp = r.Id;
            let data = {
              'country': r.Country.Description,
              'cif': r.CompanyCIF,
              'name': r.CompanyName,
            }
            await this.openDialog3ppIsLinked(data)
              .then(r => {
                if (r) {
                  this.router.navigate(['/perfil-tercero', this.id3pp]);
                }
                this.avisado = true;
              });
          }
        })

      await this.get3PP(countryId, nif)
        .then(r => {
          if (r != null) {
            if (!this.avisado) {
              this.form.get('CompanyName').setValue(r.CompanyName);
              this.form.get('ThirdPartyType').setValue(r.ThirdPartyType);
              this.form.get('NatureOfActivity').setValue(r.NatureOfActivity);
              this.form.get('TypeOfActivity').setValue(r.TypeOfActivity);
            }
            this.deshabilita();
          }
          else {
            this.habilita();
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  }

  async get3PPalready(idCountry, companyCif) {
    return await this.remoteService.getRequest("ThirdParties/CheckThirdPartyExistByClient/" + idCountry + "/" + companyCif)
  }

  async get3PP(idCountry, companyCif) {
    return await this.remoteService.getRequest("ThirdParties/CheckThirdPartyExist/" + idCountry + "/" + companyCif)
  }

  habilita() {
    this.form.get('ThirdPartyType').enable();
    this.form.get('NatureOfActivity').enable();
    this.form.get('TypeOfActivity').enable();
  }

  deshabilita() {
    let data = {
      'country': this.form.get('Country').value.Description,
      'cif': this.form.get('CompanyCIF').value,
      'name': this.form.get('CompanyName').value,
    }

    if (!this.avisado) {
      this.avisado = false;
      this.form.get('CompanyName').disable();
      this.openDialog3ppExists(data)
    }
  }

  activateEditMode() {
    this.form.get('CompanyName').disable({ emitEvent: false });
  }

  get mailContactExist() {
    if (this.form.get('ContactEmail').touched && this.form.get('ContactEmail').valid) {
      return this.contactEmailExist;
    }
    return false;

  }

  get mailContactNoValid() {
    return this.form.get('ContactEmail').touched && this.form.get('ContactEmail').invalid;
  }

  get Sector() {
    return this.form.get('Sector');
  }

  get Country() {
    return this.form.get('Country');
  }

  get ThirdPartyType() {
    return this.form.get('ThirdPartyType');
  }

  get UserResponsible() {
    return this.form.get('UserResponsible');
  }

  get ContactLanguage() {
    return this.form.get('ContactLanguage');
  }

  get TypeOfActivity() {
    return this.form.get('TypeOfActivity');
  }

  get NatureOfActivity() {
    return this.form.get('NatureOfActivity');
  }

  get ThirdPartyStatusSelect() {
    return this.form.get('ThirdPartyStatusSelect');
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (option && value) {
      return option.Id === value.Id;
    }
    else {
      return false;
    }
  }

  saveChanges() {
    this.isSubmitted = true;
    if (this.form.invalid || this.contactEmailExist) {
      return Object.values(this.form.controls).forEach(control => {
        control.markAsTouched();
      });
    }

    this.onSubmit.emit(this.buildSalida());
  }

  back() {
    this.globalService.closeThird();
  }

  buildSalida() {
    let salida;
    let contacto;
    let owner;
    let config;
    if (this.model !== undefined) {
      salida = (<ThirdPartyUpdateDTO>{});
      contacto = (<ContactUpdateDTO>{});
      owner = (<RiskallayUserUpdateDTO>{});
      config = (<ThirdPartyConfigUpdateDTO>{});

      salida.Id = this.model.Id;
      contacto.Id = this.model.Contact.Id;
      owner.Id = this.model.UserOwner.Id;
      config.Id = this.model.ThirdPartyConfig.Id;


    } else {
      salida = (<ThirdPartyCreateDTO>{});
      contacto = (<ContactCreateDTO>{});
      owner = (<RiskallayUserCreateDTO>{});
      config = (<ThirdPartyConfigCreateDTO>{});
    }

    salida.CompanyCIF = this.form.get('CompanyCIF').value;
    salida.CompanyName = this.form.get('CompanyName').value;
    salida.Country = this.form.get('Country').value;

    owner = this.form.get('UserResponsible').value;
    salida.UserOwner = owner;

    config.CompanyAddress = this.form.get('CompanyAddress').value;
    config.CompanyCP = this.form.get('CompanyCP').value;
    config.CompanyProvince = this.form.get('ThirdProvince').value;
    config.Alias = this.form.get('Alias').value;
    config.Website = this.form.get('Website').value;
    config.Fax = this.form.get('Fax').value;
    config.Sector = this.form.get('Sector').value;
    config.ThirdPartyStatus = this.form.get('ThirdPartyStatusSelect').value;
    config.ThirdPartyType = this.form.get('ThirdPartyType').value;
    config.TypeOfActivity = this.form.get('TypeOfActivity').value;
    config.NatureOfActivity = this.form.get('NatureOfActivity').value;
    config.InherentRisk = this.form.get('ThirdPartyInherentRiskSelect').value;

    const customFieldFormValues = this.form.get('CustomFields').value;
    const customFieldValues = customFieldFormValues?.map((cf: any) => ({ id: cf.id, value: cf.userValue })) || [];
    config.CustomFields = JSON.stringify(customFieldValues);

    salida.ThirdPartyConfig = config;

    contacto.Email = this.form.get('ContactEmail').value;
    contacto.Language = this.form.get('ContactLanguage').value;
    contacto.Name = this.form.get('ContactName').value;
    contacto.PhoneNumber = this.form.get('ContactPhoneNumber').value;
    contacto.Position = this.form.get('ContactPosition').value;
    contacto.Surnames = this.form.get('ContactSurnames').value;
    salida.Contact = contacto;

    return salida;
  }

  openDialog3ppExists(third) {
    const dialogRef = this.dialog.open(
      Dialog3ppExistsComponent,
      {
        data: third
      }
    )

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        this.form.get('Country').setValue(null);
        this.form.get('CompanyCIF').setValue(null);
        this.form.get('CompanyName').setValue(null);
        this.habilita();
      }
    })
  }

  openDialogDeleteThirparty() {
    const dialogRef = this.dialog.open(
      DialogDeleteThirdpartyComponent,
      {
        data: {
          Name: this.model.CompanyName,
          Country: this.model.Country,
          Type: this.model.ThirdPartyConfig.ThirdPartyType,
          Sector: this.model.ThirdPartyConfig.Sector
        }
      }
    )

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.thirdPartiesService.deleteThirdParty(this.model.Id.toString()).subscribe(
          response => {
            this.router.navigate(['/busqueda']);
            this.toaster.open({ text: this.translations.deletedSuccessfully, duration: 6000, type: 'info' });
          },
          errores => {
            this.errores = parsearErroresAPI(errores);
            this.toaster.open({ text: this.translations.errorUpdating, duration: 6000, type: 'danger' });
          });
      }
    })
  }

  populateFormWithCustomFields(customFields: CustomField[]): void {
    const customFieldsString = this.model?.ThirdPartyConfig?.CustomFields;
    let customFieldsValues = [];
    if (customFieldsString) {
      // TODO try catch here?
      customFieldsValues = JSON.parse(customFieldsString);
    }

    for (const customField of customFields) {
      const customFieldGroup = new FormGroup({
        id: new FormControl(customField.id),
        description: new FormControl(customField.description),
        descriptionEN: new FormControl(customField.descriptionEN),
        descriptionPT: new FormControl(customField.descriptionPT),
        descriptionFR: new FormControl(customField.descriptionFR),
        hasTranslations: new FormControl(customField.hasTranslations),
        type: new FormControl(customField.type),
        options: new FormControl(customField.options),
        mandatory: new FormControl(customField.mandatory),
        userValue: new FormControl(customFieldsValues?.find(c => c.id === customField.id)?.value || '', customField.mandatory ? Validators.required : null),
      });

      if (!this.canEdit) {
        customFieldGroup.disable()
      }

      this.customFieldsControl.push(customFieldGroup);
    }
  }

  public objectComparisonByIdFunction = function (optionId: number, value: number): boolean {
    if (optionId && value) {
      return optionId === value;
    }
    else {
      return false;
    }
  }

  public thirdPartyWasSaved(success: boolean): void {
    if (success) {
      this.form.markAsPristine();
    } else {
      this.form.markAsDirty();
    }
  }

  getLanguageTranslationKey(language: Language): string {
    return mapLanguageToTranslationKey(language);
  }

  getInherentRiskName(inherentRisk: InherentRiskEnum): string {
    return mapInherentRiskToTranslationKey(inherentRisk);
  }

  shareTPWithBC() {
    const existingRecordErrorValue = "The record in table Third party master already exists."
    const companyCifValue = this.form.get("CompanyCIF").value;
    this.businessCentralService.sendDataToBC(this.model.Id).subscribe(r => {

      if (r.includes(existingRecordErrorValue) && r.includes(companyCifValue)) {
        this.openDialogThirpartyExistsInBc();
        return;
      }
      switch (r) {
        case "OK":
          this.toaster.open({ text: this.translations.businessCentral.success, duration: 3000, type: 'success' });
          break;
        case "SAME_USER_ERROR":
          this.toaster.open({ text: this.translations.businessCentral.sameUser, duration: 30000, type: 'danger' });
          break;
        case "DATA_SYNCED":
          this.toaster.open({ text: this.translations.businessCentral.dataSynced, duration: 3000, type: 'danger' });
          break;
        case "NOT_APPROVED_ERROR":
          this.toaster.open({ text: this.translations.businessCentral.mustBeApproved, duration: 3000, type: 'danger' });
          break;
        default:
          this.toaster.open({ text: r, duration: 30000, type: 'danger' });
          break;
      }
    });
  }

  showBusinessCentral() {
    return this.authService.getFieldJWT("CanSendToBC") === "1";
  }

  openDialogThirpartyExistsInBc() {
    const dialogRef = this.dialog.open(Dialog3ppExistsInBc);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const thirdPartyId = this.model.Id;

        this.businessCentralService.syncToExistingBC(thirdPartyId).subscribe(response => {
          switch (response) {
            case "OK":
              this.toaster.open({ text: this.translations.businessCentral.success, duration: 3000, type: 'success' });
              break;
            case "SAME_USER_ERROR":
              this.toaster.open({ text: this.translations.businessCentral.sameUser, duration: 30000, type: 'danger' });
              break;
            case "DATA_SYNCED":
              this.toaster.open({ text: this.translations.businessCentral.dataSynced, duration: 3000, type: 'danger' });
              break;
            case "NOT_APPROVED_ERROR":
              this.toaster.open({ text: this.translations.businessCentral.mustBeApproved, duration: 3000, type: 'danger' });
              break;
            default:
              this.toaster.open({ text: response, duration: 30000, type: 'danger' });
              break;
          }
        })
      }
    })
  }
}
