import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryResult } from '@app/models/common/queryResult';
import { ScreeningProvider } from '@app/modules/screening-hub/models/ScreeningProvider';
import { MemberCheckScanResult } from '@app/modules/screening-hub/models/member-check/member-check-scan-result';
import { CreateResolutionComponent } from '@app/modules/screening-hub/resolutions/create-resolution/create-resolution.component';
import { MemberCheckScoringService } from '@app/modules/screening-hub/services/member-check-scoring.service';
import { UsersService } from '@app/services/users.service';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { RiskallayUserDto } from 'src/proxy/model/riskallayUserDto';

@Component({
  selector: 'member-check-scoring-candidate',
  templateUrl: './member-check-scoring-candidate.component.html',
  styleUrls: ['./member-check-scoring-candidate.component.scss'],
})
export class MemberCheckCandidatesComponent implements OnInit {

  displayedColumns: string[] = [
    'matchedAlias',
    'matchStrength',
    'location'
  ];

  queryResult: QueryResult<MemberCheckScanResult> = { items: [], totalItems: 0 };

  thirdPartyId: number;
  scanId: number;
  scoringCandidates: MemberCheckScanResult[] = [];
  name: string;
  searchType: number;
  translations: any;

  currentUser: RiskallayUserDto;

  query = {
    PageSize: 5,
    SortBy: '',
    IsSortAscending: false,
    Page: 1,
  };

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private toaster: Toaster,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private scoringToolsService: MemberCheckScoringService,
    private userClient: UsersService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.thirdPartyId = params.id,
        this.scanId = params.scanId
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.name = params.name;
      this.searchType = params.searchType;
      if (this.searchType == 1) {
        this.displayedColumns.push('dob');
      }

      this.displayedColumns.push('actions');
    });

    this.translateService.get("ThirdPartyCandidateTable").subscribe(t => this.translations = t);
  }

  ngOnInit() {
    this.userClient.getCurrentUser()
      .subscribe(userData => this.currentUser = userData);

    if (this.searchType == 1) {
      this.getMemberScanResults();
    } else {
      this.getCorpScanResults();
    }

  }

  getMemberScanResults() {
    this.scoringToolsService.scanMemberCandidates(this.scanId).subscribe((res) => {
      this.scoringCandidates = res
        .sort((a, b) => this.sortingFunction(a, b));
      this.queryResult.items = res.slice(
        (this.query.Page - 1) * this.query.PageSize,
        this.query.Page * this.query.PageSize
      );
      this.queryResult.totalItems = this.scoringCandidates.length;
    });
  }

  getCorpScanResults() {
    this.scoringToolsService.scanCorpCandidates(this.scanId).subscribe((res) => {
      this.scoringCandidates = res
        .sort((a, b) => this.sortingFunction(a, b));
      this.queryResult.items = res.slice(
        (this.query.Page - 1) * this.query.PageSize,
        this.query.Page * this.query.PageSize
      );
      this.queryResult.totalItems = this.scoringCandidates.length;
    });
  }

  getCandidates() {
    this.queryResult.items = this.scoringCandidates
      .sort((a, b) => this.sortingFunction(a, b))
      .slice(
        (this.query.Page - 1) * this.query.PageSize,
        this.query.Page * this.query.PageSize
      );
    this.queryResult.totalItems = this.scoringCandidates.length;
  }

  sortBy(columnName) {
    this.query.Page = 1;
    this.query.SortBy = columnName.active;
    this.query.IsSortAscending = columnName.direction === 'asc';
    this.getCandidates();
  }

  onPageChange(page) {
    this.query.Page = page.pageIndex + 1;
    this.query.PageSize = page.pageSize;
    this.queryResult.items = this.scoringCandidates.slice(
      (this.query.Page - 1) * this.query.PageSize,
      this.query.Page * this.query.PageSize
    );
  }

  sortingFunction(a: MemberCheckScanResult, b: MemberCheckScanResult): number {
    if (a[this.query.SortBy] > b[this.query.SortBy]) {
      return this.query.IsSortAscending ? 1 : -1;
    }

    if (a[this.query.SortBy] < b[this.query.SortBy]) {
      return this.query.IsSortAscending ? -1 : 1;
    }

    return 0;
  }

  getRisks(risks: string[]): string[] {
    return risks.reduce(function (dr: string[], a) {
      const index = dr.findIndex((r) => r == a);
      if (index === -1) {
        dr.push(a);
      }
      return dr;
    }, []);
  }

  back() {
    if (this.thirdPartyId) {
      this.router.navigate(['scoring-candidates', this.thirdPartyId]);
    } else {
      this.router.navigate(['scoring-candidates']);
    }
  }

  viewDetails(scanResult: MemberCheckScanResult) {

    if (this.thirdPartyId) {
      this.router.navigate([
        'member-check-candidate-details',
        this.scanId,
        this.thirdPartyId,
      ],{ state: { scanResult, currentUser: this.currentUser, currentDate: new Date() } });
    } else {
      this.router.navigate([
        'member-check-candidate-details',
        this.scanId,
      ],{ state: { scanResult, currentUser: this.currentUser, currentDate: new Date() } });
    }
  }

  createResolution() {
    const dialogRef = this.dialog.open(CreateResolutionComponent, {
      data: {
        name: this.name,
        software: ScreeningProvider.MemberCheck,
        data: JSON.stringify(this.scoringCandidates),
        totalResults: this.scoringCandidates.length,
        searchType: this.searchType,
        thirdPartyId: this.thirdPartyId
      }
    });
    dialogRef.afterClosed().subscribe((r: boolean) => {
      if (r != null || r != undefined) {
        if (r) {
          this.toaster.open({ text: this.translations.resolutionCreated, duration: 6000, type: 'success' });
        } else {
          this.toaster.open({ text: this.translations.resolutionCreationError, duration: 6000, type: 'danger' });
        }
      }
    })
  }
}
