import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { RemoteService } from '@app/services/remote.service';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionDTO } from '@app/models/Question';
import { QuestionaryDTO, QuestionaryEvaluationDto } from '@app/models/Questionary';
import { EvaluationQuestionaryCreateDTO } from '@app/models/EvaluationQuestionary';
import { MatTabGroup } from '@angular/material/tabs';
import { Location } from '@angular/common';
import { ThirdPartyQuestionaryReportService } from '@app/services/reports/third-party-questionary-report.service';
import download from "downloadjs";
import { sort } from '@app/utilities/utilities';
import { QuestionariesService } from '@app/services/questionaries-service';
import { QuestionScoreOverrideDto } from 'src/proxy/model/questionScoreOverrideDto';
import { canAccess } from '@app/security/riskallay.roles';
import { AuthenticationService } from '@app/services/authentication.service';
import { Role } from '@app/models/Role';

@Component({
  selector: 'app-view-answers',
  templateUrl: './view-answers.component.html',
  styleUrls: ['./view-answers.component.scss']
})
export class ViewAnswersComponent implements OnInit {

  isLoading: boolean = true;
  generatingReportMessage: string;
  reportGeneratedMessage: string;
  scoresOverridenSuccess: string;
  scoresOverridenError: string;

  questionary: QuestionaryEvaluationDto;
  campaignId: number;
  thirdId: number;
  editable: boolean = false;

  questionaryAnswers: Array<any>;
  form: any;

  tipoBloqueDefinitionObjs = [];

  rateCompletadas = {};

  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
  currentIndex: number = 0;

  constructor(
    private fb: FormBuilder,
    private remoteService: RemoteService,
    private toaster: Toaster,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService ,
    private location: Location,
    private questionaryReportService: ThirdPartyQuestionaryReportService,
    private questionaryService: QuestionariesService
  ) {
    this.translate.get('ViewAnswers.generatingReport')
      .subscribe(translation => {
        this.generatingReportMessage = translation;
      });

    this.translate.get('ViewAnswers.reportGenerated')
      .subscribe(translation => {
        this.reportGeneratedMessage = translation;
      });

    this.translate.get('ViewAnswers.scoresOverridenSuccess')
      .subscribe(t => {
        this.scoresOverridenSuccess = t;
      });

    this.translate.get('ViewAnswers.scoresOverridenError')
      .subscribe(t => {
        this.scoresOverridenError = t;
      })
  }

  async ngOnInit(): Promise<void> {

    const userRoles = this.authenticationService.getRoles();
    this.form = this.fb.group({
      Template: new FormControl({ value: '', disabled: true }, Validators.required),
      bloques: this.fb.array([])
    });

    this.campaignId = this.activatedRoute.snapshot.params.cid;
    this.thirdId = this.activatedRoute.snapshot.params.tid;
    this.editable = this.activatedRoute.snapshot.params.editable === "1" && canAccess([Role.Manager, Role.Owner], userRoles);
    await this.loadInitialData(this.thirdId, this.campaignId);
    this.isLoading = false;
  }

  async downloadReport() {
    this.toaster.open({ text: this.generatingReportMessage, duration: 4000, type: 'info' });
    const a = await this.questionaryReportService.getReport(this.campaignId, this.thirdId);
    this.toaster.open({ text: this.reportGeneratedMessage, duration: 4000, type: 'success' });
    download(a[1], a[0], "application/pdf");
  }

  async loadInitialData(tid: number, cid: number) {

    await this.getQuestionaryP(tid, cid).then(r => {
      if (r) {
        this.questionary = r;
        this.questionaryAnswers = this.questionary.EvaluationQuestionary;
      }
    });

    this.buildFormulario(this.questionary);
  }

  getQuestionaryP(tid: number, cid: number) {
    return this.remoteService.getRequest(`Questionaries/GetByThirdPartyAndCampaign/${tid}/${cid}`);
  }

  get bloques() {
    return this.form.get("bloques") as FormArray;
  }

  addBloque(bloque: any) {
    this.bloques.push(this.newBloque(bloque));
  }

  newBloque(bloque: any): FormGroup {
    return this.fb.group({
      Risk: new FormControl({ value: bloque.Risk, disabled: true }, Validators.required),
      Id: new FormControl({ value: bloque.Id, disabled: true }, Validators.required),
      Questions: this.fb.array([])
    })
  }

  getBlockName(bloque) {
    return bloque.get('Risk').value.Description;
  }

  getBlock(bloque) {
    return bloque.get('Risk').value;
  }

  bloqueQuestions(bloqueIndex: number): FormArray {
    return this.bloques.at(bloqueIndex).get("Questions") as FormArray
  }

  findAnsweredQuestion(bloqueId: number, preguntaId: number, respuesta: EvaluationQuestionaryCreateDTO) {
    let res: any = null;
    if (respuesta !== null) {
      for (let indexB = 0; indexB < respuesta.EvaluationQuestionaryRisk.length; indexB++) {
        const elementB = respuesta.EvaluationQuestionaryRisk[indexB];
        let idBloqueCuestionario = this.questionary.QuestionaryBlocks[bloqueId].Risk.Id;
        if (elementB.RiskId == idBloqueCuestionario) {
          for (let indexQ = 0; indexQ < elementB.EvaluationQuestionaryRiskAnswer.length; indexQ++) {
            const elementQ = elementB.EvaluationQuestionaryRiskAnswer[indexQ];
            if (elementQ.QuestionId == preguntaId) {
              res = elementQ;
              break;
            }
          }
          if (res != null)
            break;
        }
      }
    }
    return res;
  }

  newQuestion2(bloqueIndex: number, question: QuestionDTO, answeredQuestion: EvaluationQuestionaryCreateDTO): FormGroup {

    let f = this.fb.group({
      Q: new FormControl(question, Validators.required),
      Description: new FormControl({ value: question.Name, disabled: true }, Validators.required),
      Id: new FormControl({ value: question.Id, disabled: true }, Validators.required),
      WeightYes: new FormControl({ value: question.WeightYes, disabled: true }, Validators.required),
      WeightNo: new FormControl({ value: question.WeightNo, disabled: true }, Validators.required),
      Answer: new FormControl(1, Validators.required),
      SelectedOption: new FormControl({ value: question.SelectedOption, disabled: true }, Validators.required),
      IsMultipleChoice: new FormControl({ value: question.IsMultipleChoice, disabled: true }, Validators.required),
      QuestionScore: new FormControl({ value: null, disabled: true }),
      OverrideScore: new FormControl({ value: null, disabled: !this.editable }),
      Options: this.fb.array([]),
      AnswerId: new FormControl({ value: null, disabled: false }),
    });

    if (question.IsMultipleChoice) {
      const options = JSON.parse(question.Options);
      for (const option of options) {
        (f.get('Options') as FormArray).push(this.fb.group({
          Text: option.Text
        }));
      }
    }

    let comment = null;
    let fileuri = null;
    let filepath = null;

    let valorRespuestas = this.findAnsweredQuestion(bloqueIndex, question.Id, answeredQuestion)

    if (question.CanAddComments) {
      if (question.MustAddComments) {
        f.addControl('Comment', new FormControl(comment, Validators.required))
      } else {
        f.addControl('Comment', new FormControl(comment, []))
      }

      f.get("Comment").disable();
    }

    let doc = null;
    if (fileuri !== null) {
      doc = { 'FileUri': fileuri, 'FileName:': filepath }
    }
    if (question.CanAddDoc) {
      if (question.MustAddDoc) {
        f.addControl('Doc', new FormControl(doc, Validators.required))
      } else {
        f.addControl('Doc', new FormControl(doc, []))
      }
    }

    if (valorRespuestas != null) {
      f.get("AnswerId").setValue(valorRespuestas.Id);
      f.get("Answer").setValue(valorRespuestas.Value.toString());
      f.get("SelectedOption").setValue(valorRespuestas.SelectedOption);
      f.get("QuestionScore").setValue(valorRespuestas.QuestionScore);
      f.get("OverrideScore").setValue(valorRespuestas.OverridenScore);
      if (valorRespuestas.Comment !== null) {
        f.get("Comment").setValue(valorRespuestas.Comment);
      }
      if (valorRespuestas.FileName !== null) {
        f.get("Doc").setValue({ 'FileName': valorRespuestas.FileName, 'FileUri': valorRespuestas.FileUri, 'File': {} });
      }
    }

    return f;
  }

  addQuestion2(bloqueIndex: number, question: QuestionDTO, answeredQuestion: EvaluationQuestionaryCreateDTO) {
    this.bloqueQuestions(bloqueIndex).push(this.newQuestion2(bloqueIndex, question, answeredQuestion));
  }

  getButtonText(b, q) {
    let qs = this.bloqueQuestions(b);
    if (qs == null || qs == undefined || qs.at(q) == null || qs.at(q) == undefined)
      return '';

    let Q = qs.at(q).get('Q');
    if (Q == null || Q == undefined)
      return '';

    return Q.value['DocTitle'];
  }

  buildFormulario(data: QuestionaryDTO) {
    this.form = this.fb.group({
      Template: new FormControl({ value: data.Code, disabled: true }, Validators.required),
      Client: new FormControl(data.Client, Validators.required),
      bloques: this.fb.array([])
    });

    for (let indexB = 0; indexB < data.QuestionaryBlocks.length; indexB++) {
      const bloque = data.QuestionaryBlocks[indexB];
      this.addBloque(bloque);

      const preguntas = (<QuestionDTO[]>bloque.Questions).sort((a, b) => sort(a, b, 'Order', true));

      for (let indexQ = 0; indexQ < preguntas.length; indexQ++) {
        const pregunta = preguntas[indexQ];

        let nuevaRespuesta = (this.questionaryAnswers.length == 0);
        let respuestaAnterior: EvaluationQuestionaryCreateDTO = null;
        if (!nuevaRespuesta) {
          respuestaAnterior = this.questionaryAnswers[0];
        }

        this.addQuestion2(indexB, pregunta, respuestaAnterior);
      }
    }
  }

  hasComment(questionForm) {
    let x = questionForm.get('Comment');
    if (x === null || x === undefined)
      return false;
    else
      return true;
  }

  hasDoc(questionForm) {
    let x = questionForm.get('Doc');
    if (x === null || x === undefined || x.value === null)
      return false;
    else
      return true;
  }

  public download(idfile: string, fileName: string) {
    this.remoteService.downloadMyFile(idfile, fileName);
  }

  getQuestionObj(indexB, indexQ, pid) {
    const pregunta = this.questionary.QuestionaryBlocks[indexB].Questions[indexQ];
    if (pregunta.Id == pid) {
      return pregunta;
    } else {
      return null;
    }
  }

  nextTab() {
    this.currentIndex = (this.currentIndex + 1) % this.bloques.length
    this.tabGroup.selectedIndex = this.currentIndex
  }

  previousTab() {
    this.currentIndex = (this.currentIndex - 1)
    if (this.currentIndex < 0) {
      this.currentIndex = this.bloques.length - 1
    }
    this.tabGroup.selectedIndex = this.currentIndex
  }

  public back() {
    this.location.back();
  }

  getValueText(value: number): string {
    if (value < 1.5)
      return 'ListadoPreguntas.form.peso1.valor'

    if (value >= 1.5 && value < 2.5)
      return 'ListadoPreguntas.form.peso2.valor'

    if (value >= 2.5 && value < 3.5)
      return 'ListadoPreguntas.form.peso3.valor'

    if (value >= 3.5 && value < 4.5)
      return 'ListadoPreguntas.form.peso4.valor'

    if (value >= 4.5)
      return 'ListadoPreguntas.form.peso5.valor'

    return ''
  }

  getYesNoText(value: string): string {
    switch (value) {
      case "true":
        return 'ViewAnswers.yes.valor';
      case "false":
        return 'ViewAnswers.no.valor';
      default:
        return '-';
    }
  }

  save() {
    let overridenScores: QuestionScoreOverrideDto[] = [];
    for (let indexB = 0; indexB < this.bloques.length; indexB++) {
      const elementBloque = (<FormArray>this.form.get("bloques")).at(indexB);
      for (let indexQ = 0; indexQ < (<FormArray>elementBloque.get("Questions")).length; indexQ++) {

        var qa = (<FormArray>elementBloque.get("Questions")).at(indexQ);

        if (qa.get('OverrideScore').value) {
          overridenScores.push(
            <QuestionScoreOverrideDto>{
              AnswerId: qa.get('AnswerId').value,
              Score: qa.get('OverrideScore').value
            }
          );

        }
      }
    }

    this.questionaryService.overrideScores(this.campaignId, this.thirdId, overridenScores).subscribe(r => {
      if (r) {
        this.toaster.open({ text: this.scoresOverridenSuccess, duration: 4000, type: 'success' });
      } else {
        this.toaster.open({ text: this.scoresOverridenError, duration: 4000, type: 'success' });
      }
    });
  }
}

