import { Component, Input } from "@angular/core";
import { Alias } from "@app/modules/screening-hub/models/GesintelScreening/Alias";

@Component({
  selector: 'aml-update-alias-variations',
  templateUrl: './alias-variations.component.html',
  styleUrls: ['./alias-variations.component.scss'],
})
export class AMLUpdateAliasVariationsComponent {
  @Input() alias: Alias[];

  constructor() { }
}
