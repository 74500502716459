import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginComponent } from '@app/modules/access/login/login.component';
import { ForgottenPasswordComponent } from '@app/modules/access/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from '@app/modules/access/reset-password/reset-password.component';
import { SearchComponent } from './modules/thirdparties/search/search.component';
import { RisklistIntroComponent } from '@modules/myrisks//risklist-intro/risklist-intro.component';
import { RisklistFillComponent } from '@modules/myrisks/risklist-fill/risklist-fill.component';
import { RisklistSignComponent } from '@modules/myrisks/risklist-sign/risklist-sign.component';
import { RisklistSentComponent } from '@modules/myrisks/risklist-sent/risklist-sent.component';
import { ThirdImportMassiveComponent } from './modules/thirdparties/third-import-massive/third-import-massive.component';
import { ThirdImportMassiveEndComponent } from '@modules/thirdparties/third-import-massive-end/third-import-massive-end.component';
import { CreateThirdComponent } from '@modules/thirdparties/create-third/create-third.component';
import { EditThirdComponent } from '@modules/thirdparties/edit-third/edit-third.component';
import { CreateCampaignComponent } from '@modules/campaigns/create-campaign/create-campaign.component';
import { SentCampaignComponent } from '@modules/campaigns/sent-campaign/sent-campaign.component';
import { CampaignsComponent } from '@modules/campaigns/campaigns/campaigns.component';
import { DetailCampaignComponent } from '@modules/campaigns/detail-campaign/detail-campaign.component';
import { AddThirdpartiesComponent } from '@modules/campaigns/add-thirdparties/add-thirdparties.component';
import { TemplateDetailCopyMasterComponent } from '@app/modules/templates/template-detail-copy-master/template-detail-copy-master.component';
import { RiskboardComponent } from '@app/modules/riskboard/riskboard/riskboard.component';
import { PortalPageIdComponent } from '@app/modules/myrisks/portal-page-id/portal-page-id.component';
import { PortalMyRisksComponent } from '@app/modules/myrisks/portal-my-risks/portal-my-risks.component';
import { CampaignReleaseRiskboardComponent } from '@app/modules/campaigns/campaign-release-riskboard/campaign-release-riskboard.component';
import { ViewAnswersComponent } from '@modules/templates/view-answers/view-answers.component'
import { ConfigCompanyComponent } from '@app/modules/config-company/config-company.component';
import { OnboardingIntroComponent } from '@modules/onboarding/onboarding-intro/onboarding-intro.component';
import { OnboardingFillCardComponent } from '@modules/onboarding/onboarding-fill-card/onboarding-fill-card.component';
import { OnboardingAddUserComponent } from '@app/modules/onboarding/onboarding-add-user/onboarding-add-user.component';
import { OnboardingEndComponent } from '@modules/onboarding/onboarding-end/onboarding-end.component';
import { EsAdminGuard } from '@app/security/es-admin.guard';
import { EsThirdPartyGuard } from '@app/security/es-thirdparty.guard';
import { EsAdminUserGuard } from '@app/security/es-adminuser.guard';
import { ResetPasswordNewClientComponent } from '@app/modules/access/reset-password-new-client/reset-password-new-client.component';
import { ResetPasswordThirdpartyComponent } from '@app/modules/access/reset-password-thirdparty/reset-password-thirdparty.component';
import { EvaluacionEnviadaNewThirdpartiesComponent } from '@modules/campaigns/evaluacion-enviada-new-thirdparties/evaluacion-enviada-new-thirdparties.component';
import { AppTwoStepVerificationComponent } from './modules/access/app-two-step-verification/app-two-step-verification.component';
import { ThirdPartyScoringCandidatesComponent } from './modules/screening-hub/components/scoring-candidates/scoring-candidates.component';
import { ThirdPartyScoringCandidateDetailsComponent } from './modules/screening-hub/components/refinitiv/scoring-candidate-details/scoring-candidate-details.component';
import { ThirdPartyScreeningDetailsComponent } from './modules/screening-hub/components/refinitiv/screening-details/screening-details.component';
import { ThirdPartyScoringCandidatesListComponent } from './modules/screening-hub/components/refinitiv/scoring-candidates-list/scoring-candidates-list.component';
import { IsDirtyGuardService } from './services/is-dirty-guard.service';
import { ThirdPartySignUpComponent } from './modules/access/third-party-sign-up/third-party-sign-up.component';
import { ThirdPartySignUpIntroComponent } from './modules/access/third-party-sign-up-intro/third-party-sign-up-intro.component';
import { ConfirmEmailComponent } from './modules/access/confirm-email/confirm-email.component';
import { ThirdPartyUserCreatedComponent } from './modules/access/third-party-user-created/third-party-user-created.component';
import { TemplateComponent } from './modules/templates/template/template.component';
import { MainComponent } from './modules/main/main.component';
import { HomeComponent } from './modules/home/home.component';
import { MyAccountComponent } from './modules/my-account/my-account.component';
import { GeneralAdjustmentsComponent } from './modules/general-adjustments/general-adjustments.component';
import { IsTemplateFormDirtyGuardService } from './services/is-template-form-dirty-guard.service';
import { CustomFieldDetailComponent } from './modules/configuration/custom-field-detail/custom-field-detail.component';
import { CustomFieldAddComponent } from './modules/configuration/custom-field-add/custom-field-add.component';
import { ScreeningToolsHomeComponent } from './modules/screening-hub/pages/screening-tools-home/screening-tools-home.component';
import { ScoringCandidatesListGesintelComponent } from './modules/screening-hub/components/aml-update/scoring-candidates-list-gesintel/scoring-candidates-list-gesintel.component';
import { AMLUpdateScoringCandidateDetailsComponent } from './modules/screening-hub/components/aml-update/scoring-candidate-details/scoring-candidate-details.component';
import { ResolutionRecordDetailsComponent } from './modules/screening-hub/resolutions/resolution-record-details/resolution-record-details.component';
import { AMLUpdateScreeninDetailsComponent } from './modules/screening-hub/components/aml-update/screening-details/screening-details.component';
import { EquifaxWSScreeninDetailsComponent } from './modules/screening-hub/components/equifax-ws/screening-details/screening-details.component';
import { EquifaxWSScoringCandidateDetailsComponent } from './modules/screening-hub/components/equifax-ws/scoring-candidate-details/scoring-candidate-details.component';
import { ScoringCandidatesListEquifaxWSComponent } from './modules/screening-hub/components/equifax-ws/scoring-candidates-list/scoring-candidates-list.component';
import { MemberCheckCandidatesComponent } from './modules/screening-hub/components/member-check/member-check-scoring-candidate/member-check-scoring-candidate.component';
import { MemberCheckCandidateDetailComponent } from './modules/screening-hub/components/member-check/member-check-candidate-detail/member-check-candidate-detail.component';
import { MemberCheckScoringDetailComponent } from './modules/screening-hub/components/member-check/member-check-scoring-detail/member-check-scoring-detail.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  //Basicas
  { path: 'login', component: LoginComponent },
  { path: 'twostepverification', component: AppTwoStepVerificationComponent },
  { path: 'he-olvidado-contrasena', component: ForgottenPasswordComponent },
  { path: 'cambio-de-contrasena', component: ResetPasswordComponent },
  { path: 'cambio-de-contrasena-client', component: ResetPasswordNewClientComponent },
  { path: 'cambio-de-contrasena-third', component: ResetPasswordThirdpartyComponent },
  { path: 'confirm-email', component: ConfirmEmailComponent },
  { path: 'third-party-sign-up', component: ThirdPartySignUpIntroComponent },
  { path: 'third-party-sign-up-form', component: ThirdPartySignUpComponent },
  { path: 'third-party-user-created', component: ThirdPartyUserCreatedComponent },
  { path: 'main', component: MainComponent, canActivate: [AuthGuardService] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService, EsAdminUserGuard] },
  { path: 'busqueda', component: SearchComponent, canActivate: [AuthGuardService] },
  { path: 'riskboard', component: RiskboardComponent, canActivate: [AuthGuardService] },
  { path: 'mi-perfil', component: MyAccountComponent, canActivate: [AuthGuardService] },
  { path: 'screening-tools', component: ScreeningToolsHomeComponent, canActivate: [AuthGuardService] },
  //Cuenta de Compliance Manager
  { path: 'configuracion-empresa', component: ConfigCompanyComponent, canActivate: [AuthGuardService, EsAdminGuard] },
  { path: 'nueva-evaluacion/:cid', component: CreateCampaignComponent, canActivate: [AuthGuardService] },
  { path: 'evaluacion-enviada/:id', component: SentCampaignComponent, canActivate: [AuthGuardService] },
  { path: 'evaluaciones', component: CampaignsComponent, canActivate: [AuthGuardService] },
  { path: 'detalle-evaluacion/:id', component: DetailCampaignComponent, canActivate: [AuthGuardService] },
  { path: 'anadir-terceros/:id', component: AddThirdpartiesComponent, canActivate: [AuthGuardService] },
  { path: 'evaluacion-enviada-new-thirdparties/:id', component: EvaluacionEnviadaNewThirdpartiesComponent, canActivate: [AuthGuardService] },
  { path: 'plantillas', component: TemplateComponent, canActivate: [AuthGuardService] },
  { path: 'detalle-plantilla/:qid', component: TemplateDetailCopyMasterComponent, canActivate: [AuthGuardService], canDeactivate: [IsTemplateFormDirtyGuardService] },
  { path: 'nuevo-tercero', component: CreateThirdComponent, canActivate: [AuthGuardService] },
  { path: 'perfil-tercero/:id', component: EditThirdComponent, canActivate: [AuthGuardService] },
  { path: 'perfil-tercero/:id/:tabIndex', component: EditThirdComponent, canActivate: [AuthGuardService] },
  { path: 'riskboard', component: RiskboardComponent, canActivate: [AuthGuardService] },
  { path: 'pagina-id', component: PortalPageIdComponent, canActivate: [AuthGuardService] },
  { path: 'mis-riesgos', component: PortalMyRisksComponent, canActivate: [AuthGuardService] },
  { path: 'introduccion-risklist/:ca/:third', component: RisklistIntroComponent, canActivate: [AuthGuardService] },
  { path: 'rellena-risklist/:ca/:third', component: RisklistFillComponent, canActivate: [AuthGuardService], canDeactivate: [IsDirtyGuardService] },
  { path: 'firma-risklist/:ca/:third', component: RisklistSignComponent, canActivate: [AuthGuardService] },
  { path: 'risklist-enviado', component: RisklistSentComponent, canActivate: [AuthGuardService] },
  { path: 'evaluaciones-lanzadas-riskboard/:tid/:eid', component: CampaignReleaseRiskboardComponent, canActivate: [AuthGuardService] },
  { path: 'evaluation-answers/:tid/:cid/:editable', component: ViewAnswersComponent, canActivate: [AuthGuardService] },
  { path: 'importar-terceros-carga-masiva', component: ThirdImportMassiveComponent, canActivate: [AuthGuardService] },
  { path: 'importar-terceros-carga-masiva-realizada', component: ThirdImportMassiveEndComponent, canActivate: [AuthGuardService] },
  { path: 'add-custom-field', component: CustomFieldAddComponent, canActivate: [AuthGuardService] },
  { path: 'custom-field-detail/:id', component: CustomFieldDetailComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidates', component: ThirdPartyScoringCandidatesComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidates/:id', component: ThirdPartyScoringCandidatesComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidates-result/:caseId', component: ThirdPartyScoringCandidatesListComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidates-result/:caseId/:id', component: ThirdPartyScoringCandidatesListComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidates-result-gesintel', component: ScoringCandidatesListGesintelComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidates-result-gesintel/:id', component: ScoringCandidatesListGesintelComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidate-details/:referenceId/:caseId/:matchStrength', component: ThirdPartyScoringCandidateDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidate-details/:referenceId/:caseId/:matchStrength/:id', component: ThirdPartyScoringCandidateDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidate-details-gesintel/:rut', component: AMLUpdateScoringCandidateDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'scoring-candidate-details-gesintel/:rut/:id', component: AMLUpdateScoringCandidateDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'screening-details/:screeningId', component: ThirdPartyScreeningDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'screening-details/:screeningId/:id', component: ThirdPartyScreeningDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'amlupdate/screening-details/:screeningId', component: AMLUpdateScreeninDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'amlupdate/screening-details/:screeningId/:id', component: AMLUpdateScreeninDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'equifaxws/screening-details/:screeningId', component: EquifaxWSScreeninDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'equifaxws/screening-details/:screeningId/:id', component: EquifaxWSScreeninDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'resolution-details/:resolutionId/:id', component: ResolutionRecordDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'resolution-details/:resolutionId', component: ResolutionRecordDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'equifaxws/scoring-candidates-results', component: ScoringCandidatesListEquifaxWSComponent, canActivate: [AuthGuardService] },
  { path: 'equifaxws/scoring-candidates-results/:id', component: ScoringCandidatesListEquifaxWSComponent, canActivate: [AuthGuardService] },
  { path: 'equifaxws/scoring-candidate-details/:cedula/:searchType', component: EquifaxWSScoringCandidateDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'equifaxws/scoring-candidate-details/:cedula/:searchType/:id', component: EquifaxWSScoringCandidateDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'member-check/candidates/:scanId', component: MemberCheckCandidatesComponent, canActivate: [AuthGuardService] },
  { path: 'member-check/candidates/:scanId/:id', component: MemberCheckCandidatesComponent, canActivate: [AuthGuardService] },
  { path: 'member-check-candidate-details/:scanId', component: MemberCheckCandidateDetailComponent, canActivate: [AuthGuardService] },
  { path: 'member-check-candidate-details/:scanId/:id', component: MemberCheckCandidateDetailComponent, canActivate: [AuthGuardService] },
  { path: 'member-check/screening-details/:screeningId/:name', component: MemberCheckScoringDetailComponent, canActivate: [AuthGuardService] },
  { path: 'member-check/screening-details/:screeningId/:name/:id', component: MemberCheckScoringDetailComponent, canActivate: [AuthGuardService] },
  //Onboarding
  { path: 'onboarding-introduccion', component: OnboardingIntroComponent, canActivate: [AuthGuardService] },
  { path: 'onboarding-rellenar-ficha-cliente', component: OnboardingFillCardComponent, canActivate: [AuthGuardService] },
  { path: 'onboarding-alta-usuarios', component: OnboardingAddUserComponent, canActivate: [AuthGuardService] },
  { path: 'onboarding-cuenta-configurada', component: OnboardingEndComponent, canActivate: [AuthGuardService] },
  //Cuenta de Tercero
  { path: 'pagina-id', component: PortalPageIdComponent, canActivate: [AuthGuardService, EsThirdPartyGuard] },
  { path: 'mis-riesgos', component: PortalMyRisksComponent, canActivate: [AuthGuardService, EsThirdPartyGuard] },
  { path: 'rellena-risklist', component: RisklistFillComponent, canActivate: [AuthGuardService, EsThirdPartyGuard], canDeactivate: [IsDirtyGuardService] },
  { path: 'firma-risklist', component: RisklistSignComponent, canActivate: [AuthGuardService, EsThirdPartyGuard] },
  { path: 'risklist-enviado', component: RisklistSentComponent, canActivate: [AuthGuardService, EsThirdPartyGuard] },
  //Ajustes generales
  { path: 'ajustes-generales', component: GeneralAdjustmentsComponent, canActivate: [AuthGuardService, EsAdminGuard] },
  { path: 'ajustes-generales/:table', component: GeneralAdjustmentsComponent, canActivate: [AuthGuardService, EsAdminGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
