import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Toaster } from "ngx-toast-notifications";
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from "@amcharts/amcharts4/maps";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import download from "downloadjs";
import { forkJoin } from "rxjs";
import { Country } from "@app/models/Country";
import { ThirdPartiesService } from "@app/services/third-parties.services";
import { ThirdPartyTypesService } from "@app/services/third-party-types.service";
import { ThirdPartyType } from "@app/models/ThirdPartyType";
import { ThirdParty } from "@app/models/ThirdParty";
import { ThirdPartyStatus } from "@app/models/ThirdPartyStatus";
import { ThirdPartyStatusHistoryRecord } from "@app/models/ThirdPartyStatusHistoryRecord";
import { ThirdPartyRiskboardReportService } from "@app/services/reports/third-party-riskboard-report.service";

@Component({
  selector: 'app-third-party-riskboard',
  templateUrl: './third-party-riskboard.component.html',
  styleUrls: ['./third-party-riskboard.component.scss']
})
export class ThirdPartyRiskboardComponent implements OnInit, AfterViewInit, OnDestroy {
  mapChart: am4maps.MapChart;
  thirdPartiesBySectorChart: am4charts.XYChart;
  statusEvoChart: am4charts.XYChart;
  thirdPartiesByCountryChart: am4charts.XYChart;
  thirdPartyStatusBySectorsChart: am4charts.XYChart;
  thirdPartyStatusByCountryChart: am4charts.XYChart;

  thirdParties: ThirdParty[];
  thirdPartiesCount = 0;
  pending = 0;
  inProgress = 0;
  approved = 0;
  rejected = 0;
  tpTypeFilter: number[];
  tpTypes: ThirdPartyType[];
  tpStatusHistory: ThirdPartyStatusHistoryRecord[];

  thirdPartiesWithUnspecifiedCountries = 0;

  generatingReportMessage: string;
  reportGeneratedMessage: string;
  translations: any;

  isLoading = true;

  constructor(
    private reportService: ThirdPartyRiskboardReportService,
    private thirdPartyService: ThirdPartiesService,
    private thirdPartyTypesService: ThirdPartyTypesService,
    private translate: TranslateService,
    private toaster: Toaster) { }

  ngOnInit() {
    this.tpTypeFilter = [];

    this.translate.get('Riskboard.generatingReport')
      .subscribe(translation => {
        this.generatingReportMessage = translation;
      });

    this.translate.get('Riskboard.reportGenerated')
      .subscribe(translation => {
        this.reportGeneratedMessage = translation;
      });
  }

  ngAfterViewInit(): void {
    this.mapa();

    forkJoin({
      t: this.translate.get("Riskboard"),
      r: this.thirdPartyService.getAllThirdParties(),
      tpTypes: this.thirdPartyTypesService.getThirdPartyTypes(),
      s: this.thirdPartyService.getThirdPartyStatusHistoryRecords()
    })
      .subscribe(({ t, r, tpTypes, s }) => {
        this.createThirdPartiesByCountriesChart(t);
        this.createThirdPartiesBySectorChart(t);
        this.createThirdPartyStatusByCountryChart(t);
        this.createThirdPartyStatusBySectorsChart(t);
        this.evoChart(t);
        this.translations = t;
        this.thirdParties = r;
        this.tpStatusHistory = s;

        const unspecifiedThirdPartyType: ThirdPartyType = {
          id: 0,
          code: 'Not specified',
          description: 'No especificado',
          descriptionEN: 'Not specified',
          descriptionPT: 'Não especificado',
          descriptionFR: 'Non spécifié'
        };
        this.tpTypes = [...tpTypes, unspecifiedThirdPartyType];
        this.loadDataData();
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.mapChart?.dispose();
    this.thirdPartiesBySectorChart?.dispose();
    this.statusEvoChart?.dispose();
    this.thirdPartiesByCountryChart?.dispose();
    this.thirdPartyStatusBySectorsChart?.dispose();
    this.thirdPartyStatusByCountryChart?.dispose();
  }

  async downloadReport() {
    this.toaster.open({ text: this.generatingReportMessage, duration: 4000, type: 'info' });
    const reportTPs = this.tpTypeFilter.length > 0 ? this.thirdParties.filter(c => this.tpTypeFilter.find(f => c.thirdPartyConfig.ThirdPartyType?.Id === f)) : this.thirdParties;
    const imgStatusEvoChart = await this.statusEvoChart.exporting.getImage('png');
    const imgThirdPartyStatusByCountry = await this.thirdPartyStatusByCountryChart.exporting.getImage('png');
    const imgThirdPartyStatusBySector = await this.thirdPartyStatusBySectorsChart.exporting.getImage('png');
    const a = await this.reportService.getReport(reportTPs, imgStatusEvoChart, imgThirdPartyStatusByCountry, imgThirdPartyStatusBySector);
    this.toaster.open({ text: this.reportGeneratedMessage, duration: 4000, type: 'success' });
    download(a[1], a[0], "application/pdf");
  }

  loadDataData() {
    const filteredThirdParties = this.tpTypeFilter.length > 0 ? this.thirdParties.filter(c => this.tpTypeFilter.find(f => c.thirdPartyConfig.ThirdPartyType?.Id === f)) : this.thirdParties;
    const filteredTPStatus = this.tpTypeFilter.length > 0 ? this.tpStatusHistory.filter(c => this.tpTypeFilter.find(f => c.thirdPartyTypeId === f)) : this.tpStatusHistory;
    this.mapChartData(filteredThirdParties);
    this.thirdPartiesBySectorChartData(filteredThirdParties);
    this.thirdPartiesByCountryChartData(filteredThirdParties);
    this.thirdPartyStatusByCountryChartData(filteredThirdParties);
    this.thirdPartyStatusBySectorsChartData(filteredThirdParties);
    this.evoChartData(filteredTPStatus);
    this.thirdPartiesCount = filteredThirdParties.length;
    this.rejected = filteredThirdParties.filter(tp => tp.status == ThirdPartyStatus.rejected).length;
    this.inProgress = filteredThirdParties.filter(tp => tp.status == ThirdPartyStatus.inProgress).length;
    this.pending = filteredThirdParties.filter(tp => tp.status == ThirdPartyStatus.pending).length;
    this.approved = filteredThirdParties.filter(tp => tp.status == ThirdPartyStatus.approved).length;

    this.thirdPartiesWithUnspecifiedCountries = this.thirdParties.filter(tp => tp.country.id === 0).length;
  }

  changeTPType(e) {
    this.tpTypeFilter = e.value;
    this.loadDataData();
  }

  mapa() {
    this.mapChart = am4core.create("mapChartDiv", am4maps.MapChart);

    // Set map definition
    this.mapChart.geodata = am4geodata_worldLow;

    // Set projection
    this.mapChart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    let polygonSeries = this.mapChart.series.push(new am4maps.MapPolygonSeries());

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#9FC9F8");

    // Create hover state and set alternative fill color
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#367B25");

    // Remove Antarctica
    polygonSeries.exclude = ["AQ"];

    polygonSeries.data = [];

    // Bind "fill" property to "fill" key in data
    polygonTemplate.propertyFields.fill = "fill";

    // Add zoom control
    this.mapChart.smallMap = new am4maps.SmallMap();
  }

  mapChartData(thirdParties: ThirdParty[]) {
    const countries = thirdParties.filter(tp => tp.country != null).map(tp => tp.country);

    let serie = this.mapChart.series.values[0];
    serie.data = countries.reduce((countList: Country[], c) => {
      if (!countList.find(cl => cl.code === c?.code))
        countList.push(c);
      return countList;
    }, [])
      .filter(c => c !== null)
      .map(c => {
        return {
          "id": c.code,
          "name": c.description,
          "value": 100,
          "fill": am4core.color("#FFC00A")
        }
      });

    this.mapChart.series.values[0] = serie;
  }

  evoChart(translations: any) {
    this.statusEvoChart = am4core.create("statusEvoChartDiv", am4charts.XYChart);
    let dateAxis = this.statusEvoChart.xAxes.push(new am4charts.DateAxis());
    let valueAxis = this.statusEvoChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });
    valueAxis.adapter.add("getTooltipText", (text) => {
      return text + "%";
    });

    let pendingSeries = this.statusEvoChart.series.push(new am4charts.LineSeries());
    pendingSeries.dataFields.dateX = "date";
    pendingSeries.dataFields.valueY = "pending";
    pendingSeries.sequencedInterpolation = false;
    pendingSeries.dataFields.valueYShow = "totalPercent";
    pendingSeries.tooltipText = `${translations.pending3PP}`;
    pendingSeries.fillOpacity = 1;
    pendingSeries.stroke = am4core.color("#A6ADC4");
    pendingSeries.fill = am4core.color("#A6ADC4");
    pendingSeries.stacked = true;
    pendingSeries.defaultState.transitionDuration = 0;
    pendingSeries.tensionX = 0.8;

    let inProgressSeries = this.statusEvoChart.series.push(new am4charts.LineSeries());
    inProgressSeries.dataFields.dateX = "date";
    inProgressSeries.dataFields.valueY = "inProgress";
    inProgressSeries.sequencedInterpolation = false;
    inProgressSeries.dataFields.valueYShow = "totalPercent";
    inProgressSeries.tooltipText = `${translations.inProgress3PP}`;
    inProgressSeries.fillOpacity = 1;
    inProgressSeries.stroke = am4core.color("#7881A0");
    inProgressSeries.fill = am4core.color("#7881A0");
    inProgressSeries.stacked = true;
    inProgressSeries.defaultState.transitionDuration = 0;
    inProgressSeries.tensionX = 0.8;

    let rejectedSeries = this.statusEvoChart.series.push(new am4charts.LineSeries());
    rejectedSeries.dataFields.dateX = "date";
    rejectedSeries.dataFields.valueY = "rejected";
    rejectedSeries.sequencedInterpolation = false;
    rejectedSeries.dataFields.valueYShow = "totalPercent";
    rejectedSeries.tooltipText = `${translations.rejected}`;
    rejectedSeries.fillOpacity = 1;
    rejectedSeries.stroke = am4core.color("#C70000");
    rejectedSeries.fill = am4core.color("#C70000");
    rejectedSeries.stacked = true;
    rejectedSeries.defaultState.transitionDuration = 0;
    rejectedSeries.tensionX = 0.8;

    let approvedSeries = this.statusEvoChart.series.push(new am4charts.LineSeries());
    approvedSeries.dataFields.dateX = "date";
    approvedSeries.dataFields.valueY = "approved";
    approvedSeries.sequencedInterpolation = false;
    approvedSeries.dataFields.valueYShow = "totalPercent";
    approvedSeries.tooltipText = `${translations.approved}`;
    approvedSeries.fillOpacity = 1;
    approvedSeries.stroke = am4core.color("#64C700");
    approvedSeries.fill = am4core.color("#64C700");
    approvedSeries.stacked = true;
    approvedSeries.defaultState.transitionDuration = 0;
    approvedSeries.tensionX = 0.8;

    this.statusEvoChart.cursor = new am4charts.XYCursor();
    this.statusEvoChart.cursor.xAxis = dateAxis;
  }

  evoChartData(thirdPartyStatusHistoryRecords: ThirdPartyStatusHistoryRecord[]) {
    var groupedData = thirdPartyStatusHistoryRecords.reduce((rv, tp) => {
      let date = new Date(tp.timeStamp.getUTCFullYear(), tp.timeStamp.getUTCMonth(), 1);
      let index = rv.findIndex(r => r.date.getUTCFullYear() === date.getUTCFullYear() && r.date.getUTCMonth() === date.getUTCMonth());
      if (index > 0) {
        let index2 = rv[index].tpStatus.findIndex((t: ThirdPartyStatusHistoryRecord) => t.thirdPartyId === tp.thirdPartyId);
        if (index2 > 0) {
          if (rv[index].tpStatus[index2].timeStamp < tp.timeStamp) {
            rv[index].tpStatus[index2] = tp;
          }
        } else {
          rv[index].tpStatus.push(tp);
        }
      } else {
        rv.push({
          date: date,
          tpStatus: [tp]
        })
      }

      return rv;
    }, []);

    let data = groupedData.map(gd => {
      return {
        date: gd.date,
        pending: gd.tpStatus.filter((tp: ThirdPartyStatusHistoryRecord) => tp.status == ThirdPartyStatus.pending).length,
        inProgress: gd.tpStatus.filter((tp: ThirdPartyStatusHistoryRecord) => tp.status == ThirdPartyStatus.inProgress).length,
        rejected: gd.tpStatus.filter((tp: ThirdPartyStatusHistoryRecord) => tp.status == ThirdPartyStatus.rejected).length,
        approved: gd.tpStatus.filter((tp: ThirdPartyStatusHistoryRecord) => tp.status == ThirdPartyStatus.approved).length
      }
    });

    this.statusEvoChart.data = data;
  }

  createThirdPartiesByCountriesChart(translations: any) {
    this.thirdPartiesByCountryChart = am4core.create("thirdPartiesByCountryChartDiv", am4charts.XYChart);

    let categoryAxis = this.thirdPartiesByCountryChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.tooltipText = "{category}"

    let valueAxis = this.thirdPartiesByCountryChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minWidth = 25;

    let serie = this.thirdPartiesByCountryChart.series.push(new am4charts.ColumnSeries());
    serie.dataFields.valueX = "value";
    serie.columns.template.tooltipText = `${translations.thirdParties.valor}: {valueX}`;
    serie.columns.template.width = am4core.percent(60);
    serie.dataFields.categoryY = "category";
    serie.dataItems.template.locations.categoryY = 0.5;
    serie.columns.template.strokeWidth = 0;
    serie.stacked = true;
    serie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#FCEDCA');
    });
  }

  createThirdPartiesBySectorChart(translations: any) {
    this.thirdPartiesBySectorChart = am4core.create("thirdPartiesBySectorChartDiv", am4charts.XYChart);

    let categoryAxis = this.thirdPartiesBySectorChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.tooltipText = "{category}"

    let valueAxis = this.thirdPartiesBySectorChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minWidth = 25;

    let serie = this.thirdPartiesBySectorChart.series.push(new am4charts.ColumnSeries());
    serie.dataFields.valueX = "value";
    serie.columns.template.tooltipText = `${translations.thirdParties.valor}: {valueX}`;
    serie.columns.template.width = am4core.percent(60);
    serie.dataFields.categoryY = "category";
    serie.dataItems.template.locations.categoryY = 0.5;
    serie.columns.template.strokeWidth = 0;
    serie.stacked = true;
    serie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#FCEDCA');
    });
  }

  createThirdPartyStatusBySectorsChart(translations: any) {
    this.thirdPartyStatusBySectorsChart = am4core.create("thirdPartyStatusBySectorsChartDiv", am4charts.XYChart);

    let categoryAxis = this.thirdPartyStatusBySectorsChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.tooltipText = "{category}"

    let valueAxis = this.thirdPartyStatusBySectorsChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minWidth = 25;

    let pendingThirdPartySerie = this.thirdPartyStatusBySectorsChart.series.push(new am4charts.ColumnSeries());
    pendingThirdPartySerie.dataFields.valueX = "pendingThirdParties";
    pendingThirdPartySerie.columns.template.tooltipText = `${translations.pending3PP} {valueX}`;
    pendingThirdPartySerie.dataFields.categoryY = "category";
    pendingThirdPartySerie.columns.template.width = am4core.percent(50);
    pendingThirdPartySerie.dataItems.template.locations.categoryY = 0.5;
    pendingThirdPartySerie.columns.template.strokeWidth = 0;
    pendingThirdPartySerie.stacked = true;
    pendingThirdPartySerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#A6ADC4');
    });

    let inProgressSerie = this.thirdPartyStatusBySectorsChart.series.push(new am4charts.ColumnSeries());
    inProgressSerie.dataFields.valueX = "inProgressThirdParties";
    inProgressSerie.columns.template.tooltipText = `${translations.inProgress3PP} {valueX}`;
    inProgressSerie.columns.template.width = am4core.percent(60);
    inProgressSerie.dataFields.categoryY = "category";
    inProgressSerie.dataItems.template.locations.categoryY = 0.5;
    inProgressSerie.columns.template.strokeWidth = 0;
    inProgressSerie.stacked = true;
    inProgressSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#7881A0');
    });

    let rejectedSerie = this.thirdPartyStatusBySectorsChart.series.push(new am4charts.ColumnSeries());
    rejectedSerie.dataFields.valueX = "rejectedThirdParties";
    rejectedSerie.columns.template.tooltipText = `${translations.rejected} {valueX}`;
    rejectedSerie.dataFields.categoryY = "category";
    rejectedSerie.columns.template.width = am4core.percent(50);
    rejectedSerie.dataItems.template.locations.categoryY = 0.5;
    rejectedSerie.columns.template.strokeWidth = 0;
    rejectedSerie.stacked = true;
    rejectedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#C70000');
    });

    let approvedSerie = this.thirdPartyStatusBySectorsChart.series.push(new am4charts.ColumnSeries());
    approvedSerie.dataFields.valueX = "approvedThirdParties";
    approvedSerie.columns.template.tooltipText = `${translations.approved} {valueX}`;
    approvedSerie.dataFields.categoryY = "category";
    approvedSerie.columns.template.width = am4core.percent(50);
    approvedSerie.dataItems.template.locations.categoryY = 0.5;
    approvedSerie.columns.template.strokeWidth = 0;
    approvedSerie.stacked = true;
    approvedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#64C700');
    });
  }

  createThirdPartyStatusByCountryChart(translations: any) {
    this.thirdPartyStatusByCountryChart = am4core.create("thirdPartyStatusByCountryChartDiv", am4charts.XYChart);

    let categoryAxis = this.thirdPartyStatusByCountryChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.tooltipText = "{category}"

    let valueAxis = this.thirdPartyStatusByCountryChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minWidth = 25;

    let pendingThirdPartySerie = this.thirdPartyStatusByCountryChart.series.push(new am4charts.ColumnSeries());
    pendingThirdPartySerie.dataFields.valueX = "pendingThirdParties";
    pendingThirdPartySerie.columns.template.tooltipText = `${translations.pending3PP} {valueX}`;
    pendingThirdPartySerie.dataFields.categoryY = "category";
    pendingThirdPartySerie.columns.template.width = am4core.percent(50);
    pendingThirdPartySerie.dataItems.template.locations.categoryY = 0.5;
    pendingThirdPartySerie.columns.template.strokeWidth = 0;
    pendingThirdPartySerie.stacked = true;
    pendingThirdPartySerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#A6ADC4');
    });

    let inProgressSerie = this.thirdPartyStatusByCountryChart.series.push(new am4charts.ColumnSeries());
    inProgressSerie.dataFields.valueX = "inProgressThirdParties";
    inProgressSerie.columns.template.tooltipText = `${translations.inProgress3PP} {valueX}`;
    inProgressSerie.columns.template.width = am4core.percent(60);
    inProgressSerie.dataFields.categoryY = "category";
    inProgressSerie.dataItems.template.locations.categoryY = 0.5;
    inProgressSerie.columns.template.strokeWidth = 0;
    inProgressSerie.stacked = true;
    inProgressSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#7881A0');
    });

    let rejectedSerie = this.thirdPartyStatusByCountryChart.series.push(new am4charts.ColumnSeries());
    rejectedSerie.dataFields.valueX = "rejectedThirdParties";
    rejectedSerie.columns.template.tooltipText = `${translations.rejected} {valueX}`;
    rejectedSerie.dataFields.categoryY = "category";
    rejectedSerie.columns.template.width = am4core.percent(50);
    rejectedSerie.dataItems.template.locations.categoryY = 0.5;
    rejectedSerie.columns.template.strokeWidth = 0;
    rejectedSerie.stacked = true;
    rejectedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#C70000');
    });

    let approvedSerie = this.thirdPartyStatusByCountryChart.series.push(new am4charts.ColumnSeries());
    approvedSerie.dataFields.valueX = "approvedThirdParties";
    approvedSerie.columns.template.tooltipText = `${translations.approved} {valueX}`;
    approvedSerie.dataFields.categoryY = "category";
    approvedSerie.columns.template.width = am4core.percent(50);
    approvedSerie.dataItems.template.locations.categoryY = 0.5;
    approvedSerie.columns.template.strokeWidth = 0;
    approvedSerie.stacked = true;
    approvedSerie.columns.template.adapter.add("fill", (fill, target) => {
      return am4core.color('#64C700');
    });
  }

  thirdPartiesBySectorChartData(thirdParties: ThirdParty[]) {
    this.thirdPartiesBySectorChart.data = thirdParties.reduce((rv, c) => {
      if (c.thirdPartyConfig.Sector === null) {
        c.thirdPartyConfig.Sector = { Id: 0, Code: 'Not specified', Description: 'No especificado', DescriptionEN: 'Not specified', DescriptionPT: 'Não especificado', DescriptionFR: 'Non spécifié' }
      }
      let index = rv.findIndex(g => g.category === c.thirdPartyConfig.Sector?.Description);
      if (index < 0) {
        rv.push({ category: c.thirdPartyConfig.Sector.Description, value: 1, thirdParties: [c.id] });
      } else {
        if (rv[index].thirdParties.findIndex(tp => c.id === tp) < 0) {
          rv[index].value++;
          rv[index].thirdParties.push(c.id);
        }
      }
      return rv;
    }, []);
  }

  thirdPartiesByCountryChartData(thirdParties: ThirdParty[]) {
    const currentLang = this.translate.currentLang;
    const translatedNADescription = currentLang === 'es-ES' ? 'No especificado' : currentLang === 'en-US' ? 'Not specified' : 'Não especificado';
    this.thirdPartiesByCountryChart.data = thirdParties.reduce((rv, c) => {
      if (c.country === null) {
        c.country = { id: 0, code: 'Not specified', codeAlpha3: 'Not specified', description: translatedNADescription }
      }

      let index = rv.findIndex(g => g.category === c.country?.description);
      if (index < 0 && c.country !== null) {
        rv.push({ category: c.country.description, value: 1, thirdParties: [c.id] });
      } else if (c.country !== null) {
        if (rv[index].thirdParties.findIndex(tp => c.id === tp) < 0) {
          rv[index].value++;
          rv[index].thirdParties.push(c.id);
        }
      }
      return rv;
    }, []);
  }

  thirdPartyStatusBySectorsChartData(thirdParties: ThirdParty[]) {
    this.thirdPartyStatusBySectorsChart.data = thirdParties.reduce((rv, tp) => {
      let index = rv.findIndex(g => g.category === tp.thirdPartyConfig.Sector?.Description);
      if (index < 0 && tp.thirdPartyConfig.Sector !== null) {
        rv.push({
          category: tp.thirdPartyConfig.Sector.Description,
          pendingThirdParties: 0,
          inProgressThirdParties: 0,
          approvedThirdParties: 0,
          rejectedThirdParties: 0
        });
        index = rv.length - 1;
      }
      if (tp.thirdPartyConfig.Sector !== null) {
        switch (tp.status) {
          case ThirdPartyStatus.pending:
            rv[index].pendingThirdParties++;
            break;
          case ThirdPartyStatus.inProgress:
            rv[index].inProgressThirdParties++;
            break;
          case ThirdPartyStatus.approved:
            rv[index].approvedThirdParties++;
            break;
          case ThirdPartyStatus.rejected:
            rv[index].rejectedThirdParties++;
            break;
          default:
            break;
        }
      }
      return rv;
    }, []);
  }

  thirdPartyStatusByCountryChartData(thirdParties: ThirdParty[]) {
    this.thirdPartyStatusByCountryChart.data = thirdParties.reduce((rv, tp) => {
      let index = rv.findIndex(g => g.category === tp.country?.description);
      if (index < 0 && tp.country !== null) {
        rv.push({
          category: tp.country.description,
          pendingThirdParties: 0,
          inProgressThirdParties: 0,
          approvedThirdParties: 0,
          rejectedThirdParties: 0
        });
        index = rv.length - 1;
      }
      if (tp.country !== null) {
        switch (tp.status) {
          case ThirdPartyStatus.pending:
            rv[index].pendingThirdParties++;
            break;
          case ThirdPartyStatus.inProgress:
            rv[index].inProgressThirdParties++;
            break;
          case ThirdPartyStatus.approved:
            rv[index].approvedThirdParties++;
            break;
          case ThirdPartyStatus.rejected:
            rv[index].rejectedThirdParties++;
            break;
          default:
            break;
        }
      }
      return rv;
    }, []);
  }

}
