<div class="regions">
  <p id="keyData" class="regions__legend u-mt-1medium">{{ "regions.title" | translate }}</p>
  <section class="c-table">
    <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "regions.type" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.type }}
        </td>
      </ng-container>
      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "regions.country" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.country }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sanctions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "regions.sanctions" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.sanctions }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">{{'regions.noResults' | translate}} </td>
      </tr>
    </table>
    <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems"
      class="c-pagination">
    </mat-paginator>
  </section>
</div>
