import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { formatearFecha } from '@app/utilities/utilities'

@Component({
  selector: 'app-dialog-confirm-import-third-to-campaign',
  templateUrl: './dialog-confirm-import-third-to-campaign.component.html'
})
export class DialogConfirmImportThirdToCampaignComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogConfirmImportThirdToCampaignComponent>,
    @Inject(MAT_DIALOG_DATA)public data: {
      CampaignName: string,
      CountThirdParties: number,
      CountNewThirdParties: number,
      StartDate: Date,     
      QuestionaryName: string      
    }) { }

  public dateTimeStart: string;

  onNoClick(): void {
    this.dialogRef.close();
  }

  finishCreate():void{
    this.dialogRef.close(true); 
  }

  ngOnInit(): void {
    this.dateTimeStart = formatearFecha(this.data.StartDate);
  }

}
