import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { CampaignCreateDTO } from '@app/models/Campaign';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { QuestionaryDTO } from '@app/models/Questionary';
import { QuestionariesService } from '@app/services/questionaries-service';
import { MatDialog } from '@angular/material/dialog';
import { ThirdPartyDTO } from '@app/models/ThirdParty';
import { CampaignService } from '@app/services/campaign-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Toaster } from 'ngx-toast-notifications';
import { parsearErroresAPI } from '@app/utilities/utilities';
import { ActiveCampaignService } from '../../../services/active-campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemePalette } from '@angular/material/core';
import { CampaignUpdateDTO } from '@app/models/Campaign';
import { DialogDeleteCampaignComponent } from '../dialog-delete-campaign/dialog-delete-campaign.component';
import { AwsS3Service } from '@app/services/awsS3.service';
import { DialogSendCampaignComponent } from '../dialog-send-campaign/dialog-send-campaign.component';
import { RemoteService } from '@app/services/remote.service';
import { ThirdPartyTypeDTO } from '@app/models/ThirdPartyType';
import { MatTabGroup, MatTabHeader } from '@angular/material/tabs';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})
export class CreateCampaignComponent implements OnInit, AfterViewChecked {

  @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;

  errores: string[] = [];

  controlStep = 1;

  model: any;
  selectdFile: any;
  selectedFileName: any;
  SelectedFileUri: string;
  uploadButtonText: string;
  selectedRows: ThirdPartyDTO[] = [];
  creatingCampaign = false;

  form = this.formBuilder.group({
    Name: ['', { validators: [Validators.required], },],
    StartDate: ['', { validators: [Validators.required], },],
    EndDate: ['', { validators: [Validators.required], },],
    Questionary: ['', { validators: [Validators.required], },],
    Amount: ['', { validators: [Validators.required], },],
    Introduction: ['', { validators: [Validators.required], },],
    Doc: new FormControl(null, []),
    DiscardThreshold: [{ value: '', disabled: true }, { validators: [], },],
    ApproveThreshold: [{ value: '', disabled: true }, { validators: [], },]
  }, {
    validator: this.dateLessThan('StartDate', 'EndDate')
  });

  isSubmitted = false;
  enableAutomatizationDeny = false;
  enableAutomatizationApprove = false;

  questionariesNoSelected: QuestionaryDTO[] = [];

  campaignId = 0;
  thirdPartyTypes: any = [];
  thirdParties: ThirdPartyDTO[];

  private assessmentCreatedMessage: string;
  private assessmentUpdatedMessage: string;
  private assessmentDeletedMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private questionaryService: QuestionariesService,
    public dialog: MatDialog,
    private router: Router,
    private toaster: Toaster,
    public activeCampaign: ActiveCampaignService,
    private translate: TranslateService,
    private campaignService: CampaignService,
    private remote: RemoteService,
    private awsS3Service: AwsS3Service
  ) { }

  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDateStart: Date = new Date();
  public minDateEnd: Date = new Date();
  public maxDateStart: Date = new Date();
  public maxDateEnd: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public color: ThemePalette = 'primary';
  public disableMinute = false;
  public hideTime = false;
  public canRemove = false;

  ngOnInit(): void {
    this.minDateStart = new Date();
    this.minDateStart.setMinutes(new Date().getMinutes() + 5);
    this.minDateEnd = new Date();
    this.minDateEnd.setDate(new Date().getDate() + 1);

    this.translate.get(['NuevaCampañaConfig.introtext.valor', 'NuevaCampañaConfig.load.valor']).subscribe((translation) => {
      this.form.get('Introduction').setValue(translation['NuevaCampañaConfig.introtext.valor']);
      this.uploadButtonText = translation['NuevaCampañaConfig.load.valor'];
      this.getQuestionaries();
    });

    this.translate.get('NuevaCampaña.assessmentCreated')
      .subscribe(translation => {
        this.assessmentCreatedMessage = translation;
      });
    this.translate.get('NuevaCampaña.assessmentUpdated')
      .subscribe(translation => {
        this.assessmentUpdatedMessage = translation;
      });
    this.translate.get('NuevaCampaña.assessmentDeleted')
      .subscribe(translation => {
        this.assessmentDeletedMessage = translation;
      });

    this.activatedRoute.params.subscribe(p => {
      if (p.cid! > 0) {
        this.campaignId = p.cid;
        this.getCampaignData(p.cid);
        this.activeCampaign.activeCampaign(p.cid);
      } else {
        this.activeCampaign.activeCampaign(0);
      }
      Promise.all([this.remote.getRequest("Commons/ThirdPartyType"), this.remote.getRequest("ThirdParties")])
        .then(values => {

          let [thirdPartyTypes, thirdParties] = values;

          this.thirdPartyTypes.push({ id: null, 'name': 'All', code: 'All', 'checked': true })
          thirdPartyTypes.map((element: ThirdPartyTypeDTO) => {
            this.thirdPartyTypes.push({ id: element.Id, description: element.Description, descriptionEN: element.DescriptionEN, descriptionPT: element.DescriptionPT, descriptionFR: element.DescriptionFR, code: element.Code, 'checked': false })
          });

          this.thirdParties = thirdParties;
        });
    });
  }

  ngAfterViewChecked(): void {
    (this.tabGroup?._tabHeader as MatTabHeader).updatePagination();
  }

  public getThirdPartiesWithThirdPartyTypeId(thirdPartyTypeId: number): ThirdPartyDTO[] {
    if (!thirdPartyTypeId) {
      return this.thirdParties;
    }

    return this.thirdParties?.filter(tp => tp.ThirdPartyConfig.ThirdPartyType?.Id === thirdPartyTypeId);
  }

  public getCampaignData(id: string) {
    this.campaignService.getCampaignById(id).then(response => {
      if (response.FileUri !== null || response.FileName !== null) {
        this.SelectedFileUri = response.FileUri;
        this.selectedFileName = response.FileName;
        this.selectdFile = null;
      } else {
        this.SelectedFileUri = null;
        this.selectedFileName = null;
        this.selectdFile = null;
      }

      this.enableAutomatizationDeny = !!response.DiscardThreshold;
      this.enableAutomatizationApprove = !!response.ApproveThreshold;

      this.form.get('Name').setValue(response.Name);
      this.form.get('Amount').setValue(response.Amount);
      this.form.get('StartDate').setValue(new Date(response.StartDate));
      this.form.get('EndDate').setValue(new Date(response.EndDate));
      this.form.get('Questionary').patchValue(response.Questionary);
      this.form.get('Doc').setValue({ 'FileUri': this.SelectedFileUri, 'FileName': this.selectedFileName, 'File': this.selectdFile });
      this.canRemove = response.CanRemove;
      if (this.enableAutomatizationDeny) {
        this.form.get('DiscardThreshold').setValue(response.DiscardThreshold);
        this.form.get('DiscardThreshold').setValidators([Validators.required, Validators.max(5), Validators.min(1)]);
        this.form.get('DiscardThreshold').enable();
      }

      if (this.enableAutomatizationApprove) {
        this.form.get('ApproveThreshold').setValue(response.ApproveThreshold);
        this.form.get('ApproveThreshold').setValidators([Validators.required, Validators.max(5), Validators.min(1)]);
        this.form.get('ApproveThreshold').enable();
      }

      this.model = response as CampaignCreateDTO;
    });
  }

  public selectRows(selectRows: ThirdPartyDTO[]) {
    this.selectedRows = selectRows;
  }

  public changeStep(val: number) {
    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    this.controlStep = val;
  }

  public getQuestionaries() {
    this.questionaryService.getAllQuestionary().then(response => {
      this.questionariesNoSelected = response as QuestionaryDTO[];
    });
  }

  get Questionary() {
    return this.form.get('Questionary');
  }

  // abrir modal
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogSendCampaignComponent, {
      data: {
        CampaignName: this.model.Name,
        CountThirdParties: this.model.ThirdParties.length,
        StartDate: this.model.StartDate,
        EndDate: this.model.EndDate,
        QuestionaryName: this.model.Questionary.Code,
        Introduction: this.model.Introduction,
        Amount: this.model.Amount
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.creatingCampaign = true;
        this.model.DiscardThreshold = this.enableAutomatizationDeny ? this.model.DiscardThreshold : null;
        this.model.ApproveThreshold = this.enableAutomatizationApprove ? this.model.ApproveThreshold : null;
        if (this.selectdFile) {
          this.campaignService.getFileStorageKey(this.selectedFileName, this.model.Name)
            .then(r => {
              this.model.FileUri = r.FileId;
              this.awsS3Service.uploadFileToS3(r.FileUri, this.selectdFile).subscribe(r => {
                this.createCampaign();
              }, errores => {
                this.creatingCampaign = false;
                this.errores = parsearErroresAPI(errores);
              });
            }).finally(() => {
              this.creatingCampaign = false;
            });
        } else {
          this.createCampaign();
        }
      }
    });
  }

  private createCampaign() {
    const dataToSend = {...this.model, File: null};
    this.campaignService.createCampaign(dataToSend).subscribe(response => {
      this.router.navigate(['//evaluacion-enviada/' + `/${response}`]);
      this.toaster.open({ text: this.assessmentCreatedMessage, duration: 6000, type: 'info' });
    }, errores => {
      this.creatingCampaign = false;
      this.errores = parsearErroresAPI(errores);
    });
  }

  saveChanges() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach(control => {
        control.markAsTouched();
      });
    }

    if (this.campaignId === 0) {
      // CREATE
      this.model = this.form.value as CampaignCreateDTO;
      this.model.ThirdParties = [] as ThirdPartyDTO[];
      this.selectedRows.forEach(row => {
        this.model.ThirdParties.push(row);
      });
      if (this.selectdFile != null) {
        this.model.File = this.selectdFile;
        this.model.FileName = this.selectedFileName;
      }
      this.openDialog();
    } else {
      // UPDATE
      this.model = this.form.value as CampaignUpdateDTO;
      this.model.ThirdParties = [] as ThirdPartyDTO[];
      this.selectedRows.forEach(row => {
        this.model.ThirdParties.push(row);
      });

      if (this.selectdFile != null) {
        this.model.File = this.selectdFile;
        this.model.FileName = this.selectedFileName;
      } else {
        this.model.FileName = this.selectedFileName;
        this.model.FileUri = this.SelectedFileUri;
      }

      this.campaignService.updateCampaign(this.campaignId, this.model)
        .subscribe(response => {
          this.router.navigate(['/evaluaciones']);
          this.toaster.open({ text: this.assessmentUpdatedMessage, duration: 6000, type: 'info' });
          this.activeCampaign.closeCampaignToCampaigns();
        }, errores => this.errores = parsearErroresAPI(errores));
    }
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (f.value !== '' && t.value !== '' && f.value > t.value) {
        return {
          dates: 'La fecha de inicio no puede ser mayor que la de fin.'
        };
      }
      return {};
    };
  }

  selectDocument(resp) {
    this.selectdFile = resp.file;
    this.selectedFileName = resp.file.name;
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (option && value) {
      return option.Id === value.Id;
    } else {
      return false;
    }
  }

  openDialogDeleteCampaign() {
    this.model.StartDate = new Date(this.model.StartDate);
    this.model.EndDate = new Date(this.model.EndDate);

    const dialogRef = this.dialog.open(
      DialogDeleteCampaignComponent,
      {
        data: {
          CampaignName: this.model.Name,
          CountThirdParties: this.model.ThirdParties.length,
          StartDate: this.model.StartDate,
          EndDate: this.model.EndDate,
          QuestionaryName: this.model.Questionary.Code,
          Introduction: this.model.Introduction,
          Amount: this.model.Amount
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.campaignService.deleteCampaign(this.model.Id.toString()).subscribe(response => {
          this.router.navigate(['/evaluaciones']);
          this.toaster.open({ text: this.assessmentDeletedMessage, duration: 6000, type: 'info' });
        }, errores => this.errores = parsearErroresAPI(errores));
      }
    });
  }

  slideChange() {
    if (this.enableAutomatizationDeny) {
      this.form.get('DiscardThreshold').setValidators([Validators.required, Validators.max(5), Validators.min(1)]);
      this.form.get('DiscardThreshold').enable();
    } else {
      this.form.get('DiscardThreshold').clearValidators();
      this.form.get('DiscardThreshold').disable();
    }
  }

  slideChangeApprove() {
    if (this.enableAutomatizationApprove) {
      this.form.get('ApproveThreshold').setValidators([Validators.required, Validators.max(5), Validators.min(1)]);
      this.form.get('ApproveThreshold').enable();
    } else {
      this.form.get('ApproveThreshold').clearValidators();
      this.form.get('ApproveThreshold').disable();
    }
  }
}
