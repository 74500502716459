<div class="marital-status">
  <p id="keyData" class="marital-status__legend u-mt-1medium">{{ "EquifaxWSMaritalStaus.title" | translate }}</p>
  <p id="keyData" class="marital-status__sublegend u-mt-1medium">{{ "EquifaxWSMaritalStaus.currentMaritalStatus" | translate }}</p>
  <equifax-ws-individual-marital-status-data [maritalStatus]="individualDetails?.currentMaritalStatus"></equifax-ws-individual-marital-status-data>
  <p id="keyData" class="marital-status__sublegend u-mt-1medium">{{ "EquifaxWSMaritalStaus.maritalStatusHistory" | translate }}</p>
  <ng-container *ngFor="let maritalStatus of individualDetails?.maritalStatusHistoric">
    <equifax-ws-individual-marital-status-data [maritalStatus]="maritalStatus"></equifax-ws-individual-marital-status-data>
  </ng-container>


</div>
