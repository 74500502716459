<a *ngIf="actions.includes('editar')" (click)='onEditTemplate()' class="c-table__watch" data-test-id="edit-link">
    <ng-container *ngIf="questionary.IsMaster || (isQuestionaryInUse$ | async)">
        <span class="c-box-edit__button-ico" aria-hidden="true">
            <span class="c-box-edit__button-ico" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12.747">
                    <path d="m19.873 98.82c-.179-.244-4.436-5.985-9.873-5.985s-9.695 5.74-9.873 5.984a.66 .66 0 0 0 0 .778c.178.244 4.436 5.985 9.873 5.985s9.694-5.74 9.873-5.984a.659 .659 0 0 0 0 -.778m-9.873 5.444c-4 0-7.474-3.81-8.5-5.05 1.026-1.247 4.487-5.06 8.5-5.06s7.473 3.809 8.5 5.06c-1.025 1.247-4.486 5.05-8.5 5.05" transform="translate(0-92.83)"/>
                    <path d="M158.678 154.725a3.956 3.956 0 1 0 3.956 3.956A3.961 3.961 0 0 0 158.678 154.725m0 6.593a2.637 2.637 0 1 1 2.637 -2.637A2.64 2.64 0 0 1 158.678 161.318" transform="translate(-148.68-152.3)"/>
                </svg>
            </span>
        </span>
        <span>{{'TablaPlantillas.action.ver.valor' | translate}}</span>
    </ng-container>
    <ng-container *ngIf="!questionary.IsMaster && !(isQuestionaryInUse$ | async)">
        <ng-container *appAuthRole="['Owner','Manager']">
            <span class="c-box-edit__button-ico" aria-hidden="true">
                <span class="c-box-edit__button-ico" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path
                            d="M16.38.782,6.22,10.942A4.138,4.138,0,0,0,5,13.888v1.119a.833.833,0,0,0,.833.833H6.952A4.138,4.138,0,0,0,9.9,14.621l10.16-10.16a2.6,2.6,0,0,0,0-3.678,2.662,2.662,0,0,0-3.678,0Zm2.5,2.5L8.72,13.442a2.517,2.517,0,0,1-1.767.732H6.667v-.286A2.517,2.517,0,0,1,7.4,12.121l10.16-10.16a.957.957,0,0,1,1.322,0,.936.936,0,0,1,0,1.322Z"
                            transform="translate(-.833-.008)" />
                        <path
                            d="M19.167,7.482a.833.833,0,0,0-.833.833V12.5H15A2.5,2.5,0,0,0,12.5,15v3.333H4.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5H11.7A.833.833,0,0,0,11.7,0H4.167A4.172,4.172,0,0,0,0,4.167V15.833A4.172,4.172,0,0,0,4.167,20h9.452a4.14,4.14,0,0,0,2.947-1.22l2.213-2.215A4.14,4.14,0,0,0,20,13.619v-5.3A.833.833,0,0,0,19.167,7.482ZM15.387,17.6a2.479,2.479,0,0,1-1.221.667V15A.833.833,0,0,1,15,14.167h3.271a2.513,2.513,0,0,1-.667,1.22Z" />
                    </svg>
                </span>
            </span>
            <span>{{'TablaPlantillas.action.edit.valor' | translate}}</span>
        </ng-container>
        <ng-container *appAuthRole="['Auditor']">
            <span class="c-box-edit__button-ico" aria-hidden="true">
                <span class="c-box-edit__button-ico" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12.747">
                        <path d="m19.873 98.82c-.179-.244-4.436-5.985-9.873-5.985s-9.695 5.74-9.873 5.984a.66 .66 0 0 0 0 .778c.178.244 4.436 5.985 9.873 5.985s9.694-5.74 9.873-5.984a.659 .659 0 0 0 0 -.778m-9.873 5.444c-4 0-7.474-3.81-8.5-5.05 1.026-1.247 4.487-5.06 8.5-5.06s7.473 3.809 8.5 5.06c-1.025 1.247-4.486 5.05-8.5 5.05" transform="translate(0-92.83)"/>
                        <path d="M158.678 154.725a3.956 3.956 0 1 0 3.956 3.956A3.961 3.961 0 0 0 158.678 154.725m0 6.593a2.637 2.637 0 1 1 2.637 -2.637A2.64 2.64 0 0 1 158.678 161.318" transform="translate(-148.68-152.3)"/>
                    </svg>
                </span>
            </span>
            <span>{{'TablaPlantillas.action.ver.valor' | translate}}</span>
        </ng-container>
    </ng-container>
</a>
<ng-container *appAuthRole="['Owner','Manager']">
    <a *ngIf="actions.includes('duplicar')" (click)="onDuplicateTemplate()" class="c-table__watch" data-test-id="duplicate-link">
        <span class="c-box-edit__button-ico" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20">
                <defs>
                    <style>
                        .a { fill: url(#a); }
                        .b { fill: none; }
                    </style>
                    <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#fdc130" />
                        <stop offset="1" stop-color="#faa868" />
                    </linearGradient>
                </defs>
                <path class="a"
                    d="M11.167,16.667A4.172,4.172,0,0,0,15.333,12.5V5.2a3.31,3.31,0,0,0-.977-2.357L12.488.977A3.31,3.31,0,0,0,10.131,0H6.167A4.172,4.172,0,0,0,2,4.167V12.5a4.172,4.172,0,0,0,4.167,4.167ZM3.667,12.5V4.167a2.5,2.5,0,0,1,2.5-2.5s4.1.012,4.167.02V3.333A1.667,1.667,0,0,0,12,5h1.647c.008.068.02,7.5.02,7.5a2.5,2.5,0,0,1-2.5,2.5h-5a2.5,2.5,0,0,1-2.5-2.5Zm15-5.833v9.167A4.172,4.172,0,0,1,14.5,20H7a.833.833,0,1,1,0-1.667h7.5a2.5,2.5,0,0,0,2.5-2.5V6.667a.833.833,0,0,1,1.667,0Z"
                    transform="translate(0 0)" />
                <rect class="b" width="20" height="20" />
            </svg>
        </span>
        <span *ngIf="!questionary.IsMaster">{{'TablaPlantillas.action.duplicate.valor' | translate}}</span>
        <span *ngIf="questionary.IsMaster">{{'TablaPlantillas.action.duplicateMaster.valor' | translate}}</span>
    </a>
</ng-container>
