import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RiskListProxyClient } from 'src/proxy/apiRiskList/riskList.proxy';
import { Campaign } from "@app/models/Campaign";
import { mapCampaign } from './mappings/campaign.mappings';
import { TranslateService } from '@ngx-translate/core';
import { RiskThirdPartyDto } from 'src/proxy/model/riskThirdPartyDto';

@Injectable({
    providedIn: 'root'
})
export class RiskListService {

    constructor(private riskListClient: RiskListProxyClient, private translate: TranslateService){}

    getPendingRiskLists(): Observable<Campaign[]> {
        return this.riskListClient.riskListGetAllPendingsGet().pipe(map(r => r.map(c => mapCampaign(c, this.translate.currentLang))));
    }

    getAllRiskLists(): Observable<Campaign[]> {
        return this.riskListClient.riskListGetAllLaunchedGet().pipe(map(r => r.map(c => mapCampaign(c, this.translate.currentLang))));
    }
}
