<div class="basic-data">
  <p id="keyData" class="basic-data__legend u-mt-1medium">{{ "EquifaxWSGeneralData.title" | translate }}</p>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="name" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.name" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="name">
        {{ scoringDetails?.basicData?.name || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="lastname" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.lastname" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="lastname">
        {{ scoringDetails?.basicData?.lastname || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="lastname2" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.lastname2" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="lastname2">
        {{ scoringDetails?.basicData?.lastname2 || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="cedula" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.cedula" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="cedula">
        {{ scoringDetails?.basicData?.cedula || "" }}
      </p>
    </div>
  </div>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="cedulaExpDate" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.cedulaExp" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="cedulaExpDate">
        {{ scoringDetails?.basicData?.cedulaExpDate || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="birthPlace" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.birthPlace" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="birthPlace">
        {{ scoringDetails?.basicData?.birthPlace || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="birthDate" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.birthDate" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="birthDate">
        {{ scoringDetails?.basicData?.birthDate || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="age" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.age" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="age">
        {{ scoringDetails?.basicData?.age || "" }}
      </p>
    </div>
  </div>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="gender" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.gender" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="gender">
        {{ scoringDetails?.basicData?.gender || "" }}
      </p>
    </div>
  </div>
  <p id="keyData" class="electoral-data__legend u-mt-1medium">{{ "EquifaxWSGeneralData.electoralData" | translate }}</p>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="province" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.province" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="province">
        {{ scoringDetails?.basicData?.electoralProvince || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="canton" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.canton" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="canton">
        {{ scoringDetails?.basicData?.electoralCanton || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="electoralDistrict" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.electoralDistrict" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="electoralDistrict">
        {{ scoringDetails?.basicData?.electoralDistrict || "" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="administrativeDistrict" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.administrativeDistrict" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="administrativeDistrict">
        {{ scoringDetails?.basicData?.administrativeDistrict || "" }}
      </p>
    </div>
  </div>
  <p id="keyData" class="electoral-data__legend u-mt-1medium">{{ "EquifaxWSGeneralData.relativesData" | translate }}</p>
  <equifax-ws-individual-relative-data *ngIf="scoringDetails?.mother" [relative]="scoringDetails?.mother"></equifax-ws-individual-relative-data>
  <equifax-ws-individual-relative-data *ngIf="scoringDetails?.father" [relative]="scoringDetails?.father"></equifax-ws-individual-relative-data>
  <p id="keyData" class="electoral-data__legend u-mt-1medium">{{ "EquifaxWSGeneralData.underAgeOffspring" | translate }}</p>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="under6" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.under6" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="under6">
        {{ under6 }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="from6to12" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.from6to12" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="from6to12">
        {{ from6to12 }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="from13to17" class="basic-data-item__title">
        {{ "EquifaxWSGeneralData.from13to17" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="from13to17">
        {{ from13to17 }}
      </p>
    </div>
  </div>
</div>
