import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Campaign, CampaignQueryResourceDto } from '@app/models/Campaign'
import { CampaignService } from '@app/services/campaign-service';
import { MatPaginator } from '@angular/material/paginator';
import { QueryResult } from '@app/models/common/queryResult';

@Component({
  selector: 'app-campaign-table',
  templateUrl: './campaign-table.component.html',
  styleUrls: ['./campaign-table.component.scss']
})
export class CampaignTableComponent implements OnInit, OnDestroy {

    @Input() statusPreFilter: string;
    @ViewChild('paginator') paginator: MatPaginator;

    isLoading: boolean;
    locale: string;
    private subscription: Subscription;

    queryResult: QueryResult<Campaign>;
    query: CampaignQueryResourceDto = {
        PageSize: 5,
        SortBy: 'endDate',
        IsSortAscending: false,
        Page: 1,
        SearchWord: '',
        Status: ''
    };

    tableColumns = ['name','startDate','endDate','numThirdParties','responsible','action'];

    constructor(private campaignService: CampaignService,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        this.locale = this.translate.currentLang.split("-")[0];

        this.subscription = this.translate.onLangChange
            .subscribe((langChangeEvent: LangChangeEvent) => {
            this.locale = langChangeEvent.lang.split("-")[0];
            });
        this.query.Status = this.statusPreFilter;
        this.isLoading = false;
        this.getCampaigns();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getCampaigns() {
        this.isLoading = true;
        this.campaignService.getAllCampaignFilter(this.query)
            .subscribe(result => {
                this.queryResult = result;
                this.onPageLoadEnd();
            });
    }

    handleFilterChange(filterValue: string) {
        this.query.SearchWord = filterValue.trim().toLowerCase();
        this.query.Page = 1; 
        this.getCampaigns();
    }

    sortBy(columnName) {
        if (this.query.SortBy === columnName.active) {
            this.query.IsSortAscending = !this.query.IsSortAscending; 
        } else {
            this.query.SortBy = columnName.active;
            this.query.IsSortAscending = true;
        }
        this.getCampaigns();
    }

    onPageChange(page) {
        this.query.Page = page.pageIndex + 1;
        this.query.PageSize = page.pageSize;
        this.getCampaigns();
    }

    private onPageLoadEnd() {
        this.isLoading = false;
        this.paginator.pageIndex = this.query.Page - 1;
    }
}
