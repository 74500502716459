import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { RiskThirdPartyDto } from 'src/proxy/model/riskThirdPartyDto';
import { CommonsProxyClient } from 'src/proxy/apiCommons/commons.proxy';
import { ActivityTypeDto } from 'src/proxy/model/activityTypeDto';
import { Sector } from '@app/models/Sector';
import { mapSectorDtoToSector } from './mappings/third-party.mappings';
import { CountryDto } from 'src/proxy/model/countryDto';

@Injectable({
    providedIn: 'root'
})
export class ThirdPartySignUpService {

    constructor(private commons: CommonsProxyClient, private translate: TranslateService){}

    getCountries(): Observable<CountryDto[]> {
        return this.commons.commonsCountryGet();
    }

    getActivityTypes(clientId: number): Observable<ActivityTypeDto[]> {
        return this.commons.commonsActivityTypeListClientIdGet(clientId);
    }

    getSectors(clientId: number): Observable<Sector[]> {
      return this.commons.commonsGetSectorListClientIdGet(clientId)
          .pipe(map(sl => sl.map(s => mapSectorDtoToSector(s))));
    }
}
