<section class="c-table u-mt-1">
  <div class="c-table__top">
    <div class="row">
      <div class="col-7 table-title">
        <h2>{{ "ThirdPartyScreeningTable.screeningList" | translate }}</h2>
      </div>
      <div *appAuthRole="['Owner', 'Manager' ,'Assistant', 'Collaborator']" class="col-5 d-flex justify-content-end align-items-center">
        <a [routerLink]="" (click)="onNewScoring()" class="c-button c-button--large c-button--gradient"
          style="cursor: pointer"
        >
          + {{ "ThirdPartyScreeningTable.NewScreening.valor" | translate }}
        </a>
      </div>
    </div>
  </div>
  <div>
    <div class="c-table__bottom">
      <mat-accordion>
        <ng-content></ng-content>
      </mat-accordion>
    </div>
  </div>
</section>
