import { Component } from '@angular/core';

@Component({
  selector: 'app-onboarding-fill-card',
  templateUrl: './onboarding-fill-card.component.html',
  styleUrls: ['./onboarding-fill-card.component.scss']
})
export class OnboardingFillCardComponent {

  constructor() { }
}
