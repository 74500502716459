<section class="c-login">
    <div class="c-login__wrapper">
        <!--parte izquierda - imagen-->
        <div class="c-login__left" aria-hidden="true">
            <div class="c-login-header">
                <div class="c-login-header__image" aria-hidden="true">
                    <img src="./assets/image-logo.svg" alt="" loading="lazy" />
                </div>
            </div>
            <div class="c-login__opacity"></div>
        </div>
        <!--parte derecha - form-->
        <div class="c-login__right">
            <div class="c-login__right-top">
                <h2 class="c-login__subtitle">{{'ResetPassword.title' | translate}}</h2>
                <p class="o-text">{{'ResetPassword.text' | translate}}</p>
                <form [formGroup]="resetPasswordForm" class="c-login-form" data-test-id="passwords-form">
                    <fieldset>
                        <legend class="u-sr-only">{{'ResetPassword.changePassword' | translate}}</legend>
                        <!--contraseña-->
                        <div class="c-login-form__block">
                            <mat-form-field>
                                <input formControlName="password" type="{{typePassword ? 'text' : 'password' }}" matInput id="password" class="c-login__input" aria-errormessage="e" placeholder="{{'ResetPassword.passwordPlaceholder' | translate}}" data-test-id="password-field" />
                                <button type="button" (click)="togglePassword()" class="c-login__toggle-pass" attr.aria-label="{{'ResetPassword.ariaLabel' | translate}}" data-test-id="toggle-password-visibility">
                                    <span *ngIf = "!isShow" aria-hidden="true">
                                        <img src="assets/ico-eye-close.svg" alt="" loading="lazy" />
                                    </span>
                                    <span *ngIf = "isShow" aria-hidden="true">
                                        <img src="assets/ico-eye-open.svg" alt="" loading="lazy" />
                                    </span>
                                </button>
                            </mat-form-field>
                            <div *ngIf="password.invalid && password.touched">
                                <p role="alert" *ngIf="password.errors.required" id="e" class="c-form__message-error">{{'ResetPassword.changePassword' | translate}}</p>
                                <p role="alert" *ngIf="password.errors.invalidLength" id="e" class="c-form__message-error">{{'ResetPassword.invalidLength' | translate}}</p>
                                <p role="alert" *ngIf="password.errors.mustContainUpper" id="e" class="c-form__message-error">{{'ResetPassword.mustContainUpper' | translate}}</p>
                                <p role="alert" *ngIf="password.errors.mustContainLower" id="e" class="c-form__message-error">{{'ResetPassword.mustContainLower' | translate}}</p>
                                <p role="alert" *ngIf="password.errors.mustContainNumber" id="e" class="c-form__message-error">{{'ResetPassword.mustContainNumber' | translate}}</p>
                                <p role="alert" *ngIf="password.errors.mustContainSpecial" id="e" class="c-form__message-error">{{'ResetPassword.mustContainSpecial' | translate}}</p>
                            </div>
                        </div>
                        <!--repetir contraseña-->
                        <div class="c-login-form__block">
                            <mat-form-field>
                                <input type="{{typePasswordRepeat ? 'text' : 'password' }}" matInput  id="password-again" class="c-login__input" formControlName="passwordConfirm" aria-errormessage="er" placeholder="{{'ResetPassword.passwordRepeatPlaceholder' | translate}}" data-test-id="repeat-password-field" />
                                <button type="button" (click)="togglePasswordRepeat()" class="c-login__toggle-pass" attr.aria-label="{{'ResetPassword.toggleShowPassword' | translate}}" data-test-id="toggle-repeated-password-visibility" >
                                    <span *ngIf = "!isShowRepeat" aria-hidden="true">
                                        <img src="assets/ico-eye-close.svg" alt="" loading="lazy" />
                                    </span>
                                    <span *ngIf = "isShowRepeat" aria-hidden="true">
                                        <img src="assets/ico-eye-open.svg" alt="" loading="lazy" />
                                    </span>
                                </button>
                            </mat-form-field>
                            <div role="alert" *ngIf="passwordConfirm.invalid && password.touched" class="c-form__message-error" aria-labelledby="password-again">
                                <p *ngIf="passwordConfirm.errors.required" id="er">{{'ResetPassword.requiredField' | translate}}</p>
                            </div>  
                        </div>
                        <p role="alert" *ngIf="resetPasswordForm.errors?.passwordsDifferent && (resetPasswordForm.touched || resetPasswordForm.dirty)" class="c-form__message-error text-center">
                            {{'ResetPassword.passwordsDoNotMatch' | translate}}
                        </p>
                        <!--aceptar-->
                        <button type="submit" [disabled]="resetPasswordForm.status != 'VALID'" (click)="changePassword()" class="c-button" data-test-id="submit-button">{{'ResetPassword.accept' | translate}}</button>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</section>