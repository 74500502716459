import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Usage } from '@app/models/Usage';
import { UsageService } from '@app/services/usage.service';
import { forkJoin } from 'rxjs';
import { DialogExpandQuotasComponent } from '../dialog-expand-quotas/dialog-expand-quotas.component';

@Component({
  selector: 'app-consumption-usage',
  templateUrl: './consumption-usage.component.html',
  styleUrls: ['./consumption-usage.component.scss']
})
export class ConsumptionUsageComponent implements OnInit {
  thirdPartyUsage: Usage;
  refinitivUsage: Usage;
  amlUpdateUsage: Usage;
  equifaxUsage: Usage;
  memberCheckUsage: Usage;
  assessmentsLaunched: number;
  loading: boolean = true;

  constructor(private usageService: UsageService, private dialog: MatDialog) { }

  ngOnInit(): void {
    forkJoin({
      thirdParty: this.usageService.thirdPartyUsage(),
      refinitiv: this.usageService.refinitivApiUsage(),
      assessments: this.usageService.assessmentUsage(),
      amlUpdate: this.usageService.amlUpdateApiUsage(),
      equifax: this.usageService.equifaxApiUsage(),
      memberCheck: this.usageService.memberCheckUsage()
    })
      .subscribe(({ thirdParty, refinitiv, assessments, amlUpdate, equifax, memberCheck }) => {
        this.thirdPartyUsage = thirdParty;
        this.refinitivUsage = refinitiv;
        this.assessmentsLaunched = assessments;
        this.amlUpdateUsage = amlUpdate;
        this.equifaxUsage = equifax;
        this.memberCheckUsage = memberCheck;

        this.loading = false;
      });
  }

  openExpandQuotasDialog() {
    this.dialog.open(DialogExpandQuotasComponent, {});
  }
}
