/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BulkStatusUpdateDto } from '../model/bulkStatusUpdateDto';
import { ThirdPartyConfigurationRequestDto } from '../model/thirdPartyConfigurationRequestDto';
import { ThirdPartyCreateDto } from '../model/thirdPartyCreateDto';
import { ThirdPartyDto } from '../model/thirdPartyDto';
import { ThirdPartyDtoQueryResultResource } from '../model/thirdPartyDtoQueryResultResource';
import { ThirdPartyFileDto } from '../model/thirdPartyFileDto';
import { ThirdPartyStatusHistoryRecordDto } from '../model/thirdPartyStatusHistoryRecordDto';
import { ThirdPartyUpdateDto } from '../model/thirdPartyUpdateDto';

import { Configuration } from '../configuration';

@Injectable()
export class ThirdPartiesProxyClient {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject('API_URL') basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param thirdPartyId
   * @param filename
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesAttachFileThirdPartyIdFilenameGet(
    thirdPartyId: number,
    filename: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public thirdPartiesAttachFileThirdPartyIdFilenameGet(
    thirdPartyId: number,
    filename: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public thirdPartiesAttachFileThirdPartyIdFilenameGet(
    thirdPartyId: number,
    filename: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public thirdPartiesAttachFileThirdPartyIdFilenameGet(
    thirdPartyId: number,
    filename: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (thirdPartyId === null || thirdPartyId === undefined) {
      throw new Error(
        'Required parameter thirdPartyId was null or undefined when calling thirdPartiesAttachFileThirdPartyIdFilenameGet.'
      );
    }

    if (filename === null || filename === undefined) {
      throw new Error(
        'Required parameter filename was null or undefined when calling thirdPartiesAttachFileThirdPartyIdFilenameGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<string>(
      'get',
      `${this.basePath}/ThirdParties/AttachFile/${encodeURIComponent(
        String(thirdPartyId)
      )}/${encodeURIComponent(String(filename))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param userResponsibleId
   * @param save
   * @param file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesBulkLoadPostForm(
    userResponsibleId?: string,
    save?: boolean,
    file?: Blob,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public thirdPartiesBulkLoadPostForm(
    userResponsibleId?: string,
    save?: boolean,
    file?: Blob,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public thirdPartiesBulkLoadPostForm(
    userResponsibleId?: string,
    save?: boolean,
    file?: Blob,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public thirdPartiesBulkLoadPostForm(
    userResponsibleId?: string,
    save?: boolean,
    file?: Blob,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec(),
      });
    }

    if (userResponsibleId !== undefined) {
      formParams =
        (formParams.append(
          'UserResponsibleId',
          <any>userResponsibleId
        ) as any) || formParams;
    }
    if (save !== undefined) {
      formParams = (formParams.append('Save', <any>save) as any) || formParams;
    }
    if (file !== undefined) {
      formParams = (formParams.append('File', <any>file) as any) || formParams;
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/ThirdParties/BulkLoad`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesBulkUpdateStatusPost(
    body?: BulkStatusUpdateDto,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<boolean>;
  public thirdPartiesBulkUpdateStatusPost(
    body?: BulkStatusUpdateDto,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<boolean>>;
  public thirdPartiesBulkUpdateStatusPost(
    body?: BulkStatusUpdateDto,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<boolean>>;
  public thirdPartiesBulkUpdateStatusPost(
    body?: BulkStatusUpdateDto,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<boolean>(
      'post',
      `${this.basePath}/ThirdParties/BulkUpdateStatus`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param email
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesCheckContactExistEmailGet(
    email: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ThirdPartyDto>;
  public thirdPartiesCheckContactExistEmailGet(
    email: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ThirdPartyDto>>;
  public thirdPartiesCheckContactExistEmailGet(
    email: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ThirdPartyDto>>;
  public thirdPartiesCheckContactExistEmailGet(
    email: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (email === null || email === undefined) {
      throw new Error(
        'Required parameter email was null or undefined when calling thirdPartiesCheckContactExistEmailGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ThirdPartyDto>(
      'get',
      `${this.basePath}/ThirdParties/CheckContactExist/${encodeURIComponent(
        String(email)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param idCountry
   * @param companyCif
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesCheckThirdPartyExistByClientIdCountryCompanyCifGet(
    idCountry: number,
    companyCif: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ThirdPartyDto>;
  public thirdPartiesCheckThirdPartyExistByClientIdCountryCompanyCifGet(
    idCountry: number,
    companyCif: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ThirdPartyDto>>;
  public thirdPartiesCheckThirdPartyExistByClientIdCountryCompanyCifGet(
    idCountry: number,
    companyCif: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ThirdPartyDto>>;
  public thirdPartiesCheckThirdPartyExistByClientIdCountryCompanyCifGet(
    idCountry: number,
    companyCif: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idCountry === null || idCountry === undefined) {
      throw new Error(
        'Required parameter idCountry was null or undefined when calling thirdPartiesCheckThirdPartyExistByClientIdCountryCompanyCifGet.'
      );
    }

    if (companyCif === null || companyCif === undefined) {
      throw new Error(
        'Required parameter companyCif was null or undefined when calling thirdPartiesCheckThirdPartyExistByClientIdCountryCompanyCifGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ThirdPartyDto>(
      'get',
      `${
        this.basePath
      }/ThirdParties/CheckThirdPartyExistByClient/${encodeURIComponent(
        String(idCountry)
      )}/${encodeURIComponent(String(companyCif))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param idCountry
   * @param companyCif
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesCheckThirdPartyExistIdCountryCompanyCifGet(
    idCountry: number,
    companyCif: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ThirdPartyDto>;
  public thirdPartiesCheckThirdPartyExistIdCountryCompanyCifGet(
    idCountry: number,
    companyCif: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ThirdPartyDto>>;
  public thirdPartiesCheckThirdPartyExistIdCountryCompanyCifGet(
    idCountry: number,
    companyCif: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ThirdPartyDto>>;
  public thirdPartiesCheckThirdPartyExistIdCountryCompanyCifGet(
    idCountry: number,
    companyCif: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idCountry === null || idCountry === undefined) {
      throw new Error(
        'Required parameter idCountry was null or undefined when calling thirdPartiesCheckThirdPartyExistIdCountryCompanyCifGet.'
      );
    }

    if (companyCif === null || companyCif === undefined) {
      throw new Error(
        'Required parameter companyCif was null or undefined when calling thirdPartiesCheckThirdPartyExistIdCountryCompanyCifGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ThirdPartyDto>(
      'get',
      `${this.basePath}/ThirdParties/CheckThirdPartyExist/${encodeURIComponent(
        String(idCountry)
      )}/${encodeURIComponent(String(companyCif))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesCompleteConfigRequestPost(
    body?: ThirdPartyConfigurationRequestDto,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<boolean>;
  public thirdPartiesCompleteConfigRequestPost(
    body?: ThirdPartyConfigurationRequestDto,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<boolean>>;
  public thirdPartiesCompleteConfigRequestPost(
    body?: ThirdPartyConfigurationRequestDto,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<boolean>>;
  public thirdPartiesCompleteConfigRequestPost(
    body?: ThirdPartyConfigurationRequestDto,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<boolean>(
      'post',
      `${this.basePath}/ThirdParties/CompleteConfigRequest`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesDownloadThirdPartiesTemplateGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public thirdPartiesDownloadThirdPartiesTemplateGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public thirdPartiesDownloadThirdPartiesTemplateGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public thirdPartiesDownloadThirdPartiesTemplateGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/ThirdParties/DownloadThirdPartiesTemplate`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
     *
     *
     * @param searchWord
     * @param sortBy
     * @param isSortAscending
     * @param page
     * @param thirdPartyTypeId
     * @param pageSize
     * @param status
     * @param inheritRisk
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public thirdPartiesFilteredGet(searchWord?: string, sortBy?: string, isSortAscending?: boolean, page?: number, thirdPartyTypeId?: number, pageSize?: number, status?: Array<number>, inheritRisk?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<ThirdPartyDtoQueryResultResource>;
  public thirdPartiesFilteredGet(searchWord?: string, sortBy?: string, isSortAscending?: boolean, page?: number, thirdPartyTypeId?: number, pageSize?: number, status?: Array<number>, inheritRisk?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ThirdPartyDtoQueryResultResource>>;
  public thirdPartiesFilteredGet(searchWord?: string, sortBy?: string, isSortAscending?: boolean, page?: number, thirdPartyTypeId?: number, pageSize?: number, status?: Array<number>, inheritRisk?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ThirdPartyDtoQueryResultResource>>;
  public thirdPartiesFilteredGet(searchWord?: string, sortBy?: string, isSortAscending?: boolean, page?: number, thirdPartyTypeId?: number, pageSize?: number, status?: Array<number>, inheritRisk?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      if (searchWord !== undefined && searchWord !== null) {
          queryParameters = queryParameters.set('SearchWord', <any>searchWord);
      }
      if (sortBy !== undefined && sortBy !== null) {
          queryParameters = queryParameters.set('SortBy', <any>sortBy);
      }
      if (isSortAscending !== undefined && isSortAscending !== null) {
          queryParameters = queryParameters.set('IsSortAscending', <any>isSortAscending);
      }
      if (page !== undefined && page !== null) {
          queryParameters = queryParameters.set('Page', <any>page);
      }
      if (thirdPartyTypeId !== undefined && thirdPartyTypeId !== null) {
          queryParameters = queryParameters.set('ThirdPartyTypeId', <any>thirdPartyTypeId);
      }
      if (pageSize !== undefined && pageSize !== null) {
          queryParameters = queryParameters.set('PageSize', <any>pageSize);
      }
      if (status) {
          status.forEach((element) => {
              queryParameters = queryParameters.append('Status', <any>element);
          })
      }
      if (inheritRisk) {
          inheritRisk.forEach((element) => {
              queryParameters = queryParameters.append('InheritRisk', <any>element);
          })
      }

      let headers = this.defaultHeaders;

      // authentication (Bearer) required
      if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
          headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'text/plain',
          'application/json',
          'text/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<ThirdPartyDtoQueryResultResource>('get',`${this.basePath}/ThirdParties/filtered`,
          {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ThirdPartyDto>>;
  public thirdPartiesGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ThirdPartyDto>>>;
  public thirdPartiesGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ThirdPartyDto>>>;
  public thirdPartiesGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ThirdPartyDto>>(
      'get',
      `${this.basePath}/ThirdParties`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param idCampaign
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesGetAllAvailableThirdPartiesByCampaignGet(
    idCampaign?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ThirdPartyDto>>;
  public thirdPartiesGetAllAvailableThirdPartiesByCampaignGet(
    idCampaign?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ThirdPartyDto>>>;
  public thirdPartiesGetAllAvailableThirdPartiesByCampaignGet(
    idCampaign?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ThirdPartyDto>>>;
  public thirdPartiesGetAllAvailableThirdPartiesByCampaignGet(
    idCampaign?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (idCampaign !== undefined && idCampaign !== null) {
      queryParameters = queryParameters.set('idCampaign', <any>idCampaign);
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ThirdPartyDto>>(
      'get',
      `${this.basePath}/ThirdParties/GetAllAvailableThirdPartiesByCampaign`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesGetThirdPartiesStatusEvolutionGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ThirdPartyStatusHistoryRecordDto>>;
  public thirdPartiesGetThirdPartiesStatusEvolutionGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ThirdPartyStatusHistoryRecordDto>>>;
  public thirdPartiesGetThirdPartiesStatusEvolutionGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ThirdPartyStatusHistoryRecordDto>>>;
  public thirdPartiesGetThirdPartiesStatusEvolutionGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ThirdPartyStatusHistoryRecordDto>>(
      'get',
      `${this.basePath}/ThirdParties/GetThirdPartiesStatusEvolution`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param idThirdParty
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesGetThirdPartyFilesIdThirdPartyGet(
    idThirdParty: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ThirdPartyFileDto>>;
  public thirdPartiesGetThirdPartyFilesIdThirdPartyGet(
    idThirdParty: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ThirdPartyFileDto>>>;
  public thirdPartiesGetThirdPartyFilesIdThirdPartyGet(
    idThirdParty: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ThirdPartyFileDto>>>;
  public thirdPartiesGetThirdPartyFilesIdThirdPartyGet(
    idThirdParty: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idThirdParty === null || idThirdParty === undefined) {
      throw new Error(
        'Required parameter idThirdParty was null or undefined when calling thirdPartiesGetThirdPartyFilesIdThirdPartyGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ThirdPartyFileDto>>(
      'get',
      `${this.basePath}/ThirdParties/GetThirdPartyFiles/${encodeURIComponent(
        String(idThirdParty)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesHasPendingConfigRequestGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ThirdPartyConfigurationRequestDto>;
  public thirdPartiesHasPendingConfigRequestGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ThirdPartyConfigurationRequestDto>>;
  public thirdPartiesHasPendingConfigRequestGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ThirdPartyConfigurationRequestDto>>;
  public thirdPartiesHasPendingConfigRequestGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ThirdPartyConfigurationRequestDto>(
      'get',
      `${this.basePath}/ThirdParties/HasPendingConfigRequest`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesIdDelete(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public thirdPartiesIdDelete(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public thirdPartiesIdDelete(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public thirdPartiesIdDelete(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling thirdPartiesIdDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/ThirdParties/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesIdGet(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ThirdPartyDto>;
  public thirdPartiesIdGet(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ThirdPartyDto>>;
  public thirdPartiesIdGet(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ThirdPartyDto>>;
  public thirdPartiesIdGet(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling thirdPartiesIdGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ThirdPartyDto>(
      'get',
      `${this.basePath}/ThirdParties/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesIdPut(
    id: number,
    body?: ThirdPartyUpdateDto,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public thirdPartiesIdPut(
    id: number,
    body?: ThirdPartyUpdateDto,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public thirdPartiesIdPut(
    id: number,
    body?: ThirdPartyUpdateDto,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public thirdPartiesIdPut(
    id: number,
    body?: ThirdPartyUpdateDto,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling thirdPartiesIdPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/ThirdParties/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public thirdPartiesPost(
    body?: ThirdPartyCreateDto,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<number>;
  public thirdPartiesPost(
    body?: ThirdPartyCreateDto,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<number>>;
  public thirdPartiesPost(
    body?: ThirdPartyCreateDto,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<number>>;
  public thirdPartiesPost(
    body?: ThirdPartyCreateDto,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<number>(
      'post',
      `${this.basePath}/ThirdParties`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
