import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { QueryResult } from "@app/models/common/queryResult";
import { ScreeningProvider, mapProviderName } from "@app/modules/screening-hub/models/ScreeningProvider";
import { ScoringToolsConfigurationService } from "@app/services/scoring-tools-configuration.service";
import { AddScoringToolsAccessComponent } from "./add-scoring-tools-access/add-scoring-tools-access.component";
import { DeleteScoringToolsAccess } from "./delete-scoring-tools-access/delete-scoring-tools-access.component";
import { TranslateService } from "@ngx-translate/core";
import { Toaster } from "ngx-toast-notifications";

@Component({
  selector: 'app-scoring-tools-access',
  templateUrl: './scoring-tools-access.component.html',
  styleUrls: ['./scoring-tools-access.component.scss']
})
export class ScoringToolsAccessComponent implements OnInit {

  displayedColumns: string[] = ['software', 'configuration', 'actions'];
  queryResult: QueryResult<ScreeningProvider> = { items: [], totalItems: 0 };

  translations: any;
  providerNameTranslations: { [key:string]: string };

  constructor(
    private scoringToolsConfigurationService: ScoringToolsConfigurationService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private toaster: Toaster
  ) { }

  ngOnInit(): void {
    this.scoringToolsConfigurationService.getConfigurations().subscribe(r => {
      this.queryResult.items = r;
      this.queryResult.totalItems = r.length;
    });

    this.translate.get('scoringToolsAccess').subscribe(t => this.translations = t);

    this.translate.get('providerNames')
      .subscribe(translation => {
        this.providerNameTranslations = translation;
      });
  }

  getProviderName(provider: ScreeningProvider): string {
    return mapProviderName(provider, this.providerNameTranslations);
  }

  delete(software: ScreeningProvider) {

    const dialogRef = this.dialog.open(DeleteScoringToolsAccess);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.scoringToolsConfigurationService.deleteConfiguration(software).subscribe(r => {
          if(r) {
            this.scoringToolsConfigurationService.getConfigurations().subscribe(r => {
              this.queryResult.items = r;
              this.queryResult.totalItems = r.length;
            });

            this.toaster.open({ text: this.translations.deleteSuccess, duration: 4000, type: 'success' });
          } else {
            this.toaster.open({ text: this.translations.deleteError, duration: 4000, type: 'danger' });
          }
        });
      }
    })
  }

  openDialogAdd() {
    const dialogRef = this.dialog.open(
      AddScoringToolsAccessComponent,
      {
        data: { currentConfigs: this.queryResult.items }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        // No action
      } else if (result) {
        this.scoringToolsConfigurationService.getConfigurations().subscribe(r => {
          this.queryResult.items = r;
          this.queryResult.totalItems = r.length;
        });

        this.toaster.open({ text: this.translations.addSuccess, duration: 4000, type: 'success' });
      } else {
        this.toaster.open({ text: this.translations.addError, duration: 4000, type: 'danger' });
      }
    })
  }
}
