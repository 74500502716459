<div class="address">
  <p id="keyData" class="address__legend u-mt-1medium">{{ "EquifaxWSAddressList.title" | translate }}</p>
  <section class="c-table">
    <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSAddressList.address" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.address }}
        </td>
      </ng-container>
      <ng-container matColumnDef="telephone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSAddressList.telephone" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.telephone }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
          {{ "EquifaxWSAddressList.date" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.date }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">{{'EquifaxWSAddressList.noResults' | translate}} </td>
      </tr>
    </table>
    <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems"
      class="c-pagination">
    </mat-paginator>
  </section>
</div>
