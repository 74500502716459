<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
  <div>
    <header class="c-dialog__header">
      <h1 id="titleDialog" class="c-dialog__title">{{'addScoringToolConfig.title' | translate}}</h1>
      <button mat-button (click)="close()" class="c-dialog__close" aria-label="Cerrar ventana modal">
        <span aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
            <path
              d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
              transform="translate(-309.86-406.47)" />
          </svg>
        </span>
      </button>
    </header>
    <div class="c-dialog__content u-pb-1">
      <div>
        <h2 class="c-dialog__subtitle">{{'addScoringToolConfig.title' | translate}} </h2>
        <p class="o-text">{{'addScoringToolConfig.intro' | translate}}</p>
      </div>
      <div class="c-form">
        <fieldset class="u-mb-1medium">
          <legend class="c-form__legend">
            {{ 'addScoringToolConfig.screeningProvider'| translate }}
          </legend>
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6">
              <div class="c-form__block">
                <div class="c-select">
                  <mat-form-field>
                    <mat-select placeholder="{{ 'addScoringToolConfig.screeningProvider' | translate }}"
                      [(ngModel)]="selectedProvider">
                      <mat-option *ngFor="let provider of screeningProviders" [value]="provider">
                        {{ getProviderName(provider) }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <div *ngIf="this.selectedProvider === ScreeningProvider.Refinitiv">
          <app-refinitiv-config-form [(apiKey)]="apiKey" [(apiSecret)]="apiSecret"></app-refinitiv-config-form>
        </div>
        <div *ngIf="this.selectedProvider === ScreeningProvider.AMLUpdate">
          <app-aml-update-config-form [(apiToken)]="apiKey"></app-aml-update-config-form>
        </div>
        <div *ngIf="this.selectedProvider === ScreeningProvider.EquifaxWS">
          <app-equifax-ws-config-form [(datumUser)]="datumUser" [(reference)]="reference" [(user)]="user" [(password)]="password"></app-equifax-ws-config-form>
        </div>
      </div>
    </div>
    <footer class="c-dialog__footer flex-end">
      <button [disabled]="!isValid()" (click)="save()" class="c-button c-button--green" style="margin-right: 1rem;" mat-button>
        {{'addScoringToolConfig.addButton' | translate}}
      </button>
      <button (click)="close()" class="c-button c-button--light" mat-button>
        {{'addScoringToolConfig.cancel' | translate}}
      </button>
    </footer>
  </div>
</div>
