import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Question } from '@app/models/Question';
import { Questionary } from '@app/models/Questionary';
import { QuestionaryBlock } from '@app/models/QuestionaryBlock';
import { Risk } from '@app/models/Risk';

@Injectable({
  providedIn: 'root'
})
export class QuestionaryFormService {

  form: FormGroup;
  questionary: Questionary;
  disabled: boolean;

  usedIds = new Set();

  tipoBloqueDefinition: Risk[] = [];
  tipoBloqueDefinitionAll: Risk[] = [];

  constructor(private fb: FormBuilder) { }

  get blocks() {
    return this.form.get("bloques") as FormArray;
  }

  build(questionary: Questionary, disabled: boolean, risks: Risk[]): FormGroup {
    this.tipoBloqueDefinitionAll = risks;
    this.questionary = questionary;
    this.disabled = disabled;
    const bloques = questionary.questionaryBlocks;
    const questionaryUseWeights = questionary.questionaryBlocks.every(qb => qb.scoreWeight > 0);

    this.form = this.fb.group({
      Template: new FormControl({ value: questionary.code, disabled: disabled }, Validators.required),
      ThirdPartyType: new FormControl({ value: questionary.thirdPartyType, disabled: disabled }, Validators.required),
      Sector: new FormControl({ value: questionary.sector, disabled: disabled }, Validators.required),
      Country: new FormControl({ value: questionary.country, disabled: disabled }, Validators.required),
      Notes: new FormControl({ value: questionary.description, disabled: disabled }, Validators.required),
      Amount: new FormControl({ value: questionary.amount, disabled: disabled }, Validators.required),
      Rating: new FormControl({ value: questionary.rate, disabled: disabled }, Validators.required),
      UseWeights: new FormControl(questionaryUseWeights),
      TotalWeight: new FormControl({ value: '100.0', disabled: true }),
      bloques: this.fb.array([])
    });

    bloques.sort((a, b) => a.order - b.order);
    for (let indexB = 0; indexB < bloques.length; indexB++) {
      const elementB = bloques[indexB];
      this.addBlock();
      this.blocks.at(indexB).get("Risk").setValue(elementB.risk);
      this.blocks.at(indexB).get("Weight").setValue(+elementB.scoreWeight || 0);

      if (disabled) {
        this.blocks.at(indexB).get("Weight").disable();
      }

      this.usedIds.add(elementB.risk);

      elementB.questions.sort((a, b) => a.order - b.order);
      for (let indexQ = 0; indexQ < elementB.questions.length; indexQ++) {
        const elementQ = elementB.questions[indexQ];
        this.addQuestion(indexB);
        this.bloqueQuestions(indexB).at(indexQ).patchValue({
          Description: elementQ.description,
          CanAddComments: elementQ.canAddComments,
          MustAddComments: elementQ.mustAddComments,
          CanAddDoc: elementQ.canAddDoc,
          MustAddDoc: elementQ.mustAddDoc,
          TipoPregunta: elementQ.isMultipleChoice ? 2 : 1,
          TipoScore: elementQ.isAlternativeScorinig ? 1 : 0,
          WeightYes: elementQ.weightYes,
          WeightNo: elementQ.weightNo,
          ButtonText: elementQ.docTitle,
          Options: [],
          WeightNoComment: elementQ.weightNoComment,
          WeightComment: elementQ.weightHasComment,
          WeightNoFile: elementQ.weightNoDoc,
          WeightFile: elementQ.weightHasDoc,

        });

        if (elementQ.isMultipleChoice) {
          const optionsFormArray = this.bloqueQuestions(indexB).at(indexQ).get('Options') as FormArray;
          const options = JSON.parse(elementQ.options);
          for (const option of options) {
            optionsFormArray.push(this.fb.group({
              Text: option.Text,
              Weight: option.Weight,
            }))
          }
        }
      }
    }
    this.updateBlockSelect();

    if (disabled) {
      this.form.disable();
    }

    this.form.get('bloques').valueChanges.subscribe(blockValues => {
      const useWeights = this.form.get('UseWeights').value;
      if (useWeights && blockValues.every(r => r.Weight > 0)) {
        const totalWeightValue = blockValues.reduce((previous: number, rB: any) => previous + rB.Weight, 0);
        this.form.get("TotalWeight").setValue(totalWeightValue.toFixed(1));
      }
    });

    this.form.setValidators(this.validateWeights);

    return this.form;
  }

  addBlock(): void {
    this.blocks.push(this.newBlock());
  }

  dropBlock(event: CdkDragDrop<object[]>): void {
    if (this.questionary && !this.questionary.isMaster) {
      if (event.previousIndex !== event.currentIndex) {
        moveItemInArray(this.blocks.controls, event.previousIndex, event.currentIndex);
        this.form.markAsDirty();
      }
    }
  }

  newBlock(): FormGroup {
    return this.fb.group({
      Risk: [null, Validators.required],
      questions: this.fb.array([]),
      Weight: new FormControl({ value: 0, disabled: this.disabled }, Validators.required),
    });
  }

  addQuestion(bloqueIndex: number): void {
    this.bloqueQuestions(bloqueIndex).push(this.newQuestion());
  }

  dropQuestion(questionsBlock: FormArray, fromQuestionIndex: number, toQuestionIndex: number): void {
    moveItemInArray(questionsBlock.controls, fromQuestionIndex, toQuestionIndex);
    this.form.markAsDirty();
  }

  bloqueQuestions(bloqueIndex: number): FormArray {
    return this.blocks.at(bloqueIndex).get("questions") as FormArray;
  }

  isAddBlockButtonDisabled(): boolean {
    if (!this.questionary || this.questionary.isMaster) {
      return true;
    }

    if (this.tipoBloqueDefinition.length == 0) {
      return true;
    }

    if (!this.blocks) {
      return true;
    }

    const maxBlocks = this.tipoBloqueDefinitionAll.length;
    if (this.blocks.length >= maxBlocks) {
      return true;
    }

    return false;
  }

  addBlockWithQuestion(): void {
    this.addBlock();
    let total = this.blocks.length;
    this.addQuestion(total - 1);
  }

  newQuestion(): FormGroup {
    const controlMustAddComments = new FormControl(false, Validators.required);
    const controlAddDocButtonText = new FormControl({ value: '', disabled: true }, Validators.required);
    const controlCanAddDoc = new FormControl(false, Validators.required);
    const controlCanAddComments = new FormControl(false, Validators.required);

    controlCanAddComments.valueChanges
      .subscribe(val => {
        if (!val) {
          let brotherMustAddCommentsControl = controlCanAddComments.parent.get('MustAddComments');
          brotherMustAddCommentsControl.setValue(false);
        }
      });


    controlMustAddComments.valueChanges
      .subscribe(val => {
        let brotherCanAddCommentsControl = controlMustAddComments.parent.get('CanAddComments');
        if (brotherCanAddCommentsControl !== null && brotherCanAddCommentsControl !== undefined) {
          if (val) {
            brotherCanAddCommentsControl.setValue(2);
          }
        }
      });

    controlCanAddDoc.valueChanges
      .subscribe(val => {
        if (val) {
          controlAddDocButtonText.setValidators([Validators.required])
          controlAddDocButtonText.enable();
        } else {
          controlAddDocButtonText.setValidators([])
          controlAddDocButtonText.disable();
          let brotherCanAddDocControl = controlCanAddDoc.parent.get('MustAddDoc');
          brotherCanAddDocControl.setValue(false);
        }
      });

    const controlMustAddDoc = new FormControl(false, Validators.required);
    controlMustAddDoc.valueChanges
      .subscribe(val => {
        let brotherCanAddDocControl = controlMustAddDoc.parent.get('CanAddDoc');
        if (brotherCanAddDocControl !== null && brotherCanAddDocControl !== undefined) {
          if (val) {
            brotherCanAddDocControl.setValue(2);
          }
        }
      });

    return this.fb.group({
      Description: new FormControl(null, Validators.required),
      WeightYes: new FormControl(1, Validators.required),
      WeightNo: new FormControl(1, Validators.required),
      CanAddComments: controlCanAddComments,
      MustAddComments: controlMustAddComments,
      CanAddDoc: controlCanAddDoc,
      MustAddDoc: controlMustAddDoc,
      TipoPregunta: new FormControl(1, Validators.required),
      TipoScore: new FormControl(0, Validators.required),
      ButtonText: controlAddDocButtonText,
      Options: this.fb.array([]),
      WeightNoComment: new FormControl(1, Validators.required),
      WeightComment: new FormControl(1, Validators.required),
      WeightNoFile: new FormControl(1, Validators.required),
      WeightFile: new FormControl(1, Validators.required),
    });
  }

  deleteQuestion(bloqueIndex: number, questionIndex: number): void {
    if (this.questionary && !this.questionary.isMaster) {
      this.bloqueQuestions(bloqueIndex).removeAt(questionIndex);

      this.form.markAsDirty();
    }
  }

  blockNameChange(): void {
    const current = new Set();

    for (let indexB = 0; indexB < this.blocks.length; indexB++) {
      const elementB = (<FormArray>this.form.get("bloques")).at(indexB);
      const nombre = elementB.get('Risk').value;
      if (nombre !== null) {
        current.add(nombre);
      }
    }

    this.usedIds = current;
    this.updateBlockSelect();
  }

  getUnusedRiskTypesWithRisk(risk: Risk): Risk[] {
    if (!risk) return this.tipoBloqueDefinition;

    return [risk, ...this.tipoBloqueDefinition];
  }

  updateBlockSelect(): void {
    const usedRiskTypes = [];
    for (let index = 0; index < this.tipoBloqueDefinitionAll.length; index++) {
      const element = this.tipoBloqueDefinitionAll[index];

      const usedRiskIds = Array.from(this.usedIds);
      let isIn = false;
      for (let index2 = 0; index2 < usedRiskIds.length; index2++) {
        const element2 = <Risk>usedRiskIds[index2];
        if (element.id == element2.id) {
          isIn = true;
          break;
        }
      }
      if (!isIn) {
        usedRiskTypes.push(element);
      }
    }
    this.tipoBloqueDefinition = usedRiskTypes;
  }

  canAddDocFlag(blockIndex: number, questionIndex: number): boolean {
    const questions = this.blocks.at(blockIndex).get("questions") as FormArray;
    const canAddDocValue = questions.at(questionIndex).get('CanAddDoc').value;
    return canAddDocValue == 2;
  }

  deleteBloque(bloqueIndex: number): void {
    if (this.questionary && !this.questionary.isMaster) {
      if (this.blocks.at(bloqueIndex).get('Risk').value != null) {
        this.usedIds.delete(this.blocks.at(bloqueIndex).get('Risk').value);
        this.updateBlockSelect();
      }

      this.blocks.removeAt(bloqueIndex);
    }
  }

  validateWeights(control: AbstractControl): ValidationErrors | null {
    const useWeights = control.get("UseWeights").value;
    const riskBlocks = control.get("bloques").value as { Weight: number, Risk: any, questions: any }[];
    const totalWeightField = control.get("TotalWeight").value;

    if (riskBlocks.length === 0) {
      return null;
    }

    if (!useWeights) {
      return null;
    }

    if (useWeights && riskBlocks.every(r => r.Weight > 0) && totalWeightField === '100.0') {
      return null;
    }

    return { 'incorrectWeights': true };
  }

  outputQuestionaryFromForm(): Questionary {
    const output: Questionary = this.questionary;
    output.isMaster = false;
    output.questionaryBlocks = [];
    output.creationTime = new Date();
    output.updateTime = new Date();
    output.code = this.form.get('Template').value;
    output.thirdPartyType = this.form.get('ThirdPartyType').value;
    output.sector = this.form.get('Sector').value;
    output.country = this.form.get('Country').value;
    output.description = this.form.get('Notes').value;
    output.amount = this.form.get('Amount').value;
    output.rate = this.form.get('Rating').value;

    // Creamos los bloques
    const bloques = [];
    for (let indexB = 0; indexB < this.blocks.length; indexB++) {

      // Obtenemos el bloque del form
      const elementB = (<FormArray>this.form.get("bloques")).at(indexB);
      const block = (<QuestionaryBlock>{});
      block.risk = elementB.get('Risk').value;
      block.order = indexB;
      block.scoreWeight = this.form.get('UseWeights').value ? elementB.get('Weight').value : null;

      // Creamos las preguntas
      const questions = [];
      for (let indexQ = 0; indexQ < (<FormArray>elementB.get("questions")).length; indexQ++) {
        const question = (<Question>{});
        const questionData = (<FormArray>elementB.get("questions")).at(indexQ);
        question.description = questionData.get('Description').value;
        question.canAddComments = questionData.get('CanAddComments').value;
        question.canAddDoc = questionData.get('CanAddDoc').value;
        question.mustAddComments = questionData.get('MustAddComments').value;
        question.mustAddDoc = questionData.get('MustAddDoc').value;
        question.name = questionData.get('Description').value;
        question.weightYes = questionData.get('WeightYes').value;
        question.weightNo = questionData.get('WeightNo').value;
        question.docTitle = questionData.get('CanAddDoc').value ? questionData.get('ButtonText').value : null;
        question.order = indexQ;
        question.isMultipleChoice = questionData.get('TipoPregunta').value === 2;
        question.isAlternativeScorinig = questionData.get('TipoScore').value;
        question.weightHasComment = questionData.get('WeightComment').value;
        question.weightNoComment = questionData.get('WeightNoComment').value;
        question.weightHasDoc = questionData.get('WeightFile').value;
        question.weightNoDoc = questionData.get('WeightNoFile').value;
        question.options = question.isMultipleChoice ? JSON.stringify(questionData.get('Options').value) : null;
        questions.push(question);
      }
      block.questions = questions;
      bloques.push(block);
    }

    output.questionaryBlocks = bloques;
    return output;
  }
}
