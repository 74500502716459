import { RemoteService } from './remote.service';
import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})


export class CommonsService {


  constructor(
    private remoteService: RemoteService,
  ) { }

  private apiURL = 'Commons';

  public getSectors() {
    return this.remoteService.getRequest(this.apiURL + '/Sector');
  }
  public getCountries() {
    return this.remoteService.getRequest(this.apiURL + '/Country');
  }

  public getThirdPartyTypes() {
    return this.remoteService.getRequest(this.apiURL + '/ThirdPartyType');
  }
  public getResponsibles() {
    return this.remoteService.getRequest(this.apiURL + '/Responsibles');
  }
  public getNatureOfActivity() {
    return this.remoteService.getRequest(this.apiURL + '/ActivityNature');
  }
  public getTypeOfActivity() {
    return this.remoteService.getRequest(this.apiURL + '/ActivityType');
  }
  public getRisks() {
    return this.remoteService.getRequest(this.apiURL + '/Risk')
  }

  public postSector(sector) {
    return this.remoteService.postRequest(this.apiURL + '/Sector', sector);
  }

  public postNature(nature) {
    return this.remoteService.postRequest(this.apiURL + '/ActivityNature', nature);
  }

  public postActivity(activity) {
    return this.remoteService.postRequest(this.apiURL + '/ActivityType', activity);
  }

  public postThirdtype(type) {
    return this.remoteService.postRequest(this.apiURL + '/ThirdPartyType', type);
  }

  public postRisk(risk) {
    return this.remoteService.postRequest(this.apiURL + '/Risk', risk);
  }

  public deleteSector(id) {
    return this.remoteService.deleteRequest(this.apiURL + '/Sector/' + String(id), {});
  }

  public deleteNature(id) {
    return this.remoteService.deleteRequest(this.apiURL + '/ActivityNature/' + String(id), {});
  }

  public deleteActivity(id) {
    return this.remoteService.deleteRequest(this.apiURL + '/ActivityType/' + String(id), {});
  }

  public deleteThirdtype(id) {
    return this.remoteService.deleteRequest(this.apiURL + '/ThirdPartyType/' + String(id), {});
  }

  public deleteRisk(id) {
    return this.remoteService.deleteRequest(this.apiURL + '/Risk/' + String(id), {});
  }

  public putSector(sector) {
    return this.remoteService.putRequest(this.apiURL + '/Sector/' + String(sector.Id), sector);
  }

  public putNature(nature) {
    return this.remoteService.putRequest(this.apiURL + '/ActivityNature/' + String(nature.Id), nature);
  }

  public putActivity(activity) {
    return this.remoteService.putRequest(this.apiURL + '/ActivityType/' + String(activity.Id), activity);
  }

  public putThirdtype(type) {
    return this.remoteService.putRequest(this.apiURL + '/ThirdPartyType/' + String(type.Id), type);
  }

  public putRisk(risk) {
    return this.remoteService.putRequest(this.apiURL + '/Risk/' + String(risk.Id), risk);
  }

  // Utility funtions for password validation
  passwordLengthValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const invalidLength = control.value.length < 6
      return invalidLength ? { invalidLength: { value: control.value } } : null
    }
  }

  passwordUpperValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mustContainUpper = !(/[A-Z]/.test(control.value))
      return mustContainUpper ? { mustContainUpper: { value: control.value } } : null
    }
  }

  passwordLowerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mustContainLower = !(/[a-z]/.test(control.value))
      return mustContainLower ? { mustContainLower: { value: control.value } } : null
    }
  }

  passwordNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mustContainNumber = !(/[0123456789]/.test(control.value))
      return mustContainNumber ? { mustContainNumber: { value: control.value } } : null
    }
  }

  passwordSpecialCharacterValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mustContainSpecial = !(/[~@#$%^&*+=`|{}:;!.?\"()\[\]-]/.test(control.value))
      return mustContainSpecial ? { mustContainSpecial: { value: control.value } } : null
    }
  }

  passwordsEqualValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const password = control.get('password');
    const passwordConfirm = control.get('passwordConfirm');

    return password.value != passwordConfirm.value ? { passwordsDifferent: true } : null;
  };

  public getUsers() {
    return this.remoteService.getRequest('Users/GetAllUserData');
  }

  public postUser(user) {
    return this.remoteService.postRequest('Users/CreateClientUser', user);
  }

  public putUser(user) {
    return this.remoteService.putRequest('Users/UpdateClientUser/' + String(user.Id), user);

  }

  public getRoles() {
    return this.remoteService.getRequest(this.apiURL + '/Roles');
  }

  public getClientHaveFreeLicense() {
    return this.remoteService.getRequest('Users/ClientHaveFreeLicense');
  }

  public getIsoCertifications() {
    return this.remoteService.getRequest(this.apiURL + '/IsoCertification');
  }

  public getSponsors() {
    return this.remoteService.getRequest(this.apiURL + '/FinanceTypes');
  }

  public getOrgans() {
    return this.remoteService.getRequest(this.apiURL + '/ExecutiveOrgan');
  }
}
