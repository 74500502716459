import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { ScreeningReportData } from "@app/models/screeningReportData";
import { TranslateService } from "@ngx-translate/core";
import { RefinitivScoringProxyClient } from "src/proxy/apiRefinitiv/refinitivScoring.proxy";
import { ThirdPartiesProxyClient } from "src/proxy/apiThirdParty/third-party.proxy";
import { ThirdPartyDto } from "src/proxy/model/thirdPartyDto";
import { mapScreeningReport } from "../mappings/scoring-tools.mappings";
import { ColorHex } from "../pdf-template-generator/Models/colorHex";
import { Content } from "../pdf-template-generator/Models/content";
import { CoverPage } from "../pdf-template-generator/Models/coverPage";
import { DocumentContent } from "../pdf-template-generator/Models/documentContent";
import { Font } from "../pdf-template-generator/Models/font";
import { Footer } from "../pdf-template-generator/Models/footer";
import { Header } from "../pdf-template-generator/Models/header";
import { Image } from "../pdf-template-generator/Models/image";
import { Section } from "../pdf-template-generator/Models/section";
import { SvgPath } from "../pdf-template-generator/Models/svgPath";
import { Text } from "../pdf-template-generator/Models/text";
import { TextUnderline } from "../pdf-template-generator/Models/textUnderline";
import { templateGenerator } from "../pdf-template-generator/template-generator";
import { Language } from "@app/models/Language";

@Injectable()
export class ThirdPartyScreeningReportService {

  locale: string;

  constructor(private refinitivScoringClient: RefinitivScoringProxyClient,
    private thirdPartyClient: ThirdPartiesProxyClient,
    private translate: TranslateService) { }

  async getScreeningReport(thirdPartyId: number, screeningId: number): Promise<[string, Uint8Array]> {
    this.locale = this.translate.currentLang.split("-")[0];
    let translations = await this.translate.get("screeningReport").toPromise();
    const third = await this.thirdPartyClient.thirdPartiesIdGet(thirdPartyId).toPromise();
    if (third.Country == null) {
      third.Country = {
        Id: 0,
        Code: 'Not specified',
        CodeAlpha3: 'Not specified',
        Description: 'No especificado',
        DescriptionEN: 'Not specified',
        DescriptionPT: 'Não especificado',
        DescriptionFR: 'Non spécifié'
      };
    }
    if (third.ThirdPartyConfig.Sector == null) {
      third.ThirdPartyConfig.Sector = {
        Id: 0,
        Code: 'Not specified',
        Description: 'No especificado',
        DescriptionEN: 'Not specified',
        DescriptionPT: 'Não especificado',
        DescriptionFR: 'Non spécifié'
      };
    }
    if (third.ThirdPartyConfig.ThirdPartyType == null) {
      third.ThirdPartyConfig.ThirdPartyType = {
        Id: 0,
        Code: 'Not specified',
        Description: 'No especificado',
        DescriptionEN: 'Not specified',
        DescriptionPT: 'Não especificado',
        DescriptionFR: 'Non spécifié'
      };
    }
    const screening = mapScreeningReport(await this.refinitivScoringClient.refinitivScoringGetReportScreeningDataScreenIdGet(screeningId).toPromise());
    const username = screening.printedBy;
    const printDate = new Date();
    const fonts = await this.getReportFonts();
    const coverPage = await this.getReportCoverPage(third.CompanyName, screening.createdBy, screening.screeningDate, printDate, translations);
    const pageHeader = await this.getPageHeader(translations);
    const pageFooter = this.getPageFooter(third.CompanyName, printDate, username, translations);
    const riskallayDataSection = this.getRiskallayDataSection(third, translations);
    const refinitivDataSection = this.getRefinitivDataSection(printDate, username, third.CompanyName, screening);
    const screeeningKeyDataSection = this.getScreeeningKeyDataSection(screening);
    const risksDetectedSection = this.getRisksDetectedSection(screening);
    const biographySection = this.getBiographySection(screening);
    const relationshipsAndConnectionsSection = this.getRelationshipsConnectionsSection(screening);
    const externalSourcesSection = this.getExternalSourcesSection(screening);
    const disclaimerSection = this.getDisclaimerSection();
    const docContent = <DocumentContent>{
      header: pageHeader,
      footer: pageFooter,
      sections: [riskallayDataSection, refinitivDataSection, screeeningKeyDataSection, risksDetectedSection, biographySection,
        relationshipsAndConnectionsSection, externalSourcesSection, disclaimerSection]
    }

    return [`Riskallay-Report-screening-${third.CompanyName}_${formatDate(screening.screeningDate, 'ddMMyyyy - HHmm', this.locale)}.pdf`, await templateGenerator(fonts, docContent, coverPage)];

  }

  async getReportFonts(): Promise<Font[]> {
    let fonts: Font[] = [];
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Medium.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Medium' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Regular.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Regular' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Roboto-Bold.ttf').then((res) => res.arrayBuffer()), name: 'Roboto-Bold' });
    fonts.push(<Font>{ font: await fetch('/assets/report/Poppins-SemiBold.ttf').then((res) => res.arrayBuffer()), name: 'Poppins-SemiBold' });

    return fonts;
  }

  async getReportCoverPage(thirdName: string, companyName: string, screeningDate: Date, printDate: Date, translations: any): Promise<CoverPage> {
    return <CoverPage>{
      content: [
        <Content>{ content: <Image>{ image: await fetch('/assets/report/background.png').then((res) => res.arrayBuffer()), height: 842, width: 595, customHeight: 1.73 }, posX: 0, posY: 0, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/Riskboard03.png').then((res) => res.arrayBuffer()), height: 398, width: 515 }, posX: 40, posY: 24, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/wave.png').then((res) => res.arrayBuffer()), height: 842, width: 595 }, posX: 0, posY: 419, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height: 42, width: 180 }, posX: 40, posY: 454, isRelative: false },
        <Content>{ content: <Image>{ image: await fetch('/assets/report/poweredby.png').then((res) => res.arrayBuffer()), height: 16, width: 133 }, posX: 40, posY: 759, isRelative: false },
        <Content>{ content: <Text>{ text: translations.cover.report, font: 'Roboto-Medium', fontSize: 20, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 496, isRelative: false },
        <Content>{ content: <Text>{ text: thirdName, font: 'Poppins-SemiBold', fontSize: 20, fontColor: <ColorHex>{ hexCode: '121212' }, maxWidth: 515 }, posX: 40, posY: 528, isRelative: false },
        <Content>{
          content: <Text>{
            text: translations.cover.intro.replace('${thirdName}', thirdName), font: 'Roboto-Regular',
            fontSize: 10, fontColor: <ColorHex>{ hexCode: '666666' }, lineHeight: 16, maxWidth: 335
          }, posX: 40, posY: 660, isRelative: false
        },
        <Content>{ content: <Text>{ text: translations.screeningBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 660, isRelative: false },
        <Content>{ content: <Text>{ text: companyName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 670, isRelative: false },
        <Content>{ content: <Text>{ text: translations.screeningDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 690, isRelative: false },
        <Content>{ content: <Text>{ text: formatDate(screeningDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 700, isRelative: false },
        <Content>{ content: <Text>{ text: translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 399, posY: 720, isRelative: false },
        <Content>{ content: <Text>{ text: formatDate(printDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 399, posY: 730, isRelative: false },
      ]
    };
  }

  async getPageHeader(translations: any): Promise<Header> {
    return <Header>{
      height: 67,
      content: [<Content>{ content: <Image>{ image: await fetch('/assets/report/logo.png').then((res) => res.arrayBuffer()), height: 31, width: 133 }, posX: 40, posY: 24, isRelative: false },
      <Content>{ content: <Text>{ text: translations.confidential, font: 'Roboto-Medium', fontSize: 10, fontColor: <ColorHex>{ hexCode: 'f8b410' }, lineHeight: 16 }, posX: 474, posY: 31.5, isRelative: false }
      ]
    };
  }

  getPageFooter(thirdName: string, printDate: Date, username: string, translations: any): Footer {
    return <Footer>{
      height: 71,
      content: [<Content>{ content: <SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height: 1, width: 515, color: <ColorHex>{ hexCode: 'd9d9d9' } }, posX: 40, posY: 771, isRelative: false },
      <Content>{ content: <Text>{ text: translations.thirdName, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 783, isRelative: false },
      <Content>{ content: <Text>{ text: translations.downloadDate, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 796, isRelative: false },
      <Content>{ content: <Text>{ text: translations.downloadedBy, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 809, isRelative: false },
      <Content>{ content: <Text>{ text: thirdName, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 124, posY: 783, isRelative: false },
      <Content>{ content: <Text>{ text: formatDate(printDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 124, posY: 796, isRelative: false },
      <Content>{ content: <Text>{ text: username, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 124, posY: 809, isRelative: false },
      <Content>{ content: <Text>{ text: translations.pageNumber, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, lineHeight: 16 }, posX: 513, posY: 783, isRelative: false },
      ]
    };
  }

  getRiskallayDataSection(third: ThirdPartyDto, translations: any): Section {
    return <Section>{
      isFullPage: false,
      isAbsolute: true,
      content: [<Content>{ content: <Text>{ text: translations.riskallayDataSection.title.replace('${third.CompanyName}', third.CompanyName), font: 'Roboto-Bold', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' }, underline: <TextUnderline>{ color: <ColorHex>{ hexCode: 'fdc130' }, thickness: 2 } }, posX: 40, posY: 92, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.thirdCategorization, font: 'Roboto-Medium', fontSize: 8, lineHeight: 16, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 124, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.country, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 148, isRelative: false },
      <Content>{ content: <Text>{ text: third.Country.Description, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 161, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.thirdPartyType, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 148, isRelative: false },
      <Content>{ content: <Text>{ text: third.ThirdPartyConfig.ThirdPartyType.Code, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 161, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.sector, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 400, posY: 148, isRelative: false },
      <Content>{ content: <Text>{ text: third.ThirdPartyConfig.Sector.Code, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 161, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.basicData, font: 'Roboto-Medium', fontSize: 8, lineHeight: 16, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 189, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.taxpayerId, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 40, posY: 217, isRelative: false },
      <Content>{ content: <Text>{ text: third.CompanyCIF, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' } }, posX: 40, posY: 230, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.companyName, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156, }, posX: 220, posY: 217, isRelative: false },
      <Content>{ content: <Text>{ text: third.CompanyName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' } }, posX: 220, posY: 230, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.activityType, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 400, posY: 217, isRelative: false },
      <Content>{ content: <Text>{ text: third.ThirdPartyConfig.TypeOfActivity?.Code ?? '', font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' } }, posX: 400, posY: 230, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.activityNature, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156, }, posX: 40, posY: 261, isRelative: false },
      <Content>{ content: <Text>{ text: third.ThirdPartyConfig.NatureOfActivity?.Code ?? '', font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' } }, posX: 40, posY: 274, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.state, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156, }, posX: 220, posY: 261, isRelative: false },
      <Content>{ content: <Text>{ text: third.ThirdPartyConfig.CompanyProvince, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' } }, posX: 220, posY: 274, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.address, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156, }, posX: 400, posY: 261, isRelative: false },
      <Content>{ content: <Text>{ text: third.ThirdPartyConfig.CompanyAddress, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 274, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.zipCode, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 305, isRelative: false },
      <Content>{ content: <Text>{ text: third.ThirdPartyConfig.CompanyCP, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 318, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.thirdPartycontactData, font: 'Roboto-Medium', fontSize: 8, lineHeight: 16, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 346, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.thirdPartyContactFullname, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 374, isRelative: false },
      <Content>{ content: <Text>{ text: `${third.Contact.Name} ${third.Contact.Surnames}`, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 387, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.thirdPartyContactEmail, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 374, isRelative: false },
      <Content>{ content: <Text>{ text: third.Contact.Email, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 387, isRelative: false },
      <Content>{ content: <Text>{ text: translations.riskallayDataSection.thirdPartyContactLanguage, font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 400, posY: 374, isRelative: false },
      <Content>{ content: <Text>{ text: this.getLanguageCode(third.Contact.Language), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 387, isRelative: false },
      ]
    }
  }

  getRefinitivDataSection(printDate: Date, username: string, thirdName: string, screeningReportData: ScreeningReportData): Section {
    return <Section>{
      isFullPage: false,
      isAbsolute: true,
      content: [<Content>{ content: <Text>{ text: `Screening ${thirdName}`, font: 'Roboto-Medium', fontSize: 24, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '666666' } }, posX: 40, posY: 463, isRelative: false },
      <Content>{ content: <Text>{ text: 'World-Check Record', font: 'Roboto-Medium', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 511, isRelative: false },
      <Content>{ content: <Text>{ text: 'UID', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 543, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.worldCheckId, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 556, isRelative: false },
      <Content>{ content: <Text>{ text: 'Name', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 543, isRelative: false },
      <Content>{ content: <Text>{ text: thirdName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 556, isRelative: false },
      <Content>{ content: <Text>{ text: 'Group', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 400, posY: 543, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.group, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 556, isRelative: false },
      <Content>{ content: <Text>{ text: 'Created & Screened', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 40, posY: 592, isRelative: false },
      <Content>{ content: <Text>{ text: formatDate(screeningReportData.screeningDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 605, isRelative: false },
      <Content>{ content: <Text>{ text: 'Printed by', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 220, posY: 592, isRelative: false },
      <Content>{ content: <Text>{ text: username, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 605, isRelative: false },
      <Content>{ content: <Text>{ text: 'Date Printed', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' }, maxWidth: 156 }, posX: 400, posY: 592, isRelative: false },
      <Content>{ content: <Text>{ text: formatDate(printDate, 'dd/MM/yyyy - HH:mm', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 605, isRelative: false },
      <Content>{ content: <Text>{ text: 'Case and Comparison data', font: 'Roboto-Medium', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 657, isRelative: false },
      <Content>{ content: <Text>{ text: 'Client/Submitted Data', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 689, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.clientSubmittedData, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 702, isRelative: false },
      <Content>{ content: <Text>{ text: 'World-Check data', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 689, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.worldCheckData, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 702, isRelative: false }
      ]
    }
  }

  getScreeeningKeyDataSection(screeningReportData: ScreeningReportData): Section {
    return <Section>{
      isFullPage: false,
      isAbsolute: true,
      content: [<Content>{ content: <Text>{ text: 'Key Data Screening Microsoft Corporation', font: 'Roboto-Bold', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 84, isRelative: false },
      <Content>{ content: <Text>{ text: 'KEY DATA', font: 'Roboto-Medium', fontSize: 8, lineHeight: 16, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 116, isRelative: false },
      <Content>{ content: <Text>{ text: 'Dataset', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 144, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.dataset, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 157, isRelative: false },
      <Content>{ content: <Text>{ text: 'Category', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 144, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.category, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 157, isRelative: false },
      <Content>{ content: <Text>{ text: 'Name', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 400, posY: 144, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.companyName, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 157, isRelative: false },
      <Content>{ content: <Text>{ text: 'Special Interest Categories', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 189, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.specialInterestCategories, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 202, isRelative: false },
      <Content>{ content: <Text>{ text: 'Registered Country', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 189, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.registeredCountry, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 202, isRelative: false },
      <Content>{ content: <Text>{ text: 'Country location(s)', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 400, posY: 189, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.countryLocations, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 202, isRelative: false },
      <Content>{ content: <Text>{ text: 'Location 1', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 226, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.location1, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 239, isRelative: false },
      <Content>{ content: <Text>{ text: 'Location 2', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 226, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.location2, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 239, isRelative: false },
      <Content>{ content: <Text>{ text: 'Identification Number', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 263, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.identificationType, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 276, isRelative: false },
      <Content>{ content: <Text>{ text: 'Number', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 263, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.identificationNumber, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 276, isRelative: false },
      <Content>{ content: <Text>{ text: 'Entered Date', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 400, posY: 263, isRelative: false },
      <Content>{ content: <Text>{ text: formatDate(screeningReportData.enteredDate, 'dd MMM yyyy', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 276, isRelative: false },
      <Content>{ content: <Text>{ text: 'Updated Date', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 300, isRelative: false },
      <Content>{ content: <Text>{ text: formatDate(screeningReportData.updatedDate, 'dd MMM yyyy', this.locale), font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 40, posY: 313, isRelative: false },
      <Content>{ content: <Text>{ text: 'Update categorization', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 220, posY: 300, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.updateCategorization, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 220, posY: 313, isRelative: false },
      <Content>{ content: <Text>{ text: 'Aliases', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 400, posY: 300, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.alias, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 156, lineHeight: 12 }, posX: 400, posY: 313, isRelative: false },
      <Content>{ content: <Text>{ text: 'LOCATION', font: 'Roboto-Medium', fontSize: 8, lineHeight: 16, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 341, isRelative: false },
      <Content>{ content: <Text>{ text: 'Registered Country', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 40, posY: 369, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.registeredCountry, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 111, lineHeight: 12 }, posX: 40, posY: 382, isRelative: false },
      <Content>{ content: <Text>{ text: 'Location 1', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 175, posY: 369, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.location1, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 111, lineHeight: 12 }, posX: 175, posY: 382, isRelative: false },
      <Content>{ content: <Text>{ text: 'Location 2', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 310, posY: 369, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.location2, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 111, lineHeight: 12 }, posX: 310, posY: 382, isRelative: false },
      <Content>{ content: <Text>{ text: 'Country location(s)', font: 'Roboto-Bold', fontSize: 8, fontColor: <ColorHex>{ hexCode: '8c8c8c' } }, posX: 445, posY: 369, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.countryLocations, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, maxWidth: 111, lineHeight: 12 }, posX: 445, posY: 382, isRelative: false },
      <Content>{ content: <SvgPath>{ path: 'M 0,0 L 515,0 515,1 0,1 ', height: 1, width: 515, color: <ColorHex>{ hexCode: 'd9d9d9' } }, posX: 40, posY: 24, isRelative: true },
      ]
    }
  }

  getRisksDetectedSection(screeningReportData: ScreeningReportData): Section {

    let content = [<Content>{ content: <Text>{ text: 'Detected risks', font: 'Roboto-Bold', fontSize: 16, lineHeight: 24, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 24, isRelative: true }];

    screeningReportData.risks.forEach(risk => {
      content.push(<Content>{ content: <Text>{ text: `${risk.type} - ${formatDate(risk.creationDate, 'dd/MM/yyyy', this.locale)}`, font: 'Roboto-Bold', fontSize: 10, fontColor: <ColorHex>{ hexCode: '121212' } }, posX: 40, posY: 16, isRelative: true });
      content.push(<Content>{ content: <Text>{ text: risk.name, font: 'Roboto-Regular', fontSize: 8, fontColor: <ColorHex>{ hexCode: '121212' }, lineHeight: 12 }, posX: 40, posY: 4, isRelative: true });
    });

    return <Section>{
      isAbsolute: false,
      isFullPage: false,
      content: content
    }
  }

  getBiographySection(screeningReportData: ScreeningReportData): Section {
    return <Section>{
      isAbsolute: false,
      isFullPage: false,
      content: [<Content>{ content: <Text>{ text: 'Biography', font: 'Roboto-Bold', fontSize: 20, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 24, underline: <TextUnderline>{ color: <ColorHex>{ hexCode: 'fdc130' }, thickness: 2 } }, posX: 40, posY: 84, isRelative: false },
      <Content>{ content: <Text>{ text: 'Details', font: 'Roboto-Bold', fontSize: 16, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 24 }, posX: 40, posY: 124, isRelative: false },
      <Content>{ content: <Text>{ text: 'Biography', font: 'Roboto-Bold', fontSize: 12, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 14 }, posX: 130, posY: 124, isRelative: false },
      <Content>{ content: <Text>{ text: screeningReportData.biography, font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 16, maxWidth: 425 }, posX: 130, posY: 12, isRelative: true },
      <Content>{ content: <Text>{ text: 'Reports', font: 'Roboto-Bold', fontSize: 12, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 14 }, posX: 130, posY: 16, isRelative: true },
      <Content>{ content: <Text>{ text: screeningReportData.reports, font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 16, maxWidth: 425 }, posX: 130, posY: 12, isRelative: true },
      <Content>{ content: <Text>{ text: 'Identification', font: 'Roboto-Bold', fontSize: 12, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 14 }, posX: 130, posY: 16, isRelative: true },
      <Content>{ content: <Text>{ text: screeningReportData.identification, font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 16, maxWidth: 425 }, posX: 130, posY: 12, isRelative: true }
      ]
    }
  }

  getRelationshipsConnectionsSection(screeningReportData: ScreeningReportData): Section {
    const linkedCompanies = screeningReportData.linkedCompanies.map(lc => {
      return [<Content>{ content: <Text>{ text: lc, font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 16, maxWidth: 378 }, posX: 130, posY: 12, isRelative: true },
      <Content>{ content: <SvgPath>{ path: 'M 0,0 L 425,0 425,1 0,1 ', height: 1, width: 425, color: <ColorHex>{ hexCode: 'd9d9d9' } }, posX: 130, posY: 4, isRelative: true }]
    }).reduce(function (pre, cur) {
      return pre.concat(cur);
    }, []);

    const linkedIndividuals = screeningReportData.linkedIndividuals.map(li => {
      return [<Content>{ content: <Text>{ text: li, font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 16, maxWidth: 378 }, posX: 130, posY: 12, isRelative: true },
      <Content>{ content: <SvgPath>{ path: 'M 0,0 L 425,0 425,1 0,1 ', height: 1, width: 425, color: <ColorHex>{ hexCode: 'd9d9d9' } }, posX: 130, posY: 4, isRelative: true }]
    }).reduce(function (pre, cur) {
      return pre.concat(cur);
    }, []);

    return <Section>{
      isAbsolute: false,
      isFullPage: false,
      content: [<Content>{ content: <Text>{ text: 'Connections / Relationships', font: 'Roboto-Bold', fontSize: 20, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 24, underline: <TextUnderline>{ color: <ColorHex>{ hexCode: 'fdc130' }, thickness: 2 } }, posX: 40, posY: 49, isRelative: true },
      <Content>{ content: <Text>{ text: 'Linked companies', font: 'Roboto-Bold', fontSize: 12, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 14 }, posX: 130, posY: 16, isRelative: true }]
        .concat(linkedCompanies)
        .concat([<Content>{ content: <Text>{ text: 'Linked individuals', font: 'Roboto-Bold', fontSize: 12, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 14 }, posX: 130, posY: 21, isRelative: true }])
        .concat(linkedIndividuals)
    }
  }

  getExternalSourcesSection(screeningReportData: ScreeningReportData): Section {
    const externalSources = screeningReportData.externalSources.map(es => {
      return [<Content>{ content: <Text>{ text: es, font: 'Roboto-Regular', fontSize: 10, fontColor: <ColorHex>{ hexCode: 'f89f10' }, lineHeight: 16, maxWidth: 425, wordBreaks: [''] }, posX: 130, posY: 12, isRelative: true },
      <Content>{ content: <SvgPath>{ path: 'M 0,0 L 425,0 425,1 0,1 ', height: 1, width: 425, color: <ColorHex>{ hexCode: 'd9d9d9' } }, posX: 130, posY: 7, isRelative: true }]
    }).reduce(function (pre, cur) {
      return pre.concat(cur);
    });

    return <Section>{
      isAbsolute: false,
      isFullPage: false,
      content: [<Content>{ content: <Text>{ text: 'External Sources', font: 'Roboto-Bold', fontSize: 20, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 24, underline: <TextUnderline>{ color: <ColorHex>{ hexCode: 'fdc130' }, thickness: 2 } }, posX: 40, posY: 49, isRelative: true },
      <Content>{ content: <Text>{ text: 'Identification', font: 'Roboto-Bold', fontSize: 12, fontColor: <ColorHex>{ hexCode: '000000' }, lineHeight: 14 }, posX: 130, posY: 16, isRelative: true },
      ].concat(externalSources)
    }
  }

  getDisclaimerSection(): Section {
    const line1 = "The contents of this record are private and confidential and should not be disclosed to third parties unless: (i) the terms of your agreement with Riskallay allow you to do so; (ii) the record subject requests any data that you may hold on them, and such data includes their World-Check record; or (iii) you are under some other legal obligation to do so. You must consider and abide by your own obligations in relation to the data privacy rights of individuals and must notify them of your intention to search against World-Check and provide them with information contained in the World-Check privacy statement :https://www.refinitiv.com/en/products/world-check-kyc-screening/privacy-statement.";
    const line2 = `You shall not rely upon the content of this report without making independent checks to verify the information contained therein. Information correlated is necessarily brief and should be read by you in the context of the fuller details available in the external sources to which links are provided. The accuracy of the information found in the underlying sources must be verified with the record subject before any action is taken and you should inform us if any links to the sources are broken. If this record contains negative allegations, it should be assumed that such allegations are denied by the subject. You should not draw any negative inferences about individuals or entities merely because they are identified in the database, nor because they are shown as """"Reported being linked to"""" others identified in the database. The nature of linking varies considerably. Many persons are included solely because they hold or have held prominent political positions or are connected to such individuals.`;
    return <Section>{
      isAbsolute: false,
      isFullPage: false,
      content: [<Content>{ content: <Text>{ text: 'Refinitiv World-Check Legal Notice :', font: 'Roboto-Regular', fontSize: 6, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 6, maxWidth: 515 }, posX: 40, posY: 24, isRelative: true },
      <Content>{ content: <Text>{ text: line1, font: 'Roboto-Regular', fontSize: 6, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 6, maxWidth: 515 }, posX: 40, posY: 0, isRelative: true },
      <Content>{ content: <Text>{ text: line2, font: 'Roboto-Regular', fontSize: 6, fontColor: <ColorHex>{ hexCode: '383838' }, lineHeight: 6, maxWidth: 515 }, posX: 40, posY: 0, isRelative: true }]
    }
  }

  getLanguageCode(language: Language): string {
    switch (language) {
      case Language.English:
        return "EN";
      case Language.Spanish:
        return "ES";
      case Language.Portuguese:
        return "PT";

      default:
        return "";
    }
  }
}
