import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { NatureOfActivityCreateDTO, NatureOfActivityUpdateDTO } from '@app/models/NatureOfActivity';
import { CommonsService } from '../../../services/commons-service';

@Component({
  selector: 'app-dialog-add-nature',
  templateUrl: './dialog-add-nature.component.html',
  styles: []
})
export class DialogAddNatureComponent implements OnInit {

  form: any;

  constructor(
    public dialogRef: MatDialogRef<DialogAddNatureComponent>,
    private fb: FormBuilder,
    private commons: CommonsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    let entry = this.data;

    this.form = this.fb.group({
      Description: new FormControl(entry !== null ? entry.Description : '', Validators.required),
      DescriptionEN: new FormControl(entry !== null ? entry.DescriptionEN : '', Validators.required),
      DescriptionPT: new FormControl(entry !== null ? entry.DescriptionPT : '', Validators.required),
      DescriptionFR: new FormControl(entry !== null ? entry.DescriptionFR : '', Validators.required),
    });
  }

  // cerrar modal
  onNoClick(): void {
    this.dialogRef.close();
  }

  async addNature() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;

    let n = (<NatureOfActivityCreateDTO>{});
    n.Code = description;
    n.Description = description;
    n.DescriptionEN = descriptionEN;
    n.DescriptionPT = descriptionPT;
    n.DescriptionFR = descriptionFR;
    n.Client = null;
    this.commons.postNature(n)
      .then(r => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }

  async updateNature() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;

    let n = (<NatureOfActivityUpdateDTO>{});
    n.Id = this.data.Id;
    n.Code = description;
    n.Description = description;
    n.DescriptionEN = descriptionEN;
    n.DescriptionPT = descriptionPT;
    n.DescriptionFR = descriptionFR;
    n.Client = null;

    this.commons.putNature(n)
      .then(r => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }
}
