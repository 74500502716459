import { Screening } from "@app/models/scoring-tools/screening";
import { mapUser } from "@app/services/mappings/user.mappings";
import { AMLUpdateScreeningDto } from "src/proxy/model/aMLUpdateScreeningDto";

export function mapAMLUpdateScreening(dto: AMLUpdateScreeningDto): Screening {
  return <Screening>{
    createdBy: mapUser(dto.User),
    id: dto.Id,
    screeningDate: dto.ScreeningDate
  }
}
