import { Component } from '@angular/core';
import { ActiveCampaignService } from "@app/services/active-campaign.service";

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent {

  constructor(public activeCampaign: ActiveCampaignService) { }
}
