import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { RemoteService } from '@app/services/remote.service';
import { ThirdPartyFile } from '@app/models/ThirdPartyFile';
import { MatTableDataSource } from '@angular/material/table';
import { FileType } from '@app/models/FileType';
import { MatDialog } from '@angular/material/dialog';
import { DialogUploadThirdPartyFileComponent } from '../dialog-upload-third-party-file/dialog-upload-third-party-file.component';
import { ThirdPartyDTO } from '@app/models/ThirdParty';
import { canAccess } from '@app/security/riskallay.roles';
import { Role } from '@app/models/Role';
import { AuthenticationService } from '@app/services/authentication.service';

@Component({
  selector: 'app-third-party-table-files',
  templateUrl: './third-party-table-files.component.html',
  styleUrls: ['./third-party-table-files.component.scss'],
})
export class ThirdPartyTableFilesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() thirdParty: ThirdPartyDTO;

  columns = ['Origin', 'FileName', 'UploadDate'];

  isLoading = true;
  locale: string;
  dataSource = new MatTableDataSource<ThirdPartyFile>([]);
  thirdPartyAttachmentMsg: string;
  private subscription: Subscription;

  constructor(
    private thirdPartyService: ThirdPartiesService,
    private translate: TranslateService,
    private remoteService: RemoteService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {

    const userRoles = this.authenticationService.getRoles();

    if(canAccess([Role.Owner, Role.Manager, Role.Auditor, Role.Assistant], userRoles)) {
      this.columns.push('Action')
    }

    this.locale = this.translate.currentLang.split('-')[0];

    this.translate
      .get('ThirdPartyTable.attachedFile')
      .subscribe((translation) => {
        this.thirdPartyAttachmentMsg = translation;
      });

    this.subscription = this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang.split('-')[0];
      }
    );

    this.getThirdPartyFiles();
  }

  getThirdPartyFiles(): void {
    this.isLoading = true;

    this.thirdPartyService
      .getThirdPartyFilesById(this.thirdParty.Id)
      .subscribe((files) => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(
          files.sort((a, b) =>
            a.uploadDate < b.uploadDate
              ? 1
              : a.uploadDate > b.uploadDate
              ? -1
              : 0
          )
        );
        this.dataSource.paginator = this.paginator;
      });
  }

  getFileOrigin(file: ThirdPartyFile): string {
    if (file.documentType === FileType.ThirdPartyAttachment) {
      return this.thirdPartyAttachmentMsg;
    }

    return file.campaignName;
  }

  updateFilter(filter: string): void {
    this.dataSource.filter = filter;
    this.paginator.pageIndex = 0;
  }

  uploadFile(): void {
    const dialogRef = this.dialog.open(DialogUploadThirdPartyFileComponent, {
      data: this.thirdParty,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getThirdPartyFiles();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public download(idfile: string, fileName: string) {
    this.remoteService.downloadMyFile(idfile, fileName);
  }
}
