<div class="table-cell">
  <ng-container *ngIf="editable(); else notEditable">
    <a [routerLink]="['/introduccion-risklist', element.id, element.thirdParties[0].Id]" class="action-column">
      <span class="action-column-icon" aria-hidden="true">
        <app-sign-action-icon *ngIf="element.campaignStatus === CampaignStatus.pendingSign"></app-sign-action-icon>
        <app-fill-action-icon *ngIf="element.campaignStatus !== CampaignStatus.pendingSign"></app-fill-action-icon>
      </span>
      {{ (element.campaignStatus == CampaignStatus.pendingSign?
      'PageId.table.sign' : 'PageId.table.fill') | translate }}
    </a>
  </ng-container>
  <ng-template #notEditable>
    <a [routerLink]="['/introduccion-risklist', element.id, element.thirdParties[0].Id]" class="action-column">
      <span class="action-column-icon" aria-hidden="true">
        <app-see-action-icon></app-see-action-icon>
      </span>
      {{ 'MisRiesgos.actions2.see.valor' | translate }}
    </a>
  </ng-template>

</div>
