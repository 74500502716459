import { mapClient, mapClientToClientDto } from './client.mappings';
import { mapSector, mapSectorDtoToSector, mapSectoroToSectorDto } from './third-party.mappings';
import { QuestionaryDto } from 'src/proxy/model/questionaryDto';
import { QuestionaryBlockDto } from 'src/proxy/model/questionaryBlockDto';
import { Questionary, QuestionaryDTO } from 'src/app/models/Questionary';
import { RiskDto } from 'src/proxy/model/riskDto';
import { RiskDTO } from '@app/models/Risk';
import { mapCountry, mapCountryToCountryDto } from './country.mappings';
import { mapThirdPartyType, mapThirdPartyTypeToThirdPartyTypeDto } from './third-party-type.mappings';
import { QuestionaryBlock, QuestionaryBlockDTO } from '@app/models/QuestionaryBlock';
import { mapRiskDtoToRisk } from './risk.mappings';
import { Question } from '@app/models/Question';
import { QuestionDto } from 'src/proxy/model/questionDto';
import { QuestionaryUpdateDto } from 'src/proxy/model/questionaryUpdateDto';

export function mapQuestionaryDtoToQuestionary(dto: QuestionaryDto, currentLanguage: string): Questionary {
  if (!dto) return null;

  return <Questionary>{
    id: dto.Id,
    client: mapClient(dto.Client, currentLanguage),
    code: dto.Code,
    description: dto.Description,
    thirdPartyType: mapThirdPartyType(dto.ThirdPartyType),
    sector: mapSectorDtoToSector(dto.Sector),
    country: mapCountry(dto.Country, currentLanguage),
    versionCode: dto.VersionCode,
    isMaster: dto.IsMaster,
    questionaryBlocks: dto.QuestionaryBlocks ? dto.QuestionaryBlocks
      .map(questionaryBlock => mapQuestionaryBlockDtoToQuestionaryBlock(questionaryBlock, currentLanguage)) : null,
    creationTime: dto.CreationTime,
    updateTime: dto.UpdateTime,
    amount: dto.Amount,
    rate: dto.Rate,
    comment: dto.Comment
  }
}

export function mapQuestionaryDtoToQuestionaryDTO(dto: QuestionaryDto, currentLanguage: string): QuestionaryDTO {
  return <QuestionaryDTO>{
    Id: dto?.Id,
    Client: dto?.Client ? mapClient(dto?.Client, currentLanguage) : null,
    Code: dto?.Code,
    Description: dto?.Description,
    ThirdPartyType: dto?.ThirdPartyType,
    Sector: mapSector(dto?.Sector, currentLanguage),
    Country: dto?.Country,
    VersionCode: dto?.VersionCode,
    IsMaster: dto?.IsMaster,
    QuestionaryBlocks: dto?.QuestionaryBlocks.map(qb => mapQuestionaryBlockDtoToQuestionaryBlockDTO(qb, currentLanguage)),
    CreationTime: dto?.CreationTime,
    UpdateTime: dto?.UpdateTime,
    Amount: dto?.Amount,
    Rate: dto?.Rate,
    Comment: dto?.Comment
  };
}

export function mapQuestionaryToQuestionaryUpdateDto(dto: Questionary): QuestionaryUpdateDto {
  if (!dto) return null;

  return <QuestionaryUpdateDto>{
    id: dto.id,
    client: mapClientToClientDto(dto.client),
    clientId: dto.client?.id,
    code: dto.code,
    description: dto.description,
    thirdPartyType: mapThirdPartyTypeToThirdPartyTypeDto(dto.thirdPartyType),
    thirdPartyTypeId: dto.thirdPartyType?.id,
    sector: mapSectoroToSectorDto(dto.sector),
    sectorId: dto.sector?.id,
    country: mapCountryToCountryDto(dto.country),
    countryId: dto.country?.id,
    versionCode: dto.versionCode,
    isMaster: dto.isMaster,
    questionaryBlocks: dto.questionaryBlocks,
    amount: dto.amount,
    rate: dto.rate
  };
}

function mapQuestionaryBlockDtoToQuestionaryBlock(dto: QuestionaryBlockDto, currentLanguage: string): QuestionaryBlock {
  if (!dto) return null;

  return <QuestionaryBlock>{
    id: dto.Id,
    questions: dto.Questions ? dto.Questions.map(q => mapQuestionDtoToQuestion(q)) : [],
    risk: mapRiskDtoToRisk(dto.Risk, currentLanguage),
    order: dto.Order,
    scoreWeight: dto.ScoreWeight
  }
}

function mapQuestionaryBlockDtoToQuestionaryBlockDTO(dto: QuestionaryBlockDto, currentLanguage: string): QuestionaryBlockDTO {
  return <QuestionaryBlockDTO>{
    Id: dto?.Id,
    Questions: dto?.Questions,
    Risk: mapRisk(dto?.Risk, currentLanguage),
  }
}

function mapRisk(dto: RiskDto, currentLanguage: string): RiskDTO {
  return <RiskDTO>{
    Id: dto?.Id,
    Code: dto?.Code,
    Client: dto?.Client ? mapClient(dto.Client, currentLanguage) : null,
    Description: dto?.Description,
    DescriptionEN: dto?.DescriptionEN,
    DescriptionPT: dto?.DescriptionPT,
    DescriptionFR: dto?.DescriptionFR
  }
}

function mapQuestionDtoToQuestion(dto: QuestionDto): Question {
  if (!dto) return null;

  return <Question>{
    id: dto.Id,
    name: dto.Name,
    description: dto.Description,
    canAddComments: dto.CanAddComments,
    mustAddComments: dto.MustAddComments,
    canAddDoc: dto.CanAddDoc,
    mustAddDoc: dto.MustAddDoc,
    weightYes: dto.WeightYes,
    weightNo: dto.WeightNo,
    docTitle: dto.DocTitle,
    order: dto.Order,
    options: dto.Options,
    isMultipleChoice: dto.IsMultipleChoice,
    selectedOption: dto.SelectedOption,
    isAlternativeScorinig: dto.IsAlternativeScorinig,
    weightHasComment: dto.WeightHasComment,
    weightHasDoc: dto.WeightHasDoc,
    weightNoComment: dto.WeightNoComment,
    weightNoDoc: dto.WeightNoDoc
  }
}
