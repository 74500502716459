<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'deleteTemplate.title1.valor' | translate}}</h1>
        <button mat-button (click)="close()" class="c-dialog__close" aria-label="Cerrar ventana modal" data-test-id="close-modal-btn">
        <span aria-hidden="true"> 
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                <path d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243" transform="translate(-309.86-406.47)"/>
            </svg>
        </span>
      </button>
    </header>
    <div class="c-dialog__content">
        <h2 class="c-dialog__subtitle">{{'deleteTemplate.title2.valor' | translate}}</h2>
        <p class="subtitle-comment">{{'deleteTemplate.title3.valor' | translate}}</p>
        <div class="c-dialog-info u-mb-1medium">
            <div class="c-dialog-info__left" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.5 20.484">
            <g transform="translate(-320.13-411.06)">
              <path d="m340.34 419.49l-3.23-2.826v-4.762a.836 .836 0 0 0 -.833 -.836c-.478-.006-1.734-.009-3.731-.009-2.847 0-6.446.007-7.628.009h-.441a.837 .837 0 0 0 -.836 .836v4.762l-3.23 2.826a.837 .837 0 0 0 -.285 .629v9.414a2.011 2.011 0 0 0 2.008 2.008h16.484a2.011 2.011 0 0 0 2.008 -2.008v-9.414a.835 .835 0 0 0 -.286 -.629m-18.11.629l1.41-1.234v2.468m13.469 0v-2.468l1.411 1.234m.433 1.842v6.9l-3.942-3.451m-4.858-1.643a.339 .339 0 0 1 .442 0l6.969 6.1h-14.38m5.869-7.358l-2.044 1.791-1.7-1.484v-10.08h10.125v10.08l-1.7 1.484-2.045-1.791a2.014 2.014 0 0 0 -2.645 0m-3.304 2.9l-3.944 3.453v-6.9"/>
              <path d="M329.556,420.117a2.309,2.309,0,0,1-1.768-.866L326.5,417.9a1.117,1.117,0,0,1-.067-1.393.747.747,0,0,1,1.1-.151.822.822,0,0,1,.074.071l1.321,1.394a.789.789,0,0,0,.6.325.775.775,0,0,0,.614-.29l3-3.382a.758.758,0,0,1,1.16.062,1.118,1.118,0,0,1-.009,1.37l-2.991,3.365A2.291,2.291,0,0,1,329.556,420.117Z"/>
            </g>
          </svg>
            </div>
            <div class="c-dialog-info__right">
                <h3 class="c-dialog__thirdtitle" data-test-id="code">{{(data.code || data.Code)}}</h3>
                <div class="c-dialog-data">
                    <div class="c-dialog-data__item">
                        <div class="c-dialog-data__ico color" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z"/>
                    </svg>
                        </div>
                        <div data-test-id="third-party-type">{{(data.thirdPartyType || data.ThirdPartyType) | translatedescription}}</div>
                    </div>
                    <div class="c-dialog-data__item">
                        <div class="c-dialog-data__ico color" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm6.667,8a6.625,6.625,0,0,1-1.108,3.672l-.795-.8a.333.333,0,0,1-.1-.235V10a2,2,0,0,0-2-2h-2A.667.667,0,0,1,8,7.333V7a.333.333,0,0,1,.333-.333A1.667,1.667,0,0,0,10,5V4.333A.333.333,0,0,1,10.333,4h.919a1.677,1.677,0,0,0,1.178-.488l.251-.251A6.646,6.646,0,0,1,14.667,8Zm-13.3.642,2.105,2.105a1.985,1.985,0,0,0,1.415.586H6.667A.667.667,0,0,1,7.333,12v2.633A6.677,6.677,0,0,1,1.366,8.642Zm7.3,5.991V12a2,2,0,0,0-2-2H4.886a.667.667,0,0,1-.472-.2L1.442,6.833a6.652,6.652,0,0,1,10.2-4.413l-.149.149a.338.338,0,0,1-.235.1h-.919A1.667,1.667,0,0,0,8.667,4.333V5a.333.333,0,0,1-.333.333A1.667,1.667,0,0,0,6.667,7v.333a2,2,0,0,0,2,2h2a.667.667,0,0,1,.667.667v.641a1.677,1.677,0,0,0,.488,1.178l.891.891A6.647,6.647,0,0,1,8.667,14.633Z"/>
                    </svg>
                        </div>
                        <div data-test-id="country">{{(data.country || data.Country) | translatedescription}}</div>
                    </div>
                    <div class="c-dialog-data__item">
                        <div class="c-dialog-data__ico color" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M12.667,2.667h-.733A3.339,3.339,0,0,0,8.667,0H7.333A3.339,3.339,0,0,0,4.067,2.667H3.333A3.337,3.337,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6a3.337,3.337,0,0,0-3.333-3.333ZM7.333,1.333H8.667a2,2,0,0,1,1.877,1.333H5.456A2,2,0,0,1,7.333,1.333ZM3.333,4h9.333a2,2,0,0,1,2,2V8H1.333V6a2,2,0,0,1,2-2Zm9.333,10.667H3.333a2,2,0,0,1-2-2V9.333h6V10a.667.667,0,0,0,1.333,0V9.333h6v3.333A2,2,0,0,1,12.667,14.667Z"/>
                    </svg>
                        </div>
                        <div data-test-id="sector">{{(data.sector || data.Sector) | translatedescription}}</div>
                    </div>
                    <div class="c-dialog-data__item">
                        <div class="c-dialog-data__ico color" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 16 16.002">
                        <path d="M15.482,3.829,13.474,1.117A2.67,2.67,0,0,0,11.3,0H4.7A2.667,2.667,0,0,0,2.533,1.1L.49,3.836a2.662,2.662,0,0,0,.082,3.19l5.862,8.22A1.99,1.99,0,0,0,8,16H8a2,2,0,0,0,1.586-.783L15.4,7.09a2.667,2.667,0,0,0,.08-3.261ZM12.4,1.9,14.408,4.62c.011.015.013.032.024.047H11.17l-.952-3.334H11.3A1.341,1.341,0,0,1,12.4,1.9ZM8,12.747,6.2,6H9.8ZM6.218,4.667,7.17,1.333H8.831l.952,3.334ZM3.611,1.892A1.338,1.338,0,0,1,4.7,1.333H5.783L4.831,4.667H1.545c.009-.015.012-.033.023-.047ZM1.632,6.217A1.267,1.267,0,0,1,1.509,6H4.822l2,7.488Zm7.55,7.278,2-7.494h3.324a1.4,1.4,0,0,1-.162.281Z" transform="translate(.0001)"/>
                    </svg>
                        </div>
                        <div data-test-id="amount">{{(data.amount || data.Amount)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="c-dialog__footer c-dialog__footer--full">
            <button mat-button (click)="close()" class="c-button c-button--light" data-test-id="cancel-btn">{{'deleteTemplate.cancel.valor' | translate}}</button>
            <button type="submit" mat-button cdkFocusInitial (click)="delete()" routerLinkActive="is-active" class="c-button c-button--green" data-test-id="submit-btn">{{'deleteTemplate.accept.valor' | translate}}</button>
        </footer>
    </div>