import { EquifaxWSAddressData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSAddressData";
import { EquifaxWSImmovablePropertyData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSImmovablePropertyData";
import { EquifaxWSIndividualBasicData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSIndividualBasicData";
import { EquifaxWSIndividualData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSIndividualData";
import { EquifaxWSLien } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSLien";
import { EquifaxWSMaritalStatusData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSMaritalStatusData";
import { EquifaxWSMortgageCreditData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSMortgageCreditData";
import { EquifaxWSMovablePropertyData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSMovablePropertyData";
import { EquifaxWSPledgeCreditData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSPledgeCreditData";
import { EquifaxWSRecord } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSRecord";
import { EquifaxWSRelative } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSRelative";
import { EquifaxWSTelephoneData } from "@app/modules/screening-hub/models/EquifaxWS/EquifaxWSTelephoneData";
import { EquifaxWSAddress } from "src/proxy/apiEquifaxWS/model/equifaxWSAddress";
import { EquifaxWSImmovableProperty } from "src/proxy/apiEquifaxWS/model/equifaxWSImmovableProperty";
import { EquifaxWSIndividualGeneralData } from "src/proxy/apiEquifaxWS/model/equifaxWSIndividualGeneralData";
import { EquifaxWSIndividualRecord } from "src/proxy/apiEquifaxWS/model/equifaxWSIndividualRecord";
import { EquifaxWSLienDto } from "src/proxy/apiEquifaxWS/model/equifaxWSLienDto";
import { EquifaxWSMaritalStatus } from "src/proxy/apiEquifaxWS/model/equifaxWSMaritalStatus";
import { EquifaxWSMortgageCredit } from "src/proxy/apiEquifaxWS/model/equifaxWSMortgageCredit";
import { EquifaxWSMovableProperty } from "src/proxy/apiEquifaxWS/model/equifaxWSMovableProperty";
import { EquifaxWSPledgeCredit } from "src/proxy/apiEquifaxWS/model/equifaxWSPledgeCredit";
import { EquifaxWSRecordDto } from "src/proxy/apiEquifaxWS/model/equifaxWSRecordDto";
import { EquifaxWSRelativesDataDto } from "src/proxy/apiEquifaxWS/model/equifaxWSRelativesDataDto";
import { EquifaxWSTelephone } from "src/proxy/apiEquifaxWS/model/equifaxWSTelephone";

export function mapEquifaxWSRecord(dto: EquifaxWSRecordDto): EquifaxWSRecord {
  if (!dto) return null;

  return <EquifaxWSRecord>{
    cedula: dto.CompanyRecord?.Cedula ?? dto.IndividualRecord?.Cedula ?? '',
    name: dto.CompanyRecord?.Name ?? dto.IndividualRecord?.Name ?? '',
    individualData: mapIndividualData(dto.IndividualRecord),
    creationDate: dto.CreationDate,
    report: dto.Report
  };
}

function mapIndividualData(dto: EquifaxWSIndividualRecord): EquifaxWSIndividualData {

  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSIndividualData>{
    basicData: mapIndividualBasicData(dto.GeneralData),
    cedula: dto.Cedula,
    estimatedIncome: dto.EstimatedIncome,
    estimatedIncome12Month: dto.EstimatedIncome12Month,
    estimatedIncome3Month: dto.EstimatedIncome3Month,
    estimatedIncome6Month: dto.EstimatedIncome6Month,
    father: mapRelative(dto.Father),
    mother: mapRelative(dto.Mother),
    name: dto.Name,
    offspring: dto.Offspring?.map(r => mapRelative(r)),
    siblings: dto.Siblings?.map(r => mapRelative(r)),
    currentMaritalStatus: mapMaritalStatus(dto.CurrentMaritalStatus),
    maritalStatusHistoric: dto.MaritalStatusHistoric?.map(m => mapMaritalStatus(m)),
    addresses: dto.Addresses?.map(a => mapAddress(a)),
    telephones: dto.Telephones?.map(t => mapTelephone(t)),
    immovableProperties: dto.ImmovableProperties?.map(i => mapImmovableProperty(i)),
    movableProperties: dto.MovableProperties?.map(m => mapMovableProperty(m)),
    pledgeCredits: dto.PledgeCredits?.map(p => mapPledgeCredit(p)),
    mortgageCredits: dto.MortgageCredits?.map(m => mapMortgageCredit(m))
  };
}

function mapIndividualBasicData(dto: EquifaxWSIndividualGeneralData): EquifaxWSIndividualBasicData {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSIndividualBasicData>{
    administrativeDistrict: dto.AdministrativeDistrict,
    age: dto.Age,
    birthDate: dto.BirthDate,
    birthPlace: dto.BirthPlace,
    cedula: dto.Cedula,
    cedulaExpDate: dto.CedulaExpDate,
    electoralCanton: dto.ElectoralCanton,
    electoralDistrict: dto.ElectoralDistrict,
    electoralProvince: dto.ElectoralProvince,
    gender: dto.Gender,
    lastname: dto.Lastname,
    lastname2: dto.Lastname2,
    name: dto.Name
  };
}

function mapRelative(dto: EquifaxWSRelativesDataDto): EquifaxWSRelative {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSRelative>{
    age: stringToNumber(dto.Age),
    cedula: dto.Cedula,
    kinship: dto.Kinship,
    name: dto.Name,
    nationality: dto.Nationality
  };
}

function mapMaritalStatus(dto: EquifaxWSMaritalStatus): EquifaxWSMaritalStatusData {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSMaritalStatusData>{
    eventDate: dto.EventDate,
    maritalStatus: dto.MaritalStatus,
    partnerCedula: dto.PartnerCedula,
    partnerName: dto.PartnerName
  };
}

function mapAddress(dto: EquifaxWSAddress): EquifaxWSAddressData {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSAddressData>{
    address: dto.Address,
    date: dto.Date,
    name: dto.Name,
    telephone: dto.Telephone
  };
}

function mapTelephone(dto: EquifaxWSTelephone): EquifaxWSTelephoneData {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSTelephoneData>{
    type: dto.Type,
    date: dto.Date,
    name: dto.Name,
    number: dto.Number
  };
}

function mapImmovableProperty(dto: EquifaxWSImmovableProperty): EquifaxWSImmovablePropertyData {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSImmovablePropertyData>{
    location: dto.Location,
    purpose: dto.Purpose,
    register: dto.Register,
    fiscalValue: dto.FiscalValue,
    planNumber: dto.PlanNumber,
    presentation: dto.Presentation,
    size: dto.Size,
    value: dto.Value,
    liens: dto.Liens?.map(l => mapLien(l))
  };
}

function mapMovableProperty(dto: EquifaxWSMovableProperty): EquifaxWSMovablePropertyData {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSMovablePropertyData>{
    brand: dto.Brand,
    model: dto.Model,
    plate: dto.Plate,
    value: dto.Value,
    year: dto.Year,
    liens: dto.Liens?.map(l => mapLien(l))
  };
}

function mapLien(dto: EquifaxWSLienDto): EquifaxWSLien {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSLien>{
    amount: dto.Amount,
    creditor: dto.Creditor,
    currency: dto.Currency,
    expDate: dto.ExpDate,
    registrationDate: dto.RegistrationDate,
    startDate: dto.StartDate,
    term: dto.Term,
    type: dto.Type,
    partialCancels: dto.PartialCancels
  };
}

function mapPledgeCredit(dto: EquifaxWSPledgeCredit): EquifaxWSPledgeCreditData {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSPledgeCreditData>{
    cedula: dto.Cedula,
    collateral: dto.Collateral,
    creditorCedula: dto.CreditorCedula,
    creditorName: dto.CreditorName,
    currency: dto.Currency,
    entry: dto.Entry,
    expDate: dto.ExpDate,
    goodAmount: dto.GoodAmount,
    pledgeAmount: dto.PledgeAmount,
    quota: dto.Quota,
    remainingQuotas: dto.RemainingQuotas,
    sequence: dto.Sequence,
    startDate: dto.StartDate,
    status: dto.Status,
    term: dto.Term,
    type: dto.Type,
    volume: dto.Volume
  };
}

function mapMortgageCredit(dto: EquifaxWSMortgageCredit): EquifaxWSMortgageCreditData {
  if (dto === null || dto === undefined)
    return null;

  return <EquifaxWSMortgageCreditData>{

  };
}

function stringToNumber(str: string): number {
  if (!isNaN(Number(str))) {
    return Number(str);
  } else {
    0
  }
}
