import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/services/authentication.service';
import { Role } from '@app/models/Role';
import { canAccess } from './riskallay.roles';

@Injectable({
  providedIn: 'root'
})
export class EsAdminUserGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService,
    private router: Router) {}

  private activationRoles = [Role.Owner, Role.Manager, Role.Collaborator, Role.Auditor, Role.Assistant];

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const userRoles = this.authenticationService.getRoles();
    if (canAccess(this.activationRoles, userRoles)) {
      return true;
    }

    this.router.navigate(['/login']);
      return false;
  }
}
