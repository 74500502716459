import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { RemoteService } from '@app/services/remote.service'
import { GlobalService } from '@app/services/global.service';
import { base64ToArrayBuffer } from '@app/utilities/utilities'
import { DialogConfirmThirdMassiveComponentOk } from '@modules/thirdparties/dialog-confirm-third-massive-ok/dialog-confirm-third-massive-ok.component'
import { DialogConfirmThirdMassiveComponentKo } from '@modules/thirdparties/dialog-confirm-third-massive-ko/dialog-confirm-third-massive-ko.component'
import { BulkLoadDTO } from '@app/models/BulkLoad';
import { Toaster } from 'ngx-toast-notifications';
import { DialogRequestThirdPartiesComponent } from '../dialog-request-more-third-parties/dialog-request-more-third-parties.component';

@Component({
  selector: 'app-third-import-massive',
  templateUrl: './third-import-massive.component.html',
  styleUrls: ['./third-import-massive.component.scss']
})
export class ThirdImportMassiveComponent implements OnInit {

    file: File = null
    responsibles: any[]
    selectedResponsible: any
    loading = false
    uploadButtonText: string;
    uploadErrorText: string;
    formatErrorText: string;
    requestSentMessage: string;

    constructor(
        private remote: RemoteService,
        public global: GlobalService,
        private location: Location,
        private dialog: MatDialog,
        private router: Router,
        private globalService: GlobalService,
        private thirdPartiesService: ThirdPartiesService,
        private translate: TranslateService,
        private toast: Toaster
    ) {
        this.getThirdPartiesTemplate = this.getThirdPartiesTemplate.bind(this)
    }

    async ngOnInit() {
        this.globalService.activeThird();

        this.translate.get('MassiveLoad').subscribe(
            (translation) => {
                this.uploadButtonText = translation.uploadText.valor;
                this.uploadErrorText = translation.uploadError;
                this.formatErrorText = translation.formatError;
                this.requestSentMessage = translation.requestSent;
            }
        );

        this.responsibles = await this.remote.getRequest('Commons/Responsibles')
        if (this.responsibles.length > 0) {
            this.selectedResponsible = this.responsibles[0];
        }
    }

    public objectComparisonFunction = function (option, value): boolean {
        if (option && value) {
          return option.Id === value.Id;
        }
        else {
          return false;
        }
    }

    back() {
        this.location.back();
        this.globalService.closeThird();
    }

    selectThirdPartiesExcel(resp) {
        this.file = resp.file
    }

    async getThirdPartiesTemplate() {
        this.loading = true;
        let fileBytes = await this.remote.getRequest('ThirdParties/DownloadThirdPartiesTemplate')
        this.loading = false;
        return fileBytes;
    }

    async checkThirdPartiesExcel() {
        this.loading = true;
        const model: BulkLoadDTO = {
            Save: false,
            UserResponsibleId: this.selectedResponsible.Id,
            File: this.file
          };

        this.thirdPartiesService.checkThirdPartiesExcel(model)
            .subscribe(result => {
                this.loading = false
                if(result == null){
                    this.openDialogNoData();
                }
                else if (result.thirdPartiesCreditLeft < 0) {
                    this.openRequestDialog(result.thirdPartiesCount);
                }
                else if(result.errorsCount == 0) {
                    this.openDialogOk(result)
                }
                else {
                    this.openDialogKo(result)
                }
            },
            _ => {
                this.toast.open({
                    text: this.formatErrorText,
                    duration: 10000,
                    type: 'danger'
                })
                this.loading = false
            });
    }

    openDialogOk(result) {
        const dialogRef = this.dialog.open(
            DialogConfirmThirdMassiveComponentOk,
            {
                data: { totalLoaded: result.thirdPartiesCount }
            }
        )

        dialogRef.afterClosed().subscribe(saveThirdParties => {
            if (saveThirdParties) {
                this.loading = true
                const model: BulkLoadDTO = {
                    Save: true,
                    UserResponsibleId: this.selectedResponsible.Id,
                    File: this.file
                  };

                this.thirdPartiesService.checkThirdPartiesExcel(model)
                    .subscribe(result => {
                        this.loading = false
                        this.global.uploadedThirdParties = result.thirdPartiesDto
                        this.router.navigate(['/importar-terceros-carga-masiva-realizada'])
                    },
                    _ => {
                        this.loading = false
                        this.toast.open({
                            text: this.uploadErrorText,
                            duration: 10000,
                            type: 'danger'
                        })
                    })
            }
        })
    }

    openDialogKo(result) {
        const dialogRef = this.dialog.open(
            DialogConfirmThirdMassiveComponentKo,
            {
                data: {
                    totalLoaded: result.thirdPartiesCount,
                    totalErrors: result.errorsCount,
                }
            }
        )

        dialogRef.afterClosed().subscribe(dowloadErrors => {
            if (dowloadErrors) {
                let myBlob = new Blob([base64ToArrayBuffer(result.fileContents)], { type: 'octect/stream' })
                let urlexcel = window.URL.createObjectURL(myBlob)
                var link = document.createElement('a')
                link.href = urlexcel
                link.download = "results.xlsx"
                link.click()
                setTimeout(() => { window.URL.revokeObjectURL(urlexcel) }, 10)
            }
        })
    }

    openDialogNoData() {
        const dialogRef = this.dialog.open(
            DialogConfirmThirdMassiveComponentKo,
            {
                data: {
                    totalLoaded: 0,
                    totalErrors: 0,
                }
            });
    }

    openRequestDialog(totalLoaded: number) {
        const dialogRef = this.dialog.open(DialogRequestThirdPartiesComponent, { data: { totalLoaded: totalLoaded }});

        dialogRef.afterClosed().subscribe(r => {
          if(r) {
            this.toast.open({ text: this.requestSentMessage, duration: 6000, type: 'info' });
          }
        })
    }
}
