<ul class="c-statistics">
    <li class="c-statistics-item">
        <div class="c-statistics-item__left" aria-hidden="true">
            <img src="/assets/ico-third-large.svg" alt="">
        </div>
        <div class="c-statistics-item__right">
            <p class="c-statistics-item__value">{{ total }}</p>
            <p class="c-statistics-item__text">{{'assesmentDetailStatistics.thirdParties' | translate}}</p>
        </div>
    </li>
    <li class="c-statistics-item">
        <div class="c-statistics-item__left" aria-hidden="true">
            <img src="/assets/campaingStatusPendingResponse.svg" alt="">
        </div>
        <div class="c-statistics-item__right">
            <p class="c-statistics-item__value">{{ pendingResponse }}</p>
            <p class="c-statistics-item__text">{{'assesmentDetailStatistics.pending' | translate}}</p>
        </div>
    </li>
    <li class="c-statistics-item">
        <div class="c-statistics-item__left" aria-hidden="true">
            <img src="/assets/campaignStatusInProgress.svg" alt="">
        </div>
        <div class="c-statistics-item__right">
            <p class="c-statistics-item__value">{{ inProgress }}</p>
            <p class="c-statistics-item__text">{{'assesmentDetailStatistics.inProgress' | translate}}</p>
        </div>
    </li>
    <li class="c-statistics-item">
        <div class="c-statistics-item__left" aria-hidden="true">
            <img src="/assets/campaignStatusPendingSign.svg" alt="">
        </div>
        <div class="c-statistics-item__right">
            <p class="c-statistics-item__value">{{ pendingSign }}</p>
            <p class="c-statistics-item__text">{{'assesmentDetailStatistics.pendingSign' | translate}}</p>
        </div>
    </li>
    <li class="c-statistics-item">
        <div class="c-statistics-item__left" aria-hidden="true">
            <img src="/assets/campaignStatusPendingReview.svg" alt="">
        </div>
        <div class="c-statistics-item__right">
            <p class="c-statistics-item__value">{{ pendingReview }}</p>
            <p class="c-statistics-item__text">{{'assesmentDetailStatistics.pendingReview' | translate}}</p>
        </div>
    </li>
    <li class="c-statistics-item">
        <div class="c-statistics-item__left" aria-hidden="true">
            <img src="/assets/campaignStatusAcknowledged.svg" alt="">
        </div>
        <div class="c-statistics-item__right">
            <p class="c-statistics-item__value">{{ acknowledged }}</p>
            <p class="c-statistics-item__text">{{'assesmentDetailStatistics.acknowledged' | translate}}</p>
        </div>
    </li>
    <li class="c-statistics-item">
        <div class="c-statistics-item__left" aria-hidden="true">
            <img src="/assets/campaignStatusDiscarded.svg" alt="">
        </div>
        <div class="c-statistics-item__right">
            <p class="c-statistics-item__value">{{ discarded }}</p>
            <p class="c-statistics-item__text">{{'assesmentDetailStatistics.discarded' | translate}}</p>
        </div>
    </li>
    <li class="c-statistics-item">
        <div class="c-statistics-item__left" aria-hidden="true">
            <img src="/assets/campaignStatusReviewed.svg" alt="">
        </div>
        <div class="c-statistics-item__right">
            <p class="c-statistics-item__value">{{ reviewed }}</p>
            <p class="c-statistics-item__text">{{'assesmentDetailStatistics.reviewed' | translate}}</p>
        </div>
    </li>
</ul>
