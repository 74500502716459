import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { Questionary } from '@app/models/Questionary';

@Component({
    selector: 'app-questionary-weights',
    templateUrl: './questionary-weights.component.html',
    styleUrls: ['./questionary-weights.component.scss']
})
export class QuestionaryWeightsComponent implements OnInit {

    @Input() questionary: Questionary;
    @Input() canEdit: boolean;

    formDisabled: boolean;
    form: FormGroup;
    useWeights: boolean;

    get blocks() {
        return this.form.get("bloques") as FormArray;
    }

    constructor(public rootFormGroup: FormGroupDirective) { }

    ngOnInit(): void {
        this.form = this.rootFormGroup.control;
        this.useWeights = this.form.get("UseWeights").value;
        this.modifyUseWeights();
        this.form.markAsPristine();
        this.formDisabled = this.questionary.isMaster || !this.canEdit;
    }

    modifyUseWeights() {
        if (this.useWeights) {
            this.enableUsingWeights();
            this.form.get('UseWeights').setValue(true);
        } else {
            this.disableUsingWeights();
            this.form.get('UseWeights').setValue(false);
        }
    }

    private enableUsingWeights(): void {
        for(const block of this.blocks.controls) {
            if(this.canEdit && !this.formDisabled) {
                block.get('Weight').enable();
            }
        }
    
        this.form.markAsDirty();
    }

    private disableUsingWeights(): void {
        const blocks = this.blocks.controls;
        const averageWeightValue = (100 / blocks.length).toFixed(2);

        for(const block of blocks) {
            block.get('Weight').setValue(+averageWeightValue);
            block.get('Weight').disable();
        }

        this.form.markAsDirty();
    }
}
