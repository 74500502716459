export enum ScreeningProvider {
  Refinitiv = 1,
  AMLUpdate = 2,
  EquifaxWS = 3,
  MemberCheck = 4
}

export function mapProviderName(position: ScreeningProvider, translations: {[key:string]: string}): string {
  switch (position) {
    case ScreeningProvider.Refinitiv:
      return 'Refinitiv';
    case ScreeningProvider.AMLUpdate:
      return 'AML Update - Gesintel';
    case ScreeningProvider.EquifaxWS:
      return 'Equifax'
    case ScreeningProvider.MemberCheck:
      return translations['memberCheck'];
    default:
      return 'Other';
  }
}
