/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RefinitivCredentialsDto } from './model/refinitivCredentialsDto';
import { Configuration } from '../configuration';
import { AMLUpdateCredentialsDto } from './model/aMLUpdateCredentialsDto';
import { EquifaxWSCredentialsDto } from './model/equifaxWSCredentialsDto';


@Injectable()
export class ScoringToolsConfigurationProxyClient {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public scoringToolsConfigurationAmlUpdateConfigPost(body?: AMLUpdateCredentialsDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public scoringToolsConfigurationAmlUpdateConfigPost(body?: AMLUpdateCredentialsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public scoringToolsConfigurationAmlUpdateConfigPost(body?: AMLUpdateCredentialsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public scoringToolsConfigurationAmlUpdateConfigPost(body?: AMLUpdateCredentialsDto, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<boolean>('post', `${this.basePath}/ScoringToolsConfiguration/amlUpdateConfig`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param software
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public scoringToolsConfigurationDeleteSoftwareDelete(software: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public scoringToolsConfigurationDeleteSoftwareDelete(software: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public scoringToolsConfigurationDeleteSoftwareDelete(software: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public scoringToolsConfigurationDeleteSoftwareDelete(software: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (software === null || software === undefined) {
      throw new Error('Required parameter software was null or undefined when calling scoringToolsConfigurationDeleteSoftwareDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<boolean>('delete', `${this.basePath}/ScoringToolsConfiguration/delete/${encodeURIComponent(String(software))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public scoringToolsConfigurationEquifaxWSConfigPost(body?: EquifaxWSCredentialsDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public scoringToolsConfigurationEquifaxWSConfigPost(body?: EquifaxWSCredentialsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public scoringToolsConfigurationEquifaxWSConfigPost(body?: EquifaxWSCredentialsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public scoringToolsConfigurationEquifaxWSConfigPost(body?: EquifaxWSCredentialsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


      let headers = this.defaultHeaders;

      // authentication (Bearer) required
      if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
          headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'text/plain',
          'application/json',
          'text/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
          'application/json-patch+json',
          'application/json',
          'text/json',
          'application/_*+json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
          headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<boolean>('post',`${this.basePath}/ScoringToolsConfiguration/equifaxWSConfig`,
          {
              body: body,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public scoringToolsConfigurationGet(observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
  public scoringToolsConfigurationGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
  public scoringToolsConfigurationGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
  public scoringToolsConfigurationGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<number>>('get', `${this.basePath}/ScoringToolsConfiguration`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public scoringToolsConfigurationRefinitivConfigPost(body?: RefinitivCredentialsDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public scoringToolsConfigurationRefinitivConfigPost(body?: RefinitivCredentialsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public scoringToolsConfigurationRefinitivConfigPost(body?: RefinitivCredentialsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public scoringToolsConfigurationRefinitivConfigPost(body?: RefinitivCredentialsDto, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<boolean>('post', `${this.basePath}/ScoringToolsConfiguration/refinitivConfig`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
