<div role="dialog" class="c-dialog" aria-labelledby="titleDialog" style="display:block;visibility:visible;">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{ 'bulkStatusUpdateDialog.title' | translate }}</h1>
        <button mat-button (click)="onClose()" class="c-dialog__close"
            attr.aria-label="{{ 'bulkStatusUpdateDialog.closeModalWindow' | translate }}">
            <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path
                        d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
                        transform="translate(-309.86-406.47)" />
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content small-padding">
        <h2 class="c-dialog__subtitle">{{ ('bulkStatusUpdateDialog.subtitle' |
            translate).replace('${totalThirdParties}', data.length - pendingThirdParties) }}</h2>
        <div class="c-fields">
            <p class="o-text">{{ 'bulkStatusUpdateDialog.text' | translate }}</p>
            <div class="row u-mt-2">
                <div class="col-5">
                    <h3 class="o-title o-title--medium">{{ 'bulkStatusUpdateDialog.currentState' | translate }}</h3>
                    <div class="current-status" *ngFor="let thirdPartyStatus of nonPendingThirdParties">
                        <p>{{thirdPartyStatus.thirdParties}}</p>
                        <app-third-party-status [status]="thirdPartyStatus.status"></app-third-party-status>
                    </div>
                </div>
                <div class="col-5 offset-2">
                    <div class="c-form__block">
                        <mat-form-field class="c-select">
                            <mat-select placeholder="{{ 'bulkStatusUpdateDialog.newState' | translate }}"
                                [(ngModel)]="selectedStatus">
                                <mat-option value="1">
                                    <app-third-party-status [status]="1"></app-third-party-status>
                                </mat-option>
                                <mat-option value="2">
                                    <app-third-party-status [status]="2"></app-third-party-status>
                                </mat-option>
                                <mat-option value="3">
                                    <app-third-party-status [status]="3"></app-third-party-status>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row u-mt-2" *ngIf="pendingThirdParties > 0">
                <div class="col-12 d-flex disabled">
                    <p class="o-text">{{pendingThirdParties}} *</p>
                    <app-third-party-status [status]="ThirdPartyStatus.pending"></app-third-party-status>
                </div>
                <div class="col-12 u-mt-1">
                    <p class="o-text">{{'bulkStatusUpdateDialog.pendingStatusNonModifiable' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <footer class="c-dialog__footer">
        <mat-dialog-actions>
            <button class="c-button" mat-button [mat-dialog-close]="false">
                {{'bulkStatusUpdateDialog.cancel' | translate}}
            </button>
            <button class="c-button c-button--green" mat-button (click)="updateBulkStatus()"
                [disabled]="!selectedStatus">
                {{ 'bulkStatusUpdateDialog.changeState' | translate }}
            </button>
        </mat-dialog-actions>
    </footer>
</div>