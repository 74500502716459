import { Component, OnInit, Input, ElementRef, AfterContentInit } from '@angular/core';
import { TableTemplateConfiguration } from '../../../utilities/utilities';
import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RemoteService } from '../../../services/remote.service';
import { MatTableDataSource } from '@angular/material/table';
import { QuestionaryDTO } from '../../../models/Questionary';
import { SelectionModel } from '@angular/cdk/collections';
import { MatRadioChange } from '@angular/material/radio';
import { TemplateBlockerService } from '../../../services/template-blocker.service';
import { Toaster } from 'ngx-toast-notifications';
import { Router } from '@angular/router';
import { QuestionariesService } from '../../../services/questionaries-service';
import { MatDialog } from '@angular/material/dialog';
import { DialogDoubleTemplateComponent } from '../dialog-double-template/dialog-double-template.component'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-template-table',
  templateUrl: './template-table.component.html',
  styleUrls: ['./template-table.component.scss']
})
export class TemplateTableComponent implements OnInit, AfterContentInit {

  @Input() config: TableTemplateConfiguration;
  dataSource: any;
  selection = new SelectionModel<QuestionaryDTO>(true, []);
  isLoading: boolean = true;
  filtertypes: any;
  selectedType: string = "All";
  translations: any;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild("input", { static: true }) input: ElementRef;

  constructor(
    private remote: RemoteService,
    private blocker: TemplateBlockerService,
    private toaster: Toaster,
    public router: Router,
    private qservice: QuestionariesService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {  }

  ngAfterContentInit(): void {
    this.isLoading = true;
    this.loadData();

  }

  ngOnInit(): void {
    if (this.config.filterByTypes) {
      this.filtertypes = [];
      this.filtertypes.push({ 'name': 'all', code: 'All', 'checked': true })
      this.filtertypes.push({ 'name': 'master', code: 'master', 'checked': false })
      this.filtertypes.push({ 'name': 'mytemplates', code: 'mytemplates', 'checked': false })
    }
    this.translate.get('TablaPlantillas').subscribe(t => this.translations = t);
  }

  loadData() {
    this.remote.getRequest("Questionaries").then(async r => {
      r.sort((a: QuestionaryDTO, b: QuestionaryDTO) => +new Date(b.CreationTime) - +new Date(a.CreationTime));
      this.isLoading = true;
      this.dataSource = new MatTableDataSource(r);
      this.overrideFilter();
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel<QuestionaryDTO>(true, []);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {

        switch (property) {
          case 'id': {
            return item['Id'];
          }
          default: {
            return item['Code'];
          }
        }
      };

       this.isLoading = false;
    }).catch(e => {
      this.isLoading = false;
    })
  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value.trim();

    this.dataSource.filter = this.selectedType + "#<->#" + filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  overrideFilter() {
    let _this = this;
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      var inputFilter = filter.split("#<->#")[1];
      if (_this.selectedType.toLowerCase() == 'all') {
        if (filter == null || filter == undefined || inputFilter.trim().length == 0) {
          return true
        }
        else {
          return String(data.ThirdPartyType.Description).toLowerCase().includes(inputFilter)
            || String(data.Code).toLowerCase().includes(inputFilter)
            || String(data.Amount).toLowerCase().includes(inputFilter)
            || String(data.Country.Description).toLowerCase().includes(inputFilter)
            || String(data.Sector.Code).toLowerCase().includes(inputFilter)
        }
      }
      else if (_this.selectedType.toLowerCase() == 'master') {
        if (filter == null || filter == undefined || inputFilter.trim().length == 0) {
          return (data.IsMaster)
        }
        else {
          return (String(data.ThirdPartyType.Description).toLowerCase().includes(inputFilter)
            || String(data.Code).toLowerCase().includes(inputFilter)
            || String(data.Amount).toLowerCase().includes(inputFilter)
            || String(data.Country.Description).toLowerCase().includes(inputFilter)
            || String(data.Sector.Code).toLowerCase().includes(inputFilter))
            && (data.IsMaster)
        }
      }
      else { // mis checklists
        if (filter == null || filter == undefined || inputFilter.trim().length == 0) {
          return (!data.IsMaster)
        }
        else {
          return (String(data.ThirdPartyType.Description).toLowerCase().includes(inputFilter)
            || String(data.Code).toLowerCase().includes(inputFilter)
            || String(data.Amount).toLowerCase().includes(inputFilter)
            || String(data.Country.Description).toLowerCase().includes(inputFilter)
            || String(data.Sector.Code).toLowerCase().includes(inputFilter))
            && (!data.IsMaster)
        }
      }
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  radioChange(event: MatRadioChange) {
    this.selectedType = event.value;
    const filterValue = this.input.nativeElement.value;
    this.dataSource.filter = event.value + "#<->#" + filterValue.trim().toLowerCase();
  }

  async editarPlantilla(id) {
    let lock = await this.blocker.getTemplateLock(id);
    if (lock === undefined) {
      this.toaster.open({ text: this.translations.errorCheckingBlock, duration: 4000, type: 'danger' });
      return;
    } else if (lock != null) {
      if (lock.UserOwner.Email.toLowerCase() != localStorage.getItem('currentUser')) {
        let fileDate = new Date((<string>lock.UpdateTime).substring(0, 19)).getTime();
        let nowDate = new Date(new Date().toISOString().substring(0, 19)).getTime();

        if (nowDate - fileDate < 1 * 60 * 1000) {
          this.toaster.open({ text: this.translations.fileBlockedBy.replace('${userEmail}',lock.UserOwner.Email), duration: 4000, type: 'danger' });
          return;
        }
      } else {

      }
    }
    this.router.navigate(['/detalle-plantilla', id]);
  }

  duplicarPlantilla(element) {
    this.openDialogUpdate({ data: element });
  }

  openDialogUpdate(data): void {

    const dialogRef = this.dialog.open(DialogDoubleTemplateComponent, data);

    dialogRef.afterClosed().subscribe(async result => {
      if (result === undefined) {
        return
      }
       this.qservice.duplicateQuestionary(data.data.Id)
        .then(r => {

          let q = (<QuestionaryDTO>r);
          this.router.navigate(['/detalle-plantilla', q.Id]);
        })
        .catch(
          err => {
            this.toaster.open({ text: this.translations.errorDuplicating, duration: 4000, type: 'danger' });
          }
        )
    })
  }
}
