import { Injectable } from '@angular/core';
import { CustomField } from '@app/models/CustomField';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomFieldsProxyClient } from 'src/proxy/apiCustomFields/customFields.proxy';
import { CustomFieldTypesService } from './custom-fields/custom-field-types.service';
import { mapCustomField, mapCustomFieldToCreateDto, mapCustomFieldToUpdateDto } from './mappings/custom-field.mappings';

@Injectable({
    providedIn: 'root'
})
export class CustomFieldsService {

    constructor(
        private customFieldsClient: CustomFieldsProxyClient,
        private customFieldTypesService: CustomFieldTypesService
    ) { }

    getCustomFields(): Observable<CustomField[]> {
        return this.customFieldsClient.customFieldsGet()
            .pipe(map(cfs => cfs.map(c => mapCustomField(c, this.customFieldTypesService))));
    }

    getCustomFieldById(customFieldId: number): Observable<CustomField> {
        return this.customFieldsClient.customFieldsCustomFieldIdGet(customFieldId)
            .pipe(map(cf => mapCustomField(cf, this.customFieldTypesService)));
    }

    createCustomField(customField: CustomField): Observable<CustomField> {
        return this.customFieldsClient
            .customFieldsPost(mapCustomFieldToCreateDto(customField))
            .pipe(map(cf => mapCustomField(cf, this.customFieldTypesService)));
    }

    updateCustomField(customField: CustomField): Observable<CustomField> {
        return this.customFieldsClient
            .customFieldsIdPost(customField.id, mapCustomFieldToUpdateDto(customField))
            .pipe(map(cf => mapCustomField(cf, this.customFieldTypesService)));
    }
}
