<fieldset class="u-mb-1medium">
  <legend class="c-form__legend">
    {{ "MemberCheckCorpSearch.information" | translate }}
  </legend>
  <div class="row">
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>
            {{ "MemberCheckCorpSearch.filter.companyName" | translate }}
          </mat-label>
          <input type="text" matInput name="companyName" value="" id="companyName" [(ngModel)]="model.name"
            class="c-form__input" placeholder="{{ 'MemberCheckCorpSearch.filter.companyName' | translate}}" required />
          <mat-error>
            {{ "MemberCheckCorpSearch.required" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>
            {{ "MemberCheckCorpSearch.filter.companyId" | translate }}
          </mat-label>
          <input type="text" matInput name="companyId" value="" id="companyId" [(ngModel)]="model.regNumber"
            class="c-form__input" placeholder="{{ 'MemberCheckCorpSearch.filter.companyId' | translate}}" />
        </mat-form-field>
      </div>
    </div>
    <div class="col-12 col-lg-9 col-xl-9">
      <div class="c-form__block">
        <mat-form-field>
          <mat-label>
            {{ "MemberCheckCorpSearch.filter.address" | translate }}
          </mat-label>
          <input type="text" matInput name="address" value="" id="address" [(ngModel)]="model.address"
            class="c-form__input" placeholder="{{ 'MemberCheckCorpSearch.filter.address' | translate}}"/>
        </mat-form-field>
      </div>
    </div>
  </div>
</fieldset>
<fieldset class="u-mb-1medium">
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-3">
      <div class="c-form__block">
        <button (click)="search()" type="button" [disabled]="!model.name?.length" class="c-button u-mr-1">
          {{ "MemberCheckCorpSearch.search" | translate }}
        </button>
      </div>
    </div>
  </div>
</fieldset>
