import { Component, Input, OnInit } from "@angular/core";
import { CampaignStatus } from "@app/models/CampaignStatus";
import { CampaignThirdPartyService } from "@app/services/campaign-third-party.service";

@Component({
    selector: 'app-campaign-detail-statistics',
    templateUrl: './campaign-detail-statistics.component.html',
    styleUrls: ['./campaign-detail-statistics.component.scss']
})
export class CampaignDetailStatisticsComponent implements OnInit {

    @Input() campaignId: number;
    total: number = 0;
    pendingResponse: number = 0;
    inProgress: number = 0;
    pendingSign: number = 0;
    pendingReview: number = 0;
    reviewed: number = 0;
    discarded: number = 0;
    acknowledged: number = 0;

    constructor(private campaignThirdPartyService: CampaignThirdPartyService) { }

    ngOnInit(): void {
        
        this.campaignThirdPartyService.getCampaignThirdPartiesByCampaign(this.campaignId).subscribe( r => {
            this.total = r.length;
            this.pendingResponse = r.filter(ctp => ctp.status == CampaignStatus.pending).length;
            this.inProgress = r.filter(ctp => ctp.status == CampaignStatus.inProgress).length;
            this.pendingSign = r.filter(ctp => ctp.status == CampaignStatus.pendingSign).length;
            this.pendingReview = r.filter(ctp => ctp.status == CampaignStatus.pendingReview).length;
            this.reviewed = r.filter(ctp => ctp.status == CampaignStatus.reviewed).length;
            this.discarded = r.filter(ctp => ctp.status == CampaignStatus.discarded).length;
            this.acknowledged = r.filter(ctp => ctp.status == CampaignStatus.acknowledged).length;
        });        
    }
}
