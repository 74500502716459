<div class="c-group-select">
    <div class="c-group-select__item">
        <div class="c-select">
            <mat-form-field>
                <mat-select (selectionChange)="changeTPType($event)" placeholder="{{'Riskboard.filter3PPlaceholder' | translate}}" multiple>
                    <mat-option *ngFor="let type of tpTypes" [value]="type.id">{{type | translatedescription}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="c-group-select__item"></div>
    <div class="c-group-select__item"></div>
    <div class="c-group-select__item"></div>
    <div class="c-group-select__item">
        <button *appAuthRole="['Owner','Manager']" class="c-button" (click)="downloadReport()">
            {{'Riskboard.DownloadReport.valor'|translate}}
        </button>
    </div>
</div>
<div *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
</div>
<div class="o-space-lateral o-scroll">
    <!--estados-->
    <ul class="c-process-states-5 u-mb-1medium">
        <li class="c-process-states-5__item">
            <figure class="c-process-states-5__ico" aria-hidden="true">
                <img src="/assets/ico-third-large.svg" [alt]="" loading="lazy" />
            </figure>
            <div class="c-process-states-5__info">
                <p class="c-process-states-5__quantity" aria-labelledby="terceros">{{ thirdPartiesCount }}</p>
                <p id="terceros" class="c-process-states-5__type">{{'Riskboard.total3PP'|translate}}</p>
            </div>
        </li>
        <li class="c-process-states-5__item">
            <figure class="c-process-states-5__ico" aria-hidden="true">
                <img src="/assets/thirdPartyPending.svg" [alt]="" loading="lazy" />
            </figure>
            <div class="c-process-states-5__info">
                <p class="c-process-states-5__quantity" aria-labelledby="pending">{{ pending }}</p>
                <p id="pending" class="c-process-states-5__type">{{'ThirdPartyStatus.pending'|translate}}</p>
            </div>
        </li>
        <li class="c-process-states-5__item">
            <figure class="c-process-states-5__ico" aria-hidden="true">
                <img src="/assets/thirdPartyInProgress.svg" [alt]="" loading="lazy" />
            </figure>
            <div class="c-process-states-5__info">
                <p class="c-process-states-5__quantity" aria-labelledby="inProgress">{{ inProgress }}</p>
                <p id="inProgress" class="c-process-states-5__type">{{'ThirdPartyStatus.inProgress'|translate}}</p>
            </div>
        </li>
        <li class="c-process-states-5__item">
            <figure class="c-process-states-5__ico" aria-hidden="true">
                <img src="/assets/thirdPartyApproved.svg" [alt]="" loading="lazy" />
            </figure>
            <div class="c-process-states-5__info">
                <p class="c-process-states-5__quantity" aria-labelledby="enproceso">{{ approved }}</p>
                <p id="pendingSign" class="c-process-states-5__type">{{'ThirdPartyStatus.approved'|translate}}</p>
            </div>
        </li>
        <li class="c-process-states-5__item">
            <figure class="c-process-states-5__ico" aria-hidden="true">
                <img src="/assets/thirdPartyRejected.svg" [alt]="" loading="lazy" />
            </figure>
            <div class="c-process-states-5__info">
                <p class="c-process-states-5__quantity" aria-labelledby="enproceso">{{ rejected }}</p>
                <p id="pendingSign" class="c-process-states-5__type">{{'ThirdPartyStatus.rejected'|translate}}</p>
            </div>
        </li>
    </ul>
    <!--gráficos dos columnas-->
    <div class="row">
        <div class="col-lg-6">
            <article class="c-graphic">
                <header class="c-graphic__header">
                    <h1 class="c-graphic__title">{{'Riskboard.title.chart.assesmentsEvolution'|translate}}</h1>
                </header>
                <div class="c-graphic__graph">
                    <div id="statusEvoChartDiv" style="width: 100%; height: 400px"></div>
                </div>
            </article>
        </div>
        <div class="col-lg-6">
            <article class="c-graphic">
                <header class="c-graphic__header">
                    <h1 class="c-graphic__title">{{'Riskboard.title.chart.map.valor'|translate}}</h1>
                </header>
                <div class="c-graphic__graph c-graphic__map-graph">
                    <div id="mapChartDiv" style="width: 100%; height: 400px"></div>
                    <div *ngIf="thirdPartiesWithUnspecifiedCountries > 0" class="unspecified-country-warning">
                      <img class="warning-icon" src="assets/fi-rr-exclamation.svg" alt="" loading="lazy" />
                      {{ ('Riskboard.title.chart.unspecifiedCountries' | translate).replace('${numThirdParties}', thirdPartiesWithUnspecifiedCountries) }}
                    </div>
                </div>
            </article>
        </div>
    </div>
    <!--gráficos tres columnas-->
    <div class="row">
        <div class="col-lg-6">
            <article class="c-graphic">
                <header class="c-graphic__header c-graphic__header--flex">
                    <h1 class="c-graphic__title">{{'Riskboard.title.chart.thirdPartyStatusByCountry.valor'|translate}}</h1>
                    <span class="c-graphic__header-ico" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <path d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z"/>
                        </svg>
                    </span>
                </header>
                <div class="c-graphic__graph">
                    <div id="thirdPartyStatusByCountryChartDiv" style="width: 100%; height: 400px"></div>
                </div>
            </article>
        </div>
        <div class="col-lg-6">
            <article class="c-graphic">
                <header class="c-graphic__header c-graphic__header--flex">
                    <h1 class="c-graphic__title">{{'Riskboard.title.chart.thirdPartyStatusBySector.valor'|translate}}</h1>
                    <span class="c-graphic__header-ico" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <path d="M12.667,2.667h-.733A3.339,3.339,0,0,0,8.667,0H7.333A3.339,3.339,0,0,0,4.067,2.667H3.333A3.337,3.337,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6a3.337,3.337,0,0,0-3.333-3.333ZM7.333,1.333H8.667a2,2,0,0,1,1.877,1.333H5.456A2,2,0,0,1,7.333,1.333ZM3.333,4h9.333a2,2,0,0,1,2,2V8H1.333V6a2,2,0,0,1,2-2Zm9.333,10.667H3.333a2,2,0,0,1-2-2V9.333h6V10a.667.667,0,0,0,1.333,0V9.333h6v3.333A2,2,0,0,1,12.667,14.667Z"/>
                        </svg>
                    </span>
                </header>
                <div class="c-graphic__graph">
                    <div id="thirdPartyStatusBySectorsChartDiv" style="width: 100%; height: 400px"></div>
                </div>
            </article>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <article class="c-graphic">
                <header class="c-graphic__header c-graphic__header--flex">
                    <h1 class="c-graphic__title">{{'Riskboard.title.chart.thirdPartiesByCountry.valor'|translate}}</h1>
                    <span class="c-graphic__header-ico" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <path d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z"/>
                        </svg>
                    </span>
                </header>
                <div class="c-graphic__graph">
                    <div id="thirdPartiesByCountryChartDiv" style="width: 100%; height: 400px"></div>
                </div>
            </article>
        </div>
        <div class="col-lg-6">
            <article class="c-graphic">
                <header class="c-graphic__header c-graphic__header--flex">
                    <h1 class="c-graphic__title">{{'Riskboard.title.chart.thirdPartiesBySector.valor'|translate}}</h1>
                    <span class="c-graphic__header-ico" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <path d="M12.667,2.667h-.733A3.339,3.339,0,0,0,8.667,0H7.333A3.339,3.339,0,0,0,4.067,2.667H3.333A3.337,3.337,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6a3.337,3.337,0,0,0-3.333-3.333ZM7.333,1.333H8.667a2,2,0,0,1,1.877,1.333H5.456A2,2,0,0,1,7.333,1.333ZM3.333,4h9.333a2,2,0,0,1,2,2V8H1.333V6a2,2,0,0,1,2-2Zm9.333,10.667H3.333a2,2,0,0,1-2-2V9.333h6V10a.667.667,0,0,0,1.333,0V9.333h6v3.333A2,2,0,0,1,12.667,14.667Z"/>
                        </svg>
                    </span>
                </header>
                <div class="c-graphic__graph">
                    <div id="thirdPartiesBySectorChartDiv" style="width: 100%; height: 400px"></div>
                </div>
            </article>
        </div>
    </div>
</div>
