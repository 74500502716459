<div class="o-wrapper">
    <main id="content">
        <!-- header section -->
        <div class="c-head-section c-head-section--tab bg-cream">
            <!--parte superior-->
            <div class="c-head-section--tab__top">
                <div class="c-head-section__left">
                    <div class="c-head-section--tab__ico is-active">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path
                                d="M3.333,5.462a2.384,2.384,0,0,1-1.768-.8L.278,3.412A.97.97,0,0,1,.211,2.126a.786.786,0,0,1,1.177-.074L2.709,3.339a.806.806,0,0,0,.6.3.8.8,0,0,0,.614-.268l3-3.122A.784.784,0,0,1,8.083.306a.971.971,0,0,1-.009,1.265L5.083,4.678A2.368,2.368,0,0,1,3.333,5.462ZM20,3.642a.874.874,0,0,0-.833-.91H10.833a.874.874,0,0,0-.833.91.874.874,0,0,0,.833.91h8.333A.874.874,0,0,0,20,3.642ZM5.083,11.958,8.074,8.851a.962.962,0,0,0,.258-.884A.88.88,0,0,0,7.746,7.3a.786.786,0,0,0-.821.238l-3,3.122a.8.8,0,0,1-1.178,0L1.422,9.213a.784.784,0,0,0-1.178,0,.97.97,0,0,0,0,1.287l1.321,1.442a2.353,2.353,0,0,0,3.522.015ZM20,10.923a.874.874,0,0,0-.833-.91H10.833a.914.914,0,0,0,0,1.82h8.333A.874.874,0,0,0,20,10.923ZM5.083,19.239,8.07,16.132a.962.962,0,0,0,.258-.884.88.88,0,0,0-.585-.672.786.786,0,0,0-.821.238l-3,3.122a.8.8,0,0,1-.614.268.806.806,0,0,1-.6-.3L1.388,16.613a.786.786,0,0,0-1.177.074.97.97,0,0,0,.067,1.286l1.287,1.25a2.353,2.353,0,0,0,3.522.015ZM20,18.2a.874.874,0,0,0-.833-.91H10.833a.914.914,0,0,0,0,1.82h8.333A.874.874,0,0,0,20,18.2Z"
                                transform="translate(.0001-.023)" />
                        </svg>
                    </div>
                    <div class="c-head-section--tab__info">
                        <h2 class="c-head-section--tab__title">{{ model?model.CompanyName:'' }}</h2>
                        <div class="c-head-section--tab-data">
                            <div class="c-head-section--tab-data__item">
                                <div class="c-head-section--tab-data__ico" aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                        <path
                                            d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z" />
                                    </svg>
                                </div>
                                <div>
                                    {{ model?model.ThirdPartyConfig.ThirdPartyType?.Description:'' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-head-section__right">
                    <div class="c-score">
                        <p class="c-score__title">{{ 'InfoThird.thirdPartyStatus' | translate }}: </p>
                        <div class="c-score__value">
                            <app-third-party-status [status]="model?.ThirdPartyConfig.ThirdPartyStatus"></app-third-party-status>
                        </div>
                    </div>
                </div>
            </div>
            <!--volver a listado-->
            <div class="c-go-back">
                <a [routerLink]="" (click)="back()" class="c-go-back__button">
                    <span class="c-go-back__arrow" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                            <path
                                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                                transform="translate(-5-6.01)" />
                        </svg>
                    </span> {{'InfoThird.back2search.valor' | translate}}
                </a>
            </div>
            <div *ngIf="isLoading" class="c-spinner-wrapper">
                <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
            </div>
            <!--parte inferior-->
            <div class="c-head-section--tab__bottom">
                <!--tabs-->
                <mat-tab-group class="c-tabs" [selectedIndex]="tabIndex">
                    <!--Third party information-->
                    <mat-tab label="{{'InfoThird.info.valor' | translate}}">
                        <div class="o-scroll o-scroll--small">
                            <app-formulario-third
                                [errores]="errores"
                                [sectorsNoSelected]="sectorsNoSelected"
                                [countriesNoSelected]="countriesNoSelected"
                                [languagesNoSelected]="languagesNoSelected"
                                [thirdPartyTypeNoSelected]="thirdPartyTypeNoSelected"
                                [responsiblesNoSelected]="responsiblesNoSelected"
                                [natureOfActivityNoSelected]="natureOfActivityNoSelected"
                                [typeOfActivityNoSelected]="typeOfActivityNoSelected"
                                (onSubmit)="saveChanges($event)"
                                *ngIf="model"
                                [model]="model"
                            ></app-formulario-third>
                        </div>
                    </mat-tab>
                    <!--evaluaciones lanzadas-->
                    <mat-tab label="{{'InfoThird.campaigns.valor' | translate}}">
                        <app-third-party-campaigns-table *ngIf="!isLoading" [thirdPartyId]="model.Id"></app-third-party-campaigns-table>
                    </mat-tab>
                    <!--documentos-->
                    <mat-tab label="{{'InfoThird.docs.valor' | translate}}">
                        <app-third-party-table-files *ngIf="!isLoading" [thirdParty]="model"></app-third-party-table-files>
                    </mat-tab>
                    <!--documentos-->
                    <mat-tab *appAuthRole="['Refinitiv', 'AMLUpdate', 'EquifaxWS', 'MemberCheck']" label="{{'InfoThird.scoringTools.valor' | translate}}">
                        <app-screening-dashboard *ngIf="!isLoading" [thirdPartyId]='model?.Id'></app-screening-dashboard>
                        <mat-divider></mat-divider>
                        <app-resolutions-dashboard *ngIf="!isLoading" [thirdPartyId]='model?.Id'></app-resolutions-dashboard>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </main>
</div>
