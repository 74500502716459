import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmailNotificationProxyClient } from "src/proxy/apiEmailNotification/emailNotification.proxy";

@Injectable()
export class EmailNotificaitonService {

    constructor(private emailNotificationClient: EmailNotificationProxyClient) { }

    public resendInvitationEmail(thirdPartyId: number, campaignId: number): Observable<boolean> {
        return this.emailNotificationClient.emailNotificationResendCampaignInvitationGet(thirdPartyId, campaignId);
    }
}