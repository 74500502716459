import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog3pp-is-linked',
  templateUrl: './dialog3pp-is-linked.component.html',
  styleUrls: ['./dialog3pp-is-linked.component.scss']
})
export class Dialog3ppIsLinkedComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<Dialog3ppIsLinkedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  navigate(){
    this.dialogRef.close(true);
  }

  continue(){
    this.dialogRef.close();
  }
}
