/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AMLUpdateLinkDto } from '../model/aMLUpdateLinkDto';
import { AMLUpdateRecordDto } from '../model/aMLUpdateRecordDto';
import { AMLUpdateScreeningDto } from '../model/aMLUpdateScreeningDto';
import { AMLUpdateSearchResponse } from '../model/aMLUpdateSearchResponse';
import { Configuration } from '../configuration';


@Injectable()
export class AMLUpdateScoringProxyClient {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   *
   * @param rut
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public aMLUpdateScoringCandidateDetailsRutGet(rut: string, observe?: 'body', reportProgress?: boolean): Observable<AMLUpdateRecordDto>;
  public aMLUpdateScoringCandidateDetailsRutGet(rut: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AMLUpdateRecordDto>>;
  public aMLUpdateScoringCandidateDetailsRutGet(rut: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AMLUpdateRecordDto>>;
  public aMLUpdateScoringCandidateDetailsRutGet(rut: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (rut === null || rut === undefined) {
      throw new Error('Required parameter rut was null or undefined when calling aMLUpdateScoringCandidateDetailsRutGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<AMLUpdateRecordDto>('get', `${this.basePath}/AMLUpdateScoring/CandidateDetails/${encodeURIComponent(String(rut))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param rut
   * @param name
   * @param thirdPartyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public aMLUpdateScoringCreateLinkPostForm(rut?: string, name?: string, thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public aMLUpdateScoringCreateLinkPostForm(rut?: string, name?: string, thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public aMLUpdateScoringCreateLinkPostForm(rut?: string, name?: string, thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public aMLUpdateScoringCreateLinkPostForm(rut?: string, name?: string, thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {




    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (thirdPartyId !== undefined && thirdPartyId !== null) {
      queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (rut !== undefined) {
      formParams = formParams.append('rut', <any>rut) as any || formParams;
    }
    if (name !== undefined) {
      formParams = formParams.append('name', <any>name) as any || formParams;
    }

    return this.httpClient.request<boolean>('post', `${this.basePath}/AMLUpdateScoring/createLink`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param thirdPartyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public aMLUpdateScoringLinksGet(thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AMLUpdateLinkDto>>;
  public aMLUpdateScoringLinksGet(thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AMLUpdateLinkDto>>>;
  public aMLUpdateScoringLinksGet(thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AMLUpdateLinkDto>>>;
  public aMLUpdateScoringLinksGet(thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (thirdPartyId !== undefined && thirdPartyId !== null) {
      queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<AMLUpdateLinkDto>>('get', `${this.basePath}/AMLUpdateScoring/links`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param rut
   * @param thirdPartyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public aMLUpdateScoringRefreshScreeinigRutPost(rut: string, thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public aMLUpdateScoringRefreshScreeinigRutPost(rut: string, thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public aMLUpdateScoringRefreshScreeinigRutPost(rut: string, thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public aMLUpdateScoringRefreshScreeinigRutPost(rut: string, thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (rut === null || rut === undefined) {
      throw new Error('Required parameter rut was null or undefined when calling aMLUpdateScoringRefreshScreeinigRutPost.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (thirdPartyId !== undefined && thirdPartyId !== null) {
      queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<string>('post', `${this.basePath}/AMLUpdateScoring/refreshScreeinig/${encodeURIComponent(String(rut))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param linkId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public aMLUpdateScoringRemoveLinkLinkIdPost(linkId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public aMLUpdateScoringRemoveLinkLinkIdPost(linkId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public aMLUpdateScoringRemoveLinkLinkIdPost(linkId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public aMLUpdateScoringRemoveLinkLinkIdPost(linkId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (linkId === null || linkId === undefined) {
      throw new Error('Required parameter linkId was null or undefined when calling aMLUpdateScoringRemoveLinkLinkIdPost.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<boolean>('post', `${this.basePath}/AMLUpdateScoring/removeLink/${encodeURIComponent(String(linkId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param screenId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public aMLUpdateScoringScreeningDetailsScreenIdGet(screenId: number, observe?: 'body', reportProgress?: boolean): Observable<AMLUpdateRecordDto>;
  public aMLUpdateScoringScreeningDetailsScreenIdGet(screenId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AMLUpdateRecordDto>>;
  public aMLUpdateScoringScreeningDetailsScreenIdGet(screenId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AMLUpdateRecordDto>>;
  public aMLUpdateScoringScreeningDetailsScreenIdGet(screenId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (screenId === null || screenId === undefined) {
      throw new Error('Required parameter screenId was null or undefined when calling aMLUpdateScoringScreeningDetailsScreenIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<AMLUpdateRecordDto>('get', `${this.basePath}/AMLUpdateScoring/screeningDetails/${encodeURIComponent(String(screenId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param linkId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public aMLUpdateScoringScreeningsLinkIdGet(linkId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AMLUpdateScreeningDto>>;
  public aMLUpdateScoringScreeningsLinkIdGet(linkId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AMLUpdateScreeningDto>>>;
  public aMLUpdateScoringScreeningsLinkIdGet(linkId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AMLUpdateScreeningDto>>>;
  public aMLUpdateScoringScreeningsLinkIdGet(linkId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (linkId === null || linkId === undefined) {
      throw new Error('Required parameter linkId was null or undefined when calling aMLUpdateScoringScreeningsLinkIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<AMLUpdateScreeningDto>>('get', `${this.basePath}/AMLUpdateScoring/screenings/${encodeURIComponent(String(linkId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param name
   * @param country
   * @param residenceCountry
   * @param date
   * @param type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public aMLUpdateScoringSearchGet(name?: string, country?: string, residenceCountry?: string, date?: Date, type?: number, observe?: 'body', reportProgress?: boolean): Observable<AMLUpdateSearchResponse>;
  public aMLUpdateScoringSearchGet(name?: string, country?: string, residenceCountry?: string, date?: Date, type?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AMLUpdateSearchResponse>>;
  public aMLUpdateScoringSearchGet(name?: string, country?: string, residenceCountry?: string, date?: Date, type?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AMLUpdateSearchResponse>>;
  public aMLUpdateScoringSearchGet(name?: string, country?: string, residenceCountry?: string, date?: Date, type?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {






    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (name !== undefined && name !== null) {
      queryParameters = queryParameters.set('name', <any>name);
    }
    if (country !== undefined && country !== null) {
      queryParameters = queryParameters.set('country', <any>country);
    }
    if (residenceCountry !== undefined && residenceCountry !== null) {
      queryParameters = queryParameters.set('residenceCountry', <any>residenceCountry);
    }
    if (date !== undefined && date !== null) {
      queryParameters = queryParameters.set('date', <any>date.toISOString());
    }
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', <any>type);
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
      headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<AMLUpdateSearchResponse>('get', `${this.basePath}/AMLUpdateScoring/Search`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
