import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { TypeOfActivityCreateDTO, TypeOfActivityUpdateDTO } from '@app/models/TypeOfActivity';
import { CommonsService } from '../../../services/commons-service';

@Component({
  selector: 'app-dialog-add-activity',
  templateUrl: './dialog-add-activity.component.html',
  styles: []
})
export class DialogAddActivityComponent implements OnInit {

  form: any;

  constructor(
    public dialogRef: MatDialogRef<DialogAddActivityComponent>,
    private fb: FormBuilder,
    private commons: CommonsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    let entry = this.data;
    this.form = this.fb.group({
      Description: new FormControl(entry !== null ? entry.Description : '', Validators.required),
      DescriptionEN: new FormControl(entry !== null ? entry.DescriptionEN : '', Validators.required),
      DescriptionPT: new FormControl(entry !== null ? entry.DescriptionPT : '', Validators.required),
      DescriptionFR: new FormControl(entry !== null ? entry.DescriptionFR : '', Validators.required),
    });
  }

  // cerrar modal
  onNoClick(): void {
    this.dialogRef.close();
  }

  async addActivity() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;

    let a = (<TypeOfActivityCreateDTO>{});
    a.Code = description;
    a.Description = description;
    a.DescriptionEN = descriptionEN;
    a.DescriptionPT = descriptionPT;
    a.DescriptionFR = descriptionFR;
    a.Client = null;
    this.commons.postActivity(a)
      .then(r => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }

  async updateActivity() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;

    let a = (<TypeOfActivityUpdateDTO>{});
    a.Id = this.data.Id;
    a.Code = description;
    a.Description = description;
    a.DescriptionEN = descriptionEN;
    a.DescriptionPT = descriptionPT;
    a.DescriptionFR = descriptionFR;
    a.Client = null;
    this.commons.putActivity(a)
      .then(r => {

        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }
}
