import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { Questionary } from '@app/models/Questionary';
import { Risk } from '@app/models/Risk';
import { TranslateService } from '@ngx-translate/core';
import { QuestionaryFormService } from '../questionary-form.service';

@Component({
  selector: 'app-questionary-blocks',
  templateUrl: './questionary-blocks.component.html',
  styleUrls: ['./questionary-blocks.component.scss']
})
export class QuestionaryBlocksComponent implements OnInit {

  @Input() questionary: Questionary;
  @Input() canEdit: boolean;

  form: FormGroup;
  bloques: FormArray;

  tipoPreguntasValues = [];
  tipoScoreValues = [];
  pesoValues = [];

  constructor(
    public rootFormGroup: FormGroupDirective,
    private translate: TranslateService,
    private questionaryFormService: QuestionaryFormService
  ) { }

  unusedRiskTypesWithRisk(risk: Risk): Risk[] {
    return this.questionaryFormService.getUnusedRiskTypesWithRisk(risk);
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    this.bloques = this.rootFormGroup.control.get('bloques') as FormArray;

    this.translate.get([
      'ListadoPreguntas.form.respuestaSiNo.valor',
      'ListadoPreguntas.form.multipleAnswer',
      'ListadoPreguntas.form.peso1.valor',
      'ListadoPreguntas.form.peso2.valor',
      'ListadoPreguntas.form.peso3.valor',
      'ListadoPreguntas.form.peso4.valor',
      'ListadoPreguntas.form.peso5.valor',
      'ListadoPreguntas.form.valoracion.valor',
      'ListadoPreguntas.form.answerBasedScore',
      'ListadoPreguntas.form.commentAndDocBasedScore'
    ])
      .subscribe(translation => {
        for (let index = 0; index < 5; index++) {
          this.pesoValues.push({
            value: index + 1,
            text: translation['ListadoPreguntas.form.peso' + (index + 1).toString() + '.valor']
          });
        }

        this.tipoPreguntasValues.push({
          value: 1,
          text: translation['ListadoPreguntas.form.respuestaSiNo.valor']
        }, {
          value: 2,
          text: translation['ListadoPreguntas.form.multipleAnswer']
        });

        this.tipoScoreValues.push(
          {
            value: 0,
            text: translation['ListadoPreguntas.form.answerBasedScore']
          },
          {
            value: 1,
            text: translation['ListadoPreguntas.form.commentAndDocBasedScore']
          });
      });
  }

  dropBlock(event: CdkDragDrop<object[]>): void {
    this.questionaryFormService.dropBlock(event);
  }

  isAddBlockButtonDisabled(): boolean {
    return !this.canEdit || this.questionaryFormService.isAddBlockButtonDisabled();
  }

  addBlock(): void {
    this.questionaryFormService.addBlockWithQuestion();
  }

  addQuestion(bloqueIndex: number): void {
    this.questionaryFormService.addQuestion(bloqueIndex);
  }

  canAddDocFlag(blockIndex: number, questionIndex: number): boolean {
    return this.questionaryFormService.canAddDocFlag(blockIndex, questionIndex);
  }

  dropQuestion(f: any, event: CdkDragDrop<object[]>): void {
    if (this.questionary && !this.questionary.isMaster) {
      const questionsBlock = <FormArray>f.controls['questions'];
      this.questionaryFormService.dropQuestion(questionsBlock, event.previousIndex, event.currentIndex);
    }
  }

  deleteQuestion(bloqueIndex: number, questionIndex: number): void {
    this.questionaryFormService.deleteQuestion(bloqueIndex, questionIndex);
  }

  blockNameChange(): void {
    this.questionaryFormService.blockNameChange();
  }

  deleteBloque(bloqueIndex: number): void {
    this.questionaryFormService.deleteBloque(bloqueIndex);
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (option && value) {
      return option.id === value.id;
    }
    else {
      return false;
    }
  }

  mustAddCommentChanged(a: any) {
    if(a.controls['MustAddComments'].value) {
      a.controls['WeightNoComment'].disable();
    } else {
      a.controls['WeightNoComment'].enable();
    }
  }

  mustAddDocChanged(a: any) {
    if(a.controls['MustAddDoc'].value) {
      a.controls['WeightNoFile'].disable();
    } else {
      a.controls['WeightNoFile'].enable();
    }
  }
}
