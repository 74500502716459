import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { TemplateDetailCopyMasterComponent } from '@app/modules/templates/template-detail-copy-master/template-detail-copy-master.component';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsTemplateFormDirtyGuardService implements CanDeactivate<TemplateDetailCopyMasterComponent> {
  canDeactivate(component: TemplateDetailCopyMasterComponent,  currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {
    if (nextState.url === '/login') {
      return of(true);
    }

    return component.confirmExitingIfDirty();
  }
}
