<div *ngIf="loggedIn == true">
  <!--header-->
  <header class="c-header">
    <div class="c-header__left">
      <h1 class="c-header__logo">
        <a [routerLink]="[URLHome]" (click)="global.closeAllNav()">
          <img src="assets/logo-riskallay-nav.svg">
        </a>
      </h1>
    </div>
    <div class="c-header__right">
      <!--perfil-->
      <div class="c-header-profile" aria-labelledby="profilePerson">
        <div class="c-header-profile__left">
          <figure class="c-header-profile__image">
            <img [src]="image ? image :'assets/placeholder-avatar.png'" alt="" loading="lazy" />
            <figcaption id="profilePerson" class="c-header-profile__info">
              <p class="c-header-profile__name">{{auth.getFieldJWT('Name')}}</p>
              <p class="c-header-profile__job">{{getPosition() | translate}}</p>
            </figcaption>
          </figure>
        </div>
        <div class="c-header-profile__right">
          <button type="button" mat-button [matMenuTriggerFor]="menuConfig" class="c-header-profile__button"
            aria-label="Abrir desplegable">
            <span aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.594 24" aria-labelledby="profileIco">
                <title id="profileIco">{{'Main.configuration.valor' | translate}}</title>
                <g transform="translate(-1.203)">
                  <path class="shape" d="M12 8a4 4 0 1 0 4 4A4 4 0 0 0 12 8m0 6a2 2 0 1 1 2 -2A2 2 0 0 1 12 14" />
                  <path class="shape"
                    d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,1,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.7,4.9a3,3,0,1,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29L2.7,13.9a3,3,0,0,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,1,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,1,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732L4.791,15a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,1,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732L19.209,9a1,1,0,0,0-.463,1.129Z" />
                </g>
              </svg>
            </span>
          </button>
          <mat-menu #menuConfig="matMenu" backdropClass="c-dropdown">
            <a mat-menu-item [routerLink]="['/mi-perfil']" routerLinkActive="is-active"
              class="c-dropdown__link">{{'Main.myaccount.valor' | translate}}</a>
            <a *appAuthRole="['Owner','Manager']" mat-menu-item [routerLink]="['/configuracion-empresa']"
              routerLinkActive="is-active" class="c-dropdown__link">{{'Main.company.valor' | translate}}</a>
            <a *appAuthRole="['Owner','Manager']" mat-menu-item [routerLink]="['/ajustes-generales']"
              routerLinkActive="is-active" class="c-dropdown__link">{{'Main.general.valor' | translate}}</a>
            <button mat-menu-item (click)="logout()" class="c-dropdown__link">{{'Main.exit.valor' | translate}}</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </header>

  <div *ngIf='activeCampaign.navShow == true' class="c-lateralBar">
    <button *appAuthRole="['Owner','Manager','Assistant','Collaborator']" type="button" (click)="activeCampaign.activeCampaign(0)"
      class="c-lateralBar-button">
      <span class="c-lateralBar-button__text">{{'Menu.newCampaign.valor' | translate}}</span>
    </button>
    <nav class="c-nav">
      <ul class="c-nav-list">
        <!--home-->
        <li *appAuthRole="['Owner','Manager','Assistant','Collaborator','Auditor']" class="c-nav-list__item">
          <a [routerLink]="['/home']" routerLinkActive="is-active" (click)="inactiveSublist()" class="c-nav-list__link"
            lang="en">
            <span class="c-nav-list__ico" aria-hidden="true">
              <img src="assets/home-ico.svg">
            </span>
            <span class="c-nav-list__text">{{'Menu.home.valor' | translate}}</span>
          </a>
        </li>
        <li *appAuthRole="['Owner','Manager','Assistant','Collaborator','Auditor']" class="c-nav-list__item">
          <a [routerLink]="['/busqueda']" routerLinkActive="is-active" (click)="toggleSublist()"
            class="c-nav-list__link" lang="en">
            <span class="c-nav-list__ico" aria-hidden="true">
              <img src="assets/third-list-ico.svg">
            </span>
            <span class="c-nav-list__text">{{'Menu.3pp.valor' | translate}}</span>
          </a>
        </li>
        <li *appAuthRole="['Owner','Manager','Assistant','Collaborator','Auditor']" class="c-nav-list__item">
          <a [routerLink]="['/plantillas']" routerLinkActive="is-active" (click)="activeSublist()"
            class="c-nav-list__link">
            <span class="c-nav-list__ico" aria-hidden="true">
              <img src="assets/questionaries-ico.svg">
            </span>
            <span class="c-nav-list__text">{{'Menu.3pp.templates.valor' | translate}}</span>
          </a>
        </li>
        <li *appAuthRole="['Owner','Manager','Assistant','Collaborator','Auditor']" class="c-nav-list__item">
          <a [routerLink]="['/evaluaciones']" routerLinkActive="is-active" (click)="activeSublist()"
            class="c-nav-list__link">
            <span class="c-nav-list__ico" aria-hidden="true">
              <img src="assets/assesments.svg" alt="Assesment icon" />
            </span>
            <span class="c-nav-list__text">{{'Menu.3pp.campaigns.valor' | translate}}</span>
          </a>
        </li>
        <li *appAuthRole="['Owner','Manager','Assistant','Collaborator','Auditor']" class="c-nav-list__item">
          <a [routerLink]="['/riskboard']" routerLinkActive="is-active" (click)="activeSublist()"
            class="c-nav-list__link" lang="en">
            <span class="c-nav-list__ico" aria-hidden="true">
              <img src="assets/riskboard-ico.svg">
            </span>
            <span class="c-nav-list__text">{{'Menu.3pp.riskboard.valor' | translate}}</span>
          </a>
        </li>
        <li *appAuthRole="['Refinitiv', 'AMLUpdate', 'EquifaxWS', 'MemberCheck']" class="c-nav-list__item">
          <a [routerLink]="['/screening-tools']" routerLinkActive="is-active" (click)="activeSublist()"
            class="c-nav-list__link" lang="en">
            <span class="c-nav-list__ico" aria-hidden="true">
              <img src="assets/screening-tools.svg">
            </span>
            <span class="c-nav-list__text">{{'Menu.3pp.screeningTools' | translate}}</span>
          </a>
        </li>

        <mat-divider class="separator-bar c-lateralBar-separator"></mat-divider>

        <!--Portal Riskallay-->
        <li *appAuthRole="['ThirdParty']" class="c-nav-list__item">
          <a [routerLink]="['/pagina-id']" routerLinkActive="is-active" (click)="toggleSublistPortal()"
            class="c-nav-list__link" lang="en">
            <span class="c-nav-list__ico" aria-hidden="true">
              <img src="assets/pending-risklist-ico.svg">
            </span>
            <span class="c-nav-list__text">{{'Menu.portal.valor' | translate}}</span>
          </a>
        </li>
        <li *appAuthRole="['ThirdParty']" class="c-nav-list__item">
          <a [routerLink]="['/mis-riesgos']" routerLinkActive="is-active" (click)="activeSublistPortal()"
            class="c-nav-list__link">
            <span class="c-nav-list__ico" aria-hidden="true">
              <img src="assets/completed-risklist-ico.svg">
            </span>
            <span class="c-nav-list__text">{{'Menu.portal.myRisks.valor' | translate}}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <!--NAVEGACIÓN crear evaluación-->
  <div *ngIf='activeCampaign.navShow === false' class="c-lateralBar c-lateralBar--campaign">
    <button type="button" (click)="activeCampaign.closeCampaign()" class="c-lateralBar-button">
      <span class="c-lateralBar-button__text">{{'Menu.Assesment.Exit.valor' | translate}}</span>
    </button>
    <nav class="c-nav">
      <ul class="c-nav-list">
        <!--configurar evaluación-->
        <li class="c-nav-list__item">
          <a [routerLink]="['/nueva-evaluacion/0']" routerLinkActive="is-active" id="config" class="c-nav-list__link">
            <span class="c-nav-list__ico" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M.833,3.958h2.28a3.107,3.107,0,0,0,6,0H19.167a.833.833,0,1,0,0-1.667H9.109a3.107,3.107,0,0,0-6,0H.833a.833.833,0,0,0,0,1.667ZM6.111,1.667A1.458,1.458,0,1,1,4.653,3.125,1.458,1.458,0,0,1,6.111,1.667Z" />
                <path
                  d="M19.167,10.541h-2.28a3.106,3.106,0,0,0-6,0H.833a.833.833,0,0,0,0,1.667H10.892a3.106,3.106,0,0,0,5.995,0h2.28a.833.833,0,1,0,0-1.667Zm-5.278,2.292a1.458,1.458,0,1,1,1.458-1.458,1.458,1.458,0,0,1-1.458,1.458Z"
                  transform="translate(0-1.375)" />
                <path
                  d="M19.167,18.792H9.109a3.107,3.107,0,0,0-6,0H.833a.833.833,0,1,0,0,1.667h2.28a3.107,3.107,0,0,0,6,0H19.167a.833.833,0,1,0,0-1.667ZM6.111,21.083a1.458,1.458,0,1,1,1.458-1.458,1.458,1.458,0,0,1-1.458,1.458Z"
                  transform="translate(0-2.75)" />
              </svg>
            </span>
            <span class="c-nav-list__text">{{'Menu.Assesment.Configure.valor' | translate}}</span>
          </a>
        </li>
        <!--seleccionar terceros-->
        <li class="c-nav-list__item">
          <a [routerLink]="['/nueva-evaluacion']" id="selectThird" class="c-nav-list__link">
            <span class="c-nav-list__ico" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M21.833,10.5H20.167V8.833a.833.833,0,1,0-1.667,0V10.5H16.833a.833.833,0,0,0,0,1.667H18.5v1.667a.833.833,0,1,0,1.667,0V12.167h1.667a.833.833,0,1,0,0-1.667Z"
                  transform="translate(-2.667-1.333)" />
                <path d="M8 10A5 5 0 1 0 3 5a5 5 0 0 0 5 5M8 1.667A3.333 3.333 0 1 1 4.667 5 3.333 3.333 0 0 1 8 1.667"
                  transform="translate(-.5)" />
                <path
                  d="M7.5,14A7.508,7.508,0,0,0,0,21.5a.833.833,0,1,0,1.667,0,5.833,5.833,0,1,1,11.667,0A.833.833,0,0,0,15,21.5,7.508,7.508,0,0,0,7.5,14Z"
                  transform="translate(0-2.333)" />
              </svg>
            </span>
            <span class="c-nav-list__text">{{'Menu.Assesment.Select3pp.valor' | translate}}</span>
          </a>
        </li>
        <!--Enviar evaluación-->
        <li class="c-nav-list__item">
          <a [routerLink]="['//evaluacion-enviada']" routerLinkActive="is-active" id="send" class="c-nav-list__link">
            <span class="c-nav-list__ico" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M15.833,0H9.167A4.172,4.172,0,0,0,5,4.167v5H4.167A4.172,4.172,0,0,0,0,13.333v2.5A4.172,4.172,0,0,0,4.167,20h2.5a4.172,4.172,0,0,0,4.167-4.167V15h5A4.172,4.172,0,0,0,20,10.833V4.167A4.172,4.172,0,0,0,15.833,0ZM9.167,13.333a2.5,2.5,0,0,1-2.5-2.5A2.5,2.5,0,0,1,9.167,13.333Zm0,2.5a2.5,2.5,0,0,1-2.5,2.5h-2.5a2.5,2.5,0,0,1-2.5-2.5v-2.5a2.5,2.5,0,0,1,2.5-2.5H5A4.172,4.172,0,0,0,9.167,15Zm9.167-5a2.5,2.5,0,0,1-2.5,2.5h-5a4.14,4.14,0,0,0-.694-2.293L15,6.178V8.333a.833.833,0,1,0,1.667,0v-2.5a2.5,2.5,0,0,0-2.5-2.5h-2.5a.833.833,0,0,0,0,1.667h2.155L8.961,9.861a4.141,4.141,0,0,0-2.294-.694v-5a2.5,2.5,0,0,1,2.5-2.5h6.667a2.5,2.5,0,0,1,2.5,2.5Z" />
              </svg>
            </span>
            <span class="c-nav-list__text">{{'Menu.Assesment.Send.valor' | translate}}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <!--NAVEGACIÓN nuevo tercero-->
  <div *ngIf='global.navShow === false' class="c-lateralBar c-lateralBar--third">
    <button type="button" (click)="global.closeThird()" class="c-lateralBar-button">
      <span class="c-lateralBar-button__text"></span>
    </button>
  </div>
  <!--NAVEGACIÓN rellenar risklist-->
  <div class="c-lateralBar c-lateralBar--risklist">
    <a (click)="exitFillRiskList()" class="c-lateralBar-button">
      <span class="c-lateralBar-button__text">{{'Exit.valor' | translate}}</span>
    </a>
    <nav class="c-nav">
      <ul class="c-nav-list">
      </ul>
    </nav>
  </div>
  <!--NAVEGACIÓN importar terceros masiva-->
  <div *ngIf='global.navShowImportThird === false' class="c-lateralBar c-lateralBar--import-massive">
    <button type="button" (click)="global.closeImportThird()" class="c-lateralBar-button">
      <span class="c-lateralBar-button__text">{{'Exit.valor' | translate}}</span>
    </button>
    <nav class="c-nav">
      <ul class="c-nav-list">
      </ul>
    </nav>
  </div>
  <!--NAVEGACIÓN Onboarding-->
  <div *ngIf='global.navShowOnboarding === false' class="c-lateralBar c-lateralBar--onboarding">
    <button *ngIf="false" type="button" (click)="global.closeOnboarding()" class="c-lateralBar-button">
      <span class="c-lateralBar-button__text">{{'Exit.valor' | translate}}</span>
    </button>
    <nav class="c-nav">
      <ul class="c-nav-list">
        <!--introducción-->
        <li class="c-nav-list__item">
          <a [routerLink]="['/onboarding-introduccion']" routerLinkActive="is-active" class="c-nav-list__link">
            <span class="c-nav-list__ico" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M10 0A10 10 0 1 0 20 10 10 10 0 0 0 10 0m0 18.333A8.333 8.333 0 1 1 18.333 10 8.333 8.333 0 0 1 10 18.333" />
                <path
                  d="M11.667,10h-.833a.833.833,0,0,0,0,1.667h.833v5a.833.833,0,1,0,1.667,0v-5A1.667,1.667,0,0,0,11.667,10Z"
                  transform="translate(-1.667-1.666)" />
                <circle cx="1.25" cy="1.25" r="1.25" transform="translate(8.75 4.166)" />
              </svg>
            </span>
            <span class="c-nav-list__text">{{'Menu.Onboarding.Introduction.valor' | translate}}</span>
          </a>
        </li>
        <!--datos iniciales-->
        <li class="c-nav-list__item">
          <a [routerLink]="['/onboarding-rellenar-ficha-cliente']" routerLinkActive="is-active"
            class="c-nav-list__link">
            <span class="c-nav-list__ico" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M15.833,3.333H12.5V2.5a2.5,2.5,0,1,0-5,0v.833H4.167A4.172,4.172,0,0,0,0,7.5v8.333A4.172,4.172,0,0,0,4.167,20H15.833A4.172,4.172,0,0,0,20,15.833V7.5a4.172,4.172,0,0,0-4.167-4.167ZM9.167,2.5a.833.833,0,1,1,1.667,0V4.167a.833.833,0,1,1-1.667,0Zm9.167,13.333a2.5,2.5,0,0,1-2.5,2.5H4.167a2.5,2.5,0,0,1-2.5-2.5V7.5A2.5,2.5,0,0,1,4.167,5H7.653a2.485,2.485,0,0,0,4.693,0h3.487a2.5,2.5,0,0,1,2.5,2.5Zm-10-7.5H4.167a.833.833,0,0,0-.833.833v6.667a.833.833,0,0,0,.833.833H8.333a.833.833,0,0,0,.833-.833V9.167A.833.833,0,0,0,8.333,8.333ZM7.5,15H5V10H7.5Zm9.167-2.5a.833.833,0,0,1-.833.833H11.667a.833.833,0,0,1,0-1.667h4.167A.833.833,0,0,1,16.667,12.5Zm0-3.333a.833.833,0,0,1-.833.833H11.667a.833.833,0,0,1,0-1.667h4.167A.833.833,0,0,1,16.667,9.167ZM15,15.833a.833.833,0,0,1-.833.833h-2.5a.833.833,0,1,1,0-1.667h2.5A.833.833,0,0,1,15,15.833Z" />
              </svg>
            </span>
            <span class="c-nav-list__text">{{'Menu.Onboarding.InitialData.valor' | translate}}</span>
          </a>
        </li>
        <!--finalizar-->
        <li class="c-nav-list__item">
          <a [routerLink]="['/onboarding-cuenta-configurada']" routerLinkActive="is-active" class="c-nav-list__link">
            <span class="c-nav-list__ico" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M15.833,0H9.167A4.172,4.172,0,0,0,5,4.167v5H4.167A4.172,4.172,0,0,0,0,13.333v2.5A4.172,4.172,0,0,0,4.167,20h2.5a4.172,4.172,0,0,0,4.167-4.167V15h5A4.172,4.172,0,0,0,20,10.833V4.167A4.172,4.172,0,0,0,15.833,0ZM9.167,13.333a2.5,2.5,0,0,1-2.5-2.5A2.5,2.5,0,0,1,9.167,13.333Zm0,2.5a2.5,2.5,0,0,1-2.5,2.5h-2.5a2.5,2.5,0,0,1-2.5-2.5v-2.5a2.5,2.5,0,0,1,2.5-2.5H5A4.172,4.172,0,0,0,9.167,15Zm9.167-5a2.5,2.5,0,0,1-2.5,2.5h-5a4.14,4.14,0,0,0-.694-2.293L15,6.178V8.333a.833.833,0,1,0,1.667,0v-2.5a2.5,2.5,0,0,0-2.5-2.5h-2.5a.833.833,0,0,0,0,1.667h2.155L8.961,9.861a4.141,4.141,0,0,0-2.294-.694v-5a2.5,2.5,0,0,1,2.5-2.5h6.667a2.5,2.5,0,0,1,2.5,2.5Z" />
              </svg>
            </span>
            <span class="c-nav-list__text">{{'Menu.Onboarding.Finish.valor' | translate}}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
<router-outlet></router-outlet>
