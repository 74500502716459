<section class="c-table">
  <div class="c-table__top">
    <app-text-table-filter (filter)="handleFilterChange($event)"></app-text-table-filter>
  </div>
  <div class="o-scroll">
    <div class="c-table__bottom">
      <div *ngIf="isLoading!=undefined? isLoading : false">
        <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
      </div>
      <table mat-table [dataSource]="this.queryResult?.items" class="mat-elevation-z8" matSort
        (matSortChange)="sortBy($event)">
        <!--name columna-->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'TablaCampaña.header.name.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element" class="bold"> <span>{{element.name}}</span> </td>
        </ng-container>

        <!--start columna-->
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'TablaCampaña.header.start.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="c-table__limit">
              {{element.startDate | date:'d MMM y, H:mm':undefined:locale}}
            </span>
          </td>
        </ng-container>

        <!--end columna-->
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'TablaCampaña.header.end.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.endDate | date:'d MMM y, H:mm':undefined:locale}}
            </span>
          </td>
        </ng-container>
        <!--terceros columna-->
        <ng-container matColumnDef="numThirdParties">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'TablaCampaña.header.3pps.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.thirdParties.length}}
          </td>
        </ng-container>
        <!--responsable-->
        <ng-container matColumnDef="responsible">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            {{'TablaCampaña.header.responsible.valor' | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <app-responsible-user [responsibleUserId]="element.userResponsibleId"></app-responsible-user>
          </td>
        </ng-container>
        <!--acciones-->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            {{'TablaCampaña.header.actions.valor' | translate}}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *appAuthRole="['Auditor']">
              <a [routerLink]="['/detalle-evaluacion',element.id]" class="c-table__watch">
                <span class="c-box-edit__button-ico" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12.747">
                    <path
                      d="m19.873 98.82c-.179-.244-4.436-5.985-9.873-5.985s-9.695 5.74-9.873 5.984a.66 .66 0 0 0 0 .778c.178.244 4.436 5.985 9.873 5.985s9.694-5.74 9.873-5.984a.659 .659 0 0 0 0 -.778m-9.873 5.444c-4 0-7.474-3.81-8.5-5.05 1.026-1.247 4.487-5.06 8.5-5.06s7.473 3.809 8.5 5.06c-1.025 1.247-4.486 5.05-8.5 5.05"
                      transform="translate(0-92.83)" />
                    <path
                      d="M158.678 154.725a3.956 3.956 0 1 0 3.956 3.956A3.961 3.961 0 0 0 158.678 154.725m0 6.593a2.637 2.637 0 1 1 2.637 -2.637A2.64 2.64 0 0 1 158.678 161.318"
                      transform="translate(-148.68-152.3)" />
                  </svg>
                </span> {{'TablaCampaña.action.view.valor' | translate}}
              </a>
            </ng-container>
            <ng-container *appAuthRole="['Owner','Manager','Assistant','Collaborator']">
              <a *ngIf="!element.canEdit" [routerLink]="['/detalle-evaluacion',element.id]" class="c-table__watch">
                <span class="c-box-edit__button-ico" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12.747">
                    <path
                      d="m19.873 98.82c-.179-.244-4.436-5.985-9.873-5.985s-9.695 5.74-9.873 5.984a.66 .66 0 0 0 0 .778c.178.244 4.436 5.985 9.873 5.985s9.694-5.74 9.873-5.984a.659 .659 0 0 0 0 -.778m-9.873 5.444c-4 0-7.474-3.81-8.5-5.05 1.026-1.247 4.487-5.06 8.5-5.06s7.473 3.809 8.5 5.06c-1.025 1.247-4.486 5.05-8.5 5.05"
                      transform="translate(0-92.83)" />
                    <path
                      d="M158.678 154.725a3.956 3.956 0 1 0 3.956 3.956A3.961 3.961 0 0 0 158.678 154.725m0 6.593a2.637 2.637 0 1 1 2.637 -2.637A2.64 2.64 0 0 1 158.678 161.318"
                      transform="translate(-148.68-152.3)" />
                  </svg>
                </span> {{'TablaCampaña.action.view.valor' | translate}}
              </a>
              <a *ngIf="element.canEdit" [routerLink]="['/nueva-evaluacion',element.id]" class="c-table__watch">
                <span class="c-box-edit__button-ico" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      d="M16.38.782,6.22,10.942A4.138,4.138,0,0,0,5,13.888v1.119a.833.833,0,0,0,.833.833H6.952A4.138,4.138,0,0,0,9.9,14.621l10.16-10.16a2.6,2.6,0,0,0,0-3.678,2.662,2.662,0,0,0-3.678,0Zm2.5,2.5L8.72,13.442a2.517,2.517,0,0,1-1.767.732H6.667v-.286A2.517,2.517,0,0,1,7.4,12.121l10.16-10.16a.957.957,0,0,1,1.322,0,.936.936,0,0,1,0,1.322Z"
                      transform="translate(-.833-.008)" />
                    <path
                      d="M19.167,7.482a.833.833,0,0,0-.833.833V12.5H15A2.5,2.5,0,0,0,12.5,15v3.333H4.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5H11.7A.833.833,0,0,0,11.7,0H4.167A4.172,4.172,0,0,0,0,4.167V15.833A4.172,4.172,0,0,0,4.167,20h9.452a4.14,4.14,0,0,0,2.947-1.22l2.213-2.215A4.14,4.14,0,0,0,20,13.619v-5.3A.833.833,0,0,0,19.167,7.482ZM15.387,17.6a2.479,2.479,0,0,1-1.221.667V15A.833.833,0,0,1,15,14.167h3.271a2.513,2.513,0,0,1-.667,1.22Z" />
                  </svg>
                </span> {{'TablaCampaña.action.edit.valor' | translate}}
              </a>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">{{'TablaCampaña.noresults.valor' | translate}}</td>
        </tr>
      </table>
      <mat-paginator #paginator (page)="onPageChange($event)" [pageSize]="query.PageSize"
        [length]="this.queryResult?.totalItems" [pageSizeOptions]="[5, 10, 25, 100]" class="c-pagination">
      </mat-paginator>
    </div>
  </div>
</section>
