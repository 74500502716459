<div class="o-wrapper">
  <main id="content">
    <!--encabezado de sección-->
    <div class="c-head-section c-head-section--tab bg-cream">
      <!--parte superior-->
      <div class="c-head-section--tab__top">
        <div class="c-head-section__left">
          <div class="c-head-section--tab__info">
            <h2 class="c-head-section--tab__title">{{'resolutionDetails.title'| translate}}</h2>
            <div class="c-head-section--tab-data">
              <div class="c-head-section--tab-data__item">
                <div>
                  {{'resolutionDetails.subtitle' | translate}} {{ name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-head-section__right">
          <button *appAuthRole="['Owner','Manager']" type="button" class="c-button"
            (click)="downloadReport()">{{'resolutionDetails.downloadReport'| translate}}</button>
        </div>
      </div>
      <!--volver a listado-->
      <div class="c-go-back">
        <a [routerLink]="" (click)="back()" class="c-go-back__button">
          <span class="c-go-back__arrow" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
              <path
                d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                transform="translate(-5-6.01)" />
            </svg>
          </span> {{'resolutionDetails.goBack'|translate}}
        </a>
      </div>
      <!--parte inferior-->
      <div class="c-head-section--tab__bottom">
      </div>
    </div>
    <div class="o-space-lateral o-scroll">
      <div class="c-form c-form--space">
        <div class="c-fields">
          <p id="keyData" class="c-fields__legend-l u-mt-1medium">{{'resolutionDetails.basicData'| translate}}
          </p>
          <div class="c-fields__group" aria-labelledby="keyData">
            <div class="c-fields-item-l">
              <p id="nameSearched" class="c-fields-item-l__title">
                {{'resolutionDetails.nameSearched'| translate}}
              </p>
              <p class="c-fields-item-l__text" aria-labelledby="nameSearched">{{name || ""}}</p>
            </div>
            <div class="c-fields-item-l">
              <p id="resolutionDate" class="c-fields-item-l__title">{{'resolutionDetails.resolutionDate'|
                translate}}</p>
              <p class="c-fields-item-l__text" aria-labelledby="resolutionDate">
                {{resolutionRecord?.creationDate | date:'dd/MM/yyyy - HH:mm'}}
              </p>
            </div>
            <div class="c-fields-item-l">
              <p id="createdBy" class="c-fields-item-l__title">{{'resolutionDetails.createdBy'|
                translate}}:</p>
              <p class="c-fields-item-l__text" aria-labelledby="createdBy">
                {{ resolutionRecord?.user?.name || ""}} {{ resolutionRecord?.user?.surName || ""}}
              </p>
            </div>
            <div class="c-fields-item-l">
              <p id="thirdCategory" class="c-fields-item__title">
                {{'resolutionDetails.searchType'| translate}}
              </p>
              <p class="c-fields-item-l__text" aria-labelledby="thirdCategory">
                {{getSearchTypeName(resolutionRecord?.searchType)}}
              </p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="c-fields">
          <p id="moreInfo" class="c-fields__legend-l u-mt-1medium">
            {{'resolutionDetails.resolution'| translate}}
          </p>
          <div class="c-fields__group" aria-labelledby="moreInfo">
            <div class="c-fields-item-xl">
              <p class="c-fields-item-xl__text">
                {{'resolutionDetails.reason'|translate}}
              </p>
              <p class="c-fields-item-xl__title" aria-labelledby="thirdName">
                {{ getReason(resolutionRecord?.reason) || ""}}
              </p>
            </div>
          </div>
          <div class="c-fields__group" aria-labelledby="moreInfo">
            <div class="c-fields-item-xl">
              <p class="c-fields-item-xl__title">
                {{resolutionRecord?.reasonExplanation || ""}}
              </p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="c-fields">
          <p id="result list" class="c-fields__legend-l u-mt-1medium">
            {{'resolutionDetails.serchResults'|translate}} {{resolutionRecord?.totalResults}}
            {{'resolutionDetails.results' | translate}}
          </p>
          <app-refinitiv-result-list *ngIf="resolutionRecord.software === ScreeningProvider.Refinitiv"
            [data]="resolutionRecord.data">
          </app-refinitiv-result-list>
          <aml-update-result-list *ngIf="resolutionRecord.software === ScreeningProvider.AMLUpdate"
            [data]="resolutionRecord.data">
          </aml-update-result-list>
        </div>
        <mat-divider></mat-divider>
        <div class="c-fields">
          <p id="comment" class="c-fields__legend-l u-mt-1medium">{{'resolutionDetails.comment'|translate}}
          </p>
          <app-resolution-record-comments [resolutionRecord]="resolutionRecord"></app-resolution-record-comments>
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="resolutionRecord.software === ScreeningProvider.Refinitiv" class="c-fields">
          <p id="externalSources" class="c-fields__legend-l u-mt-1medium">Refinitiv World-Check Legal Notice :
          </p>
          <div class="c-fields-item-xl">
            The contents of this record are private and confidential and should not be disclosed to third
            parties unless: (i) the terms of your agreement with Riskallay allow you to do so; (ii)
            the record subject requests any data that you may hold on them, and such data includes their
            World-Check record; or (iii) you are under some other legal obligation to do so.
            You must consider and abide by your own obligations in relation to the data privacy rights of
            individuals and must notify them of your intention to search against World-Check and
            provide them with information contained in the World-Check privacy statement : <a
              href="https://www.refinitiv.com/en/products/world-check-kyc-screening/privacy-statement" target="_blank"
              rel="noopener noreferrer"
              style="cursor: pointer; color: #f89f10;">https://www.refinitiv.com/en/products/world-check-kyc-screening/privacy-statement</a>
            You shall not rely upon the content of this report without making independent checks to verify
            the information contained therein. Information correlated is necessarily brief and
            should be read by you in the context of the fuller details available in the external sources to
            which links are provided. The accuracy of the information found in the underlying
            sources must be verified with the record subject before any action is taken and you should
            inform us if any links to the sources are broken. If this record contains negative
            allegations, it should be assumed that such allegations are denied by the subject. You should
            not draw any negative inferences about individuals or entities merely because they
            are identified in the database, nor because they are shown as """"Reported being linked to""""
            others identified in the database. The nature of linking varies considerably. Many
            persons are included solely because they hold or have held prominent political positions or are
            connected to such individuals.
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
