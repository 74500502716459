<section class="c-login">
  <div class="c-login__wrapper">
    <!--parte izquierda - imagen-->
    <div class="c-login__left" aria-hidden="true">
      <div class="c-login-header">
        <div class="c-login-header__image" aria-hidden="true">
          <img src="./assets/image-logo.svg" alt="" loading="lazy" />
        </div>
      </div>
      <div class="c-login__opacity"></div>
    </div>
    <!--parte derecha - form-->
    <div class="c-login__right">
      <div class="c-login__right-top">
        <h2 class="c-login__subtitle">{{ "createThirdPartyUser.title" | translate }}</h2>
        <p class="o-text">{{ "createThirdPartyUser.text" | translate }}</p>
        <form [formGroup]="createUserForm" class="c-login-form" *ngIf="!loading">
          <fieldset>
            <div class="row">
              <div class="col-12">
                <legend class="c-login__legend">
                  {{ "createThirdPartyUser.userSectionTitle" | translate }}
                </legend>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-xl-6">
                <div class="c-form__block">
                  <mat-form-field>
                    <input type="text" matInput name="name" id="config-name" class="c-form__input"
                      formControlName="name" placeholder="{{
                        'createThirdPartyUser.namePlaceholder' | translate
                      }}" autocomplete="off" required />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="c-form__block">
                  <mat-form-field>
                    <input type="text" matInput name="surname" id="config-surname" class="c-form__input"
                      formControlName="surname" placeholder="{{
                        'createThirdPartyUser.surnamePlaceholder' | translate
                      }}" autocomplete="off" required />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12">
                <div class="c-login-form__block">
                  <mat-form-field>
                    <input type="email" matInput name="email" id="config-email" class="c-form__input"
                      formControlName="email" placeholder="Email" required />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="c-login-form__block">
                  <div class="c-select">
                    <mat-form-field>
                      <mat-select formControlName="language"
                        placeholder="{{'createThirdPartyUser.languagePlaceholder' | translate }}"
                        (selectionChange)="changeLanguage($event)" required>
                        <mat-option *ngFor="let language of languages" [value]="language">
                          {{ getLanguageTranslationKey(language) | translate }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="c-form__block">
                  <div class="c-select">
                    <mat-form-field>
                      <mat-select formControlName="jobTitle"
                        placeholder="{{'createThirdPartyUser.jobTitlePlaceholder' | translate }}" required>
                        <mat-option *ngFor="let position of positions" [value]="position">
                          {{ getPositionTranslationKey(position) | translate }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div class="row">
              <div class="col-12">
                <div class="row u-mb-1">
                  <div class="col-12">
                    <legend class="c-login__legend">
                      {{ "createThirdPartyUser.accessSectionTitle" | translate }}
                    </legend>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!--contraseña-->
              <div class="c-login-form__block col-12 col-xl-6">
                <mat-form-field>
                  <input formControlName="password" type="{{ typePassword ? 'text' : 'password' }}" matInput
                    id="password" class="c-login__input" aria-errormessage="e" placeholder="{{
                      'createThirdPartyUser.passwordPlaceholder' | translate
                    }}" required />
                  <button type="button" (click)="togglePassword()" class="c-login__toggle-pass" attr.aria-label="{{
                      'createThirdPartyUser.toggleShowPassword' | translate
                    }}">
                    <span *ngIf="!isShow" aria-hidden="true">
                      <img src="assets/ico-eye-close.svg" alt="" loading="lazy" />
                    </span>
                    <span *ngIf="isShow" aria-hidden="true">
                      <img src="assets/ico-eye-open.svg" alt="" loading="lazy" />
                    </span>
                  </button>
                </mat-form-field>
                <div *ngIf="password.invalid && password.touched" class="row">
                  <p role="alert" *ngIf="password.errors.required" id="e" class="col-12 c-form__message-error">
                    {{ "createThirdPartyUser.requiredField" | translate }}
                  </p>
                  <p role="alert" *ngIf="password.errors.invalidLength" id="e" class="col-12 c-form__message-error">
                    {{ "createThirdPartyUser.invalidLength" | translate }}
                  </p>
                  <p role="alert" *ngIf="password.errors.mustContainUpper" id="e" class="col-12 c-form__message-error">
                    {{
                    "createThirdPartyUser.mustContainUpper" | translate
                    }}
                  </p>
                  <p role="alert" *ngIf="password.errors.mustContainLower" id="e" class="col-12 c-form__message-error">
                    {{
                    "createThirdPartyUser.mustContainLower" | translate
                    }}
                  </p>
                  <p role="alert" *ngIf="password.errors.mustContainNumber" id="e" class="col-12 c-form__message-error">
                    {{ "createThirdPartyUser.mustContainNumber" }}
                  </p>
                  <p role="alert" *ngIf="password.errors.mustContainSpecial" id="e"
                    class="col-12 c-form__message-error">
                    {{
                    "createThirdPartyUser.mustContainSpecial" | translate
                    }}
                  </p>
                </div>
              </div>
              <!--repetir contraseña-->
              <div class="c-login-form__block col-12 col-xl-6">
                <mat-form-field>
                  <input type="{{ typePasswordRepeat ? 'text' : 'password' }}" matInput id="password-again"
                    class="c-login__input" formControlName="passwordConfirm" aria-errormessage="er" placeholder="{{
                      'createThirdPartyUser.passwordRepeatPlaceholder'
                        | translate
                    }}" required />
                  <button type="button" (click)="togglePasswordRepeat()" class="c-login__toggle-pass" attr.aria-label="{{
                      'createThirdPartyUser.toggleShowPassword' | translate
                    }}">
                    <span *ngIf="!isShowRepeat" aria-hidden="true">
                      <img src="assets/ico-eye-close.svg" alt="" loading="lazy" />
                    </span>
                    <span *ngIf="isShowRepeat" aria-hidden="true">
                      <img src="assets/ico-eye-open.svg" alt="" loading="lazy" />
                    </span>
                  </button>
                </mat-form-field>
                <div role="alert" *ngIf="passwordConfirm.invalid && password.touched" class="c-form__message-error row"
                  aria-labelledby="password-again">
                  <p class="col-12" *ngIf="passwordConfirm.errors.required" id="er">
                    {{ "createThirdPartyUser.requiredField" | translate }}
                  </p>
                </div>
              </div>
              <p role="alert" *ngIf="
                  createUserForm.errors?.passwordsDifferent &&
                  (createUserForm.touched || createUserForm.dirty)
                " class="col-12 c-form__message-error text-center">
                {{ "createThirdPartyUser.passwordsDoNotMatch" | translate }}
              </p>
            </div>
          </fieldset>
          <fieldset>
            <div class="row">
              <div class="col-12">
                <div class="row u-mb-1">
                  <div class="col-12">
                    <legend class="c-login__legend">
                      {{ "createThirdPartyUser.companySectionTitle" | translate }}
                    </legend>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="c-form__block">
                      <mat-form-field>
                        <input type="text" matInput name="company-name" id="config-company-name" class="c-form__input"
                          formControlName="companyName" placeholder="{{
                            'createThirdPartyUser.companyNamePlaceholder' | translate
                          }}" required />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6">
                    <div class="c-form__block">
                      <mat-form-field>
                        <input type="text" matInput name="tax-id" id="config-tax-id" class="c-form__input"
                          formControlName="taxId" placeholder="{{
                            'createThirdPartyUser.nifPlaceholder' | translate
                          }}" required />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6">
                    <div class="c-login-form__block">
                      <div class="c-select">
                        <mat-form-field>
                          <mat-select formControlName="country"
                            placeholder="{{'createThirdPartyUser.countryPlaceholder' | translate }}" required>
                            <mat-option *ngFor="let country of countries" [value]="country.Id">
                              {{country | translatedescription}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6">
                    <div class="c-login-form__block">
                      <div class="c-select">
                        <mat-form-field>
                          <mat-select formControlName="typeOfActivity" placeholder="{{'createThirdPartyUser.activityTypePlaceholder' | translate }}" required>
                            <mat-option *ngFor="let typeOfActivity of typeOfActivities" [value]="typeOfActivity.Id">
                              {{typeOfActivity | translatedescription}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6">
                    <div class="c-login-form__block">
                      <div class="c-select">
                        <mat-form-field>
                          <mat-select formControlName="sector"
                            placeholder="{{'createThirdPartyUser.sectorPlaceholder' | translate }}" required>
                            <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                              {{sector | translatedescription}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-8">
                    <div class="c-login-form__block">
                      <mat-form-field>
                        <input type="text" matInput name="address" id="config-address" class="c-form__input"
                          formControlName="address" placeholder="{{
                            'createThirdPartyUser.addressPlaceholder' | translate
                          }}" required />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <div class="c-login-form__block">
                      <mat-form-field>
                        <input type="text" matInput name="zip-code" id="config-zip-code" class="c-form__input"
                          formControlName="zipCode" placeholder="{{
                            'createThirdPartyUser.zipCodePlaceholder' | translate
                          }}" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="c-checkbox u-mt-1medium">
                  <mat-checkbox formControlName="checkPolicy">{{ "createThirdPartyUser.legalText" | translate
                    }}<a href="https://www.riskallay.com/legales/politica-privacidad" target="_blank"
                      class="o-checkbox__link">{{ "createThirdPartyUser.privacyPolicy" | translate }}</a></mat-checkbox>
                </div>
                <!--aceptar-->
                <button type="submit" [disabled]="createUserForm.status != 'VALID' || sendingData"
                  (click)="createThirdPartyUser()" class="c-button">
                  {{ "createThirdPartyUser.completeChecklist" | translate }}
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</section>
