<div *ngIf="campaignThirdParty?.status == campaignStatus.reviewed"
    class="c-state" aria-hidden="true">
    <img src="/assets/campaignStatusReviewed.svg" alt="">
</div>
<div *ngIf="campaignThirdParty?.status == campaignStatus.inProgress"
    class="c-state" aria-hidden="true">
    <img src="/assets/campaignStatusInProgress.svg" alt="">
</div>
<div *ngIf="campaignThirdParty?.status == campaignStatus.pending"
    class="c-state" aria-hidden="true">
    <img src="/assets/campaingStatusPendingResponse.svg" alt="">
</div>
<div *ngIf="campaignThirdParty?.status == campaignStatus.pendingSign"
    class="c-state" aria-hidden="true">
    <img src="/assets/campaignStatusPendingSign.svg" alt="">
</div>
<div *ngIf="campaignThirdParty?.status == campaignStatus.pendingReview"
    class="c-state" aria-hidden="true">
    <img src="/assets/campaignStatusPendingReview.svg" alt="">
</div>
<div *ngIf="campaignThirdParty?.status == campaignStatus.discarded"
    class="c-state" aria-hidden="true">
    <img src="/assets/campaignStatusDiscarded.svg" alt="">
</div>
<div *ngIf="campaignThirdParty?.status == campaignStatus.acknowledged"
    class="c-state" aria-hidden="true">
    <img src="/assets/campaignStatusAcknowledged.svg" alt="">
</div>
<span class="status-description">{{translateStatusDescription(campaignThirdParty?.status)}}</span>