<div *ngIf="loading">
  <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CustomFieldsTable.header.name' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{element | translatedescription}} </td>
  </ng-container>

  <ng-container matColumnDef="Type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CustomFieldsTable.header.type' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{element.type | translatedescription}} </td>
  </ng-container>

  <ng-container matColumnDef="Mandatory">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CustomFieldsTable.header.mandatory' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{(element.mandatory ? 'CustomFieldsTable.yes' : 'CustomFieldsTable.no') | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="Options">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CustomFieldsTable.header.options' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{displayOptions(element.options, element.hasTranslations) || '-'}} </td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef> {{'CustomFieldsTable.header.actions' | translate}} </th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="['/custom-field-detail', element.id]" *ngIf="this.actions.includes('update')"
        class="c-table__watch">
        <span class="c-box-edit__button-ico" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M16.38.782,6.22,10.942A4.138,4.138,0,0,0,5,13.888v1.119a.833.833,0,0,0,.833.833H6.952A4.138,4.138,0,0,0,9.9,14.621l10.16-10.16a2.6,2.6,0,0,0,0-3.678,2.662,2.662,0,0,0-3.678,0Zm2.5,2.5L8.72,13.442a2.517,2.517,0,0,1-1.767.732H6.667v-.286A2.517,2.517,0,0,1,7.4,12.121l10.16-10.16a.957.957,0,0,1,1.322,0,.936.936,0,0,1,0,1.322Z"
              transform="translate(-.833-.008)" />
            <path
              d="M19.167,7.482a.833.833,0,0,0-.833.833V12.5H15A2.5,2.5,0,0,0,12.5,15v3.333H4.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5H11.7A.833.833,0,0,0,11.7,0H4.167A4.172,4.172,0,0,0,0,4.167V15.833A4.172,4.172,0,0,0,4.167,20h9.452a4.14,4.14,0,0,0,2.947-1.22l2.213-2.215A4.14,4.14,0,0,0,20,13.619v-5.3A.833.833,0,0,0,19.167,7.482ZM15.387,17.6a2.479,2.479,0,0,1-1.221.667V15A.833.833,0,0,1,15,14.167h3.271a2.513,2.513,0,0,1-.667,1.22Z" />
          </svg>
        </span> {{'CustomFieldsTable.actions.update' | translate}}
      </a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9">{{'CustomFieldsTable.noResults' | translate}} </td>
  </tr>
</table>

<mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25]" class="c-pagination">
</mat-paginator>

<div class="row u-mt-2">
  <div class="col-12">
    <button type="button" [routerLink]="['/add-custom-field']" class="c-button c-button--add c-button--add-bg"
      data-test-id="add-custom-field-btn">
      <span class="c-button--add__ico" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path data-name=" ↳Color" d="M14,8H8v6H6V8H0V6H6V0H8V6h6Z" transform="translate(5 5)" />
        </svg>
      </span>
      {{'CustomFieldsTable.addCustomField' | translate}}
    </button>
  </div>
</div>
