import { WatchListInfoDto } from "src/proxy/model/watchListInfoDto";
import { WatchListInfo } from "../../models/GesintelScreening/WatchListInfo";
import { mapAlias } from "./alias.mapping";
import { mapDocument } from "./document.mapping";
import { mapRegion } from "./region.mappings";
import { mapRole } from "./role.mapping";
import { AdverseMediaInfoDto } from "src/proxy/model/adverseMediaInfoDto";

export function mapWatchListInfo(dto: WatchListInfoDto | AdverseMediaInfoDto, entityType: string): WatchListInfo {
  if (!dto) return null;

  return <WatchListInfo> {
    profileId: dto.ProfileId,
    entityType: entityType,
    clasification: dto.Clasification,
    profileUpdateDate: dto.ProfileUpdateDate,
    gender: dto.Gender,
    deseaced: dto.Deseaced,
    firstName: dto.FirstName,
    middleName: dto.MiddleName,
    lastName: dto.LastName,
    originalScriptName: dto.OriginalScriptName,
    aliases: dto.Aliases?.map(a => mapAlias(a)),
    aliasVariations: dto.AliasVariations?.map(a => mapAlias(a)),
    photos: dto.Photos,
    regions: dto.Regions?.map(r => mapRegion(r)),
    roles: dto.Roles?.map(r => mapRole(r)),
    documents: dto.Documents?.map(d => mapDocument(d)),
  }
}
