import { Directive, ViewContainerRef, TemplateRef, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';
import { canAccess } from './riskallay.roles';

@Directive({
  selector: '[appAuthRole]'
})
export class AuthRoleDirective implements OnInit {

  @Input() appAuthRole: string[];
  // policies = getAppPolicies();

  visible = false;

  constructor(private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    const userRoles = this.authenticationService.getRoles();

    if (!userRoles || userRoles.length === 0) {
      this.hide();
      return;
    }

    if (canAccess(this.appAuthRole, userRoles)) {
      // Creo que siempre será false
      if (!this.visible) {
        this.visible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.hide();
    }
  }

  private hide() {
    this.visible = false;
    this.viewContainerRef.clear();
  }

  

}
