import { NatureOfActivityDTO } from "@app/models/NatureOfActivity";
import { Sector, SectorDTO } from "@app/models/Sector";
import { ThirdParty, ThirdPartyDTO, ThirdPartySignIn } from "@app/models/ThirdParty";
import { ThirdPartyConfigDTO } from "@app/models/ThirdPartyConfig";
import { ThirdPartyTypeDTO } from "@app/models/ThirdPartyType";
import { TypeOfActivityDTO } from "@app/models/TypeOfActivity";
import { ActivityNatureDto } from "src/proxy/model/activityNatureDto";
import { ActivityTypeDto } from "src/proxy/model/activityTypeDto";
import { SectorDto } from "src/proxy/model/sectorDto";
import { ThirdPartyConfigDto } from "src/proxy/model/thirdPartyConfigDto";
import { ThirdPartyDto } from "src/proxy/model/thirdPartyDto";
import { ThirdPartySignInDto } from "src/proxy/model/thirdPartySignInDto";
import { ThirdPartyTypeDto } from "src/proxy/model/thirdPartyTypeDto";
import { mapCountry, mapCountryDTO } from "./country.mappings";
import { mapDescriptionToCurrentLanguage } from "./description-translation.mapping";

export function mapThirdPartyDtoToThirdParty(dto: ThirdPartyDto, currentLanguage: string): ThirdParty {
  if (!dto) return null;

  return <ThirdParty>{
    id: dto.Id,
    companyCif: dto.CompanyCIF,
    companyName: dto.CompanyName,
    alias: dto.Alias,
    website: dto.Website,
    fax: dto.Fax,
    country: dto.Country ? mapCountry(dto.Country, currentLanguage) : null,
    contact: null,
    thirdPartyConfig: dto.ThirdPartyConfig,
    userOwnerId: dto.UserOwnerId,
    campaignStatus: null,
    canRemove: dto.CanRemove,
    progress: dto.Progress,
    signed: dto.Signed,
    status: dto.ThirdPartyConfig?.ThirdPartyStatus
  };
}
export function mapThirdParty(dto: ThirdPartyDto, currentLanguage: string, progress: number): ThirdPartyDTO {
  return <ThirdPartyDTO>{
    CampaignStatus: dto.Status,
    CanRemove: dto.CanRemove,
    CompanyCIF: dto.CompanyCIF,
    CompanyName: dto.CompanyName,
    Country: dto.Country ? mapCountryDTO(dto.Country, currentLanguage) : null,
    Id: dto.Id,
    ThirdPartyConfig: dto.ThirdPartyConfig ? mapThirdPartyConfig(dto.ThirdPartyConfig, currentLanguage) : null,
    progress
  };
}

function mapThirdPartyConfig(dto: ThirdPartyConfigDto, currentLanguage: string): ThirdPartyConfigDTO {
  return <ThirdPartyConfigDTO>{
    CompanyAddress: dto?.CompanyAddress,
    CompanyCP: dto?.CompanyCP,
    CompanyProvince: dto?.CompanyProvince,
    Alias: dto?.Alias,
    Website: dto?.Website,
    Fax: dto?.Fax,
    Id: dto?.Id,
    NatureOfActivity: mapNatureOfActivity(dto?.NatureOfActivity, currentLanguage),
    Sector: mapSector(dto?.Sector, currentLanguage),
    ThirdPartyStatus: dto?.ThirdPartyStatus,
    ThirdPartyType: mapThirdPartyType(dto?.ThirdPartyType, currentLanguage),
    TypeOfActivity: mapTypeOfActivity(dto?.TypeOfActivity, currentLanguage),
    LastUpdateUser: dto?.LastUpdateUser
  };
}

function mapNatureOfActivity(dto: ActivityNatureDto, currentLanguage: string): NatureOfActivityDTO {
  if (!dto) return null;

  return <NatureOfActivityDTO>{
    Code: dto?.Code,
    Description: mapDescriptionToCurrentLanguage(dto, currentLanguage),
    Id: dto?.Id
  };
}

export function mapSector(dto: SectorDto, currentLanguage: string): SectorDTO {
  return <SectorDTO>{
    Code: dto?.Code,
    Description: mapDescriptionToCurrentLanguage(dto, currentLanguage),
    Id: dto?.Id
  };
}

export function mapSectorDtoToSector(dto: SectorDto): Sector {
  if (!dto) return null;

  return <Sector>{
    id: dto.Id,
    code: dto.Code,
    description: dto.Description,
    descriptionEN: dto.DescriptionEN,
    descriptionPT: dto.DescriptionPT,
    descriptionFR: dto.DescriptionFR
  };
}

export function mapSectoroToSectorDto(dto: Sector): SectorDto {
  if (!dto) return null;

  return <SectorDto>{
    Id: dto.id,
    Code: dto.code,
    Description: dto.description,
    DescriptionEN: dto.descriptionEN,
    DescriptionPT: dto.descriptionPT,
    DescriptionFR: dto.descriptionFR
  };
}

export function mapThirdPartySignInToThirdPartySignInDto(dto: ThirdPartySignIn) {
  if (!dto) return null;

  return <ThirdPartySignInDto>{
    email: dto.email,
    contactName: dto.contactName,
    companyName: dto.companyName,
    taxId: dto.taxId,
    zipCode: dto.zipCode,
    address: dto.address,
    countryId: dto.countryId,
    sectorId: dto.sectorId,
    activityTypeId: dto.activityTypeId,
    surname: dto.surname,
    password: dto.password,
    languageId: dto.languageId,
    position: dto.position,
  }
}

function mapThirdPartyType(dto: ThirdPartyTypeDto, currentLanguage: string): ThirdPartyTypeDTO {
  return <ThirdPartyTypeDTO>{
    Code: dto?.Code,
    Description: mapDescriptionToCurrentLanguage(dto, currentLanguage),
    Id: dto?.Id
  };
}

function mapTypeOfActivity(dto: ActivityTypeDto, currentLanguage: string): TypeOfActivityDTO {
  if (!dto) return null;

  return <TypeOfActivityDTO>{
    Code: dto?.Code,
    Description: mapDescriptionToCurrentLanguage(dto, currentLanguage),
    Id: dto?.Id
  };
}
