import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Country } from '@app/models/Country';
import { GesintelOrganizationFilter } from '@app/modules/screening-hub/models/GesintelScreening/GesintelOrganizationFilter';

@Component({
  selector: 'app-scoring-candidates-organization-search-gesintel',
  templateUrl:
    './scoring-candidates-organization-search-gesintel.component.html',
  styleUrls: [
    './scoring-candidates-organization-search-gesintel.component.scss',
  ],
})
export class ScoringCandidatesOrganizationSearchGesintelComponent {
  @Input() countryList: Country[];
  @Output() onSearchClick = new EventEmitter<GesintelOrganizationFilter>();

  model: GesintelOrganizationFilter;

  constructor() {
    this.model = new GesintelOrganizationFilter();
  }

  search() {
    this.onSearchClick.emit(this.model);
  }
}
