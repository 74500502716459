import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-send-risklist',
  templateUrl: './dialog-send-risklist.component.html'
})
export class DialogSendRisklistComponent implements OnInit {

  entrada: any;

  constructor(
    public dialogRef: MatDialogRef<DialogSendRisklistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.entrada = this.data;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  sign() {
    this.dialogRef.close(true);
  }
}
