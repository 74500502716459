import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RiskListService } from '@app/services/risk-list.service';
import { Campaign } from '@app/models/Campaign';
import { CampaignStatus } from '@app/models/CampaignStatus';
import { MatDialog } from '@angular/material/dialog';
import { DialogFillClientDataComponent } from '../dialog-fill-client-data/dialog-fill-client-data.component';
import { ThirdPartiesService } from '@app/services/third-parties.services';
import { ThirdPartyConfigurationRequest } from '@app/models/ThirdPartyConfigurationRequest';
import { AuthenticationService } from '@app/services/authentication.service';

@Component({
  selector: 'app-portal-page-id',
  templateUrl: './portal-page-id.component.html',
  styleUrls: ['./portal-page-id.component.scss']
})

export class PortalPageIdComponent implements OnInit {

  locale: string;
  displayedColumns = ['name', 'assessed', 'dates', 'status', 'actions']


  //imagen superior
  headPortalImage = '/assets/headerThird.png';

  //logo
  headPortalLogo = '/assets/imagen_perfil.png';

  //risklists pendientes de rellenar
  pendingList: Campaign[] = [];

  hasPendingConfigRequest = true;
  username: string;

  constructor(
    private translate: TranslateService,
    private riskListService: RiskListService,
    private dialog: MatDialog,
    private thirdPartiesService: ThirdPartiesService,
    private auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.locale = this.translate.currentLang.split("-")[0];

    this.username = this.auth.getFieldJWT('Name');

    this.thirdPartiesService.hasPendingConfigRequest()
      .subscribe((configRequest: ThirdPartyConfigurationRequest) => {
        if (!configRequest) {
          this.hasPendingConfigRequest = false;
        } else {
          this.dialog.open(DialogFillClientDataComponent, { disableClose: true, data: configRequest });
        }
      });

    this.riskListService.getPendingRiskLists().subscribe(r => this.pendingList = r.filter(c => c.campaignStatus == CampaignStatus.pending ||
      c.campaignStatus == CampaignStatus.inProgress ||
      c.campaignStatus == CampaignStatus.pendingSign));
  }

  goToVideo() {
    if (this.locale.toLocaleLowerCase() === 'es'.toLocaleLowerCase()) {
      window.open('https://youtu.be/8SjWAPUvAtU', '_blank');
    } else {
      window.open('https://youtu.be/kdKHNC9JLSo', '_blank');
    }
  }

  checkIfExpiring(value: string) {
    const endDate = new Date(value);
    const lowerLimit = new Date();
    lowerLimit.setDate(endDate.getDate() - 2);
    const currentDate = new Date();

    return lowerLimit <= currentDate && currentDate <= endDate;
  }
}
