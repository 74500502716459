import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CustomField } from '@app/models/CustomField';
import { CustomFieldOption } from '@app/models/CustomFieldOption';
import { CustomFieldsService } from '@app/services/custom-fields.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-fields-table',
  templateUrl: './custom-fields-table.component.html',
  styleUrls: ['./custom-fields-table.component.scss']
})
export class CustomFieldsTableComponent implements OnInit, AfterContentInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<CustomField>;
  displayedColumns: string[] = ['Name', 'Type', 'Mandatory', 'Options', 'Actions'];
  actions: string[] = ['update'];
  loading: boolean = false;
  errorMessage: string;
  doneMessage: string;

  constructor(
    private customFieldsService: CustomFieldsService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.translate.get('TablaRisks.error')
      .subscribe(translation => {
        this.errorMessage = translation;
      });
    this.translate.get('TablaRisks.done')
      .subscribe(translation => {
        this.doneMessage = translation;
      });
  }

  ngAfterContentInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.customFieldsService
      .getCustomFields()
      .subscribe((customFields: CustomField[]) => {
        this.dataSource = new MatTableDataSource(customFields);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      });
  }

  displayOptions(options: CustomFieldOption[], hasTranslations: boolean) {
    if (!hasTranslations) {
      return options?.map(o => o.description).join(', ');
    }

    switch (this.translate.currentLang) {
      case 'en-US':
        return options?.map(o => o.descriptionEN).join(', ');
      case 'es-ES':
        return options?.map(o => o.description).join(', ');
      case 'pt-PT':
        return options?.map(o => o.descriptionPT).join(', ');
      case 'fr-FR':
        return options?.map(o => o.descriptionFR).join(', ');
      default:
        return options?.map(o => o.descriptionEN).join(', ');
    }
  }
}
