import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommercialService } from '@app/services/commercial.service';

@Component({
  selector: 'app-dialog-request-more-third-parties',
  templateUrl: './dialog-request-more-third-parties.component.html',
  styleUrls: ['./dialog-request-more-third-parties.component.scss']
})
export class DialogRequestThirdPartiesComponent {

    public numberOfThirdPartiesRequested: number;
    public requestRemarks: string;

    constructor(public dialogRef: MatDialogRef<DialogRequestThirdPartiesComponent>, 
        @Inject(MAT_DIALOG_DATA)public data: {totalLoaded: number},
        private commercialService: CommercialService) { }
 
    closeModal(): void {
        this.dialogRef.close(false);
    }

    submitForm(): void {
        this.commercialService.sendThirdPartyIncrementRequest(this.numberOfThirdPartiesRequested, this.requestRemarks).subscribe(r => {
            this.dialogRef.close(r);
        });
    }
}
