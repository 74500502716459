<ng-container [formGroup]="form">
  <div class="container-fluid c-form c-form--space">
    <div class="row u-mb-1medium">
      <div class="col-12">
        <h3 class="o-title o-title--medium">{{'CustomFieldForm.fieldConfiguration.title'
          | translate}}</h3>
      </div>
    </div>
    <div class="row u-mb-1">
      <div class="col-md-4">
        <div class="c-form__block u-mb-1medium">
          <mat-form-field>
            <input type="text" id="description" class="c-form__input" matInput formControlName="description"
              placeholder="{{'CustomFieldForm.fieldConfiguration.fieldName'|translate}} (ES)">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-4">
        <div class="c-form__block u-mb-1medium">
          <mat-form-field>
            <input type="text" id="descriptionEN" class="c-form__input" matInput formControlName="descriptionEN"
              placeholder="{{'CustomFieldForm.fieldConfiguration.fieldName'|translate}} (EN)">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-4">
        <div class="c-form__block u-mb-1medium">
          <mat-form-field>
            <input type="text" id="descriptionPT" class="c-form__input" matInput formControlName="descriptionPT"
              placeholder="{{'CustomFieldForm.fieldConfiguration.fieldName'|translate}} (PT)">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-4">
        <div class="c-form__block u-mb-1medium">
          <mat-form-field>
            <input type="text" id="descriptionFR" class="c-form__input" matInput formControlName="descriptionFR"
              placeholder="{{'CustomFieldForm.fieldConfiguration.fieldName'|translate}} (FR)">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row u-mb-1">
      <div class="col-md-4">
        <div class="c-switches c-switches--cols">
          <div class="c-switches--cols__group" aria-labelledby="is-mandatory">
            <div class="c-switches__items">
              <mat-slide-toggle class="c-switch" formControlName="mandatory">
                {{'CustomFieldForm.fieldConfiguration.mandatory'|translate}}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row u-mb-1medium">
      <div class="col-12">
        <h3 class="o-title o-title--medium">
          {{'CustomFieldForm.optionsConfiguration.title' | translate}}</h3>
      </div>
    </div>
    <div class="row u-mb-1medium">
      <div class="col-md-4">
        <div class="c-form__block">
          <div class="c-select c-select--height-input">
            <mat-form-field>
              <label for="fieldType" class="u-sr-only">
                {{'CustomFieldForm.optionsConfiguration.fieldType' | translate}}
              </label>
              <mat-select formControlName="type" id="fieldType"
                placeholder="{{'CustomFieldForm.optionsConfiguration.fieldType' | translate}}">
                <mat-option *ngFor="let customFieldType of customFieldTypes" [value]="customFieldType">
                  {{customFieldType | translatedescription}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="c-switches c-switches--cols">
          <div class="c-switches--cols__group" aria-labelledby="add-translations">
            <div class="c-switches__items">
              <mat-slide-toggle class="c-switch" formControlName="hasTranslations">
                {{'CustomFieldForm.optionsConfiguration.translateOptions'|translate}}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="hasOptions">
      <div class="row u-mb-1medium">
        <div class="col-12">
          <h3 class="o-title o-title--medium">
            {{'CustomFieldForm.fieldOptions.title' | translate}}</h3>
        </div>
      </div>
      <div class="row u-mb-1medium" *ngFor="let option of options.controls; let i = index">
        <div class="col-11" [formGroup]="option"
          *ngIf="!form.get('hasTranslations').value; else optionsWithTranslations">
          <div class="c-form__block u-mb-1medium">
            <mat-form-field>
              <input type="text" class="c-form__input" matInput formControlName="description"
                placeholder="{{'CustomFieldForm.fieldOptions.option'|translate}} {{i + 1}}">
            </mat-form-field>
          </div>
        </div>
        <ng-template #optionsWithTranslations>
          <div class="col-11">
            <div class="row">
              <div class="col-4" [formGroup]="option">
                <div class="c-form__block u-mb-1medium">
                  <mat-form-field>
                    <input type="text" class="c-form__input" matInput formControlName="description"
                      placeholder="{{'CustomFieldForm.fieldOptions.option'|translate}} {{i + 1}} (ES)">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-4" [formGroup]="option">
                <div class="c-form__block u-mb-1medium">
                  <mat-form-field>
                    <input type="text" class="c-form__input" matInput formControlName="descriptionEN"
                      placeholder="{{'CustomFieldForm.fieldOptions.option'|translate}} {{i + 1}} (EN)">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-4" [formGroup]="option">
                <div class="c-form__block u-mb-1medium">
                  <mat-form-field>
                    <input type="text" class="c-form__input" matInput formControlName="descriptionPT"
                      placeholder="{{'CustomFieldForm.fieldOptions.option'|translate}} {{i + 1}} (PT)">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-4" [formGroup]="option">
                <div class="c-form__block u-mb-1medium">
                  <mat-form-field>
                    <input type="text" class="c-form__input" matInput formControlName="descriptionFR"
                      placeholder="{{'CustomFieldForm.fieldOptions.option'|translate}} {{i + 1}} (FR)">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <div class="col-1 icon-wrapper">
          <div (click)="removeOption(i)" class="icon" [ngClass]="{disabled: !isOptionDeletable}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path
                d="M17.322,2.667h-2.1A3.383,3.383,0,0,0,11.9,0H8.1A3.383,3.383,0,0,0,4.78,2.667h-2.1A.667.667,0,1,0,2.678,4h.678v8.667A3.366,3.366,0,0,0,6.746,16h6.508a3.366,3.366,0,0,0,3.39-3.333V4h.678a.667.667,0,1,0,0-1.333ZM8.1,1.333h3.8a2.036,2.036,0,0,1,1.918,1.333H6.184A2.036,2.036,0,0,1,8.1,1.333Zm7.186,11.333a2.017,2.017,0,0,1-2.034,2H6.746a2.017,2.017,0,0,1-2.034-2V4H15.288Z"
                transform="translate(-2)" />
              <path d="M9.678,15.333a.672.672,0,0,0,.678-.667v-4a.678.678,0,0,0-1.356,0v4A.672.672,0,0,0,9.678,15.333Z"
                transform="translate(-4.39 -3.333)" />
              <path
                d="M13.678,15.333a.672.672,0,0,0,.678-.667v-4a.678.678,0,0,0-1.356,0v4A.672.672,0,0,0,13.678,15.333Z"
                transform="translate(-5.678 -3.333)" />
              <path
                d="M13.678,15.333a.672.672,0,0,0,.678-.667v-4a.678.678,0,0,0-1.356,0v4A.672.672,0,0,0,13.678,15.333Z"
                transform="translate(-2.966 -3.333)" />
            </svg>
          </div>
        </div>
      </div>
      <div class="row u-mb-1medium">
        <div class="col-3">
          <button type="button" class="c-button c-button--add c-button--add-bg" (click)="addOption()">
            <span class="c-button--add__ico" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path data-name=" ↳Color" d="M14,8H8v6H6V8H0V6H6V0H8V6h6Z" transform="translate(5 5)" />
              </svg>
            </span>
            {{'CustomFieldForm.fieldOptions.addOption' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
