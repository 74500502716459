import { Gender } from "@app/models/scoring-tools/gender";

export class MemberFilter {
  address: string;
  dob: Date;
  firstName: string;
  lastname: string;
  middleName: string;
  gender: Gender;
}
