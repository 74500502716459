import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { SectorCreateDTO, SectorDTO, SectorUpdateDTO } from '@app/models/Sector';
import { CommonsService } from '../../../services/commons-service';

@Component({
  selector: 'app-dialog-add-sector',
  templateUrl: './dialog-add-sector.component.html',
  styles: [
  ]
})
export class DialogAddSectorComponent implements OnInit {

  form: any;
  entry: SectorDTO = null;

  constructor(

    public dialogRef: MatDialogRef<DialogAddSectorComponent>,
    private fb: FormBuilder,
    private commons: CommonsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    let entry = this.data;

    this.form = this.fb.group({
      Description: new FormControl(entry !== null ? entry.Description : '', Validators.required),
      DescriptionEN: new FormControl(entry !== null ? entry.DescriptionEN : '', Validators.required),
      DescriptionPT: new FormControl(entry !== null ? entry.DescriptionPT : '', Validators.required),
      DescriptionFR: new FormControl(entry !== null ? entry.DescriptionFR : '', Validators.required),
    });
  }

  // cerrar modal
  onNoClick(): void {
    this.dialogRef.close();
  }

  async addSector() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;

    let s = (<SectorCreateDTO>{});
    s.Code = description;
    s.Description = description;
    s.DescriptionEN = descriptionEN;
    s.DescriptionPT = descriptionPT;
    s.DescriptionFR = descriptionFR;
    s.Client = null;

    this.commons.postSector(s)
      .then(r => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }

  async updateSector() {
    let description = this.form.get("Description").value;
    let descriptionEN = this.form.get("DescriptionEN").value;
    let descriptionPT = this.form.get("DescriptionPT").value;
    let descriptionFR = this.form.get("DescriptionFR").value;


    let u = (<SectorUpdateDTO>{});
    u.Id = this.data.Id;
    u.Code = description;
    u.Description = description;
    u.DescriptionEN = descriptionEN;
    u.DescriptionPT = descriptionPT;
    u.DescriptionFR = descriptionFR;
    u.Client = null;
    this.commons.putSector(u)
      .then(r => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.dialogRef.close(false);
      });
  }
}
