import { Injectable } from '@angular/core';
import { ThirdPartySignIn } from '@app/models/ThirdParty';
import { Observable } from 'rxjs';
import { AccountProxyClient } from 'src/proxy/apiAccount/account.proxy';
import { mapThirdPartySignInToThirdPartySignInDto } from './mappings/third-party.mappings';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private accountClient: AccountProxyClient) {}

  public updatePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    return this.accountClient.accountUpdatePasswordPost(oldPassword, newPassword);
  }

  public createThirdPartyAccount(thirdParty: ThirdPartySignIn): Observable<boolean> {
    return this.accountClient.accountCreateThirdPartyUserPost(mapThirdPartySignInToThirdPartySignInDto(thirdParty));
  }

  public confirmEmail(email: string, token: string): Observable<boolean> {
    return this.accountClient.accountConfirmEmailPostForm(email, token);
  }
}
