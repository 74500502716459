import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CustomField } from '@app/models/CustomField';
import { CustomFieldsService } from '@app/services/custom-fields.service';

@Component({
  selector: 'app-custom-field-add',
  templateUrl: './custom-field-add.component.html',
  styleUrls: ['./custom-field-add.component.scss']
})
export class CustomFieldAddComponent {

    isValid: boolean;
    customField: CustomField;

    constructor(private customFieldsService: CustomFieldsService, private router: Router) { }

    addCustomField() {
        this.customFieldsService
            .createCustomField(this.customField)
            .subscribe((response) => {
                this.router.navigate(['/ajustes-generales/custom-fields']);
            });
    }

    isFormValid(isValid: boolean) {
        this.isValid = isValid;
    }

    customFieldUpdated(customField: CustomField) {
        this.customField = customField;
    }
}
