import { ResolutionLink } from "@app/models/scoring-tools/resolutionLink";
import { ResolutionRecord } from "@app/models/scoring-tools/resolutionRecord";
import { ResolutionLinkDto } from "src/proxy/model/resolutionLinkDto";
import { ResolutionRecordDto } from "src/proxy/model/resolutionRecordDto";
import { mapUser } from "./user.mappings";

export function mapResolutionLink(dto: ResolutionLinkDto): ResolutionLink {
    return <ResolutionLink>{
        id: dto.Id,
        name: dto.Name
    };
}

export function mapResolutionRecord(dto: ResolutionRecordDto): ResolutionRecord {
    return <ResolutionRecord>{
        creationDate: dto.CreationTime,
        id: dto.Id,
        searchType: dto.SearchType,
        software: dto.Software,
        totalResults: dto.TotalResults,
        userId: dto.UserId,
        comment: dto.Comment,
        reason: dto.Reason,
        reasonExplanation: dto.ReasonExplanation,
        user: mapUser(dto.User),
        data: dto.Data
    };
}