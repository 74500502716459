/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 import { RefinitivIndividualScreeningDto } from '../model/refinitivIndividualScreeningDto';
 import { RefinitivItemDetailsDto } from '../model/refinitivItemDetailsDto';
 import { RefinitivLinkDto } from '../model/refinitivLinkDto';
 import { RefinitivOrganisationScreeningDto } from '../model/refinitivOrganisationScreeningDto';
 import { RefinitivTablaScreeningItemDto } from '../model/refinitivTablaScreeningItemDto';
 import { RefinitivTableItemDto } from '../model/refinitivTableItemDto';
 import { ReportScreeningDataDto } from '../model/reportScreeningDataDto';
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
export class RefinitivScoringProxyClient {
 
     protected basePath = '/';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
    constructor(protected httpClient: HttpClient, @Optional()@Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringCreateIndividualCasePost(body?: RefinitivIndividualScreeningDto, observe?: 'body', reportProgress?: boolean): Observable<string>;
     public refinitivScoringCreateIndividualCasePost(body?: RefinitivIndividualScreeningDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
     public refinitivScoringCreateIndividualCasePost(body?: RefinitivIndividualScreeningDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
     public refinitivScoringCreateIndividualCasePost(body?: RefinitivIndividualScreeningDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<string>('post',`${this.basePath}/RefinitivScoring/CreateIndividualCase`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringCreateOrganizationCasePost(body?: RefinitivOrganisationScreeningDto, observe?: 'body', reportProgress?: boolean): Observable<string>;
     public refinitivScoringCreateOrganizationCasePost(body?: RefinitivOrganisationScreeningDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
     public refinitivScoringCreateOrganizationCasePost(body?: RefinitivOrganisationScreeningDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
     public refinitivScoringCreateOrganizationCasePost(body?: RefinitivOrganisationScreeningDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<string>('post',`${this.basePath}/RefinitivScoring/CreateOrganizationCase`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param caseId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringGetCaseDataCaseIdGet(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RefinitivTableItemDto>>;
     public refinitivScoringGetCaseDataCaseIdGet(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RefinitivTableItemDto>>>;
     public refinitivScoringGetCaseDataCaseIdGet(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RefinitivTableItemDto>>>;
     public refinitivScoringGetCaseDataCaseIdGet(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (caseId === null || caseId === undefined) {
             throw new Error('Required parameter caseId was null or undefined when calling refinitivScoringGetCaseDataCaseIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<RefinitivTableItemDto>>('get',`${this.basePath}/RefinitivScoring/GetCaseData/${encodeURIComponent(String(caseId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param worldCheckId 
      * @param thirdPartyId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringGetNewRefinitivScreeningWorldCheckIdGet(worldCheckId: string, thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
     public refinitivScoringGetNewRefinitivScreeningWorldCheckIdGet(worldCheckId: string, thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
     public refinitivScoringGetNewRefinitivScreeningWorldCheckIdGet(worldCheckId: string, thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
     public refinitivScoringGetNewRefinitivScreeningWorldCheckIdGet(worldCheckId: string, thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (worldCheckId === null || worldCheckId === undefined) {
             throw new Error('Required parameter worldCheckId was null or undefined when calling refinitivScoringGetNewRefinitivScreeningWorldCheckIdGet.');
         }
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (thirdPartyId !== undefined && thirdPartyId !== null) {
             queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<string>('get',`${this.basePath}/RefinitivScoring/GetNewRefinitivScreening/${encodeURIComponent(String(worldCheckId))}`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param referenceId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringGetProfileDetailsRefinitivReferenceIdGet(referenceId: string, observe?: 'body', reportProgress?: boolean): Observable<RefinitivItemDetailsDto>;
     public refinitivScoringGetProfileDetailsRefinitivReferenceIdGet(referenceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RefinitivItemDetailsDto>>;
     public refinitivScoringGetProfileDetailsRefinitivReferenceIdGet(referenceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RefinitivItemDetailsDto>>;
     public refinitivScoringGetProfileDetailsRefinitivReferenceIdGet(referenceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (referenceId === null || referenceId === undefined) {
             throw new Error('Required parameter referenceId was null or undefined when calling refinitivScoringGetProfileDetailsRefinitivReferenceIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<RefinitivItemDetailsDto>('get',`${this.basePath}/RefinitivScoring/GetProfileDetailsRefinitiv/${encodeURIComponent(String(referenceId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param screenId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringGetRefinitivScreeningDetailsScreenIdGet(screenId: number, observe?: 'body', reportProgress?: boolean): Observable<RefinitivItemDetailsDto>;
     public refinitivScoringGetRefinitivScreeningDetailsScreenIdGet(screenId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RefinitivItemDetailsDto>>;
     public refinitivScoringGetRefinitivScreeningDetailsScreenIdGet(screenId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RefinitivItemDetailsDto>>;
     public refinitivScoringGetRefinitivScreeningDetailsScreenIdGet(screenId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (screenId === null || screenId === undefined) {
             throw new Error('Required parameter screenId was null or undefined when calling refinitivScoringGetRefinitivScreeningDetailsScreenIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<RefinitivItemDetailsDto>('get',`${this.basePath}/RefinitivScoring/GetRefinitivScreeningDetails/${encodeURIComponent(String(screenId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param refinitiveLinkId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringGetRefinitivScreeningsRefinitiveLinkIdGet(refinitiveLinkId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<RefinitivTablaScreeningItemDto>>;
     public refinitivScoringGetRefinitivScreeningsRefinitiveLinkIdGet(refinitiveLinkId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RefinitivTablaScreeningItemDto>>>;
     public refinitivScoringGetRefinitivScreeningsRefinitiveLinkIdGet(refinitiveLinkId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RefinitivTablaScreeningItemDto>>>;
     public refinitivScoringGetRefinitivScreeningsRefinitiveLinkIdGet(refinitiveLinkId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (refinitiveLinkId === null || refinitiveLinkId === undefined) {
             throw new Error('Required parameter refinitiveLinkId was null or undefined when calling refinitivScoringGetRefinitivScreeningsRefinitiveLinkIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<RefinitivTablaScreeningItemDto>>('get',`${this.basePath}/RefinitivScoring/GetRefinitivScreenings/${encodeURIComponent(String(refinitiveLinkId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param screenId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringGetReportScreeningDataScreenIdGet(screenId: number, observe?: 'body', reportProgress?: boolean): Observable<ReportScreeningDataDto>;
     public refinitivScoringGetReportScreeningDataScreenIdGet(screenId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReportScreeningDataDto>>;
     public refinitivScoringGetReportScreeningDataScreenIdGet(screenId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReportScreeningDataDto>>;
     public refinitivScoringGetReportScreeningDataScreenIdGet(screenId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (screenId === null || screenId === undefined) {
             throw new Error('Required parameter screenId was null or undefined when calling refinitivScoringGetReportScreeningDataScreenIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<ReportScreeningDataDto>('get',`${this.basePath}/RefinitivScoring/GetReportScreeningData/${encodeURIComponent(String(screenId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param thirdPartyId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringGetThirdPartyLinkRefinitivGet(thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<RefinitivLinkDto>>;
     public refinitivScoringGetThirdPartyLinkRefinitivGet(thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RefinitivLinkDto>>>;
     public refinitivScoringGetThirdPartyLinkRefinitivGet(thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RefinitivLinkDto>>>;
     public refinitivScoringGetThirdPartyLinkRefinitivGet(thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (thirdPartyId !== undefined && thirdPartyId !== null) {
             queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<RefinitivLinkDto>>('get',`${this.basePath}/RefinitivScoring/GetThirdPartyLinkRefinitiv`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param refinitivId 
      * @param matchStrength 
      * @param thirdPartyId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringLinkThirdPartyWithRefinitivPostForm(refinitivId?: string, matchStrength?: string, thirdPartyId?: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public refinitivScoringLinkThirdPartyWithRefinitivPostForm(refinitivId?: string, matchStrength?: string, thirdPartyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public refinitivScoringLinkThirdPartyWithRefinitivPostForm(refinitivId?: string, matchStrength?: string, thirdPartyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public refinitivScoringLinkThirdPartyWithRefinitivPostForm(refinitivId?: string, matchStrength?: string, thirdPartyId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (thirdPartyId !== undefined && thirdPartyId !== null) {
             queryParameters = queryParameters.set('thirdPartyId', <any>thirdPartyId);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         let formParams: { append(param: string, value: any): void; };
         let useForm = false;
         let convertFormParamsToString = false;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
 
         if (refinitivId !== undefined) {
             formParams = formParams.append('refinitivId', <any>refinitivId) as any || formParams;
         }
         if (matchStrength !== undefined) {
             formParams = formParams.append('matchStrength', <any>matchStrength) as any || formParams;
         }
 
         return this.httpClient.request<boolean>('post',`${this.basePath}/RefinitivScoring/LinkThirdPartyWithRefinitiv`,
             {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param linkId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public refinitivScoringUnLinkThirdPartyWithRefinitivLinkIdPost(linkId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public refinitivScoringUnLinkThirdPartyWithRefinitivLinkIdPost(linkId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public refinitivScoringUnLinkThirdPartyWithRefinitivLinkIdPost(linkId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public refinitivScoringUnLinkThirdPartyWithRefinitivLinkIdPost(linkId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (linkId === null || linkId === undefined) {
             throw new Error('Required parameter linkId was null or undefined when calling refinitivScoringUnLinkThirdPartyWithRefinitivLinkIdPost.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<boolean>('post',`${this.basePath}/RefinitivScoring/UnLinkThirdPartyWithRefinitiv/${encodeURIComponent(String(linkId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }