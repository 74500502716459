import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Toaster } from 'ngx-toast-notifications';
import download from "downloadjs";
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import { ThirdPartyEvaluationReportService } from '@app/services/reports/third-party-evaluation-report.service';
import { QuestionariesService } from '@app/services/questionaries-service';
import { TranslateDescriptionPipe } from '@app/shared/pipes/translate-description.pipe';
import { CampaignThirdParty } from '@app/models/CampaignThirdParty';
import { DialogThirdEvaluationComponent } from '../dialog-third-evaluation/dialog-third-evaluation.component';
import { forkJoin } from 'rxjs';
import { EvaluationQuestionaryRiskDto } from 'src/proxy/model/evaluationQuestionaryRiskDto';
import { EvaluationQuestionaryDto } from 'src/proxy/model/evaluationQuestionaryDto';
import { CampaignStatus } from '@app/models/CampaignStatus';
import { eq } from 'cypress/types/lodash';

@Component({
  selector: 'app-campaign-release-riskboard',
  templateUrl: './campaign-release-riskboard.component.html',
  styleUrls: ['./campaign-release-riskboard.component.scss']
})
export class CampaignReleaseRiskboardComponent implements OnInit, AfterViewInit, OnDestroy {

  locale: string;
  campaignThirdParty: CampaignThirdParty;
  systemScoreGaugeChart: am4charts.RadarChart;
  manualScoreGaugeChart: am4charts.RadarChart;
  systemScoresByRiskChart: am4charts.XYChart;
  manualScoresByRiskChart: am4charts.XYChart;
  campaignStatus = CampaignStatus;

  campaignId: number;
  campaign: EvaluationQuestionaryDto;
  thirdPartyId: number;
  hasManualScores: boolean = false;
  hasOverridenScores: boolean = false;

  reportRisks = [];
  reportManualRisks = [];
  mediaRiesgos: number = 0;
  mediaRiesgosManual: number = 0;
  mediaRiesgosTotal: number = 0;
  mapaValoraciones = {};

  generatingReportMessage: string;
  reportGeneratedMessage: string;
  originalComment: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private translate: TranslateService,
    private location: Location,
    private questionariesService: QuestionariesService,
    private reportService: ThirdPartyEvaluationReportService,
    private translateDescription: TranslateDescriptionPipe,
    private toaster: Toaster) { }

  ngOnInit(): void {
    this.locale = this.translate.currentLang.split("-")[0];

    this.translate.get('CampaignRiskboard.generatingReport')
      .subscribe(translation => {
        this.generatingReportMessage = translation;
      });

    this.translate.get('CampaignRiskboard.reportGenerated')
      .subscribe(translation => {
        this.reportGeneratedMessage = translation;
      });

    this.campaignId = this.activatedRoute.snapshot.params.eid;
    this.thirdPartyId = this.activatedRoute.snapshot.params.tid;
  }

  ngAfterViewInit(): void {
    forkJoin({
      s: this.questionariesService.getScores(this.thirdPartyId, this.campaignId),
      t: this.translate.get([
        'ListadoPreguntas.form.peso1.valor',
        'ListadoPreguntas.form.peso2.valor',
        'ListadoPreguntas.form.peso3.valor',
        'ListadoPreguntas.form.peso4.valor',
        'ListadoPreguntas.form.peso5.valor',
        'ListadoPreguntas.form.valoracion.valor'
      ])
    }).subscribe(({ s, t }) => {
      this.translateTexts(t);
      this.getCampaign(s);
      this.processRiskData();
    });
  }

  ngOnDestroy(): void {
    this.systemScoreGaugeChart?.dispose();
    this.manualScoreGaugeChart?.dispose();
    this.systemScoresByRiskChart?.dispose();
    this.manualScoresByRiskChart?.dispose();
  }

  async downloadReport() {
    this.toaster.open({ text: this.generatingReportMessage, duration: 4000, type: 'info' });
    const imgSystemScoreGaugeChart = await this.systemScoreGaugeChart.exporting.getImage('png');
    const imgManualScoreGaugeChart = this.manualScoreGaugeChart ? await this.manualScoreGaugeChart.exporting.getImage('png') : '';
    const imgSystemScoresByRiskChart = await this.systemScoresByRiskChart.exporting.getImage('png');
    const imgManualScoresByRiskChart = this.manualScoresByRiskChart ? await this.manualScoresByRiskChart.exporting.getImage('png') : '';
    const a = await this.reportService.getReport(this.campaignId, this.thirdPartyId, imgSystemScoreGaugeChart, imgManualScoreGaugeChart, imgSystemScoresByRiskChart, imgManualScoresByRiskChart, this.reportRisks, this.reportManualRisks);
    this.toaster.open({ text: this.reportGeneratedMessage, duration: 4000, type: 'success' });
    download(a[1], a[0], "application/pdf");
  }

  openDialog(editMode: boolean): void {

    const dialogRef = this.dialog.open(DialogThirdEvaluationComponent, {
      data: {
        risks: this.campaign.EvaluationQuestionaryRisk,
        campaign: this.campaignId,
        third: this.thirdPartyId,
        editMode: editMode
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === undefined) {
        return
      }
      if (result) {
        this.questionariesService.getScores(this.thirdPartyId, this.campaignId).subscribe(s => {
          this.getCampaign(s);
          this.processRiskData();
        });
      }
    });
  }

  translateTexts(translation: any[]) {
    for (let index = 0; index < 5; index++) {
      this.mapaValoraciones[index + 1] = translation['ListadoPreguntas.form.peso' + (index + 1).toString() + '.valor'];
    }
  }

  getCampaign(r: EvaluationQuestionaryDto) {
    if (r) {
      this.campaign = r;
      this.originalComment = this.campaign.Comment;
      this.campaignThirdParty = <CampaignThirdParty>{
        status: this.campaign.Campaign.Status
      }
    }
  }

  processRiskData() {
    if (this.campaign && this.campaign.EvaluationQuestionaryRisk && this.campaign.EvaluationQuestionaryRisk.length > 0) {
      this.reportRisks = this.campaign.EvaluationQuestionaryRisk.reduce((rv, eqr: EvaluationQuestionaryRiskDto) => {
        rv.push({ 'risk': this.translateDescription.transform(eqr.Risk), 'score': eqr.SystemScore });
        return rv;
      }, []);

      this.reportRisks = this.reportRisks.reverse();

      if (this.campaign.EvaluationQuestionaryRisk.filter(r => r.OverridenScore || r.ManualScore).length > 0) {
        this.reportManualRisks = this.campaign.EvaluationQuestionaryRisk.reduce((rv, eqr: EvaluationQuestionaryRiskDto) => {
          rv.push({ 'risk': this.translateDescription.transform(eqr.Risk), 'score': eqr.ManualScore ?? eqr.OverridenScore ?? eqr.SystemScore });
          return rv;
        }, []);

        this.reportManualRisks = this.reportManualRisks.reverse();
      }

      this.hasManualScores = this.reportManualRisks.length > 0;
      this.hasOverridenScores = this.campaign.OverridenScore !== null;

      this.mediaRiesgos = this.campaign.SystemScore;

      this.mediaRiesgosManual = this.campaign.ManualScore ?? this.campaign.OverridenScore;
    }

    this.systemScoreGaugeChart = this.createGaugeChart(this.mediaRiesgos, 'systemScoreChartDiv');
    this.systemScoresByRiskChart = this.createBarChart(this.reportRisks, 'systemScoresByRiskChartDiv')
    if (this.hasManualScores || this.hasOverridenScores) {
      this.manualScoreGaugeChart = this.createGaugeChart(this.mediaRiesgosManual, 'manualScoreChartDiv');
    }

    if (this.hasManualScores) {
      this.manualScoresByRiskChart = this.createBarChart(this.reportManualRisks, 'manualScoresByRiskChartDiv');
    }
  }

  public goBack() {
    this.location.back();
  }

  updateComment() {
    if (this.originalComment !== this.campaign.Comment) {
      this.originalComment = this.campaign.Comment;
      this.questionariesService.updateQuestionaryComment({ questionaryId: this.campaign.Id, comment: this.originalComment });
    }
  }

  createGaugeChart(value: number, divName: string): am4charts.RadarChart {
    let chart = am4core.create(divName, am4charts.RadarChart);

    // Add data
    chart.data = [{
      "category": "score",
      "value": value,
      "full": 5
    }];

    // Make chart not full circle
    chart.startAngle = -180;
    chart.endAngle = 0;
    chart.innerRadius = am4core.percent(90);


    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererRadial>());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.labels.template.disabled = true;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.max = 5;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;

    // Create series
    var series1 = chart.series.push(new am4charts.RadarColumnSeries());
    series1.dataFields.valueX = "full";
    series1.dataFields.categoryY = "category";
    series1.clustered = false;
    series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    series1.columns.template.fillOpacity = 0.08;

    series1.columns.template.strokeWidth = 0;
    series1.columns.template.radarColumn.cornerRadius = 20;

    var series2 = chart.series.push(new am4charts.RadarColumnSeries());
    series2.dataFields.valueX = "value";
    series2.dataFields.categoryY = "category";
    series2.clustered = false;
    series2.columns.template.fill = am4core.color("#F89F10");
    series2.columns.template.strokeWidth = 0;
    series2.columns.template.radarColumn.cornerRadius = 20;

    let label = chart.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = 34;
    label.fontFamily = 'Poppins';
    label.fontWeight = '600';
    label.x = am4core.percent(50);
    label.y = am4core.percent(60);
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.text = `${value.toFixed(2)}`;

    let label2 = chart.createChild(am4core.Label);
    label2.isMeasured = false;
    label2.fontSize = 16;
    label2.fontFamily = 'Poppins';
    label2.fontWeight = '600';
    label2.x = am4core.percent(50);
    label2.y = am4core.percent(70);
    label2.horizontalCenter = "middle";
    label2.verticalCenter = "top";
    label2.text = this.mapaValoraciones[Math.trunc(value)].split('-')[1].trim();
    label2.fill = am4core.color("#8c8c8c");

    return chart;
  }

  createBarChart(risks: any[], divName: string): am4charts.XYChart {
    let chart = am4core.create(divName, am4charts.XYChart);
    chart.data = risks;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "risk";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.tooltipText = "{category}";
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.labels.template.maxWidth = 250;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 5;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.minWidth = 25;
    this.createCustomXAxis(valueAxis);

    let serie = chart.series.push(new am4charts.ColumnSeries());
    serie.dataFields.valueX = "score";
    serie.columns.template.tooltipText = `{categoryY}: {valueX}`;
    serie.columns.template.width = am4core.percent(60);
    serie.dataFields.categoryY = "risk";
    serie.dataItems.template.locations.categoryY = 0.5;
    serie.columns.template.strokeWidth = 0;
    serie.stacked = true;
    serie.columns.template.adapter.add("fill", (fill, target) => {
      const value = target.dataItem.getValue('valueX');
      if (0 <= value && value <= 1) {
        return am4core.color('#69B34C');
      }
      if (1 < value && value <= 2) {
        return am4core.color('#ACB334');
      }
      if (2 < value && value <= 3) {
        return am4core.color('#FAB733');
      }
      if (3 < value && value <= 4) {
        return am4core.color('#FF8E15');
      }
      if (4 < value) {
        return am4core.color('#FF0D0D');
      }
      return am4core.color('#FCEDCA');
    });

    return chart;
  }

  createCustomXAxis(valueAxis: am4charts.ValueAxis<am4charts.AxisRenderer>) {
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    for (let value = 0; value <= 5; ++value) {
      this.addLabeltoValueAxis(valueAxis, value);
    }
  }

  addLabeltoValueAxis(chartValue: am4charts.ValueAxis<am4charts.AxisRenderer>, value: number) {
    const range = chartValue.axisRanges.create();
    range.value = value;
    range.label.text = "{value}";
  }
}
