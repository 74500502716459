import { Component, Input } from "@angular/core";
import { WatchListInfo } from "@app/modules/screening-hub/models/GesintelScreening/WatchListInfo";

@Component({
  selector: 'aml-update-adverse-media',
  templateUrl: './adverse-media.component.html',
  styleUrls: ['./adverse-media.component.scss'],
})
export class AMLUpdateAdverseMediaComponent {
  @Input() adverseMediaInfo: WatchListInfo;

  constructor() { }
}
