<!--modal añadir user-->
<div role="dialog" class="c-dialog" aria-labelledby="titleDialog" *ngIf="dataInitialized">
    <div [formGroup]="form">
        <header class="c-dialog__header">
            <h1 *ngIf="data===null" id="titleDialog" class="c-dialog__title">{{'TablaUsersDialog.titleAdd.valor' | translate}}</h1>
            <h1 *ngIf="data!==null" id="titleDialog" class="c-dialog__title">{{'TablaUsersDialog.titleUpdate.valor' | translate}}</h1>
            <button mat-button (click)="onNoClick()" class="c-dialog__close" aria-label="Cerrar ventana modal">
                <span aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                        <path
                            d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
                            transform="translate(-309.86-406.47)" />
                    </svg>
                </span>
            </button>
        </header>
        <div class="c-dialog__content u-pb-1">
            <div *ngIf="data===null">
                <h2 class="c-dialog__subtitle">{{'TablaUsersDialog.title2Add.valor' | translate}} </h2>
                <p class="o-text">{{'TablaUsersDialog.introAdd.valor' | translate}}</p>
            </div>
            <div *ngIf="data!==null">
                <h2 class="c-dialog__subtitle">{{'TablaUsersDialog.title2Update.valor' | translate}}</h2>
                <p class="o-text">{{'TablaUsersDialog.introUpdate.valor' | translate}}</p>
            </div>
            <div class="c-form">
                <div class="row">
                    <div class="col-lg-6">
                        <p id="sectorCode" class="c-fields__legend">{{'TablaUsersDialog.name.valor' | translate}}</p>
                        <mat-form-field floatLabel="never"> 
                            <input type="text" matInput formControlName="Name" name="sector-code" value="" id="name" class="c-form__input" placeholder="{{'TablaUsersDialog.name.valor' | translate}}" aria-labelledby="sectorCode" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <p id="sectorDescription" class="c-fields__legend">{{'TablaUsersDialog.surname.valor' | translate}}
                        </p>
                        <mat-form-field floatLabel="never">
                            <input type="text" matInput formControlName="SurName" name="sector-description" value="" id="surname" class="c-form__input" placeholder="{{'TablaUsersDialog.surname.valor' | translate}}" aria-labelledby="sectorDescription" />
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                        <p id="sectorDescription" class="c-fields__legend">{{'TablaUsersDialog.email.valor' | translate}}
                        </p>
                        <mat-form-field floatLabel="never">
                            <input type="text" matInput formControlName="Email" name="sector-description" value="" id="email" class="c-form__input" placeholder="{{'TablaUsersDialog.email.valor' | translate}}" aria-labelledby="sectorDescription" />
                        </mat-form-field>
                        <div *ngIf="mailContactNotValid" role="alert" aria-labelledby="config-mailContact" class="c-form-error">
                            <p class="c-form-error__text">{{'TablaUsersDialog.email_error.valor' | translate}}
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="c-select u-mt-1">
                            <mat-form-field floatLabel="never">
                                <p id="sectorDescription" class="c-fields__legend">{{'TablaUsersDialog.position.valor' | translate}}</p>
                                <mat-select formControlName="Position" placeholder="{{'TablaUsersDialog.position.valor' | translate }}" required>
                                    <mat-option *ngFor="let position of positions" [value]="position">
                                      {{ getPositionTranslationKey(position) | translate }}</mat-option>
                                  </mat-select>
                            </mat-form-field>
                        </div>                        
                    </div>
                    <div class="col-lg-6">
                        <div class="c-select u-mt-1">
                            <mat-form-field floatLabel="never" *ngIf="roles">
                                    <p id="sectorDescription" class="c-fields__legend select" [ngClass]="{'disabled': isRoleChangeEnabled()}">{{'TablaUsersDialog.role.valor' | translate}}</p>
                                    <mat-select formControlName="Role" id="rol" placeholder="{{'TablaUsersDialog.role.valor' | translate}}" aria-labelledby="sectorDescription">
                                        <mat-option *ngFor="let r of roles" [value]="r">
                                            {{r}}
                                        </mat-option>
                                    </mat-select>
                            </mat-form-field>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="c-dialog__footer flex-end">
            <button *ngIf="data===null" [disabled]="!form.valid" (click)="addUser()" class="c-button c-button--green u-align-right" mat-button>{{'TablaUsersDialog.add.valor' | translate}}</button>
            <button *ngIf="data!==null" [disabled]="!form.valid" (click)="updateUser()" class="c-button c-button--green u-align-right" mat-button>{{'TablaUsersDialog.update.valor' | translate}}</button>
        </footer>
    </div>
</div>