<div class="basic-data">
  <p id="keyData" class="basic-data__legend u-mt-1medium">{{ "AMLUpdateCompanyBasicData.title" | translate }}</p>
  <div class="basic-data__group" aria-labelledby="keyData">
    <div class="basic-data-item">
      <p id="thirdName" class="basic-data-item__title">
        {{ "AMLUpdateCompanyBasicData.name" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="fullName">
        {{ scoringDetails?.fullName || "-" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="thirdAlias" class="basic-data-item__title">
        {{ "AMLUpdateCompanyBasicData.rut" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdAlias">
        {{ scoringDetails?.rut || "-" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="thirdRisks" class="basic-data-item__title">
        {{ "AMLUpdateCompanyBasicData.startDate" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdAlias">
        {{ scoringDetails?.registerDate || "-" }}
      </p>
    </div>
    <div class="basic-data-item">
      <p id="thirdCategory" class="basic-data-item__title">
        {{ "AMLUpdateCompanyBasicData.endDate" | translate }}
      </p>
      <p class="basic-data-item__text" aria-labelledby="thirdCategory">
        {{ scoringDetails?.birthDate || "-" }}
      </p>
    </div>
  </div>
</div>
