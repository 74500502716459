<section class="c-table u-mt-1">
  <div class="o-scroll">
    <div class="c-table__bottom">
      <table mat-table [dataSource]="this.queryResult.items" class="mat-elevation-z8" matSort
        (matSortChange)="sortBy($event)">
        <ng-container matColumnDef="personalData">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="false">
            {{ "ThirdPartyCandidateTable.MatchedAlias.valor" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="c-responsible">
              <div class="c-responsible__info">
                <span class="c-responsible__name" style="font-weight: 400; color: black">
                  {{ element.fullName }}
                </span>
                <div class="c-responsible__position">
                  {{ element.rut }}
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="mandatoryLists">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            {{ "ThirdPartyCandidateTable.mandatoryLists" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.mandatoryLists?.numberOfLists || 0 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="kyc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            KYC
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.kyc?.numberOfLists || 0 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ubo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            UBO & Companies
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.ubo?.numberOfLists || 0 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="matchStrength">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
            {{ "ThirdPartyCandidateTable.MatchStrength.valor" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="c-progress-bar" [class]="calculateProgressColor(element)">
              <mat-progress-bar aria-labelledby="progressBar" class="c-progress-bar__bar"
                [value]="element.matchStrength">
              </mat-progress-bar>
              <p id="progressBar" class="c-progress-bar__percent">
                {{ element.matchStrength }}%
              </p>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: false"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">
            {{'ThirdPartyCandidateTable.ThirdsNotFound.valor'|translate}}.
          </td>
        </tr>
      </table>
      <mat-paginator (page)="onPageChange($event)" [pageSize]="query.PageSize" [length]="this.queryResult.totalItems"
        class="c-pagination">
      </mat-paginator>
    </div>
  </div>
</section>
