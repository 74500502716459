import { Client } from "@app/models/Client";
import { ClientInfo } from "@app/models/ClientInfo";
import { ClientDto } from "src/proxy/model/clientDto";
import { ClientInfoDto } from "src/proxy/model/clientInfoDto";
import { FinanceTypesDto } from "src/proxy/model/financeTypesDto";
import { FinanceTypesDto as FinanceTypes } from "@app/models/FinanceTypes";
import { mapDescriptionToCurrentLanguage } from "./description-translation.mapping";
import { ClientFinanceTypeDto } from "src/proxy/model/clientFinanceTypeDto";
import { ClientFinanceType } from "@app/models/ClientFinanceType";
import { ExecutiveOrganDto } from "src/proxy/model/executiveOrganDto";
import { ExecutiveOrganDto as ExecutiveOrgan } from "@app/models/ExecutiveOrgan";

export function mapClient(dto: ClientDto, currentLanguage: string): Client {
    if (!dto) return null;

    return <Client>{
        clientInfo: dto.ClientInfo ? mapClientInfo(dto.ClientInfo, currentLanguage): null,
        companyCif: dto.CompanyCif,
        companyRegistrationFileName: dto.CompanyRegistrationFileName,
        companyRegistrationFileUri: dto.CompanyRegistrationFileUri,
        countryId: dto.Country?.Id,
        country: dto.Country,
        coverPageImageFileName: dto.CoverPageImageFileName,
        coverPageImageFileUri: dto.CoverPageImageFileUri,
        iconImageFileName: dto.IconImageFileName,
        iconImageFileUri: dto.IconImageFileUri,
        id: dto.Id,
        name: dto.Name,
        numOfLicenses: dto.NumOfLicenses,
        otherTypeOfActivity: dto.OtherTypeOfActivity,
        typeOfActivity: dto.TypeOfActivity,
        maxThirdParties: dto.MaxThirdParties,
        refinitivQuota: dto.RefinitivQuota
    };
}

export function mapClientToClientDto(dto: Client): ClientDto {
    if (!dto) return null;

    return <ClientDto> {
        ClientInfo: null, // TODO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        CompanyCif: dto.companyCif,
        CompanyRegistrationFileName: dto.companyRegistrationFileName,
        CompanyRegistrationFileUri: dto.companyRegistrationFileUri,
        Country: dto.country,
        CoverPageImageFileName: dto.coverPageImageFileName,
        CoverPageImageFileUri: dto.coverPageImageFileUri,
        IconImageFileName: dto.iconImageFileName,
        IconImageFileUri: dto.iconImageFileUri,
        Id: dto.id,
        Name: dto.name,
        NumOfLicenses: dto.numOfLicenses,
        OtherTypeOfActivity: dto.otherTypeOfActivity,
        TypeOfActivity: dto.typeOfActivity,
        MaxThirdParties: dto.maxThirdParties,
        RefinitivQuota: dto.refinitivQuota
    }
}

    function mapClientInfo(dto: ClientInfoDto, currentLanguage: string): ClientInfo {
    return <ClientInfo>{
        annualAccountingFirstYear: dto.AnnualAccountingFirstYear,
        annualAccountingFirstYearFileName: dto.AnnualAccountingFirstYearFileName,
        annualAccountingFirstYearFileUri: dto.AnnualAccountingFirstYearFileUri,
        annualAccountingSecondYear: dto.AnnualAccountingSecondYear,
        annualAccountingSecondYearFileName: dto.AnnualAccountingSecondYearFileName,
        annualAccountingSecondYearFileUri: dto.AnnualAccountingSecondYearFileUri,
        companyGroup: dto.CompanyGroup,
        complianceProgram: dto.ComplianceProgram,
        executiveOrgan: mapExecutiveOrgan(dto.ExecutiveOrgan, currentLanguage),
        externalAudit: dto.ExternalAudit,
        externalAuditFileName: dto.ExternalAuditFileName,
        externalAuditFileUri: dto.ExternalAuditFileUri,
        financeTypes: dto.FinanceTypes.map(ft => mapClientFinanceType(ft, currentLanguage)),
        id: dto.Id,
        internalAudit: dto.InternalAudit,
        internalAuditFileName: dto.InternalAuditFileName,
        internalAuditFileUri: dto.InternalAuditFileUri,
        investeeCompanies: dto.InvesteeCompanies,
        isoCertifications: dto.IsoCertifications,
        numWorkers: dto.NumWorkers,
        otherExecutiveOrgan: dto.OtherExecutiveOrgan,
        outSourcedCompanies: dto.OutSourcedCompanies
    };
}

function mapClientFinanceType(dto: ClientFinanceTypeDto, currentLanguage: string) : ClientFinanceType {
    return <ClientFinanceType> {
        Id: dto?.Id,
        FinanceType: mapFinanceType(dto?.FinanceType, currentLanguage)
    }
}

function mapFinanceType(dto: FinanceTypesDto, currentLanguage: string) : FinanceTypes {
    return <FinanceTypes> {
        Id: dto?.Id,
        Code: dto?.Code,
        Description: mapDescriptionToCurrentLanguage(dto, currentLanguage)
    }
}

function mapExecutiveOrgan(dto: ExecutiveOrganDto, currentLanguage: string) : ExecutiveOrgan {
    return <ExecutiveOrgan> {
        Id: dto?.Id,
        Code: dto?.Code,
        Description: mapDescriptionToCurrentLanguage(dto, currentLanguage)
    }
}

