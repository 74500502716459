/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 import { Observable }                                        from 'rxjs';
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
 export class CommercialProxyClient {
 
     protected basePath = '/';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * 
      * 
      * @param quotaToIncrease 
      * @param remarks 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public commercialRequestIncreaseQuotaGet(quotaToIncrease?: number, remarks?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public commercialRequestIncreaseQuotaGet(quotaToIncrease?: number, remarks?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public commercialRequestIncreaseQuotaGet(quotaToIncrease?: number, remarks?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public commercialRequestIncreaseQuotaGet(quotaToIncrease?: number, remarks?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (quotaToIncrease !== undefined && quotaToIncrease !== null) {
             queryParameters = queryParameters.set('quotaToIncrease', <any>quotaToIncrease);
         }
         if (remarks !== undefined && remarks !== null) {
             queryParameters = queryParameters.set('remarks', <any>remarks);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<boolean>('get',`${this.basePath}/Commercial/RequestIncreaseQuota`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param thirdPartyIncrement 
      * @param remarks 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public commercialRequestIncreaseThirdPartyLimitGet(thirdPartyIncrement?: number, remarks?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public commercialRequestIncreaseThirdPartyLimitGet(thirdPartyIncrement?: number, remarks?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public commercialRequestIncreaseThirdPartyLimitGet(thirdPartyIncrement?: number, remarks?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public commercialRequestIncreaseThirdPartyLimitGet(thirdPartyIncrement?: number, remarks?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (thirdPartyIncrement !== undefined && thirdPartyIncrement !== null) {
             queryParameters = queryParameters.set('thirdPartyIncrement', <any>thirdPartyIncrement);
         }
         if (remarks !== undefined && remarks !== null) {
             queryParameters = queryParameters.set('remarks', <any>remarks);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (Bearer) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<boolean>('get',`${this.basePath}/Commercial/RequestIncreaseThirdPartyLimit`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }
 