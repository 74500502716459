import { Component, EventEmitter, Output } from '@angular/core';
import { Gender, getGenderList } from '@app/models/scoring-tools/gender';
import { MemberFilter } from '@app/modules/screening-hub/models/member-check/member-filter';

@Component({
  selector: 'member-check-scoring-member-search',
  templateUrl: './member-check-scoring-member-search.component.html',
  styleUrls: ['./member-check-scoring-member-search.component.scss']
})
export class MemberCheckScoringMemberSearchComponent {
  @Output() onSearchClick = new EventEmitter<MemberFilter>();

  model: MemberFilter;
  genderList: Gender[];

  constructor() {
    this.genderList = getGenderList();
    this.model = new MemberFilter();
  }

  search() {
    this.onSearchClick.emit(this.model);
  }
}
