import { IndividualFilter } from "@app/models/scoring-tools/individual-filter";
import { OrganizationFilter } from "@app/models/scoring-tools/organization-filter";
import { Scoring } from "@app/models/scoring-tools/scoring";
import { ScoringCandidate } from "@app/models/scoring-tools/scoringCandidate";
import { ScoringCandidateDetails } from "@app/models/scoring-tools/scoringCandidateDetails";
import { ScoringRisk } from "@app/models/scoring-tools/scoringRisk";
import { Screening } from "@app/models/scoring-tools/screening";
import { ScreeningDetails } from "@app/models/scoring-tools/screeningDetails";
import { Keyword, ScreeningReportData } from "@app/models/screeningReportData";
import { RefinitivIndividualScreeningDto } from "src/proxy/model/refinitivIndividualScreeningDto";
import { RefinitivItemDetailsDto } from "src/proxy/model/refinitivItemDetailsDto";
import { RefinitivLinkDto } from "src/proxy/model/refinitivLinkDto";
import { RefinitivOrganisationScreeningDto } from "src/proxy/model/refinitivOrganisationScreeningDto";
import { RefinitivRiskDto } from "src/proxy/model/refinitivRiskDto";
import { RefinitivTablaScreeningItemDto } from "src/proxy/model/refinitivTablaScreeningItemDto";
import { RefinitivTableItemDto } from "src/proxy/model/refinitivTableItemDto";
import { ReportScreeningDataDto } from "src/proxy/model/reportScreeningDataDto";
import { mapUser } from "./user.mappings";

export function mapScreeningDetails(dto: RefinitivItemDetailsDto): ScreeningDetails {
    return <ScreeningDetails>{
        alias: dto?.Alias,
        category: dto?.Category,
        name: dto?.Name,
        thirdPartyRisks: dto?.Risks.map(r => mapScoringRisk(r)),
        worldCheckId: dto?.EntityId,
        externalSources: dto?.ExternalResources.map(s => s.uri),
        biography: dto?.FutherInformation.find(f => f.detailType === 'BIOGRAPHY')?.text,
        reports: dto?.FutherInformation.find(f => f.detailType === 'REPORTS')?.text,
        funding: dto?.FutherInformation.find(f => f.detailType === 'FUNDING')?.text,
        identification: dto?.FutherInformation.find(f => f.detailType === 'IDENTIFICATION')?.text,
        matchStrength: dto?.MatchStrength,
        createdBy: mapUser(dto.User),
        screeningDate: dto?.ScreeningDate
    };
  }

export function mapScreening(dto: RefinitivTablaScreeningItemDto, worldCheckId: string): Screening {
    return <Screening>{
        screeningDate: dto.ScreeningDate,
        thirdPartyRisks: dto?.Risks.reduce(function (dr: string[], a) {
            const index = dr.findIndex(r => r == a.Type);
            if(index === -1){
                dr.push(a.Type)
            }
            return dr;
        }, []),
        id: dto.IdScreening,
        createdBy: mapUser(dto.User),
        worldCheckId: worldCheckId,
        entityType: dto.EntityType,
    }
}

export function mapScoringCandidate(dto: RefinitivTableItemDto): ScoringCandidate {
    return <ScoringCandidate> {
        category: dto?.Category,
        matchStrength: dto?.MatchStrength,
        referenceId: dto?.ReferenceId,
        software: 'Refinitiv',
        thirdPartyRisks: dto?.Risks,
        name: dto?.Name,
        worldCheckId: dto?.ReferenceId,
        matchedAlias: dto?.Alias
    }
}

export function mapScoring(dto: RefinitivLinkDto): Scoring {
    return <Scoring> {
        category: dto?.Category,
        matchStrength: dto?.MatchStrength,
        id: dto?.Id,
        worldCheckId: dto?.ReferenceId,
        name: dto?.Name,
        alias: dto?.Alias
    }
}

export function mapScoringCandidateDetails(dto: RefinitivItemDetailsDto): ScoringCandidateDetails {
    return <ScoringCandidateDetails>{
        alias: dto?.Alias,
        category: dto?.Category,
        name: dto?.Name,
        thirdPartyRisks: dto?.Risks.map(r => mapScoringRisk(r)),
        worldCheckId: dto?.EntityId,
        externalSources: dto?.ExternalResources.map(s => s.uri),
        biography: dto?.FutherInformation.find(f => f.detailType === 'BIOGRAPHY')?.text,
        reports: dto?.FutherInformation.find(f => f.detailType === 'REPORTS')?.text,
        funding: dto?.FutherInformation.find(f => f.detailType === 'FUNDING')?.text,
        identification: dto?.FutherInformation.find(f => f.detailType === 'IDENTIFICATION')?.text,
        matchStrength: ''
    };
}

export function mapIndividualFilterToDto(filter: IndividualFilter): RefinitivIndividualScreeningDto {
    return <RefinitivIndividualScreeningDto> {
        dateOfBirth: filter.birthDate,
        gender: filter.gender,
        location: filter.country,
        name: filter.name,
        nationality: filter.nationality,
        placeOfBirth: filter.birthPlace
    };
}

export function mapOrganizationFilterToDto(filter: OrganizationFilter): RefinitivOrganisationScreeningDto {
    return <RefinitivOrganisationScreeningDto> {
        name: filter.name,
        location: filter.country
    }
}

export function mapScreeningReport(dto: ReportScreeningDataDto): ScreeningReportData {
    return <ScreeningReportData>{
        alias: dto.Alias,
        biography: dto.Biography,
        category: dto.Category,
        clientSubmittedData: dto.ClientSubmittedData,
        companyName: dto.CompanyName,
        countryLocations: dto.CountryLocations,
        createdBy: dto.CreatedBy,
        dataset: dto.Dataset,
        enteredDate: dto.EnteredDate,
        externalSources: dto.ExternalSources,
        group: dto.Group,
        identification: dto.Identification,
        identificationType: dto.IdentificationType,
        identificationNumber: dto.IdentificationNumber,
        keywords: dto.Keywords.map(k => {return <Keyword>{keyword: k.Key, country:k.Country, description: k.Description}}),
        linkedCompanies: dto.LinkedCompanies,
        linkedIndividuals: dto.LinkedIndividuals,
        location1: dto.Location1,
        location2: dto.Location2,
        registeredCountry: dto.RegisteredCountry,
        reports: dto.Reports,
        screeningDate: dto.ScreeningDate,
        specialInterestCategories: dto.SpecialInteresCategories,
        updateCategorization: dto.UpdateCategory,
        updatedDate: dto.UpdatedDate,
        worldCheckData: dto.WorldChekData,
        worldCheckId: dto.WorldCheckId,
        printedBy: dto.PrintedBy,
        risks: dto.Risks?.map(r => mapScoringRisk(r))
    };
}

function mapScoringRisk(dto: RefinitivRiskDto): ScoringRisk {
    return <ScoringRisk> {
        abbreviation: dto.Abbreviation,
        country: dto.Country,
        name: dto.Name,
        type: dto.Type,
        creationDate: dto.CreationDate
    };
}
