import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-third-massive-ok',
  templateUrl: './dialog-confirm-third-massive-ok.component.html',
  styles: [
  ]
})
export class DialogConfirmThirdMassiveComponentOk implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmThirdMassiveComponentOk>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
    }

    // cerrar modal
    onNoClick(): void {
        this.dialogRef.close();
    }
}
