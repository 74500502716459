<section class="c-login">
    <div class="c-login__wrapper">
        <!--parte izquierda - imagen-->
        <div class="c-login__left" aria-hidden="true">
            <div class="c-login-header">
            </div>
            <div class="c-login__opacity"></div>
        </div>
        <!--parte derecha - form-->
        <div class="c-login__right">
            <div class="c-login__right-top">
                <h2 class="c-login__subtitle">{{'Login.title.valor' | translate}}</h2>
                <form [formGroup]="form" (ngSubmit)="onSubmit()" class="c-login-form" autocomplete="off" data-test-id="login-form">
                    <fieldset>
                        <legend class="u-sr-only">{{'Login.accessData' | translate}}</legend>
                        <!--email usuario-->
                        <div class="c-login-form__block">
                            <mat-form-field>
                                <input type="email" matInput name="username" value="" id="login-mail" data-test-id="login-mail" class="c-login__input" formControlName="username" placeholder="{{'Login.user_placeholder.valor' | translate}}" />
                            </mat-form-field>
                        </div>
                        <!--contraseña-->
                        <div class="c-login-form__block">
                            <mat-form-field>
                                <input type="{{typePassword ? 'text' : 'password' }}" matInput name="password" id="login-pass" data-test-id="login-pass" class="c-login__input" formControlName="password" placeholder="{{'Login.pass.valor' | translate}}" />
                                <button type="button" (click)="togglePassword()" class="c-login__toggle-pass" aria-label="Mostrar u ocultar contraseña">
                                    <span *ngIf = "!isShow" aria-hidden="true">
                                        <img src="assets/ico-eye-close.svg" alt="" loading="lazy" />
                                    </span>
                                    <span *ngIf = "isShow" aria-hidden="true">
                                        <img src="assets/ico-eye-open.svg" alt="" loading="lazy" />
                                    </span>
                                </button>
                            </mat-form-field>
                        </div>
                        <a [routerLink]="['/he-olvidado-contrasena']" class="c-login__link" aria-labelledby="login-pass" data-test-id="forgot-password-link">{{'Login.forgot.valor' | translate}}</a>
                        <!--iniciar sesión botón-->
                        <button [disabled]="loading" type="submit" class="c-button" data-test-id="log-in-btn">{{'Login.init.valor' | translate}}</button>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</section>