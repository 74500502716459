import { Component, OnInit } from '@angular/core';
import { RiskListService } from '@app/services/risk-list.service';
import { Campaign } from '@app/models/Campaign';
import { sort } from '@app/utilities/utilities';

@Component({
  selector: 'app-myrisks-table',
  templateUrl: './myrisks-table.component.html',
  styleUrls: ['./myrisks-table.component.scss']
})
export class MyrisksTableComponent implements OnInit {

  assessments: Campaign[] = [];
  filteredAssessments: Campaign[] = [];
  totalAssessments: number;
  isLoading: boolean;
  pageSize: number = 5;
  page: number = 1;
  sortingColumn: string = 'startDate';
  isSortAscending: boolean = false;
  filterText: string = '';

  tableColumns = ['name', 'assessed', 'dates', 'status', 'actions']

  constructor(private risklistService: RiskListService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.risklistService.getAllRiskLists().subscribe(r => {
      this.assessments = r.sort((a, b) => sort(a, b, 'endDate', false));
      this.getData();
      this.isLoading = false;
    });
  }

  getData() {
    let filtered = this.assessments?.filter(ctp => this.filterFunction(ctp)).sort((a, b) => sort(a, b, this.sortingColumn, this.isSortAscending)) ?? []
    this.filteredAssessments = filtered.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    this.totalAssessments = filtered.length;
  }

  sortBy(columnName) {
    this.page = 1;
    this.sortingColumn = columnName.active;
    this.isSortAscending = columnName.direction === 'asc'
    this.getData();
  }

  onFilter(text: string): void {
    this.page = 1;
    this.filterText = text.toLowerCase();
    this.getData();
  }

  onPageChange(page) {
    this.page = page.pageIndex + 1;
    this.pageSize = page.pageSize;
    this.getData();
  }

  filterFunction(assessment: Campaign): boolean {
    if (this.filterText == '') return true;

    return String(assessment.name).toLowerCase().includes(this.filterText.toLowerCase())
      || String(assessment.client.name).toLowerCase().includes(this.filterText.toLowerCase());
  }

  checkIfExpiring(value: string) {
    const endDate = new Date(value);
    const lowerLimit = new Date();
    lowerLimit.setDate(endDate.getDate() - 2);
    const currentDate = new Date();

    return lowerLimit <= currentDate && currentDate <= endDate;
  }
  // private populateExpansionCampaigns(campaigns: CampaignDto[]): RisksExpanded[] {
  //   const transformedCampaigns = campaigns.map(c => {
  //     const currentDate = new Date();
  //     const campaignEndDate = new Date(c.EndDate);
  //     const isCampaignEnded = currentDate > campaignEndDate;

  //     let actions = ['contact'];
  //     switch (c.Status) {
  //       case CampaignStatus.pendingSign:
  //         actions.push('sign');
  //         break;
  //       case CampaignStatus.inProgress:
  //         actions.push('fill');
  //         break;
  //       case CampaignStatus.pending:
  //         actions.push('fill');
  //         break;
  //       default:
  //         actions.push('see')
  //         break;
  //     }

  //     return <RisksExpanded>{
  //       campaignId: c.Id,
  //       campaign: c.Name,
  //       dateEnd: c.EndDate,
  //       progress: c.Progress,
  //       contactId: c.UserResponsibleId,
  //       thirdPartyId: c.ThirdParties[0].Id,
  //       actions: isCampaignEnded ? ['see', 'contact'] : actions,
  //       questionaryId: c.QuestionaryId
  //     };
  //   });
  //   transformedCampaigns.sort((a, b) => (new Date(b.dateEnd)).getTime() - (new Date(a.dateEnd)).getTime());
  //   return transformedCampaigns;
  // }

}
